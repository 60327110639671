import * as React from "react";
import Collapsible from "react-collapsible";
import { Account } from "core/models";
import { AccountAddRow } from "components/features/dashboard/components/account-category-section/components/account-add-row";
import { DndProvider } from "react-dnd";
import { DraggableWrapper } from "components/features/dashboard/components/row-card/draggable-wrapper";
import { EditCategoryButton } from "components/features/dashboard/components/account-category-section/components/edit-category-menu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon, IconAsset } from "components/core/icons";
import { IsAccountTypeConnectable } from "components/features/dashboard/components/add-account-tray/models";
import { RowCard } from "components/features/dashboard/components/row-card";
import { Text, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useHover } from "core/hooks/hover";
import { useLocalStorage, useSessionStorage } from "core/utils/browser-storage";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

interface Props {
  category: string;
  sum: number;
  accounts: Account[];
  openAccountID: string;
  highlightRows?: boolean;
  existingCategories: string[];
  onOpen: (accountID: string) => void;
  onClose: () => void;
  onHighlightClear?: () => void;
  onAccountMove: (
    category: string,
    dragIndex: number,
    moveIndex: number
  ) => void;
  onDragComplete: () => void;
  onRemove: (category: string, isExample: boolean) => void;
  onAdd: (category: string) => void;
  lastCategory?: boolean;
}

export const AccountCategorySection: React.FC<Props> = (props: Props) => {
  const [hasSeenHighlightFirstRow, setHasSeenHighlightFirstRow] =
    useLocalStorage("hasSeenHighlightFirstRow", false);
  const [isOpen, setIsOpen] = useSessionStorage(
    `collapsible.accounts.${props.category}.isopen`,
    true
  );

  const caratStyles = React.useMemo(() => {
    return isOpen
      ? "accounts__section-carat"
      : "accounts__section-carat-closed";
  }, [isOpen]);

  const isExample = !!props.accounts
    ?.map((a) => a.account_id)
    .find((id) => id.includes("EXAMPLE"));

  const [hoverRef, isFirstHovering] = useHover<HTMLDivElement>();
  const [secondHoverRef, isSecondHovering] = useHover<HTMLDivElement>();

  const isHovering = isFirstHovering || isSecondHovering;

  const shouldHighlightFirstRow = () => {
    return props.highlightRows && !hasSeenHighlightFirstRow;
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: isHovering ? "var(--gray-02)" : "",
        borderRadius: "10px",
      }}
    >
      <div style={{ flexGrow: 1 }} className="collapsible-wrapper draggable">
        <Collapsible
          overflowWhenOpen={"visible"}
          open={isOpen}
          trigger={
            <div
              ref={hoverRef}
              style={{
                display: "flex",
                width: "100%",
              }}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div className={caratStyles}>
                  <Icon
                    asset={IconAsset.CollapsibleOpen}
                    width="24px"
                    height="24px"
                  />
                </div>
              </div>
              <div
                className="account-section-header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  opacity: isHovering ? 1 : 0,
                }}
              >
                <div>
                  <Icon
                    asset={IconAsset.Reorder}
                    height={"16px"}
                    width={"10px"}
                  />
                </div>
              </div>
              <div
                ref={secondHoverRef}
                style={{ flexGrow: 1 }}
                className="accounts__section-category"
              >
                <div className="ellipsis" style={{ width: "50%" }}>
                  {props.category}
                </div>
                <div style={{ display: "flex" }}>
                  <div className="accounts__section-balance">
                    <Text type={TextType.Div} weight={600}>
                      {toMoneyString(props.sum)}
                    </Text>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  opacity: isHovering ? 1 : 0,
                }}
              >
                <EditCategoryButton
                  existingCategories={props.existingCategories}
                  isExample={isExample}
                  onAdd={(newCategory) => props.onAdd(newCategory)}
                  onRemove={() => props.onRemove(props.category, isExample)}
                />
              </div>
            </div>
          }
        >
          {props.accounts.length !== 0 && (
            <div
              className={`${
                shouldHighlightFirstRow() ? "highlight-account-row" : ""
              }`}
            >
              <div className="table accounts">
                <div className="table-head">
                  <div className="table-cell">Name</div>
                  <div className="table-cell owner">Owned by</div>
                  <div className="table-cell value">Value</div>
                </div>
                <div className="table-body">
                  <DndProvider backend={HTML5Backend}>
                    <div className="draggable-table-rows">
                      {props.accounts.map((acc, i) => {
                        return (
                          <DraggableWrapper
                            type={`accounts-${props.category}`}
                            id={i}
                            key={acc.account_id}
                            index={i}
                            moveCard={(dragIndex, moveIndex) => {
                              props.onAccountMove(
                                props.category,
                                dragIndex,
                                moveIndex
                              );
                            }}
                            onDrop={props.onDragComplete}
                          >
                            <RowCard
                              key={acc.account_id}
                              account={acc}
                              title={acc.name}
                              draggable={true}
                            />
                          </DraggableWrapper>
                        );
                      })}
                    </div>
                  </DndProvider>
                  <AccountAddRow
                    connectable={IsAccountTypeConnectable(props.category)}
                    category={props.category}
                    openMenuUpwards={props.lastCategory}
                  />
                </div>
              </div>
              {shouldHighlightFirstRow() && (
                <div className="fake-tooltip">
                  <div className="arrow-up"></div>
                  <div
                    className=""
                    style={{ fontSize: "1rem", fontWeight: "bold" }}
                  >
                    Account Details
                  </div>
                  <div className="mt1" style={{ fontSize: "1rem" }}>
                    Click into your accounts to see your balance history,
                    <br /> notes, documents, collaborators, and more!
                  </div>
                  <div className="mt2">
                    <LeafButton
                      buttonStyle={LeafButtonStyle.LeafSmall}
                      buttonColour={LeafButtonColour.Black}
                      onClick={() => {
                        setHasSeenHighlightFirstRow(true);
                        props.onHighlightClear?.();
                      }}
                    >
                      GOT IT
                    </LeafButton>
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapsible>
      </div>
    </div>
  );
};
