import * as React from "react";
import { accountShortName } from "core/models/account";
import { Colour } from "core/models";
import { createSharedDataMutation } from "core/queries/shared-data";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { Icon, IconAsset } from "components/core/icons";
import { InviteNewUserButton } from "components/features/dashboard/components/invite-new-user-button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RecipientList } from "components/features/dashboard/components/manage-account-modal/recipient-list";
import { SharedSuccessToast } from "components/core/toasts/components/shared-success-toast";
import { SimpleDropDown } from "components/core/simple-drop-down";
import { TextD, TextStyle } from "components/core/text";
import { toast } from "react-toastify";
import { TrayDivider } from "components/core/tray-divider";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  AssetType,
  Collaboration,
  UserData,
} from "components/features/dashboard/models/sharing";
import {
  Asset,
  isAccount,
  isCreditHistory,
  isDocument,
  isDocumentCategory,
  isScenarioPlan,
} from "core/models/asset";
import {
  CollaborationQueryResponse,
  collaborationsQuery,
  RecipientForAssetQueryResponse,
  recipientsForAssetQuery,
} from "core/queries/collaborations";

export interface Props {
  asset: Asset;
  onAssetShared?: () => void;
  onClose?: () => void;
}

export interface Option {
  value: string;
  label: JSX.Element;
  isDisabled: boolean;
}

export interface ShareModalState {
  email: string;
  phone: string;
}

export const ShareWidget: React.FC<Props> = ({
  asset,
  onAssetShared,
  onClose,
}) => {
  let assetID: string;
  let assetType: AssetType;
  let assetName: string;

  if (isAccount(asset)) {
    assetID = asset.account_id;
    assetType = AssetType.Account;
    assetName = accountShortName(asset);
  } else if (isDocument(asset)) {
    assetID = asset.id;
    assetType = AssetType.Document;
    assetName = asset.name;
  } else if (isDocumentCategory(asset)) {
    assetID = asset.id;
    assetType = AssetType.DocumentFolder;
    assetName = asset.name;
  } else if (isScenarioPlan(asset)) {
    assetID = asset.id;
    assetType = AssetType.ScenarioPlan;
    assetName = asset.scenarioName;
  } else if (isCreditHistory(asset)) {
    assetID = asset.ownerid || "";
    assetType = AssetType.CreditHistory;
    assetName = `Credit History [${assetID}]`;
  } else {
    console.error("Unrecognized asset ", asset);
    throw new Error("Unrecognized asset ");
  }

  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const additionalRefetchQueries = onUpdateRefetchQueries ?? [];
  const { data: recipientsData, loading: recipientsLoading } =
    useQuery<RecipientForAssetQueryResponse>(recipientsForAssetQuery, {
      fetchPolicy: "cache-and-network",
      variables: {
        assetID: assetID,
        assetType: assetType,
      },
    });

  const [createSharedData, { loading: createCollabLoading }] = useMutation(
    createSharedDataMutation,
    {
      refetchQueries: [
        {
          query: recipientsForAssetQuery,
          variables: {
            assetID: assetID,
            assetType: assetType,
          },
        },
        ...additionalRefetchQueries,
      ],
      onCompleted: () => {
        toast(SharedSuccessToast(assetType.toLowerCase()));
        onAssetShared?.();
        setselectedCollaboration(null);
      },
      onError: (err) => {
        console.error("Failed to create shared data", err);
      },
    }
  );

  const {
    loading: createLoading,
    error,
    data,
  } = useQuery<CollaborationQueryResponse>(collaborationsQuery);
  const getCollabIDForRecipient = (recipientID: string) => {
    return (
      data?.collaborations.find(
        (c: Collaboration) => c.recipientID === recipientID
      )?.id ?? "missing-id"
    );
  };

  const [selectedCollaboration, setselectedCollaboration] =
    useState<Option | null>(null);

  if (createLoading || !recipientsData?.recipientsForAsset || error) {
    return null;
  }

  let recipients: UserData[] = recipientsData.recipientsForAsset
    .filter((r) => !r.isDeleted)
    .map((r) => r.user);

  const renderCollaboratorState = () => {
    const options: Option[] =
      data?.recipients?.map((r: UserData): Option => {
        const collabID = getCollabIDForRecipient(r.id);
        let labelText = r.email;

        if (r.firstName) {
          labelText = `${r.firstName} ${r.lastName}`;
        }

        const isDisabled = !!recipients.find((x) => x.id === r.id);
        let label = <div>{labelText}</div>;
        if (isDisabled) {
          label = (
            <OverlayTrigger
              placement="top-end"
              overlay={
                <Tooltip id={`shared-label-${r.id}`}>Already Shared</Tooltip>
              }
            >
              <div>{labelText}</div>
            </OverlayTrigger>
          );
        }
        return {
          value: collabID,
          label: label,
          isDisabled: isDisabled,
        };
      }) ?? [];

    return (
      <div
        className="flex-column gap-1"
        style={{
          padding: "40px",
          color: "black",
          width: "32rem",
        }}
      >
        <div className="flex-column gap-1">
          {options?.length > 0 && (
            <>
              <TextD style={TextStyle.M31B}>Select people to share with</TextD>
              <div className="share-widget__dropdown-wrapper">
                <SimpleDropDown
                  fullWidth
                  disabled={recipientsLoading || createLoading}
                  selectedValue={selectedCollaboration}
                  onSelect={(e) => {
                    if (!e) {
                      setselectedCollaboration(null);
                      return;
                    }
                    setselectedCollaboration(e);
                  }}
                  options={options}
                  toDisplay={(v): JSX.Element => {
                    return v?.label ?? <div />;
                  }}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <LeafButton
                  buttonStyle={LeafButtonStyle.LeafSmall}
                  buttonColour={LeafButtonColour.Black}
                  disabled={!selectedCollaboration}
                  loading={createCollabLoading}
                  onClick={() => {
                    if (!selectedCollaboration) {
                      return;
                    }
                    createSharedData({
                      variables: {
                        collaborationID: selectedCollaboration.value,
                        assetID: assetID,
                        assetType: assetType,
                      },
                    });
                  }}
                >
                  Share
                </LeafButton>
              </div>

              <RecipientList asset={asset} />
              <TrayDivider />
              <TextD style={TextStyle.M31B}>Invite someone new</TextD>
              <InviteNewUserButton />
            </>
          )}

          {!options?.length && (
            <div
              style={{
                height: "18rem",
                backgroundColor: "rgba(182, 224, 242, 0.3)",
                borderRadius: "10px",
                padding: "1rem",
                marginTop: "2rem",
              }}
            >
              <div
                style={{
                  height: "3rem",
                  width: "3rem",
                  backgroundColor: "white",
                  borderRadius: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Icon
                  width="24px"
                  height="24px"
                  asset={IconAsset.ShareAsset}
                  colour={Colour.Blue01}
                />
              </div>
              <div
                style={{
                  color: "var(--navy)",
                  fontSize: "1.25rem",
                  fontWeight: 700,
                  margin: "1rem 0",
                }}
              >
                {`Share ${assetType === "ACCOUNT" ? "account" : "document"}`}
              </div>
              <div
                style={{
                  color: "var(--gray-70)",
                  fontSize: "0.875rem",
                  margin: "1rem 0",
                }}
              >
                Sharing on Pylon is the easiest, safest way to collaborate on
                your finances with someone.
              </div>
              <div>
                <InviteNewUserButton />
              </div>
            </div>
          )}
        </div>
        <div
          className="flex-row gap-1"
          style={{
            paddingTop: "0.5rem",
            justifyContent: "flex-end",
          }}
        >
          <LeafButton
            onClick={() => {
              onClose?.();
            }}
            buttonStyle={LeafButtonStyle.LeafSmall}
            buttonColour={LeafButtonColour.Transparent}
          >
            Cancel
          </LeafButton>
        </div>
      </div>
    );
  };

  return renderCollaboratorState();
};
