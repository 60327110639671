import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Account, Colour } from "core/models";
import { AccountTransaction } from "core/queries/transactions";
import { AccountTransactionRow } from "components/features/dashboard/components/transactions/transaction-row";
import { Dropdown } from "react-bootstrap";
import { Icon, IconAsset } from "components/core/icons";
import { PaginatedFooter } from "core/paginated-footer";
import { PlaidWrapper } from "components/features/dashboard/components/plaid-wrapper";
import { Text, TextStyle } from "components/core/text";
import "./styles.css";

export interface Props {
  transactions: AccountTransaction[];
  showTitle?: boolean;
  showTotal?: boolean;
  subscriptionView?: boolean;
  limit?: number;
  accountID?: string;
  accountMap?: { [key: string]: Account };
  onTransactionUpdate?: () => void;
  noData?: boolean;
}

enum SortableColumn {
  Date = "date",
  Amount = "amount",
}

enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export const AccountTransactionTable: React.FC<Props> = ({
  transactions,
  subscriptionView,
  limit,
  accountID,
  accountMap,
  showTitle = true,
  showTotal = false,
  onTransactionUpdate,
  noData,
}) => {
  const total = transactions.length ?? 0;
  const editableTotal = limit ?? total;
  const [showPlaid, setShowPlaid] = React.useState<boolean>(false);
  const [sortColumn, setSortColumn] = React.useState(SortableColumn.Date);
  const [sortDirection, setSortDirection] = React.useState(SortDirection.Desc);
  const [page, setPage] = React.useState(0);

  const hasNext = total > (page + 1) * editableTotal;
  const hasPrevious = page > 0;
  const goToPage = React.useCallback((n: number) => {
    setPage(n);
  }, []);

  const [currentPageLimit, setCurrentPageLimit] = React.useState(0);

  React.useEffect(() => {
    if (currentPageLimit !== transactions.length) {
      // Reset current page when transaction changes
      setPage(0);
    }
    setCurrentPageLimit(transactions.length);
  }, [currentPageLimit, transactions]);

  const sortedTransactions = React.useMemo(() => {
    return transactions.slice().sort((a, b) => {
      let r: number = 0;
      if (sortColumn === SortableColumn.Amount) {
        r = a.amount - b.amount;
      } else if (sortColumn === SortableColumn.Date) {
        if (a.pending && !b.pending) {
          r = 1;
        } else if (!a.pending && b.pending) {
          r = -1;
        } else {
          r = a.date - b.date;
        }
      }

      if (sortDirection === SortDirection.Desc) {
        r = r * -1;
      }

      if (r === 0) {
        return a.name.localeCompare(b.name);
      }

      return r;
    });
  }, [sortColumn, sortDirection, transactions]);

  const displayedTransactions = React.useMemo(() => {
    if (!limit) {
      return sortedTransactions;
    }
    return sortedTransactions.slice(page * limit, (page + 1) * limit);
  }, [limit, page, sortedTransactions]);

  const sortDirectionIcon =
    SortDirection.Asc === sortDirection ? (
      <Icon
        asset={IconAsset.SortUp}
        width="20"
        height="20"
        colour={Colour.Text03}
      />
    ) : (
      <Icon
        asset={IconAsset.SortDown}
        width="20"
        height="20"
        colour={Colour.Text03}
      />
    );

  const selectedItemIcon = (
    <Icon
      asset={IconAsset.DropDownSelectedItem}
      width="24"
      height="24"
      colour={Colour.Black}
    />
  );
  const menuItemPlaceholder = (
    <div
      style={{
        width: "24px",
        height: "24px",
      }}
    />
  );

  console.log(accountMap);

  return (
    <div className="transactions-table">
      {showPlaid && <PlaidWrapper onClose={() => setShowPlaid(false)} />}
      {showTitle && (
        <div className="flex-row between">
          <h6>All Transactions {showTotal && `(${total})`}</h6>
        </div>
      )}
      <div className="table" style={{ minWidth: "100%" }}>
        <div className="table-head">
          <div className="table-cell transaction-column">Name</div>
          {!accountID && (
            <div className="table-cell account-column">Account</div>
          )}
          <div className="table-cell category-column">Category</div>
          <div className="table-cell recurring-column">Recurring</div>
          <div
            className={`table-cell income-column sorted-column amount-date-header flex-row`}
            style={{
              cursor: "pointer",
              overflow: "visible",
            }}
          >
            <Dropdown bsPrefix="pylon" className="dropdown">
              <DropdownToggle as="div" bsPrefix="amount-date-toggle">
                {sortColumn === SortableColumn.Amount ? "Amount" : "Date"}
                <Icon
                  asset={IconAsset.DropDown}
                  width="16"
                  height="16"
                  colour={Colour.Text03}
                />
              </DropdownToggle>
              <DropdownMenu
                className="pylon-transaction-amount-date-menu"
                onClick={(e: any) => e.stopPropagation()}
              >
                <div
                  className="amount-column-menu-item"
                  onClick={(e) => {
                    if (sortColumn !== SortableColumn.Amount) {
                      setSortColumn(SortableColumn.Amount);
                    } else if (sortDirection === SortDirection.Desc) {
                      setSortDirection(SortDirection.Asc);
                    } else {
                      setSortDirection(SortDirection.Desc);
                    }
                  }}
                >
                  {sortColumn === SortableColumn.Amount
                    ? selectedItemIcon
                    : menuItemPlaceholder}
                  <div className="amount-column-menu-item__text">Amount</div>
                </div>

                <div
                  className="amount-column-menu-item"
                  onClick={(e) => {
                    if (sortColumn !== SortableColumn.Date) {
                      setSortColumn(SortableColumn.Date);
                    } else if (sortDirection === SortDirection.Desc) {
                      setSortDirection(SortDirection.Asc);
                    } else {
                      setSortDirection(SortDirection.Desc);
                    }
                  }}
                >
                  {sortColumn === SortableColumn.Date
                    ? selectedItemIcon
                    : menuItemPlaceholder}
                  <div className="amount-column-menu-item__text">Date</div>
                </div>
              </DropdownMenu>
            </Dropdown>
            {/* exclude the direction arrow from triggering dropdown */}
            <div
              onClick={() => {
                if (sortDirection === SortDirection.Desc) {
                  setSortDirection(SortDirection.Asc);
                } else {
                  setSortDirection(SortDirection.Desc);
                }
              }}
            >
              {sortDirectionIcon}
            </div>
          </div>
        </div>
        <div className="table-body" style={{ width: "100%" }}>
          {noData && displayedTransactions.length === 0 && (
            <div
              className="flex-column"
              style={{
                width: "100%",
              }}
            >
              <div className={`table-row`}>No data available</div>
            </div>
          )}
          {!noData && displayedTransactions.length === 0 && (
            <div
              className="flex-column"
              style={{
                width: "100%",
              }}
            >
              <div className={`table-row`}>
                Connect to your
                <span
                  className={"empty-state-link"}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowPlaid(true);
                  }}
                >
                  <Text style={TextStyle.M15} colour={Colour.Blue500}>
                    credit card
                  </Text>
                </span>
                and
                <span
                  className={"empty-state-link"}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowPlaid(true);
                  }}
                >
                  <Text style={TextStyle.M15} colour={Colour.Blue500}>
                    bank accounts
                  </Text>
                </span>
              </div>
            </div>
          )}
          {displayedTransactions.map((tx, i) => {
            return (
              <AccountTransactionRow
                key={tx.id}
                account={accountID ? undefined : accountMap?.[tx.accountID]}
                transaction={tx}
                onChange={onTransactionUpdate}
                subscriptionView={subscriptionView}
              />
            );
          })}
        </div>
      </div>

      {limit && displayedTransactions.length > 0 && (
        <PaginatedFooter
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          page={page}
          totalPages={Math.ceil(total / limit)}
          goToPage={goToPage}
        />
      )}
    </div>
  );
};
