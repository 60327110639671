import * as React from "react";
import { Colour } from "core/models";
import { Text, TextType } from "components/core/text";

export enum DetailsTabs {
  Overview = "Overview",
  Notes = "Notes",
  More = "More",
}

interface Props {
  selected: DetailsTabs;
  onSelect: (e: DetailsTabs) => void;
}

export const DetailsNav: React.FC<Props> = ({ selected, onSelect }) => {
  return (
    <div
      style={{
        display: "flex",
        borderBottom: "1px solid #EEEEEE",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          marginRight: "0 1rem",
        }}
        onClick={() => {
          onSelect(DetailsTabs.Overview);
        }}
      >
        <DetailsNavItem
          text={"Overview"}
          selected={selected === DetailsTabs.Overview}
        />
      </div>
      <div
        style={{
          margin: "0 1rem",
        }}
        onClick={() => {
          onSelect(DetailsTabs.Notes);
        }}
      >
        <DetailsNavItem
          text={"Notes"}
          selected={selected === DetailsTabs.Notes}
        />
      </div>
      <div
        style={{
          marginLeft: "0 1rem",
        }}
        onClick={() => {
          onSelect(DetailsTabs.More);
        }}
      >
        <DetailsNavItem
          text={"More"}
          selected={selected === DetailsTabs.More}
        />
      </div>
    </div>
  );
};

interface NavItemProps {
  text: string;
  selected: boolean;
}
export const DetailsNavItem: React.FC<NavItemProps> = ({ text, selected }) => {
  const itemStyle = selected
    ? {
        padding: "0.875rem 0",
        borderBottom: "2px solid black",
      }
    : {
        padding: "0.875rem 0",
      };
  return (
    <div style={itemStyle}>
      <Text
        size={"15px"}
        type={TextType.Div}
        weight={600}
        colour={selected ? Colour.Black : Colour.Gray70}
      >
        {text}
      </Text>
    </div>
  );
};
