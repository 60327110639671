import * as React from "react";
import { LeafButton } from "components/core/leaf-button";
import { TaxContext } from "components/contexts/tax-context";
import { TaxIncomeEventType } from "core/queries/taxes";
import { TaxPageSection } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section";
import { TaxSectionIncomeEventRow } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/income-event-row";
import { toMoneyString } from "core/utils";

export const TaxSectionCommissionIncome: React.FC = () => {
  const {
    commissionIncomeEvents,
    saveIncomeEventFieldValue,
    totalCommissionIncome,
  } = React.useContext(TaxContext);

  return (
    <>
      {" "}
      {commissionIncomeEvents && commissionIncomeEvents.length > 0 && (
        <TaxPageSection
          localStorageKey="taxes.commissionincome"
          headerLeft="Commission Income"
          headerRight={
            <div className="table-cell">
              {toMoneyString(totalCommissionIncome)}
            </div>
          }
          bodyLeft={
            <>
              Provide any supplemental income that took the form of a
              commission.
              <br />
              <br />
              Note that while the taxes you owe on commission income is
              identical to those owed on regular income, taxes are withheld at a
              different rate.
            </>
          }
          bodyRight={
            <>
              {commissionIncomeEvents &&
                commissionIncomeEvents.length != null &&
                commissionIncomeEvents?.map((incomeEvent) => {
                  return (
                    <TaxSectionIncomeEventRow
                      key={incomeEvent.id}
                      incomeEvent={incomeEvent}
                      incomeEventType={TaxIncomeEventType.Commission}
                      fieldLabel="Commission Income"
                      tooltipText={
                        <>
                          Any income in addition to your regular income that is
                          given as proportion of a sale or other action.
                          <br />
                          <br />
                          Note that while the taxes you owe on commission income
                          is identical to those owed on regular income, taxes
                          are withheld at a different rate.
                        </>
                      }
                    />
                  );
                })}

              <div className="mt1">
                <LeafButton
                  onClick={() => {
                    saveIncomeEventFieldValue(
                      "amount",
                      "0",
                      TaxIncomeEventType.Commission
                    );
                  }}
                >
                  Add Another Commission
                </LeafButton>
              </div>
            </>
          }
        />
      )}
    </>
  );
};
