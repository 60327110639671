import Toggle from "react-toggle";
import { Account } from "core/models";
import { formatDateToUTCMMDDYY } from "components/features/dashboard/models/account-transaction";
import { MutationWrapper } from "core/queries/mutation";
import { toMoneyString } from "core/utils";
import { TransactionCategory } from "components/features/dashboard/components/transactions/transaction-category";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UserDisplayName } from "core/utils/profile";
import "./styles.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipWrapperDisplay,
} from "components/core/tooltip";
import {
  AccountTransaction,
  RecurringExpenseType,
  UpdateAccountTransactionMutationInput,
  UpdateAccountTransactionResponse,
  UPDATE_TRANSACTION,
} from "core/queries/transactions";

export interface Props {
  transaction: AccountTransaction;

  subscriptionView?: boolean;
  account?: Account;
  onChange?: () => void;
}

export const AccountTransactionRow: React.FC<Props> = ({
  transaction,
  subscriptionView = false,
  account,

  onChange,
}) => {
  const [updateTransaction, { loading }] = useMutation<
    UpdateAccountTransactionResponse,
    MutationWrapper<UpdateAccountTransactionMutationInput>
  >(UPDATE_TRANSACTION, {
    onCompleted: () => {
      onChange?.();
    },
  });

  const [isRecurring, setIsRecurring] = useState(
    SubscriptionRecurringExpenseTypes.includes(transaction.recurringExpenseType)
  );
  useEffect(() => {
    const newIsRecuring = SubscriptionRecurringExpenseTypes.includes(
      transaction.recurringExpenseType
    );
    if (isRecurring !== newIsRecuring) {
      setIsRecurring(newIsRecuring);
    }
    // Only recompute the cached value when transaction changes.
    // Ignore changes of cached values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const toggleRecurring = () => {
    if (loading) {
      return;
    }

    const newVal = isRecurring
      ? RecurringExpenseType.None
      : RecurringExpenseType.Subscription;
    setIsRecurring(!isRecurring);
    updateTransaction({
      variables: {
        input: {
          accountID: transaction.accountID,
          transactionID: transaction.transactionID,
          recurringExpenseType: newVal,
        },
      },
    });
  };

  const formattedAmt = toMoneyString(-transaction.amount);

  return (
    <div
      className="flex-column"
      style={{
        width: "100%",
      }}
    >
      <div className={`table-row`}>
        <div className="table-cell transaction-column ellipsis flex-row">
          <PylonTooltip
            fullWidth
            text={transaction.name ?? transaction.merchantName}
            spacingSide={SpacingSide.None}
            wrapperDisplay={TooltipWrapperDisplay.None}
          >
            <div className="ellipsis ">
              ({UserDisplayName(account?.owner)}){" "}
              <b>{transaction.merchantName ?? transaction.name}</b>
            </div>
          </PylonTooltip>
        </div>

        {account && (
          <div className="table-cell account-column">
            <div className="ellipsis">{account?.name}</div>
            <div>{account?.mask}</div>
          </div>
        )}

        <div className="table-cell category-column">
          <TransactionCategory
            disabled={loading || transaction.pending}
            transaction={transaction}
            onChange={onChange}
          />
        </div>
        <div className="table-cell recurring-column">
          <Toggle
            className="transaction-row-exclude-toggle"
            disabled={loading || transaction.pending}
            icons={false}
            checked={isRecurring}
            onChange={toggleRecurring}
          />
        </div>
        <div className={"table-cell income-column ellipsis"}>
          <div className={(transaction.amount || 0) < 0 ? "income" : ""}>
            {formattedAmt}
          </div>
          {transaction.pending ? (
            <div className="overline">PENDING</div>
          ) : (
            <div
              className="flex-row label-small"
              style={{
                justifyContent: "flex-end",
              }}
            >
              {formatDateToUTCMMDDYY(new Date(transaction.date || ""))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SubscriptionRecurringExpenseTypes = [
  // RecurringExpenseType.Recurring,
  RecurringExpenseType.Subscription,
];
