import _ from "lodash";
import React, { useState } from "react";
import { ApolloError, useMutation } from "@apollo/client";
import { AssetType } from "components/features/dashboard/models/sharing";
import { CollapsibleSection } from "components/core/collapsible-section";
import { Colour } from "core/models";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentTags } from "components/features/dashboard/components/document-tags";
import { extension } from "mime-types";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { recipientsForAssetQuery } from "core/queries/collaborations";
import { TextD, TextStyle } from "components/core/text";
import { toast } from "react-toastify";
import { UserDisplayName } from "core/utils/profile";
import {
  AccountInformationValueCard,
  ValueCardType,
} from "components/features/dashboard/components/manage-account-modal/details/detail-card";
import {
  ADD_DOCUMENT_TAG,
  CategoryData,
  FETCH_DOCUMENT_CATEGORIES,
  REMOVE_DOCUMENT_TAG,
} from "core/queries/documents";

interface Props {
  document: DocumentData;
}
export const DisplayDocumentDetails: React.FC<Props> = ({ document }) => {
  const errHandler: (error: ApolloError) => void = (err) => {
    toast(
      <PylonToastBody
        title={"Unexpected Error Occurred"}
        body={`${err.message}`}
        type={ToastType.Error}
      />
    );
  };

  const [addTagMutation, { loading }] = useMutation(ADD_DOCUMENT_TAG, {
    onError: errHandler,
    refetchQueries: [
      {
        query: recipientsForAssetQuery,
        variables: {
          assetID: document.id,
          assetType: AssetType.Document,
        },
      },
      { query: FETCH_DOCUMENT_CATEGORIES },
    ],
  });

  const [removeTagMutation, { loading: removeTagLoading }] = useMutation(
    REMOVE_DOCUMENT_TAG,
    {
      onError: errHandler,
    }
  );

  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <div>
      <CollapsibleSection
        title="Details"
        stateKey={DOCUMENT_DETAILS_COLLAPSIBLE_KEY}
      >
        <div className="flex-column">
          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Original Name"
            value={document.filename}
          />

          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Type"
            value={
              extension(document.mimeType).toString().toUpperCase() || "unknown"
            }
          />

          <TextD style={TextStyle.M15} colour={Colour.Black}>
            <div className="account-detail__detail-row">
              <div className="account-detail__detail-label">Connected to</div>
              <div className="account-detail__detail-value">
                <DocumentTags
                  assets={document.categories ?? []}
                  sectionWrapperClassName="document-row__section-wrapper"
                  tagsWrapperClassName="document-row__tag-wrapper"
                  onAdd={(folders) => {
                    folders.forEach((x: CategoryData) =>
                      addTagMutation({
                        variables: {
                          documentID: document.id,
                          categoryData: {
                            id: x.id,
                            type: x.type,
                          },
                        },
                      })
                    );
                  }}
                  onRemove={(acc) =>
                    removeTagMutation({
                      variables: {
                        documentID: document.id,
                        categoryData: {
                          id: acc.id,
                          type: acc.type,
                        },
                      },
                    })
                  }
                  disabled={loading || removeTagLoading}
                />
              </div>
            </div>
          </TextD>
          {!document.isOwner && (
            <AccountInformationValueCard
              type={ValueCardType.String}
              title="Owned by"
              value={UserDisplayName(document.owner)}
            />
          )}
          <DocumentRecipientCard document={document} />
          {/* <div
            className="flex-row"
            style={{ justifyContent: "flex-end", paddingTop: "0.5rem" }}
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            Edit document
          </div> */}
        </div>
        {/* <EditAccountInformationModal
          show={showEditModal}
          onClose={() => {
            setShowEditModal(false);
          }}
          account={account}
          isConnected={props.isConnected}
        /> */}
      </CollapsibleSection>
    </div>
  );
};

export const DocumentRecipientCard: React.FC<{
  document: DocumentData;
}> = ({ document }) => {
  // Only owner should see the recipient list
  if (!document.isOwner || _.isEmpty(document.recipients)) {
    return null;
  }

  return (
    <TextD style={TextStyle.M15} colour={Colour.Black}>
      <div className="account-detail__detail-row">
        <div className="account-detail__detail-label">Who has access</div>
        <div className="account-detail__detail-value flex-column">
          {document.recipients?.map((r) => (
            <div key={r.id}>{UserDisplayName(r)}</div>
          ))}
        </div>
      </div>
    </TextD>
  );
};

const DOCUMENT_DETAILS_COLLAPSIBLE_KEY =
  "document_details-details--collapsible";
