import React from "react";
import "./styles.css";

type InputProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

export type Props = InputProps;

export const TextArea: React.FC<Props> = (props) => {
  return (
    <textarea {...props} className={`pylon-textarea ${props.className}`} />
  );
};
