import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { FC, useRef } from "react";
import { XYCoord } from "dnd-core";

export const RowCardType = "rowCard";

const style = {};

export interface CardProps {
  id: any;
  index: number;

  type: string;

  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDrop: (item: DragItem) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DraggableWrapper: FC<CardProps> = ({
  id,
  index,
  type,
  moveCard,
  children,
  onDrop,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    drop(item: DragItem) {
      onDrop(item);
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (
        dragIndex < hoverIndex &&
        hoverClientY < hoverMiddleY - hoverMiddleY * 0.33
      ) {
        return;
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY + hoverMiddleY * 0.33
      ) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.33 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      style={{ ...style, opacity, height: "fit-content" }}
      data-handler-id={handlerId}
    >
      {children}
    </div>
  );
};
