import React from "react";
import { LoginContextProvider } from "components/contexts/login-context-provider";
import { PublicMobileNavigation } from "components/features/login/components/mobile-navigation";
import { renderLoginRoutes } from "./pages";
import { Switch } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import { WithBackground } from "components/features/public/with-background";
import "./styles.css";

export const LoginComponent: React.FC = () => {
  const mobileLayout = useMobileLayout();
  return (
    <LoginContextProvider>
      {mobileLayout && <PublicMobileNavigation redirectToHome={true} />}
      <WithBackground>
        <Switch>{renderLoginRoutes()}</Switch>
      </WithBackground>
    </LoginContextProvider>
  );
};
