import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Colour } from "core/models";
import { ConfirmationModal, ConfirmationModalOld } from "components/core/modal";
import { Dropdown } from "react-bootstrap";
import { DropDownMenu } from "components/core/drop-down-menu";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { Icon, IconAsset } from "components/core/icons";
import { TempAccountTypes } from "components/features/dashboard/components/add-account-tray/models";
import { Text, TextType } from "components/core/text";
import { useClickOutDetector } from "core/hooks/click-out";

interface Props {
  onSelect: (key: string) => void;
  onClose: () => void;
}

interface ButtonProps {
  existingCategories: string[];
  isExample: boolean;
  onAdd: (category: string) => void;
  onRemove: () => void;
}
export const EditCategoryButton: React.FC<ButtonProps> = ({
  existingCategories,
  onAdd,
  onRemove,
  isExample,
}) => {
  const categories = Object.values(TempAccountTypes);
  const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false);
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
        marginRight: "-12px",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showRemoveModal}
        children={
          <>
            {isExample ? (
              <Text weight={600}>
                Removing this section will hide example data from your account
                list. You can get it back anytime by adding this section again.
              </Text>
            ) : (
              <Text weight={600}>
                Are you sure you want to remove this section? All accounts will
                be permanently deleted.
              </Text>
            )}
          </>
        }
        onAction={() => {
          onRemove();
          setShowRemoveModal(false);
        }}
        actionButtonText={"Remove"}
        onCancel={() => {
          setShowRemoveModal(false);
        }}
        cancelButtonText="Cancel"
      />

      <ConfirmationModal
        width={"30rem"}
        onClose={() => setShowAddModal(false)}
        showModal={showAddModal}
        children={
          <div style={{ marginBottom: "2rem" }}>
            <Text weight={600}>Add Account Category</Text>
            <div
              style={{
                height: "1px",
                width: "100%",
                borderTop: "1px solid #EEEEEE",
              }}
            ></div>
            {categories.map((e) => {
              const existing = existingCategories.includes(e);
              return (
                <div
                  key={e}
                  style={{
                    borderBottom: "1px solid #EEEEEE",
                    padding: "1rem 0",
                    cursor: !existing ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (!existing) {
                      onAdd(e);
                      setShowAddModal(false);
                    }
                  }}
                >
                  <Text
                    type={TextType.Div}
                    colour={existing ? Colour.Gray10 : Colour.Black}
                  >
                    {e}
                  </Text>
                </div>
              );
            })}
          </div>
        }
        onCancel={() => {
          setShowAddModal(false);
        }}
        cancelButtonText="Cancel"
      />

      <Dropdown className="asset-note__menu" bsPrefix="pylon">
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          <Icon asset={IconAsset.MenuKebab} height="24px" width="24px" />
        </DropdownToggle>
        <DropdownMenu
          className="asset-note__menu-item-wrapper"
          style={{ boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)" }}
        >
          <EditCategoryMenu
            onClose={() => {}}
            onSelect={(s) => {
              switch (s) {
                case "remove":
                  setShowRemoveModal(true);
                  break;
                case "add":
                  setShowAddModal(true);
                  break;
                default:
                  break;
              }
            }}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
export const EditCategoryMenu: React.FC<Props> = ({ onSelect, onClose }) => {
  const clickOutRef = useClickOutDetector<HTMLDivElement>(onClose);
  return (
    <div
      ref={clickOutRef}
      style={{
        width: "15rem",
        position: "absolute",
        zIndex: 1000,
        cursor: "pointer",
        backgroundColor: "white",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
        borderRadius: "2px",
        top: "0",
        right: "0",
      }}
    >
      <DropDownMenu>
        <DropDownMenuItem
          key={"remove"}
          text={
            <>
              <Icon asset={IconAsset.MenuRemove} height="24px" width="24px" />{" "}
              Remove
            </>
          }
          onClick={() => {
            onSelect("remove");
            clickOutRef?.current?.click();
          }}
        />
      </DropDownMenu>
    </div>
  );
};
