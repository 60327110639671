import * as React from "react";
import qs from "qs";
import { Account, Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentRowCard } from "components/features/dashboard/components/document-row-card";
import { FETCH_ALL_COLLABORATIONS } from "core/queries/collaborations";
import { Icon, IconAsset } from "../../../../core/icons";
import { InviteNewUserButton } from "components/features/dashboard/components/invite-new-user-button";
import { RowCard } from "components/features/dashboard/components/row-card";
import { SessionContext } from "components/contexts/session-context-provider";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import { UserData } from "../../models/sharing";
import "./styles.css";
import {
  CollaborationDirection,
  CollaboratorFilter,
} from "../../components/collaborator-filter";

interface Props {}

export enum SharedAssetType {
  ACCOUNT = "ACCOUNT",
  DOCUMENT = "DOCUMENT",
}

export interface SharedData {
  assetID: string;
  assetType: SharedAssetType;
  document?: DocumentData;
  account?: Account;
}

interface CollaborationResponse {
  collaborations: {
    id: string;
    ownerID: string;
    recipientID: string;
    createdAt: string;
    sharedData: SharedData[];
  }[];
  collaborationsWithMe: {
    id: string;
    ownerID: string;
    recipientID: string;
    createdAt: string;
    sharedData: SharedData[];
  }[];
}

export const Collaborations: React.FC<Props> = () => {
  const { setOnUpdateRefetchQueries } = React.useContext(DataPageContext);
  const [filteringOnUserID, setFilteringOnUser] = React.useState<string>("");
  const [filteringType, setFilteringType] = React.useState<string>("");
  const { userID } = React.useContext(SessionContext.context);
  const { data, loading } = useQuery<CollaborationResponse>(
    FETCH_ALL_COLLABORATIONS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const history = useHistory();
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  React.useEffect(() => {
    setOnUpdateRefetchQueries([{ query: FETCH_ALL_COLLABORATIONS }]);

    // Only set once on coomponent mount; this does not depend on anything else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDocumentID = query["documentID"] as string;

  if (!data) {
    return null;
  }

  const onFilter = (collaborator?: UserData, type?: string) => {
    setFilteringOnUser(collaborator?.id || "");
    setFilteringType(type || "");
  };

  const sharedDataWithDuplicates = data?.collaborations
    ?.flatMap((x) => x.sharedData)
    ?.concat(data?.collaborationsWithMe?.flatMap((x) => x.sharedData) ?? []);

  const sharedData: SharedData[] = [];
  sharedDataWithDuplicates?.forEach((sd) => {
    if (!sd) return;
    const asset = sd.account || sd.document;
    if (filteringOnUserID) {
      if (filteringType === CollaborationDirection.Outoing) {
        const recipient = asset?.recipients?.find((r) => {
          return r.id === filteringOnUserID;
        });
        if (!recipient) {
          return;
        }
      } else if (asset?.owner?.id !== filteringOnUserID) {
        return;
      }
    } else if (filteringType === CollaborationDirection.Outoing) {
      const recipient = asset?.recipients?.find((r) => {
        return r.id !== userID;
      });
      if (!recipient) {
        return;
      }
    }
    sharedData.push(sd);
  });

  const renderSharedAccounts = (sd: SharedData[]) => {
    const accounts = [
      ...new Set(
        sd
          .filter(
            (data) => data.assetType === SharedAssetType.ACCOUNT && data.account
          )
          .map((x) => x.account)
      ),
    ];

    if (accounts.length > 0) {
      return (
        <>
          <div>
            <div className="sharing-type-title">Accounts</div>
          </div>
          {accounts.map((acc: Account | undefined, i: number) => {
            if (!acc) {
              return null;
            }
            return (
              <RowCard key={acc.account_id} account={acc} title={acc.name} />
            );
          })}
        </>
      );
    }

    return null;
  };

  const renderSharedDocuments = (state: SharedData[]) => {
    const documents = [
      ...new Set(
        state
          .filter(
            (data) =>
              data.assetType === SharedAssetType.DOCUMENT && data.document
          )
          .map((x) => x.document)
      ),
    ];

    if (documents.length > 0) {
      return (
        <>
          <div className="sharing-section">
            <div className="sharing-type-title">Documents</div>
          </div>
          {documents.map((document: DocumentData | undefined) => {
            if (!document) {
              return null;
            }
            return (
              <DocumentRowCard
                key={document.id}
                document={document}
                open={document.id === openDocumentID}
                onOpen={() => {
                  const search = qs.stringify({
                    documentID: document.id,
                  });
                  history.push(`${DashboardPages.Collaborations}?${search}`);
                }}
                onClose={() => {
                  history.push(DashboardPages.Collaborations);
                }}
              />
            );
          })}
        </>
      );
    }

    return null;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 1rem",
          marginBottom: "1rem",
          alignItems: "center",
        }}
      >
        <div>
          <h4 className="title">
            Shared
            <Icon
              asset={IconAsset.Dot}
              width="8px"
              height="8px"
              colour={Colour.Blue01}
            />
          </h4>
        </div>
        <CollaboratorFilter
          onClick={(collaborator, type) => onFilter(collaborator, type)}
        />
      </div>
      {sharedData.length > 0 && (
        <>
          {renderSharedAccounts(sharedData)}
          {renderSharedDocuments(sharedData)}
        </>
      )}
      {!loading && sharedData.length === 0 && filteringOnUserID && (
        <div className="nothing-here">
          <p>There isn't anything currently shared with this person</p>
        </div>
      )}

      {!loading && sharedData.length === 0 && !filteringOnUserID && (
        <div className="nothing-here">
          <p>You currently haven’t shared anything</p>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "1rem",
              }}
            >
              <InviteNewUserButton />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
