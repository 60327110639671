import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Dropdown } from "react-bootstrap";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import {
  OwnerTypeKey,
  SummaryContext,
} from "components/contexts/summary-context";
interface Props {
  alignLeft?: boolean;
}

export const NetworthAccountOwnerFilter: React.FC<Props> = ({ alignLeft }) => {
  const { ownerType, setOwnerType } = React.useContext(SummaryContext);

  const alignmentStyle = {
    left: alignLeft ? "0" : undefined,
    right: alignLeft ? undefined : "0",
  };
  return (
    <Dropdown alignRight={!alignLeft}>
      <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
        <PylonDropDownToggle text={nameFromOwnerType(ownerType)} />
      </DropdownToggle>
      <DropdownMenu align={"left"}>
        <div
          style={{
            width: "15rem",
            position: "absolute",
            zIndex: 1000,
            cursor: "pointer",
            backgroundColor: "white",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
            borderRadius: "2px",
            top: "0",
            ...alignmentStyle,
          }}
        >
          <DropDownMenuItem
            text={nameFromOwnerType(OwnerTypeKey.Me)}
            onClick={() => {
              // clickOutRef?.current?.click();
              setOwnerType(OwnerTypeKey.Me);
              document.dispatchEvent(new MouseEvent("click"));
            }}
          />
          <DropDownMenuItem
            text={nameFromOwnerType(OwnerTypeKey.Spouse)}
            onClick={() => {
              setOwnerType(OwnerTypeKey.Spouse);
              document.dispatchEvent(new MouseEvent("click"));
            }}
          />
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

const nameFromOwnerType = (t: OwnerTypeKey) => {
  switch (t) {
    case OwnerTypeKey.Any: {
      return "All (Includes Shared Accounts)";
    }
    case OwnerTypeKey.Spouse: {
      return "Mine and Spouse/Partner";
    }
    case OwnerTypeKey.Me: {
      return "My Accounts Only";
    }
    default:
      return "Unknown";
  }
};
