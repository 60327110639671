import * as React from "react";
import { CategoryData } from "../../../../../core/queries/documents";
import { DocumentUploadComponent } from "components/features/dashboard/components/add-document-tray/document-upload-component";
import { gql, useMutation } from "@apollo/client";
import { progressQuery } from "core/queries/overview";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { toast } from "react-toastify";
import { UploadFileDropzone } from "./upload-file-dropzone";
import { useState } from "react";
import { validDocumentName } from "core/utils";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

export interface Props {
  defaultCategory?: CategoryData;
  suggestionItemID?: string;
  onUpload?: () => void;
}

interface UploadDocumentResponse {
  uploadDocument: {
    document: {
      id: string;
    };
  };
}

const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation uploadDocument(
    $documentName: String!
    $file: Upload!
    $categories: [DocumentCategoryInput!]
    $suggestionItemID: String
    $suggestedFolderID: String
  ) {
    uploadDocument(
      documentName: $documentName
      file: $file
      categories: $categories
      suggestionItemID: $suggestionItemID
      suggestedFolderID: $suggestedFolderID
    ) {
      document {
        id
      }
    }
  }
`;

export interface UploadFileProps {
  id: string;
  documentName: string;
  file: File;
  categories: CategoryData[];
  documentID?: string;
  suggestionItemID?: string;
}

export const AddDocumentTray: React.FC<Props> = (props: Props) => {
  const [counter, setCounter] = useState(1);
  const [files, setFiles] = useState<UploadFileProps[]>([]);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadDocument, { loading }] = useMutation<UploadDocumentResponse>(
    UPLOAD_DOCUMENT_MUTATION,
    {
      refetchQueries: [{ query: progressQuery }],
    }
  );
  const disabled =
    loading ||
    files.length === 0 ||
    !files
      .map((x) => validDocumentName(x.documentName))
      ?.reduce((x, y) => x && y);

  const filesToUploadComps = files.map((x, i) => {
    return (
      <DocumentUploadComponent
        key={x.id}
        fileToAdd={x}
        unremovableTagID={
          props.suggestionItemID ? props.defaultCategory?.id : undefined
        }
        disabled={loading || hasUploaded}
        onRemove={() => {
          setFiles(files.filter((y) => y.id !== x.id));
        }}
        onChange={(newFile) => {
          const newFiles = [...files];
          newFiles[i] = { ...newFile };
          setFiles(newFiles);
        }}
      />
    );
  });

  const uploadAllFiles = async () => {
    setHasUploaded(true);

    const newFiles = [...files];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const resp = await uploadDocument({
        variables: {
          file: file.file,
          documentName: file.documentName,
          suggestionItemID: props.suggestionItemID,
          suggestedFolderID: props.defaultCategory?.id,
          categories: file.categories.map((c) => {
            return {
              id: c.id,
              type: c.type,
              name: c.name,
            };
          }),
        },
      });

      newFiles[i] = {
        ...file,
        documentID: resp.data?.uploadDocument?.document?.id,
      };
    }
    setFiles(newFiles);

    toast(
      <PylonToastBody
        title={`Successfully uploaded documents!`}
        body={`Your documents are now safe and sound and always available.`}
      />
    );

    props.onUpload?.();
  };

  return (
    <div>
      {filesToUploadComps}
      <div className="add-document-tray__content">
        <div className="add-document-tray__step-selection">
          {!loading && !hasUploaded && (
            <form className="add-document-tray__upload-form">
              <UploadFileDropzone
                allowMulti
                onChange={(toAdd: File[]) => {
                  let j = counter;
                  let newFiles = files;

                  toAdd.forEach((x) => {
                    const id = j++;

                    newFiles = [
                      ...newFiles,
                      {
                        id: `${id}`,
                        file: x,
                        categories: props.defaultCategory
                          ? [
                              {
                                id: props.defaultCategory.id,
                                type: props.defaultCategory.type,
                                name: props.defaultCategory.name,
                              },
                            ]
                          : [],
                        documentName: `${x.name
                          ?.split(".")
                          .slice(0, -1)
                          .join(".")}`,
                      },
                    ];
                  });

                  setFiles(newFiles);
                  setCounter(counter + toAdd.length);
                }}
              />
            </form>
          )}
          {files.length > 0 && (
            <div className="add-document-tray__step-continue-button-container">
              <LeafButton
                disabled={disabled}
                loading={loading}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  if (hasUploaded) {
                    setFiles([]);
                    setCounter(1);
                    setHasUploaded(false);
                  } else {
                    uploadAllFiles();
                  }
                }}
                buttonStyle={LeafButtonStyle.LeafSmall}
                buttonColour={LeafButtonColour.Black}
              >
                {hasUploaded ? "Upload more documents" : "Upload document"}
              </LeafButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
