import * as React from "react";
import { ChartCreditHistory } from "components/features/dashboard/components/credit-history-chart-daily";
import { CreditSummarySection } from "components/features/dashboard/components/credit-summary-section";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { SummaryContextProvider } from "components/contexts/summary-context";

export const CreditPage: React.FC = () => {
  return (
    <PageWrapper
      header={
        <PageTitle
          title="Credit"
          subtitle="Track your credit history for free without any impact on your credit score."
        />
      }
    >
      <SummaryContextProvider>
        <ChartCreditHistory />
        <CreditSummarySection showUtilizationDetails={true} />
      </SummaryContextProvider>
    </PageWrapper>
  );
};
