import * as React from "react";
import { LoginComponent } from "./component";
import { PublicHeader } from "components/features/public/header";
import { useMobileLayout } from "core/hooks/responsive";

export const Login: React.FC<{}> = () => {
  const mobileLayout = useMobileLayout();
  return (
    <div>
      {!mobileLayout && <PublicHeader hideIcon noLoginLink />}
      <LoginComponent />
    </div>
  );
};
