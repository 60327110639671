import React, { useState } from "react";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { addYears } from "date-fns";
import { formatOptionalStringNumber } from "core/utils";
import { ModalDateInput } from "components/core/input/modal-date-input";
import { ModalInput } from "components/core/input/modal-input";
import { PylonALink } from "components/core/alink";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { shallowEquals } from "core/utils/compare";
import { Text, TextStyle, TextType } from "components/core/text";
import "./styles.css";
import {
  Account,
  CopyOptionalIntegratedFields,
  RentalPropertyDetails,
} from "core/models";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const ManageRentalPropertyDetails: React.FC<Props> = ({
  account,
  isConnected,
  displayOnly,
  onUpdate,
}) => {
  const inputDetails = account.details?.rentalPropertyDetails;
  const [edited, setEdited] = useState(false);

  const [details, setDetails] = useState<RentalPropertyDetails>({
    estimatedValue: inputDetails?.estimatedValue,

    monthlyPayment: inputDetails?.monthlyPayment,
    purchaseDate: inputDetails?.purchaseDate,
    // Treat null as undefined for currency input
    originalBalance: CopyOptionalIntegratedFields(
      inputDetails?.originalBalance
    ),
    interestRate: CopyOptionalIntegratedFields(inputDetails?.interestRate),
    term: CopyOptionalIntegratedFields(inputDetails?.term),
    address: CopyOptionalIntegratedFields(inputDetails?.address),
    dueDate: inputDetails?.dueDate,

    monthlyIncome: inputDetails?.monthlyIncome,
    monthlyExpenses: inputDetails?.monthlyExpenses,
  });

  const onBlurUpdate = () => {
    if (edited) {
      setEdited(false);
      onUpdate?.({
        details: {
          rentalPropertyDetails: details,
        },
      });
    }
  };

  const updateDetails = (newDetails: RentalPropertyDetails) => {
    if (!shallowEquals(details, newDetails)) {
      setDetails(newDetails);

      if (!edited) {
        setEdited(true);
      }
    }
  };

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Estimated Value"
          value={account.details?.rentalPropertyDetails?.estimatedValue}
        />
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Original Loan Amount"
          value={account.details?.rentalPropertyDetails?.originalBalance?.value}
        />
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Monthly Payment"
          value={account.details?.rentalPropertyDetails?.monthlyPayment}
        />
        <AccountInformationValueCard
          type={ValueCardType.Percentage}
          title="Interest Rate"
          value={account.details?.rentalPropertyDetails?.interestRate?.value}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Terms"
          value={account.details?.rentalPropertyDetails?.term?.value}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Address"
          value={account.details?.rentalPropertyDetails?.address?.value}
        />

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Due Date"
          value={account.details?.rentalPropertyDetails?.dueDate}
        />

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Purchase Date"
          value={account.details?.rentalPropertyDetails?.purchaseDate}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Monthly Income"
          value={account.details?.rentalPropertyDetails?.monthlyIncome}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Monthly Expense"
          value={account.details?.rentalPropertyDetails?.monthlyExpenses}
        />
      </>
    );
  }

  return (
    <>
      <ModalInput
        id="account-estimated-value"
        type="money"
        label={"Estimated Value"}
        value={details.estimatedValue}
        placeholder="$650,000"
        onChange={(v) => {
          updateDetails({
            ...details,
            estimatedValue: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />
      <Text type={TextType.Div} style={TextStyle.Hint}>
        Not sure? Try{" "}
        <PylonALink
          href="https://www.redfin.com/what-is-my-home-worth"
          target="_blank"
        >
          Redfin{" "}
        </PylonALink>
        or{" "}
        <PylonALink
          href="https://www.zillow.com/how-much-is-my-home-worth/"
          target="_blank"
        >
          Zillow{" "}
        </PylonALink>
        to get an estimate
      </Text>

      {!details.originalBalance?.isIntegrated && (
        <ModalInput
          id="account-original-loan-amount"
          type="money"
          label={"Original Loan Amount"}
          value={formatOptionalStringNumber(details.originalBalance?.value)}
          placeholder="$500,000"
          onChange={(v) => {
            updateDetails({
              ...details,
              originalBalance: {
                value: sanitizeStringNumberToString(v),
                isIntegrated: false,
              },
            });
          }}
          onBlur={onBlurUpdate}
        />
      )}

      <ModalInput
        id="account-monthly-payment"
        type="money"
        label={"Monthly Payment"}
        value={formatOptionalStringNumber(details.monthlyPayment)}
        placeholder="$2,200"
        onChange={(v) => {
          updateDetails({
            ...details,
            monthlyPayment: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      {!details.interestRate?.isIntegrated && (
        <ModalInput
          id="account-interest-rate"
          type="percentage"
          label={"Interest Rate"}
          value={formatOptionalStringNumber(details.interestRate?.value)}
          placeholder="5%"
          onChange={(v) => {
            updateDetails({
              ...details,
              interestRate: {
                value: sanitizeStringNumberToString(v),
                isIntegrated: false,
              },
            });
          }}
          onBlur={onBlurUpdate}
        />
      )}

      {!details.term?.isIntegrated && (
        <ModalInput
          id="account-term"
          type="string"
          label={"Term (years)"}
          value={details.term?.value ?? ""}
          placeholder="30"
          onChange={(v) => {
            updateDetails({
              ...details,
              term: {
                value: v,
                isIntegrated: false,
              },
            });
          }}
          onBlur={onBlurUpdate}
        />
      )}

      {!details.address?.isIntegrated && (
        <ModalInput
          id="account-address"
          type="string"
          label={"Address"}
          value={details.address?.value ?? ""}
          placeholder="1600 Pennsylvania Avenue NW, Washington, DC 20500, USA"
          onChange={(v) => {
            updateDetails({
              ...details,
              address: {
                value: v,
                isIntegrated: false,
              },
            });
          }}
          onBlur={onBlurUpdate}
        />
      )}

      <ModalDateInput
        id="account-purchase-date"
        label={"Purchase Date"}
        placeholderText="01/20/2020"
        minDate={addYears(new Date(), -50)}
        maxDate={new Date()}
        selected={details.purchaseDate ? new Date(details.purchaseDate) : null}
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...details,
              purchaseDate: date?.toUTCString() ?? undefined,
            };

            setDetails(newDetails);
            onUpdate?.({
              details: {
                rentalPropertyDetails: newDetails,
              },
            });
          }
        }}
      />

      <ModalDateInput
        id="account-due-date"
        label={"Due Date"}
        placeholderText="01/20/2030"
        minDate={new Date()}
        maxDate={addYears(new Date(), 35)}
        selected={details.dueDate ? new Date(details.dueDate) : null}
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...details,
              dueDate: date?.toUTCString() ?? undefined,
            };

            setDetails(newDetails);
            onUpdate?.({
              details: {
                rentalPropertyDetails: newDetails,
              },
            });
          }
        }}
      />

      <ModalInput
        id="account-monthly-income"
        type="money"
        label={"Monthly Income"}
        value={formatOptionalStringNumber(details.monthlyIncome)}
        placeholder="$2,000"
        onChange={(v) => {
          updateDetails({
            ...details,
            monthlyIncome: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-monthly-expense"
        type="money"
        label={"Monthly Expenses"}
        value={formatOptionalStringNumber(details.monthlyExpenses)}
        placeholder="$1,000"
        onChange={(v) => {
          updateDetails({
            ...details,
            monthlyExpenses: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <div className="highlight mt1" style={{ padding: "2rem" }}>
        To automatically view monthly income and expenses for this account with
        your cashflows, make sure the account in which you receive income and
        pay expenses is connected on Pylon.
      </div>
    </>
  );
};
