import _ from "lodash";
import Illustration from "./house-illustration.svg";
import { Colour } from "core/models";
import { PlanContext } from "components/contexts/plan-context-provider";
import { PlanDataSerializer } from "components/features/dashboard/components/planning/calculators/data-serializer";
import { PlanOptions } from "components/features/dashboard/components/planning/models";
import { PlanTitle } from "components/features/dashboard/components/planning/calculators/plan-title";
import { PylonCurrencyInput } from "components/core/currency-input";
import { SavedScenario } from "components/features/dashboard/components/planning/saved-scenario";
import { ScenarioPlan } from "core/queries/scenario-plans";
import { SerializablePlan } from "components/features/dashboard/components/planning/calculators/models";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs
  } from "react-tabs";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { toMoneyStringNoDecimal } from "core/utils";
import { useContext, useState } from "react";
import "./styles.css";
import {
  CashflowResult,
  ExpenseResult,
  ScenarioResultScreen,
} from "components/features/dashboard/components/planning/calculators/result-screen";

enum HouseCalculationType {
  Monthly = "Monthly",
  Guided = "Guided",
}

const TabIndex: Record<HouseCalculationType, number> = {
  [HouseCalculationType.Monthly]: 0,
  [HouseCalculationType.Guided]: 1,
};
const IndexToType: Record<number, HouseCalculationType> = {
  0: HouseCalculationType.Monthly,
  1: HouseCalculationType.Guided,
};

type HouseData = {
  totalCost?: string;
  downPayment?: string;
  term?: string;
  interestRate?: string;
  remainingCashflow?: string;
  type?: HouseCalculationType;
} & SerializablePlan;

const getDefaultType = (type?: HouseCalculationType) => {
  if (type && Object.values(HouseCalculationType).includes(type)) {
    return type;
  }

  return HouseCalculationType.Monthly;
};

export const HouseCalculator: React.FC = () => {
  const { householdIncome, monthlyCashflow } = useContext(PlanContext);

  const getDefaultScenarioData = () => {
    return {
      totalCost: "825000",
      downPayment: "165000",
      term: "30",
      interestRate: "3.25",
      remainingCashflow: "1000",
      type: HouseCalculationType.Monthly,
    };
  };

  const [scenario, setScenario] = useState<Partial<HouseData>>(
    getDefaultScenarioData()
  );

  const [savedScenario, setSavedScenario] = useState<ScenarioPlan | undefined>(
    undefined
  );

  const type = getDefaultType(scenario.type);

  let monthlyPayment: number | undefined = undefined;
  let calculatedMonthlyCashflow: number | undefined = undefined;
  let calculatedHouseCost: number | undefined = undefined;

  if (
    type === HouseCalculationType.Monthly &&
    !_.isNil(scenario.totalCost) &&
    !_.isNil(scenario.downPayment) &&
    !_.isNil(scenario.term) &&
    !_.isNil(scenario.interestRate)
  ) {
    const cost = Number.parseFloat(scenario.totalCost);
    const down = Number.parseFloat(scenario.downPayment);
    const parsedTerm = Number.parseFloat(scenario.term);
    const rate = Number.parseFloat(scenario.interestRate);

    const amountBorrowed = cost - down;
    if (parsedTerm > 0 && rate >= 0 && amountBorrowed > 0) {
      const normalizedRate = rate / 100 / 12;
      const months = parsedTerm * 12;

      const rn = Math.pow(1 + normalizedRate, months);

      if (normalizedRate === 0) {
        monthlyPayment = amountBorrowed / months;
      } else {
        monthlyPayment = amountBorrowed * ((normalizedRate * rn) / (rn - 1));
      }

      calculatedMonthlyCashflow = monthlyCashflow - monthlyPayment;
    }
  }

  if (
    type === HouseCalculationType.Guided &&
    !_.isNil(scenario.remainingCashflow) &&
    !_.isNil(scenario.downPayment) &&
    !_.isNil(scenario.term) &&
    !_.isNil(scenario.interestRate)
  ) {
    const remainingCashflow = Number.parseFloat(scenario.remainingCashflow);
    const down = Number.parseFloat(scenario.downPayment);
    const parsedTerm = Number.parseFloat(scenario.term);
    const rate = Number.parseFloat(scenario.interestRate);

    monthlyPayment = monthlyCashflow - remainingCashflow;

    if (parsedTerm > 0 && rate >= 0 && monthlyPayment > 0) {
      const normalizedRate = rate / 100 / 12;
      const months = parsedTerm * 12;

      const rn = Math.pow(1 + normalizedRate, months);

      if (normalizedRate === 0) {
        calculatedHouseCost = monthlyPayment * months + down;
      } else {
        calculatedHouseCost =
          monthlyPayment / ((normalizedRate * rn) / (rn - 1)) + down;
      }

      calculatedMonthlyCashflow = remainingCashflow;
    }
  }

  const namingParts = [scenario.totalCost ?? "Scenario", scenario.term ?? ""];

  const defaultName = namingParts.join(" - ");

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <SavedScenario
        selectedOption={PlanOptions.House}
        onLoad={(d) => {
          if (!d) {
            setSavedScenario(undefined);
            setScenario(getDefaultScenarioData());
            return;
          }
          const data = PlanDataSerializer.deserialize<HouseData>(d.value);

          const newInput: HouseData = data;
          if (!newInput.type) {
            newInput.type = HouseCalculationType.Monthly;
          }
          setScenario({
            ...newInput,
          });
          setSavedScenario(d);
        }}
      />
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <PlanTitle scenario={savedScenario} />

          <Tabs
            className="plan-tabs"
            selectedIndex={TabIndex[type]}
            onSelect={(i) => {
              const newType = IndexToType[i] ?? HouseCalculationType.Monthly;

              const newScenario = {
                ...scenario,
                type: newType,
              };
              switch (newType) {
                case HouseCalculationType.Monthly: {
                  if (!_.isNil(calculatedHouseCost)) {
                    newScenario.totalCost = calculatedHouseCost.toFixed(10);
                  }
                  newScenario.remainingCashflow = undefined;
                  break;
                }
                case HouseCalculationType.Guided: {
                  if (!_.isNil(calculatedMonthlyCashflow)) {
                    newScenario.remainingCashflow =
                      calculatedMonthlyCashflow.toFixed(10);
                  }

                  newScenario.totalCost = undefined;
                  break;
                }
              }
              setScenario(newScenario);
            }}
          >
            <TabList className="plan-tab-list">
              <Tab
                selected={type === HouseCalculationType.Monthly}
                className="plan-tab__tab-title"
                selectedClassName="plan-tab__tab-title-selected"
                onClick={() => {
                  setScenario({
                    ...scenario,
                    type: HouseCalculationType.Monthly,
                  });
                }}
              >
                Monthly Payments
              </Tab>
              <Tab
                selected={type === HouseCalculationType.Guided}
                className="plan-tab__tab-title"
                selectedClassName="plan-tab__tab-title-selected"
                onClick={() => {
                  setScenario({
                    ...scenario,
                    type: HouseCalculationType.Guided,
                  });
                }}
              >
                How much can I afford
              </Tab>
            </TabList>

            <TabPanel className="plan-panel__content">
              <MonthlyHouseInput
                scenario={scenario}
                setScenario={setScenario}
              />
            </TabPanel>
            <TabPanel className="plan-panel__content">
              <GuidedInput scenario={scenario} setScenario={setScenario} />
            </TabPanel>
          </Tabs>
        </div>

        <div
          style={{
            paddingTop: "2rem",
          }}
        />

        <ScenarioResultScreen
          saveButtonProps={{
            selectedOption: PlanOptions.House,
            serializedPlan: PlanDataSerializer.serialize({
              ...scenario,
              monthlyCashflow: calculatedMonthlyCashflow,
            }),
            defaultName: defaultName,
            onSave: (sp) => {
              setSavedScenario(sp);
            },
            scenario: savedScenario,
          }}
        >
          <ExpenseResult monthlyCost={monthlyPayment} />

          {type === HouseCalculationType.Monthly && (
            <CashflowResult newCashflow={calculatedMonthlyCashflow} />
          )}

          {type === HouseCalculationType.Guided && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Text
                type={TextType.Div}
                style={TextStyle.B16}
                weight={600}
                format={TextFormat.UpperCase}
              >
                Total Cost (Incl. taxes and fees)
              </Text>
              <div style={{ paddingTop: "1rem" }} />
              <Text type={TextType.Div} style={TextStyle.B16}>
                {calculatedHouseCost
                  ? toMoneyStringNoDecimal(calculatedHouseCost)
                  : "Unable to calculate"}
              </Text>
            </div>
          )}

          <div
            style={{
              display: "flex",
              paddingTop: "1.5rem",
              flexDirection: "column",
              width: "50%",
            }}
          >
            <Text
              type={TextType.Div}
              style={TextStyle.B16}
              weight={600}
              format={TextFormat.UpperCase}
            >
              Projected Debt-to-income ratio
            </Text>
            <div style={{ paddingTop: "1rem" }} />
            <Text type={TextType.Div} style={TextStyle.B16}>
              {monthlyPayment
                ? `${(
                    (monthlyPayment /
                      (Number.parseFloat(householdIncome) / 12)) *
                    100
                  ).toFixed(2)}%`
                : "Unknown"}
            </Text>
          </div>
        </ScenarioResultScreen>

        <div
          style={{
            paddingTop: "2rem",
          }}
        >
          <svg width="688" height="293">
            <use xlinkHref={`${Illustration}#illustration`} />
          </svg>
        </div>
      </div>
    </div>
  );
};

const MonthlyHouseInput: React.FC<{
  scenario: HouseData;
  setScenario: (d: HouseData) => void;
}> = ({ scenario, setScenario }) => {
  return (
    <>
      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          colour={Colour.Text03}
          format={TextFormat.UpperCase}
        >
          Total Cost (Including taxes and other fees)
        </Text>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.totalCost}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              totalCost: v,
            });
          }}
          placeholder="$20,000"
          prefix="$"
          decimalsLimit={0}
          allowNegativeValue={false}
          decimalScale={0} // Precise value (10 digits) of the cost is set when user switches between mode, however we do not want to display that many digits
        />
      </div>

      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          colour={Colour.Text03}
          format={TextFormat.UpperCase}
        >
          Term (Years)
        </Text>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.term}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              term: v,
            });
          }}
          placeholder="72"
          decimalsLimit={0}
          allowNegativeValue={false}
        />
      </div>

      <div>
        <div
          style={{
            display: "flex",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.L12}
            colour={Colour.Text03}
            format={TextFormat.UpperCase}
          >
            Down Payment
          </Text>

          <div
            id="plan-house-down-20"
            style={{
              padding: "0 0.5rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setScenario({
                ...scenario,
                downPayment: (
                  Number.parseFloat(scenario.totalCost || "0") * 0.2
                ).toFixed(0),
              });
            }}
          >
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Blue01}
              size={"0.75rem"}
            >
              20%
            </Text>
          </div>
          <div
            id="plan-house-down-20"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setScenario({
                ...scenario,
                downPayment: (
                  Number.parseFloat(scenario.totalCost || "0") * 0.3
                ).toFixed(0),
              });
            }}
          >
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Blue01}
              size={"0.75rem"}
            >
              30%
            </Text>
          </div>
        </div>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.downPayment}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              downPayment: v,
            });
          }}
          placeholder="$2,000"
          prefix="$"
          decimalsLimit={2}
          allowNegativeValue={false}
        />
      </div>

      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          colour={Colour.Text03}
          format={TextFormat.UpperCase}
        >
          Interest Rate
        </Text>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.interestRate}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              interestRate: v,
            });
          }}
          placeholder="3.00%"
          suffix="%"
          decimalsLimit={2}
          allowNegativeValue={false}
        />
      </div>
    </>
  );
};

const GuidedInput: React.FC<{
  scenario: HouseData;
  setScenario: (d: HouseData) => void;
}> = ({ scenario, setScenario }) => {
  return (
    <>
      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          colour={Colour.Text03}
          format={TextFormat.UpperCase}
        >
          Remaining Monthly Cashflow
        </Text>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.remainingCashflow}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              remainingCashflow: v,
            });
          }}
          placeholder="$1,000"
          prefix="$"
          decimalsLimit={0}
          decimalScale={0} // Precise value (10 digits) of the cost is set when user switches between mode, however we do not want to display that many digits
          allowNegativeValue={false}
        />
      </div>

      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          colour={Colour.Text03}
          format={TextFormat.UpperCase}
        >
          Term (YEARS)
        </Text>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.term}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              term: v,
            });
          }}
          placeholder="72"
          decimalsLimit={0}
          allowNegativeValue={false}
        />
      </div>

      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          colour={Colour.Text03}
          format={TextFormat.UpperCase}
        >
          Down Payment
        </Text>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.downPayment}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              downPayment: v,
            });
          }}
          placeholder="$2,000"
          prefix="$"
          decimalsLimit={0}
          allowNegativeValue={false}
        />
      </div>

      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          colour={Colour.Text03}
          format={TextFormat.UpperCase}
        >
          Interest Rate
        </Text>
        <PylonCurrencyInput
          className="plan-input-box"
          value={scenario.interestRate}
          onValueChange={(v) => {
            setScenario({
              ...scenario,
              interestRate: v,
            });
          }}
          placeholder="3.00%"
          suffix="%"
          decimalsLimit={2}
          allowNegativeValue={false}
        />
      </div>
    </>
  );
};
