import * as React from "react";
import { Asset } from "core/models/asset";
import { ShareWidget } from "components/features/dashboard/components/share-widget";
import "./styles.css";

export interface Props {
  asset: Asset;
  onClose?: () => void;
}

export const ShareTray: React.FC<Props> = ({ asset, onClose }) => {
  return (
    <div className="share-tray">
      <ShareWidget asset={asset} onClose={onClose} />
    </div>
  );
};
