import * as React from "react";
import { blurOnEnter } from "core/utils/form-helpers";
import { FilingStatus } from "@pylon/taxes";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { setValue } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/util";
import { TaxContext } from "components/contexts/tax-context";
import { TaxPageSection } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section";
import { Text, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";

export const TaxSectionRetirementContributions: React.FC = () => {
  const {
    filingStatus,
    saveLiabilityFieldValue,
    retirementContributions,
    contributions401K,
    setSpouseContributionsIRA,
    setContributions401K,
    contributionsIRA,
    setContributionsIRA,
    spouseContributions401K,
    setSpouseContributions401K,
    spouseContributionsIRA,
    taxResults,
  } = React.useContext(TaxContext);

  return (
    <TaxPageSection
      localStorageKey="taxes.retirementdeductions"
      headerLeft="Retirement Deductions"
      headerRight={
        <div className="table-cell">
          {toMoneyString(taxResults.calculated.allowedDeductibleContributions)}
        </div>
      }
      bodyLeft={
        <>
          <div className="text-02 mt1">
            If you've made any contributions to a <b>401k</b> or <b>IRA</b>,
            enter those amounts here.{" "}
            <b>We'll determine how much is deductible</b>.
          </div>
        </>
      }
      bodyRight={
        <>
          <div className="flex-row between mobile-flex-column">
            <div>
              {" "}
              <div className="label">
                Your Total 401k Contributions&#160;
                <PylonTooltip
                  spacingSide={SpacingSide.Right}
                  text={
                    <Text type={TextType.Div}>
                      <p>
                        This is the total amount that you contributed to any
                        401k plans in this tax year. Exclude from this number
                        any contributions made by your employer.
                      </p>
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <PylonCurrencyInput
                placeholder="401k Contributions"
                value={contributions401K}
                maxLength={12}
                onValueChange={(v) => {
                  setValue(v || "", setContributions401K);
                }}
                onBlur={(e) => {
                  saveLiabilityFieldValue(
                    "contributions401K",
                    sanitizeCurrencyString(e.target.value)
                  );
                }}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                onKeyDown={blurOnEnter}
              />
            </div>
            <div>
              <div className="label">
                Your Total IRA Contributions&#160;
                <PylonTooltip
                  spacingSide={SpacingSide.Right}
                  text={
                    <Text type={TextType.Div}>
                      <p>
                        This is the total amount that you contributed to any
                        traditional IRAs in this tax year. This does not include
                        Roth-IRAs. Exclude from this number any contributions
                        made by your employer.
                      </p>
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <PylonCurrencyInput
                placeholder="IRA Contibutions"
                value={contributionsIRA}
                maxLength={12}
                onValueChange={(v) => {
                  setValue(v || "", setContributionsIRA);
                }}
                onBlur={(e) => {
                  saveLiabilityFieldValue(
                    "contributionsIRA",
                    sanitizeCurrencyString(e.target.value)
                  );
                }}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                onKeyDown={blurOnEnter}
              />
            </div>
          </div>

          {filingStatus === FilingStatus.MarriedFilingJointly && (
            <div className="flex-row between mt2 mobile-flex-column">
              <div>
                {" "}
                <div className="label">
                  Spouse's Total 401k Contributions&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>
                          This is the total amount that you contributed to any
                          401k plans in this tax year. <br />
                          <br />
                          Exclude from this number any contributions made by
                          your employer.
                        </p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <PylonCurrencyInput
                  placeholder="401k Contributions"
                  value={spouseContributions401K}
                  maxLength={12}
                  onValueChange={(v) => {
                    setValue(v || "", setSpouseContributions401K);
                  }}
                  onBlur={(e) => {
                    saveLiabilityFieldValue(
                      "spouseContributions401K",
                      sanitizeCurrencyString(e.target.value)
                    );
                  }}
                  allowDecimals={true}
                  decimalSeparator={"."}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  prefix="$"
                  onKeyDown={blurOnEnter}
                />
              </div>
              <div>
                <div className="label">
                  Spouse's Total IRA Contributions&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>
                          This is the total amount that you contributed to any
                          traditional IRAs in this tax year. This does not
                          include Roth-IRAs. Exclude from this number any
                          contributions made by your employer.
                        </p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <PylonCurrencyInput
                  placeholder="IRA Contibutions"
                  value={spouseContributionsIRA}
                  maxLength={12}
                  onValueChange={(v) => {
                    setValue(v || "", setSpouseContributionsIRA);
                  }}
                  onBlur={(e) => {
                    saveLiabilityFieldValue(
                      "spouseContributionsIRA",
                      sanitizeCurrencyString(e.target.value)
                    );
                  }}
                  allowDecimals={true}
                  decimalSeparator={"."}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  prefix="$"
                  onKeyDown={blurOnEnter}
                />
              </div>
            </div>
          )}
        </>
      }
    />
  );
};
