import * as React from "react";
import { OnboardingCardContainer } from "components/features/onboarding/components/onboarding-card-container";
import { OnboardingProps } from "components/features/onboarding/flows/utils";

interface CustomProps {
  titleElement: React.ReactNode;
}

type Props = OnboardingProps & CustomProps;
export const OnboardingWhatElseDoYouWant: React.FC<Props> = ({
  titleElement,
  state,
  optimisticUpdate,
}) => {
  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>{titleElement}</div>
      </div>
      <div
        className="onboarding-middle buttons"
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          paddingTop: "2vh",
          paddingBottom: "2vh",
        }}
      >
        <OnboardingCardContainer
          state={state}
          optimisticUpdate={optimisticUpdate}
        />
      </div>
    </div>
  );
};
