import { Account } from "core/models";
import { ACCOUNT_FIELDS } from "core/queries/accounts";
import { ConvertToManualProps } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { gql } from "@apollo/client";

export interface ExchangeCoinbaseMutationResponse {
  exchangeCoinbaseCode: Account;
}
export const ExchangeCoinbaseCode = gql`
  ${ACCOUNT_FIELDS}
  mutation ExchangeCoinbaseCode($code: String!, $convert: ConvertAccountInput) {
    exchangeCoinbaseCode(code: $code, convert: $convert) {
      ...AccountFields
    }
  }
`;

export interface CreateCoinbaseProMutationInput {
  input: {
    apiKey: string;
    secretKey: string;
    passphrase: string;
    convertToManualProps?: ConvertToManualProps;
  };
}

export interface CreateCoinbaseProMutationResponse {
  createCoinbaseProAccount: Account;
}

export const CreateCoinbaseProMutation = gql`
  ${ACCOUNT_FIELDS}
  mutation createCoinbaseProAccount($input: CreateCoinbaseProAccountInput!) {
    createCoinbaseProAccount(input: $input) {
      ...AccountFields
    }
  }
`;
