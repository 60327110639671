import { Colour } from "core/models";
import React, { SVGAttributes } from "react";
import Icons from "./icons.svg";

export enum IconAsset {
  Home = "home",
  Account = "account",
  Document = "document",
  Trashcan = "trashcan",
  TrashcanAlt = "trashcan-alt",
  Shared = "shared",
  Feedback = "feedback",
  Logo = "logo",
  LogoDot = "logo-dot",
  LogoDotWhite = "logo-dot-white",
  Suitcase = "suitcase",
  TwoUsers = "two-users",
  Chart = "chart",
  ChatBubble = "chat-bubble",
  Help = "help",
  Notifications = "notifications",
  Connect = "connect",
  Disconnect = "disconnect",
  ShareAsset = "share-asset",
  RefreshAccount = "refresh-account",
  RemoveAsset = "remove-asset",
  Eye = "eye",
  EyeSlash = "eye-slash",
  RemoveSharedAsset = "remove-shared-asset",
  Edit = "edit",
  Plus = "plus",
  SmallCross = "small-cross",
  Close = "close",
  LeftArrow = "left-arrow",
  RightArrow = "right-arrow",
  Info = "info",
  Reorder = "reorder",
  Users = "users",
  Cog = "cog",
  Back = "back",
  Sharing = "sharing",
  Lock = "lock",
  Note = "note",
  FileUpload = "fileupload",
  CircledPlus = "circled-plus",
  GreenCheckMark = "green-check-mark",
  CheckMark = "check-mark",
  ArrowPointingRight = "arrow-pointing-right",
  RedCross = "red-cross",
  Ellipsis = "ellipsis",
  Dot = "dot",
  CreditMonitor = "credit-monitor",
  EstatePlan = "estate-plan",
  AddAccountEmpty = "add-account-empty",
  IncomeAndTaxes = "income-and-taxes",
  AddCircle = "add-circle",
  Garbage = "garbage",
  Ambulance = "ambulance",
  NotificationDot = "notification-dot",
  LeftDirectionArrow = "left-direction-arrow",
  RightDirectionArrow = "right-direction-arrow",
  Calculators = "calculators",
  Download = "download",
  ScrollArrowRight = "scroll-arrow-right",
  ScrollArrowLeft = "scroll-arrow-left",
  ArrowUpToRight = "arrow-up-to-right",
  ArrowDownToRight = "arrow-down-to-right",
  BoxedPlus = "boxed-plus",
  ExpensesEmpty = "expenses-empty",
  CashFlowEmpty = "cashflow-empty",

  OnboardingMoney = "onboarding-money",
  OnboardingLady = "onboarding-lady",
  OnboardingTax = "onboarding-tax",
  OnboardingDocuments = "onboarding-documents",
  OnboardingOldGuy = "onboarding-old-guy",
  OnboardingCreditScore = "onboarding-credit-score",
  OnboardingShare = "onboarding-share",
  OnboardingSellPrivateStock = "onboarding-sell-private-stock",
  OnboardingLowerInterest = "onboarding-lower-interest",
  OnboardingPayOffDebt = "onboarding-pay-off-debt",
  OnboardingOpen529 = "onboarding-open-529",
  OnboardingCompareNetworth = "onboarding-compare-networth",
  OnboardingHelpParents = "onboarding-help-parents",
  OnboardingSetEmergencyContact = "onboarding-set-emergency-contact",
  OnboardingCheckCredit = "onboarding-check-credit",
  OnboardingTrackCashflow = "onboarding-track-cashflow",
  OnboardingTrackInvestments = "onboarding-track-investments",
  OnboardingBuyProperty = "onboarding-buy-property",
  OnboardingBuyVehicle = "onboarding-buy-vehicle",
  OnboardingInvestPrivately = "onboarding-invest-privately",
  OnboardingTaxes = "onboarding-taxes",

  OnboardingMartialStatusSingle = "onboarding-single-person",
  OnboardingMartialStatusMarried = "onboarding-married",
  OnboardingMartialStatusHeadOfHousehold = "onboarding-head-of-household",

  OnboardingSpreadsheet = "onboarding-spreadsheet",
  OnboardingOffline = "onboarding-offline",
  OnboardingIDK = "onboarding-idk",
  OnboardingLots = "onboarding-lots",
  OnboardingCelebration = "onboarding-celebration",
  OnboardingComputer = "onboarding-computer",

  PylonTip = "pylon-tip",
  BackButton = "back-button",

  StocksEmptyState = "stocks-empty-state",

  PlanCar = "plan-car",
  PlanHouse = "plan-house",
  PlanChildcare = "plan-childcare",
  PlanMillionaire = "plan-millionaire",
  PlanBillionaire = "plan-billionaire",
  PlanIcon = "plan-icon",

  ClosedArrow = "closed-arrow",
  OpenedArrow = "opened-arrow",

  DidYouKnow = "did-you-know",
  Padlock = "padlock",

  Menu = "menu",
  MenuClose = "menu-close",
  MenuCloseWhite = "menu-close-white",

  DisabledToggle = "disabled-toggle",

  MenuCollaborators = "menu-collaborators",
  MenuDocuments = "menu-documents",
  MenuInvestments = "menu-investments",
  MenuCashflow = "menu-cashflow",
  MenuAccounts = "menu-accounts",
  MenuDashboard = "menu-dashboard",
  MenuDashboardSeletected = "menu-dashboard-selected",
  MenuSupport = "menu-support",
  MenuSupport2 = "menu-support2",
  MenuSecurity = "menu-security",
  MenuSettings = "menu-settings",
  MenuLogout = "menu-logout",
  MenuLogoutMain = "menu-logout-two",
  MenuTaxes = "menu-taxes",
  MenuCredit = "menu-credit",
  MenuInsurance = "menu-insurance",
  MenuNotifications = "menu-notifications",

  SortUp = "sort-up",
  SortDown = "sort-down",
  DropDown = "drop-down",
  DropDownSelectedItem = "drop-down-selected-item",

  CollapsibleOpen = "collapsible-open",
  CollapsibleClosed = "collapsible-closed",

  LeftPaginationArrow = "left-pagination-arrow",
  RightPaginationArrow = "right-pagination-arrow",

  UpArrow = "up-arrow",
  DownArrow = "down-arrow",
  Expand = "expand",
  VerticalDivider = "vertical-divider",

  HamburgDot = "hamburg-dot",
  MenuKebab = "menu-kebab",
  MenuRemove = "menu-remove",
  AddCategory = "add-category",
  SharedAsset = "shared-asset",

  DropdownCaret = "dropdown-caret",
  CategoryDropdown = "category-dropdown",

  PylonLogoIcon = "pylon-logo-icon",
}

export interface Props {
  height: string;
  width: string;
  asset: IconAsset; // Maps to the icons file
  colour?: Colour | string; // Maps to svg.fill
  strokeColour?: Colour | string; // Maps to svg.stroke
  svgClass?: string;
  selected?: boolean;
}

export const Icon: React.FC<Props> = ({
  height,
  width,
  asset,
  colour,
  strokeColour,
  svgClass,
  selected,
}) => {
  const iconColour = colour ?? Colour.Gray50;
  const attr: SVGAttributes<SVGSVGElement> = {
    className: "icon",
    width: width,
    height: height,
  };
  if (iconColour) {
    attr.fill = iconColour;
  }
  if (strokeColour) {
    attr.stroke = strokeColour;
  }
  if (svgClass) {
    attr.className = svgClass;
  }

  return (
    <svg {...attr}>
      <use xlinkHref={`${Icons}#icon-${asset}${selected ? "-selected" : ""}`} />
    </svg>
  );
};
