import { useQuery } from "@apollo/client";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton, LeafButtonStyle } from "components/core/leaf-button";
import { LinkType, PylonLink } from "components/core/link";
import {
  Text,
  TextD,
  TextFormat,
  TextStyle,
  TextType,
} from "components/core/text";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { DashboardPages } from "components/features/dashboard/pages";
import { Colour } from "core/models";
import { PaginatedFooter } from "core/paginated-footer";
import {
  FETCH_INVESTMENT_HOLDINGS,
  InvestmentHolding,
  InvestmentHoldingsResponse,
} from "core/queries/investments";
import { toMoneyString } from "core/utils";
import * as React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

interface Props {
  limit?: number;
  accountID?: string;
  enablePagination?: boolean;
  hideEmptyState?: boolean;
}
export const InvestmentHoldingsSection: React.FC<Props> = ({
  limit,
  accountID,
  enablePagination,
  hideEmptyState,
}) => {
  const { data, refetch } = useQuery<InvestmentHoldingsResponse>(
    FETCH_INVESTMENT_HOLDINGS,
    {
      variables: {
        limit,
        accountID,
        offset: 0,
      },
      fetchPolicy: "network-only",
    }
  );

  const holdings = React.useMemo(() => {
    return data?.investmentHoldings?.holdings;
  }, [data?.investmentHoldings]);

  const calculateTotalReturn = (holding: InvestmentHolding) => {
    return holding.costBasis
      ? (holding.institutionValue || 0) - holding.costBasis
      : holding.institutionValue;
  };

  const roundQuantity = (n: number) => {
    const rounded = Math.round(n);
    return n !== rounded ? n?.toFixed(2) || 0 : rounded;
  };

  const total = data?.investmentHoldings?.total || 0;
  const showPaging = limit;
  const [page, setPage] = React.useState(0);
  const hasNext = !limit ? false : total > (page + 1) * limit;
  const hasPrevious = page > 0;
  const goToPage = (n: number) => {
    if (!showPaging) {
      return;
    }
    setPage(n);
    refetch({
      accountID,
      limit,
      offset: n * (limit || 0),
    });
  };

  const emptyState = hideEmptyState ? null : (
    <div className="overview-component investment-holdings-section mt3 flex-row between mobile-flex-column">
      <div style={{ alignSelf: "center" }}>
        <Icon asset={IconAsset.StocksEmptyState} height="160px" width="100%" />
      </div>
      <div>
        <h6 className="mt2">Get A Portfolio Overview</h6>
        <div
          style={{
            marginBottom: "2rem",
            fontSize: ".875rem",
            fontFamily: "Montserrat",
          }}
        >
          Easily view and manage your stock with Pylon.
        </div>
        <PylonLink
          to={{
            pathname: DashboardPages.Integrations,
          }}
          linkType={LinkType.NoStyle}
        >
          <LeafButton buttonStyle={LeafButtonStyle.LeafSmallDense}>
            Click here to add stocks
          </LeafButton>
        </PylonLink>
      </div>
    </div>
  );

  return (
    ((holdings?.length || 0) > 0 && (
      <div className="investment-holdings-section">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
            marginTop: "3rem",
            fontFamily: "'Montserrat', sans-serif",
            alignItems: "center",
          }}
        >
          <h6>Stock Portfolio</h6>
          {limit && !enablePagination && (
            <Link to={DashboardPages.InvestmentHoldings}>
              <TextD style={TextStyle.M15SM} colour={Colour.Blue01}>
                SEE ALL MY STOCKS
              </TextD>
            </Link>
          )}
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          {holdings && (
            <div className="flex-column" style={{ width: "100%" }}>
              <div className="table">
                <div className="table-head">
                  <div className="table-cell stock">Stock</div>
                  <div className="table-cell shares no-mobile">Shares</div>
                  <div className="table-cell no-mobile cost-basis">
                    Cost Basis
                  </div>
                  <div className="table-cell no-mobile closing">
                    Closing Price
                  </div>
                  <div className="table-cell return mobile-last">Return</div>
                  <div className="table-cell no-mobile equity">Equity</div>
                </div>
                <div className="table-body">
                  {holdings.map((holding, i) => {
                    return (
                      <div className="table-row" key={i}>
                        <div
                          title={holding.name || holding.ticker}
                          className="table-cell ellipsis flex-row stock"
                        >
                          {" "}
                          {!accountID && (
                            <div className="account-icon-cell">
                              <Link
                                to={`${DashboardPages.AccountDetails}?accountID=${holding?.account?.account_id}`}
                              >
                                {holding.account && (
                                  <div style={{ marginRight: ".5rem" }}>
                                    <AccountIcon
                                      account={holding.account}
                                      scale={0.75}
                                      backgroundColor="var(--text-01)"
                                    />
                                  </div>
                                )}
                              </Link>
                            </div>
                          )}
                          {holding.name || holding.ticker}
                        </div>
                        <div className="table-cell shares no-mobile">
                          {roundQuantity(holding.quantity) || 0}
                        </div>
                        <div className="no-mobile table-cell cost-basis">
                          {holding.costBasis ? (
                            toMoneyString(
                              holding.costBasis / (holding.quantity || 1)
                            )
                          ) : (
                            <Text
                              weight={400}
                              colour={Colour.Gray70}
                              size={"0.75rem"}
                              format={TextFormat.UpperCase}
                            >
                              N/A
                            </Text>
                          )}
                        </div>
                        <div className="no-mobile table-cell closing">
                          {holding.costBasis ? (
                            toMoneyString(holding.closePrice)
                          ) : (
                            <Text
                              weight={400}
                              colour={Colour.Gray70}
                              size={"0.75rem"}
                              format={TextFormat.UpperCase}
                            >
                              N/A
                            </Text>
                          )}
                        </div>
                        <div className="table-cell return mobile-last">
                          {holding.costBasis ? (
                            toMoneyString(calculateTotalReturn(holding))
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div style={{ marginRight: "0.5rem" }}>
                                <Text
                                  weight={400}
                                  colour={Colour.Gray70}
                                  size={"0.75rem"}
                                  format={TextFormat.UpperCase}
                                >
                                  N/A
                                </Text>
                              </div>
                              <PylonTooltip
                                spacingSide={SpacingSide.Right}
                                text={
                                  <Text type={TextType.Div}>
                                    <p>
                                      These are blank because this institution{" "}
                                      <b>does not</b> provide us with a{" "}
                                      <b>cost basis</b>. We use the cost basis
                                      to calculate your total return.
                                      Additionally, it does not provide us with
                                      a <b>closing price</b> given the market
                                      does not close.
                                    </p>
                                  </Text>
                                }
                                size={TooltipIconSize.Small}
                              />
                            </div>
                          )}
                        </div>
                        <div className="no-mobile table-cell equity">
                          {toMoneyString(holding.institutionValue)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                {enablePagination && limit && (
                  <PaginatedFooter
                    hasNext={hasNext}
                    hasPrevious={hasPrevious}
                    page={page}
                    totalPages={Math.ceil(total / limit)}
                    goToPage={goToPage}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )) ||
    emptyState
  );
};
