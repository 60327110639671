import * as React from "react";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { Input } from "components/core/input";
import { OrConnect } from "components/features/dashboard/components/add-account-tray/details-entry/or-connect";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeStringNumber } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { TextD, TextStyle } from "components/core/text";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  AccountSubtypeEnum,
  AccountType,
  IsAccountTypeConnectable,
} from "components/features/dashboard/components/add-account-tray/models";

const VALID_CHARACTER_REGEX = /[a-zA-Z0-9 ]/;

interface Props {
  isDisabled: boolean;
  onEnter: () => void;
}

export const DefaultBalanceOrLoginEntryTray: React.FC<Props> = ({
  isDisabled,
  onEnter,
}) => {
  const { trayStack, popTray } = React.useContext(SideTrayContext);
  const {
    accountName,
    balance,
    setAccountName,
    setBalance,
    limit,
    setLimit,
    accountType,
    accountSubType,
  } = React.useContext(AddAccountsContext);
  const handleBalanceChange = (value: string | undefined) => {
    setBalance(sanitizeStringNumber(value));
  };

  const handleLimitChange = (value: string | undefined) => {
    setLimit(sanitizeStringNumber(value));
  };

  const isCreditCard =
    accountType.toLowerCase() === AccountType.CreditCard.toLowerCase();

  const getInstructionText = (accountType: string, accountSubType: string) => {
    switch (accountSubType) {
      case AccountSubtypeEnum.PrivateStock:
        return "What is the name of the company you have equity in (shares or options)";
      case AccountSubtypeEnum.PrivateCompany:
        return "What is the name of the company you invested in";
      case AccountSubtypeEnum.LifeInsurance:
        return "What is the name of the company you have Life Insurance with";
    }

    switch (accountType) {
      case AccountType.Insurance:
        return "What is the name of your Insurance Provider";
      default: // Do nothing let next block handle it
    }

    return "Please enter the name and current balance below";
  };

  const getInstructionHint = (accountSubType: string) => {
    switch (accountSubType) {
      case AccountSubtypeEnum.PrivateStock:
        return "Pylon Inc";
    }

    return "Wells Fargo";
  };

  return (
    <div
      className="add-account-tray"
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "6vh",
        marginBottom: "3rem",
      }}
    >
      {IsAccountTypeConnectable(accountType, accountSubType) && <OrConnect />}
      <div
        className={`add-account-tray__content account-type-${accountType
          .toLowerCase()
          .replace(/\W/g, "-")}`}
      >
        <div className="add-account-tray__step-action">
          <h5>
            Manually track it yourself
            <span style={{ color: "var(--blue-01)" }}>.</span>
          </h5>
          <div
            style={{
              fontWeight: "normal",
              fontSize: "1rem",
            }}
          >
            {getInstructionText(accountType, accountSubType)}
            <span style={{ color: "var(--blue-01)" }}>.</span>
            <br />
          </div>
        </div>
      </div>
      <TextD style={TextStyle.M15}>
        <div className="flex-column gap-1 mt1">
          <div>
            Name
            <Input
              type="text"
              placeholder={getInstructionHint(accountSubType)}
              value={accountName}
              keyPressValidation={{
                inputRegex: VALID_CHARACTER_REGEX,
                errorMessage: "Please use alphanumeric characters",
              }}
              onEnter={onEnter}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (accountName.length >= 64) {
                  return;
                }
                setAccountName(e.currentTarget.value);
              }}
            />
          </div>

          <div>
            Balance (Estimates okay)
            <PylonCurrencyInput
              id="validation-example-2-field"
              placeholder="$100.00"
              onValueChange={handleBalanceChange}
              value={balance || ""}
              prefix={"$"}
              step={1}
              decimalScale={2}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onEnter();
                }
              }}
            />
          </div>

          {isCreditCard && (
            <div>
              <div>Credit Limit</div>
              <div>
                <PylonCurrencyInput
                  placeholder="$1000.00"
                  className={"add-account-tray__input"}
                  onValueChange={handleLimitChange}
                  value={limit || ""}
                  prefix={"$"}
                  step={1}
                  decimalScale={2}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      onEnter();
                    }
                  }}
                />
              </div>
            </div>
          )}

          <div className="flex-row gap-half">
            {trayStack.length > 1 && (
              <LeafButton
                buttonStyle={LeafButtonStyle.LeafSmall}
                buttonColour={LeafButtonColour.Transparent}
                onClick={() => {
                  popTray();
                }}
              >
                Prev
              </LeafButton>
            )}
            <LeafButton
              buttonStyle={LeafButtonStyle.LeafSmall}
              buttonColour={LeafButtonColour.Black}
              onClick={onEnter}
              disabled={isDisabled}
            >
              Finish
            </LeafButton>
          </div>
        </div>
      </TextD>
    </div>
  );
};
