import { useMutation, useQuery } from "@apollo/client";
import { SessionContext } from "components/contexts/session-context-provider";
import { ShareAccountList } from "components/features/dashboard/components/account-share-list";
import { AssetType } from "components/features/dashboard/models/sharing";
import { accountsQuery, AccountsResponse } from "core/queries/accounts";
import {
  Collaboration,
  FetchAccountsByCollaborationResponse,
  FETCH_ACCOUNTS_BY_COLLABORATION,
} from "core/queries/collaborations";
import {
  createSharedDataMutation,
  CreateSharedDataMutationInput,
  deleteSharedDataMutation,
  DeleteSharedDataMutationInput,
  RemoveAllSharedAssetsByCollaborationMutation,
  RemoveAllSharedAssetsByCollaborationMutationInput,
  ShareAllAssetByCollaborationMutationInput,
  ShareAllAssetsByCollaborationMutation,
} from "core/queries/shared-data";
import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";

interface Props {
  collaborator: Collaboration;
}

export const AccountShareList: React.FC<Props> = ({ collaborator }) => {
  const { userID } = useContext(SessionContext.context);
  const { data: accountData, loading: accountLoading } =
    useQuery<AccountsResponse>(accountsQuery, {
      fetchPolicy: "cache-and-network",
    });

  const refetchQueries = [
    {
      query: FETCH_ACCOUNTS_BY_COLLABORATION,
      variables: {
        collaborationID: collaborator.id,
      },
    },
  ];

  const { data: collabData, loading: collabLoading } =
    useQuery<FetchAccountsByCollaborationResponse>(
      FETCH_ACCOUNTS_BY_COLLABORATION,
      {
        fetchPolicy: "cache-and-network",
        variables: {
          collaborationID: collaborator.id,
        },
      }
    );

  const [createSharedData, { loading: createLoading }] = useMutation<
    {},
    CreateSharedDataMutationInput
  >(createSharedDataMutation, {
    refetchQueries: refetchQueries,
  });

  const [removeSharedData, { loading: deleteLoading }] = useMutation<
    {},
    DeleteSharedDataMutationInput
  >(deleteSharedDataMutation, {
    refetchQueries: refetchQueries,
  });

  const [shareAllMutation, { loading: shareAllLoading }] = useMutation<
    {},
    ShareAllAssetByCollaborationMutationInput
  >(ShareAllAssetsByCollaborationMutation, {
    variables: {
      collaborationID: collaborator.id,
      type: AssetType.Account,
    },
    refetchQueries: refetchQueries,
  });

  const [unshareAllMutation, { loading: unshareAllLoading }] = useMutation<
    {},
    RemoveAllSharedAssetsByCollaborationMutationInput
  >(RemoveAllSharedAssetsByCollaborationMutation, {
    variables: {
      collaborationID: collaborator.id,
      type: AssetType.Account,
    },
    refetchQueries: refetchQueries,
  });

  if (!accountData && accountLoading) {
    return <Spinner animation="border" size="sm" />;
  }

  const editDisabled =
    accountLoading ||
    createLoading ||
    deleteLoading ||
    collabLoading ||
    shareAllLoading ||
    unshareAllLoading;

  const accountIDToSharedDataID: Record<string, string> = {};

  collabData?.collaboration?.sharedData
    ?.filter((sd) => sd.assetType === AssetType.Account)
    .forEach((sd) => {
      accountIDToSharedDataID[sd.assetID] = sd.id;
    });

  const myAccounts =
    accountData?.accounts?.filter((acc) => acc.owner?.id === userID) || [];

  const isAccShared = (accID: string) => {
    return !!accountIDToSharedDataID[accID];
  };

  const onAccRemove = (accID: string) => {
    removeSharedData({
      variables: {
        id: accountIDToSharedDataID[accID],
      },
    });
  };

  const onAccAdd = (accID: string) => {
    createSharedData({
      variables: {
        collaborationID: collaborator.id,
        assetID: accID,
        assetType: AssetType.Account,
      },
    });
  };

  return (
    <ShareAccountList
      accounts={myAccounts}
      disabled={editDisabled}
      isAccShared={isAccShared}
      onAccAdd={onAccAdd}
      onAccRemove={onAccRemove}
      unshareAll={unshareAllMutation}
      shareAll={shareAllMutation}
    />
  );
};
