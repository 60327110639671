import * as React from "react";
import classnames from "classnames";
import { Colour } from "core/models";
import { ErrorResponse, submitBetaEmailNew } from "core/api";
import { Icon, IconAsset } from "components/core/icons";
import { Link } from "react-router-dom";
import { PublicPageLinks } from "components/features/public";
import { RegisterButton } from "components/features/landing/components/register";
import { useState } from "react";
import "./styles.css";

enum EmailCollectionState {
  None,
  Success,
}

const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateFullName = (fullName: string) => {
  return fullName.split(" ").length > 1;
};

interface Props {
  title?: string;
}

export const HomeEmails: React.FC<Props> = ({ title }) => {
  const [state, setState] = useState<EmailCollectionState>(
    EmailCollectionState.None
  );
  const [err, setErr] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const onSubmitEmail = () => {
    if (!validateEmail(email)) {
      setErr("Please enter a valid email.");
      return;
    }
    if (!validateFullName(fullName)) {
      setErr("Please enter a first and last name.");
      return;
    }
    submitBetaEmailNew(
      email,
      fullName,
      onCollectionSuccess,
      onCollectionSuccess
    );
    return;
  };

  const onCollectionSuccess = () => {
    setErr("");
    setState(EmailCollectionState.Success);
  };

  const onCollectionErr = (err: ErrorResponse) => {
    setErr(err.msg);
  };

  const renderErr = () => {
    return err !== "" ? (
      <div className="home__email-section-subtitle">{err}</div>
    ) : null;
  };

  const successState = state === EmailCollectionState.Success;

  const animationClasses = classnames({
    "home__animation-holder": true,
    "home__success-animation": successState,
  });

  const textAnimationClasses = classnames({
    "home__text-animation-holder": true,
    "home__text-animiation-success": successState,
  });

  const renderedTitle = title ? title : "Effortless Money Management";
  return (
    <>
      <div className="landing-mobile">
        <div className="tag-line-1">Your Financial Roundup</div>
        <h1 className="tag-line-2">{renderedTitle}</h1>
        <div className="flex-column register">
          <RegisterButton optionalParam="skip=true" />
        </div>
        <div className="home-background">
          <div className="flat-back"></div>
          <div className="bg"></div>
        </div>

        <div className={`flex-column form ${successState ? "hide" : "show"}`}>
          <div className="form-title">Join the Waitlist</div>
          <div className="flex-column between fields">
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFullName(e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  onSubmitEmail();
                }
              }}
              style={{ marginBottom: "1rem" }}
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === "Enter") {
                  onSubmitEmail();
                }
              }}
            />
            <br />
            <button disabled={successState} onClick={() => onSubmitEmail()}>
              Submit
            </button>
          </div>
          {renderErr()}
        </div>
        <div className={animationClasses} style={{ width: "calc(92vw)" }}>
          <div className={textAnimationClasses}>
            <div className="home__green-check"></div>
            <div>You're all set!</div>
          </div>
        </div>
        <div className="flex-row evenly mt3 home-page-links">
          <div>
            <div className="spacer"></div>
            <div>
              <Link to={"mailto:support@hellopylon.com"}>Support</Link>
            </div>
          </div>
          <div>
            <div className="spacer"></div>
            <div>
              <Link to={PublicPageLinks.Privacy}>Privacy</Link>
            </div>
          </div>
          <div>
            <div className="spacer"></div>
            <div>
              <Link to={PublicPageLinks.Term}>Terms</Link>
            </div>
          </div>
          <div>
            <div className="spacer"></div>
            <div>
              <Link to="/security">Security</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="landing">
        <div className="home-background">
          <div className="bg"></div>
          <div className="flat-back"></div>
        </div>
        <div className="flex-column between left">
          <div>
            <div className="logo-desktop" style={{ paddingBottom: "10vh" }}>
              <Icon
                width="8vw"
                height="2vw"
                asset={IconAsset.LogoDot}
                colour={Colour.Navy}
              />
            </div>
            <div className="logo-mobile" style={{ paddingBottom: "10vh" }}>
              <Icon
                width="142px"
                height="41px"
                asset={IconAsset.LogoDot}
                colour={Colour.Navy}
              />
            </div>
            <div className="tag-line-1">Your Financial Roundup</div>
            <h1 className="tag-line-2">{renderedTitle}</h1>
            <div className="">
              <div className={animationClasses}>
                <div className={textAnimationClasses}>
                  <div className="home__green-check"></div>
                  <div>You're all set!</div>
                </div>
              </div>
              <div className="flex-column register">
                <RegisterButton optionalParam="skip=true" />
              </div>
              <div className="flex-column form">
                <div className="form-title">Join the Waitlist</div>
                <div className="flex-column between fields">
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFullName(e.target.value)
                    }
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter") {
                        onSubmitEmail();
                      }
                    }}
                    style={{ marginBottom: "1rem" }}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === "Enter") {
                        onSubmitEmail();
                      }
                    }}
                  />
                  <button
                    disabled={successState}
                    onClick={() => onSubmitEmail()}
                  >
                    Submit
                  </button>
                </div>
                {renderErr()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
