export enum BeneficiaryLevel {
  None = "none",
  Primary = "primary",
  Backup = "backup",
}

export enum ExecutorLevel {
  None = "none",
}

export interface ContactInfo {
  executor: boolean;
  benefitLevel: BeneficiaryLevel;
  email: string;
  phone: string;
  firstName?: string;
  lastName?: string;
  age?: string | undefined;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  postalCode?: string;
  stateProvinceRegion?: string;
  country?: string;
}
