import _ from "lodash";
import { accountShortName } from "core/models/account";
import { accountsQuery, AccountsResponse } from "core/queries/accounts";
import { AccountSubtypeEnum } from "components/features/dashboard/components/add-account-tray/models";
import { BalanceSheetPDF } from "components/features/dashboard/components/personal-balance-sheet/pdf-statement";
import { format } from "date-fns";
import { NetworthAccountOwnerFilter } from "components/features/dashboard/components/networth-summary-section/components/networth-account-owner-filter";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { SessionContext } from "components/contexts/session-context-provider";
import { Text, TextStyle, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useContext, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { UserDisplayName } from "core/utils/profile";
import "./styles.css";
import {
  AccountGroup,
  CategorizedAccount,
} from "components/features/dashboard/components/personal-balance-sheet/models";
import {
  CollaborationRelationships,
  FetchMyCollaboratorsResponse,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";

enum OwnerTypeKey {
  Me = "me",
  Spouse = "spouse",
  Any = "shared",
}

const SpouseParnterRelationship: string[] = [
  CollaborationRelationships.Partner,
  CollaborationRelationships.Spouse,
];

export const PersonalBalanceSheet: React.FC = () => {
  const { data, loading } = useQuery<AccountsResponse>(accountsQuery, {
    fetchPolicy: "cache-and-network",
  });
  const { userID } = useContext(SessionContext.context);

  const [accountOwnerType, setAccountOwnerType] = useState(OwnerTypeKey.Me);

  const { data: collabData, loading: collabLoading } =
    useQuery<FetchMyCollaboratorsResponse>(FETCH_MY_COLLABORATORS, {
      fetchPolicy: "cache-and-network",
    });

  let accounts = data?.accounts ?? [];
  switch (accountOwnerType) {
    case OwnerTypeKey.Me: {
      accounts = accounts.filter((acc) => acc.owner?.id === userID);
      break;
    }
    case OwnerTypeKey.Spouse: {
      const spousePartnerID =
        collabData?.collaborations
          ?.filter((c) => {
            return SpouseParnterRelationship.includes(c.relationship ?? "");
          })
          ?.map((c) => c.recipient.id) ?? [];

      const eligibleUserIDs = [userID, ...spousePartnerID];
      accounts = accounts.filter((acc) =>
        eligibleUserIDs.includes(acc.owner?.id ?? "")
      );
      break;
    }
    default: // Keep all account, do nothing
  }

  const assetGroups: Record<string, CategorizedAccount> = {};
  const liabilityGroups: Record<string, CategorizedAccount> = {};

  accounts.forEach((acc) => {
    // Skip accounts with no balance
    if (!acc.balances?.current) {
      return;
    }

    const type = acc.type;
    let name = accountShortName(acc);
    if (!acc.isOwner) {
      name = `${name} - ${UserDisplayName(acc.owner)}`;
    }
    const amt = acc.balances.current;

    if (acc.typeProperties?.isAsset) {
      updateRecord(assetGroups, type, name, amt);
    }
    if (acc.typeProperties?.isLiability) {
      updateRecord(liabilityGroups, type, name, amt);
    }

    if (
      acc.subtype === AccountSubtypeEnum.Mortgage &&
      acc.details?.mortgageDetails?.estimatedValue
    ) {
      updateRecord(
        assetGroups,
        "Property",
        name,
        Number.parseFloat(acc.details.mortgageDetails.estimatedValue)
      );
    }

    if (
      acc.subtype === AccountSubtypeEnum.RentalProperty &&
      acc.details?.rentalPropertyDetails?.estimatedValue
    ) {
      updateRecord(
        assetGroups,
        "Property",
        name,
        Number.parseFloat(acc.details.rentalPropertyDetails.estimatedValue)
      );
    }
  });

  const assetAccounts = Object.keys(assetGroups)
    .map((k) => {
      const r: AccountGroup = {
        group: k,
        accounts: assetGroups[k],
      };
      return r;
    })
    .sort((a, b) => {
      return a.accounts.total - b.accounts.total;
    });

  const liabilityAccounts = Object.keys(liabilityGroups).map((k) => {
    const r: AccountGroup = {
      group: k,
      accounts: liabilityGroups[k],
    };
    return r;
  });

  const assetRows = assetAccounts.map((obj, i) => {
    return (
      <>
        <BalanceSheetHeader key={`asset-${obj.group}`} title={obj.group} />
        <div key={`asset-${obj.group}-acc`}>
          {obj.accounts.accounts
            .sort((a, b) => b.amount - a.amount)
            .map((acc, i) => {
              return (
                <BalanceSheetRow key={i} name={acc.name} amount={acc.amount} />
              );
            })}
        </div>
      </>
    );
  });

  const liabilityRows = liabilityAccounts.map((obj, i) => {
    return (
      <>
        <BalanceSheetHeader key={`liabilty-${obj.group}`} title={obj.group} />
        <div key={`liability-${obj.group}-acc`}>
          {obj.accounts.accounts
            .sort((a, b) => b.amount - a.amount)
            .map((acc, i) => {
              return (
                <BalanceSheetRow key={i} name={acc.name} amount={acc.amount} />
              );
            })}
        </div>
      </>
    );
  });

  const assetTotal = assetAccounts.reduce((a, b) => {
    return a + b.accounts.total;
  }, 0);

  const liabilityTotal = liabilityAccounts.reduce((a, b) => {
    return a + b.accounts.total;
  }, 0);

  const pdfDocument = useMemo(() => {
    return (
      <BalanceSheetPDF
        assets={assetAccounts}
        assetsTotal={assetTotal}
        liabilities={liabilityAccounts}
        liabilitiesTotal={liabilityTotal}
      />
    );
  }, [assetAccounts, assetTotal, liabilityAccounts, liabilityTotal]);

  const today = new Date();
  const todayFile = format(today, "yyyy-MM-dd");
  const todayDisplay = format(today, "MMMM dd, yyyy");
  const downloadLink = useMemo(() => {
    return (
      <PDFDownloadLink
        key={accountOwnerType}
        document={pdfDocument}
        fileName={`balance-sheet-${todayFile}.pdf`}
      >
        Download PDF
      </PDFDownloadLink>
    );
  }, [accountOwnerType, pdfDocument, todayFile]);

  if (loading || collabLoading) {
    return <div>Loading your assets and liabilities...</div>;
  }
  return (
    <div>
      <div className="mt1" />
      <div className="flex-row between align-center">
        <NetworthAccountOwnerFilter alignLeft />

        {downloadLink}
      </div>
      <div className="mt1" />
      <div
        style={{
          fontWeight: 600,
          fontSize: 24,
          textAlign: "center",
        }}
      >
        Personal Balance Sheet
      </div>
      <div
        style={{
          fontSize: 12,
          textAlign: "center",
          marginBottom: 16,
        }}
      >
        {todayDisplay}
      </div>
      <div
        style={{
          backgroundColor: "#EEEEEE",
          padding: "0.5rem",
        }}
      >
        <Text style={TextStyle.M18} type={TextType.Div}>
          Assets
        </Text>
      </div>
      <div>{assetRows}</div>
      <div
        className="flex-row between"
        style={{
          padding: "0rem 0.5rem",
        }}
      >
        <div
          style={{
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <Text style={TextStyle.L14} type={TextType.Div}>
            Total Asset
          </Text>
        </div>
        <div
          style={{
            borderTop: "1px solid",
          }}
        >
          {toMoneyString(assetTotal)}
        </div>
      </div>
      <div className="mt1" />
      <div
        style={{
          backgroundColor: "#EEEEEE",
          padding: "0.5rem",
        }}
      >
        <Text style={TextStyle.M18} type={TextType.Div}>
          Liabilities
        </Text>
      </div>
      <div>{liabilityRows}</div>
      <div
        className="flex-row between"
        style={{
          margin: "0 0.5rem",
        }}
      >
        <div
          style={{
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <Text style={TextStyle.L14} type={TextType.Div}>
            Total Liabilities
          </Text>
        </div>
        <div
          style={{
            borderTop: "1px solid",
          }}
        >
          {toMoneyString(liabilityTotal)}
        </div>
      </div>
      <div className=" mt1" />
      <div
        className="flex-row between"
        style={{
          borderTop: "1px solid",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          paddingRight: "0.5rem",
        }}
      >
        <div>
          <Text style={TextStyle.M18} type={TextType.Div} weight="bold">
            Net Worth
          </Text>
        </div>
        {toMoneyString(assetTotal - liabilityTotal)}
      </div>

      <div className="mt3" />
    </div>
  );
};

const updateRecord = (
  record: Record<string, CategorizedAccount>,
  type: string,
  name: string,
  amount: number
) => {
  const k = type;

  if (_.isNil(record[k])) {
    record[k] = {
      total: 0,
      accounts: [],
    };
  }

  record[k].total += amount;
  record[k].accounts.push({
    name: name,
    amount: amount,
  });
};

const BalanceSheetHeader: React.FC<{ title: string }> = ({ title }) => {
  return <div className="balance-sheet-statement-row-header">{title}</div>;
};

const BalanceSheetRow: React.FC<{
  name: string;
  amount: number;
}> = ({ name, amount }) => {
  return (
    <div className="balance-sheet-statement-row">
      <div
        style={{
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
        }}
      >
        {name}
      </div>
      {toMoneyString(amount)}
    </div>
  );
};

const nameFromOwnerType = (t: OwnerTypeKey) => {
  switch (t) {
    case OwnerTypeKey.Any: {
      return "Include mine and shared Accounts";
    }
    case OwnerTypeKey.Spouse: {
      return "Mine and Spouse/Partner";
    }
    case OwnerTypeKey.Me: {
      return "My Accounts Only";
    }
    default:
      return "Unknown";
  }
};
