import { gql } from "@apollo/client";
import {
  AssetType,
  AuditLog,
} from "components/features/dashboard/models/sharing";

export interface CreateSharedDataMutationInput {
  collaborationID: string;
  assetID: string;
  assetType: AssetType;
}

export const createSharedDataMutation = gql`
  mutation CreateSharedData(
    $collaborationID: ID!
    $assetID: ID!
    $assetType: String!
  ) {
    createSharedData(
      collaborationID: $collaborationID
      assetID: $assetID
      assetType: $assetType
    ) {
      id
      collaborationID
      assetType
      assetID
      createdAt
      modifiedAt
      modifiedBy
    }
  }
`;

export interface DeleteSharedDataMutationInput {
  id: string;
}

export const deleteSharedDataMutation = gql`
  mutation DeleteSharedData($id: ID!) {
    deleteSharedData(id: $id)
  }
`;

export interface DeleteSharedDataByAssetMutationInput {
  id: string;
  type: AssetType;
}

export const deleteSharedDataByAssetMutation = gql`
  mutation DeleteSharedDataByAsset($id: ID!, $type: String!) {
    deleteSharedDataByAsset(id: $id, type: $type)
  }
`;

export interface GetAuditLogResponse {
  auditLogs: AuditLog[];
}
export const getAuditLogForAssetIDQuery = gql`
  query GetAuditLogForAssetID($assetID: ID!, $assetType: String!) {
    auditLogs(assetID: $assetID, assetType: $assetType) {
      id
      actionType
      actingUser {
        id
        firstName
        lastName
        email
      }
      affectedUser {
        id
        firstName
        lastName
        email
      }
      createdAt
      updateField
      updateFrom
      updateTo
    }
  }
`;

export const createViewLogMutation = gql`
  mutation CreateViewLog($assetID: ID!) {
    createViewAuditLog(assetID: $assetID) {
      id
    }
  }
`;

export const createDownloadLogMutation = gql`
  mutation CreateDownloadLog($assetID: ID!) {
    createDownloadAuditLog(assetID: $assetID) {
      id
    }
  }
`;

export interface ShareAllAssetByCollaborationMutationInput {
  collaborationID: string;
  type: AssetType;
}

export const ShareAllAssetsByCollaborationMutation = gql`
  mutation shareAllAssetsByCollaborationID(
    $collaborationID: ID!
    $type: String!
  ) {
    shareAllAssetsByCollaborationID(
      collaborationID: $collaborationID
      type: $type
    )
  }
`;

export interface RemoveAllSharedAssetsByCollaborationMutationInput {
  collaborationID: string;
  type: AssetType;
}

export const RemoveAllSharedAssetsByCollaborationMutation = gql`
  mutation removeAllSharedAssetsByCollaborationID(
    $collaborationID: ID!
    $type: String!
  ) {
    removeAllSharedAssetsByCollaborationID(
      collaborationID: $collaborationID
      type: $type
    )
  }
`;
