import * as React from "react";
import Illustration404 from "./404.svg";
import qs from "qs";
import { AccountDetailView } from "components/features/dashboard/components/account-detail-view";
import { AccountTransactionContextProvider } from "components/contexts/account-transaction-context";
import { Colour } from "../../../../../core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { DataPageContext } from "../../../../contexts/data-page-context-provider";
import { Link } from "react-router-dom";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { scrollToTop } from "core/utils/scrolling";
import { Text, TextStyle, TextType } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./index-version-2.css";
import {
  getAccountByID,
  GetAccountResponse,
} from "../../../../../core/queries/accounts";

interface Props {}

export const AccountDetailsv2: React.FC<Props> = (props: Props) => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const accountID = query["accountID"] as string;

  const { setOnUpdateRefetchQueries } = React.useContext(DataPageContext);

  React.useEffect(() => {
    setOnUpdateRefetchQueries([
      {
        query: getAccountByID,
        variables: {
          id: accountID,
        },
      },
    ]);
  }, [setOnUpdateRefetchQueries, accountID]);

  const { loading, data } = useQuery<GetAccountResponse>(getAccountByID, {
    fetchPolicy: "cache-first",
    variables: {
      id: accountID,
    },
    onError: (err) => {
      console.log("error is ", err);
    },
  });

  const account = data?.account;

  React.useEffect(() => {
    scrollToTop();
  }, []);

  if (!loading && !account) {
    return (
      <PageWrapper>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              paddingBottom: "5rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              colour={Colour.Navy}
              style={TextStyle.TitlePoppins}
              type={TextType.Div}
            >
              <h1>404</h1>
            </Text>

            <div
              style={{
                padding: "2rem",
              }}
            />
            <svg>
              <use xlinkHref={`${Illustration404}#illustration`} />
            </svg>

            <div style={{ textAlign: "center" }}>
              <Text style={TextStyle.B16}>
                The account you are trying to view has been deleted or you may
                no longer have access to it.
              </Text>
            </div>

            <Link to={DashboardPages.Integrations}>
              <Text style={TextStyle.M15SM}>BACK TO ACCOUNTS</Text>
            </Link>
          </div>
        </div>
      </PageWrapper>
    );
  }

  if (!account) {
    return null;
  }

  return (
    <PageWrapper>
      <div className=" account-details">
        <AccountTransactionContextProvider accountID={account.account_id}>
          <AccountDetailView account={account} loading={loading} fullPageView />
        </AccountTransactionContextProvider>
      </div>
    </PageWrapper>
  );
};
