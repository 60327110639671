import { CollaboratorPermission } from "components/features/dashboard/models/sharing";
import { format } from "date-fns";
import _ from "lodash";
import validFilename from "valid-filename";

const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const moneyFormatterNoDecimal = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export function toAccountString(accountMask: string) {
  return `•••${accountMask}`;
}

export function toMoneyString(
  amt: number,
  signMultiplier?: number,
  precision?: number
) {
  const adjustedAmount =
    signMultiplier && amt !== 0 ? signMultiplier * amt : amt; // -0 gets formatted into -$0
  if (!_.isNil(precision)) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }).format(amt);
  }
  return moneyFormatter.format(adjustedAmount);
}

export function pluralize(singularStr: string, count: number) {
  return singularStr + (count > 1 ? "s" : "");
}

export function toMoneyStringNoDecimal(amt: number) {
  return moneyFormatterNoDecimal.format(amt);
}

export function millisecondsReadable(millis: number) {
  let minutes = Math.floor(millis / 60000);
  if (minutes > 2880) {
    return `${Math.floor(minutes / 60 / 24)} days`;
  } else if (minutes > 60) {
    return `${Math.floor(minutes / 60)}h`;
  } else if (minutes < 1) {
    return `1 minute`;
  }
  return `${minutes} minutes`;
}

export function timeSince(date: Date) {
  const now = new Date();
  const diff = Math.abs(Math.floor((date.getTime() - now.getTime()) / 1000));
  let interval = diff / 31536000;
  let singular = Math.floor(interval) <= 1;

  if (interval > 1) {
    return `${Math.floor(interval)} ${singular ? "year" : "years"}`;
  }
  interval = diff / 2592000;
  singular = Math.floor(interval) <= 1;
  if (interval > 1) {
    return `${Math.floor(interval)} ${singular ? "month" : "months"}`;
  }
  interval = diff / 86400;
  singular = Math.floor(interval) <= 1;
  if (interval > 1) {
    return `${Math.floor(interval)} ${singular ? "day" : "days"}`;
  }
  interval = diff / 3600;
  singular = Math.floor(interval) <= 1;
  if (interval > 1) {
    return `${Math.floor(interval)} ${singular ? "hour" : "hours"}`;
  }
  interval = diff / 60;
  singular = Math.floor(interval) <= 1;
  if (interval > 1) {
    return `${Math.floor(interval)} ${singular ? "minute" : "minutes"}`;
  }
  return Math.floor(diff) + " seconds";
}

export const formatMobileNumber = (mobileNumber: string) => {
  // return nothing if no number
  if (!mobileNumber) return mobileNumber;

  // only allows 0-9 inputs
  const currentValue = mobileNumber.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) return currentValue;

  // returns: "xxx", "xxx-x", "xxx-xx", "xxx-xxx",
  if (cvLength < 7)
    return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;

  // returns: "xxx-xxx-", "xxx-xxx-x", "xxx-xxx-xx", "xxx-xxx-xxx", "xxx-xxx-xxxx"
  return `${currentValue.slice(0, 3)}-${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`;
};

export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const PHONE_REGEX = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
export const NON_EMPTY_STR = /^(.*)+$/;
export const AGE_REGEX = /^[0-9]+$/;
export const ALPHANUMERIC_REGEX = /^[0-9a-zA-Z]*$/;
export const PERCENTAGE_REGEX = /^[1-9][0-9]?\.?[0-9]?[0-9]?$|^100$|^0$/;

export const validDocumentName = (name: string): boolean => {
  return !!name && validFilename(name) && name.length < 250;
};

const SHORT_MONTH_NAMES: { [key: number]: string } = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const dateFormatMMMMDD = (date: Date) => {
  if (!date) return null;
  return `${SHORT_MONTH_NAMES[date.getMonth()]} ${date.getDate()}`;
};

export const dateFormatDateTimeMinute = (date: Date) => {
  return format(date, "yyyy-MMM-dd h:mma");
};

export const dateFormatMMMMDDYY = (date: Date) => {
  if (!date) return null;
  return `${
    SHORT_MONTH_NAMES[date.getMonth()]
  } ${date.getDate()} ${date.getFullYear()}`;
};

export const getLeadingZeroMonth = (date: Date) => {
  const monthNumber = date.getMonth() + 1;
  return monthNumber < 10 ? `0${monthNumber}` : monthNumber.toString();
};

export const getLeadingZeroDay = (date: Date) => {
  const dayNumber = date.getDate();
  return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
};

export const dateFormatYYYY_MM = (date: Date) => {
  return `${date.getFullYear()}-${getLeadingZeroMonth(date)}`;
};

export const dateFormatYYYY_MM_DD = (date: Date) => {
  return `${date.getFullYear()}-${getLeadingZeroMonth(
    date
  )}-${getLeadingZeroDay(date)}`;
};

export const formatPermission = (perm: CollaboratorPermission | string) => {
  switch (perm) {
    case "EDITOR":
      return "Edit Access";
    case "VIEWER":
      return "View Only";
  }
};

export const formatOptionalNumber = (
  n: number | undefined
): number | undefined => {
  if (n === null || n === undefined || isNaN(n)) {
    return undefined;
  }

  return n;
};

export const formatOptionalStringNumber = (
  n: string | undefined | null
): string | undefined => {
  if (n === null || n === undefined || Number.isNaN(n)) {
    return undefined;
  }

  return n;
};

export const toPercentageString = (v: number) => {
  if (isNaN(v)) {
    return "0%";
  }
  return ((v * 100)?.toFixed(2) || 0) + "%";
};

export const formatOptionalStringPercentage = (
  n: string | undefined | null
): string | undefined => {
  if (n === null || n === undefined || Number.isNaN(n)) {
    return undefined;
  }

  return toPercentageString(parseFloat(n) / 100);
};

export const formatOptionalStringMoney = (
  n: string | undefined | null,
  signMultiplier?: number,
  precision?: number
): string | undefined => {
  if (n === null || n === undefined || Number.isNaN(n)) {
    return undefined;
  }

  return toMoneyString(Number.parseFloat(n), signMultiplier, precision);
};

export const shortNumber = (value: number): string => {
  let newValue: number = Math.abs(value);
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  let asString: string;
  if (Math.round(newValue) === newValue) {
    asString = newValue.toFixed(0);
  } else {
    asString = newValue.toFixed(1);
  }

  asString += suffixes[suffixNum];
  return (value < 0 ? "-" : "") + "$" + asString;
};
