import * as React from "react";
import Select from "react-select";
import { SELECT__MODAL_INPUT_STYLE } from "components/core/input/styles";
import {
  Collaboration,
  CollaborationRelationships,
} from "core/queries/collaborations";

interface Props {
  collaborator?: Collaboration;
  onUpdate?: (newRelationship: string) => void;
}

export const EditContactRelationshipSelector: React.FC<Props> = ({
  collaborator,
  onUpdate,
}) => {
  const renderContactInfoSelector = () => {
    return (
      <>
        <Select
          isSearchable={false}
          styles={SELECT__MODAL_INPUT_STYLE}
          value={{
            value: collaborator?.relationship,
            label: collaborator?.relationship,
          }}
          onChange={(e) => {
            if (!e) {
              return;
            }
            onUpdate?.(e.value || "");
          }}
          options={Object.values(CollaborationRelationships).map((x) => {
            return {
              label: x,
              value: x,
            };
          })}
        />
      </>
    );
  };

  return <div>{renderContactInfoSelector()}</div>;
};
