import * as React from "react";
import { Account, Colour } from "core/models";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { AccountTransactionTable } from "components/features/dashboard/components/transactions/transactions-table";
import { FETCH_CASH_FLOW } from "core/queries/transactions";
import { getDailyCasfhlowQueryVariables } from "components/features/dashboard/components/chart-cashflow-daily/query";
import { ManageSubscriptionTray } from "components/features/dashboard/components/transactions/manage-subscription-rules-tray";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { SubscriptionRecurringExpenseTypes } from "components/features/dashboard/components/transactions/transaction-row";
import { Text, TextStyle, TextType } from "components/core/text";
import { useLazyQuery } from "@apollo/client";
import "./styles.css";

const limit = 10;

export const RecurringExpenseTable: React.FC = () => {
  const { pushTray } = React.useContext(SideTrayContext);
  const { transactionData, accountID, month } = React.useContext(
    AccountTransactionContext
  );

  const accountLookup: { [key: string]: Account } = {};
  transactionData?.accountTransactions.accounts?.forEach((account) => {
    accountLookup[account?.account_id] = account;
  });

  // Hack to make cross module conditional refetch work
  const [refetchDailyCashflow] = useLazyQuery(FETCH_CASH_FLOW, {
    variables: getDailyCasfhlowQueryVariables(month),
    fetchPolicy: "cache-and-network",
  });

  const onTransactionUpdate = React.useMemo(() => {
    if (!accountID) {
      return refetchDailyCashflow;
    }
    return undefined;
  }, [accountID, refetchDailyCashflow]);

  const qualifyingTransactions = (
    transactionData?.accountTransactions?.transactions ?? []
  ).filter(
    (tx) =>
      SubscriptionRecurringExpenseTypes.includes(tx.recurringExpenseType) &&
      !tx.pending
  );

  if (transactionData?.accountTransactions?.summary?.count === 0) {
    return null;
  }

  return (
    <div className="transactions-table">
      <div className="flex-row between">
        <h6>Recurring Expenses & Subscriptions</h6>
        <div
          className="flex-row"
          onClick={() => {
            pushTray({
              header: <SimpleSideTrayHeader text="Manage Subscription Rules" />,

              children: <ManageSubscriptionTray />,
            });
          }}
          style={{
            cursor: "pointer",
            marginRight: "1rem",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.Default}
            colour={Colour.Blue01}
          >
            Manage Rules
          </Text>
        </div>
      </div>

      <AccountTransactionTable
        transactions={qualifyingTransactions}
        limit={limit}
        accountID={accountID}
        accountMap={accountLookup}
        noData={(transactionData?.accountTransactions?.summary?.count || 0) > 0}
        onTransactionUpdate={onTransactionUpdate}
        subscriptionView
        showTitle={false}
      />
    </div>
  );
};
