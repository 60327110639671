import React, { useContext, useState } from "react";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon, IconAsset } from "components/core/icons";
import { Input } from "components/core/input";
import { isPasswordValid } from "components/features/login/pages/new-user";
import { LeafButton } from "components/core/leaf-button";
import { PylonPasswordChecker } from "components/features/password-checker";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SessionContext } from "components/contexts/session-context-provider";
import { Text, TextStyle, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import "./styles.css";
import {
  loggedInUserRequestPasswordReset,
  loggedInUserResetPassword,
} from "core/api";

export const PasswordReset: React.FC = () => {
  const history = useHistory();
  const { sessionToken } = useContext(SessionContext.context);
  const [resetToken, setResetToken] = useState("");
  const [password, setPassword] = React.useState("");
  const [confirmedPassword, setConfirmedPassword] = React.useState("");
  const [passwordError, setPasswordError] = useState("");

  const requestToken = () => {
    loggedInUserRequestPasswordReset(
      sessionToken,
      password,
      (data) => {
        if (!data?.idToken) {
          setPasswordError("Invalid password");
          return;
        }
        setResetToken(data?.idToken);
        setPassword("");
        setPasswordError("");
      },
      (err) => {
        setPasswordError(
          "There's an issue verifying password. Please try again"
        );
      }
    );
  };

  const resetUserPassword = () => {
    if (password !== confirmedPassword || !isPasswordValid(password)) {
      return;
    }

    loggedInUserResetPassword(
      password,
      sessionToken,
      resetToken,

      () => {
        toast(
          <PylonToastBody
            title={"Password Reset"}
            body={`Password reset successfully`}
          />
        );

        history.push(DashboardPages.Settings);
      },
      (err) => {
        toast(
          <PylonToastBody
            title={"Password Reset failed"}
            body={`An error occurred. Please try again later`}
            type={ToastType.Error}
          />
        );
      }
    );
  };

  if (!resetToken) {
    return (
      <div className="password-reset-page-border">
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => history.push(DashboardPages.Settings)}
        >
          <Icon asset={IconAsset.LeftArrow} height="32px" width="32px" />
        </div>
        <div className="password-reset-page-wrapper">
          <div className="password-page-field">
            <h6>Change Password</h6>
            <div
              className="label"
              style={{
                width: "unset",
              }}
            >
              To continue, first we need to confirm it's you
            </div>

            <div className="field-data">
              <Input
                autoFocus
                className="password-page-field"
                type="password"
                placeholder="Current password"
                value={password}
                style={{
                  maxWidth: "15rem",
                }}
                onEnter={() => {
                  requestToken();
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.currentTarget.value);
                  if (passwordError) {
                    setPasswordError("");
                  }
                }}
              />
              {passwordError && (
                <Text type={TextType.Div} style={TextStyle.Error}>
                  {passwordError}
                </Text>
              )}
            </div>
            <div style={{ paddingBottom: "1rem" }} />
            <LeafButton
              onClick={() => {
                requestToken();
              }}
            >
              Continue
            </LeafButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="password-reset-page-border">
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => history.push(DashboardPages.Settings)}
      >
        <Icon asset={IconAsset.LeftArrow} height="32px" width="32px" />
      </div>
      <div className="password-reset-page-wrapper">
        <div className="password-page-field">
          <div
            className="label"
            style={{
              width: "unset",
              paddingBottom: "1rem",
            }}
          >
            Enter your new password
          </div>

          <div className="field-data ">
            <Input
              autoFocus
              type="password"
              placeholder="New Password"
              autoComplete="chrome-off"
              value={password}
              onEnter={resetUserPassword}
              onChange={(e) => setPassword(e.target.value)}
              onBlurValidate={[
                {
                  validationFunc: (pw) => !pw || isPasswordValid(pw),
                  errorMessage:
                    "Please use a password that satisfies requirement below",
                },
              ]}
              required
            />
          </div>
          <div style={{ paddingTop: "1rem" }} />
          <div className="field-data password-page-field">
            <Input
              type="password"
              id="confirm-password"
              name="confirmpassword"
              placeholder="Confirm new password"
              autoComplete="chrome-off"
              value={confirmedPassword}
              onEnter={resetUserPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              onBlurValidate={[
                {
                  validationFunc: (pw) => !pw || pw === password,
                  errorMessage: "Password mismatch",
                },
              ]}
              required
            />
          </div>

          <div style={{ paddingTop: "1rem" }} />
          <PylonPasswordChecker password={password} />
          <LeafButton
            disabled={
              password !== confirmedPassword || !isPasswordValid(password)
            }
            onClick={resetUserPassword}
          >
            Set new password
          </LeafButton>
        </div>
      </div>
    </div>
  );
};
