import { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { PylonCurrencyInput } from "components/core/currency-input";
import { useEffect, useState } from "react";
import "./styles.css";

export interface Props {
  id: string;
  value: number | string | undefined;
  onChange: (v: string | undefined) => void;

  label?: string;
  className?: string;
  type?: "money" | "percentage" | "number";
  overrides?: CurrencyInputProps;
}

export const BufferedInput: React.FC<Props> = ({
  id,
  label,
  value,
  onChange,
  className,
  type = "money",
  overrides,
}) => {
  const [buffer, setBuffer] = useState<string | undefined>(value as string);

  useEffect(() => {
    if (value !== buffer) {
      setBuffer(value as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <PylonCurrencyInput
      id={id}
      value={buffer}
      onValueChange={(v) => setBuffer(v)}
      onBlur={() => onChange(buffer)}
      allowNegativeValue={false}
      decimalsLimit={0}
      prefix={type === "money" ? "$" : undefined}
      suffix={type === "percentage" ? "%" : undefined}
      placeholder={label ? `Enter ${label}` : undefined}
      className={`pylon-buffered-input__numeric-input ${
        className ? className : ""
      }`}
      {...overrides}
    />
  );
};
