import { ScenarioPlan } from "core/queries/scenario-plans";
import { ScenarioRecipients } from "components/features/dashboard/components/planning/calculators/scenario-recipients";
import { Text, TextStyle, TextType } from "components/core/text";

export interface Props {
  scenario?: ScenarioPlan;
}

export const PlanTitle: React.FC<Props> = ({ scenario }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingBottom: "1rem",
      }}
    >
      <Text
        type={TextType.Div}
        style={TextStyle.L14}
        size={"1.25rem"}
        weight={700}
      >
        {scenario ? scenario.scenarioName : "Unsaved scenario"}
      </Text>

      <div style={{ paddingLeft: "1rem" }} />
      <ScenarioRecipients scenario={scenario} />
    </div>
  );
};
