import React, { useState } from "react";
import { Account, HomeEquityLineOfCreditDetails } from "core/models";
import { addYears } from "date-fns/esm";
import { AutomaticPaymentDeductionToggle } from "./auto-payment-deduction-toggle";
import { getProjectedPaymentsInfo } from "./utils";
import { ModalDateInput } from "components/core/input/modal-date-input";
import { ModalInput } from "components/core/input/modal-input";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import "./styles.css";
import {
  AccountInformationValueCard,
  AutoDeductToggleDisplayCard,
  ValueCardType,
} from "./detail-card";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const ManageHomeEquityLineOfCreditDetails: React.FC<Props> = ({
  account,
  isConnected,
  displayOnly,
  onUpdate,
}) => {
  const inputDetails = account.details?.homeEquityLineOfCreditDetails;
  const [details, setDetails] = useState<
    Partial<HomeEquityLineOfCreditDetails>
  >({
    interestRate: inputDetails?.interestRate || undefined,
    term: inputDetails?.term || undefined,
    creditLimit: inputDetails?.creditLimit || undefined,
    propertyAddress: inputDetails?.propertyAddress || undefined,
    dueDate: inputDetails?.dueDate || undefined,
    monthlyPayment: inputDetails?.monthlyPayment || undefined,
  });

  const [autoUpdate, setAutoUpdate] = useState(
    !!account.automaticPaymentDeduction
  );

  const onBlurUpdate = () => {
    onUpdate?.({
      details: {
        homeEquityLineOfCreditDetails: details,
      },
    });
  };

  const paymentInfo = getProjectedPaymentsInfo(
    account.balances.current,
    details.interestRate,
    details.monthlyPayment,
    details.dueDate
  );

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Total Home Equity Line"
          value={account.details?.homeEquityLineOfCreditDetails?.creditLimit}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Monthly Payment"
          value={account.details?.homeEquityLineOfCreditDetails?.monthlyPayment}
        />

        <AccountInformationValueCard
          type={ValueCardType.Percentage}
          title="Interest Rate"
          value={account.details?.homeEquityLineOfCreditDetails?.interestRate}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Term"
          value={account.details?.homeEquityLineOfCreditDetails?.term}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Property Address"
          value={
            account.details?.homeEquityLineOfCreditDetails?.propertyAddress
          }
        />

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Due Date"
          value={account.details?.homeEquityLineOfCreditDetails?.dueDate}
        />

        <AutoDeductToggleDisplayCard
          isConnected={isConnected}
          checked={account.automaticPaymentDeduction && paymentInfo.valid}
        />
      </>
    );
  }

  return (
    <>
      <ModalInput
        id="account-creditLimit"
        type="money"
        label={"Total Home Equity Line"}
        value={details.creditLimit}
        placeholder="$50,000"
        onChange={(v) => {
          setDetails({
            ...details,
            creditLimit: v,
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-monthlyPayment"
        type="money"
        label={"Monthly Payment"}
        value={details.monthlyPayment}
        placeholder="$3,000"
        onChange={(v) => {
          setDetails({
            ...details,
            monthlyPayment: v,
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-interest-rate"
        type="percentage"
        label={"Interest Rate"}
        value={details.interestRate}
        placeholder="5%"
        onChange={(v) => {
          setDetails({
            ...details,
            interestRate: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-term"
        type="string"
        label={"Term (years)"}
        value={details.term}
        placeholder="30"
        onChange={(v) => {
          setDetails({
            ...details,
            term: v,
          });
        }}
        onBlur={onBlurUpdate}
      />
      <ModalInput
        id="account-address"
        type="string"
        label={"Address"}
        value={details.propertyAddress}
        placeholder="1600 Pennsylvania Avenue NW, Washington, DC 20500, USA"
        onChange={(v) => {
          setDetails({
            ...details,
            propertyAddress: v,
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalDateInput
        id="account-due-date"
        label="Due Date"
        placeholderText="01/20/2030"
        minDate={new Date()}
        maxDate={addYears(new Date(), 35)}
        selected={details.dueDate ? new Date(details.dueDate) : null}
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...details,
              dueDate: date?.toUTCString() ?? undefined,
            };

            setDetails(newDetails);
            onUpdate?.({
              details: {
                homeEquityLineOfCreditDetails: newDetails,
              },
            });
          }
        }}
      />

      {!isConnected && (
        <AutomaticPaymentDeductionToggle
          disabled={!paymentInfo.valid}
          checked={autoUpdate}
          onChange={() => {
            onUpdate?.({
              automaticPaymentDeduction: !autoUpdate,
            });
            setAutoUpdate(!autoUpdate);
          }}
        />
      )}
    </>
  );
};
