import * as React from "react";
import { Account } from "core/models";
import { AccountSubtypeEnum } from "components/features/dashboard/components/add-account-tray/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Link } from "react-router-dom";
import { TextD, TextStyle } from "components/core/text";
import { toMoneyString } from "core/utils";
import "./styles.css";

export interface Props {
  accounts: Account[];
}

export const NetWorthSummaryBrief: React.FC<Props> = ({ accounts }) => {
  const groupedData = React.useMemo(() => {
    let assets = 0;
    let liability = 0;

    accounts.forEach((acc) => {
      if (acc.typeProperties?.isAsset) {
        assets += acc.balances.current;
      } else if (acc.typeProperties?.isLiability) {
        liability += acc.balances.current;
      }

      // deduce the illiquid asset for mortgage
      if (
        acc.subtype === AccountSubtypeEnum.Mortgage &&
        acc.details?.mortgageDetails?.estimatedValue
      ) {
        assets += parseFloat(acc.details.mortgageDetails.estimatedValue) ?? 0;
      }
    });

    liability = liability * -1;
    return {
      assets: assets,
      liabilty: liability,
      networth: assets + liability,
    };
  }, [accounts]);

  return (
    <div className="flex-column">
      <div
        className="flex-row"
        style={{
          justifyContent: "flex-end",
          paddingRight: "0.5rem",
        }}
      >
        <Link to={DashboardPages.BalanceSheet}>
          <TextD style={TextStyle.M15SM}>Balance Sheet</TextD>
        </Link>
      </div>

      <div className="networth-summary-brief summary-box">
        <div className="networth-summary-content flex-row between mobile-flex-column">
          <div>
            <div>
              <div
                className={`number   ${
                  groupedData.networth >= 0 ? "green-01" : "red-01"
                }`}
              >
                {toMoneyString(groupedData.networth)}
              </div>
              <div className="label">Total Net Worth</div>
              <div className="holding-name"> </div>
            </div>
          </div>

          <div>
            <div>
              <div
                className={`number ${
                  groupedData.assets >= 0 ? "green-01" : "red-01"
                }`}
              >
                {toMoneyString(groupedData.assets)}
              </div>
              <div className="label">Assets</div>
              <div className="holding-name"> </div>
            </div>
          </div>

          <div>
            <div>
              <div
                className={`number ${
                  groupedData.liabilty >= 0 ? "green-01" : "red-01"
                }`}
              >
                {toMoneyString(Math.abs(groupedData.liabilty))}
              </div>
              <div className="label">Liabilities</div>
              <div className="holding-name"> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
