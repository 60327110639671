import * as React from "react";
import { Account } from "core/models";
import { AccountTransaction, FETCH_CASH_FLOW } from "core/queries/transactions";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { AccountTransactionTable } from "components/features/dashboard/components/transactions/transactions-table";
import { getDailyCasfhlowQueryVariables } from "components/features/dashboard/components/chart-cashflow-daily/query";
import { SubscriptionRecurringExpenseTypes } from "components/features/dashboard/components/transactions/transaction-row";
import { TextD, TextStyle } from "components/core/text";
import { useLazyQuery } from "@apollo/client";
import "./styles.css";

const limit = 10;

export const DuplicatedExpensesTable: React.FC = () => {
  const { transactionData, accountID, month } = React.useContext(
    AccountTransactionContext
  );

  const accountLookup: { [key: string]: Account } = {};
  transactionData?.accountTransactions.accounts?.forEach((account) => {
    accountLookup[account?.account_id] = account;
  });

  // Hack to make cross module conditional refetch work
  const [refetchDailyCashflow] = useLazyQuery(FETCH_CASH_FLOW, {
    variables: getDailyCasfhlowQueryVariables(month),
    fetchPolicy: "cache-and-network",
  });

  const onTransactionUpdate = React.useMemo(() => {
    if (!accountID) {
      return refetchDailyCashflow;
    }
    return undefined;
  }, [accountID, refetchDailyCashflow]);

  const qualifyingTransactions = (
    transactionData?.accountTransactions?.transactions ?? []
  ).filter(
    (tx) =>
      SubscriptionRecurringExpenseTypes.includes(tx.recurringExpenseType) &&
      !tx.pending
  );

  if (transactionData?.accountTransactions?.summary?.count === 0) {
    return null;
  }

  const groupedTransaction: { [key: string]: AccountTransaction[] } = {};

  qualifyingTransactions.forEach((t) => {
    if (t.merchantName) {
      if (!groupedTransaction[t.merchantName]) {
        groupedTransaction[t.merchantName] = [];
      }
      groupedTransaction[t.merchantName].push(t);
    }
  });
  return (
    <div className="transactions-table">
      <div className="flex-row between">
        <h6>Duplicated Expenses & Subscriptions</h6>
      </div>

      {Object.keys(groupedTransaction)
        .filter((g) => {
          const uniqueOwners = unique(
            groupedTransaction[g].map((t) => {
              const acc = accountLookup[t.accountID];
              if (acc) {
                return acc.owner?.id ?? "";
              }
              return "";
            })
          );
          if (uniqueOwners.length < 2) {
            //
            return false;
          }
          return true;
        })
        .map((g) => {
          return (
            <>
              <TextD style={TextStyle.M15SM}>From: {g}</TextD>
              <AccountTransactionTable
                transactions={groupedTransaction[g]}
                limit={limit}
                accountID={accountID}
                accountMap={accountLookup}
                noData={
                  (transactionData?.accountTransactions?.summary?.count || 0) >
                  0
                }
                onTransactionUpdate={onTransactionUpdate}
                subscriptionView
                showTitle={false}
              />
            </>
          );
        })}
    </div>
  );
};

const unique = (items: any[]) => {
  return [...new Set(items)];
};
