import React, { useContext, useState } from "react";
import { MutationWrapper } from "core/queries/mutation";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { toast } from "react-toastify";
import { TrustedBeneficiaryCard } from "components/features/dashboard/components/trusted-beneficiary/card";
import { useMutation } from "@apollo/client";
import {
  ManageCollaboratorHeader,
  ManageCollaboratorTray,
} from "components/features/dashboard/components/manage-collaborator-tray";
import {
  Collaboration,
  UpdateCollaborationMutation,
  UpdateCollaborationMutationInput,
  UpdateCollaborationMutationResponse,
} from "core/queries/collaborations";

interface TrayProps {
  collaborator: Collaboration;
  isSelected?: boolean;
}

export const EditableBeneficiaryCard: React.FC<TrayProps> = ({
  collaborator,
  isSelected,
}) => {
  const { pushTray, popTray } = useContext(SideTrayContext);

  const [updateInput, setUpdateInput] =
    useState<UpdateCollaborationMutationInput>({
      id: collaborator.id,
    });

  const ref =
    React.useRef<Partial<UpdateCollaborationMutationInput>>(updateInput);

  const onCompleteMutation = () =>
    toast(
      <PylonToastBody
        title={"Contact Updated"}
        body={"Contact information successfully updated."}
      />
    );
  const onErrorMutation = (err: Error) => {
    console.error("failed to update contact info", err);
    toast(
      <PylonToastBody
        type={ToastType.Error}
        title={"Contact Error"}
        body={err.message ?? "An unexpected error occurred. Please try again"}
      />
    );
  };

  const [updateContact] = useMutation<
    UpdateCollaborationMutationResponse,
    MutationWrapper<UpdateCollaborationMutationInput>
  >(UpdateCollaborationMutation, {
    onCompleted: () => {
      setUpdateInput({ id: collaborator.id });
      ref.current = { id: collaborator.id };
      onCompleteMutation();
    },
    onError: (err) => {
      onErrorMutation(err);
    },
  });

  const triggerUpdate = () => {
    if (Object.keys(ref.current).filter((x) => x !== "id").length > 0) {
      updateContact({
        variables: {
          input: {
            id: collaborator.id,
            ...ref.current,
          },
        },
      });
    }
  };

  return (
    <div className="manage-trusted-contact-wrapper">
      <TrustedBeneficiaryCard
        collaborator={collaborator}
        editable={true}
        selected={isSelected}
        onClick={() => {
          pushTray({
            id: "manage-collaborator",
            header: <ManageCollaboratorHeader edit={true} />,
            children: (
              <ManageCollaboratorTray
                onClose={() => popTray()}
                manageUserInput={{
                  collaborator: collaborator,
                  updateInput: ref,
                  triggerUpdate: triggerUpdate,
                  onUpdateInput: (s) => {
                    ref.current = s;
                    setUpdateInput({
                      ...s,
                      id: collaborator.id,
                    });
                  },
                }}
              />
            ),
            onClose: () => {
              triggerUpdate();
            },
          });
        }}
      />
    </div>
  );
};
