import * as React from "react";
import classNames from "classnames";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import "./styles.css";

export interface Props {
  text: string;

  showBack?: boolean;
}

export const SimpleSideTrayHeader: React.FC<Props> = ({ text, showBack }) => {
  const { popTray } = React.useContext(SideTrayContext);

  const headerClass = classNames({
    "side-tray-header__wrapper": true,
    "side-tray-header__wrapper__back": showBack,
  });
  return (
    <div className="flex-row align-center">
      {showBack && (
        <div
          className="flex-row align-center"
          onClick={() => {
            popTray();
          }}
          style={{ cursor: "pointer" }}
        >
          <Icon asset={IconAsset.Back} width="36px" height="36px" />
        </div>
      )}
      <div className={headerClass}>
        <h5>
          {text}
          <Icon
            asset={IconAsset.Dot}
            width="6px"
            height="6px"
            colour={Colour.Blue01}
          />
        </h5>
      </div>
    </div>
  );
};
