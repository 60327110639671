export class PlanSerializer {
  serialize(data: any): string {
    return JSON.stringify(data);
  }

  deserialize<T>(d: string): T {
    return JSON.parse(d);
  }
}

export const PlanDataSerializer = new PlanSerializer();
