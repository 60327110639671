import * as React from "react";

export interface Props {
  src: string;
  className?: string;
}

export const ImageViewer: React.FC<Props> = ({ src, className }) => {
  return (
    <img
      alt="file"
      width="100%"
      height="100%"
      className={className}
      src={src}
    />
  );
};
