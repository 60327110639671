import * as React from "react";
import Modal from "react-modal";
import { ArrayFlowWrapper } from "components/features/dashboard/components/array-flow-wrapper";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";

interface Props {
  showModal: boolean;
  onComplete?: () => void;
  onClose: () => void;
}

export const CreditModal: React.FC<Props> = ({
  showModal,
  onComplete,
  onClose,
}) => {
  return (
    <Modal
      shouldCloseOnEsc
      isOpen={showModal}
      onRequestClose={() => onClose()}
      className="modal-base credit-score-modal"
      overlayClassName="overlay-base"
      style={{
        content: {
          width: "55rem",
          minHeight: "fit-content",
          maxHeight: "44rem",
          overflowY: "auto",
        },
        overlay: {
          backgroundColor: "rgba(3, 12, 23, 0.7)",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "1rem",
          right: "1.5rem",
          width: "auto",
          cursor: "pointer",
        }}
        onClick={() => {
          onClose();
        }}
      >
        <Icon
          asset={IconAsset.Close}
          width="2rem"
          height="2rem"
          colour={Colour.Text02}
        />
      </div>
      <div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ marginBottom: 0 }}>
            Free Credit Score
            <Icon
              asset={IconAsset.Dot}
              width=".25rem"
              height=".25rem"
              colour={Colour.Blue01}
            />
          </h6>
        </div>
        <div>
          <ArrayFlowWrapper
            onClose={() => onClose()}
            onComplete={() => {
              onComplete?.();
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
