import * as React from "react";
import PngManageAccs from "core/assets/onboarding-manage-accs.png";
import { Colour } from "core/models";
import { PylonTip } from "components/core/pylon-tip";
import { Text, TextFormat, TextStyle } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";

export const OnboardingUnifiedViewPresentation: React.FC = () => {
  const mobileLayout = useMobileLayout();
  const rendered = React.useMemo(() => {
    const o = [
      <>
        <div className="onboarding-screenshots" key={0}>
          <div
            style={{
              margin: "1vh 0",
            }}
          >
            <PylonTip />
          </div>
          <h4
            style={{
              margin: "1rem 0",
              marginBottom: "2vh",
              fontSize: "3.5vh",
              lineHeight: "3.5vh",
            }}
          >
            View all of your accounts real-time.
          </h4>
          <div className="onboarding-screenshots-paragraph">
            Pylon supports <span style={{ color: "var(--blue-01)" }}>any</span>{" "}
            account type allowing you to aggregate all your financial accounts
            and documents in one platform.
          </div>
          <div
            style={{
              marginTop: "4vh",
            }}
          >
            <div>
              <Text
                style={TextStyle.FieldLabel}
                weight={700}
                size={"0.875rem"}
                format={TextFormat.UpperCase}
              >
                Account Types
              </Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                  paddingRight: "1rem",
                }}
              >
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Banking
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Credit Card
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Retirement
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                  paddingRight: "1rem",
                }}
              >
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    stocks
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    property
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    insurance
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                  paddingRight: "1rem",
                }}
              >
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    and more
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      <div className={"manage-img"} key={1}>
        <img src={PngManageAccs} alt="Manage Account" />
      </div>,
    ];
    return mobileLayout ? o.reverse() : o;
  }, [mobileLayout]);

  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            Pylon provides{" "}
            <span style={{ color: "var(--blue-01)" }}>one unified view</span> of
            all your accounts
            <span style={{ color: "var(--blue-01)" }}>.</span>
          </div>
        </div>
      </div>
      <div className="onboarding-middle">{rendered}</div>
    </div>
  );
};
