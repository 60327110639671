import { Account } from "../models";
import {
  AccountSubType,
  AccountType,
  SubtypesWithCalculatedBalance,
} from "../../components/features/dashboard/components/add-account-tray/models";

export const isBalanceEditable = (acc: Account) => {
  if (!acc.isOwnerOrEditor) {
    return false;
  }
  if (SubtypesWithCalculatedBalance.includes(acc.subtype)) {
    return false;
  }
  return true;
};

// Option type of react-select
export interface Option {
  value: string;
  label: string;
  type?: string;
}

export interface OptionGroup {
  label: string;
  options: Option[];
}

export const GetCategoryGroupings = () => {
  const optionGroupsMap: Map<string, Option[]> = new Map();
  const optionGroup: OptionGroup[] = [];

  for (let type in AccountType) {
    if (type === AccountType.Taxes || type === AccountType.Payroll) {
      continue;
    }
    let options: Option[] = [];
    for (let subtype in AccountSubType[
      AccountType[type as keyof typeof AccountType] as AccountType
    ])
      options.push({
        value: subtype,
        label: subtype,
        type,
      });
    optionGroupsMap.set(type, options);
  }

  // use optionGroup here as the "Other" category, all categories after the top 5
  let index = 0;
  optionGroupsMap.forEach((x, y) => {
    if (index >= 6) {
      optionGroup.push({
        label: AccountType[y as keyof typeof AccountType],
        options: x,
      });
    }
    index++;
  });

  return {
    optionGroupsMap,
    optionGroup,
  };
};

export const GetCategoryGroupsV2 = () => {
  const optionGroupsMap: Map<string, Option[]> = new Map();
  for (let type in AccountType) {
    if (type === AccountType.Taxes || type === AccountType.Payroll) {
      continue;
    }
    let options: Option[] = [];
    const typeStr = AccountType[type as keyof typeof AccountType];

    for (let subtype in AccountSubType[typeStr])
      options.push({
        value: subtype,
        label: subtype,
        type: typeStr,
      });
    optionGroupsMap.set(typeStr, options);
  }
  return {
    optionGroupsMap,
  };
};
