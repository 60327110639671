import React from "react";
import Select from "react-select";
import { BufferedInput } from "core/components/buffered-input";
import { TaxStateResults } from "components/features/landing/components/diff-state-taxes/tax-results";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";
import {
  FilingStatus,
  getAllStates,
  getLocalities,
  getStateRules,
  TaxCalculationInput,
  TaxCalculator,
} from "@pylon/taxes";

export const StateTaxDifference: React.FC = () => {
  const isMobileLayout = useMobileLayout();

  const [filingStatus, setFilingStatus] = React.useState<FilingStatus>(
    FilingStatus.Single
  );
  const [income, setIncome] = React.useState("100000");
  const [spouseIncome, setSpouesIncome] = React.useState("100000");
  const [state, setState] = React.useState("MD");
  const [locality, setLocality] = React.useState(defaultLocality(state));
  const [totalExemptions, setTotalExemptions] = React.useState("0");

  const [newState, setNewState] = React.useState("FL");
  const [newLocality, setNewLocality] = React.useState(
    defaultLocality(newState)
  );

  const localityValues = React.useMemo(() => {
    return getLocalityValues(state);
  }, [state]);
  const newLocalityValues = React.useMemo(() => {
    return getLocalityValues(newState);
  }, [newState]);

  const stateRules = React.useMemo(() => {
    return getStateRules(state);
  }, [state]);
  const newStateRules = React.useMemo(() => {
    return getStateRules(newState);
  }, [newState]);

  const filingStatusValues = Object.keys(FilingStatus)
    .filter((s) => {
      return s !== FilingStatus.QualifyingWidower;
    })
    .map((s) => {
      return {
        value: s,
        label: s.split(/(?=[A-Z])/).join(" "),
      };
    });

  const stateValues = getAllStates()
    .map((stateAbbreviation) => {
      return {
        value: stateAbbreviation,
        label: stateAbbreviation,
      };
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  const currentTaxResult = React.useMemo(() => {
    const taxInputs: TaxCalculationInput = {
      stateAbbreviation: state,
      locality: locality,
      filingStatus: filingStatus,
      income:
        parseFloat(income) +
        (filingStatus === FilingStatus.MarriedFilingJointly
          ? parseFloat(spouseIncome) ?? 0
          : 0),
      exemptions: parseFloat(totalExemptions),
    };
    const calculator = new TaxCalculator(taxInputs);

    return {
      outcome: calculator.getTaxOutcome(),
      inputState: state,
    };
  }, [filingStatus, income, locality, spouseIncome, state, totalExemptions]);

  const newTaxResult = React.useMemo(() => {
    const taxInputs: TaxCalculationInput = {
      stateAbbreviation: newState,
      locality: newLocality,
      filingStatus: filingStatus,
      income:
        parseFloat(income) +
        (filingStatus === FilingStatus.MarriedFilingJointly
          ? parseFloat(spouseIncome) ?? 0
          : 0),
      exemptions: parseFloat(totalExemptions),
    };
    const calculator = new TaxCalculator(taxInputs);

    return {
      outcome: calculator.getTaxOutcome(),
      inputState: newState,
    };
  }, [
    filingStatus,
    income,
    newLocality,
    newState,
    spouseIncome,
    totalExemptions,
  ]);

  return (
    <div>
      <div
        className="flex-row between mobile-flex-column"
        style={{
          alignItems: isMobileLayout ? "unset" : "center",
        }}
      >
        <div className="taxes__state-title">
          <div>
            <div className="taxes__state-section-header">Basic Information</div>
            <div className="taxes__state-section-description">
              Tell us about your current situation
            </div>
          </div>
        </div>
        <div
          className="flex-row between mobile-flex-column"
          style={{
            flexBasis: "60%",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <div className="taxes__cell-2">
            <div className="taxes__state-label">Filing Status</div>
            <Select
              id="filing-status"
              onChange={(e) => {
                if (!e) return;
                const newStatus =
                  FilingStatus[e.value as keyof typeof FilingStatus];
                setFilingStatus(newStatus);
              }}
              value={filingStatusValues.find((v) => {
                return v.value === filingStatus;
              })}
              isSearchable={false}
              options={filingStatusValues}
              className="taxes__state-dropdown"
            />
          </div>
          <div className="taxes__cell-2">
            <div className="taxes__state-label">Your Annual Gross Income</div>
            <BufferedInput
              id="your-income"
              className="taxes__state-input"
              label="Your Gross income"
              value={income}
              onChange={(v) => setIncome(v || "")}
            />
          </div>
          <div className="taxes__cell-2">
            <div className="taxes__state-label">
              Total Tax Exemptions (optional)
            </div>
            <BufferedInput
              id="total-tax-exemption"
              className="taxes__state-input"
              label="Total Exemptions (optional)"
              value={totalExemptions}
              onChange={(v) => setTotalExemptions(v || "")}
            />
          </div>
          {filingStatus === FilingStatus.MarriedFilingJointly ? (
            <div className="taxes__cell-2">
              <div className="taxes__state-label">Your Spouse's Income</div>
              <BufferedInput
                id="spouse-income"
                className="taxes__state-input"
                label="Your Spouse's income"
                value={spouseIncome}
                onChange={(v) => setSpouesIncome(v || "")}
              />
            </div>
          ) : (
            <div className="taxes__cell-2" />
          )}
        </div>
      </div>

      <div className="mt3" />
      <hr />
      <div className="mt3" />

      <div
        className="mt2 mobile-flex-row"
        style={{
          gap: "1rem",
        }}
      >
        <div className="flex-row between mobile-flex-column taxes__cell-2">
          <div className="taxes__state-title">
            <div className="taxes__state-section-header">Current State</div>
            <div className="taxes__state-section-description">
              Which state do you live right now?
            </div>
          </div>
          <div
            className="flex-row between mobile-flex-column"
            style={{
              flexBasis: "60%",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <div className="taxes__cell-2">
              <div className="taxes__state-label">State</div>
              <Select
                id="set-state"
                onChange={(e) => {
                  if (!e) return;

                  setState(e.value);

                  let newLocality = null;
                  if (getStateRules(state).localityTaxes) {
                    newLocality = getLocalities(state)[0];
                  } else {
                    newLocality = "";
                  }
                  setLocality(newLocality);
                }}
                isSearchable={false}
                value={stateValues.find((v) => {
                  return v.value === state;
                })}
                options={stateValues}
                className="taxes__state-dropdown"
              />
            </div>

            {stateRules.localityTaxes ? (
              <div className="taxes__cell-2">
                <div className="taxes__state-label">Locality</div>
                <Select
                  id="set-locality"
                  onChange={(e) => {
                    if (!e) return;
                    setLocality(e.value);
                  }}
                  value={localityValues.find((v) => {
                    return v.value === locality;
                  })}
                  isSearchable={false}
                  options={localityValues}
                  className="taxes__state-dropdown"
                />
              </div>
            ) : (
              <div className="taxes__cell-2" />
            )}
          </div>
        </div>
      </div>
      <div className="mt3" />
      <hr />
      <div className="mt3" />
      <div
        className="mt2 mobile-flex-row"
        style={{
          gap: "1rem",
        }}
      >
        <div className="flex-row between mobile-flex-column">
          <div className="taxes__state-title">
            <div className="taxes__state-section-header">New State</div>
            <div className="taxes__state-section-description">
              Which state are you thinking about moving to?
            </div>
          </div>
          <div
            className="flex-row between mobile-flex-column"
            style={{
              gap: "1rem",
              flexBasis: "60%",
            }}
          >
            <div className="taxes__cell-2">
              <div className="taxes__state-label"> New State</div>
              <Select
                id="set-new-state"
                onChange={(e) => {
                  if (!e) return;

                  setNewState(e.value);

                  let newLocality = null;
                  if (getStateRules(state).localityTaxes) {
                    newLocality = getLocalities(state)[0];
                  } else {
                    newLocality = "";
                  }
                  setNewLocality(newLocality);
                }}
                isSearchable={false}
                value={stateValues.find((v) => {
                  return v.value === newState;
                })}
                options={stateValues}
                className="taxes__state-dropdown"
              />
            </div>

            {newStateRules.localityTaxes ? (
              <div className="taxes__cell-2">
                <div className="taxes__state-label">New Locality</div>
                <Select
                  id="set-new-locality"
                  onChange={(e) => {
                    if (!e) return;
                    setNewLocality(e.value);
                  }}
                  value={newLocalityValues.find((v) => {
                    return v.value === newLocality;
                  })}
                  isSearchable={false}
                  options={newLocalityValues}
                  className="taxes__state-dropdown"
                />
              </div>
            ) : (
              <div className="taxes__cell-2" />
            )}
          </div>
        </div>
        <div className="mt3" />

        <TaxStateResults
          currentStateTax={currentTaxResult}
          newStateTax={newTaxResult}
        />
      </div>
    </div>
  );
};

const defaultLocality = (state: string) => {
  return getStateRules(state).localityTaxes ? getLocalities(state)[0] : "";
};

const getLocalityValues = (state: string) => {
  const rules = getStateRules(state);
  const o = rules?.localityTaxes
    ? getLocalities(state)?.map((l: string) => {
        return {
          label: l.replace(/_/g, " "),
          value: l,
        };
      }) || []
    : [];
  return o;
};
