import * as React from "react";
import _ from "lodash";
import { dateFormatYYYY_MM } from "core/utils";
import { format, parse } from "date-fns";
import { MONTH_SELECTOR_FORMAT } from "components/features/dashboard/components/transaction-month-selector";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import {
  AccountTransaction,
  AccountTransactionsInput,
  AccountTransactionsResponse,
  FETCH_TRANSACTIONS,
} from "core/queries/transactions";

export interface AccountTransactionContextValue {
  month: string;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  accountID: string | undefined;
  setAccountID: React.Dispatch<React.SetStateAction<string | undefined>>;
  transactionData: AccountTransactionsResponse | undefined;
  getPageOfData: (
    limit: number,
    offset: number
  ) => AccountTransaction[] | undefined;

  transactionDataLoading: boolean;
  transactionMerchants: string[];
  monthDate: Date;
}

interface Props {
  children: React.ReactNode;
  initialMonth?: string;
  accountID?: string;
  limitToUserIDs?: string[];
}

export const AccountTransactionContext =
  React.createContext<AccountTransactionContextValue>({
    month: format(new Date(), "yyyy-MM"),
    setMonth: () => null,
    accountID: "",
    setAccountID: () => null,
    transactionData: undefined,
    getPageOfData: () => undefined,
    transactionDataLoading: true,
    transactionMerchants: [],
    monthDate: new Date(),
  });

export const AccountTransactionContextProvider: React.FC<Props> = (
  props: Props
) => {
  const [month, setMonth] = useState(
    props.initialMonth ?? `${dateFormatYYYY_MM(new Date())}`
  );
  const [currentFetchedMonth, setCurrentFetchedMonth] = useState(month);

  const [accountID, setAccountID] = useState(props.accountID);

  const {
    data,
    refetch,
    loading: transactionDataLoading,
  } = useQuery<AccountTransactionsResponse, AccountTransactionsInput>(
    FETCH_TRANSACTIONS,
    {
      fetchPolicy: "cache-first",
      variables: {
        month,
        accountID,
        userIDs: props.limitToUserIDs,
      },
      skip: props.limitToUserIDs && props.limitToUserIDs.length < 1,
      onCompleted: () => {
        setCurrentFetchedMonth(month);
      },
    }
  );

  React.useEffect(() => {
    if (currentFetchedMonth !== month) {
      refetch({
        accountID,
        month,
      });
    }
  }, [accountID, month, currentFetchedMonth, refetch]);

  const getPageOfData = React.useCallback(
    (limit: number, offset: number) => {
      const dataToReturn = data?.accountTransactions?.transactions
        ?.slice()
        .slice(offset, limit + offset);
      return dataToReturn;
    },
    [data?.accountTransactions?.transactions]
  );

  const transactionMerchants =
    [
      ...new Set(
        data?.accountTransactions?.transactions
          ?.filter((tx) => !_.isEmpty(tx.merchantName))
          .map((tx) => tx.merchantName)
          .sort()
      ),
    ] ?? [];
  return (
    <AccountTransactionContext.Provider
      value={{
        month,
        setMonth,
        accountID,
        setAccountID,
        transactionData: data,
        getPageOfData,
        transactionDataLoading: transactionDataLoading,
        transactionMerchants,
        monthDate: parse(month, MONTH_SELECTOR_FORMAT, new Date()),
      }}
    >
      {props.children}
    </AccountTransactionContext.Provider>
  );
};
