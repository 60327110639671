export enum Colour {
  Navy = "var(--navy)",
  Text01 = "var(--text-01)",
  Text02 = "var(--text-02)",
  Text03 = "var(--text-03)",
  Text04 = "var(--text-04)",
  Text05 = "var(--text-05)",
  Bg01 = "var(--bg-01)",
  Bg02 = "var(--bg-02)",
  Bg03 = "var(--bg-03)",
  Separator01 = "var(--separator-01)",
  Separator02 = "var(--separator-02)",

  Gray70 = "var(--gray-70)",
  Gray30 = "var(--gray-30)",
  Gray10 = "var(--gray-10)",
  Gray06 = "var(--gray-06)",
  Gray02 = "var(--gray-02)",
  Blue01 = "var(--blue-01)",
  Ornage01 = "var(--orange-01)",
  Yellow01 = "var(--yellow-01)",
  Green01 = "var(--green-01)",
  Purple01 = "var(--purple-01)",
  Pink01 = "var(--pink-01)",
  Red01 = "var(--red-01)",
  Blue02 = "var(--blue-02)",
  Orange02 = "var(--orange-02)",
  Yellow02 = "var(--yellow-02)",
  Green02 = "var(--green-02)",
  Purple02 = "var(--purple-02)",
  Pink02 = "var(--pink-02)",
  Red02 = "var(--red-02)",
  Red03 = "var(--red-03)",
  Blue03 = "var(--blue-03)",
  Blue04 = "var(--blue-04)",
  Green03 = "var(--green-03)",
  Green04 = "var(--green-04)",
  Purple03 = "var(--purple-03)",
  Purple04 = "var(--purple-04)",
  Orange = "var(--orange)",
  White = "#FFFFFF",
  Black = "#000000",
  AltBlue = "var(--alt-blue)",
  AltBlue2 = "var(--alt-blue-2)",

  Gray900 = "var(--gray-900)",
  Gray800 = "var(--gray-800)",
  Gray700 = "var(--gray-700)",
  Gray600 = "var(--gray-600)",
  Gray500 = "var(--gray-500)",
  Gray400 = "var(--gray-400)",
  Gray300 = "var(--gray-300)",
  Gray200 = "var(--gray-200)",
  Gray100 = "var(--gray-100)",
  Gray50 = "var(--gray-50)",

  Purple900 = "var(--purple-900)",
  Purple800 = "var(--purple-800)",
  Purple700 = "var(--purple-700)",
  Purple600 = "var(--purple-600)",
  Purple500 = "var(--purple-500)",
  Purple400 = "var(--purple-400)",
  Purple300 = "var(--purple-300)",
  Purple200 = "var(--purple-200)",
  Purple100 = "var(--purple-100)",
  Purple50 = "var(--purple-50)",

  Blue900 = "var(--blue-900)",
  Blue800 = "var(--blue-800)",
  Blue700 = "var(--blue-700)",
  Blue600 = "var(--blue-600)",
  Blue500 = "var(--blue-500)",
  Blue400 = "var(--blue-400)",
  Blue300 = "var(--blue-300)",
  Blue200 = "var(--blue-200)",
  Blue100 = "var(--blue-100)",
  Blue50 = "var(--blue-50)",

  Cyan900 = "var(--cyan-900)",
  Cyan800 = "var(--cyan-800)",
  Cyan700 = "var(--cyan-700)",
  Cyan600 = "var(--cyan-600)",
  Cyan500 = "var(--cyan-500)",
  Cyan400 = "var(--cyan-400)",
  Cyan300 = "var(--cyan-300)",
  Cyan200 = "var(--cyan-200)",
  Cyan100 = "var(--cyan-100)",

  Green900 = "var(--green-900)",
  Green800 = "var(--green-800)",
  Green700 = "var(--green-700)",
  Green600 = "var(--green-600)",
  Green500 = "var(--green-500)",
  Green400 = "var(--green-400)",
  Green300 = "var(--green-300)",
  Green200 = "var(--green-200)",
  Green100 = "var(--green-100)",
  Green50 = "var(--green-50)",

  Yellow900 = "var(--yellow-900)",
  Yellow800 = "var(--yellow-800)",
  Yellow700 = "var(--yellow-700)",
  Yellow600 = "var(--yellow-600)",
  Yellow500 = "var(--yellow-500)",
  Yellow400 = "var(--yellow-400)",
  Yellow300 = "var(--yellow-300)",
  Yellow200 = "var(--yellow-200)",
  Yellow100 = "var(--yellow-100)",
  Yellow50 = "var(--yellow-50)",

  Orange900 = "var(--orange-900)",
  Orange800 = "var(--orange-800)",
  Orange700 = "var(--orange-700)",
  Orange600 = "var(--orange-600)",
  Orange500 = "var(--orange-500)",
  Orange400 = "var(--orange-400)",
  Orange300 = "var(--orange-300)",
  Orange200 = "var(--orange-200)",
  Orange100 = "var(--orange-100)",

  Red900 = "var(--red-900)",
  Red800 = "var(--red-800)",
  Red700 = "var(--red-700)",
  Red600 = "var(--red-600)",
  Red500 = "var(--red-500)",
  Red400 = "var(--red-400)",
  Red300 = "var(--red-300)",
  Red200 = "var(--red-200)",
  Red100 = "var(--red-100)",
  Red50 = "var(--red-50)",
}
