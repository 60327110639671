import classNames from "classnames";
import React from "react";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon } from "components/core/icons";
import { Text, TextStyle, TextType } from "components/core/text";
import { useHistory } from "react-router";
import "./styles.css";
import {
  PlanOptionAsset,
  PlanOptions,
} from "components/features/dashboard/components/planning/models";

export interface Props {
  selectedOption?: PlanOptions;
}

export const SelectPlanOption: React.FC<Props> = ({ selectedOption }) => {
  const history = useHistory();
  const unselectedFill = "#E8EFFF";
  const unselectedStroke = "#8493A6";

  const selectedFill = "#E1FFF1";
  const selectedStroke = "#57BA8D";

  const options = Object.keys(PlanOptionAsset).map((opt) => {
    const selected = selectedOption === opt;
    const option = PlanOptionAsset[opt as PlanOptions];

    const className = classNames({
      "plan-option": true,
      "plan-option-selected": selected,
    });
    return (
      <div
        key={opt}
        id={`select-option-${opt}`}
        className={className}
        onClick={() => {
          if (selected) {
            history.push(`${DashboardPages.Calculators}`);
          } else {
            history.push(`${DashboardPages.Calculators}?option=${opt}`);
          }
        }}
      >
        <div className="plan-option__icon">
          <Icon
            width="56"
            height="56"
            colour={selected ? selectedFill : unselectedFill}
            strokeColour={selected ? selectedStroke : unselectedStroke}
            asset={option.asset}
          />
        </div>
        <div className="plan-option__name">
          <Text type={TextType.Div} style={TextStyle.B14}>
            {option.name}
          </Text>
        </div>
      </div>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {options}
    </div>
  );
};
