import CurrencyInput from "react-currency-input-field";
import { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";
import "./styles.css";

export const PylonCurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  className,
  ...props
}) => {
  const sanitizedValue =
    value === null || Number.isNaN(value) ? undefined : value; // Null is displayed as NaN by currency input
  return (
    <CurrencyInput
      {...props}
      className={`pylon-currency-input ${className} pylon-input`}
      value={sanitizedValue}
    />
  );
};
