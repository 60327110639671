import { LandingPageCashflow1a } from "components/features/landing/cashflow";
import { LandingPageNetworth1a } from "components/features/landing/networth";
import { LandingPageWrapper, PylonRoute } from "pages";
import { Logo } from "components/core/logo";
import { Route, Switch } from "react-router";
import "./all-landing.css";
import {
  LandingPageTaxes1,
  LandingPageTaxes1a,
  LandingPageTaxes2,
  LandingPageTaxes2a,
  LandingPageTaxes3,
  LandingPageTaxes3a,
  LandingPageTaxes3b,
  LandingPageTaxes9,
  LandingPageTaxes9a,
  LandingPageTaxes9b,
} from "components/features/landing/taxes";

export const LandingPages: React.FC<{}> = () => {
  return (
    <div className="public-page-wrapper">
      <div className="public-page-content-wrapper landing-page">
        <Logo redirectTo={"/landing/main"} />
        <Switch>{renderRoutes()}</Switch>
      </div>
    </div>
  );
};

export interface LandingPageDefinition {
  Component: React.FC;
  Path: string;
}
const renderRoutes = () => {
  const newRoute = (routeDef: LandingPageDefinition) => {
    return {
      route: routeDef.Path,
      component: LandingPageWrapper(routeDef.Component),
      index: 0, // this is ignored below
      exact: true,
    };
  };

  const routes: PylonRoute[] = [
    newRoute(LandingPageTaxes1),
    newRoute(LandingPageTaxes1a),
    newRoute(LandingPageTaxes2),
    newRoute(LandingPageTaxes2a),
    newRoute(LandingPageTaxes3),
    newRoute(LandingPageTaxes3a),
    newRoute(LandingPageTaxes3b),
    newRoute(LandingPageTaxes9),
    newRoute(LandingPageTaxes9a),
    newRoute(LandingPageTaxes9b),
    newRoute(LandingPageCashflow1a),
    newRoute(LandingPageNetworth1a),
  ];

  return routes.map((route, i) => (
    <Route
      exact={route.exact}
      key={i}
      path={route.route}
      component={route.component}
    />
  ));
};
