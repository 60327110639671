import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Icon, IconAsset } from "components/core/icons";
import { RecipientDropdownItem } from "components/features/dashboard/components/row-card-recipients/recipients-dropdown-item";
import { UserData } from "components/features/dashboard/models/sharing";
import { UserDisplayName } from "core/utils/profile";

interface Props {
  recipients: UserData[] | undefined;

  fullTextClickable?: boolean;
  noDivider?: boolean;
  hideFirstRecipientName?: boolean;
  text?: string;
  limitNameLength?: string;
}

export const RowCardRecipients: React.FC<Props> = ({
  recipients,
  noDivider,
  text,
  limitNameLength,
  fullTextClickable,
}) => {
  const [open, setOpen] = React.useState(false);

  const onToggle = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  if (!recipients || recipients.length === 0) {
    return null;
  }

  const sharedText = (
    <div
      style={{
        marginRight: "0.25rem",
      }}
    >
      {text ?? "Shared with"}
    </div>
  );

  const recipientText = (
    <div
      style={{
        fontWeight: 500,
        color: "var(--navy)",
        maxWidth: limitNameLength ?? "unset",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      {recipients?.length === 1
        ? `${UserDisplayName(recipients[0])}`
        : `${UserDisplayName(recipients[0])} and ${recipients.length - 1} ${
            recipients.length === 2 ? "other" : "others"
          }`}
    </div>
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="row-card-recipients"
    >
      {!noDivider && (
        <div className="row-card__content-subtext-divider"> {" · "} </div>
      )}
      <div
        style={{
          display: "flex",
        }}
        className="row-card__content-subtext"
        onClick={(e) => e.preventDefault()}
      >
        {!fullTextClickable && sharedText}
        <Dropdown onToggle={onToggle} show={open} bsPrefix="pylon" alignRight>
          <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
            <div
              style={{
                display: "flex",
              }}
            >
              {fullTextClickable && sharedText}
              {recipientText}
            </div>
          </DropdownToggle>
          <DropdownMenu className="pylon-dropdown-menu">
            <div>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  right: "0.5rem",
                }}
              >
                <div style={{ flexGrow: 1 }}></div>
                <div onClick={() => onToggle(!open)}>
                  <Icon
                    asset={IconAsset.Close}
                    height={"20px"}
                    width={"20px"}
                  />
                </div>
              </div>
              <div style={{ marginTop: "0.5rem" }}>
                {recipients?.map((r: UserData, index: number) => (
                  <RecipientDropdownItem
                    key={r.id}
                    recipient={r}
                    bottomBorder={index !== recipients.length - 1}
                  />
                ))}
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};
