import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import React, { useContext } from "react";

import { DashboardPages } from "components/features/dashboard/pages";
import { LoginContext } from "components/contexts/login-context-provider";
import { LoginPages } from "components/features/login/pages";
import { SessionContext } from "components/contexts/session-context-provider";
import { TwoFactorAuthComponent } from "components/features/login/components/2fa";
import { Verify2FAResponse } from "core/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

/*
 * Shared 2fa page for login pages
 */
export const LoginTwoFactorAuthPage: React.FC = () => {
  const { twoFARequest, redirectTo } = useContext(LoginContext.context);
  const { setSessionToken } = useContext(SessionContext.context);
  const { setLast2FAToken } = useContext(SessionContext.context);

  const history = useHistory();

  if (!twoFARequest) {
    history.push(LoginPages.Login);
    return <div />;
  }

  return (
    <TwoFactorAuthComponent
      res={twoFARequest}
      onCodeVerified={(rawData) => {
        const data = rawData as Verify2FAResponse & {
          authToken: string;
        };
        setLast2FAToken(data?.last2FAToken);
        if (data?.authToken) {
          setSessionToken(data.authToken);
          if (redirectTo) {
            history.push(redirectTo);
          } else {
            history.push(DashboardPages.Summary);
          }

          return;
        } else {
          const msg = data?.message ?? `Something went wrong. Please try again`;
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={msg}
              type={ToastType.Error}
            />
          );
        }
      }}
    />
  );
};
