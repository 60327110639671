import { gql } from "@apollo/client";

export interface BudgetGoal {
  id: string;
  category: string;
  budget: string;
}

export interface GetBudgetGoalQueryResponse {
  budgetGoals: BudgetGoal[];
}

const BUDGET_GOAL_FRAGMENT = gql`
  fragment BudgetGoalFields on BudgetGoal {
    id
    category
    budget
  }
`;

export const GET_BUDGET_GOAL_QUERY = gql`
  ${BUDGET_GOAL_FRAGMENT}
  query budgetGoals {
    budgetGoals {
      ...BudgetGoalFields
    }
  }
`;

export interface CreateOrUpdateBudgetGoalMutationInput {
  category: string;
  budget: string;
}

export interface CreateOrUpdateBudgetGoalMutationResponse {
  createOrUpdateBudgetGoal: BudgetGoal;
}

export const CREATE_OR_UPDATE_BUDGET_GOAL_MUTATION = gql`
  ${BUDGET_GOAL_FRAGMENT}

  mutation createOrUpdateBudgetGoal($input: AddBudgetGoalInput!) {
    createOrUpdateBudgetGoal(input: $input) {
      ...BudgetGoalFields
    }
  }
`;

export interface DeleteBudgetGoalMutationInput {
  id: string;
}

export const DELETE_BUDGET_GOAL_MUTATION = gql`
  mutation deleteBudgetGoal($id: ID!) {
    deleteBudgetGoal(id: $id)
  }
`;
