export enum IntegrationSource {
  Manual = "MANUAL",
  Plaid = "PLAID",
  Savvy = "SAVVY",
  CoinGecko = "COINGECKO",
  Coinbase = "COINBASE",
  CoinbasePro = "COINBASEPRO",

  Example = "EXAMPLE",
}
