import * as React from "react";
import Illustration404 from "./404.svg";
import { AssetNotes } from "components/features/dashboard/components/asset-notes";
import { AssetType } from "components/features/dashboard/models/sharing";
import { AuditLog } from "components/features/dashboard/components/audit-log";
import { Colour } from "../../../../../core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { DefaultPreviewIcon } from "components/core/file-viewer/default-preview-icon";
import { DeleteDocumentQuickAction } from "components/features/dashboard/components/delete-document-quick-action";
import { DisplayDocumentDetails } from "components/features/dashboard/components/display-document-details";
import { DownloadDocumentButton } from "components/features/dashboard/components/document-download-button";
import { DownloadDocumentQuickAction } from "components/features/dashboard/components/download-document-quick-action";
import { extension } from "mime-types";
import { FileViewer } from "components/core/file-viewer";
import { Link } from "react-router-dom";
import { ShareDocumentQuickAction } from "components/features/dashboard/components/share-document-quick-action";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs
  } from "react-tabs";
import { TextD, TextStyle } from "components/core/text";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";
import {
  DocumentData,
  DocumentExtensionColorHex,
} from "components/features/dashboard/models/document";

interface Props {
  document?: DocumentData | null;
  loading?: boolean;
  fullPageView?: boolean;
}

export const DocumentDetailView: React.FC<Props> = ({
  document,
  loading,
  fullPageView,
}) => {
  const history = useHistory();
  const mobileLayout = useMobileLayout();
  const goBack = () => {
    history.goBack();
  };

  const isOwnerOrEditor = document?.isOwner || document?.isEditor || false;

  if (!loading && !document) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: "5rem 0",
            maxWidth: "25.275rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextD colour={Colour.Navy} style={TextStyle.TitlePoppins}>
            <h1>404</h1>
          </TextD>

          <div
            style={{
              padding: "2rem",
            }}
          />
          <svg>
            <use xlinkHref={`${Illustration404}#illustration`} />
          </svg>

          <div style={{ textAlign: "center" }}>
            <TextD style={TextStyle.B16}>
              The document you are trying to view has been deleted or you may no
              longer have access to it.
            </TextD>
          </div>

          <Link to={DashboardPages.Documents}>
            <TextD style={TextStyle.M15SM}>BACK TO DOCUMENTS</TextD>
          </Link>
        </div>
      </div>
    );
  }

  if (!document) {
    return null;
  }

  const ext = extension(document.mimeType).toString();
  return (
    <TextD style={TextStyle.M15}>
      <div
        className="flex-column"
        style={{
          color: Colour.Black,
        }}
      >
        <div
          style={{
            height: "8rem",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${
              DocumentExtensionColorHex[ext || "pdf"]
            }`,
          }}
        >
          <div
            style={{
              paddingTop: "6rem",
            }}
          />
          <div
            style={{
              paddingLeft: mobileLayout ? "1rem" : "7rem",
              width: "fit-content",
              height: "fit-content",
              maxWidth: mobileLayout ? "100vw" : undefined,
            }}
          >
            <div
              className="document-card__icon"
              style={{
                backgroundColor: `${DocumentExtensionColorHex[ext || "pdf"]}`,
                height: "64px",
                width: "64px",
                borderRadius: "16px",
              }}
            >
              <DefaultPreviewIcon format={ext.toUpperCase() || "N/A"} />
            </div>
          </div>
        </div>
        <div className="mt3" />
        <div
          className="flex-column"
          style={{
            padding: mobileLayout ? "0 1rem" : "0 7rem",
          }}
        >
          <TextD style={TextStyle.M31B}>{document.name}</TextD>
          {fullPageView && (
            <div className="flex-row gap-half">
              <ShareDocumentQuickAction document={document} />

              <DownloadDocumentQuickAction document={document} />

              <DeleteDocumentQuickAction
                document={document}
                onDelete={() => goBack()}
              />
            </div>
          )}
          <div className="mt2" />
          <Tabs>
            <TabList className="account-detail-tab-list">
              <Tab
                id="account-detail-tab__overview"
                className="account-detail-tab-item"
                selectedClassName="account-detail-tab-item--selected"
              >
                Overview
              </Tab>
              <Tab
                id="account-detail-tab__notes"
                className="account-detail-tab-item"
                selectedClassName="account-detail-tab-item--selected"
              >
                Notes
              </Tab>
              <Tab
                id="account-detail-tab__more"
                className="account-detail-tab-item"
                selectedClassName="account-detail-tab-item--selected"
              >
                More
              </Tab>
            </TabList>
            <TabPanel>
              <FileViewer
                fileURL={document.previewURL || ""}
                mimeType={document.mimeType}
                unsupportedComponent={
                  <DownloadDocumentButton document={document} />
                }
              />
            </TabPanel>
            <TabPanel>
              <AssetNotes
                assetID={document.id}
                assetType={AssetType.Document}
                owner={document.isOwner === true}
                editor={document.isEditor === true}
              />
            </TabPanel>
            <TabPanel>
              <div
                className="flex-column"
                style={{
                  gap: "2.5rem",
                }}
              >
                <DisplayDocumentDetails document={document} />
                {isOwnerOrEditor && (
                  <div>
                    <AuditLog
                      assetID={document.id}
                      assetType={AssetType.Document}
                    />
                  </div>
                )}
              </div>
            </TabPanel>
            <div className="mt5" />
          </Tabs>
        </div>
      </div>
    </TextD>
  );
};
