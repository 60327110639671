import "../styles/styles.css";
import "./styles.css";

import {
  InviteTokenResponse,
  Verify2FAResponse,
  obtainInviteToken,
} from "core/api";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import React, { useContext, useState } from "react";

import { AccountsHeader } from "../../components/header";
import { LoginContext } from "components/contexts/login-context-provider";
import { LoginPages } from "components/features/login/pages";
import { SectionHeader } from "components/core/section-header";
import { TwoFactorAuthComponent } from "components/features/login/components/2fa";
import qs from "qs";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

export const InvitationLandingPage: React.FC = () => {
  const { setEmail, setPhoneNumber, setIdentityToken } = useContext(
    LoginContext.context
  );
  const history = useHistory();
  const [inviteResp, setInviteResp] = useState<InviteTokenResponse | undefined>(
    undefined
  );

  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const invitationCode = query["code"] as string;

  React.useEffect(() => {
    obtainInviteToken(
      invitationCode,
      (data) => {
        if (!data.identityToken) {
          history.push(LoginPages.Login);
        }
        setInviteResp(data);
      },
      (err) => {
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={`This link is no longer valid, please contact us at: support@hellpylon.com if you have further questions`}
            type={ToastType.Error}
          />,
          {
            closeOnClick: false,
            closeButton: true,
          }
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only run once on component mount

  if (inviteResp) {
    return (
      <div>
        <TwoFactorAuthComponent
          res={inviteResp}
          title="Let's get started"
          onCodeVerified={(rawData) => {
            const data = rawData as Verify2FAResponse & {
              email: string;
              phone: string;
            };
            setEmail(data.email);
            setPhoneNumber(data.phone);
            setIdentityToken(data.identityToken);
            history.push(
              `${LoginPages.Register}?invitationCode=${encodeURIComponent(
                invitationCode || ""
              )}`
            );
          }}
        />
      </div>
    );
  }
  return (
    <div className="account-page">
      <AccountsHeader redirectToHome />
      <SectionHeader>Let's get started</SectionHeader>
      <div>Loading your invitation...</div>
    </div>
  );
};
