import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ErrorBoundary, Provider } from "@rollbar/react";
import { createUploadLink } from "apollo-upload-client";
import { DataPageContextProvider } from "components/contexts/data-page-context-provider";
import { PlanContextProvider } from "components/contexts/plan-context-provider";
import { SideTrayContextProvider } from "components/contexts/side-tray-context-provider";
import { ErrorDisplay } from "components/core/error-boundary";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { AddAccountTrayContextProvider } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { GQL_URL } from "core/api";
import { scrollToTop } from "core/utils/scrolling";
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Switch, useLocation } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import { TOKEN_KEY } from "./components/contexts/session-context-provider";
import "./mobile.css";
import { renderRoutes } from "./pages";

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return null;
}

const uploadLink = createUploadLink({
  uri: GQL_URL,
  credentials: "same-origin",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(TOKEN_KEY);
  return {
    headers: {
      ...headers,
      "X-Correlation-Id": uuidv4(),
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions?.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case "UNAUTHENTICATED":
            logout();
            return;
          case "GRAPHQL_VALIDATION_FAILED":
            continue;
          default:
            toast(
              <PylonToastBody
                title={"Error Occurred"}
                body={`${err.message}`}
                type={ToastType.Error}
              />,
              {
                autoClose: 10000, // 10 seconds
              }
            );
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
    forward(operation);
  }
);

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
  cache: new InMemoryCache({}),
});

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.clear();
  client.resetStore();
  window.location.href = window.location.pathname;
};

const rollbarConfig = {
  accessToken: "8118d27764604a0bbe54eb1267aa8bec",
  enabled: process.env.REACT_APP_ENVIRONMENT === "production",
  //  captureUncaught: true,
  //  captureUnhandledRejections: true,
  environment: "production",
};

export const App: React.FC = () => {
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    console.log("App environment: ", process.env.REACT_APP_ENVIRONMENT);
  }

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ScrollToTop />
        <Provider config={rollbarConfig}>
          {/* ErrorBoundary catches all React errors in the tree below and logs them to Rollbar */}
          <ErrorBoundary fallbackUI={ErrorDisplay}>
            <SideTrayContextProvider>
              {/* Anything context that are used in a sidetray components need to be parent of <SideTray /> component */}
              <AddAccountTrayContextProvider>
                <DataPageContextProvider>
                  <PlanContextProvider>
                    <Switch>{renderRoutes()}</Switch>
                  </PlanContextProvider>
                </DataPageContextProvider>
              </AddAccountTrayContextProvider>
            </SideTrayContextProvider>
          </ErrorBoundary>
        </Provider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
