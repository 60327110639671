import { format, utcToZonedTime } from "date-fns-tz";

export const BROWSER_TIMEZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDateToUTCMMDDYY = (date: Date) => {
  return format(utcToZonedTime(date, "UTC"), "MM/dd/yy", {
    timeZone: "UTC",
  });
};
