import { gql } from "@apollo/client";
import { UserData } from "components/features/dashboard/models/sharing";

export interface DashboardInput {
  userIDs: string[];
  startDate: string;
  endDate: string;
}

export interface FetchDashboardNetworthResponse {
  networth: {
    networth: number;
    change?: number;
  };
}

export const FetchDashboardNetworth = gql`
  query networth($networthInput: DashboardInput!) {
    networth(networthInput: $networthInput) {
      networth
      change
    }
  }
`;

export interface CreditResponseItem {
  score: number;
  change: number;
  date: string;
  owner: UserData;
}
export interface FetchDashboardCreditResponse {
  credit: CreditResponseItem[];
}

export const FetchDashboardCredit = gql`
  query credit($creditInput: DashboardInput!) {
    credit(creditInput: $creditInput) {
      score
      change
      owner {
        id
        firstName
      }
    }
  }
`;

export interface FinancialPlanProgressStep {
  step: string;
  id: string;
  isCompleted: boolean;
}

export interface FetchFinancialPlanProgressResponse {
  setupProgress: {
    id: string;
    steps: FinancialPlanProgressStep[];
  };
}
export const FetchFinancialPlanProgress = gql`
  query setupProgress {
    setupProgress {
      id
      steps {
        step
        id
        isCompleted
      }
    }
  }
`;
