import React from "react";
import { AccountDetails, RentalPropertyDetails } from "core/models";
import { PylonALink } from "components/core/alink";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeStringNumber } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { Text, TextStyle, TextType } from "components/core/text";
import "./styles.css";

export interface Props {
  balance?: string;
  onBalanceUpdate: (balance: string) => void;

  details?: AccountDetails;
  onUpdate: (details: AccountDetails) => void;

  viewOnly?: boolean;
  onEnter?: () => void;
}

export const AddRentalPropertyDetailsEntry: React.FC<Props> = ({
  balance,
  onBalanceUpdate,

  details,
  onUpdate,

  viewOnly,
  onEnter,
}) => {
  const rentalPropertyDetails: RentalPropertyDetails =
    details?.rentalPropertyDetails || {};

  return (
    <>
      <div>
        <div>Remaining balance (Estimates okay)</div>
        <PylonCurrencyInput
          id="validation-example-2-field"
          placeholder="$1,000.00"
          onValueChange={(v) => {
            onBalanceUpdate(sanitizeStringNumber(v));
          }}
          value={balance || ""}
          prefix={"$"}
          step={1}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>

      <div>
        <div>Estimated value</div>
        <PylonCurrencyInput
          id="estimated-value-input"
          placeholder="$700,000"
          onValueChange={(v) =>
            onUpdate({
              rentalPropertyDetails: {
                ...rentalPropertyDetails,
                estimatedValue: sanitizeStringNumber(v),
              },
            })
          }
          disabled={viewOnly}
          value={rentalPropertyDetails.estimatedValue}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
        <Text type={TextType.Div} style={TextStyle.Hint}>
          Not sure? Try{" "}
          <PylonALink
            href="https://www.redfin.com/what-is-my-home-worth"
            target="_blank"
          >
            Redfin{" "}
          </PylonALink>
          or{" "}
          <PylonALink
            href="https://www.zillow.com/how-much-is-my-home-worth/"
            target="_blank"
          >
            Zillow{" "}
          </PylonALink>
          to get an estimate
        </Text>
      </div>
    </>
  );
};
