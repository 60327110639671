import { useQuery } from "@apollo/client";
import { Icon, IconAsset } from "components/core/icons";
import { DashboardPages } from "components/features/dashboard/pages";
import {
  GetPendingNotficationCount,
  GetPendingNotificationCountResponse,
} from "core/queries/notifications";
import React from "react";
import { useHistory } from "react-router";
import "./styles.css";

export const NotificationBell: React.FC = () => {
  const { data } = useQuery<GetPendingNotificationCountResponse>(
    GetPendingNotficationCount,
    {
      pollInterval: 15 * 1000, // 15 seconds
    }
  );

  const history = useHistory();

  const count = data?.pendingNotificationCount?.count;
  let displayCount: string | null = null;
  if (count) {
    if (count > 9) {
      displayCount = "9+";
    } else if (count > 0) {
      displayCount = `${count}`;
    }
  }

  return (
    <div
      className="notification-bell__base"
      onClick={() => {
        history.push(DashboardPages.Notifications);
      }}
    >
      <Icon asset={IconAsset.MenuNotifications} width="24px" height="24px" />

      {displayCount && <div className="notification-bell__overlay"></div>}
    </div>
  );
};
