import { addMonths, startOfMonth } from "date-fns";

export const toFirstDayOfThisMonth = (date: Date): Date => {
  if (!date) {
    return date;
  }

  return startOfMonth(date);
};

export const toFirstDayOfNextMonth = (date: Date) => {
  if (!date) {
    return date;
  }
  return startOfMonth(addMonths(date, 1));
};
