import * as React from "react";
import { blurOnEnter } from "core/utils/form-helpers";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { TaxContext } from "components/contexts/tax-context";
import { Text, TextType } from "components/core/text";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
import {
  TaxIncomeEventInterface,
  TaxIncomeEventType,
} from "core/queries/taxes";

interface PublicProps {
  incomeEvent: TaxIncomeEventInterface;
  isSpouse?: boolean;
}

export const SupplementalIncomeTray: React.FC<PublicProps> = ({
  incomeEvent: incomingIncomeEvent,
  isSpouse,
}) => {
  const {
    saveIncomeEventFieldValue,
    saveSupplementalIncome,
    bonusIncomeEvents,
    commissionIncomeEvents,
  } = React.useContext(TaxContext);
  const [federalWithholding, setFederalWithholding] = React.useState<number>();
  const [stateWithholding, setStateWithholding] = React.useState<number>();
  const [localWithholding, setLocalWithholding] = React.useState<number>();

  const incomeEvent = (
    incomingIncomeEvent.incomeEventType === TaxIncomeEventType.Bonus
      ? bonusIncomeEvents
      : commissionIncomeEvents
  )?.find((ie) => {
    return ie.id === incomingIncomeEvent.id;
  });

  React.useEffect(() => {
    setFederalWithholding(
      parseFloat(
        (isSpouse
          ? incomeEvent?.spouseFederalWithholding
          : incomeEvent?.federalWithholding) || "0"
      )
    );

    setStateWithholding(
      parseFloat(
        (isSpouse
          ? incomeEvent?.spouseStateWithholding
          : incomeEvent?.stateWithholding) || "0"
      )
    );

    setLocalWithholding(
      parseFloat(
        (isSpouse
          ? incomeEvent?.spouseLocalWithholding
          : incomeEvent?.localWithholding) || "0"
      )
    );
  }, [
    incomeEvent?.federalWithholding,
    incomeEvent?.localWithholding,
    incomeEvent?.spouseFederalWithholding,
    incomeEvent?.spouseLocalWithholding,
    incomeEvent?.spouseStateWithholding,
    incomeEvent?.stateWithholding,
    isSpouse,
  ]);

  if (!incomeEvent) {
    return null;
  }

  const fieldLabel = isSpouse ? "Spouse's Income" : "Your Income";
  return (
    <div className="">
      <div className="manage-account-tray__content">
        <div
          className="flex-row "
          style={{ flexWrap: "wrap", justifyContent: "space-start" }}
        >
          <div className="manage-account-tray__properties ">
            <div style={{ fontWeight: "bold" }}>{fieldLabel}</div>
            <div>
              <PylonCurrencyInput
                placeholder={fieldLabel}
                defaultValue={
                  (isSpouse
                    ? incomeEvent?.spouseAmount
                    : incomeEvent?.amount) || 0
                }
                maxLength={12}
                onBlur={(e) =>
                  saveIncomeEventFieldValue(
                    isSpouse ? "spouseAmount" : "amount",
                    sanitizeCurrencyString(e.target.value),
                    incomeEvent?.incomeEventType,
                    incomeEvent?.id
                  )
                }
                onKeyDown={blurOnEnter}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                style={{ marginBottom: "0.5rem" }}
              />
            </div>
          </div>
        </div>{" "}
        <div
          className="flex-row "
          style={{ flexWrap: "wrap", justifyContent: "space-start" }}
        >
          <div
            className="flex-row between mobile-flex-column"
            style={{ width: "100%" }}
          >
            <div>
              <h6 className="mt2">Withholding</h6>
            </div>
            <div style={{ marginTop: "1.25rem" }}>
              <LeafButton
                buttonStyle={LeafButtonStyle.LeafSmallDense}
                buttonColour={LeafButtonColour.Black}
                onClick={() => {
                  saveSupplementalIncome(
                    parseFloat(
                      (isSpouse
                        ? incomeEvent.spouseAmount
                        : incomeEvent.amount) || "0"
                    ),
                    !!isSpouse,
                    incomeEvent.incomeEventType,
                    incomeEvent.id,
                    true
                  );
                }}
              >
                Estimate this for me
              </LeafButton>
            </div>
          </div>
          <div className="manage-account-tray__properties ">
            <div style={{ fontWeight: "bold", display: "flex" }}>
              Federal&#160;
              <PylonTooltip
                spacingSide={SpacingSide.Right}
                text={
                  <Text type={TextType.Div}>
                    <p>
                      This is the amount of federal taxes that were withheld for
                      this supplemental income.
                    </p>
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <div>
              {" "}
              <PylonCurrencyInput
                placeholder={`Federal Withholding`}
                value={federalWithholding}
                onChange={(e) => {
                  setFederalWithholding(parseFloat(e.target.value));
                }}
                maxLength={12}
                onBlur={(e) =>
                  saveIncomeEventFieldValue(
                    isSpouse
                      ? "spouseFederalWithholding"
                      : "federalWithholding",
                    sanitizeCurrencyString(e.target.value),
                    incomeEvent?.incomeEventType,
                    incomeEvent?.id
                  )
                }
                onKeyDown={blurOnEnter}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                style={{ marginBottom: "0.5rem" }}
              />
            </div>
          </div>
          <div className="manage-account-tray__properties ">
            <div style={{ fontWeight: "bold", display: "flex" }}>
              State&#160;
              <PylonTooltip
                spacingSide={SpacingSide.Right}
                text={
                  <Text type={TextType.Div}>
                    <p>
                      This is the amount of state taxes that were withheld for
                      this supplemental income.
                    </p>
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <div>
              {" "}
              <PylonCurrencyInput
                placeholder={`State Withholding`}
                value={stateWithholding}
                onChange={(e) => {
                  setStateWithholding(parseFloat(e.target.value));
                }}
                maxLength={12}
                onBlur={(e) =>
                  saveIncomeEventFieldValue(
                    isSpouse ? "spouseStateWithholding" : "stateWithholding",
                    sanitizeCurrencyString(e.target.value),
                    incomeEvent?.incomeEventType,
                    incomeEvent?.id
                  )
                }
                onKeyDown={blurOnEnter}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                style={{ marginBottom: "0.5rem" }}
              />
            </div>
          </div>
          <div
            className="manage-account-tray__properties "
            style={{ display: "none" }}
          >
            <div style={{ fontWeight: "bold", display: "flex" }}>
              Local&#160;
              <PylonTooltip
                spacingSide={SpacingSide.Right}
                text={
                  <Text type={TextType.Div}>
                    <p>
                      This is the amount of local taxes that were withheld for
                      this supplemental income.
                    </p>
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <div>
              {" "}
              <PylonCurrencyInput
                placeholder={`Local Withholding`}
                defaultValue={
                  (isSpouse
                    ? incomeEvent?.spouseLocalWithholding
                    : incomeEvent?.localWithholding) || 0
                }
                maxLength={12}
                onBlur={(e) =>
                  saveIncomeEventFieldValue(
                    isSpouse ? "spouseLocalWithholding" : "localWithholding",
                    sanitizeCurrencyString(e.target.value),
                    incomeEvent?.incomeEventType,
                    incomeEvent?.id
                  )
                }
                onKeyDown={blurOnEnter}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                style={{ marginBottom: "0.5rem" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
