import { Colour } from "core/models";
import { TextD, TextStyle } from "components/core/text";
import "./styles.css";
import {
  Props as DatePickerProps,
  PylonDatePicker,
} from "components/core/date-picker";

export interface LabelProps {
  id: string;
  label?: string;
}

export type Props = LabelProps & DatePickerProps;

export const ModalDateInput: React.FC<Props> = ({
  id,
  label,
  ...datePickerProps
}) => {
  let inputComponent = <PylonDatePicker id={id} {...datePickerProps} />;

  if (!label) {
    return inputComponent;
  }

  return (
    <div className="flex-column gap-half">
      <TextD style={TextStyle.M15} colour={Colour.Black}>
        {label}
      </TextD>
      {/* 
          date component need to be wrapped in another div to avoid 
          gap adding unnecessary padding when the date picker is active
        */}
      <div>{inputComponent}</div>
    </div>
  );
};
