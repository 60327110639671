import * as React from "react";
import classnames from "classnames";
import { Icon, IconAsset } from "components/core/icons";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import "./styles.css";

export const SideTray: React.FC = () => {
  const { trayStack, clearStack } = React.useContext(SideTrayContext);
  if (!trayStack || trayStack.length === 0) {
    return <></>;
  }

  const trayComponents = trayStack.map((tray, i) => {
    const isCurrentTray = i === trayStack.length - 1;

    const trayParentClass = classnames({
      "side-tray__parent": true,
      "side-tray__hidden": !isCurrentTray,
      "side-tray__show": isCurrentTray,
    });
    const trayClasses = classnames({
      "side-tray": true,
      "tray-open": true,
      [trayStack[0].className || ""]: true,
    });

    return (
      <div
        className={trayParentClass}
        key={i}
        style={{
          display: i === trayStack.length - 1 ? "flex" : "none",
        }}
      >
        <div
          className="side-tray__background"
          onClick={() => {
            clearStack();
          }}
        ></div>
        <div className={trayClasses}>
          <div className="side-tray__header">
            <div className="side-tray__inner-header">{tray.header}</div>
            <div
              onClick={() => {
                clearStack();
              }}
              style={{ cursor: "pointer" }}
            >
              <Icon asset={IconAsset.Close} width="36px" height="36px" />
            </div>
          </div>
          {tray.children}
        </div>
      </div>
    );
  });

  return <>{trayComponents}</>;
};
