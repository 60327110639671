import { Colour } from "core/models";
import { LandingNetworthPageA } from "components/features/landing/networth/v2-a";
import { LandingNetworthPageB } from "components/features/landing/networth/v2-b";
import { LandingPageBanner } from "components/features/landing/components/top-banner";
import { LandingPageEffortlessManagementD } from "components/features/landing/manage";
import { LandingPageWrapper, PylonRoute } from "pages";
import { Route, Switch } from "react-router";
import {
  LandingEstimateSavings,
  LandingFinancialDecisions,
  LandingPageTrackYourMoneyC,
} from "components/features/landing/tracking/experiment-c";

export const LandingPageV2: React.FC<{}> = () => {
  return (
    <div>
      <Switch>{renderRoutes()}</Switch>
    </div>
  );
};

export interface LandingPageDefinition {
  Component: React.FC;
  Path: string;
}

const renderRoutes = () => {
  const newRoute = (
    routeDef: LandingPageDefinition,
    showTopBanner: boolean = true,
    bannerBackgroundColor: Colour = Colour.Black
  ) => {
    const wrappedComponent: React.FC = () => {
      return (
        <div>
          {showTopBanner && (
            <LandingPageBanner bannerBackgroundColor={bannerBackgroundColor} />
          )}
          {LandingPageWrapper(routeDef.Component)({})}
        </div>
      );
    };

    return {
      route: routeDef.Path,
      component: wrappedComponent,
      index: 0, // this is ignored below
      exact: true,
    };
  };

  const routes: PylonRoute[] = [
    newRoute(LandingPageTrackYourMoneyC),
    newRoute(LandingEstimateSavings),
    newRoute(LandingFinancialDecisions),
    newRoute(LandingPageEffortlessManagementD, false),
    newRoute(LandingNetworthPageA, false),
    newRoute(LandingNetworthPageB, false),
  ];

  return routes.map((route, i) => (
    <Route
      exact={route.exact}
      key={i}
      path={route.route}
      component={route.component}
    />
  ));
};
