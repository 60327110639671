import { Colour } from "core/models";
import { GoogleAdWordsConversionIDs } from "core/distribution/distribution";
import { LoginPages } from "components/features/login/pages";
import { Text, TextStyle, TextType } from "components/core/text";
import { useHistory } from "react-router";
import "./styles.css";

export interface Props {
  text: string;
  optionalParam?: string;
}

export const RegisterButtonLeaf: React.FC<Props> = ({
  text,
  optionalParam,
}) => {
  const history = useHistory();
  return (
    <button
      className="landing-page__register_leaf"
      onClick={() => {
        gtag("event", "conversion", {
          send_to: GoogleAdWordsConversionIDs.StartedRegistration,
        });
        history.push(
          `${LoginPages.Register}?invitationCode=7fdf0e32e42${
            optionalParam ? `&${optionalParam}` : ""
          }`
        );
      }}
    >
      <Text
        type={TextType.Div}
        style={TextStyle.Sharp17Regular}
        colour={Colour.White}
      >
        {text}
      </Text>
    </button>
  );
};
