import React from "react";
import { AccountDetails } from "core/models";
import { BufferedInput } from "core/components/buffered-input";
import { Input } from "components/core/input";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

export interface Props {
  balance?: string;
  onBalanceUpdate: (balance: string) => void;

  details?: AccountDetails;
  onDetailsUpdate: (details: AccountDetails) => void;

  onEnter?: () => void;
}

export const AddCryptoDetailsEntry: React.FC<Props> = ({
  details,
  onDetailsUpdate,
}) => {
  const cryptoDetails = details?.cryptoDetails || {
    holdings: [
      {
        id: "",
        price: 0,
        name: "",
        symbol: "",
        total: 0,
      },
    ],
  };

  return (
    <>
      {cryptoDetails.holdings.map((v, i) => (
        <div key={i} style={{ width: "100%" }}>
          <div>
            <div>
              <div>Symbol (e.g. BTC, ETH)</div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <Input
                  height="1rem"
                  onChange={(e) => {
                    const holdings = cryptoDetails.holdings;
                    holdings[i].symbol = e.currentTarget.value;
                    onDetailsUpdate({
                      cryptoDetails: {
                        holdings,
                      },
                    });
                  }}
                  value={v.symbol}
                ></Input>
              </div>
            </div>
            <div style={{ marginTop: "0.5rem" }}>
              <div>Number of coins</div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <BufferedInput
                  id={`new-coin-${i}`}
                  type="number"
                  onChange={(e) => {
                    const holdings = cryptoDetails.holdings;
                    holdings[i].total = parseFloat(e ?? "0") ?? 0;
                    onDetailsUpdate({
                      cryptoDetails: {
                        holdings,
                      },
                    });
                  }}
                  value={v.total || ""}
                  overrides={{
                    decimalsLimit: 10,
                  }}
                ></BufferedInput>
              </div>
            </div>
          </div>
        </div>
      ))}

      <LeafButton
        buttonColour={LeafButtonColour.Transparent}
        buttonStyle={LeafButtonStyle.LeafSmall}
        onClick={() =>
          onDetailsUpdate({
            cryptoDetails: {
              holdings: cryptoDetails.holdings.concat([
                {
                  id: "",
                  price: 0,
                  name: "",
                  symbol: "",
                  total: 0,
                },
              ]),
            },
          })
        }
      >
        + Add
      </LeafButton>
      <div className="mt1" />
    </>
  );
};
