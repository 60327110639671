import * as React from "react";
import Select from "react-select";
import { FilingStatus, getLocalities, getStateRules } from "@pylon/taxes";
import { Input } from "components/core/input";
import { OnboardingSelections } from "components/features/onboarding/models";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { SELECT__MODAL_INPUT_STYLE } from "components/core/input/styles";
import { SelectionState } from "components/features/onboarding/flows/utils";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { TaxResults } from "components/features/dashboard/pages/income-and-taxes/components/tax-results/tax-results";
import { useEffect } from "react";
import { useMobileLayout } from "core/hooks/responsive";
import "components/features/dashboard/pages/income-and-taxes/index-version-2.css";
import {
  filingStatusValues,
  martialStatuses,
  stateValues,
  TaxContext,
} from "components/contexts/tax-context";
import {
  ManageCollaboratorHeader,
  ManageCollaboratorTray,
} from "components/features/dashboard/components/manage-collaborator-tray";

interface Props {
  onboardingState: SelectionState;
}
export const TaxOnboardingIncomeEntry: React.FC<Props> = ({
  onboardingState,
}) => {
  const { pushTray, popTray } = React.useContext(SideTrayContext);
  const {
    earnedIncome,
    setEarnedIncome,
    earnedIncomeSpouse,
    setEarnedIncomeSpouse,
    filingStatus,
    setFilingStatus,
    state,
    setState,
    totalExemptions,
    setTotalExemptions,
    locality,
    setLocality,
    stateRules,
    localityValues,
    noSpouse,
    saveLiabilityFieldValues,
    saveLiabilityFieldValue,
  } = React.useContext(TaxContext);

  const mobileLayout = useMobileLayout();
  const setValue = (v: string, set: (i: string) => any) => {
    let i = parseFloat(v);
    if (isNaN(i)) {
      set("0");
      return;
    }
    set(v.replace("$", ""));
  };

  useEffect(() => {
    if (onboardingState?.[OnboardingSelections.MartialStatusSingle]) {
      setFilingStatus(FilingStatus.Single);
      saveLiabilityFieldValue("filingStatus", FilingStatus.Single);
    } else if (onboardingState?.[OnboardingSelections.MartialStatusMarried]) {
      setFilingStatus(FilingStatus.MarriedFilingJointly);
      saveLiabilityFieldValue(
        "filingStatus",
        FilingStatus.MarriedFilingJointly
      );
    } else if (
      onboardingState?.[OnboardingSelections.MartialStatusHeadOfHousehold]
    ) {
      setFilingStatus(FilingStatus.HeadOfHousehold);
      saveLiabilityFieldValue("filingStatus", FilingStatus.HeadOfHousehold);
    }
  }, [onboardingState]);

  const showAddNewCollaborator = () =>
    pushTray({
      id: "manage-collaborator",
      header: <ManageCollaboratorHeader />,
      children: <ManageCollaboratorTray onClose={() => popTray()} />,
    });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  const width = mobileLayout ? "100%" : "20rem";

  const containerStyle: React.CSSProperties = mobileLayout
    ? {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 1rem",
      }
    : {
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      };
  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            Tell us a little about your{" "}
            <span style={{ color: "var(--blue-01)" }}>income</span> so we can
            estimate your{" "}
            <span style={{ color: "var(--blue-01)" }}>taxes.</span>
          </div>
        </div>
      </div>
      <div style={containerStyle}>
        <div
          className="mobile-flex-column"
          style={{
            marginTop: "1rem",
            alignItems: mobileLayout ? "unset" : "center",
            display: "flex",
            width: mobileLayout ? "100%" : "90%",
          }}
        >
          <div
            style={{
              margin: mobileLayout ? "0" : "0 0.5rem",
              width: mobileLayout ? "100%" : "15%",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: 600,
                fontFamily: "Montserrat",
                color: "var(--text-01)",
              }}
            >
              Federal Tax
            </div>
            <div className="mt1 text-01">
              Determine your federal
              <br />
              tax burden
            </div>
          </div>
          <div
            style={{
              width,
            }}
          >
            <div
              className="label"
              style={{
                display: "flex",
                marginBottom: "1rem",
              }}
            >
              Filing Status
              {noSpouse && martialStatuses.includes(filingStatus) && (
                <div
                  style={{
                    paddingLeft: "1rem",
                    cursor: "pointer",
                    color: "var(--blue-01)",
                  }}
                  onClick={showAddNewCollaborator}
                >
                  Invite Spouse
                </div>
              )}
            </div>
            <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
              <Select
                onChange={(e) => {
                  if (!e) return;
                  const newStatus =
                    FilingStatus[e.value as keyof typeof FilingStatus];
                  const newTotalExemptions =
                    newStatus === FilingStatus.MarriedFilingJointly ? 2 : 1;

                  setTotalExemptions("" + newTotalExemptions);
                  setFilingStatus(newStatus);
                  saveLiabilityFieldValue("filingStatus", newStatus);
                }}
                value={filingStatusValues.find((v) => {
                  return v.value === filingStatus;
                })}
                isSearchable={false}
                options={filingStatusValues}
                styles={{
                  ...SELECT__MODAL_INPUT_STYLE,
                  menu: (styles) => ({
                    ...styles,
                    zIndex: 5,
                  }),
                }}
              />
            </div>
          </div>

          <div style={{ margin: mobileLayout ? "0" : "0 0.5rem", width }}>
            <div className="label">
              Your Gross Annual Income (Salary, wages, etc)
            </div>
            <PylonCurrencyInput
              placeholder="Your Gross Annual Income"
              value={earnedIncome}
              maxLength={12}
              onValueChange={(v) => {
                setValue(v || "", setEarnedIncome);
              }}
              onBlur={(e) =>
                saveLiabilityFieldValue(
                  "earnedIncome",
                  sanitizeCurrencyString(e.target.value)
                )
              }
              onKeyDown={handleKeyDown}
              allowDecimals={true}
              decimalSeparator={"."}
              decimalsLimit={2}
              allowNegativeValue={false}
              prefix="$"
            />
          </div>
          <div style={{ width }}>
            {filingStatus === FilingStatus.MarriedFilingJointly && (
              <div>
                <div className="label">Spouse's Gross Annual Income</div>
                <PylonCurrencyInput
                  placeholder="Spouse's Gross Annual Income"
                  value={earnedIncomeSpouse}
                  maxLength={12}
                  onValueChange={(v) => {
                    setValue(v || "0", setEarnedIncomeSpouse);
                  }}
                  onBlur={(e) => {
                    saveLiabilityFieldValue(
                      "spouseEarnedIncome",
                      sanitizeCurrencyString(e.target.value)
                    );
                  }}
                  allowDecimals={true}
                  decimalSeparator={"."}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  prefix="$"
                  onKeyDown={handleKeyDown}
                />
              </div>
            )}
          </div>
        </div>

        <hr className="no-mobile"></hr>
        <div
          className="mobile-flex-column"
          style={{
            alignItems: mobileLayout ? "unset" : "center",
            display: "flex",
            width: mobileLayout ? "100%" : "90%",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              margin: mobileLayout ? "0" : "0 0.5rem",
              width: mobileLayout ? "100%" : "15%",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: 600,
                fontFamily: "Montserrat",
                color: "var(--text-01)",
              }}
            >
              {" "}
              State Tax
            </div>
            <div className="mt1 text-01">
              Determine your state and
              <br /> local tax burden
            </div>
          </div>
          <div style={{ marginRight: "0.5rem", width }}>
            <div
              className="label"
              style={{
                marginBottom: "1rem",
              }}
            >
              State
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <Select
                onChange={(e) => {
                  if (!e) return;

                  setState(e.value);

                  let newLocality = null;
                  if (getStateRules(state).localityTaxes) {
                    newLocality = getLocalities(state)[0];
                  } else {
                    newLocality = "";
                  }
                  setLocality(newLocality);
                  saveLiabilityFieldValues({
                    state: e.value,
                    county: newLocality,
                  });
                }}
                isSearchable={false}
                value={stateValues.find((v) => {
                  return v.value === state;
                })}
                height="36px"
                minHeight="36px"
                options={stateValues}
                styles={{
                  ...SELECT__MODAL_INPUT_STYLE,
                  menu: (styles) => ({
                    ...styles,
                    zIndex: 5,
                  }),
                }}
              />
            </div>
          </div>
          {stateRules.localityTaxes && (
            <div style={{ marginRight: "0.5rem", width }}>
              <div
                className="label"
                style={{
                  marginBottom: "1rem",
                }}
              >
                Locality
              </div>

              <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
                <Select
                  onChange={(e) => {
                    if (!e) return;
                    setLocality(e.value);
                    saveLiabilityFieldValue("county", e.value);
                  }}
                  value={localityValues.find((v) => {
                    return v.value === locality;
                  })}
                  isSearchable={false}
                  options={localityValues}
                  styles={{
                    ...SELECT__MODAL_INPUT_STYLE,
                    menu: (styles) => ({
                      ...styles,
                      zIndex: 5,
                    }),
                  }}
                />
              </div>
            </div>
          )}
          <div style={{ width }}>
            <div className="label">Total Exemptions</div>
            <Input
              placeholder="Total Exemptions"
              prefix="$"
              value={totalExemptions}
              maxLength={12}
              onChange={(e) => {
                setValue(e.target.value, setTotalExemptions);
              }}
              onBlur={(e) => {
                saveLiabilityFieldValue("totalExemptions", e.target.value);
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div style={{ width: mobileLayout ? "100%" : "90%" }}>
          <TaxResults compact={true} shouldNotPop={true} />
        </div>
      </div>
    </div>
  );
};
