import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Dropdown } from "react-bootstrap";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import { useMemo, useRef } from "react";
import { v4 } from "uuid";

export interface Props<T> {
  // Option props
  options: T[];
  toDisplay?: (value: T) => string | JSX.Element; // The displayed value of the option, default is the value itself

  newOption?: {
    label: string;
    onClick: () => void;
  };

  // Control props
  selectedValue?: T;
  onSelect: (value: T | undefined) => void;
  disabled?: boolean;

  id?: string;
  placeholder?: string;

  fullWidth?: boolean;
  menuMaxHeight?: string;
}

export const SimpleDropDown: <T>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  options,
  toDisplay = (s) => `${s}`,
  selectedValue,
  onSelect,
  placeholder,
  id,
  newOption,
  disabled,
  fullWidth,
  menuMaxHeight = "40vh",
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const menuText = useMemo(() => {
    const defaultValue = placeholder ?? "Select...";

    if (!selectedValue) {
      return defaultValue;
    }

    return toDisplay(selectedValue);
  }, [placeholder, selectedValue, toDisplay]);

  const menuItems = useMemo(() => {
    return options.map((o) => {
      return (
        <DropDownMenuItem
          key={v4()}
          text={toDisplay(o)}
          onClick={() => {
            onSelect?.(o);
            wrapperRef?.current?.click();
          }}
        />
      );
    });
  }, [onSelect, options, toDisplay]);

  const newItem = useMemo(() => {
    if (!newOption) {
      return null;
    }

    return (
      <DropDownMenuItem
        key={"new-option"}
        text={newOption.label}
        onClick={() => {
          newOption.onClick();
          wrapperRef?.current?.click();
        }}
      />
    );
  }, [newOption]);

  return (
    <div
      id={id}
      ref={wrapperRef}
      style={{
        userSelect: "none",
        width: fullWidth ? "100%" : undefined,
      }}
    >
      <Dropdown>
        <DropdownToggle
          as="div"
          bsPrefix="pylon-dropdown-toggle"
          disabled={disabled}
        >
          <PylonDropDownToggle
            text={menuText}
            styleOverride={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              padding: "0.75rem 1rem",
              justifyContent: "space-between",
            }}
          />
        </DropdownToggle>

        <DropdownMenu className="full-width">
          <div
            style={{
              maxHeight: menuMaxHeight,
              overflow: "auto",
            }}
          >
            {menuItems}
            {newItem}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
