import { useQuery } from "@apollo/client";
import { SessionContext } from "components/contexts/session-context-provider";
import { accountsQueryForIDs } from "core/queries/accounts";
import { dateFormatYYYY_MM_DD } from "core/utils";
import { useLocalStorage } from "core/utils/browser-storage";
import { toFirstDayOfNextMonth, toFirstDayOfThisMonth } from "core/utils/dates";
import * as React from "react";
import { useState } from "react";

export interface GlobalFilteringContextValue {
  ownerIDs: string[];
  setOwnerIDs: React.Dispatch<React.SetStateAction<string[]>>;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setMonth: (date: Date) => void;
  hasAccounts: boolean;
}

interface Props {
  children: React.ReactNode;
}

export const GlobalFilteringContext =
  React.createContext<GlobalFilteringContextValue>({
    ownerIDs: [],
    setOwnerIDs: () => null,
    startDate: "",
    setStartDate: () => null,
    endDate: "",
    setEndDate: () => null,
    setMonth: () => null,
    hasAccounts: true,
  });

export const GlobalFilteringContextProvider: React.FC<Props> = (
  props: Props
) => {
  const now = new Date();
  const { userID } = React.useContext(SessionContext.context);
  const keyPrefix = `${userID || ""}`;
  const [ownerIDs, setOwnerIDs] = useLocalStorage(
    `${keyPrefix}.global.filtering.owners`,
    [userID],
    false
  );
  React.useEffect(() => {
    if (!ownerIDs && userID) {
      setOwnerIDs([userID]);
    }
  }, [userID, setOwnerIDs, ownerIDs]);
  const [hasAccounts, setHasAccounts] = useState<boolean>(false);
  useQuery(accountsQueryForIDs, {
    skip: !userID,
    onCompleted: (myAccountsData) => {
      setHasAccounts((myAccountsData?.accounts?.length || 0) > 0);
    },
  });
  const [startDate, setStartDate] = useState<string>(
    dateFormatYYYY_MM_DD(toFirstDayOfThisMonth(now))
  );
  const [endDate, setEndDate] = useState<string>(
    dateFormatYYYY_MM_DD(toFirstDayOfNextMonth(now))
  );
  const setMonth = (date: Date) => {
    setStartDate(dateFormatYYYY_MM_DD(toFirstDayOfThisMonth(date)));
    setEndDate(dateFormatYYYY_MM_DD(toFirstDayOfNextMonth(date)));
  };

  return (
    <GlobalFilteringContext.Provider
      value={{
        ownerIDs,
        setOwnerIDs,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        setMonth,
        hasAccounts,
      }}
    >
      {props.children}
    </GlobalFilteringContext.Provider>
  );
};
