import React from "react";
import { AccountDetails, PrivateStockDetails } from "core/models";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";

export interface Props {
  details?: AccountDetails;
  onUpdate: (details: AccountDetails) => void;

  onEnter?: () => void;
}

export const PrivateStockDetailsEntry: React.FC<Props> = ({
  details,
  onUpdate,
  onEnter,
}) => {
  const privateStockDetails: PrivateStockDetails =
    details?.privateStockDetails || {};

  return (
    <>
      <div>
        <div>How many fully diluted shares or options do you own?</div>

        <PylonCurrencyInput
          id="shares-owned"
          placeholder="123,456"
          onValueChange={(v) => {
            onUpdate({
              privateStockDetails: {
                ...privateStockDetails,
                sharesOwned: sanitizeStringNumberToString(v),
              },
            });
          }}
          value={privateStockDetails.sharesOwned}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>

      <div>
        <div>
          If you know the current FMV (fair market value) of each share, please
          enter it below. If unknown, leave blank
        </div>
        <PylonCurrencyInput
          id="estimated-value-input"
          placeholder="$123.45"
          onValueChange={(v) =>
            onUpdate({
              privateStockDetails: {
                ...privateStockDetails,
                valuePerShare: sanitizeStringNumberToString(v),
              },
            })
          }
          value={privateStockDetails.valuePerShare}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
    </>
  );
};
