import * as React from "react";
import classnames from "classnames";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { useState } from "react";
import "./styles.css";

export enum LoadingAnimation {
  SpinLeftToRight = "spin__left-to-right",
}
interface Props {
  onClick: () => void;
  optionText: string | React.ReactNode;
  iconAsset: IconAsset;

  loading?: boolean;
  loadingAnimation?: LoadingAnimation;
  disabledToolTip?: string;
  disabled?: boolean;
  bottomBorder?: boolean;
}

export const ManageMenuOption: React.FC<Props> = (props: Props) => {
  const [hovering, setHovering] = useState(false);
  const optionClasses = classnames({
    "manage-account-menu__option": true,
    "option-border": props.bottomBorder,
  });

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
      className={optionClasses}
      style={{ margin: "0 1rem" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className={props.loading ? props.loadingAnimation : undefined}>
          <Icon
            width="24px"
            height="24px"
            asset={props.iconAsset}
            colour={hovering ? Colour.Blue01 : Colour.Gray50}
          />
        </div>
        <div style={{ paddingRight: "0.5rem" }} />
        {props.optionText}
      </div>
    </div>
  );
};
