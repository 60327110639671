import * as React from "react";
import { Account } from "core/models";
import { AccountTransaction, FETCH_CASH_FLOW } from "core/queries/transactions";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { AccountTransactionTable } from "components/features/dashboard/components/transactions/transactions-table";
import { getDailyCasfhlowQueryVariables } from "components/features/dashboard/components/chart-cashflow-daily/query";
import { useLazyQuery, useQuery } from "@apollo/client";
import "./styles.css";
import {
  CustomTransactionCategoryResponse,
  GetCustomTransactionCategoryQuery,
} from "core/queries/transaction-category";

interface Props {
  transactionFilter?: (tx: AccountTransaction) => boolean;
  limit?: number;
  showTitle?: boolean;
  detailsView?: boolean;
}

export const TransactionsTable: React.FC<Props> = ({
  transactionFilter,
  limit = 10,
  showTitle = true,
  detailsView = true,
}) => {
  const { transactionData, accountID, month } = React.useContext(
    AccountTransactionContext
  );

  // Warm the cache, they'll be used later in the rows
  useQuery<CustomTransactionCategoryResponse>(
    GetCustomTransactionCategoryQuery,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const accountLookup: { [key: string]: Account } = {};
  transactionData?.accountTransactions.accounts?.forEach((account) => {
    accountLookup[account?.account_id] = account;
  });

  // Hack to make cross module conditional refetch work
  const [refetchDailyCashflow] = useLazyQuery(FETCH_CASH_FLOW, {
    variables: getDailyCasfhlowQueryVariables(month),
    fetchPolicy: "cache-and-network",
  });

  const onTransactionUpdate = !accountID
    ? () => {
        refetchDailyCashflow();
      }
    : undefined;

  const filteredTransactions = transactionData?.accountTransactions
    ?.transactions
    ? transactionFilter
      ? transactionData?.accountTransactions?.transactions.filter(
          transactionFilter
        )
      : transactionData?.accountTransactions?.transactions
    : [];

  if (
    transactionData?.accountTransactions?.summary?.count === 0 &&
    detailsView
  ) {
    return null;
  }

  return (
    <div>
      <AccountTransactionTable
        limit={limit}
        transactions={filteredTransactions}
        noData={(transactionData?.accountTransactions?.summary?.count || 0) > 0}
        accountID={accountID}
        accountMap={accountLookup}
        onTransactionUpdate={onTransactionUpdate}
        subscriptionView
        showTitle={showTitle}
      />
    </div>
  );
};
