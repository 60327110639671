import React from "react";
import { DashLeftNav } from "components/features/dashboard/components/dash-left-nav";
import { MobileHeader } from "components/features/wizard/components/wizard-header";
import { renderDashboardRoutes } from "components/features/dashboard/pages";
import "./global-styles.css";
import "./tables.css";

export const DashboardComponent: React.FC = () => {
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  return (
    <div className={`dashboard-container ${showMobileNav ? "show-nav" : ""}`}>
      <MobileHeader
        isOpen={showMobileNav}
        onMenuOpenClick={() => {
          setShowMobileNav(true);
        }}
        onMenuCloseClick={() => {
          setShowMobileNav(false);
        }}
      />
      <DashLeftNav
        onClick={() => {
          setShowMobileNav(false);
        }}
      />
      <div className="dashboard-wrapper">{renderDashboardRoutes()}</div>
      <div style={{ marginBottom: "7.5rem" }}></div>
    </div>
  );
};
