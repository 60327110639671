import * as React from "react";
import Modal from "react-modal";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { CoinbaseProModalComponent } from "components/features/dashboard/components/crypto-connect-wrapper/coinbase-pro-modal";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { PylonALink } from "components/core/alink";
import { Text } from "components/core/text";
import { useLocalStorage } from "core/utils/browser-storage";
import { v4 } from "uuid";
import "./styles.css";

enum ModalStates {
  ChooseIntegrationSource,
  CoinbaseIntroduction,
  CoinbaseProIntroduction,
  CoinbaseProDetails,
}

export interface Props {
  showingModal: boolean;
  onClose?: () => void;
}

export const CryptoConnectWrapper: React.FC<Props> = ({
  showingModal,
  onClose,
}) => {
  const { convertToManualProps } = React.useContext(AddAccountsContext);
  React.useEffect(() => {
    localStorage.removeItem("cbConvertProps");
    if (convertToManualProps) {
      localStorage.setItem(
        "cbConvertProps",
        JSON.stringify(convertToManualProps)
      );
    }
  }, [convertToManualProps]);
  const redirectURI = encodeURIComponent(
    (window.location.host === "localhost:3000" ? "http://" : "https://") +
      window.location.host +
      DashboardPages.CoinbaseOauthCallback
  );

  const [modalState, setModalState] = React.useState(
    ModalStates.ChooseIntegrationSource
  );
  const [cbState, setCBState] = React.useState(v4());
  const [_, setCBLocalStorageState] = useLocalStorage("cbState", cbState);

  const URL = `https://www.coinbase.com/oauth/authorize?account=all&response_type=code&client_id=255247fbe50f3f918b706b628753a43c8ce694eef9fd3de677f278c89269e6bd&redirect_uri=${redirectURI}&state=${cbState}&scope=wallet:accounts:read`;

  const modalPages: Record<ModalStates, () => React.ReactElement> = {
    [ModalStates.ChooseIntegrationSource]: () => {
      return (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h6>
              Connections
              <Icon
                asset={IconAsset.Dot}
                width=".25rem"
                height=".25rem"
                colour={Colour.Blue01}
              />
            </h6>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Text colour={Colour.Navy} size={"0.875rem"}>
              We support the following cryptocurrency integrations
            </Text>
          </div>
          <div
            style={{
              margin: "2rem 2rem 3rem 2rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Text colour={Colour.Navy} size={"1.25rem"}>
                Coinbase
              </Text>
            </div>
            <div>
              <LeafButton
                onClick={() => {
                  setModalState(ModalStates.CoinbaseIntroduction);
                }}
              >
                Connect
              </LeafButton>
            </div>
          </div>

          <div
            style={{
              margin: "2rem 2rem 3rem 2rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Text colour={Colour.Navy} size={"1.25rem"}>
                Coinbase Pro
              </Text>
            </div>
            <div>
              <LeafButton
                onClick={() => {
                  setModalState(ModalStates.CoinbaseProDetails);
                }}
              >
                Connect
              </LeafButton>
            </div>
          </div>
        </div>
      );
    },
    [ModalStates.CoinbaseIntroduction]: () => {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 3rem",
            }}
          >
            <h6>
              Authentication
              <Icon
                asset={IconAsset.Dot}
                width="4px"
                height="4px"
                colour={Colour.Blue01}
              />
            </h6>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 1rem",
            }}
          >
            <Text colour={Colour.Navy} size={"0.875rem"}>
              To connect your Coinbase account, we’ll redirect you to your
              institution’s website to securely log in.
            </Text>
          </div>
          <div
            style={{
              margin: "3rem 2rem",
              marginTop: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <ol style={{ color: "var(--navy)", fontSize: "0.875rem" }}>
                <li>
                  <Text colour={Colour.Navy} size={"0.875rem"}>
                    Sign into your Coinbase account.
                  </Text>
                </li>
                <li>
                  <Text colour={Colour.Navy} size={"0.875rem"}>
                    Authorize Pylon to view your portfolio.
                  </Text>
                </li>
                <li>
                  <Text colour={Colour.Navy} size={"0.875rem"}>
                    You'll be redirected back to Pylon to view your account.
                  </Text>
                </li>
              </ol>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <PylonALink href={URL}>
                <LeafButton
                  onClick={() => {
                    setCBLocalStorageState(cbState);
                  }}
                >
                  Connect
                </LeafButton>
              </PylonALink>
            </div>
          </div>
        </div>
      );
    },
    [ModalStates.CoinbaseProDetails]: () => {
      return <CoinbaseProModalComponent onComplete={() => onClose?.()} />;
    },
    [ModalStates.CoinbaseProIntroduction]: () => {
      return <div />;
    },
  };

  const getModalState = () => {
    return modalPages[modalState]?.();
  };
  return (
    <>
      <Modal
        isOpen={showingModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={() => onClose?.()}
        className="modal-base crypto-modal"
        overlayClassName="overlay-base"
        style={{
          content: {
            width:
              modalState === ModalStates.CoinbaseProDetails ? "43rem" : "33rem",
            minHeight: "fit-content",
            maxHeight:
              modalState === ModalStates.CoinbaseProDetails ? "54rem" : "44rem",
            overflowY: "auto",
          },
          overlay: {
            backgroundColor: "rgba(3, 12, 23, 0.7)",
          },
        }}
      >
        <div
          style={{
            top: "1rem",
            right: "1.5rem",
            width: "auto",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              margin: "1rem",
              cursor: "pointer",
              visibility: modalState === 0 ? "hidden" : "visible",
            }}
            onClick={() => setModalState(ModalStates.ChooseIntegrationSource)}
          >
            <Icon
              asset={IconAsset.Back}
              width="32px"
              height="32px"
              colour={Colour.Text02}
            />
          </div>
          <div
            style={{ margin: "1rem", cursor: "pointer" }}
            onClick={() => {
              onClose?.();
            }}
          >
            <Icon
              asset={IconAsset.Close}
              width="32px"
              height="32px"
              colour={Colour.Text02}
            />
          </div>
        </div>
        <div>
          <div>{getModalState()}</div>
        </div>
      </Modal>
    </>
  );
};
