import * as React from "react";
import { IconAsset } from "components/core/icons";
import { OnboardingProps } from "components/features/onboarding/flows/utils";
import { OnboardingSelections } from "components/features/onboarding/models";
import {
  OnboardingCard,
  OnboardingIconSize,
} from "components/features/onboarding/components/onboarding-card";

export const OnboardingHowDoYouTrack: React.FC<OnboardingProps> = ({
  state,
  optimisticUpdate,
}) => {
  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            How do you currently manage your accounts
            <span style={{ color: "var(--blue-01)" }}>?</span>
          </div>
        </div>
      </div>
      <div className="onboarding-middle buttons">
        <OnboardingCard
          id={OnboardingSelections.ManageAccountsSpreadsheet}
          onClick={() => {
            optimisticUpdate(
              OnboardingSelections.ManageAccountsSpreadsheet,
              !!!state?.[OnboardingSelections.ManageAccountsSpreadsheet]
            );
          }}
          iconSize={OnboardingIconSize.Large}
          asset={IconAsset.OnboardingSpreadsheet}
          text={"Spreadsheet"}
          selected={state?.[OnboardingSelections.ManageAccountsSpreadsheet]}
        />

        <OnboardingCard
          id={OnboardingSelections.ManageAccountsOffline}
          onClick={() => {
            optimisticUpdate(
              OnboardingSelections.ManageAccountsOffline,
              !!!state?.[OnboardingSelections.ManageAccountsOffline]
            );
          }}
          iconSize={OnboardingIconSize.Large}
          asset={IconAsset.OnboardingOffline}
          text={"Offline"}
          selected={state?.[OnboardingSelections.ManageAccountsOffline]}
        />

        <OnboardingCard
          id={OnboardingSelections.ManageAccountsIDK}
          onClick={() => {
            optimisticUpdate(
              OnboardingSelections.ManageAccountsIDK,
              !!!state?.[OnboardingSelections.ManageAccountsIDK]
            );
          }}
          iconSize={OnboardingIconSize.Large}
          asset={IconAsset.OnboardingIDK}
          text={"I don't know"}
          selected={state?.[OnboardingSelections.ManageAccountsIDK]}
        />

        <OnboardingCard
          id={OnboardingSelections.ManageAccountsLotsOfApps}
          onClick={() => {
            optimisticUpdate(
              OnboardingSelections.ManageAccountsLotsOfApps,
              !!!state?.[OnboardingSelections.ManageAccountsLotsOfApps]
            );
          }}
          iconSize={OnboardingIconSize.Large}
          asset={IconAsset.OnboardingLots}
          text={"Lots of apps & websites"}
          selected={state?.[OnboardingSelections.ManageAccountsLotsOfApps]}
        />
      </div>
    </div>
  );
};
