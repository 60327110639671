import * as React from "react";

export interface AccountTableContextValue {
  onSelectAccount: (accountID: string) => void;
}

export const AccountTableContext =
  React.createContext<AccountTableContextValue>({
    onSelectAccount: () => {},
  });
