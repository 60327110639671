import React, { useState } from "react";
import { Account } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { ALPHANUMERIC_REGEX } from "core/utils";
import { ModalDateInput } from "components/core/input/modal-date-input";
import { ModalInput } from "components/core/input/modal-input";
import { ModalInputWrapper } from "components/core/input/modal-input-wrapper";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { SimpleDropDown } from "components/core/simple-drop-down";
import "./styles.css";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const ManageLifeInsuranceDetails: React.FC<Props> = ({
  account,
  isConnected,
  displayOnly,
  onUpdate,
}) => {
  const inputDetails = account.details?.lifeInsuranceDetails;
  const [details, setDetails] = useState({
    lifeInsuranceType: inputDetails?.lifeInsuranceType || undefined,
    lengthInYears: inputDetails?.lengthInYears || undefined,
    annualPremium: inputDetails?.annualPremium || undefined,
    effectiveStartDate: inputDetails?.effectiveStartDate || undefined,
    accountNumber: inputDetails?.accountNumber || undefined,
  });

  const onBlurUpdate = () => {
    onUpdate?.({
      details: {
        lifeInsuranceDetails: details,
      },
    });
  };

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Insurance Type"
          value={account.details?.lifeInsuranceDetails?.lifeInsuranceType}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Length (in years)"
          value={account.details?.lifeInsuranceDetails?.lengthInYears}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Annual Premium"
          value={account.details?.lifeInsuranceDetails?.annualPremium}
        />

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Effective Start Date"
          value={account.details?.lifeInsuranceDetails?.effectiveStartDate}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Account Number"
          value={account.details?.lifeInsuranceDetails?.accountNumber}
        />
      </>
    );
  }

  return (
    <>
      <ModalInputWrapper label="Insurance Type">
        <SimpleDropDown
          id="account-insurance-type"
          options={lifeInsurnaceTypes}
          toDisplay={(x) => x || ""}
          selectedValue={details.lifeInsuranceType}
          onSelect={(v) => {
            const newDetails = {
              ...details,
              lifeInsuranceType: v,
            };

            setDetails(newDetails);
            onUpdate?.({
              details: {
                lifeInsuranceDetails: newDetails,
              },
            });
          }}
        />
      </ModalInputWrapper>
      <ModalInput
        type="number"
        id="account-insurance-length"
        label="Length (in years)"
        placeholder="50"
        onChange={(v) => {
          setDetails({
            ...details,
            lengthInYears: sanitizeStringNumberToString(v),
          });
        }}
        value={details.lengthInYears}
        currencyOverrides={{
          allowDecimals: false,
          decimalScale: 0,
          maxLength: 3,
        }}
      />

      <ModalInput
        id="account-annual-premium"
        type="money"
        label={"Annual Premium"}
        placeholder="$1,000"
        value={details.annualPremium}
        onChange={(v) => {
          setDetails({
            ...details,
            annualPremium: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalDateInput
        id="account-effective-start-date"
        label={"Effective Start Date"}
        placeholderText="01/20/2020"
        maxDate={new Date()}
        selected={
          details.effectiveStartDate
            ? new Date(details.effectiveStartDate)
            : null
        }
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...details,
              effectiveStartDate: date?.toUTCString() ?? undefined,
            };

            setDetails(newDetails);
            onUpdate?.({
              details: {
                lifeInsuranceDetails: newDetails,
              },
            });
          }
        }}
      />

      <ModalInput
        id="account-account-number"
        type="string"
        label={"Account Number"}
        placeholder="abc123..."
        value={details.accountNumber}
        onChange={(v) => {
          setDetails({
            ...details,
            accountNumber: v,
          });
        }}
        onBlur={onBlurUpdate}
        stringOverrides={{
          keyPressValidation: {
            inputRegex: ALPHANUMERIC_REGEX,
          },
        }}
      />
    </>
  );
};

const lifeInsurnaceTypes: string[] = [
  "Term",
  "Whole",
  "Universal",
  "Variable",
  "Group",
  "Joint",
  "Simplified",
  "Guaranteed",
  "Mortgage Life Insurance",
  "Credit Life Insurance",
  "Other",
  "I don't know",
];
