import * as React from "react";
import Collapsible from "react-collapsible";
import { Icon, IconAsset } from "components/core/icons";
import { useSessionStorage } from "core/utils/browser-storage";

interface Props {
  title: string;
  stateKey: string;
}

export const CollapsibleSection: React.FC<Props> = ({
  title,
  stateKey,
  children,
}) => {
  const [isOpen, setIsOpen] = useSessionStorage(`${stateKey}`, true);

  const caratStyles = React.useMemo(() => {
    return isOpen
      ? "accounts__section-carat"
      : "accounts__section-carat-closed";
  }, [isOpen]);

  return (
    <Collapsible
      overflowWhenOpen="visible"
      open={isOpen}
      trigger={
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div className={caratStyles}>
              <Icon
                asset={IconAsset.CollapsibleOpen}
                width="24px"
                height="24px"
              />
            </div>
          </div>
          <div
            style={{ flexGrow: 1, borderBottom: "1px solid #EEEEEE" }}
            className="accounts__section-category"
          >
            <div className="ellipsis" style={{ width: "50%" }}>
              {title}
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Collapsible>
  );
};
