import * as React from "react";
import CSS from "csstype";
import Modal from "react-modal";
import { BaseModalProps } from "components/core/modal/props";
import { TextD, TextStyle } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";
import "../styles/modal.css";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

// Used to specify the order of the modals when multiple modals are displayed
export enum ModalPriority {
  Lower = 500,
  Low = 750,
  Default = 1000,
  High = 1250,
  Higher = 1500,
}
interface PublicProps {
  showModal: boolean;
  onClose: () => void;
  title?: string;
  overflow?: CSS.Property.Overflow;
  children: React.ReactNode;

  defaultStyling?: boolean;

  onCancel?: () => void;
  cancelButtonText?: string;
  cancelButtonType?: LeafButtonStyle;

  onAction?: () => void;
  actionButtonText?: string;
  actionButtonType?: LeafButtonStyle;
  actionButtonLoading?: boolean;
  actionButtonDisabled?: boolean;
  additionalModalClassName?: string;

  modalPriority?: ModalPriority;

  buttonGrouping?: CSS.Property.JustifyContent;
}

type Props = PublicProps & BaseModalProps;

/*
 * Used to notify user to confirm their actions
 */
export const ConfirmationModal: React.FC<Props> = ({
  showModal,
  onClose,
  title,
  overflow,
  children,

  defaultStyling,

  onCancel,
  cancelButtonText,
  cancelButtonType,

  onAction,
  actionButtonText,
  actionButtonType,
  actionButtonDisabled,
  actionButtonLoading,
  additionalModalClassName,

  buttonGrouping,

  modalPriority = ModalPriority.Default,

  height,
  width,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
}) => {
  const mobileLayout = useMobileLayout();
  return (
    <Modal
      isOpen={showModal}
      className="modal-base new-modal-base"
      overlayClassName="overlay-base"
      style={{
        overlay: {
          backgroundColor: "rgba(3, 12, 23, 0.7)",
          zIndex: modalPriority,
        },
      }}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <div
        className={`confirmation-modal__wrapper ${additionalModalClassName}`}
        style={{
          overflow: overflow,
          height: height,
          width: width,
          maxWidth: "95vw",
          maxHeight: "95vh",
          color: "black",
          padding: defaultStyling
            ? mobileLayout
              ? "1rem"
              : "2.5rem"
            : undefined,
          gap: defaultStyling ? "1rem" : undefined,
        }}
      >
        {title && <TextD style={TextStyle.M31B}>{title}</TextD>}
        <div>{children}</div>
        <div
          style={{
            display: "flex",
            justifyContent: buttonGrouping ? buttonGrouping : "flex-end",
            gap: "1rem",
          }}
        >
          {onCancel && (
            <LeafButton
              buttonColour={LeafButtonColour.Transparent}
              buttonStyle={cancelButtonType ?? LeafButtonStyle.LeafSmall}
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
              }}
            >
              {cancelButtonText ?? "Cancel"}
            </LeafButton>
          )}
          {onAction && (
            <LeafButton
              buttonColour={LeafButtonColour.Black}
              buttonStyle={actionButtonType ?? LeafButtonStyle.LeafSmall}
              disabled={actionButtonDisabled}
              loading={actionButtonLoading}
              onClick={(e) => {
                e.stopPropagation();
                onAction();
              }}
            >
              {actionButtonText ?? "Confirm"}
            </LeafButton>
          )}
        </div>
      </div>
    </Modal>
  );
};
