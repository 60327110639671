import React, { ReactElement } from "react";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { AddCarHomeHealthInsuranceDetailsEntry } from "components/features/dashboard/components/add-account-tray/details-entry/car-home-health-insurance-details-entry";
import { AddCDDetailsEntry } from "./cd-details-entry";
import { AddCryptoDetailsEntry } from "components/features/dashboard/components/add-account-tray/details-entry/crypto-details-input";
import { AddHomeConstructionDetailsEntry } from "./home-construction-details-entry";
import { AddHomeEquityLineOfCreditDetailsEntry } from "./home-equity-line-of-credit-details-entry";
import { AddHomeEquityLoanDetailsEntry } from "./home-equity-loan-details-entry";
import { AddLifeInsuranceDetailsEntry } from "components/features/dashboard/components/add-account-tray/details-entry/life-insurance-details-entry";
import { AddMortgageDetailsEntry } from "./mortgage-details-entry";
import { AddRentalPropertyDetailsEntry } from "components/features/dashboard/components/add-account-tray/details-entry/rental-property-details-entry";
import { AddSavingDetailsEntry } from "./saving-details-entry";
import { DefaultBalanceOrLoginEntryTray } from "./default-balance-or-login-entry";
import { Input } from "components/core/input";
import { LoanDetailsEntry } from "components/features/dashboard/components/add-account-tray/details-entry/loan-details-entry";
import { OrConnect } from "components/features/dashboard/components/add-account-tray/details-entry/or-connect";
import { PrivateCompanyDetailsEntry } from "components/features/dashboard/components/add-account-tray/details-entry/private-company-details-entry";
import { PrivateStockDetailsEntry } from "./private-stock-details-entry";
import { PylonALink } from "components/core/alink";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  AccountSubtypeEnum,
  AccountType,
  IsAccountTypeConnectable,
} from "components/features/dashboard/components/add-account-tray/models";
const VALID_CHARACTER_REGEX = /[a-zA-Z0-9 ]/;

export const AddAccountDetailsEntry = () => {
  const { clearStack, trayStack, popTray } = React.useContext(SideTrayContext);
  const {
    accountName,
    accountType,
    accountSubType,
    balance,
    setBalance,
    setAccountName,
    details,
    setDetails,
    onCompleteActions,
    submit,
    clear,
    addLoading,
  } = React.useContext(AddAccountsContext);

  const onComplete = () => {
    if (isDisabled()) {
      return;
    }

    if (!onCompleteActions?.keepStackOnAccountAdd) {
      clearStack();
    }

    clear();
  };

  const isDisabled = (): boolean => {
    switch (accountSubType) {
      case AccountSubtypeEnum.Mortgage: {
        if (
          details?.mortgageDetails?.estimatedValue == null ||
          balance == null // Loose checking to cover both null and undefined cases
        ) {
          return true;
        }
        return false;
      }
      case AccountSubtypeEnum.RentalProperty: {
        if (
          details?.rentalPropertyDetails?.estimatedValue == null ||
          balance == null // Loose checking to cover both null and undefined cases
        ) {
          return true;
        }
        return false;
      }
      case AccountSubtypeEnum.PrivateStock: {
        if (
          details?.privateStockDetails?.sharesOwned === null ||
          details?.privateStockDetails?.sharesOwned === undefined
        ) {
          return true;
        }

        return false;
      }
      case AccountSubtypeEnum.Cryptocurrency: {
        if (!details?.cryptoDetails?.holdings) {
          return true;
        }

        if (
          details?.cryptoDetails?.holdings &&
          details?.cryptoDetails?.holdings.length > 0
        ) {
          for (let h of details?.cryptoDetails?.holdings) {
            if (h.total <= 0 || h.symbol === "") {
              return true;
            }
          }
        }
        return false;
      }
      case AccountSubtypeEnum.PrivateCompany: {
        if (
          details?.privateCompanyDetails?.estimatedValueAtInvestmentTime ===
            null ||
          details?.privateCompanyDetails?.estimatedValueAtInvestmentTime ===
            undefined
        ) {
          return true;
        }
        if (
          details?.privateCompanyDetails?.estimatedInvestmentValue === null ||
          details?.privateCompanyDetails?.estimatedInvestmentValue === undefined
        ) {
          return true;
        }

        return false;
      }
    }
    return accountName === "";
  };

  const getInstructionText = (accountType: string, accountSubType: string) => {
    switch (accountSubType) {
      case AccountSubtypeEnum.PrivateStock:
        return "What are the details of your private equity (shares or options)";
      case AccountSubtypeEnum.PrivateCompany:
        return "What are the details of your private company investment";
      case AccountSubtypeEnum.LifeInsurance:
        return "What are the details of your Life Insurance policy";
      default:
    }

    switch (accountType) {
      case AccountType.Insurance:
        return "What are the details of your Insurance policy";
      default: // Do nothing let next block handle it
    }

    return "What are the details of your account";
  };

  const getInstructionHint = (accountSubType: string) => {
    switch (accountSubType) {
      case AccountSubtypeEnum.PrivateStock:
        return "Pylon Inc";
      case AccountSubtypeEnum.Cryptocurrency:
        return "Coinbase";
    }

    return "Wells Fargo";
  };

  const _updateAccount = () => {
    if (isDisabled()) {
      return;
    }
    submit(onComplete);
  };

  let introText = "";
  let detailComp: ReactElement | null = null;

  switch (accountSubType) {
    case AccountSubtypeEnum.Mortgage: {
      introText = "Please enter your mortgage information";
      detailComp = (
        <AddMortgageDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }
    case AccountSubtypeEnum.RentalProperty: {
      introText = "Please enter your rental property information";
      detailComp = (
        <AddRentalPropertyDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }
    case AccountSubtypeEnum.SavingsAccounts: {
      introText = "Please enter your savings account information";
      detailComp = (
        <AddSavingDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }
    case AccountSubtypeEnum.PrivateStock: {
      introText = "Please enter your private stock information";
      detailComp = (
        <PrivateStockDetailsEntry
          details={details}
          onUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }
    case AccountSubtypeEnum.PrivateCompany: {
      introText = "Please enter your private company information";
      detailComp = (
        <PrivateCompanyDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );

      break;
    }
    case AccountSubtypeEnum.CD: {
      introText = "Please enter your account information";
      detailComp = (
        <AddCDDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }
    case AccountSubtypeEnum.HomeEquityLineOfCredit: {
      introText =
        "Please enter your home equity line of credit information below";
      detailComp = (
        <AddHomeEquityLineOfCreditDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }

    case AccountSubtypeEnum.HomeEquityLoan: {
      introText = "Please enter your home equity loan information below";
      detailComp = (
        <AddHomeEquityLoanDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }
    case AccountSubtypeEnum.HomeConstruction: {
      introText = "Please fill in the construction loan details below";
      detailComp = (
        <AddHomeConstructionDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );

      break;
    }
    case AccountSubtypeEnum.Cryptocurrency: {
      introText = "Please add your cryptocurrency details below";
      detailComp = (
        <AddCryptoDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
    }
    case AccountSubtypeEnum.CarInsurance: // Fallthrough
    case AccountSubtypeEnum.HomeInsurance: // Fallthrough
    case AccountSubtypeEnum.HealthInsurance: // Fallthrough
      introText = "Please enter your insurance details below";
      detailComp = (
        <AddCarHomeHealthInsuranceDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;

    case AccountSubtypeEnum.LifeInsurance:
      introText = "Please enter your insurance details below";
      detailComp = (
        <AddLifeInsuranceDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
  }

  switch (accountType) {
    case AccountType.Loans:
      introText = "Please enter your loan details below";
      detailComp = (
        <LoanDetailsEntry
          balance={balance}
          onBalanceUpdate={setBalance}
          details={details}
          onDetailsUpdate={setDetails}
          onEnter={_updateAccount}
        />
      );
      break;
  }

  if (detailComp) {
    return (
      <div
        className="add-account-tray"
        style={{
          flexDirection: "column",
          marginTop: "6vh",
          marginBottom: "3rem",
        }}
      >
        {IsAccountTypeConnectable(accountType, accountSubType) && <OrConnect />}
        <div>
          {introText && (
            <div className={`add-account-tray__content `}>
              <div className="add-account-tray__step-action">
                <h5>
                  {IsAccountTypeConnectable(accountType, accountSubType) ? (
                    <>
                      Manually track it yourself
                      <span style={{ color: "var(--blue-01)" }}>.</span>
                      <br />
                      <div
                        style={{
                          fontWeight: "normal",
                          fontSize: "1rem",
                        }}
                      >
                        {introText}
                        <span style={{ color: "var(--blue-01)" }}>.</span>
                        <br />
                      </div>
                    </>
                  ) : (
                    <>
                      {getInstructionText(accountType, accountSubType)}
                      <span style={{ color: "var(--blue-01)" }}>?</span>
                      <br />
                      <span style={{ color: "var(--blue-01)" }}>＿</span>
                      <div
                        style={{
                          fontWeight: "normal",
                          fontSize: "1rem",
                        }}
                      >
                        {introText}
                        <span style={{ color: "var(--blue-01)" }}>.</span>
                        <br />
                      </div>
                    </>
                  )}
                </h5>
              </div>
            </div>
          )}
          <form
            autoComplete="off"
            style={{ width: "100%" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <TextD style={TextStyle.M15}>
              <div className="flex-column gap-1 mt1">
                <div>
                  Name
                  <Input
                    autoFocus
                    type="text"
                    placeholder={getInstructionHint(accountSubType)}
                    value={accountName}
                    keyPressValidation={{
                      inputRegex: VALID_CHARACTER_REGEX,
                      errorMessage: "Please use alphanumeric characters",
                    }}
                    onEnter={_updateAccount}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (accountName.length >= 64) {
                        return;
                      }
                      setAccountName(e.currentTarget.value);
                    }}
                  />
                </div>
                {detailComp}

                <div className="flex-row gap-half">
                  {trayStack.length > 1 && (
                    <>
                      <LeafButton
                        buttonStyle={LeafButtonStyle.LeafSmall}
                        buttonColour={LeafButtonColour.Transparent}
                        onClick={() => {
                          popTray();
                        }}
                      >
                        Prev
                      </LeafButton>
                    </>
                  )}
                  <LeafButton
                    disabled={isDisabled()}
                    buttonStyle={LeafButtonStyle.LeafSmall}
                    buttonColour={LeafButtonColour.Black}
                    onClick={() => _updateAccount()}
                    loading={addLoading}
                  >
                    Finish
                  </LeafButton>
                </div>
                {accountSubType === AccountSubtypeEnum.Cryptocurrency && (
                  <div>
                    <Text type={TextType.Div} style={TextStyle.Hint}>
                      Cryptocurrency prices powered by{" "}
                      <PylonALink
                        href="https://www.coingecko.com/en"
                        target="_blank"
                      >
                        CoinGecko
                      </PylonALink>
                      .
                    </Text>
                  </div>
                )}
              </div>
            </TextD>
          </form>
        </div>
      </div>
    );
  }

  return (
    <DefaultBalanceOrLoginEntryTray
      isDisabled={isDisabled()}
      onEnter={_updateAccount}
    />
  );
};
