import { gql } from "@apollo/client";

export interface OnboardingState {
  onboardingState: {
    complete: boolean;
    mustComplete: boolean;
    preferences: string;
  };
}

export const OnboardingStateQuery = gql`
  query OnboardingState {
    onboardingState {
      complete
      mustComplete
      preferences
    }
  }
`;

export const SaveOnboardingPreferences = gql`
  mutation SaveOnboardingPreferences($prefs: [OnboardingPreferenceInput]!) {
    saveOnboardingPreference(prefs: $prefs)
  }
`;

export const completeMutation = gql`
  mutation completeOnboarding($flow: String) {
    completeOnboarding(flow: $flow)
  }
`;
