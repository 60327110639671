import _ from "lodash";
import { Account, Colour } from "core/models";
import { format, isValid } from "date-fns";
import { TextD, TextStyle } from "components/core/text";
import { toMoneyString, toPercentageString } from "core/utils";
import { UserDisplayName } from "core/utils/profile";

export enum ValueCardType {
  String,
  Money,
  Percentage,
  Date,
}

export interface Props {
  type: ValueCardType;
  title: string;
  value: string | number | undefined;
}

export const AccountInformationValueCard: React.FC<Props> = ({
  type,
  title,
  value,
}) => {
  if (_.isNil(value)) {
    return null;
  }

  if (type === ValueCardType.Money || type === ValueCardType.Percentage) {
    const v = Number.parseFloat(value as string);
    if (!_.isFinite(v)) {
      return null;
    }
  }

  if (type === ValueCardType.Date) {
    if (!isValid(new Date(value))) {
      return null;
    }
  }

  let valueComp: string;
  switch (type) {
    case ValueCardType.Money:
      valueComp = toMoneyString(Number.parseFloat(value as string));
      break;
    case ValueCardType.Percentage:
      valueComp = toPercentageString(Number.parseFloat(value as string) / 100);
      break;
    case ValueCardType.Date:
      valueComp = format(new Date(value), "MM/dd/yyyy");
      break;

    case ValueCardType.String: // Fallthrough
    default:
      valueComp = value as string;
  }

  return (
    <TextD style={TextStyle.M15} colour={Colour.Black}>
      <div className="account-detail__detail-row">
        <div className="account-detail__detail-label">{title}</div>
        <div className="account-detail__detail-value">{valueComp}</div>
      </div>
    </TextD>
  );
};

export const AccountRecipientCard: React.FC<{
  account: Account;
}> = ({ account }) => {
  // Only owner should see the recipient list
  if (!account.isOwner || _.isEmpty(account.recipients)) {
    return null;
  }

  return (
    <TextD style={TextStyle.M15} colour={Colour.Black}>
      <div className="account-detail__detail-row">
        <div className="account-detail__detail-label">Who has access</div>
        <div className="account-detail__detail-value flex-column">
          {account.recipients?.map((r) => (
            <div key={r.id}>{UserDisplayName(r)}</div>
          ))}
        </div>
      </div>
    </TextD>
  );
};

export const AutoDeductToggleDisplayCard: React.FC<{
  checked: boolean | undefined;
  isConnected: boolean;
}> = ({ checked, isConnected }) => {
  if (isConnected || !checked) {
    return null;
  }

  return (
    <AccountInformationValueCard
      type={ValueCardType.String}
      title={"Automatically Deduct Principal"}
      value={"Yes"}
    />
  );
};
