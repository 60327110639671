import * as React from "react";
import { TaxContext } from "components/contexts/tax-context";
import { TaxPageSection } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section";
import { toMoneyString } from "core/utils";

export const TaxSectionSupplementalWithholding: React.FC = () => {
  const { bonusIncomeEvents } = React.useContext(TaxContext);

  const stateWithholding =
    bonusIncomeEvents?.reduce((total, incomeEvent) => {
      return (
        total +
        parseFloat(incomeEvent?.stateWithholding || "0") +
        parseFloat(incomeEvent?.spouseStateWithholding || "0")
      );
    }, 0) || 0;
  const federalWithholding =
    bonusIncomeEvents?.reduce((total, incomeEvent) => {
      return (
        total +
        parseFloat(incomeEvent?.federalWithholding || "0") +
        parseFloat(incomeEvent?.spouseFederalWithholding || "0")
      );
    }, 0) || 0;

  return (
    <TaxPageSection
      defaultClosed={true}
      localStorageKey="taxes.supplementalincome"
      headerLeft="Bonus and Commission Withholding"
      headerRight={
        <>
          <div className="table-cell state-amount no-mobile">
            {toMoneyString(stateWithholding)}
          </div>
          <div className="table-cell federal-amount">
            {toMoneyString(federalWithholding)}
          </div>
        </>
      }
      bodyLeft="Provide any supplemental income that took the form of a bonus."
    />
  );
};
