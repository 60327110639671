import { gql } from "@apollo/client";

export interface CustomTransactionCategory {
  id: string;
  category: string;
}

export interface CreateTransactionCategoryInput {
  category: string;
  merchants?: string[];
}

export interface CreateTransactionCategoryResponse {
  createCustomTransactionCategories: CustomTransactionCategory;
}

export const CreateTransactionCategory = gql`
  mutation createCustomTransactionCategories(
    $input: AddCustomTransactionCategoryInput!
  ) {
    createCustomTransactionCategories(input: $input) {
      id
      category
    }
  }
`;

export interface CustomTransactionCategoryResponse {
  customTransactionCategories: CustomTransactionCategory[];
}

export const GetCustomTransactionCategoryQuery = gql`
  query customTransactionCategories {
    customTransactionCategories {
      id
      category
    }
  }
`;

export interface TransactionMerchantsResponse {
  transactionMerchants: string[];
}

export const GetTransactionMerchants = gql`
  query transactionMerchants {
    transactionMerchants
  }
`;

export interface TransactionRule {
  id: string;
  category?: string;
  merchant?: string;
}

export interface TransactionRulesResponse {
  transactionRules: TransactionRule[];
}

export const GetTransactionRulesQuery = gql`
  query transactionRules {
    transactionRules {
      id
      category
      merchant
    }
  }
`;

export interface CreateTransactionRuleInput {
  category: string;
  merchants?: string[];
}

export interface CreateTransactionRuleResponse {
  createCustomTransactionCategories: CustomTransactionCategory;
}

export const CreateTransactionRule = gql`
  mutation createTransactionRules($input: AddTransactionRuleInput!) {
    createTransactionRules(input: $input)
  }
`;

export interface DeleteCustomTransactionCategoryMutationInput {
  id: string;
}

export const DeleteCustomTransactionCategoryMutation = gql`
  mutation deleteCustomTransactionCategory($id: ID!) {
    deleteCustomTransactionCategory(id: $id)
  }
`;

export interface DeleteTransactionRuleMutationInput {
  id: string;
}

export const DeleteTransactionRuleMutation = gql`
  mutation deleteTransactionRule($id: ID!) {
    deleteTransactionRule(id: $id)
  }
`;

export interface TransactionSubscriptionRule {
  id: string;
  merchant: string;
  amount: string;
  recurringExpenseType: string;
}

export interface GetTransactionSubscriptionRulesResponse {
  transactionSubscriptionRules: TransactionSubscriptionRule[];
}

export const GetTransactionSubscriptionRuleQuery = gql`
  query transactionSubscriptionRules {
    transactionSubscriptionRules {
      id
      merchant
      amount
      recurringExpenseType
    }
  }
`;
export interface DeleteTransactionSubscriptionRuleMutationInput {
  id: string;
}

export const DeleteTransactionSubscriptionRuleMutation = gql`
  mutation deleteSubscriptionRule($id: ID!) {
    deleteSubscriptionRule(id: $id)
  }
`;
