import * as React from "react";
import { Colour } from "core/models";
import { dateFormatMMMMDDYY } from "core/utils";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentFolderSuggestionSetting } from "core/queries/documents";
import { ManageDocumentTray } from "components/features/dashboard/components/manage-document-tray";
import { SessionContext } from "components/contexts/session-context-provider";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import "./styles.css";

export interface PublicProps {
  document: DocumentData;
  open: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  suggestion?: DocumentFolderSuggestionSetting;
}

export const DocumentRowCard: React.FC<PublicProps> = ({
  document,
  onClose,
  open,
  onOpen,
  suggestion,
}) => {
  const { userID } = React.useContext(SessionContext.context);
  const { pushTray } = React.useContext(SideTrayContext);
  const isSharedWithMe = document.owner?.id !== userID;

  React.useEffect(() => {
    if (open) {
      pushTray({
        id: `document-${document.id}`,
        header: <SimpleSideTrayHeader text="Document Information" />,
        children: <ManageDocumentTray document={document} />,
        onClose: () => {
          onClose?.();
        },
        className: "manage-document",
      });
    }
    // Only monitor open as we don't want to multiple tray pops stack on top of each other
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div className="row-card-wrapper selectable">
      <div
        className="row-card__linkable-area table-row"
        onClick={onOpen}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <div className="row-card__content table-cell flex-row">
          <div
            style={{
              // marginLeft: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignSelf: "flex-start",
            }}
            className="row-card__title"
          ></div>

          <div
            className="row-card__content-title-text"
            style={{ marginRight: "0.5rem" }}
          >
            {document.name}
          </div>
          {!!suggestion && (
            <div
              style={{
                paddingLeft: "1.5rem",
              }}
            >
              <Text
                style={TextStyle.O10}
                type={TextType.Div}
                format={TextFormat.UpperCase}
                colour={Colour.Text03}
              >
                {suggestion.itemTitle} - Recommend by pylon
              </Text>
            </div>
          )}
        </div>
        <div className="table-cell owner">
          {isSharedWithMe
            ? document.owner?.firstName || document.owner?.email
            : "Me"}
        </div>
        <div className="row-card__balance table-cell value">
          {dateFormatMMMMDDYY(new Date(document.createdAt))}
        </div>
      </div>
    </div>
  );
};
