import * as React from "react";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Link } from "react-router-dom";
import { PaginatedFooter } from "core/paginated-footer";
import {
  Text,
  TextD,
  TextFormat,
  TextStyle
  } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  AccountSubType,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  accountsBySubtypesQuery,
  AccountsBySubtypesResponse,
} from "core/queries/accounts";
import {
  formatOptionalStringMoney,
  formatOptionalStringPercentage,
  toMoneyString,
} from "core/utils";

interface Props {
  limit?: number;
  accountID?: string;
  enablePagination?: boolean;
  hideEmptyState?: boolean;
}
export const PrivateHoldingsSection: React.FC<Props> = ({
  limit,
  accountID,
  enablePagination,
  hideEmptyState,
}) => {
  const { data, refetch } = useQuery<AccountsBySubtypesResponse>(
    accountsBySubtypesQuery,
    {
      variables: {
        subtypes: [
          AccountSubType[AccountType.NonRetirement]["Private Company"],
          AccountSubType[AccountType.NonRetirement]["Private Stock"],
        ],
      },
    }
  );

  const holdings = React.useMemo(() => {
    return data?.accountsBySubtypes;
  }, [data?.accountsBySubtypes]);

  const total = holdings?.length || 0;
  const showPaging = limit;
  const [page, setPage] = React.useState(0);
  const hasNext = !limit ? false : total > (page + 1) * limit;
  const hasPrevious = page > 0;
  const goToPage = (n: number) => {
    if (!showPaging) {
      return;
    }
    setPage(n);
    refetch({
      accountID,
      limit,
      offset: n * (limit || 0),
    });
  };

  const emptyState = hideEmptyState ? null : <div className=""></div>;

  return (
    ((holdings?.length || 0) > 0 && (
      <div className="private-stock-section">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0",
            marginTop: "3rem",
            fontFamily: "'Montserrat', sans-serif",
            alignItems: "center",
          }}
        >
          <h6>Private Stock</h6>
          {limit && !enablePagination && (
            <Link to={DashboardPages.InvestmentHoldings}>
              <TextD style={TextStyle.M15SM} colour={Colour.Blue01}>
                SEE ALL MY STOCKS
              </TextD>
            </Link>
          )}
        </div>

        <div
          style={{
            display: "flex",
          }}
        >
          {holdings && (
            <div className="flex-column" style={{ width: "100%" }}>
              <div className="table private-stock">
                <div className="table-head">
                  <div className="table-cell name">Stock/Company</div>
                  <div className="table-cell owned no-mobile">Amount Owned</div>
                  <div className="table-cell shares no-mobile">
                    Shares Owned
                  </div>
                  <div className="table-cell no-mobile fmv">FMV</div>
                  <div className="table-cell current">Current Value</div>
                </div>
                <div className="table-body">
                  {holdings.map((holding, i) => {
                    return (
                      <div className="table-row" key={i}>
                        <div
                          title={holding.name}
                          className="table-cell flex-row name"
                        >
                          {!accountID && (
                            <div>
                              <Link
                                to={`${DashboardPages.AccountDetails}?accountID=${holding?.account_id}`}
                              >
                                {holding && (
                                  <div style={{ marginRight: ".5rem" }}>
                                    <AccountIcon
                                      account={holding}
                                      scale={0.75}
                                      backgroundColor="var(--text-01)"
                                    />
                                  </div>
                                )}
                              </Link>
                            </div>
                          )}
                          {holding.name}
                        </div>
                        <div className="no-mobile owned table-cell">
                          {(holding?.details?.privateCompanyDetails
                            ?.amountInvestedPercentage &&
                            formatOptionalStringPercentage(
                              holding?.details?.privateCompanyDetails
                                ?.amountInvestedPercentage
                            )) || (
                            <Text
                              weight={400}
                              colour={Colour.Gray70}
                              size={"0.75rem"}
                              format={TextFormat.UpperCase}
                            >
                              N/A
                            </Text>
                          )}
                        </div>
                        <div className="no-mobile shares table-cell">
                          {(holding?.details?.privateStockDetails
                            ?.sharesOwned &&
                            parseFloat(
                              holding?.details?.privateStockDetails.sharesOwned
                            )) || (
                            <Text
                              weight={400}
                              colour={Colour.Gray70}
                              size={"0.75rem"}
                              format={TextFormat.UpperCase}
                            >
                              N/A
                            </Text>
                          )}
                        </div>
                        <div className="fmv table-cell no-mobile">
                          {(holding?.details?.privateCompanyDetails
                            ?.estimatedInvestmentValue &&
                            formatOptionalStringMoney(
                              holding?.details?.privateCompanyDetails
                                ?.estimatedInvestmentValue
                            )) ||
                            (holding?.details?.privateStockDetails
                              ?.valuePerShare &&
                              formatOptionalStringMoney(
                                holding?.details?.privateStockDetails
                                  ?.valuePerShare
                              )) ||
                            "N/A"}
                        </div>
                        <div className="current table-cell">
                          {toMoneyString(holding?.balances.current || 0)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                {enablePagination && limit && (
                  <PaginatedFooter
                    hasNext={hasNext}
                    hasPrevious={hasPrevious}
                    page={page}
                    totalPages={Math.ceil(total / limit)}
                    goToPage={goToPage}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )) ||
    emptyState
  );
};
