import { CollaborationRelationships } from "core/queries/collaborations";

export type SuggestableRelationship =
  | CollaborationRelationships.Spouse
  | CollaborationRelationships.Accountant;

export const SUGGESTED_RELATIONSHIPS: Readonly<SuggestableRelationship[]> = [
  CollaborationRelationships.Spouse,
  CollaborationRelationships.Accountant,
];
