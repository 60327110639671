import { BufferedInput } from "core/components/buffered-input";
import { Colour } from "core/models";
import { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { Props as InputProps } from "components/core/input";
import { TextD, TextStyle } from "components/core/text";
import "./styles.css";

export interface Props {
  id: string;
  value: number | string | undefined;
  onChange: (v: string | undefined) => void;

  label?: string;
  placeholder?: string;
  className?: string;
  type?: "money" | "percentage" | "number";

  currencyOverrides?: CurrencyInputProps;
  stringOverrides?: InputProps;
}

export const ModalBufferedInput: React.FC<Props> = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  className,
  type = "number",
  currencyOverrides,
}) => {
  let inputComponent = (
    <BufferedInput
      id={id}
      value={value}
      type={type}
      onChange={onChange}
      overrides={{
        ...currencyOverrides,
        placeholder: placeholder ? placeholder : undefined,
        className: `${className ? className : ""}`,
      }}
    />
  );

  if (!label) {
    return inputComponent;
  }

  return (
    <div className="flex-column gap-half">
      <TextD style={TextStyle.M15} colour={Colour.Black}>
        {label}
      </TextD>
      {inputComponent}
    </div>
  );
};
