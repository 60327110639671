import { DocumentData } from "components/features/dashboard/models/document";
import { Account } from "core/models";
import { CreditHistory } from "core/queries/credit";
import { CategoryData } from "core/queries/documents";
import { ScenarioPlan } from "core/queries/scenario-plans";

export type Asset =
  | Account
  | DocumentData
  | CategoryData
  | ScenarioPlan
  | CreditHistory;

export const isAccount = (asset: Asset): asset is Account => {
  return (asset as Account).__typename === "Account";
};

export const isDocument = (asset: Asset): asset is DocumentData => {
  return (asset as DocumentData).__typename === "Document";
};

export const isDocumentCategory = (asset: Asset): asset is CategoryData => {
  return (asset as CategoryData).__typename === "DocumentCategory";
};

export const isScenarioPlan = (asset: Asset): asset is ScenarioPlan => {
  return (asset as ScenarioPlan).__typename === "ScenarioPlan";
};

export const isCreditHistory = (asset: Asset): asset is CreditHistory => {
  let a = asset as CreditHistory;
  if (a.__typename) {
    return (
      ["PaginatedCreditHistory", "CreditHistory"].indexOf(a.__typename) >= 0
    );
  }
  return false;
  //return (asset as CreditHistory).__typename === "CreditHistory";
};
