import * as React from "react";
import { ChartNetWorthSeries } from "components/features/dashboard/components/chart-networth-series";
import { DayFiltering } from "components/features/dashboard/components/networth-summary-section";
import { InsuranceCalculator } from "components/features/dashboard/pages/insurances/personal-data";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { TaxContextProvider } from "components/contexts/tax-context";
import {
  DataTypeFiltering,
  OwnerTypeKey,
  SummaryContextProvider,
} from "components/contexts/summary-context";

export const InsurancePage: React.FC = () => {
  const [chartContext, setChartContext] = React.useState<any>();

  return (
    <PageWrapper>
      <SummaryContextProvider>
        <TaxContextProvider>
          <div className="flex-column">
            <div className="mt3" />
            <InsuranceCalculator />

            <div
              className="mt1"
              style={{
                fontWeight: 700,
                color: "var(--navy)",
                fontSize: "1.25rem",
              }}
            >
              My Networth History
            </div>
            <ChartNetWorthSeries
              chartContext={chartContext}
              setChartContext={setChartContext}
              interactedWithChart={false}
              enableInteractivity={false}
              onInteraction={() => {}}
              days={DayFiltering.NintetyDays}
              ownerTypeOverride={OwnerTypeKey.Me}
              dataTypeOverride={DataTypeFiltering.NetWorth}
            />

            {/* <NetworthSummarySection /> */}
          </div>
        </TaxContextProvider>
      </SummaryContextProvider>
    </PageWrapper>
  );
};
