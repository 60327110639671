import * as React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DayFiltering } from "components/features/dashboard/components/networth-summary-section";
import { getEnumKeyByEnumValue } from "../../../../../core/utils/typescript-helpers";
import { shortNumber, toMoneyString, toMoneyStringNoDecimal } from "core/utils";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  FetchNetworthHistoryResponse,
  FETCH_NETWORTH_HISTORY,
  NetworthHistory,
} from "../../../../../core/queries/overview";
import {
  DataTypeFiltering,
  OwnerTypeKey,
  SummaryContext,
} from "../../../../contexts/summary-context";

interface ChartState {
  options: ApexOptions;
  series: Array<any>;
}

interface Props {
  interactedWithChart: boolean;
  days: DayFiltering;
  onInteraction: () => void;

  chartContext: any;
  setChartContext: (args: any) => void;

  dataTypeOverride?: DataTypeFiltering;
  ownerTypeOverride?: OwnerTypeKey;
  enableInteractivity?: boolean;
}

export const ChartNetWorthSeries: React.FC<Props> = ({
  days,
  setChartContext,
  onInteraction,
  dataTypeOverride,
  ownerTypeOverride,
  enableInteractivity = true,
}) => {
  const {
    filteredOverviewData,
    ownerType: savedOwnerType,
    dataType: savedDataType,
  } = React.useContext(SummaryContext);

  const ownerType = ownerTypeOverride ? ownerTypeOverride : savedOwnerType;
  const dataType = dataTypeOverride ? dataTypeOverride : savedDataType;

  const { data } = useQuery<FetchNetworthHistoryResponse>(
    FETCH_NETWORTH_HISTORY,
    {
      fetchPolicy: "network-only",
      variables: {
        ownerType: ownerType,
        days: getEnumKeyByEnumValue(DayFiltering, days),
      },
    }
  );

  const noData =
    !data?.networthHistory?.history ||
    data.networthHistory?.history?.length === 0;
  const dataset = React.useMemo<NetworthHistory[]>(() => {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    const yesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    if (noData || !data?.networthHistory?.history)
      return [
        {
          date: yesterday,
          networth: 0,
          assets: 0,
          liabilities: 0,
          illiquidAsset: 0,
          liquidAsset: 0,
        },
        {
          date: new Date(),
          networth: 5,
          assets: 0,
          liabilities: 0,
          illiquidAsset: 0,
          liquidAsset: 0,
        },
      ];

    if (data?.networthHistory?.history?.length === 1) {
      const newHistory = data.networthHistory.history.map((o: any) => {
        return o;
      });
      newHistory.push({
        date: yesterday,
        networth: 0,
        assets: 0,
        liabilities: 0,
        illiquidAsset: 0,
        liquidAsset: 0,
      });
      return newHistory;
    }
    return data.networthHistory.history;
  }, [data, noData]);

  const networthValues = React.useMemo<number[]>(() => {
    return (
      dataset?.map((d) => {
        return typeToValue(dataType, d);
      }) || []
    );
  }, [dataset, dataType]);

  const commonInteraction = React.useCallback(
    (thisChartContext: any, config: any) => {
      onInteraction();
    },
    [onInteraction]
  );

  const state = React.useMemo<ChartState>(() => {
    return {
      options: {
        colors: ["#57BA8D"],
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
          zoom: {
            type: "x",
            enabled: enableInteractivity,
            autoScaleYaxis: true,
          },
          events: {
            click: commonInteraction,
            mouseDown: commonInteraction,
            selection: commonInteraction,
            dataPointSelection: commonInteraction,
            scrolled: commonInteraction,
            zoomed: commonInteraction,
            legendClick: commonInteraction,
            markerClick: commonInteraction,
            mounted: (thisChartContext: any, config: any) => {
              setChartContext(thisChartContext);
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "round",
          colors: undefined,
          width: 3,
          dashArray: 0,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickAmount: 4,

          labels: {
            show: !noData,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "",
              minute: "hh:mm tt",
            },
          },
          type: "datetime" as "datetime",
        },
        yaxis: {
          show: true,
          forceNiceScale: true,
          labels: {
            show: !noData,
            formatter: (value: any) => {
              return `${shortNumber(value)}`;
            },
          },
        },
        markers: {
          discrete: [],
        },
        tooltip: {
          enabled: !noData,
          y: {
            formatter: (value: any) => {
              return `${toMoneyString(value)}`;
            },
          },
        },
      },
      series: [
        {
          name: "Net Worth",
          data: dataset
            .map((d) => {
              return {
                x: new Date(d.date),
                y: typeToValue(dataType, d),
              };
            })
            .sort((a: { x: Date; y: number }, b: { x: Date; y: number }) => {
              return a.x.getTime() - b.x.getTime();
            }),
        },
      ],
    };
  }, [
    enableInteractivity,
    commonInteraction,
    noData,
    dataset,
    setChartContext,
    dataType,
  ]);

  const networthChange =
    networthValues?.length > 1 && !noData
      ? networthValues[0] - networthValues[1]
      : undefined;
  return (
    filteredOverviewData && (
      <div
        style={{
          flexGrow: 1,
          margin: "1rem",
          marginRight: "0",
          position: "relative",
        }}
        className="networth-series"
      >
        {noData && (
          <div
            style={{
              backgroundColor: "black",
              opacity: "0.1",
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "6px",
            }}
          ></div>
        )}
        <div
          className="flex-row between text-01 mobile-flex-column"
          style={{ paddingTop: "1rem" }}
        >
          <div />
          {networthValues?.length > 0 && (
            <div style={{ textAlign: "left" }}>
              <h6 style={{ fontWeight: "normal" }}>
                Total Net Worth: {toMoneyStringNoDecimal(networthValues[0])}
              </h6>
              {networthChange != null && networthChange !== 0 && (
                <>
                  <span
                    className={networthChange > 0 ? "green-01" : "red-01"}
                    style={{ fontWeight: "bold" }}
                  >
                    {toMoneyStringNoDecimal(networthChange)}
                    {networthChange > 0 && <>&uarr;</>}
                    {networthChange < 0 && <>&darr;</>}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
        <div>
          <Chart
            style={{
              opacity: noData ? "0.31" : "1",
            }}
            options={state.options}
            series={state.series}
            type="line"
          />
        </div>
      </div>
    )
  );
};

const typeToValue = (dataType: DataTypeFiltering, data: NetworthHistory) => {
  switch (dataType) {
    case DataTypeFiltering.Assets:
      return data.assets;
    case DataTypeFiltering.IlliquidAssets:
      return data.illiquidAsset;
    case DataTypeFiltering.LiquidAssets:
      return data.liquidAsset;
    case DataTypeFiltering.Liabilities:
      return data.liabilities;
    default:
      return data.networth;
  }
};
