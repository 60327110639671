import * as React from "react";
import _ from "lodash";
import Chart from "react-apexcharts";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { LeafButton } from "components/core/leaf-button";
import { LinkType, PylonLink } from "components/core/link";
import { SummaryContext } from "../../../../contexts/summary-context";
import { Text, TextFormat, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";

interface Props {}

export const ChartCreditUtilization: React.FC<Props> = () => {
  const mobileLayout = useMobileLayout();
  const { overviewData } = React.useContext(SummaryContext);

  const creditData = React.useMemo(() => {
    if (!overviewData) return null;
    const filteredOD = overviewData["shared"];
    return filteredOD?.credit;
  }, [overviewData]);

  const getUtilizationClassName = React.useCallback(() => {
    const utilization = creditData?.utilization * 100;
    return utilization === 0
      ? "better"
      : utilization >= 50
      ? "bad"
      : utilization > 0 && utilization < 10
      ? "best"
      : utilization >= 10 && utilization < 30
      ? "better"
      : "good";
  }, [creditData]);

  const getUtilizationColor = React.useCallback(() => {
    const utilization = creditData?.utilization * 100;
    return utilization === 0
      ? "var(--yellow-01)"
      : utilization >= 50
      ? "var(--red-01)"
      : utilization > 0 && utilization < 10
      ? "var(--green-01)"
      : utilization >= 10 && utilization < 30
      ? "var(--yellow-01)"
      : "var(--orange-01)";
  }, [creditData]);

  const state = React.useMemo<any>(() => {
    return {
      series: [Math.round(creditData?.utilization * 100)],
      options: {
        chart: {
          type: "radialBar",
          width: mobileLayout ? 360 : 180,
        },
        fill: {
          colors: [getUtilizationColor()],
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: mobileLayout ? `80%` : "70%",
            },
            track: {
              background: "var(--gray-10)",
            },
            dataLabels: {
              enabled: true,
              value: { fontSize: "1.5rem", offsetY: "-9" },
            },
          },
        },
        labels: [""],
      },
    };
  }, [creditData?.utilization, getUtilizationColor, mobileLayout]);

  const renderData = () => {
    return (
      <div
        style={{
          alignItems: "center",
          flexGrow: 1,
        }}
        className="credit-utilization flex-row between mobile-flex-column"
      >
        <div className="chart">
          <Chart
            options={state.options}
            series={state.series}
            type="radialBar"
            width={mobileLayout ? "300px" : "180px"}
          />
        </div>
        <div className="flex-row keys">
          <div className="chart-key">
            <div className={`key-block ${getUtilizationClassName()}`}></div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                type={TextType.Div}
                colour={Colour.Gray70}
                weight={700}
                size={"0.75rem"}
                format={TextFormat.UpperCase}
              >
                Credit Card Debt
              </Text>
              <Text
                type={TextType.Div}
                colour={Colour.Navy}
                weight={400}
                size={"1rem"}
              >
                {toMoneyString(creditData.debt)}
              </Text>
            </div>
          </div>
          <div className="chart-key">
            <div className="key-block"></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                type={TextType.Div}
                colour={Colour.Gray70}
                weight={700}
                size={"0.75rem"}
                format={TextFormat.UpperCase}
              >
                Credit Card Limit
              </Text>
              <Text
                type={TextType.Div}
                colour={Colour.Navy}
                weight={400}
                size={"1rem"}
              >
                {toMoneyString(creditData.limit)}
              </Text>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEmptyState = () => {
    return (
      <div
        style={{
          margin: mobileLayout ? "1rem 0" : "2.75rem 2rem",
          alignItems: mobileLayout ? "flex-start" : "center",
        }}
        className="empty-credit-utilization flex-row mobile-flex-column align-center gap-1"
      >
        <div
          style={{
            flex: "1",
          }}
        >
          <Text
            type={TextType.Div}
            colour={Colour.Gray70}
            weight={400}
            size={"1rem"}
          >
            Start by adding your credit card accounts and setting a credit
            limit.
          </Text>
        </div>
        <div
          style={{
            flex: "0",
            width: "fit-content",
            whiteSpace: "nowrap",
            alignSelf: mobileLayout ? "center" : undefined,
          }}
        >
          <PylonLink
            to={DashboardPages.Integrations}
            linkType={LinkType.InheritColour}
          >
            <LeafButton>Get Started</LeafButton>
          </PylonLink>
        </div>
      </div>
    );
  };

  return _.isNil(creditData?.utilization) || creditData?.accountsIncluded === 0
    ? renderEmptyState()
    : renderData();
};
