export enum WhatToDoSelections {
  Networth = "networth",
  Share = "share",
  TaxObligation = "tax-obligation",
  SaveDocuments = "save-documents",
  SeeRetirementAge = "see-retirement-age",
  TrackCreditScore = "track-credit-score",
  EstimateMyTaxes = "estimate-my-taxes",

  SellPrivateStock = "sell-private-stock",
  LowerInterestRate = "lower-any-interest-rate",
  PayOffDebt = "pay-off-debt-faster",
  Open529 = "open-529-plan",
  CompareNetworth = "compare-my-networth-with-others",
  HelpParentsOrganize = "help-my-parents-organize",
  SetEmergencyContact = "set-emergency-contact",
  CheckCredit = "check-my-credit",
  TrackCashflow = "track-monthly-cash-flow",
  TrackInvestmentsInOnePlace = "track-investments-in-one-place",
  BuyProperty = "buy-property",
  BuyVehicle = "buy-vehicle",
  InvestInPrivateCompanies = "invest-in-private-companiesm",
}

export enum OnboardingSelections {
  MartialStatusSingle = "maritial-status-single",
  MartialStatusMarried = "maritial-married",
  MartialStatusHeadOfHousehold = "maritial-head-of-household",

  ManageAccountsSpreadsheet = "manage-accounts-spreadsheet",
  ManageAccountsOffline = "manage-accounts-offline",
  ManageAccountsIDK = "manage-accounts-idk",
  ManageAccountsLotsOfApps = "manage-accounts-lots",
}

export const currentOfferings = [
  WhatToDoSelections.Networth,
  WhatToDoSelections.Share,
  WhatToDoSelections.TaxObligation,
  WhatToDoSelections.SaveDocuments,
  WhatToDoSelections.TrackCreditScore,
  WhatToDoSelections.CheckCredit,
  WhatToDoSelections.SetEmergencyContact,
  WhatToDoSelections.TrackCashflow,
  WhatToDoSelections.TrackInvestmentsInOnePlace,
  WhatToDoSelections.EstimateMyTaxes,
];

export const comingSoon = Object.values(WhatToDoSelections).filter(
  (v) => !currentOfferings.includes(v)
);
