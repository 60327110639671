import * as React from "react";
import { DocumentData } from "components/features/dashboard/models/document";
import { DownloadDocumentButton } from "components/features/dashboard/components/document-download-button";
import { SessionContext } from "../../../../contexts/session-context-provider";

interface Props {
  document?: DocumentData | null;
}

export const DownloadDocumentQuickAction: React.FC<Props> = ({ document }) => {
  const { userID } = React.useContext(SessionContext.context);
  const isSharedWithMe = document?.owner?.id !== userID;

  if (!document || isSharedWithMe) {
    return null;
  }

  return (
    <>
      <div
        key="share"
        className="flex-row align-center"
        style={{
          cursor: "pointer",
        }}
      >
        <DownloadDocumentButton document={document} asQuickAction />
      </div>
    </>
  );
};
