import * as React from "react";
import { ChartInvestmentPortfolio } from "components/features/dashboard/components/chart-investment-portfolio";
import { Colour } from "core/models";
import { InvestmentChangeTypeFilter } from "components/features/dashboard/components/investments-summary-section/components/investment-change-type-filter";
import { InvestmentDataTypeFilter } from "components/features/dashboard/components/investments-summary-section/components/investment-account-type-filter";
import { InvestmentOwnerFilter } from "components/features/dashboard/components/investments-summary-section/components/investment-owner-filter";
import { InvestmentSummary } from "components/features/dashboard/components/investments-summary-section/components/investment-summary";
import { InvestmentSummaryContext } from "components/features/dashboard/components/investments-summary-section/context/investments-summary-context";
import { InvestmentTimeFilter } from "components/features/dashboard/components/investments-summary-section/components/investment-time-filter";
import { Text, TextType } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";

export const InvestmentsSummarySection: React.FC = () => {
  const mobileLayout = useMobileLayout();
  const [interactedWithChart, setInteractedWithChart] =
    React.useState<boolean>(false);
  const [chartContext, setChartContext] = React.useState<any>();
  const { days } = React.useContext(InvestmentSummaryContext);

  const resetSeries = () => {
    if (!chartContext) return;
    chartContext.resetSeries();
    setInteractedWithChart(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem ",
          fontFamily: "'Montserrat', sans-serif",
        }}
      >
        <Text
          type={TextType.Div}
          weight={700}
          colour={Colour.Navy}
          size={"1.25rem"}
        >
          Investments History
        </Text>
        <div
          style={{
            padding: "0.25rem",
          }}
        />
        {!mobileLayout && (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <InvestmentChangeTypeFilter />
            <InvestmentDataTypeFilter />
            <InvestmentTimeFilter />
            <InvestmentOwnerFilter />
            {interactedWithChart && (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "0.25rem",
                }}
              >
                <button
                  onClick={() => resetSeries()}
                  className="button-as-link"
                >
                  <strong>Reset</strong>
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className="overview-component flex-row mobile-flex-column"
        style={{ display: "flex" }}
      >
        <ChartInvestmentPortfolio
          chartContext={chartContext}
          setChartContext={setChartContext}
          interactedWithChart={interactedWithChart}
          days={days}
          onInteraction={() => setInteractedWithChart(true)}
        />
        <InvestmentSummary />
      </div>
    </div>
  );
};
