import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import {
  addMonths,
  differenceInCalendarMonths,
  format,
  parse
  } from "date-fns";
import { dateFormatYYYY_MM_DD } from "core/utils";
import { Dropdown } from "react-bootstrap";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { GlobalFilteringContext } from "components/contexts/global-filtering-context";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import { SessionContext } from "components/contexts/session-context-provider";
import { toFirstDayOfNextMonth, toFirstDayOfThisMonth } from "core/utils/dates";
import { useContext } from "react";
import "./styles.css";

export const MONTH_SELECTOR_FORMAT = "yyyy-MM";

export interface Props {
  updateGloablFilter?: boolean;
}

export const TransactionMonthSelector: React.FC<Props> = ({
  updateGloablFilter,
}) => {
  const { createdAt } = useContext(SessionContext.context);
  const { month, setMonth } = useContext(AccountTransactionContext);

  const { setStartDate, setEndDate } = useContext(GlobalFilteringContext);

  const currentMonth = parse(month, MONTH_SELECTOR_FORMAT, new Date());
  const minDate = parse(
    format(createdAt ? new Date(createdAt) : new Date(), MONTH_SELECTOR_FORMAT),
    MONTH_SELECTOR_FORMAT,
    new Date()
  );
  const maxDate = new Date();

  const options: Date[] = [];
  for (let i = 0; i <= differenceInCalendarMonths(maxDate, minDate); i++) {
    const m = addMonths(maxDate, i * -1);
    options.push(m);
  }

  return (
    <Dropdown alignRight>
      <DropdownToggle
        as="div"
        bsPrefix="pylon-dropdown-toggle"
        className="transaction-month-selector__toggle"
      >
        <PylonDropDownToggle text={format(currentMonth, "MMMM yyyy")} />
      </DropdownToggle>

      <DropdownMenu>
        <div className="transaction-month-selector__menu-items">
          {options.map((o) => (
            <DropDownMenuItem
              key={o.getTime()}
              text={format(o, "MMMM yyyy")}
              onClick={() => {
                if (updateGloablFilter) {
                  setStartDate(dateFormatYYYY_MM_DD(toFirstDayOfThisMonth(o)));
                  setEndDate(dateFormatYYYY_MM_DD(toFirstDayOfNextMonth(o)));
                }
                setMonth(format(o, MONTH_SELECTOR_FORMAT));
                document.dispatchEvent(new MouseEvent("click"));
              }}
            />
          ))}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
