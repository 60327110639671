import qs from "qs";
import React, { useState } from "react";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon, IconAsset } from "components/core/icons";
import { Input } from "components/core/input";
import { LeafButton } from "components/core/leaf-button";
import { LinkType, PylonLink } from "components/core/link";
import { PylonALink } from "components/core/alink";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { Text, TextStyle, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import "./styles.css";
import {
  accountCategoriesExamplesQuery,
  accountCategoriesQuery,
  accountsQuery,
} from "core/queries/accounts";
import {
  CreateCoinbaseProMutation,
  CreateCoinbaseProMutationInput,
  CreateCoinbaseProMutationResponse,
} from "core/queries/coinbase";
import {
  FETCH_NETWORTH_HISTORY,
  FETCH_OVERVIEW_DATA,
  progressQuery,
} from "core/queries/overview";

export interface Props {
  onComplete: () => void;
}

export const CoinbaseProModalComponent: React.FC<Props> = ({ onComplete }) => {
  const { convertToManualProps } = React.useContext(AddAccountsContext);

  const [createCoinbaseProAccount, { loading }] = useMutation<
    CreateCoinbaseProMutationResponse,
    CreateCoinbaseProMutationInput
  >(CreateCoinbaseProMutation, {
    onCompleted: (data) => {
      const search = qs.stringify({
        accountID: data?.createCoinbaseProAccount?.account_id,
      });
      toast(
        <PylonToastBody title={"Account added successfully"}>
          We just added your coinbaes pro account for tracking!&nbsp;{" "}
          <PylonLink
            to={`${DashboardPages.AccountDetails}?${search}`}
            linkType={LinkType.Highlight}
          >
            View Account
          </PylonLink>
        </PylonToastBody>
      );
      onComplete();
    },
    onError: (err) => {
      toast(
        <PylonToastBody
          title={"An error occurred"}
          type={ToastType.Error}
          userError
        >
          There's an error adding the account.
          <br /> {err?.message ?? ""}
        </PylonToastBody>
      );
    },
    refetchQueries: [
      { query: accountsQuery },
      { query: accountCategoriesQuery },
      { query: accountCategoriesExamplesQuery },
      { query: progressQuery },
      { query: FETCH_OVERVIEW_DATA },
      { query: FETCH_NETWORTH_HISTORY },
    ],
  });

  const [apiKey, setApiKey] = useState<string>("");
  const [secretKey, setSecretKey] = useState<string>("");
  const [passphrase, setPassphrase] = useState<string>("");

  const mask = (v: string) => {
    return "*".repeat(v?.length ?? 0);
  };

  const disabled = !(apiKey && secretKey && passphrase);
  return (
    <form
      id="coinbase-pro-input"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div
        style={{
          margin: "3rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 3rem",
          }}
        >
          <h6>
            Connect Coinbase Pro
            <Icon
              asset={IconAsset.Dot}
              width="4px"
              height="4px"
              colour={Colour.Blue01}
            />
          </h6>
        </div>
        <div className="mt2">
          <Text
            type={TextType.Div}
            style={TextStyle.FieldLabel}
            weight={"bold"}
          >
            Instructions:
          </Text>
          <Text type={TextType.Div} style={TextStyle.Hint} textAlign="left">
            <ol
              style={{
                listStylePosition: "inside",
              }}
            >
              <li>
                Go to{" "}
                <PylonALink
                  colour={Colour.Blue01}
                  href="https://pro.coinbase.com/profile/api"
                  target="_blank"
                >
                  https://pro.coinbase.com
                </PylonALink>
                , login, and navigate to the API page, or click{" "}
                <PylonALink
                  colour={Colour.Blue01}
                  href="https://pro.coinbase.com/profile/api"
                  target="_blank"
                >
                  here
                </PylonALink>
                .
              </li>
              <li>
                Click{" "}
                <PylonALink
                  colour={Colour.Blue01}
                  href="https://pro.coinbase.com/profile/api"
                  target="_blank"
                >
                  "+ New API Key"
                </PylonALink>
                .
              </li>
              <li>
                Check the "View" checkbox under permissions. You can leave the
                rest blank.
              </li>
              <li>
                Copy the passphrase, come back to Pylon, and paste it into the
                input below.
              </li>
              <li>
                Click "Create API Key" and enter your two-factor verification
                code on Coinibase when prompted.
              </li>
              <li>
                Copy the "API Secret", come back to Pylon, and paste it below.
                Then close the pop up.
              </li>
              <li>
                You will see the new API key under the words "Default Portfolio"
                -- copy that API key and paste it below.
              </li>
            </ol>
          </Text>
        </div>

        <div
          className="flex-row between align-center mt1"
          style={{
            gap: "1rem",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            API Key
          </Text>
          <Input
            id="coinbase-pro-api-key"
            placeholder={"API Key..."}
            wrapperClassName="coinbase-pro-input"
            value={apiKey}
            onChange={(e) => {
              setApiKey(e.currentTarget.value);
            }}
          />
        </div>

        <div
          id="coinbase-pro-secret-key"
          className="flex-row between align-center"
          style={{
            gap: "1rem",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            API Secret
          </Text>
          <Input
            id="coinbase-pro-secret-key"
            placeholder="API Secret..."
            wrapperClassName="coinbase-pro-input"
            type="text"
            value={mask(secretKey)}
            onChange={(e) => {
              setSecretKey(e.currentTarget.value);
            }}
          />
        </div>

        <div
          id="coinbase-pro-passphrase"
          className="flex-row between align-center"
          style={{
            gap: "1rem",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Passphrase
          </Text>
          <Input
            wrapperClassName="coinbase-pro-input"
            placeholder="Passphrase..."
            value={mask(passphrase)}
            type="text"
            onChange={(e) => {
              setPassphrase(e.currentTarget.value);
            }}
          />
        </div>

        <div className="flex-row justify-center mt2">
          <LeafButton
            loading={loading}
            disabled={disabled}
            onClick={() => {
              if (disabled) {
                return;
              }

              createCoinbaseProAccount({
                variables: {
                  input: {
                    apiKey: apiKey,
                    secretKey: secretKey,
                    passphrase: passphrase,
                    convertToManualProps: convertToManualProps,
                  },
                },
              });
            }}
          >
            Connect
          </LeafButton>
        </div>
      </div>
    </form>
  );
};
