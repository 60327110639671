import * as React from "react";
import qs from "qs";
import { Account, AccountDetails } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { IntegrationSource } from "components/features/dashboard/models/integration-source";
import { LinkType, PylonLink } from "components/core/link";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import {
  AccountSubType,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  GoogleAdWordsConversionIDs,
  trackEvent,
} from "core/distribution/distribution";
import {
  accountCategoriesExamplesQuery,
  accountCategoriesQuery,
  accountsBySubtypeQuery,
  accountsQuery,
  addAccountMutation,
  AddAccountMutationResponse,
  addCryptoPortfolio,
} from "core/queries/accounts";
import {
  FETCH_NETWORTH_HISTORY,
  FETCH_OVERVIEW_DATA,
  progressQuery,
} from "core/queries/overview";

export const SESSION_STORAGE_DATA_KEY = "pylon-add-account-data";
export const DOCUMENT_KEY = "pylon_document_data";

export interface TokenExchangeResponse {
  itemID: string;
  institutionName: string;
  accounts: Account[];
  duplicateAccounts: Account[];
}

export interface OnCompleteAction {
  keepStackOnAccountAdd?: boolean;
  callbackOnAccountAdd?: (account: Account[]) => void;
}

export interface ConvertToManualProps {
  accountID: string;
  keepShared: boolean;
  hideConvertOption?: boolean;
}

export interface AddAccountContextValue {
  accountID: string;
  accountIDToConvert: string;
  sourceType: IntegrationSource;
  accountType: AccountType;
  accountSubType: string;
  accountName: string;
  balance: string;
  limit: string;
  purchasePercentage: string;
  plaidLinkToken: string;
  exchangeResponse: TokenExchangeResponse;
  onCompleteActions: OnCompleteAction;
  convertToManualProps?: ConvertToManualProps;
  details: AccountDetails;
  showPlaid: boolean;
  showCoinbase: boolean;
  addLoading?: boolean;

  setAccountID: React.Dispatch<React.SetStateAction<string>>;
  setAccountIDToConvert: React.Dispatch<React.SetStateAction<string>>;
  setSourceType: React.Dispatch<React.SetStateAction<IntegrationSource>>;
  setAccountType: React.Dispatch<React.SetStateAction<AccountType>>;
  setAccountSubType: React.Dispatch<React.SetStateAction<string>>;
  setAccountName: React.Dispatch<React.SetStateAction<string>>;
  setBalance: React.Dispatch<React.SetStateAction<string>>;
  setLimit: React.Dispatch<React.SetStateAction<string>>;
  setPurchasePercentage: React.Dispatch<React.SetStateAction<string>>;
  setTokenExchangeResponse: React.Dispatch<
    React.SetStateAction<TokenExchangeResponse>
  >;
  setPlaidLinkToken: React.Dispatch<React.SetStateAction<string>>;
  setOnCompleteActions: React.Dispatch<React.SetStateAction<OnCompleteAction>>;
  setConvertToManualProps: React.Dispatch<
    React.SetStateAction<ConvertToManualProps | undefined>
  >;
  setDetails: React.Dispatch<React.SetStateAction<AccountDetails>>;
  setShowPlaid: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCoinbase: React.Dispatch<React.SetStateAction<boolean>>;

  resumeFromSessionStorage: () => void;
  // Overrides useful for values being updated in the same function state
  saveToSessionStorage: (overrides?: Partial<AddAccountContextValue>) => void;

  submit: (cb?: () => void) => void;
  submitCrypto: (cb?: () => void) => void;
  clear: () => void;
}

export const AddAccountsContext = React.createContext<AddAccountContextValue>({
  accountID: "",
  accountIDToConvert: "",
  sourceType: IntegrationSource.Manual,
  accountType: AccountType.Banking,
  accountSubType: "",
  accountName: "",
  balance: "",
  limit: "",
  purchasePercentage: "",
  plaidLinkToken: "",
  exchangeResponse: {} as TokenExchangeResponse,
  onCompleteActions: {},
  details: {},
  showPlaid: false,
  showCoinbase: false,

  resumeFromSessionStorage: () => null,
  saveToSessionStorage: () => null,
  setAccountID: () => null,
  setAccountIDToConvert: () => null,
  setSourceType: () => null,
  setAccountType: () => null,
  setAccountSubType: () => null,
  setAccountName: () => null,
  setBalance: () => null,
  setLimit: () => null,
  setPurchasePercentage: () => null,
  setPlaidLinkToken: () => null,
  setTokenExchangeResponse: () => null,
  setOnCompleteActions: () => null,
  setDetails: () => null,
  setShowPlaid: () => null,
  setShowCoinbase: () => null,
  setConvertToManualProps: () => null,
  submit: () => null,
  submitCrypto: () => null,
  clear: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const AddAccountTrayContextProvider: React.FC<Props> = (
  props: Props
) => {
  const [showPlaid, setShowPlaid] = React.useState<boolean>(false);
  const [showCoinbase, setShowCoinbase] = React.useState<boolean>(false);

  const [accountID, setAccountID] = React.useState("");
  const [accountIDToConvert, setAccountIDToConvert] = React.useState("");
  const [sourceType, setSourceType] = React.useState(IntegrationSource.Manual);
  const [accountType, setAccountType] = React.useState(AccountType.Banking);
  const [accountSubType, setAccountSubType] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [balance, setBalance] = React.useState("");
  const [limit, setLimit] = React.useState("");
  const [purchasePercentage, setPurchasePercentage] = React.useState("");
  const [plaidLinkToken, setPlaidLinkToken] = React.useState("");
  const [onCompleteActions, setOnCompleteActions] =
    React.useState<OnCompleteAction>({});
  const [exchangeResponse, setTokenExchangeResponse] =
    React.useState<TokenExchangeResponse>({} as TokenExchangeResponse);
  const [convertToManualProps, setConvertToManualProps] = React.useState<
    ConvertToManualProps | undefined
  >(undefined);
  const [details, setDetails] = React.useState<AccountDetails>({});

  const [addAccount, { loading: addAccountLoading }] =
    useMutation<AddAccountMutationResponse>(addAccountMutation, {
      refetchQueries: [
        { query: accountsQuery },
        { query: accountCategoriesQuery },
        { query: accountCategoriesExamplesQuery },
        { query: FETCH_OVERVIEW_DATA },
        { query: FETCH_NETWORTH_HISTORY },
        {
          query: accountsBySubtypeQuery,
          variables: {
            subtype:
              AccountSubType[AccountType.NonRetirement][
                "Brokerage/Trading Account"
              ],
          },
        },
      ],
      onCompleted: () => {
        clear();
        gtag("event", "conversion", {
          send_to: GoogleAdWordsConversionIDs.AddedAnAccount,
        });
        gtag("event", `added_account`, {});
        trackEvent("Added Account", { integrationSource: "MANUAL" });
      },
      onError: (err) => {
        console.error("failed to add account", err);
      },
    });

  const [addCrypto, { loading: addCryptoLoading }] = useMutation(
    addCryptoPortfolio,
    {
      refetchQueries: [
        { query: accountsQuery },
        { query: progressQuery },
        { query: accountCategoriesQuery },
        { query: accountCategoriesExamplesQuery },
        { query: FETCH_OVERVIEW_DATA },
        { query: FETCH_NETWORTH_HISTORY },
      ],
      onCompleted: () => {
        clear();
      },
      onError: (err) => {
        console.error("failed to add account", err);
      },
    }
  );

  const clear = () => {
    setAccountID("");
    setAccountIDToConvert("");
    setAccountType(AccountType.Banking);
    setAccountSubType("");
    setSourceType(IntegrationSource.Manual);
    setAccountName("");
    setBalance("");
    setLimit("");
    setPurchasePercentage("");
    setPlaidLinkToken("");
    setTokenExchangeResponse({
      institutionName: "",
      itemID: "",
      accounts: [],
      duplicateAccounts: [],
    });
    setOnCompleteActions({});
    setConvertToManualProps(undefined);
    setDetails({});
  };

  const submit = (cb?: () => void) => {
    addAccount({
      variables: {
        account: {
          name: accountName,
          official_name: accountName,
          notes: "",
          balances: {
            available: parseFloat(balance) || 0,
            current: parseFloat(balance) || 0,
            limit: parseFloat(limit) || 0,
            iso_currency_code: "USD",
          },
          aprs: {
            purchasePercentage: parseFloat(purchasePercentage) || 0,
          },
          mask: "",
          subtype: accountSubType,
          type: accountType,
          integration_id: "",
          integration_source: IntegrationSource.Manual,
          details: details,
        },
      },
    }).then((resp) => {
      onCompleteActions?.callbackOnAccountAdd?.(
        resp?.data?.addAccount ? [resp?.data?.addAccount] : []
      );
      const accountID = resp?.data?.addAccount?.account_id;
      const search = qs.stringify({
        accountID: accountID,
      });

      toast(
        <PylonToastBody title={"Account added successfully"}>
          We just added your account {accountName} for tracking!&nbsp;{" "}
          <PylonLink
            to={`${DashboardPages.AccountDetails}?${search}`}
            linkType={LinkType.Highlight}
          >
            View Account
          </PylonLink>
        </PylonToastBody>,
        {
          closeOnClick: false,
          closeButton: true,
        }
      );
      cb?.();
    });
  };

  const submitCrypto = (cb?: () => void) => {
    addCrypto({
      variables: {
        input: {
          name: accountName,
          holdings: details.cryptoDetails?.holdings.map((v) => ({
            symbol: v.symbol,
            total: v.total,
          })),
        },
      },
    }).then((resp) => {
      onCompleteActions?.callbackOnAccountAdd?.(resp?.data ? [resp?.data] : []);
      const accountID = resp?.data?.AddCryptoPortfolio.account_id;
      const search = qs.stringify({
        accountID: accountID,
      });

      toast(
        <PylonToastBody title={"Account added successfully"}>
          We just added your account {accountName} for tracking!&nbsp;{" "}
          <PylonLink
            to={`${DashboardPages.AccountDetails}?${search}`}
            linkType={LinkType.Highlight}
          >
            View Account
          </PylonLink>
        </PylonToastBody>,
        {
          closeOnClick: false,
          closeButton: true,
        }
      );
      cb?.();
    });
  };

  const saveToSessionStorage = (
    overrides?: Partial<AddAccountContextValue>
  ) => {
    const sessionData: Partial<AddAccountContextValue> = {
      accountID: accountID,
      accountIDToConvert: accountIDToConvert,
      sourceType: sourceType,
      accountType: accountType,
      accountSubType: accountSubType,
      accountName: accountName,
      balance: balance,
      limit: limit,
      plaidLinkToken: plaidLinkToken,
      purchasePercentage: purchasePercentage,
      convertToManualProps: convertToManualProps,
      details: details,
      ...overrides,
    };

    sessionStorage.setItem(
      SESSION_STORAGE_DATA_KEY,
      JSON.stringify(sessionData)
    );
  };

  const resumeFromSessionStorage = () => {
    let data: Partial<AddAccountContextValue> = {};
    try {
      const savedData = sessionStorage.getItem(SESSION_STORAGE_DATA_KEY);
      if (savedData) {
        data = JSON.parse(savedData);
      }
    } catch (err) {
      // swallow any json parse error, corrupted data
      sessionStorage.removeItem(SESSION_STORAGE_DATA_KEY);
    }

    if (data.accountID) {
      setAccountID(data.accountID);
    }
    if (data.accountIDToConvert) {
      setAccountIDToConvert(data.accountIDToConvert);
    }
    if (data.sourceType) {
      setSourceType(data.sourceType);
    }
    if (data.accountType) {
      setAccountType(data.accountType);
    }
    if (data.accountSubType) {
      setAccountSubType(data.accountSubType);
    }
    if (data.accountName) {
      setAccountName(data.accountName);
    }
    if (data.balance) {
      setBalance(data.balance);
    }
    if (data.limit) {
      setLimit(data.limit);
    }
    if (data.plaidLinkToken) {
      setPlaidLinkToken(data.plaidLinkToken);
    }
    if (data.purchasePercentage) {
      setPurchasePercentage(data.purchasePercentage);
    }
    if (data.convertToManualProps) {
      setConvertToManualProps(data.convertToManualProps);
    }
    if (data.details) {
      setDetails(data.details);
    }
  };

  return (
    <>
      <AddAccountsContext.Provider
        value={{
          accountID,
          setAccountID,
          accountIDToConvert,
          setAccountIDToConvert,
          sourceType,
          setSourceType,
          accountType,
          setAccountType,
          accountSubType,
          setAccountSubType,
          accountName,
          setAccountName,
          balance,
          setBalance,
          limit,
          setLimit,
          plaidLinkToken,
          setPlaidLinkToken,
          purchasePercentage,
          setPurchasePercentage,
          exchangeResponse,
          setTokenExchangeResponse,
          onCompleteActions,
          setOnCompleteActions,
          submit,
          clear,
          convertToManualProps,
          submitCrypto,
          setConvertToManualProps,
          details,
          setDetails,
          saveToSessionStorage,
          resumeFromSessionStorage,
          showPlaid,
          showCoinbase,
          setShowPlaid,
          setShowCoinbase,
          addLoading: addAccountLoading || addCryptoLoading,
        }}
      >
        {props.children}
      </AddAccountsContext.Provider>
    </>
  );
};
