import * as React from "react";
import { Text } from "components/core/text";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

interface Props {
  onComplete: () => void;
  score: number | undefined;
}

export const ArrayComplete: React.FC<Props> = ({ score, onComplete }) => {
  return score ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 2rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Text>
            Your credit score is <b>{score}</b>.
          </Text>
          <Text>
            Pylon will automatically keep your score up to date and let you know
            if there are any changes.
          </Text>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          margin: "1rem 0",
        }}
      >
        <LeafButton
          buttonColour={LeafButtonColour.Black}
          buttonStyle={LeafButtonStyle.LeafSmall}
          onClick={() => onComplete()}
        >
          Confirm
        </LeafButton>
      </div>
    </div>
  ) : null;
};
