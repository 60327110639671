import React from "react";
import { AssetType } from "components/features/dashboard/models/sharing";
import { DashboardPages } from "components/features/dashboard/pages";
import { Link } from "react-router-dom";
import { Text, TextStyle, TextType } from "components/core/text";
import {
  UserNotification,
  UserNotificationType,
} from "core/queries/notifications";

export interface Props {
  notification: UserNotification;
}

export const NotificationAction: React.FC<Props> = ({ notification }) => {
  switch (notification.notificationType) {
    case UserNotificationType.AccessRequested: {
      return (
        <Link to={DashboardPages.Settings}>
          <Text type={TextType.Div} style={TextStyle.M15SM}>
            Settings
          </Text>
        </Link>
      );
    }

    case UserNotificationType.AccountBalanceUpdated:
    // fallthrough
    case UserNotificationType.AssetShared: {
      let link: string = "";
      switch (notification.assetType) {
        case AssetType.Account: {
          const searchParams = new URLSearchParams({
            accountID: notification.assetID || "",
          }).toString();

          link = `${DashboardPages.AccountDetails}?${searchParams}`;
          break;
        }
        case AssetType.Document: {
          const searchParams = new URLSearchParams({
            documentID: notification.assetID || "",
          }).toString();

          link = `${DashboardPages.Documents}?${searchParams}`;
          break;
        }
        case AssetType.DocumentFolder: {
          const searchParams = new URLSearchParams({
            folderID: notification.assetID || "",
            folderType: "Folder",
          });

          link = `${DashboardPages.DocumentFolder}?${searchParams}`;
          break;
        }
        case AssetType.ScenarioPlan: {
          link = `${DashboardPages.Calculators}`;
          break;
        }
        default:
      }
      return (
        <Link to={link}>
          <Text type={TextType.Div} style={TextStyle.M15SM}>
            View
          </Text>
        </Link>
      );
    }
  }
  return <div />;
};
