export const scrollToTop = () => {
  document.getElementsByClassName("main-container")[0].scrollTo(0, 0);
};
export const makeHeight100Percent = () => {
  document
    .getElementsByClassName("main-container")[0]
    .setAttribute("style", "height: 100%");
};
export const makeHeight100VH = () => {
  document
    .getElementsByClassName("main-container")[0]
    .setAttribute("style", "height: 100vh");
};
