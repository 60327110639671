import React from "react";
import { createDownloadLogMutation } from "core/queries/shared-data";
import { DocumentData } from "components/features/dashboard/models/document";
import { FETCH_DOCUMENT } from "core/queries/documents";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton, LeafButtonStyle } from "components/core/leaf-button";
import { SessionContext } from "components/contexts/session-context-provider";
import { Spinner } from "react-bootstrap";
import { Text, TextStyle, TextType } from "components/core/text";
import { useLazyQuery, useMutation } from "@apollo/client";

export interface Props {
  document: DocumentData;
  asQuickAction?: boolean;
  asDiv?: boolean;
  asIcon?: boolean;
  buttonStyle?: LeafButtonStyle;
  onDownloadComplete?: () => void;
}

interface GetDocumentResponse {
  document: DocumentData;
}

const download = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DownloadDocumentButton: React.FC<Props> = ({
  document,
  asQuickAction,
  asDiv,
  asIcon,
  buttonStyle,
  onDownloadComplete,
}) => {
  const { userID } = React.useContext(SessionContext.context);
  const [createDownloadLog] = useMutation(createDownloadLogMutation);

  const [getDocument, { data: downloadData, loading: downloadLoading }] =
    useLazyQuery<GetDocumentResponse>(FETCH_DOCUMENT, {
      fetchPolicy: "network-only",
      variables: {
        id: document.id,
      },
      onCompleted: (data) => {
        if (data && data.document && data.document.downloadURL) {
          download(data.document.downloadURL);
          if (data.document.owner?.id !== userID) {
            createDownloadLog({ variables: { assetID: document?.id } });
          }
          onDownloadComplete?.();
        }
      },
    });
  let downloadButtonText: string = "Download";
  if (downloadLoading) {
    downloadButtonText = "Starting...";
  } else if (downloadData) {
    downloadButtonText = "Downloaded";
  }

  if (asQuickAction) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          gap: "0.5rem",
        }}
        onClick={() => {
          getDocument();
        }}
      >
        {downloadLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Icon width={"16px"} height={"16px"} asset={IconAsset.Download} />
        )}

        <Text style={TextStyle.M15} type={TextType.Div}>
          {downloadButtonText}
        </Text>
      </div>
    );
  }
  if (asIcon) {
    return (
      <div
        className={"document-download-icon"}
        style={{
          cursor: "pointer",
          height: "3rem",
          width: "3rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid var(--gray-30)",
          borderRadius: "8px",
          margin: "0 0.5rem",
        }}
        onClick={() => {
          getDocument();
        }}
      >
        {downloadLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Icon width={"27"} height={"26"} asset={IconAsset.Download} />
        )}
      </div>
    );
  }

  if (asDiv) {
    return (
      <div
        onClick={() => {
          getDocument();
        }}
      >
        {downloadButtonText}
      </div>
    );
  }

  return (
    <LeafButton
      buttonStyle={buttonStyle}
      onClick={() => {
        getDocument();
      }}
    >
      {downloadButtonText}
    </LeafButton>
  );
};
