import * as React from "react";
import { HomeEmails } from "./components/home-emails";
import { PublicHeader } from "components/features/public/header";
import { PublicMobileNavigation } from "components/features/login/components/mobile-navigation";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";

export const Home: React.FC = () => {
  const mobileLayout = useMobileLayout();
  return (
    <div>
      {!mobileLayout && <PublicHeader transparentBackground hideIcon />}
      {mobileLayout && (
        <PublicMobileNavigation redirectToHome={true} hideMenu={true} />
      )}
      <div className="home">
        <HomeEmails />
      </div>
    </div>
  );
};
