import * as React from "react";

export function createContext<Context extends object>(fallbackState: Context) {
  const context = React.createContext<Context>(fallbackState);
  const { Consumer, Provider } = context;

  function withContext<ProvidedProps, P = {}>(
    mapContextToProps: (c: Context, ownProps: P) => ProvidedProps
  ) {
    return (WrappedComponent: React.ComponentType<any>) => {
      const HocContextConsumer: React.FC<P> = props => {
        const contextValue = React.useContext(context);
        const injectedProps = mapContextToProps(contextValue, props);
        return <WrappedComponent {...props} {...injectedProps} />;
      };

      return (HocContextConsumer as unknown) as React.ComponentClass<any>;
    };
  }

  return {
    context,
    withContext,
    InnerProvider: Provider,
    NativeConsumer: Consumer
  };
}
