import * as React from "react";
import qs from "qs";
import { ConfirmationModalOld } from "components/core/modal";
import { DashboardPages } from "components/features/dashboard/pages";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { LinkType, PylonLink } from "components/core/link";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SavvyWidget } from "components/features/dashboard/components/savvy-wrapper/savvy-widget";
import { Text } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import {
  saveSavvyPolicyMutation,
  SaveSavvyPolicyResponse,
} from "core/queries/insurance";

interface Props {
  onClose: () => void;
  accountIDToConvert?: string;
}

export const SavvyWrapper: React.FC<Props> = ({
  onClose,
  accountIDToConvert,
}) => {
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const [showNoPoliciesModal, setShowNoPoliciesModal] = React.useState(false);
  const [waitForResponse, setWaitForResponse] = React.useState(false);
  const [saveSavvyData] = useMutation(saveSavvyPolicyMutation, {
    refetchQueries: onUpdateRefetchQueries,
    onError: (err) => {
      console.error("failed to add insurance", err);
      toast(
        <PylonToastBody
          title={"Error Occurred"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
    onCompleted: (resp: SaveSavvyPolicyResponse) => {
      if (resp?.saveSavvyPolicy?.policies?.length === 0) {
        setShowNoPoliciesModal(true);
        return;
      }
      let toastBody;
      if (resp?.saveSavvyPolicy?.policies?.length > 1) {
        toastBody = (
          <PylonToastBody title={"Insurance policy added successfully"}>
            We just added your {resp?.saveSavvyPolicy?.policies?.length}{" "}
            insurance policies for tracking!
          </PylonToastBody>
        );
      } else if (resp?.saveSavvyPolicy?.policies?.length === 1) {
        const policy = resp?.saveSavvyPolicy?.policies?.[0];
        const search = qs.stringify({
          accountID: policy.account_id,
        });
        toastBody = (
          <PylonToastBody title={"Insurance policy added successfully"}>
            We just added your policy {policy.name} for tracking!{" "}
            <PylonLink
              to={`${DashboardPages.Integrations}?${search}`}
              linkType={LinkType.Highlight}
            >
              View Policy
            </PylonLink>
          </PylonToastBody>
        );
      }
      toast(toastBody, {
        closeOnClick: false,
        closeButton: true,
      });
      closeHandler();
    },
  });

  const closeHandler = () => {
    setShowNoPoliciesModal(false);
    setWaitForResponse(false);
    onClose();
  };

  return (
    <>
      <SavvyWidget
        onEvent={(eventName, metadata) => {
          // used to determine whether or not to unmount the component until we get a response
          if (
            eventName === "TRANSITION_VIEW" &&
            metadata.view_name === "Login Success"
          ) {
            setWaitForResponse(true);
          }
        }}
        onConnect={(connectionID) =>
          saveSavvyData({
            variables: {
              connectionID,
              accountIDToConvert,
            },
          })
        }
        onClose={() => {
          if (!showNoPoliciesModal && !waitForResponse) {
            closeHandler();
          }
        }}
      />
      <ConfirmationModalOld
        children={
          <>
            <Text size={"1.25rem"} weight={600}>
              No policies found
            </Text>
            <Text>
              Unfortunately, our partner, Trellis, couldn't find any policies
              with your insurance provider. Policies are updated often, please
              check back again soon.
            </Text>
          </>
        }
        width={"22rem"}
        height={"fit-content"}
        showModal={showNoPoliciesModal}
        overflow="auto"
        buttonGrouping={"flex-end"}
        onAction={() => {
          closeHandler();
        }}
        hideCancel={true}
        onCancel={() => null}
        actionButtonText="Done"
      />
    </>
  );
};
