import { gql } from "@apollo/client";
import {
  AssetType,
  CollaboratorPermission,
  UserData,
} from "components/features/dashboard/models/sharing";
import { ACCOUNT_FIELDS } from "core/queries/accounts";
import { ContactInfo } from "core/queries/contact-info";
import { DOCUMENT_FIELDS } from "core/queries/document-fragments";

export const FETCH_ALL_COLLABORATIONS = gql`
  ${ACCOUNT_FIELDS}
  ${DOCUMENT_FIELDS}
  query GetCollaborationsByMe {
    collaborations {
      id
      ownerID
      recipientID
      createdAt
      sharedData {
        assetID
        assetType
        document {
          ...DocumentFields
        }
        account {
          ...AccountFields
        }
      }
    }

    collaborationsWithMe {
      id
      createdAt
      sharedData {
        assetID
        assetType
        document {
          ...DocumentFields
        }
        account {
          ...AccountFields
        }
      }
    }
  }
`;

export interface Collaborators {
  incoming: Collaborator[];
  outgoing: Collaborator[];
}

export const collaboratorsQuery = gql`
  query GetCollaborators {
    collaborators {
      incoming {
        id
        firstName
        lastName
        email
      }
      outgoing {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const collaborationsQuery = gql`
  query GetCollaborations {
    collaborations {
      id
      ownerID
      recipientID
      createdAt
      recipient {
        id
        firstName
        lastName
        email
      }
      permission
      relationship
      autoShareNewAccount
    }
    recipients {
      id
      firstName
      lastName
      email
    }
  }
`;

export interface Collaboration {
  id: string;
  ownerID: string;
  recipientID: string;
  createdAt: string;
  permission: string;
  recipient: UserData;
  relationship?: string;
  contactInfo?: ContactInfo;
  autoShareNewAccount?: boolean;
}

export interface Recipient {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
}

export interface Collaborator {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
}

export interface CollaborationQueryResponse {
  collaborations: Collaboration[];
  recipients: Recipient[];
}

export interface recipientsForAssetQueryResponse {
  user: UserData;
  sharedDataID: string;
  isDeleted: boolean;
  collaborationID: string;
  permission: string;
}

export interface RecipientForAssetQueryResponse {
  recipientsForAsset: recipientsForAssetQueryResponse[];
}

export const recipientsForAssetQuery = gql`
  query RecipientsForAsset($assetID: ID!, $assetType: String!) {
    recipientsForAsset(assetID: $assetID, assetType: $assetType) {
      user {
        id
        firstName
        lastName
        email
      }
      sharedDataID
      isDeleted
      collaborationID
      permission
    }
  }
`;

export interface updateCollaborationPermissionResponse {
  permission: CollaboratorPermission;
}

export const updateCollaborationPermission = gql`
  mutation UpdateCollaborationPermission(
    $collaborationID: ID!
    $permission: String!
  ) {
    updateCollaborationPermission(
      collaborationID: $collaborationID
      permission: $permission
    ) {
      permission
    }
  }
`;

export interface updateCollaborationRelationshipResponse {
  id: string;
  relationship: string;
}

export const updateCollaborationRelationship = gql`
  mutation UpdateCollaborationRelationship(
    $collaborationID: ID!
    $relationship: String!
  ) {
    updateCollaborationRelationship(
      collaborationID: $collaborationID
      relationship: $relationship
    ) {
      id
      relationship
    }
  }
`;

export const requestEditAccessMutation = gql`
  mutation RequestEditAccessMutation($id: ID!, $type: String!) {
    requestEditAccess(id: $id, type: $type)
  }
`;

export enum CollaborationRelationships {
  Spouse = "Spouse",
  Partner = "Partner",
  Child = "Child",
  Sister = "Sister",
  Brother = "Brother",
  Mother = "Mother",
  Father = "Father",
  Grandfather = "Grandfather",
  Grandmother = "Grandmother",
  Uncle = "Uncle ",
  Aunt = "Aunt",
  Client = "Client",
  Accountant = "Accountant",
  Lawyer = "Lawyer",
  FinancialAdvisor = "Financial Advisor",
  MortgageBroker = "Mortgage Broker",
  TaxAdvisor = "Tax Advisor",
  MotherInLaw = "Mother-In-Law",
  FatherInLaw = "Father-In-Law",
  SisterInLaw = "Sister-In-Law",
  BrotherInLaw = "Brother-In-Law",
  Other = "Other",
}

const COLLABORATOR_FIELDS = gql`
  fragment CollaboratorFields on Collaboration {
    id
    ownerID
    createdAt
    recipient {
      id
      firstName
      lastName
      email
      isPhoneVerified
      isEmailVerified
    }
    permission
    relationship
    autoShareNewAccount
    contactInfo {
      executor
      benefitLevel
      firstName
      lastName
      age
      addressLineOne
      addressLineTwo
      city
      postalCode
      stateProvinceRegion
      country
    }
  }
`;

export interface UpdateCollaborationMutationInput {
  id: string;
  permission?: string;
  relationship?: string;
  autoShareNewAccount?: boolean;
  contactInfo?: Partial<ContactInfo>;
}

export interface AddCollaborationMutationResponse {
  createCollaboration: {
    collaboration: Collaboration;
    newUserCreated?: boolean;
  };
}

export interface AddCollaborationMutationInput {
  email?: string;
  phone?: string;
  relationship?: string;
  permission?: string;
  executor?: boolean;
  firstName?: string;
  lastName?: string;
  autoShareNewAccount?: boolean;
  sharedAccountIDs?: string[];
}

export const AddCollaborationMutation = gql`
  ${COLLABORATOR_FIELDS}
  mutation CreateCollaboration($input: CreateCollaborationInput!) {
    createCollaboration(input: $input) {
      collaboration {
        ...CollaboratorFields
      }
      newUserCreated
    }
  }
`;

export interface UpdateCollaborationMutationResponse {
  updateCollaboration: Collaboration;
}

export interface UpdateCollaborationMutationInput {
  id: string;
  relationship?: string;
  permission?: string;
  contactInfo?: Partial<ContactInfo>;
}

export const UpdateCollaborationMutation = gql`
  ${COLLABORATOR_FIELDS}
  mutation UpdateCollaboration($input: UpdateCollaborationInput!) {
    updateCollaboration(input: $input) {
      ...CollaboratorFields
    }
  }
`;

export interface FetchMyCollaboratorsResponse {
  collaborations: Collaboration[];
}

export const FETCH_MY_COLLABORATORS = gql`
  ${COLLABORATOR_FIELDS}
  query MyCollabotorsWithContactInfo {
    collaborations {
      ...CollaboratorFields
    }
  }
`;

export interface RemoveCollaboratorMutationInput {
  collaborationID: string;
}

export const REMOVE_COLLABORATOR_MUTATION = gql`
  mutation RemoveCollaboration($collaborationID: ID!) {
    removeCollaboration(collaborationID: $collaborationID)
  }
`;

export interface SharedData {
  id: string;
  assetID: string;
  assetType: AssetType;
}

export interface FetchAccountsByCollaborationResponse {
  collaboration: Collaboration & {
    sharedData?: SharedData[];
  };
}

export const FETCH_ACCOUNTS_BY_COLLABORATION = gql`
  query FetchAccountsByCollaborationID($collaborationID: ID!) {
    collaboration(collaborationID: $collaborationID) {
      id
      sharedData {
        id
        assetType
        assetID
      }
    }
  }
`;
