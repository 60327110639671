import * as React from "react";
import classnames from "classnames";
import Collapsible from "react-collapsible";
import { Icon, IconAsset } from "components/core/icons";
import { useLocalStorage } from "core/utils/browser-storage";

interface Props {
  headerLeft: string | React.ReactNode;
  headerRight: string | React.ReactNode;
  table: string | React.ReactNode;
  headerKey: string;
}

export const TaxSectionHeader: React.FC<Props> = ({
  headerLeft,
  headerRight,
  table,
  headerKey,
}) => {
  const [isOpen, setIsOpen] = useLocalStorage(
    `tax.section.header.${headerKey}`,
    true
  );

  const caratStyles = classnames({
    "accounts__section-carat": isOpen,
    "accounts__section-carat-closed": !isOpen,
  });
  return (
    <div className="table-group">
      <Collapsible
        open={isOpen}
        trigger={
          <div
            className="flex-row between"
            style={{ fontSize: "17px" }}
            onClick={() => {
              if (!headerRight) return;
              setIsOpen(!isOpen);
            }}
          >
            {" "}
            <>
              <div
                className="flex-row"
                style={{ fontWeight: 700, width: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className={caratStyles}>
                    <Icon
                      asset={IconAsset.CollapsibleOpen}
                      width="24px"
                      height="24px"
                    />
                  </div>
                </div>
                <div
                  className="accounts__section-category"
                  style={{ flexGrow: 1, padding: "1rem !important" }}
                >
                  {headerLeft}
                  <div
                    className="accounts__section-balance"
                    style={{ justifyContent: "flex-end", fontWeight: 700 }}
                  >
                    {headerRight}
                  </div>
                </div>
              </div>
            </>
          </div>
        }
        handleTriggerClick={table ? undefined : () => null}
      >
        {table}
      </Collapsible>
    </div>
  );
};
