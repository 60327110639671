import { Account } from "core/models";
import { DocumentData } from "components/features/dashboard/models/document";

export enum AssetType {
  Account = "ACCOUNT",
  Document = "DOCUMENT",
  DocumentFolder = "DOCUMENTFOLDER",
  ScenarioPlan = "SCENARIOPLAN",
  CreditHistory = "CREDITHISTORY",
}

export interface NotesData {
  header: string;
  content: string;
}

export interface AddCollaboratorState {
  name: string;
  email: string;
  notes: NotesData;
}

export interface Comment {
  id: string;
  from: string;
  body: string;
}

export interface Recipient {
  collaborationID: string;
  email: string;
  name: string;
  sharedData: SharedData;
  comments?: Array<Comment>;

  pylonUserID?: string;
}

export interface SharedDataProto {
  asset_id: string;
  asset_type: string;
  asset_version: string;
  collaboration_id: string;
  created_at: string;
  modified_at: string;
  modified_by: string;
  shared_data_id: string;

  // sharedDataID: string;
  // assetID: string;
  // assetType: string;
  // assetVersion: string;
  // collaborationId: string;
  // createdAt: string;
  // modifiedAt: string;
  // modifiedBy: string;

  account?: Account;
  document?: DocumentData;
}

export interface SharedData {
  id: string;
  assetID: string;
  assetType: string;
  collaborationID: string;
}

export const getAccType = (type: string) => {
  switch (type) {
    case "depository":
      return "primary";
    case "investment":
      return "investment";
    case "loan":
      return "debt";
    case "credit":
      return "credit";
    default:
      return "primary";
  }
};

export interface Collaboration {
  id: string;
  ownerID: string;
  recipientID: string;
  recipient: UserData;
  createdAt: string;
}

export interface UserData {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
}

export interface AuditLog {
  id: string;
  actionType: string;
  createdAt: string;
  updateField?: string;
  updateFrom?: string;
  updateTo?: string;
  actingUser?: UserData;
  affectedUser?: UserData;
}

export enum CollaboratorPermission {
  Viewer = "VIEWER",
  Editor = "EDITOR",
}

export const GetAssetTypeDisplayName = (type: AssetType) => {
  switch (type) {
    case AssetType.Account:
      return "Account";
    case AssetType.Document:
      return "Document";
    case AssetType.DocumentFolder:
      return "Document Folder";
    case AssetType.ScenarioPlan:
      return "Scenario Plan";
    case AssetType.CreditHistory:
      return "Credit History";
    default:
      return "";
  }
};
