import * as React from "react";
import { AccountContacts } from "core/models";
import { formatMobileNumber } from "core/utils";
import { ModalInput } from "components/core/input/modal-input";
import { TextD, TextStyle } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";
import { useMutation } from "@apollo/client";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  AccountContactResponse,
  getAccountByID,
  UpdateAccountContact,
} from "core/queries/accounts";

interface State {
  name: string;
  email: string;
  phone: string;
  website: string;
}

const defaultState = (c: AccountContacts) => {
  return {
    name: c.name || "",
    email: c.email || "",
    phone: c.phone || "",
    website: c.website || "",
  };
};
interface Props {
  accountID: string;
  contact: AccountContacts;
  onClose: () => void;
}

export const ManageAccountContactModal: React.FC<Props> = ({
  accountID,
  contact,
  onClose,
}) => {
  const mobileLayout = useMobileLayout();
  const [state, setState] = React.useState<State>(defaultState(contact));
  const [updateAccountContact, { loading }] =
    useMutation<AccountContactResponse>(UpdateAccountContact, {
      refetchQueries: [
        {
          query: getAccountByID,
          variables: {
            id: accountID,
          },
        },
      ],
      onCompleted: () => {
        onClose();
      },
    });

  const submit = () => {
    const { name, email, phone, website } = state;
    updateAccountContact({
      variables: {
        input: {
          id: contact.id,
          accountID,
          name,
          email,
          phone,
          website,
        },
      },
    });
  };

  const disabled = React.useMemo(
    () =>
      !(
        state.name !== "" ||
        state.website !== "" ||
        state.phone !== "" ||
        state.email !== ""
      ),
    [state]
  );

  const submitOnEnter = () => {
    if (disabled) {
      return;
    }
    submit();
  };

  return (
    <div
      className="flex-column gap-1"
      style={{
        padding: mobileLayout ? "2.5rem 1rem" : "2.5rem",
        width: mobileLayout ? "95vw" : "32rem",
        color: "black",
      }}
    >
      <TextD style={TextStyle.M31B}>Manage Contact for this Account</TextD>

      <div
        className="flex-column gap-1"
        style={{
          paddingTop: "0.5rem",
        }}
      >
        <ModalInput
          id="new-contact-name"
          label={"Name"}
          value={state.name}
          onChange={(v) =>
            setState({
              ...state,
              name: v ?? "",
            })
          }
          placeholder="Alice Smith"
          stringOverrides={{
            onEnter: submitOnEnter,
          }}
        />

        <ModalInput
          id="new-contact-email"
          label={"Email"}
          value={state.email}
          onChange={(v) =>
            setState({
              ...state,
              email: v ?? "",
            })
          }
          placeholder="alice@example.com"
          stringOverrides={{
            onEnter: submitOnEnter,
          }}
        />

        <ModalInput
          id="new-contact-phone"
          label={"Phone Number"}
          value={state.phone}
          onChange={(v) =>
            setState({
              ...state,
              phone: formatMobileNumber(v ?? ""),
            })
          }
          placeholder="123-456-7890"
          stringOverrides={{
            onEnter: submitOnEnter,
          }}
        />

        <ModalInput
          id="new-contact-website"
          label={"Website"}
          value={state.website}
          onChange={(v) =>
            setState({
              ...state,
              website: v ?? "",
            })
          }
          placeholder="www.example.com"
          stringOverrides={{
            onEnter: submitOnEnter,
          }}
        />
      </div>
      <div
        className="flex-row gap-1"
        style={{
          paddingTop: "0.5rem",
          justifyContent: "flex-end",
        }}
      >
        <LeafButton
          onClick={(e) => {
            onClose();
          }}
          buttonStyle={LeafButtonStyle.LeafSmall}
          buttonColour={LeafButtonColour.Transparent}
        >
          Cancel
        </LeafButton>

        <LeafButton
          disabled={disabled}
          loading={loading}
          onClick={(e) => {
            submit();
          }}
          buttonStyle={LeafButtonStyle.LeafSmall}
          buttonColour={LeafButtonColour.Black}
        >
          Ok
        </LeafButton>
      </div>
    </div>
  );
};
