import * as React from "react";
import Chart from "react-apexcharts";
import { Account } from "core/models";
import { ApexOptions } from "apexcharts";
import { toMoneyStringNoDecimal } from "../../../../../core/utils";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  FETCH_ACCOUNT_BALANCE_HISTORY,
  GetBalanceHistoryResponse,
} from "../../../../../core/queries/accounts";

interface Props {
  account: Account;
  chartType?:
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar";
}

export const ChartAccountBalanceHistory: React.FC<Props> = ({
  account,
  chartType,
}) => {
  const { data } = useQuery<GetBalanceHistoryResponse>(
    FETCH_ACCOUNT_BALANCE_HISTORY,
    {
      fetchPolicy: "cache-first",
      variables: {
        accountID: account?.account_id,
      },
      skip: !account,
    }
  );

  const ranges = React.useMemo(() => {
    return account?.typeProperties?.isAsset
      ? [
          {
            from: -1000000000,
            to: -1,
            color: "var(--red-01)",
          },
          {
            from: 0,
            to: 10000000000,
            color: "var(--green-01)",
          },
        ]
      : [
          {
            from: -1000000000,
            to: -1,
            color: "var(--green-01)",
          },
          {
            from: 0,
            to: 10000000000,
            color: "var(--red-01)",
          },
        ];
  }, [account]);

  const dataset = React.useMemo<(number | Date)[][]>(() => {
    if (!data || !data.balanceHistory) return [];
    return data.balanceHistory.history
      .map((h) => {
        return [new Date(h.date), h.balance];
      })
      .sort((a: any[], b: any[]) => {
        return a[0].getTime() - b[0].getTime();
      });
  }, [data]);

  const state = React.useMemo<{
    series: Array<any>;
    options: ApexOptions;
  }>(() => {
    return {
      series: [
        {
          name: "Balance",
          data: dataset,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
            colors: {
              ranges: ranges,
            },
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (val: number) {
            return toMoneyStringNoDecimal(val);
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          tickAmount: 4,
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "",
              minute: "hh:mm tt",
            },
          },
          type: "datetime" as "datetime",
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val: number) {
              return toMoneyStringNoDecimal(val);
            },
          },
        },
      },
    };
  }, [dataset, ranges]);

  return (
    <>
      <div
        className="chart balance-history"
        style={{ display: "flex", marginLeft: "-1rem" }}
      >
        <Chart
          options={state.options}
          series={state.series}
          type={chartType || "bar"}
          height="200"
        />
      </div>
    </>
  );
};
