import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { PlanOptions } from "components/features/dashboard/components/planning/models";
import { ScenarioCard } from "components/features/dashboard/components/planning/saved-scenario/scenario-card";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  FetchScenarioPlanResponse,
  FETCH_SCENARIO_PLANS,
  ScenarioPlan,
} from "core/queries/scenario-plans";

export interface Props {
  selectedOption: PlanOptions;

  onLoad: (plan: ScenarioPlan | undefined) => void;
}

export const SavedScenario: React.FC<Props> = ({ selectedOption, onLoad }) => {
  const { data, refetch } = useQuery<FetchScenarioPlanResponse>(
    FETCH_SCENARIO_PLANS,
    {
      variables: {
        type: selectedOption,
      },

      fetchPolicy: "cache-and-network",
    }
  );
  const plans = data?.scenarioPlansByType?.plans ?? [];

  return (
    <div className="saved-scenario-wrapper">
      <div>
        <Text style={TextStyle.B16} weight={700} type={TextType.Div}>
          Saved Scenarios
        </Text>
      </div>

      {plans.map((p, i) => {
        return (
          <ScenarioCard
            key={p.id}
            id={p.id}
            plan={p}
            onLoad={() => onLoad?.(p)}
            onDelete={() => refetch()}
          />
        );
      })}

      <div
        style={{
          border: "1px solid #BFCCE4",
          width: "16rem",
        }}
      />
      <div
        id="plan-new-scenario"
        style={{
          paddingTop: "2rem",
          width: "16.25rem",
          marginLeft: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => onLoad(undefined)}
      >
        <Text
          style={TextStyle.L12}
          weight={700}
          type={TextType.Div}
          format={TextFormat.UpperCase}
          colour={Colour.Blue01}
        >
          Create New Scenario
        </Text>

        <div
          style={{
            width: "20px",
            height: "20px",
            background: "var(--bg-03)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon asset={IconAsset.BoxedPlus} width="20px" height="20px" />
        </div>
      </div>
    </div>
  );
};
