import _ from "lodash";
import addMonths from "date-fns/addMonths";
import Chart from "react-apexcharts";
import React, { useMemo, useState } from "react";
import { Account, Colour, MortgageDetails } from "core/models";
import { ApexOptions } from "apexcharts";
import { BufferedInput } from "core/components/buffered-input";
import { differenceInMonths } from "date-fns/esm";
import { EditAccountInformationModal } from "components/features/dashboard/components/manage-account-modal/details/edit-details-modal";
import { format } from "date-fns";
import { shortNumber } from "core/utils";
import { Text, TextStyle, TextType } from "components/core/text";

const DEFAULT_HOUSE_PRICE_GROWTH_RATE = "3";

export interface Props {
  account: Account;
  isConnected: boolean;
}

interface ChartSeries {
  name: string;
  data: number[];
  colour: Colour;
  width: number;
  dash: number;
}

interface ChartState {
  options: Partial<ApexOptions>;
  series: Array<ChartSeries>;
}

type ChartErrors = keyof MortgageDetails;

export const RealEstateChart: React.FC<Props> = ({ account, isConnected }) => {
  const details = account.details?.mortgageDetails;
  const estimatedValue = details?.estimatedValue;
  const originalLoanAmount = details?.originalBalance?.value;
  const interest = details?.interestRate?.value;
  const purchaseDate = details?.purchaseDate;
  const term = details?.term?.value;
  const isEditable = !!account.isOwnerOrEditor;

  const errors: ChartErrors[] = [];

  const [growthRate, setGrowthRate] = useState(DEFAULT_HOUSE_PRICE_GROWTH_RATE);

  const mortgages = generateMortgageSeries(originalLoanAmount, interest, term);
  if (mortgages.errors.length > 0) {
    errors.push(...mortgages.errors);
  }

  const projectionPeriod = mortgages?.interestSeries?.length ?? 0;

  if (!purchaseDate) {
    errors.push("purchaseDate");
  }

  const startingDate = useMemo(() => {
    const today = new Date();
    if (purchaseDate) {
      const pd = new Date(purchaseDate);
      if (pd < today) {
        return pd;
      }
      return today;
    }
    return today;
  }, [purchaseDate]);

  const estimateSeries = generateEstimatedSeries(
    projectionPeriod,
    growthRate,
    startingDate,
    estimatedValue
  );

  if (estimateSeries.error) {
    errors.push("estimatedValue");
  }

  const chartState = React.useMemo<ChartState>(() => {
    const mortgageData: ChartSeries | null = mortgages.mortgageSeries
      ? {
          name: "Balance",
          colour: Colour.Blue02,
          width: 3,
          dash: 0,
          data: mortgages.mortgageSeries,
        }
      : null;
    const interestData: ChartSeries | null = mortgages.interestSeries
      ? {
          name: "Interest",
          colour: Colour.Red02,
          width: 3,
          dash: 0,
          data: mortgages.interestSeries,
        }
      : null;
    const estimateData: ChartSeries | null = estimateSeries.data
      ? {
          name: "Net Proceeds from Sale",
          colour: Colour.Green01,
          width: 3,
          dash: 4,
          data: estimateSeries.data.map((e, i) => {
            let interest = 0;
            if (mortgages.interestSeries) {
              if (!_.isNil(mortgages.interestSeries?.[i])) {
                interest = mortgages.interestSeries[i];
              } else {
                interest =
                  mortgages.interestSeries[mortgages.interestSeries.length - 1];
              }
            }

            let mort = 0;
            if (mortgages.mortgageSeries) {
              if (!_.isNil(mortgages.mortgageSeries?.[i])) {
                mort = mortgages.mortgageSeries[i];
              } else {
                mort =
                  mortgages.mortgageSeries[mortgages.mortgageSeries.length - 1];
              }
            }

            return e - interest - mort;
          }),
        }
      : null;

    const chartData = [mortgageData, interestData, estimateData];

    const dateLabel: string[] = [];
    for (let i = 0; i < projectionPeriod; i += 1) {
      dateLabel.push(format(addMonths(startingDate, i), "MMM yyyy"));
    }

    return {
      series: getDataSeries(chartData),
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: getDataColour(chartData),
        stroke: {
          width: getDataWidth(chartData),
          curve: "straight",
          dashArray: getDataDash(chartData),
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        legend: {
          markers: {
            width: 6,
            height: 6,
          },
          position: "bottom",
          horizontalAlign: "center",
          fontFamily: '"Poppins", sans-serif',
          fontSize: "14px",
          fontWeight: 500,
          formatter: (x) => {
            return x.toUpperCase();
          },
        },
        markers: {
          size: 0,
          colors: ["var(--blue-01)"],
          strokeColors: "white",
        },
        xaxis: {
          categories: dateLabel,
          tickAmount: 10,
          labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
              fontFamily: '"Inter", sans-serif',
              fontSize: "10px",
              fontWeight: 700,
              colors: "var(--text-03)",
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          enable: true,
          show: true,
          labels: {
            style: {
              fontFamily: '"Inter", sans-serif',
              fontSize: "10px",
              fontWeight: 700,
              colors: "var(--text-02)",
            },
            show: true,
            formatter: function (val) {
              if (_.isNil(val) || !_.isFinite(val)) {
                return "";
              }
              return shortNumber(val);
            },
          },
        },
        grid: {
          borderColor: "var(--separator-02)",
        },
        style: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "10px",
          fontWeight: 700,
          colors: "var(--text-02)",
        },

        annotations: {
          xaxis: [
            {
              x: format(new Date(), "MMM yyyy"),
              borderColor: "var(--separator-01)",

              fillColor: "transparent",
              borderWidth: 2,
              opacity: 1,
              strokeDashArray: 0,

              label: {
                orientation: "horizontal",

                borderColor: "none",
                borderWidth: 1,
                style: {
                  color: "var(--text-01)",
                },
                background: "transparent",
                cssClass: "figment",
                fontSize: "16px",
                text: "Today",
              },
            },
          ],
        },
      },
    };
  }, [
    estimateSeries.data,
    mortgages.interestSeries,
    mortgages.mortgageSeries,
    projectionPeriod,
    startingDate,
  ]);

  const positionErrorTop = chartState.series.length === 0; // Position the errors on the top if there's no chart

  return (
    <div>
      <div className="section-label overline">
        <b>Total Interest, Balance, and Net Proceeds from Sale</b>
      </div>

      {errors.length > 0 && positionErrorTop && (
        <EditDataPrompt
          fields={errors}
          account={account}
          isConnected={isConnected}
          isEditable={isEditable}
        />
      )}

      <Chart
        options={chartState.options}
        series={chartState.series}
        width="100%"
      />

      <div
        className="flex-row align-center"
        style={{
          justifyContent: "flex-end",
          gap: "1rem",
        }}
      >
        <div
          className="flex-row align-center"
          style={{
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <Text type={TextType.Div} style={TextStyle.FieldLabel}>
            Home Appreciation Rate:
          </Text>
          <div
            style={{
              width: "6rem",
            }}
          >
            <BufferedInput
              id="rate-of-growth"
              type="percentage"
              overrides={{
                allowNegativeValue: false,
              }}
              value={growthRate}
              onChange={(v) => {
                if (v) {
                  setGrowthRate(v);
                }
              }}
            />
          </div>
        </div>
      </div>

      {errors.length > 0 && !positionErrorTop && (
        <EditDataPrompt
          fields={errors}
          account={account}
          isConnected={isConnected}
          isEditable={isEditable}
        />
      )}
    </div>
  );
};

const getDataSeries = (series: (ChartSeries | null)[]): ChartSeries[] => {
  return series.filter((s) => s !== null) as ChartSeries[];
};

const getDataColour = (series: (ChartSeries | null)[]): Colour[] => {
  return series
    .filter((s) => s !== null)
    .map((s) => {
      return s?.colour ?? Colour.Black;
    });
};

const getDataWidth = (series: (ChartSeries | null)[]): number[] => {
  return series
    .filter((s) => s !== null)
    .map((s) => {
      return s?.width ?? 0;
    });
};

const getDataDash = (series: (ChartSeries | null)[]): number[] => {
  return series
    .filter((s) => s !== null)
    .map((s) => {
      return s?.dash ?? 0;
    });
};

const generateMortgageSeries = (
  originalLoanAmount?: string,
  interest?: string,
  term?: string
): {
  errors: ChartErrors[];
  mortgageSeries?: number[];
  interestSeries?: number[];
} => {
  const resp: {
    errors: ChartErrors[];
    mortgageSeries?: number[];
    interestSeries?: number[];
  } = {
    errors: [],
  };

  if (!originalLoanAmount) {
    resp.errors.push("originalBalance");
  }
  if (!interest) {
    resp.errors.push("interestRate");
  }
  if (!term) {
    resp.errors.push("term");
  }
  if (resp.errors.length > 0) {
    return resp;
  }

  const parsedOriginalLoanAmount = Number.parseFloat(originalLoanAmount!);
  const parsedRate = Number.parseFloat(interest!) / 100 / 12;
  const parsedTerm = Number.parseInt(term!);

  const c = Math.pow(1 + parsedRate, 12 * parsedTerm);
  const monthlyPayment = (parsedOriginalLoanAmount * parsedRate * c) / (c - 1);

  if (
    !monthlyPayment ||
    monthlyPayment <= parsedOriginalLoanAmount * parsedRate
  ) {
    resp.errors.push("originalBalance");
    resp.errors.push("interestRate");

    return resp;
  }
  let totalInterest = 0;

  const mortgageSeries = [parsedOriginalLoanAmount];
  const interestSeries = [totalInterest];
  for (
    let remainingBalance = parsedOriginalLoanAmount;
    remainingBalance > 0;

  ) {
    const nextInterest = Math.round(remainingBalance * parsedRate * 100) / 100;
    const nextPayment = monthlyPayment - nextInterest;
    remainingBalance = Math.max(remainingBalance - nextPayment, 0);

    totalInterest += nextInterest;
    mortgageSeries.push(remainingBalance);
    interestSeries.push(totalInterest);
  }

  resp.mortgageSeries = mortgageSeries;
  resp.interestSeries = interestSeries;

  return resp;
};

const generateEstimatedSeries = (
  projectionPeriod: number,
  growthRate: string,
  startingDate: Date,
  estimatedValue?: string
): {
  data?: number[];
  error?: ChartErrors;
} => {
  const resp: {
    data?: number[];
    error?: ChartErrors;
  } = {};
  if (!projectionPeriod) {
    return resp;
  }
  if (!estimatedValue) {
    return {
      ...resp,
      error: "estimatedValue",
    };
  }
  const parsedEstimate = parseFloat(estimatedValue);

  if (!parsedEstimate) {
    return {
      ...resp,
      error: "estimatedValue",
    };
  }

  resp.data = [];
  const rate = 1 + Number.parseFloat(growthRate) / 100 / 12;

  const e =
    parsedEstimate /
    Math.pow(rate, differenceInMonths(new Date(), startingDate));

  for (let i = 0; i < projectionPeriod; i++) {
    resp.data.push(e * Math.pow(rate, i));
  }
  return resp;
};

const EditDataPrompt: React.FC<{
  fields: ChartErrors[];
  account: Account;
  isConnected: boolean;
  isEditable: boolean;
}> = ({ fields, account, isConnected, isEditable }) => {
  const [showModal, setShowModal] = useState(false);

  if (!isEditable) {
    return (
      <Text colour={Colour.Red01} type={TextType.Div}>
        This account is missing some information. Reach out to the account owner
        to provide the missing details.
      </Text>
    );
  }

  return (
    <div
      style={{
        borderRadius: "6px",
        background: Colour.Bg01,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "4rem",
        marginBottom: "1rem",
      }}
    >
      <EditAccountInformationModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        account={account}
        isConnected={isConnected}
        errorFields={fields}
      />
      <Text colour={Colour.Red01} type={TextType.Div}>
        This chart needs your help.
      </Text>
      &nbsp;
      <div
        onClick={() => {
          setShowModal(true);
        }}
        style={{
          cursor: "pointer",
          color: Colour.Blue01,
        }}
      >
        Fill in the missing fields.
      </div>
    </div>
  );
};
