import React, { useState } from "react";
import { Account, AccountContacts, Colour } from "core/models";
import { AddAccountContactTray } from "components/features/dashboard/components/add-account-contacts-tray";
import { CollapsibleSection } from "components/core/collapsible-section";
import { ConfirmationModal, ConfirmationModalOld } from "components/core/modal";
import { DeleteAccountContact, getAccountByID } from "core/queries/accounts";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { ManageAccountContactModal } from "components/features/dashboard/components/manage-account-contact-tray";
import { Text, TextFormat, TextType } from "components/core/text";
import { useHover } from "core/hooks/hover";
import { useMutation } from "@apollo/client";

interface Props {
  account: Account;
}

export const AccountContactsComponent: React.FC<Props> = ({ account }) => {
  const [showAdd, setShowAdd] = useState(false);
  return (
    <CollapsibleSection title="Contacts" stateKey={ACCOUNT_CONTACT_PREF_KEY}>
      <ConfirmationModal
        children={
          <AddAccountContactTray
            accountID={account.account_id}
            onClose={() => setShowAdd(false)}
          />
        }
        showModal={showAdd}
        onClose={() => setShowAdd(false)}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <div className="flex-column justify-center mt1">
        {account.contacts && account.contacts.length > 0 && (
          <div style={{ marginBottom: "1rem" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "1.5rem",
              }}
            >
              <div style={{ flex: "1 1 0px" }}>
                <Text
                  type={TextType.Div}
                  weight={600}
                  format={TextFormat.UpperCase}
                  colour={Colour.Text03}
                  size={"0.625rem"}
                >
                  Name
                </Text>
              </div>
              <div style={{ flex: "1 1 0px" }}>
                <Text
                  type={TextType.Div}
                  weight={600}
                  format={TextFormat.UpperCase}
                  colour={Colour.Text03}
                  size={"0.625rem"}
                >
                  Email
                </Text>
              </div>
              <div style={{ flex: "1 1 0px" }}>
                <Text
                  type={TextType.Div}
                  weight={600}
                  format={TextFormat.UpperCase}
                  colour={Colour.Text03}
                  size={"0.625rem"}
                >
                  Phone
                </Text>
              </div>
              <div style={{ flex: "1 1 0px" }}>
                <Text
                  type={TextType.Div}
                  weight={600}
                  format={TextFormat.UpperCase}
                  colour={Colour.Text03}
                  size={"0.625rem"}
                >
                  Website
                </Text>
              </div>
            </div>
            {account?.contacts?.map((c) => (
              <ContactRow
                key={c.id}
                accountID={account.account_id}
                contact={c}
              />
            ))}
          </div>
        )}
        <div className="flex-row">
          <LeafButton id="add-contact" onClick={() => setShowAdd(true)}>
            Add Contact
          </LeafButton>
        </div>
      </div>
    </CollapsibleSection>
  );
};

interface RowProps {
  accountID: string;
  contact: AccountContacts;
}

export const ContactRow: React.FC<RowProps> = ({ accountID, contact }) => {
  const [hoverRef, isHovering] = useHover<HTMLDivElement>();
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [deleteAccountContact] = useMutation<string>(DeleteAccountContact, {
    refetchQueries: [
      {
        query: getAccountByID,
        variables: {
          id: accountID,
        },
      },
    ],
  });

  return (
    <>
      <ConfirmationModal
        children={
          <ManageAccountContactModal
            accountID={accountID}
            contact={contact}
            onClose={() => {
              setShowEdit(false);
            }}
          />
        }
        showModal={showEdit}
        onClose={() => {
          setShowEdit(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />

      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        children={
          <>
            <Text weight={600}>
              Are you sure you want to delete this contact?
            </Text>
          </>
        }
        onAction={() => {
          deleteAccountContact({
            variables: {
              contactID: contact.id,
              accountID: accountID,
            },
          });
          setShowModal(false);
        }}
        actionButtonText={"Delete"}
        onCancel={() => {
          setShowModal(false);
        }}
        cancelButtonText="Cancel"
      />

      <div
        ref={hoverRef}
        style={{
          position: "relative",
          display: "flex",
          cursor: "pointer",
          padding: "0.5rem",
          backgroundColor: isHovering ? "var(--bg-01)" : "",
          borderRadius: "6px",
          marginLeft: "-0.5rem",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowEdit(true);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "1.25rem",
            width: "100%",
          }}
        >
          <div
            style={{
              flex: "1 1 0px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {contact.name}
          </div>
          <div
            style={{
              flex: "1 1 0px",

              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {contact.email}
          </div>
          <div
            style={{
              flex: "1 1 0px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {contact.phone}
          </div>
          <div
            style={{
              flex: "1 1 0px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {contact.website}
          </div>
        </div>

        <div
          style={{
            display: isHovering ? undefined : "none",
            cursor: "pointer",
            position: "absolute",
            right: 0,
            marginRight: "0.5rem",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(true);
          }}
        >
          <Icon
            height="24px"
            width="24px"
            asset={IconAsset.RemoveAsset}
            colour={Colour.Navy}
          />
        </div>
      </div>
    </>
  );
};

const ACCOUNT_CONTACT_PREF_KEY = "account_contacts--collapsible";
