import * as React from "react";
import Select from "react-select";
import { FilingStatus, getLocalities, getStateRules } from "@pylon/taxes";
import { pluralize } from "core/utils";
import { SELECT__MODAL_INPUT_STYLE } from "components/core/input/styles";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { TaxPageSection } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section";
import { Text, TextType } from "components/core/text";
import {
  filingStatusValues,
  martialStatuses,
  stateValues,
  TaxContext,
} from "components/contexts/tax-context";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
import {
  ManageCollaboratorHeader,
  ManageCollaboratorTray,
} from "components/features/dashboard/components/manage-collaborator-tray";

const exemptionOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

export const TaxSectionOverview: React.FC = () => {
  const { pushTray, popTray } = React.useContext(SideTrayContext);
  const {
    filingStatus,
    setFilingStatus,
    state,
    setState,
    totalExemptions,
    setTotalExemptions,
    locality,
    setLocality,
    noSpouse,
    stateRules,
    localityValues,

    saveLiabilityFieldValues,
    saveLiabilityFieldValue,
  } = React.useContext(TaxContext);

  const showAddNewCollaborator = () =>
    pushTray({
      id: "manage-collaborator",
      header: <ManageCollaboratorHeader />,
      children: <ManageCollaboratorTray onClose={() => popTray()} />,
    });

  return (
    <TaxPageSection
      localStorageKey="taxes.overview"
      headerLeft="Tax Overview"
      headerRight={
        <div className="table-cell" style={{ maxWidth: "50%" }}>
          {
            filingStatusValues.find((v) => {
              return v.value === filingStatus;
            })?.label
          }
          , {totalExemptions}{" "}
          {pluralize("Exemption", parseInt(totalExemptions) || 0)}, {state}
        </div>
      }
      bodyLeft="Enter the information you'll use when filing this year's tax
      returns."
      bodyRight={
        <>
          <div className="flex-row between mobile-flex-column">
            <div>
              <div
                className="label"
                style={{
                  display: "flex",
                }}
              >
                Filing Status&#160;
                <PylonTooltip
                  spacingSide={SpacingSide.Right}
                  text={
                    <Text type={TextType.Div}>
                      <p>
                        Your filing status determines which deductions, credits,
                        tax rates, and other tax rules are applied to your
                        income.
                      </p>
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
                {noSpouse && martialStatuses.includes(filingStatus) && (
                  <div
                    style={{
                      paddingLeft: "1rem",
                      cursor: "pointer",
                      color: "var(--blue-01)",
                    }}
                    onClick={showAddNewCollaborator}
                  >
                    Invite Spouse
                  </div>
                )}
              </div>
              <Select
                styles={SELECT__MODAL_INPUT_STYLE}
                onChange={(e) => {
                  if (!e) return;
                  const newStatus =
                    FilingStatus[e.value as keyof typeof FilingStatus];
                  const newTotalExemptions =
                    newStatus === FilingStatus.MarriedFilingJointly ? 2 : 1;

                  setTotalExemptions("" + newTotalExemptions);
                  setFilingStatus(newStatus);
                  saveLiabilityFieldValue("filingStatus", newStatus);
                }}
                menuPortalTarget={document.body}
                value={filingStatusValues.find((v) => {
                  return v.value === filingStatus;
                })}
                isSearchable={false}
                options={filingStatusValues}
              />
            </div>

            <div style={{ width: "48%" }}>
              <div className="label">
                Total Exemptions&#160;
                <PylonTooltip
                  spacingSide={SpacingSide.Right}
                  text={
                    <Text type={TextType.Div}>
                      <p>
                        Tax exemptions are used to reduce your taxable income.
                        Typically, you claim 1 tax exemption for every member of
                        your household. These no longer apply for your federal
                        taxes, but many states still use them.
                      </p>
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <Select
                onChange={(e) => {
                  if (!e) return;
                  saveLiabilityFieldValue("totalExemptions", e.value);
                }}
                menuPortalTarget={document.body}
                value={exemptionOptions.find((ex) => {
                  return ex.value === totalExemptions;
                })}
                isSearchable={false}
                options={exemptionOptions}
                styles={SELECT__MODAL_INPUT_STYLE}
              />
            </div>
          </div>

          <div className="flex-row between mobile-flex-column">
            <div>
              <div className="label">
                State&#160;
                <PylonTooltip
                  spacingSide={SpacingSide.Right}
                  text={
                    <Text type={TextType.Div}>
                      <p>
                        The primary state in which you will be filing your
                        taxes.
                      </p>
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <Select
                onChange={(e) => {
                  if (!e) return;

                  setState(e.value);

                  let newLocality = null;
                  if (getStateRules(e.value).localityTaxes) {
                    newLocality = getLocalities(e.value)[0];
                  } else {
                    newLocality = "";
                  }
                  setLocality(newLocality);
                  saveLiabilityFieldValues({
                    state: e.value,
                    county: newLocality,
                  });
                }}
                menuPortalTarget={document.body}
                isSearchable={false}
                value={stateValues.find((v) => {
                  return v.value === state;
                })}
                options={stateValues}
                styles={SELECT__MODAL_INPUT_STYLE}
              />
            </div>

            {stateRules.localityTaxes && (
              <div>
                <div className="label">
                  Locality&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>
                          The local entity in your state to which you owe taxes.
                        </p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <Select
                  onChange={(e) => {
                    if (!e) return;
                    setLocality(e.value);
                    saveLiabilityFieldValue("county", e.value);
                  }}
                  menuPortalTarget={document.body}
                  value={localityValues.find((v) => {
                    return v.value === locality;
                  })}
                  isSearchable={false}
                  options={localityValues}
                  styles={SELECT__MODAL_INPUT_STYLE}
                />
              </div>
            )}
          </div>
        </>
      }
    />
  );
};
