import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropDownMenu } from "components/core/drop-down-menu";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import { useClickOutDetector } from "core/hooks/click-out";
import "./styles.css";

export enum AccountOwnerTypeFiltering {
  All = "All Accounts",
  My = "My Accounts",
  SpousePartner = "Spouse/Partner",
  MineAndSpousePartner = "Mine and Spouse/Partner",
}

interface Props {
  accountOwnerType: AccountOwnerTypeFiltering;
  setAccountOwnerType: (t: AccountOwnerTypeFiltering) => void;
}
export const AccountOwnerFilter: React.FC<Props> = ({
  accountOwnerType,
  setAccountOwnerType,
}) => {
  return (
    <>
      <Dropdown>
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          <PylonDropDownToggle text={accountOwnerType} />
        </DropdownToggle>
        <DropdownMenu>
          <AccountOwnerTypeFilterMenu
            onClose={() => null}
            onSelect={(f) => {
              setAccountOwnerType(f);
            }}
          />
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

interface MenuProps {
  onSelect: (f: AccountOwnerTypeFiltering) => void;
  onClose: () => void;
}

export const AccountOwnerTypeFilterMenu: React.FC<MenuProps> = ({
  onSelect,
  onClose,
}) => {
  const clickOutRef = useClickOutDetector<HTMLDivElement>(onClose);
  const accountTypeMenuItems = Object.values(AccountOwnerTypeFiltering).map(
    (f) => (
      <DropDownMenuItem
        key={f + "1234"}
        text={f}
        onClick={() => {
          clickOutRef?.current?.click();
          onSelect(f);
          document.dispatchEvent(new MouseEvent("click"));
        }}
      />
    )
  );

  return (
    <div
      ref={clickOutRef}
      style={{
        backgroundColor: "white",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
        borderRadius: "2px",
      }}
    >
      <DropDownMenu>{accountTypeMenuItems}</DropDownMenu>
    </div>
  );
};
