import qs from "qs";
import React, { useContext, useState } from "react";
import { ConfirmationModalOld } from "components/core/modal";
import { DashboardPages } from "components/features/dashboard/pages";
import { loggedInUserVerifyEmail } from "core/api";
import { LoginPages } from "components/features/login/pages";
import { logout } from "App";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SessionContext } from "components/contexts/session-context-provider";
import { Text } from "components/core/text";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useIdleTimer } from "react-idle-timer";
import { useMutation } from "@apollo/client";
import { useTimer } from "react-timer-hook";
import {
  RefreshSessionResponse,
  REFRESH_SESSION_MUTATION,
} from "components/features/login/components/authorized-page/models";

export const AuthorizedPage: React.FC = ({ children }) => {
  const [refreshSession] = useMutation<RefreshSessionResponse>(
    REFRESH_SESSION_MUTATION
  );
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const { minutes, seconds, pause, restart } = useTimer({
    expiryTimestamp: new Date().getTime() + 1000 * 60 * 60 * 24 * 365, // Default value is never used
    onExpire: () => {
      setShowTimeoutModal(false);
      logout();
    },
  });

  const history = useHistory();

  const refresh = () => {
    refreshSession()
      .then((data) => {
        if (data.data?.refreshSession?.authToken) {
          setSessionToken(data.data.refreshSession.authToken);
        } else {
          console.error("error refreshing token");
        }
      })
      .catch((err) =>
        console.error(
          "Unable to refresh user credentials, will try again on next oppurtunity",
          err
        )
      );
  };

  const handleOnIdle = (e: Event) => {
    if (!showTimeoutModal) {
      const time = new Date();

      restart(time.getTime() + 1000 * 60 * 2);
      setShowTimeoutModal(true);
    }
  };

  const handleOnAction = (e: Event) => {
    if (showTimeoutModal || getRemainingTime() <= 0) {
      // User is currently inactive, do not refresh
      return;
    }

    refresh();
  };

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * 13, // 13 min, additional 2 minutes will be given when idle timer is hit
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    eventsThrottle: 1000 * 60 * 1, // refresh token every 1 min, determines frequency of onAction event
  });
  const { sessionToken, setSessionToken, unsetSession } = useContext(
    SessionContext.context
  );

  // User is already logged in, show them the page
  if (sessionToken) {
    const query = qs.parse(document.location.search, {
      ignoreQueryPrefix: true,
    });
    if (Object.keys(query).includes("ev")) {
      const emailVerificationCode = query["ev"] as string;
      if (emailVerificationCode && emailVerificationCode !== "") {
        loggedInUserVerifyEmail(
          emailVerificationCode,
          sessionToken,
          () => {
            history.push(`${DashboardPages.Summary}?emailverified=true`);
          },
          (err) => {
            toast(
              <PylonToastBody
                title={"Email Verification failed"}
                body={`An error occurred. Please try again later`}
                type={ToastType.Error}
              />
            );
            history.push(LoginPages.Login);
          }
        );
      }
    }

    return (
      <>
        <ConfirmationModalOld
          width={"22rem"}
          showModal={showTimeoutModal}
          children={
            <Text weight={600}>
              Your session is about to expire in {minutes * 60 + seconds}{" "}
              seconds. Would you like to remain signed in?
            </Text>
          }
          onAction={() => {
            pause();
            setShowTimeoutModal(false);
            refresh();
            reset();
          }}
          actionButtonText="Stay signed in"
          onCancel={() => {
            pause();
            setShowTimeoutModal(false);
            unsetSession();
            reset();
          }}
          cancelButtonText="Sign out"
        />
        {children}
      </>
    );
  }

  // otherwise redirect user to login
  history.push({
    pathname: "/login",
    hash: history.location.pathname + history.location.search,
  });

  return null;
};
