import * as React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Colour } from "core/models";
import { CreditHistorySummary } from "components/features/dashboard/components/credit-history-chart-daily/components/credit-history-summary";
import { CreditModal } from "components/features/dashboard/components/credit-modal";
import { getMonth } from "date-fns";
import { SessionContext } from "components/contexts/session-context-provider";
import { ShareCreditHistoryButton } from "components/features/dashboard/components/share-credit-score-button";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  CreditHistorysResponse,
  getAllCreditHistory,
} from "core/queries/credit";

interface ChartState {
  options: ApexOptions;
  series: Array<any>;
}

export const ChartCreditHistory: React.FC = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { userID } = React.useContext(SessionContext.context);
  const [selectedUserID, setSelectUserID] = React.useState<string>(userID);
  // const { data } = useQuery<GetCreditHistoryResponse>(getCreditHistory, {
  //   variables: {
  //     offset: 0,
  //     limit: 100,
  //   },
  // });

  const { data: allData } =
    useQuery<CreditHistorysResponse>(getAllCreditHistory);

  const data = React.useMemo(() => {
    if (allData?.allCreditHistory && allData.allCreditHistory.length > 0) {
      return {
        creditHistory: allData.allCreditHistory.find(
          (ch) => ch.owner.id === selectedUserID
        ),
      };
    }
  }, [allData, selectedUserID]);

  const allUsers = React.useMemo(() => {
    return allData?.allCreditHistory.map((d) => d.owner);
  }, [allData]);

  const monthNames = React.useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  const series = React.useMemo(() => {
    let history = data?.creditHistory?.creditHistory ?? [];
    const map = history.map((h) => ({
      y: Math.floor(h.score),
      x: monthNames[getMonth(new Date(h.date))],
      key: getMonth(new Date(h.date)),
    }));

    return [
      {
        name: `TransUnion`,
        type: "line",
        data: map.sort((a, b) => a.key - b.key),
      },
      // {
      //   name: `Equifax`,
      //   type: "line",
      //   data: [666, 444, 777],
      // },
      // {
      //   name: `Experian`,
      //   type: "line",
      //   data: [743, 800, 489],
      // },
    ];
  }, [data?.creditHistory?.creditHistory, monthNames]);

  const dailyCashFlowState = React.useMemo<ChartState>(() => {
    return {
      series: series,
      options: {
        chart: {
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        colors: ["var(--blue-01)"], //"var(--orange)", "var(--green-01)",
        stroke: {
          width: [3, 3, 3],
          dashArray: [0, 0, 0],
        },
        legend: {
          markers: {
            width: 6,
            height: 6,
          },
          position: "bottom",
          horizontalAlign: "center",
          fontFamily: '"Montserrat", sans-serif',
          fontSize: "14px",
          fontWeight: 500,
          formatter: (x) => {
            return x.toUpperCase();
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          colors: ["var(--blue-01)"],
          strokeColors: "white",
        },
        tooltip: {
          //https://apexcharts.com/docs/options/tooltip/#custom
          // SeriesIndex is the month and dataPointIndex is the day
          custom: ({ _, seriesIndex, dataPointIndex }) => {
            const breakdown = series[seriesIndex]?.data[dataPointIndex];
            return (
              `<div class="credit-history-tooltip-wrapper">` +
              `<div class="cashflow-tooltip-title">${breakdown.x}</div>` +
              `<div class="cashflow-tooltip-row"><div class="tooltip-title">Score</div> <div class="tooltip-value">${breakdown.y}</div></div>` +
              `</div>`
            );
          },
        },
        grid: {
          borderColor: "var(--separator-02)",
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: '"Inter", sans-serif',
              fontSize: "10px",
              fontWeight: 700,
              colors: "var(--text-02)",
            },
            formatter: (v) => Math.floor(v).toString(),
          },
        },
        xaxis: {
          labels: {
            style: {
              fontFamily: '"Inter", sans-serif',
              fontSize: "10px",
              fontWeight: 700,
              colors: "var(--text-03)",
            },
          },
          tickAmount: 14,
          tooltip: {
            enabled: false,
          },
        },
        style: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "10px",
          fontWeight: 700,
          colors: "var(--text-02)",
        },
      },
    };
  }, [series]);

  return (
    <div>
      <div
        className="flex-row between"
        style={{
          paddingBottom: "1rem",
        }}
      >
        <Text
          type={TextType.Div}
          weight={700}
          colour={Colour.Navy}
          size={"1.25rem"}
        >
          Credit Score
        </Text>
        {data &&
          data?.creditHistory?.creditHistory &&
          data?.creditHistory?.creditHistory.length !== 0 &&
          data.creditHistory.owner.id === userID && (
            <ShareCreditHistoryButton
              creditHistoryResponse={data.creditHistory}
            />
          )}
        {data?.creditHistory?.creditHistory.length === 0 && (
          <div
            style={{ textAlign: "right", minHeight: "auto", cursor: "pointer" }}
            onClick={() => setShowModal(true)}
          >
            <TextD style={TextStyle.M15SM} colour={Colour.Blue01}>
              CHECK YOUR CREDIT FOR FREE AND SHARE WITH YOUR COLLABORATORS
            </TextD>
          </div>
        )}
      </div>
      <div
        className="overview-component flex-row mobile-flex-column"
        style={{ display: "flex" }}
      >
        <div
          style={{
            paddingTop: "1rem",
            flexShrink: 1,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            paddingRight: "2rem",
          }}
        >
          <div
            style={{
              paddingTop: "0.5rem",
              paddingLeft: "1.25rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text style={TextStyle.M18} type={TextType.Div}>
              Credit Score by Month
              {/* <span
                className={
                  currentMonthCashflowDetails.total >= 0 ? "green-01" : "red-01"
                }
                style={{ fontWeight: "bold" }}
              >
                {toMoneyStringNoDecimal(currentMonthCashflowDetails.total)}
                {currentMonthCashflowDetails.total > 0 && <>&uarr;</>}
                {currentMonthCashflowDetails.total < 0 && <>&darr;</>}
              </span> */}
            </Text>
          </div>

          <br />
          <div className="cashflow-chart">
            <Chart
              options={dailyCashFlowState.options}
              series={dailyCashFlowState.series}
              height="440px"
              width="100%"
            />
          </div>
        </div>

        <div className="credit-chart-summary">
          <CreditHistorySummary
            history={data?.creditHistory?.creditHistory}
            openModal={() => setShowModal(true)}
            setSelectUserID={setSelectUserID}
            users={allUsers}
          />
        </div>
      </div>
      <CreditModal showModal={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};
