import React, { useState } from "react";
import { AddNoteModal } from "components/features/dashboard/components/asset-notes/add-note-modal";
import { AssetNoteRow } from "components/features/dashboard/components/asset-notes/asset-note-row";
import { AssetType } from "components/features/dashboard/models/sharing";
import { LeafButton } from "components/core/leaf-button";
import { PaginatedFooter } from "core/paginated-footer";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  FetchAssetNotesResponse,
  FETCH_ASSET_NOTES,
} from "core/queries/asset-notes";

export interface Props {
  assetID: string;
  assetType: AssetType;
  owner: boolean;
  editor: boolean;
}

export const AssetNotes: React.FC<Props> = ({
  assetID,
  assetType,
  owner,
  editor,
}) => {
  const itemsPerPage = 10;
  const [page, setPage] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);

  const { data, loading, refetch } = useQuery<FetchAssetNotesResponse>(
    FETCH_ASSET_NOTES,
    {
      variables: {
        offset: page * itemsPerPage,
        assetID: assetID,
        assetType: assetType,
        limit: 10,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const goToPage = (p: number) => {
    if (loading) {
      return;
    }

    setPage(p);
    refetch({
      assetID: assetID,
      assetType: assetType,
      offset: p * itemsPerPage,
      limit: itemsPerPage,
    });
  };

  const notes = data?.getAssetNotes?.notes ? data.getAssetNotes.notes : [];
  const total = data?.getAssetNotes?.total ? data?.getAssetNotes?.total : 0;

  const hasPrevious = page > 0;
  const hasNext = total > (page + 1) * itemsPerPage;

  const totalPages = Math.ceil(total / itemsPerPage);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
      }}
    >
      <AddNoteModal
        show={showAddModal}
        assetID={assetID}
        assetType={assetType}
        onCreate={() => {
          refetch();
          setShowAddModal(false);
        }}
        onClose={() => {
          setShowAddModal(false);
        }}
      />

      {notes.map((n) => (
        <AssetNoteRow
          key={n.id}
          note={n}
          owner={owner}
          editor={editor}
          onDelete={() => {
            // Calculate if we need to go back one page

            const currentOffset = page * itemsPerPage;
            const input = {
              assetID: assetID,
              assetType: assetType,
              offset: currentOffset,
              limit: itemsPerPage,
            };
            // The item that got deleted is the last item on that page, we should automatically decrement page by 1
            if (total % itemsPerPage === 1 && total > 1) {
              setPage(page - 1);
              input.offset = (page - 1) * itemsPerPage;
            }
            refetch(input);
          }}
        />
      ))}

      {totalPages > 1 && (
        <PaginatedFooter
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          page={page}
          totalPages={totalPages}
          goToPage={goToPage}
        />
      )}
      {(owner || editor) && (
        <div
          className="flex-row"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <LeafButton id="add-new-note" onClick={() => setShowAddModal(true)}>
            Add New Note
          </LeafButton>
        </div>
      )}
    </div>
  );
};
