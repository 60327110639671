import { AddNewCategoryTray } from "components/features/dashboard/components/transactions/add-new-category-tray";
import { AddTransactionRuleTray } from "components/features/dashboard/components/transactions/add-transaction-rule-tray";
import { Colour } from "core/models";
import { DeleteCategoryOrRuleButton } from "components/features/dashboard/components/transactions/delete-category-or-rule-button";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { Text, TextStyle, TextType } from "components/core/text";
import { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import "./manage-category-styles.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
import {
  CustomTransactionCategoryResponse,
  DeleteCustomTransactionCategoryMutation,
  DeleteCustomTransactionCategoryMutationInput,
  DeleteTransactionRuleMutation,
  DeleteTransactionRuleMutationInput,
  GetCustomTransactionCategoryQuery,
  GetTransactionRulesQuery,
  TransactionRulesResponse,
} from "core/queries/transaction-category";

export interface Props {}

export const ManageCategoriesTray: React.FC<Props> = () => {
  const { popTray, pushTray } = useContext(SideTrayContext);

  const { data: categoryData } = useQuery<CustomTransactionCategoryResponse>(
    GetCustomTransactionCategoryQuery,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const { data: rulesData } = useQuery<TransactionRulesResponse>(
    GetTransactionRulesQuery,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [deleteCategory] = useMutation<
    {},
    DeleteCustomTransactionCategoryMutationInput
  >(DeleteCustomTransactionCategoryMutation, {
    refetchQueries: [
      {
        query: GetCustomTransactionCategoryQuery,
      },
    ],
  });
  const [deleteRule] = useMutation<{}, DeleteTransactionRuleMutationInput>(
    DeleteTransactionRuleMutation,
    {
      refetchQueries: [
        {
          query: GetTransactionRulesQuery,
        },
      ],
    }
  );

  const categories = categoryData?.customTransactionCategories ?? [];
  const rules = rulesData?.transactionRules ?? [];

  return (
    <div>
      <div className="flex-row between">
        <Text style={TextStyle.M18} type={TextType.Div}>
          Custom Categories
        </Text>
        <div
          className="flex-row"
          onClick={() => {
            pushTray({
              header: <SimpleSideTrayHeader text="Add New Category" showBack />,
              children: (
                <AddNewCategoryTray noManage onChange={() => popTray()} />
              ),
            });
          }}
          style={{
            width: "fit-content",
            cursor: "pointer",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.L12}
            colour={Colour.Blue01}
          >
            Add new category
          </Text>
        </div>
      </div>
      <div
        className="flex-column"
        style={{
          paddingTop: "0.5rem",
        }}
      >
        {categories.length === 0 && (
          <Text
            style={TextStyle.FieldLabel}
            type={TextType.Div}
            colour={Colour.Text03}
          >
            No custom transactions found.
          </Text>
        )}

        {categories.map((c) => {
          return (
            <div
              key={c.id}
              className="flex-row align-center between ellipsis manage-category-row"
              style={{
                padding: "0.5rem",
              }}
            >
              <Text
                style={TextStyle.FieldLabel}
                type={TextType.Div}
                colour={Colour.Text03}
                whiteSpace={"pre-wrap"}
              >
                {c.category}
              </Text>
              <DeleteCategoryOrRuleButton
                name={`category ${c.category}`}
                onConfirm={() => {
                  deleteCategory({
                    variables: {
                      id: c.id,
                    },
                  });
                }}
              />
            </div>
          );
        })}
      </div>

      <div className="mt1" />
      <div className="flex-column">
        <div className="flex-row between">
          <div className="flex-row">
            <Text style={TextStyle.M18} type={TextType.Div}>
              Category Rules
            </Text>
            <PylonTooltip
              size={TooltipIconSize.Small}
              text="When we receive new transactions, we'll check against the rules and update the transaction accordingly."
              spacingSide={SpacingSide.Left}
            />
          </div>

          <div
            className="flex-row"
            onClick={() => {
              pushTray({
                header: (
                  <SimpleSideTrayHeader text="Add New Category Rule" showBack />
                ),
                children: <AddTransactionRuleTray onChange={() => popTray()} />,
              });
            }}
            style={{
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Blue01}
            >
              Add new rule
            </Text>
          </div>
        </div>

        <div className="flex-column mt1">
          {rules.length === 0 && (
            <Text
              style={TextStyle.FieldLabel}
              type={TextType.Div}
              colour={Colour.Text03}
            >
              No category rules found.
            </Text>
          )}
          {rules.map((r) => {
            return (
              <div
                key={r.id}
                className="flex-row align-center between ellipsis manage-category-row"
                style={{
                  padding: "0.5rem",
                }}
              >
                <Text
                  style={TextStyle.FieldLabel}
                  type={TextType.Div}
                  colour={Colour.Text03}
                  whiteSpace={"pre-wrap"}
                >
                  When merchant is "{r.merchant}", set category to "{r.category}
                  "
                </Text>

                <DeleteCategoryOrRuleButton
                  name={`rule for merchant ${r.merchant}`}
                  onConfirm={() => {
                    deleteRule({
                      variables: {
                        id: r.id,
                      },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt4" />
    </div>
  );
};
