import React from "react";
import { ManageCollaboratorChildTray, Props as TrayProps } from "./manage";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { TrustedSourceContextProvider } from "components/contexts/trusted-source-context";
import {
  CollaborationRelationships,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";

interface Props {
  edit?: boolean;
}

export const ManageCollaboratorTray: React.FC<
  TrayProps & {
    defaultRelationship?: CollaborationRelationships;
  }
> = ({ defaultRelationship, ...props }) => {
  return (
    <TrustedSourceContextProvider
      refetchQueries={[{ query: FETCH_MY_COLLABORATORS }]}
      defaultOverrides={{
        relationship: defaultRelationship ?? CollaborationRelationships.Spouse,
      }}
    >
      <ManageCollaboratorChildTray {...props} />
    </TrustedSourceContextProvider>
  );
};

export const ManageCollaboratorHeader: React.FC<Props> = ({ edit }) => {
  if (edit) {
    return <SimpleSideTrayHeader text="Manage Collaborator" />;
  }
  return <SimpleSideTrayHeader text="Add Collaborator" />;
};
