import { Checkbox } from "components/core/checkbox";
import { Colour } from "core/models";
import { PylonTooltip, SpacingSide } from "components/core/tooltip";
import { Text, TextType } from "components/core/text";

export interface Props {
  disabled: boolean;
  checked: boolean;
  onChange?: () => void;
}

export const AutomaticPaymentDeductionToggle: React.FC<Props> = ({
  disabled,
  checked,
  onChange,
}) => {
  return (
    <div className="add-account-tray__step-selection mt1">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <Checkbox
              id={"share-type--options"}
              style={{
                width: "1rem",
                marginRight: "1rem",
              }}
              disabled={disabled}
              label={"Automatically Update"}
              checked={checked}
              onChange={onChange}
            />

            <PylonTooltip
              text={`When enabled, we will automatically deduct your monthly payment and interest from the remaining balance on the due date indicated.  This will continue until the account is paid off.`}
              spacingSide={SpacingSide.Left}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "0.5rem",
          borderBottom: "1px solid var(--separator-01)",
          marginBottom: "0.25rem",
        }}
      />
      <Text type={TextType.Div} size={"0.875rem"} colour={Colour.Text03}>
        Required: Due date, Interest rate, Monthly payment
      </Text>
    </div>
  );
};
