import { useMutation } from "@apollo/client";
import { SessionContext } from "components/contexts/session-context-provider";
import { Checkbox } from "components/core/checkbox";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import { ConfirmationModal } from "components/core/modal";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { TextD, TextStyle } from "components/core/text";
import { TextArea } from "components/core/textarea";
import { useMobileLayout } from "core/hooks/responsive";
import {
  AssetNote,
  AssetNoteVisibilityPermission,
  EditAssetNoteMutationInput,
  EDIT_ASSET_NOTE_MUTATION,
} from "core/queries/asset-notes";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import "./styles.css";

export interface Props {
  show: boolean;
  note: AssetNote;
  onClose: () => void;
  onUpdate: () => void;
}

export const EditNoteModal: React.FC<Props> = ({
  show,
  note,
  onClose,
  onUpdate,
}) => {
  const [state, setState] = useState<EditAssetNoteMutationInput>({
    id: note.id,
    note: note.note,
    visibility: note.visibility,
  });

  const { userID } = useContext(SessionContext.context);
  const mobileLayout = useMobileLayout();

  const [editNoteMutation, { loading: editLoading }] = useMutation<
    {},
    { input: EditAssetNoteMutationInput }
  >(EDIT_ASSET_NOTE_MUTATION, {
    onCompleted: () => {
      toast(
        <PylonToastBody
          title={`Successfully updated note`}
          body={`Your note has been updated`}
        />
      );
      onUpdate();
    },
    onError: (err) => {
      console.error("error editing note", err);
      toast(
        <PylonToastBody
          title={`Failed to edit note`}
          body={`We couldn't save the note. Please try again`}
          type={ToastType.Error}
        />
      );
    },
  });

  const disabled = !state.note || !state.visibility || editLoading;

  const editNote = () => {
    if (disabled) {
      return;
    }

    editNoteMutation({
      variables: {
        input: state,
      },
    });
  };

  return (
    <ConfirmationModal
      showModal={show}
      onClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div
        className="flex-column "
        style={{
          padding: mobileLayout ? "2.5rem 1rem" : "2.5rem",
          width: mobileLayout ? "95vw" : "32rem",
          color: "black",
          gap: "1.5rem",
        }}
      >
        <TextD style={TextStyle.M31B}>Edit note</TextD>

        <div
          className="flex-column gap-1"
          style={{
            paddingTop: "0.5rem",
          }}
        >
          <TextD style={TextStyle.M15}>
            <TextArea
              className="asset-note__note-input"
              id="asset-note"
              name="assetnote"
              autoFocus
              placeholder="Something noteworthy..."
              value={state.note}
              onChange={(e) =>
                setState({
                  ...state,
                  note: e.currentTarget.value,
                })
              }
            />
          </TextD>

          {userID === note.owner.id && (
            <div className="flex-row align-center">
              <Checkbox
                id="asset-note-visibility"
                checked={
                  state.visibility ===
                  AssetNoteVisibilityPermission.VisibleToOwnerOnly
                }
                label="Mark as private (only you can see this note)"
                onChange={() => {
                  let newVisibility =
                    AssetNoteVisibilityPermission.VisibleToAll;
                  if (
                    state.visibility ===
                    AssetNoteVisibilityPermission.VisibleToAll
                  ) {
                    newVisibility =
                      AssetNoteVisibilityPermission.VisibleToOwnerOnly;
                  }
                  setState({
                    ...state,
                    visibility: newVisibility,
                  });
                }}
              />
            </div>
          )}
        </div>

        <div
          className="flex-row gap-1"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <LeafButton
            onClick={(e) => {
              onClose();
            }}
            buttonStyle={LeafButtonStyle.LeafSmall}
            buttonColour={LeafButtonColour.Transparent}
          >
            Cancel
          </LeafButton>

          <LeafButton
            disabled={disabled}
            loading={editLoading}
            onClick={(e) => {
              editNote();
            }}
            buttonStyle={LeafButtonStyle.LeafSmall}
            buttonColour={LeafButtonColour.Black}
          >
            Ok
          </LeafButton>
        </div>
      </div>
    </ConfirmationModal>
  );
};
