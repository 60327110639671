import * as React from "react";
import Select from "react-select";
import { blurOnEnter } from "core/utils/form-helpers";
import { Icon, IconAsset } from "components/core/icons";
import { Input } from "components/core/input";
import { ItemizedDeductionType } from "@pylon/taxes";
import { itemizedDeductionTypeOptions } from "components/features/dashboard/pages/income-and-taxes/util";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { SELECT__MODAL_INPUT_STYLE } from "components/core/input/styles";
import { TaxContext } from "components/contexts/tax-context";
import { TaxItemizedDeductionInterface } from "core/queries/taxes";

interface Props {
  itemizedDeduction?: TaxItemizedDeductionInterface;
}

const defaultDeductionType = (
  itemizedDeduction?: TaxItemizedDeductionInterface
) => {
  if (!itemizedDeduction?.type) {
    return ItemizedDeductionType.MedicalAndDentalExpenses;
  }

  const type =
    ItemizedDeductionType[
      itemizedDeduction.type as keyof typeof ItemizedDeductionType
    ];

  if (!type) {
    return ItemizedDeductionType.MedicalAndDentalExpenses;
  }

  return type;
};
export const TaxSectionItemizedDeductionRow: React.FC<Props> = ({
  itemizedDeduction,
}) => {
  const [type, setDeductionType] = React.useState<ItemizedDeductionType>(
    defaultDeductionType(itemizedDeduction)
  );
  const { removeItemizedDeduction, saveItemizedDeduction } =
    React.useContext(TaxContext);

  const types = itemizedDeductionTypeOptions();

  return (
    <>
      <div className="flex-row between mobile-flex-column">
        <div style={{ marginRight: "1rem" }}>
          <div className="label">Deduction Type</div>
          <Select
            isSearchable={false}
            placeholder="Select your deduction type"
            styles={SELECT__MODAL_INPUT_STYLE}
            value={types.find((v) => {
              return v.value === type.toString();
            })}
            onChange={(e) => {
              if (!e) {
                return;
              }
              const newType =
                ItemizedDeductionType[
                  e.value as keyof typeof ItemizedDeductionType
                ];
              setDeductionType(newType);

              saveItemizedDeduction(
                parseFloat(itemizedDeduction?.amount || "0") || 0,
                newType.toString(),
                itemizedDeduction?.name || "",
                itemizedDeduction?.id
              );
            }}
            menuPortalTarget={document.body}
            options={types}
          />
        </div>
        <div style={{ marginRight: "1rem" }}>
          <div className="label">Deduction Description</div>
          <Input
            placeholder={`Deduction Description `}
            defaultValue={
              itemizedDeduction?.transaction != null
                ? itemizedDeduction?.transaction.name ||
                  itemizedDeduction?.transaction.merchantName
                : itemizedDeduction?.name || ""
            }
            onBlur={(e) =>
              saveItemizedDeduction(
                parseFloat(itemizedDeduction?.amount || "0") || 0,
                itemizedDeduction?.type ||
                  ItemizedDeductionType.Miscellaneous.toString(),
                e.target.value,
                itemizedDeduction?.id
              )
            }
            onKeyDown={blurOnEnter}
            style={{ marginBottom: "0.5rem" }}
            disabled={itemizedDeduction?.transaction != null}
          />
          {itemizedDeduction?.transaction && (
            <div className="small" style={{ marginTop: "-.5rem" }}>
              From {itemizedDeduction?.transaction.account.institutionName}{" "}
              {itemizedDeduction?.transaction.account.official_name} **
              {itemizedDeduction?.transaction.account.mask}
            </div>
          )}
        </div>
        <div>
          <div className="label">Deduction Amount</div>
          <PylonCurrencyInput
            placeholder={`Your `}
            defaultValue={itemizedDeduction?.amount || 0}
            maxLength={12}
            onBlur={(e) =>
              saveItemizedDeduction(
                parseFloat(sanitizeCurrencyString(e.target.value)),
                itemizedDeduction?.type ||
                  ItemizedDeductionType.Miscellaneous.toString(),
                itemizedDeduction?.name || "",
                itemizedDeduction?.id
              )
            }
            onKeyDown={blurOnEnter}
            allowDecimals={true}
            decimalSeparator={"."}
            decimalsLimit={2}
            allowNegativeValue={false}
            prefix="$"
            style={{ marginBottom: "0.5rem" }}
            disabled={itemizedDeduction?.transaction != null}
          />
        </div>{" "}
        <div className="flex-row" style={{ width: "32px" }}>
          {itemizedDeduction && (
            <div
              className="flex-column center ml1 clickable"
              onClick={() => {
                removeItemizedDeduction(itemizedDeduction);
              }}
            >
              <Icon asset={IconAsset.Trashcan} width="16px" height="16px" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
