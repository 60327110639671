import {
  FilingStatus,
  getLocalities,
  getStateRules,
  TaxCalculationInput,
} from "@pylon/taxes";
import {
  TaxLiabilityInterface,
  TaxWithholdingInterface,
} from "core/queries/taxes";

export const currentTaxYear = 2020;

export const toTaxCalculationInput = (
  taxLiability?: TaxLiabilityInterface,
  taxWithholding?: TaxWithholdingInterface
): TaxCalculationInput => {
  const state = taxLiability?.state || "MD";
  const locality =
    taxLiability?.county || getStateRules(state).localityTaxes
      ? getLocalities(state)[0]
      : "";
  const filingStatus =
    (taxLiability?.filingStatus as FilingStatus) || FilingStatus.Single;

  return {
    stateAbbreviation: state,
    locality: locality,
    filingStatus: filingStatus,
    income:
      parseNumber(taxLiability?.earnedIncome) +
      (filingStatus === FilingStatus.MarriedFilingJointly
        ? parseNumber(taxLiability?.spouseEarnedIncome)
        : 0),
    exemptions: parseNumber(taxLiability?.totalExemptions),
    longTermCapitalGains: parseNumber(taxLiability?.capitalGainsLongTerm),
    shortTermCapitalGains: parseNumber(taxLiability?.capitalGainsShortTerm),
    contributions401k:
      (parseNumber(taxLiability?.contributions401K) || 0) +
      (parseNumber(taxLiability?.spouseContributions401K) || 0),
    contributionsTraditionalIRA:
      (parseNumber(taxLiability?.contributionsIRA) || 0) +
      (parseNumber(taxLiability?.spouseContributionsIRA) || 0),

    paymentPeriods: parseNumber(taxWithholding?.paymentPeriodsPerYear, "24"),
    federalWithholding: parseNumber(taxWithholding?.federalIncomeTax),
    stateWithholding: parseNumber(taxWithholding?.stateIncomeTax),

    spousePaymentPeriods: parseNumber(
      taxWithholding?.spousePaymentPeriodsPerYear,
      "24"
    ),
    spouseFederalWithholding: parseNumber(
      taxWithholding?.spouseFederalIncomeTax
    ),
    spouseStateWithholding: parseNumber(taxWithholding?.spouseStateIncomeTax),
  };
};

export const parseNumber = (
  n: string | undefined,
  defaultValue: string = "0"
): number => {
  return parseFloat(n ?? defaultValue);
};
