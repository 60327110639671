import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import Illustration from "./billionaire-illustration.svg";
import Select from "react-select";
import { Colour } from "core/models";
import { PlanContext } from "components/contexts/plan-context-provider";
import { PlanDataSerializer } from "components/features/dashboard/components/planning/calculators/data-serializer";
import { PlanOptions } from "components/features/dashboard/components/planning/models";
import { PlanTitle } from "components/features/dashboard/components/planning/calculators/plan-title";
import { PylonCurrencyInput } from "components/core/currency-input";
import { PylonTooltip, TooltipIconSize } from "components/core/tooltip";
import { SavedScenario } from "components/features/dashboard/components/planning/saved-scenario";
import { ScenarioPlan } from "core/queries/scenario-plans";
import { SerializablePlan } from "components/features/dashboard/components/planning/calculators/models";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useContext, useState } from "react";
import "./styles.css";
import {
  CashflowResult,
  ExpenseResult,
  ScenarioResultScreen,
} from "components/features/dashboard/components/planning/calculators/result-screen";

enum Frequency {
  Daily = 1,
  Weekly = 7,
  Monthly = 30,
  Yearly = 365,
}

interface DefaultSettings {
  frequency: Frequency;
  cost: string;
}

const PRESET_ACTIVITIES: Record<string, DefaultSettings> = {
  "1 Hour Of Private Jet": {
    frequency: Frequency.Daily,
    cost: "5313",
  },
  "Lunch with Warren Buffet": {
    frequency: Frequency.Yearly,
    cost: "4600000",
  },
  "Private Chef": {
    frequency: Frequency.Daily,
    cost: "500",
  },
  "Space Trip": {
    frequency: Frequency.Yearly,
    cost: "2500000",
  },
  "Provide 10,000 meals through foodbank": {
    frequency: Frequency.Daily,
    cost: "1000",
  },
};

interface Activity {
  name: string;
  defaultSetting?: DefaultSettings;
}

type BillionaireData = {
  activity?: string;
  frequency?: Frequency;
  cost?: string;
} & SerializablePlan;

export const BillionaireCalculator: React.FC = () => {
  const { monthlyCashflow } = useContext(PlanContext);

  const [allActivities, setAllActivities] = useState<Activity[]>(
    Object.keys(PRESET_ACTIVITIES).map((a) => {
      return {
        name: a,
        defaultSetting: PRESET_ACTIVITIES[a],
      };
    })
  );

  const getDefaultScenarioData = () => {
    return {
      activity: allActivities[0].name,
      frequency: allActivities[0].defaultSetting?.frequency,
      cost: allActivities[0].defaultSetting?.cost,
    };
  };

  const [scenario, setScenario] = useState<Partial<BillionaireData>>(
    getDefaultScenarioData()
  );

  const [savedScenario, setSavedScenario] = useState<ScenarioPlan | undefined>(
    undefined
  );

  const onActivityChange = (name: string) => {
    const act = allActivities.find((x) => x.name === name);
    const newSettings: Partial<BillionaireData> = {
      activity: name,
    };
    if (act) {
      if (act.defaultSetting) {
        newSettings.cost = act.defaultSetting.cost;
        newSettings.frequency = act.defaultSetting.frequency;
      }
    } else {
      // The activity is new, add it to the list
      setAllActivities(
        allActivities.concat({
          name: name,
        })
      );
    }

    setScenario({
      ...scenario,
      ...newSettings,
    });
  };

  let dailyCost: number | undefined = undefined;
  if (
    !_.isNil(scenario.activity) &&
    !_.isNil(scenario.frequency) &&
    !_.isNil(scenario.cost) &&
    Object.values(Frequency).includes(scenario.frequency)
  ) {
    const parsedCost = Number.parseFloat(scenario.cost);

    dailyCost = parsedCost / scenario.frequency;
  }

  const calculatedMonthlyCashflow = dailyCost
    ? monthlyCashflow - dailyCost * 30
    : undefined;

  const activityOptions = allActivities.map((a) => {
    return {
      value: a.name,
      label: a.name,
      defaultSetting: a.defaultSetting,
    };
  });

  const selectedActivity = activityOptions.find(
    (act) => act.value === scenario.activity
  );

  const frequencyOptions = Object.keys(Frequency)
    .filter((f) => _.isNaN(Number(f)))
    .map((f) => {
      return {
        label: f,
        value: Frequency[f as any as Frequency] as any as Frequency, // Force convert since we are iterating through the enum
      };
    });
  const selectedFrequency = frequencyOptions.find(
    (f) => f.value === scenario.frequency
  );

  const namingParts = [
    scenario.activity ?? "Scenario",
    scenario.frequency ? Frequency[scenario.frequency] : "",
  ];

  const defaultName = namingParts.join(" - ");

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <SavedScenario
        selectedOption={PlanOptions.Billionaire}
        onLoad={(d) => {
          if (!d) {
            setSavedScenario(undefined);
            setScenario(getDefaultScenarioData());
            return;
          }

          const data = PlanDataSerializer.deserialize<BillionaireData>(d.value);

          if (data.activity) {
            onActivityChange(data.activity);
          }

          setScenario(data);

          setSavedScenario(d);
        }}
      />

      <div
        style={{
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <PlanTitle scenario={savedScenario} />

          <div
            style={{
              paddingBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text
                type={TextType.Div}
                style={TextStyle.L12}
                colour={Colour.Text03}
                format={TextFormat.UpperCase}
              >
                Activity
              </Text>
              <PylonTooltip
                size={TooltipIconSize.Small}
                text="You can save your own activity by typing it in the following field!"
              />
            </div>

            <CreatableSelect
              className="plan_select-option"
              isSearchable
              classNamePrefix="pylon-select"
              options={activityOptions}
              value={selectedActivity}
              styles={{
                input: (base) => {
                  return {
                    ...base,

                    margin: "unset",
                    paddingBottom: "unset",
                    paddingTop: "unset",
                  };
                },
              }}
              onChange={(v) => {
                if (!v) {
                  return;
                }
                onActivityChange(v.value);
              }}
            />
          </div>

          <div
            style={{
              paddingBottom: "1rem",
            }}
          >
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Frequency
            </Text>
            <Select
              isSearchable={false}
              classNamePrefix="pylon-select"
              className="plan_select-option"
              options={frequencyOptions}
              value={selectedFrequency}
              onChange={(v) => {
                if (!v) {
                  return;
                }

                setScenario({
                  ...scenario,
                  frequency: v.value,
                });
              }}
            />
          </div>

          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Estimated Cost
            </Text>
            <PylonCurrencyInput
              className="plan-input-box"
              value={scenario.cost}
              onValueChange={(v) =>
                setScenario({
                  ...scenario,
                  cost: v,
                })
              }
              placeholder="$20,000"
              prefix="$"
              decimalsLimit={2}
              allowNegativeValue={false}
            />
          </div>
        </div>

        <div
          style={{
            paddingTop: "2rem",
          }}
        />

        <ScenarioResultScreen
          saveButtonProps={{
            selectedOption: PlanOptions.Billionaire,
            serializedPlan: PlanDataSerializer.serialize({
              ...scenario,
              monthlyCashflow: calculatedMonthlyCashflow,
            }),
            defaultName: defaultName,
            onSave: (sp) => {
              setSavedScenario(sp);
            },
            scenario: savedScenario,
          }}
        >
          <ExpenseResult monthlyCost={dailyCost ? dailyCost * 30 : undefined} />
          <CashflowResult newCashflow={calculatedMonthlyCashflow} />
        </ScenarioResultScreen>

        <div
          style={{
            paddingTop: "2rem",
          }}
        >
          <svg width="608" height="324">
            <use xlinkHref={`${Illustration}#illustration`} />
          </svg>
        </div>
      </div>
    </div>
  );
};
