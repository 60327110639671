import { AccountTransaction } from "core/queries/transactions";
import { Colour } from "core/models";

export enum TransactionSortType {
  ByDate,
  ByName,
}

export const sortTransactions = (
  transactions: AccountTransaction[],
  sortType = TransactionSortType.ByDate
) => {
  return transactions
    .slice()
    .sort((a, b) => {
      return sortStr(a.transactionID, b.transactionID);
    })
    .sort((a, b) => {
      if (b.pending && !a.pending) {
        return 1;
      }
      if (a.pending && !b.pending) {
        return -1;
      }
      switch (sortType) {
        case TransactionSortType.ByDate:
          return b.date - a.date;
        case TransactionSortType.ByName:
          return sortStr(a.name, b.name);
        default:
          throw new Error("Unknown sort type");
      }
    });
};

const sortStr = (a: string, b: string) => {
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};

export const DEFAULT_CATEGORY_LIST = [
  "Donation",
  "Entertainment",
  "Food and Drink",
  "Grocery",
  "Gym",
  "Investment",
  "Medical Visit",
  "Pet",
  "Pharmacy",
  "Recreation",
  "Restaurant",
  "Service",
  "Shops",
  "Transportation",
  "Travel",
  "Utility Bill",
  "Vacation",
];

export interface CategoryColour {
  background: Colour;
  textColour: Colour;
}

const CATEGORY_LIST_COLOUR: Record<string, CategoryColour> = {
  Donation: {
    background: Colour.Blue100,
    textColour: Colour.Black,
  },
  Entertainment: {
    background: Colour.Purple100,
    textColour: Colour.Black,
  },
  "Food and Drink": {
    background: Colour.Cyan100,
    textColour: Colour.Black,
  },
  Grocery: {
    background: Colour.Green100,
    textColour: Colour.Black,
  },
  Gym: {
    background: Colour.Yellow100,
    textColour: Colour.Black,
  },
  Investment: {
    background: Colour.Orange100,
    textColour: Colour.Black,
  },
  "Medical Visit": {
    background: Colour.Gray300,
    textColour: Colour.Black,
  },
  Pet: {
    background: Colour.Blue600,
    textColour: Colour.White,
  },
  Pharmacy: {
    background: Colour.Purple600,
    textColour: Colour.White,
  },
  Recreation: {
    background: Colour.Cyan800,
    textColour: Colour.White,
  },
  Restaurant: {
    background: Colour.Green700,
    textColour: Colour.White,
  },
  Service: {
    background: Colour.Yellow400,
    textColour: Colour.Black,
  },
  Shops: {
    background: Colour.Orange600,
    textColour: Colour.White,
  },
  Transportation: {
    background: Colour.Gray700,
    textColour: Colour.White,
  },
  Travel: {
    background: Colour.Blue400,
    textColour: Colour.White,
  },
  "Utility Bill": {
    background: Colour.Purple400,
    textColour: Colour.White,
  },
  Vacation: {
    background: Colour.Cyan400,
    textColour: Colour.Black,
  },
};

const CUSTOM_CATEGORY_COLOURS: CategoryColour[] = [
  {
    background: Colour.Green400,
    textColour: Colour.White,
  },
  {
    background: Colour.Yellow800,
    textColour: Colour.White,
  },
  {
    background: Colour.Orange400,
    textColour: Colour.Black,
  },
  {
    background: Colour.Gray600,
    textColour: Colour.White,
  },
];

export const GetCategoryColour = (category: string) => {
  if (CATEGORY_LIST_COLOUR[category]) {
    return CATEGORY_LIST_COLOUR[category];
  }

  let hash = 0;
  if (category.length > 0) {
    for (let i = 0; i < category.length; i++) {
      hash += category.charCodeAt(i);
    }
  }

  return CUSTOM_CATEGORY_COLOURS[hash % CUSTOM_CATEGORY_COLOURS.length];
};
