import { displayColouredNumber } from "components/features/dashboard/components/planning/coloured-number";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { toMoneyStringNoDecimal } from "core/utils";
import "./styles.css";
import {
  Props as SaveButtonProps,
  SaveScenarioButton,
} from "components/features/dashboard/components/planning/calculators/save-scenario-button";

export interface Props {
  saveButtonProps: SaveButtonProps;
}

export const ScenarioResultScreen: React.FC<Props> = ({
  children,
  saveButtonProps,
}) => {
  return (
    <div
      style={{
        width: "100%",
        background: "var(--bg-01)",
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {children}
      </div>

      <div
        style={{
          display: "flex",
          paddingTop: "2rem",
        }}
      >
        <SaveScenarioButton {...saveButtonProps} />
      </div>
    </div>
  );
};

export const ExpenseResult: React.FC<{ monthlyCost?: number }> = ({
  monthlyCost,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
      }}
    >
      <Text
        type={TextType.Div}
        style={TextStyle.B16}
        weight={600}
        format={TextFormat.UpperCase}
      >
        New Expense
      </Text>
      <div style={{ paddingTop: "1rem" }} />
      {monthlyCost ? (
        <>
          <Text type={TextType.Div} style={TextStyle.B16}>
            {toMoneyStringNoDecimal(monthlyCost)} in additional monthly cost
          </Text>
          <div style={{ paddingTop: "1rem" }} />
          <Text type={TextType.Div} style={TextStyle.B16}>
            {toMoneyStringNoDecimal(monthlyCost * 12)} in additional yearly cost
          </Text>
        </>
      ) : (
        <Text type={TextType.Div} style={TextStyle.B16}>
          There's no change in cost
        </Text>
      )}
    </div>
  );
};
export const CashflowResult: React.FC<{ newCashflow?: number }> = ({
  newCashflow,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
      }}
    >
      <Text
        type={TextType.Div}
        style={TextStyle.B16}
        weight={600}
        format={TextFormat.UpperCase}
      >
        New Monthly Cash flow
      </Text>
      <div style={{ paddingTop: "1rem" }} />
      <Text type={TextType.Div} style={TextStyle.B16}>
        {newCashflow
          ? displayColouredNumber(newCashflow, undefined, undefined, "1rem")
          : "No cash flow change"}
      </Text>
    </div>
  );
};
