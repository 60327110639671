import * as React from "react";

export function useInputFields<T>(
  initialValues: T,
  onSave: (key: string, value: string) => void
) {
  const createBlurHandler =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      onSave(key, value);
    };
  const createKeyDownHandler =
    (key: string) => (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        e.currentTarget.blur();
      }
    };
  return { createBlurHandler, createKeyDownHandler };
}
