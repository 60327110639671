import { ForgotPasswordPage } from "components/features/login/pages/password-reset/forgot-password-page";
import { InvitationLandingPage } from "components/features/login/pages/invitation";
import { Login } from "./login";
import { LoginTwoFactorAuthPage } from "./login/2fa";
import { NewPasswordPage } from "components/features/login/pages/password-reset/new-password-page";
import { NewUser } from "./new-user";
import { PasswordResetLandingPage } from "components/features/login/pages/password-reset/landing-page";
import { PylonRoute } from "pages";
import { Route } from "react-router";

export const LoginPages = {
  Login: "/login",
  Register: "/login/register",
  LoginTwoFA: "/login/2fa",
  Invitation: "/login/invited",
  PasswordResetLandingPage: "/login/resetpassword",
  NewPasswordPage: "/login/newpassword",
  ForgotPassword: "/login/forgot-password",
};

export const renderLoginRoutes = () => {
  const routes: PylonRoute[] = [
    {
      index: 9,
      route: LoginPages.ForgotPassword,
      component: ForgotPasswordPage,
    },
    {
      index: 8,
      route: LoginPages.NewPasswordPage,
      component: NewPasswordPage,
    },
    {
      index: 7,
      route: LoginPages.PasswordResetLandingPage,
      component: PasswordResetLandingPage,
    },
    {
      index: 6,
      route: LoginPages.Invitation,
      component: InvitationLandingPage,
    },
    {
      index: 5,
      route: LoginPages.Register,
      component: NewUser,
      exact: true,
    },
    {
      index: 1,
      route: LoginPages.LoginTwoFA,
      component: LoginTwoFactorAuthPage,
      exact: true,
    },
    {
      index: 0,
      route: LoginPages.Login,
      component: Login,
      exact: true,
    },
  ];

  return routes.map((route) => (
    <Route
      exact={route.exact}
      key={route.index}
      path={route.route}
      component={route.component}
    />
  ));
};
