import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import React, {
  useContext,
  useEffect,
  useMemo,
  useState
  } from "react";
import { AddNewCategoryTray } from "components/features/dashboard/components/transactions/add-new-category-tray";
import { Colour } from "core/models";
import { Dropdown } from "react-bootstrap";
import { DropDownMenu } from "components/core/drop-down-menu";
import { Icon, IconAsset } from "components/core/icons";
import { Input } from "components/core/input";
import { MutationWrapper } from "core/queries/mutation";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { TextD, TextStyle } from "components/core/text";
import { unique } from "core/utils/array";
import { useMutation, useQuery } from "@apollo/client";
import "./transactions-styles.css";
import {
  DropDownMenuItem,
  DropDownMenuItemType,
} from "components/core/drop-down-menu-item";
import {
  CategoryColour,
  DEFAULT_CATEGORY_LIST,
  GetCategoryColour,
} from "components/features/dashboard/components/transactions/utils";
import {
  CustomTransactionCategoryResponse,
  GetCustomTransactionCategoryQuery,
} from "core/queries/transaction-category";
import {
  AccountTransaction,
  UpdateAccountTransactionMutationInput,
  UpdateAccountTransactionResponse,
  UPDATE_TRANSACTION,
} from "core/queries/transactions";

export interface Props {
  transaction: AccountTransaction;

  disabled?: boolean;
  onChange?: () => void;
}

export const TransactionCategory: React.FC<Props> = ({
  transaction,
  disabled,
  onChange,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const { pushTray, popTray } = useContext(SideTrayContext);

  const { data: categoryData } = useQuery<CustomTransactionCategoryResponse>(
    GetCustomTransactionCategoryQuery
  );
  const [category, setCategory] = useState(transaction.category);

  const [updateTransaction, { loading }] = useMutation<
    UpdateAccountTransactionResponse,
    MutationWrapper<UpdateAccountTransactionMutationInput>
  >(UPDATE_TRANSACTION, {
    onCompleted: () => {
      onChange?.();
    },
  });

  const updateTransactionCategory = (newCategory?: string) => {
    if (loading) {
      return;
    }

    if (newCategory && category !== newCategory) {
      setCategory(newCategory);

      updateTransaction({
        variables: {
          input: {
            accountID: transaction.accountID,
            transactionID: transaction.transactionID,
            category: newCategory,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (category !== transaction.category) {
      setCategory(transaction.category);
    }

    // Only recompute the cached value when transaction changes.
    // Ignore changes of cached values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const customCategories = useMemo(() => {
    return unique(
      (categoryData?.customTransactionCategories?.map((c) => c.category) ?? [])
        .concat(transaction.category)
        .concat(DEFAULT_CATEGORY_LIST)
    );
  }, [categoryData?.customTransactionCategories, transaction.category]);

  const [categoryFilter, setCategoryFilter] = useState("");

  const filteredCategories = useMemo(() => {
    if (!categoryFilter) {
      return customCategories;
    }

    return customCategories.filter((c) => c.includes(categoryFilter));
  }, [categoryFilter, customCategories]);

  const displayColour = disabled
    ? PILL__DISABLED_COLOUR
    : GetCategoryColour(category);

  return (
    <div
      className="flex-column"
      ref={wrapperRef}
      style={{
        userSelect: "none",
        maxWidth: "100%",
        width: "fit-content", // Width css to make ellipsis work
      }}
    >
      <Dropdown className="asset-note__menu" bsPrefix="pylon">
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          <div
            className={`transaction-pill ${disabled ? "disabled" : ""}`}
            style={{
              background: displayColour.background,
            }}
          >
            <TextD
              style={TextStyle.M11}
              colour={displayColour.textColour}
              ellipsis
            >
              {category}{" "}
              <Icon
                asset={IconAsset.CategoryDropdown}
                width="7px"
                height="16px"
                colour={displayColour.textColour}
              ></Icon>
            </TextD>
          </div>
        </DropdownToggle>
        <DropdownMenu
          style={{
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
            width: "15rem",
            maxHeight: "40vh",
            marginTop: "0.5rem",
            overflow: "auto",
            border: "none",
            display: disabled ? "none" : "block",
          }}
        >
          <DropDownMenu>
            <div
              style={{
                position: "sticky",
                top: 0,
                borderBottom: Colour.Gray100,
              }}
            >
              <Input
                autoFocus
                className="transaction-search-input"
                placeholder="Select or create category..."
                value={categoryFilter}
                onChange={(v) => {
                  setCategoryFilter(v.currentTarget.value);
                }}
              />
            </div>
            {filteredCategories.map((c) => {
              const isSelected = c === category;
              return (
                <DropDownMenuItem
                  key={c}
                  type={DropDownMenuItemType.Padded}
                  text={
                    <div className="flex-row align-center gap-1">
                      <div
                        style={{
                          flexGrow: 0,
                          flexShrink: 0,
                        }}
                      >
                        {isSelected ? (
                          <Icon
                            asset={IconAsset.DropDownSelectedItem}
                            width="24px"
                            height="24px"
                            colour={Colour.Black}
                          />
                        ) : (
                          <div style={{ width: "24px", height: "24px" }} />
                        )}
                      </div>

                      <div
                        style={{
                          whiteSpace: "break-spaces",
                          flexGrow: 1,
                          flexShrink: 1,
                        }}
                      >
                        {c}
                      </div>
                    </div>
                  }
                  onClick={() => {
                    updateTransactionCategory(c);
                    wrapperRef?.current?.click();
                  }}
                />
              );
            })}
            <DropDownMenuItem
              type={DropDownMenuItemType.Padded}
              text={
                <div style={{ paddingLeft: "2.5rem" }}>Create category</div>
              }
              onClick={() => {
                wrapperRef?.current?.click();
                pushTray({
                  header: <SimpleSideTrayHeader text="Create New Category" />,
                  children: (
                    <AddNewCategoryTray
                      defaultMerchant={transaction.merchantName}
                      onChange={(newCategory) => {
                        popTray();
                        updateTransactionCategory(newCategory.category);
                      }}
                    />
                  ),
                });
              }}
            />
          </DropDownMenu>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const PILL__DISABLED_COLOUR: CategoryColour = {
  background: Colour.Gray200,
  textColour: Colour.Gray400,
};
