import * as React from "react";
import PngCollab from "core/assets/onboarding-collab.png";
import { Colour } from "core/models";
import { PylonTip } from "components/core/pylon-tip";
import { Text, TextFormat, TextStyle } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";

export const OnboardingSharingPresentation: React.FC = () => {
  const mobileLayout = useMobileLayout();
  const rendered = React.useMemo(() => {
    const o = [
      <>
        <div className="onboarding-screenshots" key={0}>
          <div
            style={{
              margin: "1rem 0",
            }}
          >
            <PylonTip />
          </div>
          <h4
            style={{
              margin: "1rem 0",
              marginBottom: "2rem",
              fontSize: "3.5vh",
              lineHeight: "3.5vh",
            }}
          >
            Securely share accounts and docs with others.
          </h4>
          <div
            style={{
              margin: "1rem 0",
              marginBottom: "2rem",
            }}
          >
            <Text style={TextStyle.Poppins} weight={400} size={"2.5vh"}>
              Invite your partner, accountant, financial advisor, or others to
              easily collaborate on your finances.
            </Text>
          </div>
          <div
            style={{
              marginTop: "4rem",
            }}
          >
            <div>
              <Text
                style={TextStyle.FieldLabel}
                weight={700}
                size={"0.875rem"}
                format={TextFormat.UpperCase}
              >
                Feature Set
              </Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                  paddingRight: "1rem",
                }}
              >
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Share
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Protect
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Combine
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      <>
        <div className={"manage-img"} key={1}>
          <img src={PngCollab} alt="Collaboration" />
        </div>
      </>,
    ];
    return mobileLayout ? o.reverse() : o;
  }, [mobileLayout]);
  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            Pylon makes <span style={{ color: "var(--blue-01)" }}>sharing</span>{" "}
            incredibly easy
            <span style={{ color: "var(--blue-01)" }}>.</span>
          </div>
        </div>
      </div>
      <div className="onboarding-middle">{rendered}</div>
    </div>
  );
};
