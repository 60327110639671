import classnames from "classnames";
import React from "react";
import { Spinner } from "react-bootstrap";
import "./styles.css";

export type ButtonProps =
  | React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  | React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >;

export enum LeafButtonStyle {
  LeafSmallDense = "pylon-button__leaf-small-dense",
  LeafSmall = "pylon-button__leaf-small",
  LeafLarge = "pylon-button__leaf-large",
}

export enum LeafButtonColour {
  Black = "pylon-button__color-black",
  Transparent = "pylon-button__color-transparent",
}

export interface PublicProps {
  buttonStyle?: LeafButtonStyle;
  buttonColour?: LeafButtonColour;

  id?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  arrowRight?: boolean;
  arrowLeft?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

type Props = PublicProps;

export const LeafButton: React.FC<Props> = ({
  id,
  buttonStyle = LeafButtonStyle.LeafSmall,
  buttonColour = LeafButtonColour.Black,
  disabled,
  fullWidth = false,
  loading,
  arrowLeft,
  arrowRight,
  children,
  onClick,
}) => {
  const clazz = classnames({
    "pylon_button__leaf-base": true,
    [buttonStyle]: true,
    [buttonColour]: true,
    "pylon_button__leaf-disabled": !loading && disabled,
    "pylon_button__leaf-full-width": fullWidth,
  });
  const wrappedOnClick = (event: React.MouseEvent) => {
    if (!disabled) {
      onClick?.(event);
    }
  };
  let buttonComp = (
    <div
      data-selector={id}
      id={id}
      className={`${clazz}`}
      onClick={wrappedOnClick}
    >
      <div
        style={{
          visibility: loading ? "hidden" : "visible",
        }}
      >
        {children}
      </div>

      {loading && (
        <div className="pylon-button__leaf-loading-spinner">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );

  return buttonComp;
};
