import * as React from "react";
import Collapsible from "react-collapsible";
import { blurOnEnter } from "core/utils/form-helpers";
import { FilingStatus } from "@pylon/taxes";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { setValue } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/util";
import { TaxContext } from "components/contexts/tax-context";
import { Text, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useLocalStorage } from "core/utils/browser-storage";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";

export const TaxSectionRegularIncome: React.FC = () => {
  const [isOpen, setIsOpen] = useLocalStorage("taxes.income", true);

  const {
    earnedIncome,
    setEarnedIncome,
    earnedIncomeSpouse,
    setEarnedIncomeSpouse,
    filingStatus,
    saveLiabilityFieldValue,
    regularIncome,
  } = React.useContext(TaxContext);

  return (
    <Collapsible
      open={isOpen}
      trigger={
        <div
          className="table-row"
          style={{}}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div style={{ flexGrow: 1 }} className="table-cell">
            Regular Income
          </div>
          <div className="table-cell">{toMoneyString(regularIncome())}</div>
        </div>
      }
    >
      <div
        className="right"
        style={{
          marginBottom: "1rem",
        }}
      >
        <div className="flex-row between mobile-flex-column">
          <div>
            <div className="label">
              Your Income&#160;
              <PylonTooltip
                spacingSide={SpacingSide.Right}
                text={
                  <Text type={TextType.Div}>
                    <p>
                      The total of any regular income that you receive (wages,
                      tips, etc.) This should not include investment income,
                      rental income, 1099 income, or supplemental income (e.g.
                      bonuses or commission).
                    </p>
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <PylonCurrencyInput
              placeholder="Your Gross Income"
              value={earnedIncome}
              maxLength={12}
              onValueChange={(v) => {
                setValue(v || "", setEarnedIncome);
              }}
              onBlur={(e) =>
                saveLiabilityFieldValue(
                  "earnedIncome",
                  sanitizeCurrencyString(e.target.value)
                )
              }
              onKeyDown={blurOnEnter}
              allowDecimals={true}
              decimalSeparator={"."}
              decimalsLimit={2}
              allowNegativeValue={false}
              prefix="$"
            />
          </div>
          {filingStatus === FilingStatus.MarriedFilingJointly && (
            <div>
              <div className="label">
                Spouse's Income&#160;
                <PylonTooltip
                  spacingSide={SpacingSide.Right}
                  text={
                    <Text type={TextType.Div}>
                      <p>
                        The total of any regular income that your spouse
                        received (wages, tips, etc.) This should not include
                        investment income, rental income, 1099 income, or
                        supplemental income (e.g. bonuses or commission).
                      </p>
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <PylonCurrencyInput
                placeholder="Spouse's Gross Income"
                value={earnedIncomeSpouse}
                maxLength={12}
                onValueChange={(v) => {
                  setValue(v || "0", setEarnedIncomeSpouse);
                }}
                onBlur={(e) => {
                  saveLiabilityFieldValue(
                    "spouseEarnedIncome",
                    sanitizeCurrencyString(e.target.value)
                  );
                }}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                onKeyDown={blurOnEnter}
              />
            </div>
          )}
        </div>
      </div>
    </Collapsible>
  );
};
