import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { PlaidLink } from "react-plaid-link";
import { PylonToastBody } from "../../../../core/pylon-toast-body";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import "./styles.css";
import {
  accountCategoriesExamplesQuery,
  accountCategoriesQuery,
  getPlaidLinkTokenQuery,
  LinkTokenResponse,
  reconnectPlaidSuccessMutation,
} from "../../../../../core/queries/accounts";

export interface Props {
  forItemID?: string;
}

export const PlaidUpdateLink: React.FC<Props> = (props: Props) => {
  const { data: tokenData } = useQuery<LinkTokenResponse>(
    getPlaidLinkTokenQuery,
    {
      variables: {
        itemID: props.forItemID,
      },
    }
  );

  const [reconnectSuccess] = useMutation(reconnectPlaidSuccessMutation, {
    onCompleted: (data) => {
      toast(
        <PylonToastBody
          title={`Your account has been reconnected`}
          body={`We'll continue to update your account with the latest information.`}
        />
      );
    },
    onError: (err) => {
      console.error("Failed to reconnect item through Plaid.", err);
    },
    refetchQueries: [
      { query: accountCategoriesQuery },
      { query: accountCategoriesExamplesQuery },
    ],
  });

  if (!tokenData) {
    return null;
  }

  return (
    <>
      <div
        className="reconnect-warning"
        onClick={(event) => {
          event?.stopPropagation();
          event?.preventDefault();
        }}
      >
        <PlaidLink
          token={tokenData.getLinkToken}
          onSuccess={() => {
            reconnectSuccess({
              variables: {
                itemID: props.forItemID,
              },
            });
          }}
          style={{
            border: "none",
            padding: "0",
            margin: "0",
            alignSelf: "center",
            height: "1.5rem",
            display: "flex",
            whiteSpace: "nowrap",
          }}
        >
          <OverlayTrigger
            placement="top"
            key="error-outline-top"
            overlay={
              <Tooltip show={true} id="error-outline">
                <strong>Click to reconnect</strong>
                <br />
                This institution requires that you login to this account again.
                Information about this account may be inaccurate until you do.
              </Tooltip>
            }
          >
            <div
              className="error-outline"
              title="You need to login to this account again. Information about this account may be inaccurate until you do."
            ></div>
          </OverlayTrigger>
        </PlaidLink>
      </div>
    </>
  );
};
