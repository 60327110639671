import * as React from "react";
import { Account, Colour } from "../../../../../core/models";
import { ConfirmationModal } from "components/core/modal";
import { Icon, IconAsset } from "components/core/icons";
import { SessionContext } from "../../../../contexts/session-context-provider";
import { ShareTray } from "../../components/share-tray";
import { Text, TextStyle, TextType } from "components/core/text";

interface Props {
  account: Account;
}

export const ShareAccountQuickAction: React.FC<Props> = ({ account }) => {
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const { userID } = React.useContext(SessionContext.context);
  const isSharedWithMe = account?.owner?.id !== userID;

  if (!account || isSharedWithMe) {
    return null;
  }

  return (
    <>
      <ConfirmationModal
        children={
          <ShareTray asset={account} onClose={() => setShowShare(false)} />
        }
        showModal={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <div
        key="share"
        className="flex-row align-center"
        onClick={() => {
          setShowShare(true);
        }}
        style={{
          cursor: "pointer",
          gap: "0.5rem",
        }}
      >
        <Icon
          asset={IconAsset.Users}
          width="16px"
          height="16px"
          colour={Colour.Black}
        />
        <Text style={TextStyle.M15} type={TextType.Div}>
          Share
        </Text>
      </div>
    </>
  );
};
