import { Colour } from "core/models";
import { DeleteCategoryOrRuleButton } from "components/features/dashboard/components/transactions/delete-category-or-rule-button";
import { RecurringExpenseType } from "core/queries/transactions";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMutation, useQuery } from "@apollo/client";
import "./manage-category-styles.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
import {
  DeleteTransactionSubscriptionRuleMutation,
  DeleteTransactionSubscriptionRuleMutationInput,
  GetTransactionSubscriptionRuleQuery,
  GetTransactionSubscriptionRulesResponse,
} from "core/queries/transaction-category";

export interface Props {}

export const ManageSubscriptionTray: React.FC<Props> = () => {
  const { data: rulesData } = useQuery<GetTransactionSubscriptionRulesResponse>(
    GetTransactionSubscriptionRuleQuery,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [deleteRule] = useMutation<
    {},
    DeleteTransactionSubscriptionRuleMutationInput
  >(DeleteTransactionSubscriptionRuleMutation, {
    refetchQueries: [
      {
        query: GetTransactionSubscriptionRuleQuery,
      },
    ],
  });

  const rules = rulesData?.transactionSubscriptionRules ?? [];

  return (
    <div>
      <div className="mt2" />

      <div className="mt1" />
      <div className="flex-column">
        <div className="flex-row between">
          <div className="flex-row">
            <Text style={TextStyle.M18} type={TextType.Div}>
              Subscription Rules
            </Text>
            <PylonTooltip
              size={TooltipIconSize.Small}
              text="When we receive new transactions, we'll check against the rules and update the transaction accordingly."
              spacingSide={SpacingSide.Left}
            />
          </div>
        </div>

        <div className="flex-column mt1">
          {rules.length === 0 && (
            <Text
              style={TextStyle.FieldLabel}
              type={TextType.Div}
              colour={Colour.Text03}
            >
              No subscription rules found.
            </Text>
          )}
          {rules.map((r) => {
            return (
              <div
                key={r.id}
                className="flex-row align-center between ellipsis manage-category-row"
                style={{
                  padding: "0.5rem",
                }}
              >
                <Text
                  style={TextStyle.FieldLabel}
                  type={TextType.Div}
                  colour={Colour.Text03}
                  whiteSpace={"pre-wrap"}
                >
                  When merchant is "{r.merchant}" and amount is "{r.amount}", it
                  is
                  <i>
                    {" "}
                    {r.recurringExpenseType ===
                    RecurringExpenseType.Subscription
                      ? ""
                      : "not "}
                  </i>
                  a recurring expense.
                </Text>

                <DeleteCategoryOrRuleButton
                  name={`rule for ${r.merchant}`}
                  onConfirm={() => {
                    deleteRule({
                      variables: {
                        id: r.id,
                      },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt4" />
    </div>
  );
};
