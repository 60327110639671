import React, { useState } from "react";
import { createViewLogMutation } from "core/queries/shared-data";
import { DisplayModal } from "components/core/modal/display";
import { DocumentData } from "components/features/dashboard/models/document";
import { DownloadDocumentButton } from "components/features/dashboard/components/document-download-button";
import { FileViewer } from "components/core/file-viewer";
import { LeafButton } from "components/core/leaf-button";
import { SessionContext } from "components/contexts/session-context-provider";
import { useMutation } from "@apollo/client";

export interface Props {
  document: DocumentData;
}

export const ViewDocumentButton: React.FC<Props> = ({ document }) => {
  const { userID } = React.useContext(SessionContext.context);
  const [sentLog, setSentLog] = React.useState(false);
  const [createViewLog] = useMutation(createViewLogMutation);
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <LeafButton
        onClick={() => {
          if (!sentLog && document?.owner?.id !== userID) {
            createViewLog({ variables: { assetID: document?.id } });
            setSentLog(true);
          }
          setShowModal(true);
        }}
      >
        View document
      </LeafButton>
      <DisplayModal
        height="fit-content"
        width="fit-content"
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <FileViewer
          fileURL={document.previewURL || ""}
          mimeType={document.mimeType}
          unsupportedComponent={<DownloadDocumentButton document={document} />}
        />
      </DisplayModal>
    </div>
  );
};
