import { AccountTransaction } from "core/queries/transactions";
import { gql } from "@apollo/client";

export interface TaxBaseInterface {
  id?: string;
  userID?: string;
  taxYear: number;
}

export interface TaxLiabilityInterface extends TaxBaseInterface {
  filingStatus?: string;
  earnedIncome?: string;
  spouseEarnedIncome?: string;
  totalExemptions?: string;
  contributions401K?: string;
  contributionsIRA?: string;
  spouseContributions401K?: string;
  spouseContributionsIRA?: string;
  capitalGainsShortTerm?: string;
  investmentCapitalGainsShortTerm?: string;
  capitalGainsLongTerm?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  county?: string;
}

export interface TaxWithholdingInterface extends TaxBaseInterface {
  federalIncomeTax?: string;
  stateIncomeTax?: string;
  localIncomeTax?: string;
  paymentPeriodsPerYear?: string;

  spouseFederalIncomeTax?: string;
  spouseStateIncomeTax?: string;
  spouseLocalIncomeTax?: string;
  spousePaymentPeriodsPerYear?: string;
}

export interface SetTaxItemizedDeductionResponse {
  setTaxItemizedDeduction: TaxItemizedDeductionInterface;
}

export enum TaxIncomeEventType {
  Bonus = "Bonus",
  Commission = "Commission",
}

export interface TaxIncomeEventInterface extends TaxBaseInterface {
  incomeEventType: TaxIncomeEventType;

  amount?: string;
  federalWithholding?: string;
  stateWithholding?: string;
  localWithholding?: string;

  spouseAmount?: string;
  spouseFederalWithholding?: string;
  spouseStateWithholding?: string;
  spouseLocalWithholding?: string;
}

export interface TaxItemizedDeductionInterface extends TaxBaseInterface {
  type: string;
  name: string;
  amount: string;
  transaction: AccountTransaction;
}

export const LIABILITY_FIELDS = gql`
  fragment LiabilityFields on TaxLiability {
    id
    userID
    taxYear

    filingStatus
    earnedIncome
    spouseEarnedIncome
    totalExemptions
    contributions401K
    contributionsIRA
    spouseContributions401K
    spouseContributionsIRA
    capitalGainsShortTerm
    capitalGainsLongTerm
    investmentCapitalGainsShortTerm
    address1
    address2
    city
    state
    zip
    county
  }
`;

export const WITHHOLDING_FIELDS = gql`
  fragment WithholdingFields on TaxWithholding {
    id
    userID
    taxYear

    federalIncomeTax
    stateIncomeTax
    localIncomeTax
    paymentPeriodsPerYear

    spouseFederalIncomeTax
    spouseStateIncomeTax
    spouseLocalIncomeTax
    spousePaymentPeriodsPerYear
  }
`;

export const INCOME_EVENT_FIELDS = gql`
  fragment IncomeEventFields on TaxIncomeEvent {
    id
    userID
    taxYear

    incomeEventType

    amount
    federalWithholding
    stateWithholding
    localWithholding

    spouseAmount
    spouseFederalWithholding
    spouseStateWithholding
    spouseLocalWithholding
  }
`;

export const ITEMIZED_DEDUCTION_FIELDS = gql`
  fragment ItemizedDeductionFields on TaxItemizedDeduction {
    id
    userID
    taxYear

    type
    name
    amount
    transaction {
      name
      merchantName
      amount
      transactionID
      account {
        institutionName
        official_name
        mask
      }
    }
  }
`;

export const SET_TAX_LIABILITY = gql`
  ${LIABILITY_FIELDS}
  mutation SetTaxLiability($input: TaxLiabilityInput!) {
    setTaxLiability(input: $input) {
      ...LiabilityFields
    }
  }
`;

export const GET_TAX_LIABILITY = gql`
  ${LIABILITY_FIELDS}
  query GetTaxLiability($taxYear: Int!) {
    taxLiability(taxYear: $taxYear) {
      ...LiabilityFields
    }
  }
`;

export const SET_TAX_WITHHOLDING = gql`
  ${WITHHOLDING_FIELDS}
  mutation SetTaxWithholding($input: TaxWithholdingInput!) {
    setTaxWithholding(input: $input) {
      ...WithholdingFields
    }
  }
`;

export const GET_TAX_WITHHOLDING = gql`
  ${WITHHOLDING_FIELDS}
  query GetTaxWithholing($taxYear: Int!) {
    taxWithholding(taxYear: $taxYear) {
      ...WithholdingFields
    }
  }
`;

export const GET_TAX_INCOME_EVENTS = gql`
  ${INCOME_EVENT_FIELDS}
  query GetTaxIncomeEvents($taxYear: Int!) {
    taxIncomeEvents(taxYear: $taxYear) {
      ...IncomeEventFields
    }
  }
`;

export const SET_TAX_INCOME_EVENT = gql`
  ${INCOME_EVENT_FIELDS}
  mutation SetIncomeTaxEvent($input: TaxIncomeEventInput!) {
    setTaxIncomeEvent(input: $input) {
      ...IncomeEventFields
    }
  }
`;

export const REMOVE_TAX_INCOME_EVENT = gql`
  ${INCOME_EVENT_FIELDS}
  mutation RemoveIncomeTaxEvent($id: String) {
    removeTaxIncomeEvent(id: $id) {
      ...IncomeEventFields
    }
  }
`;

export const GET_TAX_ITEMIZED_DEDUCTIONS = gql`
  ${ITEMIZED_DEDUCTION_FIELDS}
  query FetchTaxItemizedDeductions {
    taxItemizedDeductions(taxYear: 2020) {
      ...ItemizedDeductionFields
    }
  }
`;

export const SET_TAX_ITEMIZED_DEDUCTION = gql`
  ${ITEMIZED_DEDUCTION_FIELDS}
  mutation SetTaxItemizedDeduction($input: TaxItemizedDeductionInput!) {
    setTaxItemizedDeduction(input: $input) {
      ...ItemizedDeductionFields
    }
  }
`;

export const REMOVE_TAX_ITEMIZED_DEDUCTION = gql`
  ${ITEMIZED_DEDUCTION_FIELDS}
  mutation RemoveTaxItemizedDeduction($id: String) {
    removeTaxItemizedDeduction(id: $id) {
      ...ItemizedDeductionFields
    }
  }
`;
