import * as React from "react";
import { Colour } from "core/models";
import { ItemizedDeductionType } from "@pylon/taxes";
import { LeafButton, LeafButtonColour } from "components/core/leaf-button";
import { TaxContext } from "components/contexts/tax-context";
import { TaxPageSection } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section";
import { TaxSectionItemizedDeductionRow } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/deduction-itemized-row";
import { TextD, TextStyle } from "components/core/text";
import { toMoneyString } from "core/utils";
import "./deductions-itemized.css";

export const TaxSectionItemizedDeductions: React.FC = () => {
  const { itemizedDeductions, saveItemizedDeduction, federalDeductions } =
    React.useContext(TaxContext);

  return (
    <div className="deductions-itemized">
      <TaxPageSection
        localStorageKey="taxes.itemizeddeductions"
        headerLeft={
          <>
            <div>Itemized Deductions</div>
          </>
        }
        headerRight={
          <div className="table-cell">{toMoneyString(federalDeductions())}</div>
        }
        bodyLeft={
          <>
            Provide any expenses or losses that are tax deductible.
            <br />
            <br />
            Note that your itemized deductions will only be used if their total
            is less than the allowed standard deduction. Also, for many itemized
            deduction categories, there is a limit on the amount that can be
            deducted.
          </>
        }
        bodyRight={
          <>
            {itemizedDeductions &&
              itemizedDeductions.length != null &&
              itemizedDeductions?.map((itemizedDeduction) => {
                return (
                  <TaxSectionItemizedDeductionRow
                    key={itemizedDeduction.id}
                    itemizedDeduction={itemizedDeduction}
                  />
                );
              })}

            <div className="mt1">
              <LeafButton
                buttonColour={LeafButtonColour.Transparent}
                onClick={() => {
                  saveItemizedDeduction(
                    0,
                    ItemizedDeductionType.MedicalAndDentalExpenses.toString(),
                    ""
                  );
                }}
              >
                <TextD colour={Colour.Blue01} style={TextStyle.M15SM}>
                  Add Itemized Deduction
                </TextD>
              </LeafButton>
            </div>
          </>
        }
      />
    </div>
  );
};
