import _ from "lodash";
import { AccountTransaction } from "core/queries/transactions";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { AccountTransactionTable } from "components/features/dashboard/components/transactions/transactions-table";
import { CashflowStatementPDF } from "components/features/dashboard/components/cashflow-statements/pdf-statement";
import { CategorizedTransactions } from "components/features/dashboard/components/cashflow-statements/models";
import { Colour } from "core/models";
import { format, parse } from "date-fns";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { Text, TextStyle, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useContext, useMemo } from "react";
import "./styles.css";

interface GroupedTransactions {
  total: number;
  transactions: AccountTransaction[];
}

export const CashflowStatement: React.FC = () => {
  const { month, transactionData, transactionDataLoading } = useContext(
    AccountTransactionContext
  );

  const transactions =
    transactionData?.accountTransactions?.transactions
      ?.filter((tx) => !tx.excludeFromCashflow)
      ?.filter((tx) => !tx.pending) ?? [];

  const categorizedIncomeTotalMap: Record<
    string,
    Pick<CategorizedTransactions, "total" | "transactions">
  > = {};
  const categorizedExpenseTotalMap: Record<
    string,
    Pick<CategorizedTransactions, "total" | "transactions">
  > = {};

  let incomeTotal = 0;
  let expenseTotal = 0;
  transactions.forEach((tx) => {
    const amt = tx.amount * -1;
    if (amt > 0) {
      updateRecord(categorizedIncomeTotalMap, tx, amt);
      incomeTotal += amt;
    } else {
      updateRecord(categorizedExpenseTotalMap, tx, amt);
      expenseTotal += amt;
    }
  });

  const incomeTransactions: CategorizedTransactions[] = Object.keys(
    categorizedIncomeTotalMap
  )
    .map((k) => {
      const v = categorizedIncomeTotalMap[k];
      return {
        category: k,
        total: v.total,
        transactions: v.transactions,
      };
    })
    .sort((a, b) => {
      return b.total - a.total;
    });

  const incomeRows = incomeTransactions.map((obj, i) => {
    return (
      <CashFlowRow
        key={obj.category}
        i={i}
        category={obj.category}
        tx={obj.transactions}
        value={obj.total}
      />
    );
  });

  const expenseTransactions: CategorizedTransactions[] = Object.keys(
    categorizedExpenseTotalMap
  )
    .map((k) => {
      const v = categorizedExpenseTotalMap[k];
      return {
        category: k,
        total: v.total,
        transactions: v.transactions,
      };
    })
    .sort((a, b) => {
      return a.total - b.total;
    });

  const expenseRows = expenseTransactions.map((obj, i) => {
    return (
      <CashFlowRow
        key={obj.category}
        i={i}
        category={obj.category}
        tx={obj.transactions}
        value={obj.total}
      />
    );
  });

  const selectedMonth = useMemo(() => {
    return parse(month, "yyyy-MM", new Date());
  }, [month]);

  const pdfDocument = useMemo(() => {
    return (
      <CashflowStatementPDF
        date={selectedMonth}
        incomes={incomeTransactions}
        expenses={expenseTransactions}
        incomeTotal={incomeTotal}
        expenseTotal={expenseTotal}
      />
    );
  }, [
    expenseTotal,
    expenseTransactions,
    incomeTotal,
    incomeTransactions,
    selectedMonth,
  ]);

  const downloadButton = useMemo(() => {
    if (transactionDataLoading) {
      return (
        <div
          style={{
            color: "var(--gray-30)",
            cursor: "not-allowed",
          }}
        >
          Download PDF
        </div>
      );
    }

    const month = format(selectedMonth, "MMMM-yyyy");
    return (
      <PDFDownloadLink
        key={month}
        document={pdfDocument}
        fileName={`cashflow-${month}.pdf`}
      >
        Download PDF
      </PDFDownloadLink>
    );
  }, [pdfDocument, selectedMonth, transactionDataLoading]);

  return (
    <div>
      <div
        className="flex-row"
        style={{
          justifyContent: "flex-end",
        }}
      >
        {downloadButton}
      </div>
      <div className="mt1" />

      <div
        style={{
          backgroundColor: "#EEEEEE",
          padding: "0.5rem",
        }}
      >
        <Text style={TextStyle.M18} type={TextType.Div}>
          Income
        </Text>
      </div>
      <div>{incomeRows}</div>
      <div
        className="flex-row between"
        style={{
          padding: "0rem 0.5rem",
        }}
      >
        <div
          style={{
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <Text style={TextStyle.L14} type={TextType.Div}>
            Total Income
          </Text>
        </div>
        <div
          style={{
            borderTop: "1px solid",
          }}
        >
          {colouredNumber(incomeTotal)}
        </div>
      </div>
      <div className="mt1" />
      <div
        style={{
          backgroundColor: "#EEEEEE",
          padding: "0.5rem",
        }}
      >
        <Text style={TextStyle.M18} type={TextType.Div}>
          Expense
        </Text>
      </div>
      <div>{expenseRows}</div>
      <div
        className="flex-row between"
        style={{
          margin: "0 0.5rem",
        }}
      >
        <div
          style={{
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          <Text style={TextStyle.L14} type={TextType.Div}>
            Total Expense
          </Text>
        </div>
        <div
          style={{
            borderTop: "1px solid",
          }}
        >
          {colouredNumber(expenseTotal)}
        </div>
      </div>
      <div className=" mt1" />
      <div
        className="flex-row between"
        style={{
          borderTop: "1px solid",
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          paddingRight: "0.5rem",
        }}
      >
        <div>
          <Text style={TextStyle.M18} type={TextType.Div} weight="bold">
            Net Cash Flow
          </Text>
        </div>
        {colouredNumber(incomeTotal + expenseTotal)}
      </div>

      <div className="mt3" />
    </div>
  );
};

const updateRecord = (
  record: Record<string, GroupedTransactions>,
  tx: AccountTransaction,
  amt: number
) => {
  const k = tx.category;
  if (_.isNil(record[k])) {
    record[k] = {
      total: 0,
      transactions: [],
    };
  }

  record[k].total += amt;
  record[k].transactions.push(tx);
};

const colouredNumber = (n: number) => {
  return (
    <div
      style={{
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem",
      }}
    >
      <Text
        type={TextType.Div}
        style={TextStyle.L14}
        colour={n < 0 ? Colour.Red01 : Colour.Green01}
      >
        {toMoneyString(n)}
      </Text>
    </div>
  );
};

const CashFlowRow: React.FC<{
  i: number;
  category: string;
  tx: AccountTransaction[];
  value: number;
}> = ({ i, category, tx, value }) => {
  const { pushTray } = useContext(SideTrayContext);
  return (
    <div
      className="cashflow-statement-row"
      onClick={() => {
        pushTray({
          header: <SimpleSideTrayHeader text={category} />,
          children: <AccountTransactionTable transactions={tx} showTotal />,
        });
      }}
    >
      <div
        style={{
          paddingTop: "0.25rem",
          paddingBottom: "0.25rem",
        }}
      >
        {category}
      </div>
      {colouredNumber(value)}
    </div>
  );
};
