import { Colour } from "core/models";
import { CurrencyInputProps } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { Input, Props as InputProps } from "components/core/input";
import { PylonCurrencyInput } from "components/core/currency-input";
import { ReactElement } from "react";
import { TextD, TextStyle } from "components/core/text";
import "./styles.css";

export interface Props {
  id: string;
  value: number | string | undefined;
  onChange: (v: string | undefined) => void;

  label?: string;
  placeholder?: string;
  className?: string;
  type?: "money" | "percentage" | "number" | "string";
  onBlur?: () => void;

  currencyOverrides?: CurrencyInputProps;
  stringOverrides?: InputProps;
}

export const ModalInput: React.FC<Props> = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  className,
  type = "string",
  onBlur,
  currencyOverrides,
  stringOverrides,
}) => {
  let inputComponent: ReactElement;
  if (type === "string") {
    inputComponent = (
      <Input
        id={id}
        value={value}
        onChange={(v) => onChange(v?.currentTarget?.value)}
        placeholder={placeholder ? placeholder : undefined}
        className={`${className ? className : ""}`}
        onBlur={onBlur}
        {...stringOverrides}
      />
    );
  } else {
    inputComponent = (
      <PylonCurrencyInput
        id={id}
        value={value}
        onValueChange={(v) => onChange(v)}
        prefix={type === "money" ? "$" : undefined}
        suffix={type === "percentage" ? "%" : undefined}
        placeholder={placeholder ? placeholder : undefined}
        className={`${className ? className : ""}`}
        onBlur={onBlur}
        allowNegativeValue={false}
        {...currencyOverrides}
      />
    );
  }

  if (!label) {
    return inputComponent;
  }

  return (
    <div className="flex-column gap-half">
      <TextD style={TextStyle.M15} colour={Colour.Black}>
        {label}
      </TextD>
      {inputComponent}
    </div>
  );
};
