import { Checkbox } from "components/core/checkbox";
import { UserData } from "components/features/dashboard/models/sharing";
import * as React from "react";
import "./index.css";

interface Props {
  recipient: UserData;
  bottomBorder?: boolean;
  onClick?: (recipient: UserData) => void;
  selected?: boolean;
}
export const RecipientDropdownItem: React.FC<Props> = ({
  recipient,
  bottomBorder,
  onClick,
  selected = false,
}) => {
  const renderName = () => {
    const hasName = recipient.firstName && recipient.lastName?.length;
    const recip = hasName
      ? `${recipient.firstName} ${recipient.lastName}`
      : `${recipient.email}`;
    const id = hasName
      ? `${recipient.firstName?.charAt(0).toUpperCase()}${recipient.lastName
          ?.charAt(0)
          .toUpperCase()}`
      : `${recipient.email.charAt(0).toUpperCase()}`;
    return (
      <div
        className="flex-row between"
        style={{
          display: "flex",
          height: "4.125rem",
          alignItems: "center",
          paddingLeft: "1rem",
          borderBottom: bottomBorder ? "1px solid var(--gray-10)" : "none",
        }}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();
          if (onClick) {
            onClick(recipient);
          }
        }}
      >
        <div>
          <Checkbox
            id="array-credit-accept"
            style={{
              width: "1rem",
              marginRight: "1rem",
            }}
            checked={selected}
            label={recip}
            onChange={() => {}}
          />
        </div>
      </div>
    );
  };

  return <div>{renderName()}</div>;
};
