import React from "react";
import { Colour } from "core/models";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import "./styles.css";

export interface Props {
  password: string;
  className?: string;
}

const UpperCaseRegex = /(?=.*[A-Z])/;
const LowerCaseRegex = /(?=.*[a-z].*)/;
const DigitRegex = /(?=.*\d)/;

export const PylonPasswordChecker: React.FC<Props> = ({
  password,
  className,
}) => {
  return (
    <div className={className}>
      {/* <div className={`pylon-password-strength-checker  `}>
        <PasswordStrengthBar
          password={password}
          minLength={8}
          barColors={[
            "var(--red-01)",
            "var(--orange-01)",
            "var(--yellow-01)",
            "var(--green-01)",
            "var(--green-01)",
          ]}
          style={{
            borderRadius: "40px",
          }}
        ></PasswordStrengthBar>
      </div> */}
      <Text type={TextType.Div} style={TextStyle.Hint}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            margin: "1rem 0",
            color: "var(--text-03)",
            fontWeight: 600,
            fontSize: "0.75rem",
          }}
        >
          <Text
            colour={password.length >= 8 ? Colour.Green01 : undefined}
            type={TextType.Div}
            format={TextFormat.UpperCase}
          >
            8 characters
          </Text>
          <Text type={TextType.Div} format={TextFormat.UpperCase}>
            |
          </Text>
          <Text
            colour={DigitRegex.test(password) ? Colour.Green01 : undefined}
            type={TextType.Div}
            format={TextFormat.UpperCase}
          >
            1 number
          </Text>
          <Text type={TextType.Div} format={TextFormat.UpperCase}>
            |
          </Text>
          <Text
            colour={UpperCaseRegex.test(password) ? Colour.Green01 : undefined}
            type={TextType.Div}
            format={TextFormat.UpperCase}
          >
            1 uppercase
          </Text>
          <Text type={TextType.Div} format={TextFormat.UpperCase}>
            |
          </Text>
          <Text
            colour={LowerCaseRegex.test(password) ? Colour.Green01 : undefined}
            type={TextType.Div}
            format={TextFormat.UpperCase}
          >
            1 lowercase
          </Text>
        </div>
      </Text>
    </div>
  );
};
