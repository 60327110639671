import _ from "lodash";
import { MutationWrapper } from "core/queries/mutation";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  FetchUserPreferenceSettingsInput,
  FetchUserPreferenceSettingsResponse,
  FETCH_USER_PREFERENCES,
  SaveUserPreferenceSettingsInput,
  SaveUserPreferenceSettingsResponse,
  SAVE_USER_PREFERENCE,
  UserPreferenceSettingEnum,
} from "core/queries/user-preference-settings";

export const usePreferenceSettingBoolean = (
  defaultValue: boolean,
  preference: UserPreferenceSettingEnum,
  valueDuringLoading?: boolean
): [boolean, (b: boolean) => void] => {
  const [val, _setVal] = useState(valueDuringLoading ?? defaultValue);

  useQuery<
    FetchUserPreferenceSettingsResponse,
    FetchUserPreferenceSettingsInput
  >(FETCH_USER_PREFERENCES, {
    fetchPolicy: "network-only",
    variables: {
      preferences: [preference],
    },
    onCompleted: (d) => {
      const v = d.preferenceSettings?.find((x) => x.preference === preference);
      if (v?.setting) {
        _setVal("true" === v?.setting);
      } else if (!_.isNil(valueDuringLoading)) {
        _setVal(defaultValue);
      }
    },
  });

  const [updateSettingsMutation] = useMutation<
    SaveUserPreferenceSettingsResponse,
    MutationWrapper<SaveUserPreferenceSettingsInput>
  >(SAVE_USER_PREFERENCE);

  const setVal = (v: boolean) => {
    _setVal(v);
    updateSettingsMutation({
      variables: {
        input: {
          preference: preference,
          setting: v.toString(),
        },
      },
    });
  };

  return [val, setVal];
};

export const usePreferenceSettingBooleans = (
  defaultValue: boolean,
  preferences: UserPreferenceSettingEnum[],
  valueDuringLoading?: boolean
): [
  Readonly<Record<string, boolean>>,
  (pref: UserPreferenceSettingEnum, b: boolean) => void
] => {
  const initalVal: Record<string, boolean> = {};
  preferences.forEach((p) => {
    initalVal[p] = valueDuringLoading ?? defaultValue;
  });

  const [val, _setVal] = useState(initalVal);

  const roVal: Readonly<Record<string, boolean>> = val;

  useQuery<
    FetchUserPreferenceSettingsResponse,
    FetchUserPreferenceSettingsInput
  >(FETCH_USER_PREFERENCES, {
    fetchPolicy: "network-only",
    variables: {
      preferences: preferences,
    },
    onCompleted: (d) => {
      const loadedVals: Record<string, boolean> = {};
      preferences.forEach((p) => {
        loadedVals[p] = defaultValue;
      });

      d.preferenceSettings?.forEach((x) => {
        loadedVals[x.preference] = "true" === x?.setting;
      });

      _setVal(loadedVals);
    },
  });

  const [updateSettingsMutation] = useMutation<
    SaveUserPreferenceSettingsResponse,
    MutationWrapper<SaveUserPreferenceSettingsInput>
  >(SAVE_USER_PREFERENCE);

  const setVal = (pref: UserPreferenceSettingEnum, v: boolean) => {
    const newVal: Record<string, boolean> = {};
    Object.assign(newVal, val);
    newVal[pref] = v;
    _setVal(newVal);

    updateSettingsMutation({
      variables: {
        input: {
          preference: pref,
          setting: v.toString(),
        },
      },
    });
  };

  return [roVal, setVal];
};

export function usePreferenceSettingString(
  defaultValue: string,
  preference: UserPreferenceSettingEnum,
  valueDuringLoading?: string
): [string, (b: string) => void] {
  const [val, _setVal] = useState(valueDuringLoading ?? defaultValue);

  useQuery<
    FetchUserPreferenceSettingsResponse,
    FetchUserPreferenceSettingsInput
  >(FETCH_USER_PREFERENCES, {
    fetchPolicy: "network-only",
    variables: {
      preferences: [preference],
    },
    onCompleted: (d) => {
      const v = d.preferenceSettings?.find((x) => x.preference === preference);
      if (v?.setting) {
        _setVal(v.setting);
      } else if (!_.isNil(valueDuringLoading)) {
        _setVal(defaultValue);
      }
    },
  });

  const [updateSettingsMutation] = useMutation<
    SaveUserPreferenceSettingsResponse,
    MutationWrapper<SaveUserPreferenceSettingsInput>
  >(SAVE_USER_PREFERENCE);

  const setVal = (v: string) => {
    _setVal(v);
    updateSettingsMutation({
      variables: {
        input: {
          preference: preference,
          setting: v,
        },
      },
    });
  };

  return [val, setVal];
}

export function usePreferenceSettingStringEnum<T>(
  defaultValue: T,
  preference: UserPreferenceSettingEnum,
  valueDuringLoading?: T
): [T, (b: T) => void] {
  const [val, _setVal] = useState(valueDuringLoading ?? defaultValue);

  useQuery<
    FetchUserPreferenceSettingsResponse,
    FetchUserPreferenceSettingsInput
  >(FETCH_USER_PREFERENCES, {
    fetchPolicy: "network-only",
    variables: {
      preferences: [preference],
    },
    onCompleted: (d) => {
      const v = d.preferenceSettings?.find((x) => x.preference === preference);
      if (v?.setting) {
        _setVal(v.setting as any as T);
      } else if (!_.isNil(valueDuringLoading)) {
        _setVal(defaultValue);
      }
    },
  });

  const [updateSettingsMutation] = useMutation<
    SaveUserPreferenceSettingsResponse,
    MutationWrapper<SaveUserPreferenceSettingsInput>
  >(SAVE_USER_PREFERENCE);

  const setVal = (v: T) => {
    _setVal(v);
    updateSettingsMutation({
      variables: {
        input: {
          preference: preference,
          setting: `${v}`,
        },
      },
    });
  };

  return [val, setVal];
}
