import * as React from "react";
import { Colour } from "core/models";
import { CreditModal } from "components/features/dashboard/components/credit-modal";
import { DashboardPages } from "components/features/dashboard/pages";
import { GlobalFilteringContext } from "components/contexts/global-filtering-context";
import { SessionContext } from "components/contexts/session-context-provider";
import { Text, TextD, TextStyle } from "components/core/text";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import {
  ChangeInValueDirection,
  ChangeInValueLabel,
} from "components/features/dashboard/pages/summary/change-in-value-label";
import {
  CreditResponseItem,
  FetchDashboardCredit,
  FetchDashboardCreditResponse,
} from "core/queries/dashboard";

export const CreditSummaryCard: React.FC = () => {
  const history = useHistory();
  const { userID } = useContext(SessionContext.context);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { ownerIDs, startDate, endDate } = useContext(GlobalFilteringContext);

  const { data, refetch } = useQuery<FetchDashboardCreditResponse>(
    FetchDashboardCredit,
    {
      variables: {
        creditInput: {
          userIDs: ownerIDs,
          startDate: startDate,
          endDate: endDate,
        },
      },
      skip: ownerIDs.length < 1,
      fetchPolicy: "cache-and-network",
    }
  );

  const creditScores = data?.credit || [];
  const getCreditScoreDirection = (diff: number) => {
    if (diff === 0) {
      return undefined;
    }
    if (diff < 0) {
      return ChangeInValueDirection.Down;
    }
    return ChangeInValueDirection.Up;
  };

  const renderNotLinkedState = (score: CreditResponseItem) => {
    return (
      <div className="credit-score-block" key={score?.owner?.id}>
        <Text style={TextStyle.M35R} colour={Colour.Gray400}>
          750
        </Text>
        <ChangeInValueLabel example />
        <div className="mt1">
          <TextD style={TextStyle.M11} colour={Colour.Black}>
            {score.owner?.id === userID ? "Me" : score.owner?.firstName}
          </TextD>
          {score?.owner?.id === userID && (
            <span
              className={"empty-state-link"}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowModal(true);
              }}
            >
              <TextD style={TextStyle.M11} colour={Colour.Blue500}>
                Connect
              </TextD>
            </span>
          )}
        </div>
      </div>
    );
  };

  const render = (score?: CreditResponseItem) => {
    return (
      <div className="credit-score-block" key={score?.owner?.id}>
        <Text style={TextStyle.M35R} colour={Colour.Black}>
          {score?.score === NO_CREDIT_THIS_MONTH ? "N/A" : score?.score}
        </Text>
        {score?.change !== 0 && ( // Skip empty scores
          <ChangeInValueLabel
            direction={getCreditScoreDirection(score?.change ?? 0)}
          >
            {Math.abs(score?.change ?? 0)}
          </ChangeInValueLabel>
        )}

        <div className="mt1">
          <TextD style={TextStyle.M11} colour={Colour.Black}>
            {score?.owner?.id === userID ? "Me" : score?.owner?.firstName}
          </TextD>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        onClick={() => {
          history.push(DashboardPages.CreditHistory);
        }}
        id="dashboard-credit-box"
      >
        <div>
          <Text style={TextStyle.M17SB} colour={Colour.Black}>
            Credit Score
          </Text>
          <div className="flex-row between" style={{ marginTop: "16px" }}>
            {creditScores?.slice(0, 2).map((score) => {
              if (score.score === CREDIT_NOT_LINKED) {
                return renderNotLinkedState(score);
              }
              return render(score);
            })}
          </div>
        </div>
      </div>
      <CreditModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onComplete={refetch}
      />
    </>
  );
};

const CREDIT_NOT_LINKED = -1;
const NO_CREDIT_THIS_MONTH = -2;
