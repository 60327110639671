import * as React from "react";
import _ from "lodash";
import Chart from "react-apexcharts";
import { Account } from "core/models";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { accountShortName } from "core/models/account";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { ApexOptions } from "apexcharts";
import { formatDateToUTCMMDDYY } from "components/features/dashboard/models/account-transaction";
import { Icon, IconAsset } from "components/core/icons";
import { ManageCategoryButton } from "components/features/dashboard/components/transactions/manage-category-button";
import { toMoneyString } from "core/utils";
import { TransactionMonthSelector } from "components/features/dashboard/components/transaction-month-selector";
import { usePreferenceSettingString } from "core/hooks/user-preference-setting";
import { UserPreferenceSettingEnum } from "core/queries/user-preference-settings";
import "./styles.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipWrapperDisplay,
} from "components/core/tooltip";
import {
  AccountTransaction,
  CashflowCategorizedSummary,
} from "core/queries/transactions";

interface ChartState {
  options: ApexOptions;
  series: Array<any>;
}

enum FilterType {
  Merchant = "merchant",
  Categories = "categories",
  ExpenseTier = "expenseTier",
}

enum ExpenseTiers {
  ThousandPlus = "$1,000 or Above",
  FiveHundredsPlus = "$500 - $999",
  TwoHundredFiftyPlus = "$250 - $499",
  HundredPlus = "$100 - $249",
  BelowHundred = "Less Than $100",
}

const ExpenseTierProperties: Record<
  ExpenseTiers,
  {
    index: number;
    testFunc: (tx: AccountTransaction) => boolean;
  }
> = {
  [ExpenseTiers.ThousandPlus]: {
    index: 0,
    testFunc: (tx) => {
      return tx.amount >= 1000;
    },
  },

  [ExpenseTiers.FiveHundredsPlus]: {
    index: 1,
    testFunc: (tx) => {
      return tx.amount >= 500 && tx.amount <= 999;
    },
  },
  [ExpenseTiers.TwoHundredFiftyPlus]: {
    index: 2,
    testFunc: (tx) => {
      return tx.amount >= 250 && tx.amount <= 499;
    },
  },
  [ExpenseTiers.HundredPlus]: {
    index: 3,
    testFunc: (tx) => {
      return tx.amount >= 100 && tx.amount <= 249;
    },
  },
  [ExpenseTiers.BelowHundred]: {
    index: 4,
    testFunc: (tx) => {
      return tx.amount <= 100;
    },
  },
};

export interface Props {
  showMonthSelector?: boolean;
}
export const ChartExpenses: React.FC<Props> = ({
  showMonthSelector = false,
}) => {
  const { transactionData, transactionDataLoading, accountID } =
    React.useContext(AccountTransactionContext);

  const [filterType, setFilterType] = usePreferenceSettingString(
    FilterType.Merchant,
    UserPreferenceSettingEnum.CashflowBreakdownSelectedType
  );

  const [filterValue, setFilterValue] = React.useState<string | undefined>(
    undefined
  );

  const accountLookup: { [key: string]: Account } = {};
  transactionData?.accountTransactions?.accounts?.forEach((account) => {
    accountLookup[account?.account_id] = account;
  });

  const allTx = React.useMemo(() => {
    return (
      transactionData?.accountTransactions?.transactions
        .filter((tx) => !tx.pending)
        .filter((tx) => {
          if (accountID) {
            return tx.accountID === accountID;
          }
          return true;
        })
        .filter(expenseTransactionsFilter) ?? []
    );
  }, [transactionData, accountID]);

  const categorizedExpenses = React.useMemo(() => {
    let categories: Record<string, number> = {};

    allTx.forEach((tx) => {
      let key: string = "";
      switch (filterType) {
        case FilterType.Merchant: {
          key = getMerchantName(tx);
          break;
        }
        case FilterType.Categories: {
          key = tx.category;
          break;
        }
        case FilterType.ExpenseTier: {
          Object.keys(ExpenseTierProperties).forEach((k) => {
            if (ExpenseTierProperties[k as ExpenseTiers]?.testFunc(tx)) {
              key = k;
            }
          });
          break;
        }
      }

      if (!categories[key]) {
        categories[key] = 0;
      }
      categories[key] += tx.amount;
    });

    let resp: CashflowCategorizedSummary[] = [];
    Object.entries(categories).forEach(([k, v]) => {
      resp.push({
        category: k,
        total: v,
      });
    });

    return {
      entries: resp,
    };
  }, [filterType, allTx]);

  const expensesState = React.useMemo<ChartState>(() => {
    return {
      series: categorizedExpenses.entries.map((ce) => {
        return ce.total;
      }),

      options: {
        chart: {
          width: "100%",
          type: "donut",
          events: {
            dataPointSelection: (e, chart, opts) => {
              if (opts?.dataPointIndex == null) {
                setFilterValue(undefined);
                return true;
              }

              setFilterValue(
                categorizedExpenses.entries[opts?.dataPointIndex].category
              );
              return true;
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  show: true,
                  formatter: (v: any) => {
                    return toMoneyString(parseFloat(v));
                  },
                },
                total: {
                  show: true,
                  label: "Total",

                  formatter: (v: TotalFormatterVal) => {
                    return toMoneyString(
                      v?.globals?.series?.reduce((a, b) => a + b, 0) ?? 0
                    );
                  },
                },
              },
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        labels: categorizedExpenses.entries.map((ce) => {
          return ce.category;
        }),
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    };
  }, [categorizedExpenses.entries]);

  React.useEffect(() => {
    setFilterValue(undefined);
    // Reset filter when data changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTx]);

  if (transactionData?.accountTransactions?.summary?.count === 0) {
    return null;
  }

  return (
    <div className="expenses" style={{}}>
      <div
        className="flex-row between align-center"
        style={{
          borderBottom: "1px solid var(--separator-02)",
          paddingBottom: "0.5rem",
        }}
      >
        <h6>Expenses</h6>
        <div>
          <ManageCategoryButton />
        </div>

        {showMonthSelector && <TransactionMonthSelector />}
      </div>
      {expensesState.series.length > 0 && (
        <div className="flex-row between mt2 mobile-flex-column expenses-chart-row">
          <div
            style={{
              display: "block",
            }}
          >
            {expensesState.series.length > 0 && (
              <Chart
                options={expensesState.options}
                series={expensesState.series}
                width="100%"
                type="donut"
              />
            )}
            {expensesState.series.length === 0 && <div>No Expenses</div>}
          </div>
          <div className="flex-column" style={{ height: "23rem" }}>
            {!filterValue && (
              <>
                <div className="expense-filter-title-wrapper">
                  <div
                    id="expense-breakdown-set-filter-merchants"
                    className={`expense-filter-selector ${
                      filterType === FilterType.Merchant ? "selected" : ""
                    }`}
                    onClick={() => {
                      setFilterType(FilterType.Merchant);
                    }}
                  >
                    Merchants
                  </div>
                  <div
                    id="expense-breakdown-set-filter-categories"
                    className={`expense-filter-selector ${
                      filterType === FilterType.Categories ? "selected" : ""
                    }`}
                    onClick={() => {
                      setFilterType(FilterType.Categories);
                    }}
                  >
                    Categories
                  </div>
                  <div
                    id="expense-breakdown-set-filter-expense-tiers"
                    className={`expense-filter-selector ${
                      filterType === FilterType.ExpenseTier ? "selected" : ""
                    }`}
                    onClick={() => {
                      setFilterType(FilterType.ExpenseTier);
                    }}
                  >
                    Expense Tiers
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    overflow: "auto",
                    height: "23rem",
                    marginTop: "2rem",
                    fontSize: "14px",
                  }}
                >
                  {(categorizedExpenses?.entries.slice() || [])
                    .sort((a, b) => {
                      switch (filterType) {
                        case FilterType.ExpenseTier:
                          return (
                            (ExpenseTierProperties[a.category as ExpenseTiers]
                              ?.index ?? 0) -
                            (ExpenseTierProperties[b.category as ExpenseTiers]
                              ?.index ?? 0)
                          );
                        case FilterType.Categories: // Fallthrough
                        case FilterType.Merchant: // Fallthrough
                        default:
                          return b.total - a.total;
                      }
                    })
                    .map((cats) => {
                      return (
                        <div
                          key={cats.category}
                          className={`flex-row between category-row expense-breakdown-category`}
                          style={{ width: "100%", cursor: "pointer" }}
                          onClick={() => {
                            setFilterValue(cats.category);
                          }}
                        >
                          <div className="blue-01">{cats.category}</div>
                          <div>{toMoneyString(cats.total)}</div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
            {filterValue && (
              <>
                <h6
                  style={{ fontSize: ".875rem" }}
                  className="flex-row between category-header"
                >
                  {filterValue}
                  <span
                    id="expense-breakdown-back-button"
                    style={{
                      cursor: "pointer",
                      fontWeight: "normal",
                      color: "var(--blue-01)",
                    }}
                    onClick={() => {
                      setFilterValue(undefined);
                    }}
                  >
                    All
                  </span>
                </h6>
                <div
                  style={{
                    width: "100%",
                    overflow: "auto",
                    height: "20rem",
                    marginTop: "1rem",
                  }}
                >
                  {allTx
                    .slice()
                    .filter(expenseTransactionsFilter)
                    .filter((tx) => {
                      switch (filterType) {
                        case FilterType.Merchant:
                          return getMerchantName(tx) === filterValue;
                        case FilterType.Categories:
                          return tx.category === filterValue;
                        case FilterType.ExpenseTier:
                          return !!ExpenseTierProperties[
                            filterValue as ExpenseTiers
                          ]?.testFunc(tx);
                      }
                      // In case new filter type is added but code isn't updated
                      return false;
                    })
                    .sort((a: AccountTransaction, b: AccountTransaction) => {
                      return b.amount - a.amount;
                    })
                    .map((tx: AccountTransaction) => {
                      return (
                        <div
                          key={tx.id}
                          className={`flex-row between category-row expense-breakdown-transaction`}
                          style={{ width: "100%", gap: "1rem" }}
                        >
                          {!accountID && (
                            <div style={{ width: "3rem" }}>
                              <PylonTooltip
                                text={accountShortName(
                                  accountLookup[tx.accountID]
                                )}
                                spacingSide={SpacingSide.None}
                                wrapperDisplay={TooltipWrapperDisplay.None}
                              >
                                <AccountIcon
                                  account={accountLookup[tx.accountID]}
                                  scale={0.75}
                                  backgroundColor="#ffffff"
                                />
                              </PylonTooltip>
                            </div>
                          )}
                          <div className="expense-chart-item-main-cell">
                            <PylonTooltip
                              text={tx.name || tx.merchantName}
                              spacingSide={SpacingSide.None}
                              wrapperDisplay={TooltipWrapperDisplay.None}
                            >
                              <div className="ellipsis">
                                {tx.name || tx.merchantName}
                              </div>
                            </PylonTooltip>
                            <div className="label-small">
                              {formatDateToUTCMMDDYY(new Date(tx.date))}
                            </div>
                          </div>
                          <div>{toMoneyString(tx.amount)}</div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {expensesState.series.length === 0 && (
        <div
          className="flex-column between"
          style={{ width: "100%", textAlign: "center", height: "10rem" }}
        >
          <div>
            <Icon asset={IconAsset.ExpensesEmpty} width="64px" height="64px" />
          </div>
          <div>
            {transactionDataLoading
              ? "We are loading your data..."
              : "There are no expenses in this period."}
          </div>
        </div>
      )}
    </div>
  );
};

const getMerchantName = (tx: AccountTransaction) => {
  return tx.merchantName ?? "Miscellaneous";
};

export const expenseTransactionsFilter = (tx: AccountTransaction): boolean => {
  return _.isFinite(tx.amount) && tx.amount > 0 && !tx.excludeFromCashflow;
};

interface TotalFormatterVal {
  globals: {
    series: number[];
  };
}
