import * as React from "react";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { Logo } from "components/core/logo";
import { OnboardingProps } from "components/features/onboarding/flows/utils";
import { useMobileLayout } from "core/hooks/responsive";

export const WelcomePage: React.FC<OnboardingProps> = ({
  state,
  optimisticUpdate,
  onNext,
}) => {
  const mobileLayout = useMobileLayout();

  return (
    <div className="onboarding-wrapper-inner">
      {mobileLayout && (
        <div style={{ padding: "4vh 4vw", color: "var(--text-01)" }}>
          <div>
            <h4 style={{ fontSize: "10vw", lineHeight: "10vw" }}>
              Welcome
              <br />
              to Pylon
              <span style={{ color: "var(--blue-01)" }}>!</span>
            </h4>
          </div>
          <div style={{ alignSelf: "center", width: "100%", marginTop: "3vh" }}>
            <Icon
              asset={IconAsset.OnboardingComputer}
              width="100%"
              height="30vh"
            />
          </div>
          <div
            style={{
              fontSize: "3vh",
              lineHeight: "4vh",
              fontWeight: "normal",
              marginTop: "3vh",
            }}
          >
            Pylon is a free online platform to{" "}
            <span style={{ color: "var(--blue-01)" }}>track</span>,{" "}
            <span style={{ color: "var(--blue-01)" }}>share</span>, and{" "}
            <span style={{ color: "var(--blue-01)" }}>plan</span> your finances
            in one place.
          </div>
          <div style={{ marginTop: "6vh" }}>
            <LeafButton
              onClick={() => {
                onNext?.();
              }}
            >
              Get Started
            </LeafButton>
          </div>
        </div>
      )}
      {!mobileLayout && (
        <>
          <div
            className="onboarding-top"
            style={{ textAlign: "left", paddingLeft: "2vh" }}
          >
            <div>
              <div style={{ textAlign: "left" }}>
                <Logo scale={2} />
              </div>
            </div>
          </div>
          <div className="onboarding-middle">
            <div
              style={{
                width: "40%",
                textAlign: "left",
                padding: "0",
                paddingLeft: "2vh",
              }}
            >
              <div>
                Welcome to Pylon
                <span style={{ color: "var(--blue-01)" }}>!</span>
              </div>
              <div
                style={{
                  fontSize: "3vh",
                  lineHeight: "4vh",
                  fontWeight: "normal",
                  marginTop: "4vh",
                }}
              >
                Pylon is a free online platform to{" "}
                <span style={{ color: "var(--blue-01)" }}>track</span>,{" "}
                <span style={{ color: "var(--blue-01)" }}>share</span>, and{" "}
                <span style={{ color: "var(--blue-01)" }}>plan</span> your
                finances in one place.
              </div>
              <div style={{ marginTop: "6vh" }}>
                <LeafButton
                  onClick={() => {
                    onNext?.();
                  }}
                >
                  Get Started
                </LeafButton>
              </div>
            </div>
            <div style={{ width: "60%" }}>
              <div style={{ alignSelf: "center" }}>
                <Icon
                  asset={IconAsset.OnboardingComputer}
                  width="40vw"
                  height="40vh"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
