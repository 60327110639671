import * as React from "react";
import { AuditLog } from "components/features/dashboard/models/sharing";
import { Colour } from "core/models";
import { Text, TextType } from "components/core/text";
import { timeSince, toMoneyString } from "core/utils";

interface Props {
  log?: AuditLog;
}
export const AuditLogRow: React.FC<Props> = ({ log }) => {
  const getUpdatedString = (actingUserName: string, auditLog: AuditLog) => {
    let action = "updated";

    let updateFrom = auditLog.updateFrom;
    let updateTo = auditLog.updateTo;

    if (updateFrom === undefined || updateFrom === null) {
      action = "added";
    }

    if (updateTo === undefined || updateTo === null) {
      action = "removed";
    }

    if (
      auditLog.updateField === "Balance" ||
      auditLog.updateField === "Limit"
    ) {
      updateFrom = updateFrom && toMoneyString(parseFloat(updateFrom));
      updateTo = updateTo && toMoneyString(parseFloat(updateTo));
    }

    return (
      <>
        {actingUserName} {action}{" "}
        <span style={{ fontWeight: 600 }}>{auditLog.updateField}</span>{" "}
        {auditLog.updateTo && auditLog.updateTo && (
          <span>
            {updateFrom && (
              <span>
                from <span style={{ fontStyle: "italic" }}>{updateFrom}</span>{" "}
              </span>
            )}
            {updateTo && (
              <span>
                to <span style={{ fontStyle: "italic" }}>{updateTo}</span>
              </span>
            )}
          </span>
        )}
      </>
    );
  };

  const getActingUserString = (auditLog: AuditLog) => {
    const actingUserName = auditLog.actingUser
      ? auditLog.actingUser.firstName || auditLog.actingUser.email
      : "System";
    const affectedUserName =
      auditLog.affectedUser?.firstName || auditLog.affectedUser?.email;

    let actionStr: string | React.ReactNode = "";
    switch (auditLog.actionType) {
      case "CREATED":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> created
          </>
        );
        break;
      case "INVITED":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> has
            invited <span style={{ fontWeight: 600 }}>{affectedUserName}</span>
          </>
        );
        break;
      case "SHARED":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> has shared
            with <span style={{ fontWeight: 600 }}>{affectedUserName}</span>
          </>
        );
        break;
      case "RESHARED":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> has
            reshared with{" "}
            <span style={{ fontWeight: 600 }}>{affectedUserName}</span>
          </>
        );
        break;
      case "REMOVED":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> removed
            access for{" "}
            <span style={{ fontWeight: 600 }}>{affectedUserName}</span>
          </>
        );
        break;
      case "VIEWED":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> viewed
          </>
        );
        break;
      case "UPDATED":
        actionStr = getUpdatedString(actingUserName, auditLog);
        break;
      case "DOWNLOADED":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> downloaded
          </>
        );
        break;
      case "TRANSACTION_ADD":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> added a{" "}
            <span style={{ fontWeight: 600 }}>transaction</span> for{" "}
            <span style={{ fontWeight: 600 }}>
              {toMoneyString(parseFloat(auditLog.updateTo || "0"))}
            </span>
          </>
        );
        break;
      case "TRANSACTION_EDIT":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> edited a{" "}
            <span style={{ fontWeight: 600 }}>transaction</span>
          </>
        );
        break;
      case "TRANSACTION_DELETE":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> removed a{" "}
            <span style={{ fontWeight: 600 }}>transaction</span> for{" "}
            <span style={{ fontWeight: 600 }}>
              {toMoneyString(parseFloat(auditLog.updateTo || "0"))}
            </span>
          </>
        );
        break;
      case "CONTACT_ADD":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> added{" "}
            <span style={{ fontWeight: 600 }}>{auditLog.updateTo || ""}</span>{" "}
            as a <span style={{ fontWeight: 600 }}>contact</span>
          </>
        );
        break;
      case "CONTACT_EDIT":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> updated{" "}
            <span style={{ fontWeight: 600 }}>contact information</span> for{" "}
            <span style={{ fontWeight: 600 }}>{auditLog.updateTo || ""}</span>{" "}
          </>
        );
        break;
      case "CONTACT_DELETE":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> removed{" "}
            <span style={{ fontWeight: 600 }}>{auditLog.updateTo || ""}</span>{" "}
            as a <span style={{ fontWeight: 600 }}>contact</span>
          </>
        );
        break;

      case "NOTE_ADD":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> added a{" "}
            <span style={{ fontWeight: 600 }}>note</span>
          </>
        );
        break;
      case "NOTE_EDIT":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> edited a{" "}
            <span style={{ fontWeight: 600 }}>note</span>
          </>
        );
        break;
      case "NOTE_DELETE":
        actionStr = (
          <>
            <span style={{ fontWeight: 600 }}>{actingUserName}</span> removed a{" "}
            <span style={{ fontWeight: 600 }}>note</span>
          </>
        );
        break;

      default:
        actionStr = "";
        break;
    }

    return (
      <Text
        size={"15px"}
        type={TextType.Div}
        weight={400}
        colour={Colour.Black}
      >
        {actionStr}
      </Text>
    );
  };

  if (!log) {
    return null;
  }

  const timeStr = timeSince(new Date(log.createdAt)) + " ago";
  return (
    <div
      style={{
        display: "flex",
        padding: "0.5rem 0",
        borderBottom: "1px solid #EEEEEE",
      }}
    >
      <div
        style={{
          marginRight: "0.5rem",
        }}
      >
        {getActingUserString(log)}
      </div>
      <Text
        size={"9px"}
        type={TextType.Div}
        weight={400}
        colour={Colour.Gray70}
      >
        {timeStr}
      </Text>
      <div></div>
    </div>
  );
};
