import { useQuery } from "@apollo/client";
import { GlobalFilteringContext } from "components/contexts/global-filtering-context";
import { Text, TextStyle, TextType } from "components/core/text";
import { DashboardPages } from "components/features/dashboard/pages";
import {
  ChangeInValueDirection,
  ChangeInValueLabel,
} from "components/features/dashboard/pages/summary/change-in-value-label";
import { Colour } from "core/models";
import {
  FetchDashboardNetworth,
  FetchDashboardNetworthResponse,
} from "core/queries/dashboard";
import { toMoneyStringNoDecimal } from "core/utils";
import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

export const NetWorthSummaryCard: React.FC = () => {
  const history = useHistory();
  const { ownerIDs, startDate, endDate, hasAccounts } = useContext(
    GlobalFilteringContext
  );

  const { data } = useQuery<FetchDashboardNetworthResponse>(
    FetchDashboardNetworth,
    {
      variables: {
        networthInput: {
          userIDs: ownerIDs,
          startDate: startDate,
          endDate: endDate,
        },
      },
      skip: ownerIDs.length < 1,
      fetchPolicy: "cache-and-network",
      onCompleted: (d) => {},
    }
  );

  const isJoint = ownerIDs.length > 1;
  const networthChangeThisMonth = data?.networth.change || 0;
  const networthChangeDirection =
    networthChangeThisMonth > 0
      ? ChangeInValueDirection.Up
      : networthChangeThisMonth < 0
      ? ChangeInValueDirection.Down
      : undefined;

  const textColor = hasAccounts ? Colour.Black : Colour.Gray400;
  const networthAmount = hasAccounts ? data?.networth.networth || 0 : 1234567;
  return (
    <div
      onClick={() => {
        history.push(DashboardPages.Integrations);
      }}
      id="dashboard-net-worth-box"
    >
      <div>
        <Text style={TextStyle.M17SB} colour={Colour.Black}>
          {isJoint && "Joint "}Net Worth
        </Text>

        <Text style={TextStyle.M35R} colour={textColor}>
          {toMoneyStringNoDecimal(networthAmount)}
        </Text>
        {hasAccounts && data?.networth?.change != null && (
          <ChangeInValueLabel direction={networthChangeDirection}>
            {toMoneyStringNoDecimal(Math.abs(networthChangeThisMonth))}{" "}
            {networthChangeThisMonth === 0 && "change"} this month
          </ChangeInValueLabel>
        )}
        {!hasAccounts && <ChangeInValueLabel>Example</ChangeInValueLabel>}
        {!hasAccounts && (
          <Link
            to={DashboardPages.Integrations}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Text
              style={TextStyle.M11}
              colour={Colour.Blue500}
              type={TextType.Span}
            >
              Connect your accounts
            </Text>
          </Link>
        )}
      </div>
    </div>
  );
};
