import { ReactElement } from "react";

export interface Props {
  header?: ReactElement | ReactElement[] | string;
  actions?: ReactElement | ReactElement[];
  hideActionsOnMobile?: boolean;
  setWidth?: boolean;
}

export const PageWrapper: React.FC<Props> = ({
  header,
  actions,
  hideActionsOnMobile,
  setWidth = false,
  children,
}) => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {header ? (
        <div className="dashboard-page__header-wrapper">
          <div className="dashboard-page__header-content">
            <div className={`dashboard-page__width-limit `}>
              <div
                className={`flex-row between align-center gap-1 mobile-flex-column  ${
                  setWidth ? "layout-wide" : ""
                }`}
              >
                <div>{header}</div>

                {!hideActionsOnMobile && (
                  <div className="dashboard-page__header-actions">
                    {actions}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "3rem" }} />
      )}
      <div className="flex-row justify-center">
        <div className={`dashboard-page__width-limit `}>
          <div className={`mt2 ${setWidth ? "layout-wide" : ""}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
