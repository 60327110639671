import * as React from "react";
import { Colour } from "core/models";
import { LeafButton, LeafButtonColour } from "components/core/leaf-button";
import { TaxContext } from "components/contexts/tax-context";
import { TaxIncomeEventType } from "core/queries/taxes";
import { TaxPageSection } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section";
import { TaxSectionIncomeEventRow } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/income-event-row";
import { TextD, TextStyle } from "components/core/text";
import { toMoneyString } from "core/utils";

export const TaxSectionBonusIncome: React.FC = () => {
  const { bonusIncomeEvents, saveIncomeEventFieldValue, totalBonusIncome } =
    React.useContext(TaxContext);

  return (
    <>
      {bonusIncomeEvents && bonusIncomeEvents.length > 0 && (
        <TaxPageSection
          localStorageKey="taxes.bonusincome"
          headerLeft="Bonus Income"
          headerRight={
            <div className="table-cell">{toMoneyString(totalBonusIncome)}</div>
          }
          bodyLeft={
            <>
              Provide any supplemental income that took the form of a bonus.
              <br />
              <br />
              Note that while the taxes you owe on bonus income is identical to
              those owed on regular income, taxes are withheld at a different
              rate.
            </>
          }
          bodyRight={
            <>
              {bonusIncomeEvents &&
                bonusIncomeEvents.length != null &&
                bonusIncomeEvents?.map((incomeEvent) => {
                  return (
                    <TaxSectionIncomeEventRow
                      key={incomeEvent.id}
                      incomeEvent={incomeEvent}
                      incomeEventType={TaxIncomeEventType.Bonus}
                      fieldLabel="Bonus Income"
                      tooltipText={
                        <>
                          Any income in addition to your regular income that is
                          given as a reward or incentive.
                          <br />
                          <br />
                          Note that while the taxes you owe on bonus income is
                          identical to regular income, taxes are withheld at a
                          different rate.
                        </>
                      }
                    />
                  );
                })}

              <div className="mt1">
                <LeafButton
                  buttonColour={LeafButtonColour.Transparent}
                  onClick={() => {
                    saveIncomeEventFieldValue(
                      "amount",
                      "0",
                      TaxIncomeEventType.Bonus
                    );
                  }}
                >
                  <TextD colour={Colour.Blue01} style={TextStyle.M15SM}>
                    Add Another Bonus
                  </TextD>
                </LeafButton>
              </div>
            </>
          }
        />
      )}
    </>
  );
};
