import * as React from "react";
import _ from "lodash";
import Chart from "react-apexcharts";
import { accountsQuery, AccountsResponse } from "core/queries/accounts";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import {
  addMonths,
  format,
  getDay,
  parse
  } from "date-fns";
import { ApexOptions } from "apexcharts";
import { CashflowSummary } from "components/features/dashboard/components/chart-cashflow-daily/cashflow-summary";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { DEMO_IDS } from "core/utils/demos";
import { getDailyCasfhlowQueryVariables } from "components/features/dashboard/components/chart-cashflow-daily/query";
import { LeafButton } from "components/core/leaf-button";
import { Link } from "react-router-dom";
import { LinkType, PylonLink } from "components/core/link";
import { RowCard } from "components/features/dashboard/components/row-card";
import { SessionContext } from "components/contexts/session-context-provider";
import { shortNumber, toMoneyString } from "core/utils";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import { useLazyQuery, useQuery } from "@apollo/client";
import "./styles.css";
import {
  CashflowCategorizedSummary,
  CashFlowResponse,
  ExpenseIncomePair,
  FETCH_CASH_FLOW,
} from "core/queries/transactions";

interface ChartState {
  options: ApexOptions;
  series: Array<any>;
}

interface Props {
  showLinkToCashFlowPage?: boolean;
  showAccounts?: boolean;
  showStatementLink?: boolean;
}

export const ChartDailyCashFlow2: React.FC<Props> = ({
  showLinkToCashFlowPage,
  showAccounts,
  showStatementLink,
}) => {
  const { month } = React.useContext(AccountTransactionContext);

  const [fetchCashflow, { data, loading }] =
    useLazyQuery<CashFlowResponse>(FETCH_CASH_FLOW);
  React.useEffect(() => {
    fetchCashflow({
      variables: getDailyCasfhlowQueryVariables(month),
    });
  }, [month, fetchCashflow]);

  const { userID } = React.useContext(SessionContext.context);

  const { data: accountData } = useQuery<AccountsResponse>(accountsQuery, {
    fetchPolicy: "cache-first",
  });

  const [showAll, setShowAll] = React.useState(true);

  const cashflowAccountIDs = data?.cashflow?.accountIDs ?? [];
  const selectedMonthDate = React.useMemo(
    () => parse(month, "yyyy-MM", new Date()),
    [month]
  );

  const series = React.useMemo(() => {
    let cf = data?.cashflow?.monthlyCashflows ?? [];

    if (!showAll) {
      cf = cf.filter((x) => x.ownerID === userID);
    }

    const monthlyCashflow: Record<string, number[]> = {};
    let cfBreakdown: Record<string, ExpenseIncomePair[]> = {};

    cf.forEach((x) => {
      if (!monthlyCashflow[x.monthStr]) {
        monthlyCashflow[x.monthStr] = x.expenseFlow;
      } else {
        monthlyCashflow[x.monthStr] = mergeNumArray(
          x.expenseFlow,
          monthlyCashflow[x.monthStr]
        );
      }

      if (!cfBreakdown[x.monthStr]) {
        cfBreakdown[x.monthStr] = x.dailyCashflowBreakdown;
      } else {
        cfBreakdown[x.monthStr] = mergeIncomeExpensesArray(
          x.dailyCashflowBreakdown,
          cfBreakdown[x.monthStr]
        );
      }
    });

    const resp = Object.keys(monthlyCashflow)
      .sort((a, b) => {
        return (
          parse(a, "yyyy-MM", new Date()).getTime() -
          parse(b, "yyyy-MM", new Date()).getTime()
        );
      })
      .map((m) => {
        const month = parse(m, "yyyy-MM", new Date());
        const cf = [...monthlyCashflow[m]];
        const breakdown = [...cfBreakdown[m]];

        // Add some paychecks to demo accounts
        if (DEMO_IDS.includes(userID)) {
          for (const [i] of cf.entries()) {
            // Additional income on 2nd and 16th day of every month
            // Picked those days to see the bump
            if (i >= 1) {
              cf[i] += 10000;
            }

            if (i >= 15) {
              cf[i] += 10000;
            }

            if (i === 1 || i === 15) {
              if (breakdown[i]) {
                breakdown[i] = {
                  income: breakdown[i].income + 10000,
                  expenses: breakdown[i].expenses,
                };
              }
            }
          }
        }

        return {
          name: `${format(month, "MMMM")}`,
          type: "line",
          data: cf.map((n) => {
            return n;
          }),
          month: month,
          cashflowBreakdown: breakdown,
        };
      });

    return resp;
  }, [userID, showAll, data]);

  const currentMonthCashflowDetails = React.useMemo(() => {
    let cf = data?.cashflow?.monthlyCashflows ?? [];

    cf = cf.filter((x) => x.monthStr === month);
    if (!showAll) {
      cf = cf.filter((x) => x.ownerID === userID);
    }

    const expenses = regroupCategorySummary(cf.flatMap((x) => x.expense));
    const income = regroupCategorySummary(cf.flatMap((x) => x.income));

    if (DEMO_IDS.includes(userID)) {
      let bonus = 20000;

      const today = new Date();
      // If selected month is current month, then check for if it's the paycheck day yet
      if (format(today, "yyyy-MM") === month && getDay(today) < 15) {
        bonus = 10000;
      }

      income.push({
        category: "Gusto Paycheck",
        total: bonus,
      });
    }

    return {
      expenses: expenses,
      income: income,
      total: sumCategorySummaries(expenses) + sumCategorySummaries(income),
    };
  }, [data?.cashflow?.monthlyCashflows, month, showAll, userID]);

  const monthlyIncomeToShow = React.useMemo<number>(() => {
    if (!data?.cashflow?.monthlyCashflows) {
      return 0;
    }

    let cf = data.cashflow.monthlyCashflows;

    if (!showAll) {
      cf = cf.filter((x) => x.ownerID === userID);
    }

    const previousMonth = format(addMonths(selectedMonthDate, -1), "yyyy-MM");

    cf = cf.filter((x) => x.monthStr === previousMonth);

    const incomeTotal = sum(
      cf.map((c) => {
        return sum(c.income.map((x) => x.total));
      })
    );

    if (DEMO_IDS.includes(userID)) {
      return incomeTotal + 20000;
    }
    return incomeTotal;
  }, [data?.cashflow?.monthlyCashflows, selectedMonthDate, showAll, userID]);

  const dailyCashFlowState = React.useMemo<ChartState>(() => {
    let yAxisMax = monthlyIncomeToShow || 0;
    series.forEach((s) => {
      s?.data?.forEach((d) => {
        if (d > yAxisMax) {
          yAxisMax = d;
        }
      });
    });
    yAxisMax *= 1.1;

    const o = series.slice(-2);

    return {
      series: o,
      options: {
        chart: {
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        colors: ["var(--separator-01)", "var(--blue-01)"],
        stroke: {
          width: [3, 4],
          dashArray: [3, 0],
        },

        legend: {
          markers: {
            width: 6,
            height: 6,
          },
          position: "bottom",
          horizontalAlign: "center",
          fontFamily: '"Montserrat", sans-serif',
          fontSize: "14px",
          fontWeight: 500,
          formatter: (x) => {
            return x.toUpperCase();
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          colors: ["var(--blue-01)"],
          strokeColors: "white",
        },
        tooltip: {
          //https://apexcharts.com/docs/options/tooltip/#custom
          // SeriesIndex is the month and dataPointIndex is the day
          custom: ({ _, seriesIndex, dataPointIndex }) => {
            const indexToUse =
              series.length > 2 ? seriesIndex + 1 : seriesIndex;
            if (!series[indexToUse]) {
              console.warn(
                "Index out of range for data",
                series.length,
                indexToUse
              );
              return;
            }
            const breakdown = series[indexToUse].cashflowBreakdown[
              dataPointIndex
            ] ?? {
              income: 0,
              expenses: 0,
            };
            const monthName = format(series[indexToUse].month, "MMMM");

            const dayLabel = monthName + " " + (dataPointIndex + 1);

            return (
              `<div class="cashflow-tooltip-wrapper">` +
              `<div class="cashflow-tooltip-title">${dayLabel}</div>` +
              `<div class="cashflow-tooltip-row"><div class="tooltip-title">Income</div> <div class="tooltip-value">${toMoneyString(
                breakdown.income
              )}</div></div>` +
              `<div class="cashflow-tooltip-row"><div class="tooltip-title">Expenses</div> <div class="tooltip-value">${toMoneyString(
                breakdown.expenses
              )}</div></div>` +
              `<div class="cashflow-tooltip-title mt1">Month to Date</div>` +
              `<div class="cashflow-tooltip-row"><div class="tooltip-title">Expenses</div> <div class="tooltip-value">${toMoneyString(
                series[indexToUse].data[dataPointIndex]
              )}</div></div>` +
              `</div>`
            );
          },
        },
        grid: {
          borderColor: "var(--separator-02)",
        },
        yaxis: {
          enable: true,
          show: true,
          max: yAxisMax,
          labels: {
            style: {
              fontFamily: '"Inter", sans-serif',
              fontSize: "10px",
              fontWeight: 700,
              colors: "var(--text-02)",
            },
            show: true, // !mobileLayout,
            formatter: function (val: number) {
              if (_.isNil(val) || !_.isFinite(val)) {
                return "";
              }
              return shortNumber(val);
            },
          },
        },
        xaxis: {
          labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
              fontFamily: '"Inter", sans-serif',
              fontSize: "10px",
              fontWeight: 700,
              colors: "var(--text-03)",
            },
          },
          tickAmount: 14,
          tooltip: {
            enabled: false,
          },
        },
        style: {
          fontFamily: '"Inter", sans-serif',
          fontSize: "10px",
          fontWeight: 700,
          colors: "var(--text-02)",
        },
        annotations: {
          yaxis: [
            monthlyIncomeToShow
              ? {
                  y: monthlyIncomeToShow,
                  borderColor: "var(--green-01)",
                  fillColor: "transparent",
                  borderWidth: 2,
                  opacity: 1,
                  strokeDashArray: 0,
                  label: {
                    borderColor: "none",
                    borderWidth: 1,
                    style: {
                      padding: {
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      },
                      color: "var(--green-01)",
                      background: "transparent",
                      cssClass: "figment",
                      fontSize: "16px",
                    },
                    position: "left",
                    offsetX: 280,
                    offsetY: -4,
                    text: `Income Previous Month: ${toMoneyString(
                      monthlyIncomeToShow
                    )}`,
                  },
                }
              : {},
          ],
        },
      },
    };
  }, [series, monthlyIncomeToShow]);

  const cashflowURLParam = React.useMemo(() => {
    const u = new URLSearchParams();
    u.append("month", month);
    return u.toString();
  }, [month]);

  const firstLoading = !data && loading;
  const needToInitializeAccounts =
    data?.cashflow?.monthlyCashflows?.length === 0;

  return (
    <div>
      <div className="flex-row between">
        <div className="flex-row align-center gap-1">
          <Text
            type={TextType.Div}
            weight={700}
            colour={Colour.Navy}
            size={"1.25rem"}
          >
            Daily Cash Flow
          </Text>
          {showStatementLink && (
            <Link
              to={`${DashboardPages.CashflowStatement}?${cashflowURLParam}`}
            >
              <TextD style={TextStyle.M15SM}> Monthly Statement</TextD>
            </Link>
          )}
        </div>
        <div>
          {showLinkToCashFlowPage && (
            <Link to={DashboardPages.CashFlow}>
              <TextD style={TextStyle.M15SM}> GO TO CASH FLOW</TextD>
            </Link>
          )}
        </div>
      </div>

      <div className="mt1" />
      <div
        style={{
          boxShadow:
            "0px 1px 8px rgba(230, 233, 237, 0.8), 0px 1px 4px rgba(181, 190, 202, 0.64)",
          borderRadius: "6px",
          padding: "1rem",
        }}
        className="flex-column"
      >
        <div className="flex-row between mobile-flex-column cashflow-chart-and-summary">
          <div
            style={{
              paddingTop: "1rem",
              flexShrink: 1,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                paddingLeft: ".5rem",
                display: "flex",
              }}
            >
              <Text style={TextStyle.M18} type={TextType.Div}>
                Expenses
              </Text>
            </div>

            <div className="cashflow-chart">
              {dailyCashFlowState.series.length > 0 && (
                <Chart
                  options={dailyCashFlowState.options}
                  series={dailyCashFlowState.series}
                  height="440px"
                />
              )}
            </div>
          </div>

          <div className="cashflow-summary">
            {!firstLoading && !needToInitializeAccounts && (
              <CashflowSummary
                expenses={currentMonthCashflowDetails.expenses}
                income={currentMonthCashflowDetails.income}
                total={currentMonthCashflowDetails.total}
                showAll={showAll}
                onShowAllToggle={() => {
                  setShowAll(!showAll);
                }}
              />
            )}

            {!firstLoading && needToInitializeAccounts && (
              <div
                className="flex-column between"
                style={{
                  paddingTop: "5rem",
                  alignItems: "center",
                  paddingLeft: "1.5rem",
                  paddingRight: "1.5rem",
                }}
              >
                <div className="mt3">
                  You don't have any accounts with cash flow transactions.
                </div>
                <div className="mt2">
                  <PylonLink
                    to={DashboardPages.Integrations}
                    linkType={LinkType.NoStyle}
                  >
                    <LeafButton>Add Accounts </LeafButton>
                  </PylonLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showAccounts && (
        <div className="mt2">
          <div style={{ borderBottom: "1px solid var(--separator-02)" }}>
            <h6>Included Accounts</h6>
          </div>
          <div className="table accounts">
            <div className="table-head">
              <div className="table-cell">Name</div>
              <div className="table-cell owner">Owner</div>
              <div className="table-cell value">Value</div>
            </div>
            <div className="table-body">
              {accountData?.accounts
                ?.filter((acc) => cashflowAccountIDs.includes(acc.account_id))
                .map((acc) => {
                  return (
                    <RowCard
                      key={acc.account_id}
                      account={acc}
                      title={acc.name}
                      draggable={false}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const sumCategorySummaries = (summaries: CashflowCategorizedSummary[]) => {
  return summaries.map((x) => x.total).reduce((x, y) => x + y, 0);
};

const mergeNumArray = (a: number[], b: number[]) => {
  const n = Math.max(a.length, b.length);
  const resp: number[] = [];
  for (let i = 0; i < n; i++) {
    resp[i] = (a[i] ?? 0) + (b[i] ?? 0);
  }
  return resp;
};

const mergeIncomeExpensesArray = (
  a: ExpenseIncomePair[],
  b: ExpenseIncomePair[]
) => {
  const n = Math.max(a.length, b.length);
  const resp: ExpenseIncomePair[] = [];
  for (let i = 0; i < n; i++) {
    resp[i] = {
      expenses: (a[i]?.expenses ?? 0) + (b[i]?.expenses ?? 0),
      income: (a[i]?.income ?? 0) + (b[i]?.income ?? 0),
    };
  }
  return resp;
};

const regroupCategorySummary = (summaries: CashflowCategorizedSummary[]) => {
  const group: Record<string, number> = {};
  summaries.forEach((x) => {
    const amt = group[x.category] ?? 0;
    group[x.category] = amt + x.total;
  });

  const resp: CashflowCategorizedSummary[] = Object.keys(group).map((x) => {
    return {
      category: x,
      total: group[x],
    };
  });

  return resp;
};

const sum = (arr: number[]) => {
  return arr.reduce((total, a) => {
    return total + a;
  }, 0);
};
