import * as React from "react";
import Modal from "react-modal";
import qs from "qs";
import { AccountSubtypeEnum } from "components/features/dashboard/components/add-account-tray/models";
import { canSubtypeHaveEstimatedValueDetails } from "core/models/account";
import { ConfirmationModalOld } from "components/core/modal";
import { DashboardPages } from "components/features/dashboard/pages";
import { FETCH_OVERVIEW_DATA } from "core/queries/overview";
import { GenerateDetailsForAccountSubtype } from "core/models";
import { LinkType, PylonLink } from "components/core/link";
import { PlaidLinkErrorHandlerModal } from "components/features/dashboard/components/plaid-wrapper/components/plaid-link-error-handler";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { PlaidLinkSuccessModal } from "components/features/dashboard/components/add-account-tray/modals/plaid-link-success-modal";
import { PlaidLinkWrapper } from "components/features/dashboard/components/plaid-wrapper/components/plaid-link-wrapper";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import "./styles.css";
import {
  AddAccountsContext,
  TokenExchangeResponse,
} from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import {
  PlaidWrapperContext,
  PlaidWrapperContextProvider,
} from "components/features/dashboard/components/plaid-wrapper/context/plaid-wrapper-context";
import {
  GoogleAdWordsConversionIDs,
  trackEvent,
} from "core/distribution/distribution";
import {
  accountCategoriesExamplesQuery,
  accountCategoriesQuery,
  accountsQuery,
  exchangeTokenMutaion,
  getPlaidLinkTokenQuery,
  LinkTokenResponse,
  updateAccountMutation,
  UpdateAccountMutationInput,
} from "core/queries/accounts";

interface AccountTypesMap {
  [key: string]: {
    type: string;
    subtype: string;
    estimatedValue: string;
  };
}

interface AccountsResponse {
  exchangeLinkToken: TokenExchangeResponse;
}
export interface Props {
  oauthRedirectURI?: string;

  onSuccess?: () => void;
  onError?: (err: Error) => void;
  onClose?: () => void;
}
export const PlaidWrapper: React.FC<Props> = (props: Props) => {
  return (
    <PlaidWrapperContextProvider>
      <PlaidWrapperComponent {...props} />
    </PlaidWrapperContextProvider>
  );
};

export const PlaidWrapperComponent: React.FC<Props> = (props: Props) => {
  const { clearStack } = React.useContext(SideTrayContext);
  const {
    plaidLinkToken,
    setPlaidLinkToken,
    setTokenExchangeResponse,
    saveToSessionStorage,
    exchangeResponse,
    convertToManualProps,
    accountIDToConvert,
    setAccountIDToConvert,
  } = React.useContext(AddAccountsContext);
  const [gotResp, setGotResp] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [accountTypesMap, setAccountTypesMap] = React.useState<AccountTypesMap>(
    {}
  );

  const { shouldExit, institutionErrored, linkError } =
    React.useContext(PlaidWrapperContext);

  const [exchangeToken] = useMutation<AccountsResponse>(exchangeTokenMutaion, {
    onCompleted: (data) => {
      setGotResp(true);
      setTokenExchangeResponse(data.exchangeLinkToken);
      setShowModal(true);
      if (data.exchangeLinkToken?.accounts?.length > 0) {
        // automatically set to first found account
        setAccountIDToConvert(data.exchangeLinkToken?.accounts[0].account_id);
      }
    },
    onError: (err) => {
      console.error("failed to update account", err);
    },
    refetchQueries: [
      { query: accountsQuery },
      { query: accountCategoriesQuery },
      { query: FETCH_OVERVIEW_DATA },
    ],
  });

  const validCategories = React.useMemo(() => {
    for (let s of Object.values(accountTypesMap)) {
      if (s.type === "" || s.subtype === "") return false;
    }
    return true;
  }, [accountTypesMap]);

  const validDetails = React.useMemo(() => {
    for (let s of Object.values(accountTypesMap)) {
      if (
        s.subtype &&
        s.subtype !== "" &&
        canSubtypeHaveEstimatedValueDetails(s.subtype as AccountSubtypeEnum) &&
        (!s.estimatedValue || s.estimatedValue === "")
      ) {
        return false;
      }
    }
    return true;
  }, [accountTypesMap]);
  const { loading: tokenLoading, error: tokenError } =
    useQuery<LinkTokenResponse>(getPlaidLinkTokenQuery, {
      skip: !!plaidLinkToken,
      onCompleted: (data) => {
        setPlaidLinkToken(data.getLinkToken);
        saveToSessionStorage({
          plaidLinkToken: data.getLinkToken,
        });
      },
    });
  // plaid config
  if (tokenError) {
    toast(
      <PylonToastBody
        type={ToastType.Error}
        title={"Error communicating with Plaid"}
        body={`Error getting link token. Please try again later.`}
      />
    );
  }

  const [updateAccount] = useMutation<UpdateAccountMutationInput>(
    updateAccountMutation,
    {
      refetchQueries: [
        { query: accountsQuery },
        { query: accountCategoriesQuery },
        { query: accountCategoriesExamplesQuery },
        { query: FETCH_OVERVIEW_DATA },
      ],
      onError: (err) => {
        console.error("failed to update account", err);
      },
    }
  );

  const submitAccountTypeUpdates = () => {
    return new Promise<void>((resolve) => {
      for (const key of Object.keys(accountTypesMap)) {
        const input: UpdateAccountMutationInput = {
          account: {
            id: key,
            account_id: key,
            type: accountTypesMap[key].type,
            subtype: accountTypesMap[key].subtype,
            details: GenerateDetailsForAccountSubtype(
              accountTypesMap[key].subtype,
              { estimatedValue: accountTypesMap[key].estimatedValue }
            ),
          },
        };

        if (accountIDToConvert === key && convertToManualProps) {
          input.convert = {
            accountID: convertToManualProps.accountID,
            keepShared: convertToManualProps.keepShared,
          };
        }
        updateAccount({
          variables: input,
        });
      }

      if (
        accountIDToConvert &&
        !accountTypesMap[accountIDToConvert] &&
        convertToManualProps
      ) {
        updateAccount({
          variables: {
            account: {
              id: accountIDToConvert,
              account_id: accountIDToConvert,
            },
            convert: {
              accountID: convertToManualProps.accountID,
              keepShared: convertToManualProps.keepShared,
            },
          },
        });
      }
      resolve();
    });
  };

  const onLinkSuccess = (
    token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    exchangeToken({
      variables: {
        token,
        metadata: {
          institution: {
            institutionID: metadata?.institution?.institution_id,
            institutionName: metadata?.institution?.name,
          },
          accounts: metadata.accounts,
        },
      },
    })
      .then(() => {
        gtag("event", "conversion", {
          send_to: GoogleAdWordsConversionIDs.AddedAnAccount,
        });
        gtag("event", `added_account`, {});
        trackEvent("Added Account", { integrationSource: "PLAID" });
      })
      .catch((err) => {
        toast(
          <PylonToastBody
            type={ToastType.Error}
            title={"Error pulling data from Plaid"}
            body={`Error getting accounts. Please try again later.`}
          />
        );
        props.onError?.(err);
        console.error(err);
      });
  };

  // jank hack because plaid doesn't actually tell us when we have errors
  if (shouldExit && !!!(linkError && institutionErrored)) {
    props.onClose?.();
  }

  return (
    <>
      {!gotResp && !tokenLoading && !tokenError && plaidLinkToken !== "" && (
        <PlaidLinkWrapper
          oauthRedirectURI={props.oauthRedirectURI}
          linkToken={plaidLinkToken}
          onSuccess={onLinkSuccess}
          onExit={() => {}}
        />
      )}
      <ConfirmationModalOld
        width={"33rem"}
        additionalModalClassName={"plaid-confirmation-wrapper"}
        showModal={showModal}
        children={
          <PlaidLinkSuccessModal
            showConvertOption={!convertToManualProps?.hideConvertOption}
            allowConvert={!!convertToManualProps?.accountID}
            selectedAccountToConvert={accountIDToConvert}
            updateAccountToConvert={(accID) => setAccountIDToConvert(accID)}
            updateParentState={(accountID, type, subtype, estimatedValue) => {
              setAccountTypesMap({
                ...accountTypesMap,
                [accountID]: {
                  type,
                  subtype,
                  estimatedValue,
                },
              });
            }}
          />
        }
        onAction={() => {
          if (exchangeResponse?.accounts?.length > 0) {
            // onAction update accounts with correct categories
            submitAccountTypeUpdates().then(() => {
              toast(
                <PylonToastBody title={"Account added successfully"}>
                  <>
                    {`We just added your ${
                      exchangeResponse?.accounts?.length
                    }${" "}
                  ${exchangeResponse.institutionName}${" "}
                ${
                  exchangeResponse?.accounts?.length === 1
                    ? "account"
                    : "accounts"
                } for tracking!`}{" "}
                    {exchangeResponse?.accounts?.length === 1 && (
                      <PylonLink
                        to={`${DashboardPages.AccountDetails}?${qs.stringify({
                          accountID: exchangeResponse?.accounts?.[0].account_id,
                        })}`}
                        linkType={LinkType.Highlight}
                      >
                        View Account
                      </PylonLink>
                    )}
                  </>
                </PylonToastBody>,
                {
                  closeOnClick: false,
                  closeButton: true,
                }
              );
            });
          } else {
            toast(
              <PylonToastBody
                type={ToastType.Notification}
                title={"Already Connected"}
              >
                <>
                  {`Your 
              ${
                exchangeResponse?.duplicateAccounts?.length === 1
                  ? "account has"
                  : "accounts have "
              } already been connected to Pylon.`}{" "}
                </>
              </PylonToastBody>,
              {
                closeOnClick: false,
                closeButton: true,
              }
            );
          }
          setShowModal(false);
          clearStack();
          props.onClose?.();
          props.onSuccess?.();
        }}
        hideAction={exchangeResponse?.accounts?.length === 0}
        actionButtonText={"Confirm"}
        actionButtonDisabled={!validCategories || !validDetails}
        hideCancel={exchangeResponse?.accounts?.length > 0}
        onCancel={() => {
          if (exchangeResponse?.accounts?.length === 0) {
            toast(
              <PylonToastBody
                type={ToastType.Notification}
                title={"Already Connected"}
              >
                <>
                  {`Your 
              ${
                exchangeResponse?.duplicateAccounts?.length === 1
                  ? "account has"
                  : "accounts have "
              } already been connected to Pylon.`}{" "}
                </>
              </PylonToastBody>,
              {
                closeOnClick: false,
                closeButton: true,
              }
            );
          }
          setShowModal(false);
          clearStack();
          props.onClose?.();
        }}
        cancelButtonText="Close"
      />
      <Modal
        shouldCloseOnEsc
        isOpen={!!(linkError && institutionErrored)}
        onRequestClose={() => {
          setShowErrorModal(false);
          clearStack();
          props.onClose?.();
        }}
        className="modal-base credit-score-modal"
        overlayClassName="overlay-base"
        style={{
          content: {
            width: "33rem",
            minHeight: "fit-content",
            maxHeight: "44rem",
            overflowY: "auto",
          },
          overlay: {
            backgroundColor: "rgba(3, 12, 23, 0.7)",
          },
        }}
      >
        <PlaidLinkErrorHandlerModal
          institutionID={institutionErrored}
          linkError={linkError}
          onClose={() => {
            setShowErrorModal(false);
            clearStack();
            props.onClose?.();
          }}
        />
      </Modal>
    </>
  );
};
