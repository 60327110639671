import * as React from "react";
import { AssetType } from "components/features/dashboard/models/sharing";
import { CategoryData } from "core/queries/documents";
import { ConfirmationModal } from "components/core/modal";
import { ShareTray } from "components/features/dashboard/components/share-tray";
import { useQuery } from "@apollo/client";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  RecipientForAssetQueryResponse,
  recipientsForAssetQuery,
} from "core/queries/collaborations";

interface Props {
  category: CategoryData;
}

export const ShareFolderButton: React.FC<Props> = ({ category }) => {
  const [showShare, setShowShare] = React.useState(false);
  const { data } = useQuery<RecipientForAssetQueryResponse>(
    recipientsForAssetQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        assetID: category?.id,
        assetType:
          category?.type === "Folder"
            ? AssetType.DocumentFolder
            : AssetType.Account,
      },
      skip: !category || category.sharedWithMe,
    }
  );

  const recipients = data?.recipientsForAsset ?? [];

  if (!category.sharedWithMe) {
    return (
      <div
        className="flex-row share-button"
        style={{
          padding: "0 0.25rem",
        }}
      >
        <ConfirmationModal
          children={
            <ShareTray asset={category} onClose={() => setShowShare(false)} />
          }
          showModal={showShare}
          onClose={() => {
            setShowShare(false);
          }}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        />

        <LeafButton
          buttonStyle={LeafButtonStyle.LeafSmallDense}
          buttonColour={LeafButtonColour.Transparent}
          onClick={() => {
            setShowShare(true);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 500,
            }}
          >
            <div
              style={{
                marginRight: ".5rem",
                fontSize: ".75rem",
                textTransform: "uppercase",
                height: "1.35rem",
              }}
            >
              {category.type === "Folder" ? "Share Folder" : "Share Account"}{" "}
              <span style={{ color: "var(--blue-01)" }}>
                {recipients.length > 0 && ` (${recipients.length})`}
              </span>
            </div>
          </div>
        </LeafButton>
      </div>
    );
  }

  return null;
};
