import * as React from "react";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";

export interface Props {}

export const AddAccountHeader: React.FC<Props> = (props: Props) => {
  const { accountType } = React.useContext(AddAccountsContext);

  return <SimpleSideTrayHeader text={accountType} />;
};
