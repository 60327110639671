import * as React from "react";
import _ from "lodash";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { AddBudgetGoalTray } from "components/features/dashboard/components/budget/goals/add-budget-goal";
import { BudgetGoalRow } from "components/features/dashboard/components/budget/goals/budget-goal-row";
import { Colour } from "core/models";
import { expenseTransactionsFilter } from "components/features/dashboard/components/chart-expenses";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { TextD, TextStyle } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  GetBudgetGoalQueryResponse,
  GET_BUDGET_GOAL_QUERY,
} from "core/queries/budget-goal";

export const BudgetGoalsTable: React.FC = () => {
  const { pushTray } = React.useContext(SideTrayContext);
  const { transactionData } = React.useContext(AccountTransactionContext);

  const { data: goalsData } = useQuery<GetBudgetGoalQueryResponse>(
    GET_BUDGET_GOAL_QUERY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const rows = React.useMemo(() => {
    if (!goalsData || _.isEmpty(goalsData?.budgetGoals)) {
      return null;
    }

    return goalsData?.budgetGoals.map((g) => {
      return <BudgetGoalRow key={g.id} goal={g} />;
    });
  }, [goalsData]);

  const totalBudgeted = React.useMemo(() => {
    return (
      goalsData?.budgetGoals
        .map((g) => {
          return Number.parseFloat(g.budget) ?? 0;
        })
        .reduce((a, b) => a + b, 0) ?? 0
    );
  }, [goalsData?.budgetGoals]);

  const goalsList = React.useMemo(() => {
    return goalsData?.budgetGoals.map((g) => g.category.toLowerCase()) ?? [];
  }, [goalsData?.budgetGoals]);

  const totalSpent = React.useMemo(() => {
    const tx = transactionData?.accountTransactions?.transactions ?? [];

    return tx
      .filter((tx) => !tx.pending)
      .filter(expenseTransactionsFilter)
      .filter((c) => {
        return goalsList?.find((g) => c.category.toLowerCase().startsWith(g));
      })
      .map((tx) => {
        return tx.amount;
      })
      .reduce((a, b) => {
        return a + b;
      }, 0);
  }, [goalsList, transactionData?.accountTransactions?.transactions]);

  return (
    <div className="budget-table">
      <div className="budget-table__section-header">
        <TextD style={TextStyle.M17SB} weight={700}>
          Budgeting Goals
        </TextD>

        <TextD
          style={TextStyle.M15SM}
          colour={totalBudgeted >= totalSpent ? Colour.Green01 : Colour.Red01}
        >
          {toMoneyString(totalSpent)}
        </TextD>
      </div>

      <div className="table" style={{ minWidth: "100%" }}>
        <div className="table-head">
          <div className="table-cell category-column">Category</div>
          <div className="table-cell filler-column no-mobile" />
          <div className="table-cell budget-column">Budget</div>
          <div className="table-cell spend-column mobile-last">This Month</div>
          <div className="table-cell delete-column no-mobile">Delete Goal</div>
        </div>
        {rows && (
          <div className="table-body" style={{ width: "100%" }}>
            {rows}
          </div>
        )}

        <div
          className="budget-table__add-more"
          onClick={() => {
            pushTray({
              header: <SimpleSideTrayHeader text="Add a budget goal" />,

              children: <AddBudgetGoalTray />,
            });
          }}
        >
          <TextD style={TextStyle.M15} colour={Colour.Blue01}>
            + Add Budget
          </TextD>
        </div>
      </div>
    </div>
  );
};
