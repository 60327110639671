import * as React from "react";
import { ConfirmationModal } from "components/core/modal";
import { ManageCollaboratorTray } from "components/features/dashboard/components/manage-collaborator-tray";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

export const InviteNewUserButton: React.FC = ({ children }) => {
  const [showInvite, setShowInvite] = React.useState<boolean>(false);
  const inviteButton = children ? (
    <div className="invite-new-user-button">{children}</div>
  ) : (
    <div style={{ display: "flex", justifyContent: "right" }}>
      <LeafButton
        buttonStyle={LeafButtonStyle.LeafSmall}
        buttonColour={LeafButtonColour.Black}
        onClick={() => setShowInvite(true)}
      >
        Click to invite
      </LeafButton>
    </div>
  );

  return (
    <>
      <ConfirmationModal
        children={
          <ManageCollaboratorTray
            showBackButton
            onClose={() => setShowInvite(false)}
          />
        }
        showModal={showInvite}
        onClose={() => {
          setShowInvite(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      {inviteButton}
    </>
  );
};
