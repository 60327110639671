import * as React from "react";
import Collapsible from "react-collapsible";
import { AddDocumentTray } from "../../components/add-document-tray";
import { Colour } from "core/models";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { DocumentFolderSuggestionSetting } from "core/queries/documents";
import { DocumentRowCardList } from "components/features/dashboard/components/document-row-card-list";
import { LeafButton, LeafButtonStyle } from "components/core/leaf-button";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { ShareFolderButton } from "components/features/dashboard/components/document-folder/share-folder-button";
import { SideTrayContext } from "../../../../contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";
import { useMutation, useQuery } from "@apollo/client";
import "./document-folder.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipPlacement,
} from "components/core/tooltip";
import {
  FetchDocumentCategoryQueryResponse,
  FETCH_DOCUMENT_CATEGORY,
  UpdateDocumentFolderInput,
  UpdateDocumentFolderResponse,
  UPDATE_DOCUMENT_FOLDER_MUTATION,
} from "core/queries/document-folder";

export interface Props {
  folderID: string;
  folderType: string;
}

export const DocumentFolder: React.FC<Props> = ({ folderID, folderType }) => {
  const mobileLayout = useMobileLayout();
  const [showSuggestions, setShowSuggestions] = React.useState(true);
  const { pushTray, popTray } = React.useContext(SideTrayContext);

  const { setOnUpdateRefetchQueries } = React.useContext(DataPageContext);

  const { data, refetch: refetchDocumentsInFolder } =
    useQuery<FetchDocumentCategoryQueryResponse>(FETCH_DOCUMENT_CATEGORY, {
      fetchPolicy: "network-only",
      variables: {
        id: folderID,
        type: folderType,
      },
    });

  React.useEffect(() => {
    setOnUpdateRefetchQueries([
      {
        query: FETCH_DOCUMENT_CATEGORY,
        variables: {
          id: folderID,
          type: folderType,
        },
      },
    ]);

    // Only set once on coomponent mount; this does not depend on anything else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [updateFolderNameMutation] = useMutation<
    UpdateDocumentFolderResponse,
    UpdateDocumentFolderInput
  >(UPDATE_DOCUMENT_FOLDER_MUTATION);

  const documentCategory = data?.documentCategory;

  if (!documentCategory?.category) {
    return null;
  }

  const showAddDocTray = (suggestionID?: string) => {
    pushTray({
      id: `account-add-document`,
      header: <SimpleSideTrayHeader text="Add Document" />,
      children: (
        <AddDocumentTray
          defaultCategory={documentCategory.category}
          suggestionItemID={suggestionID}
          onUpload={() => {
            refetchDocumentsInFolder();
            popTray();
          }}
        />
      ),
    });
  };

  const suggestionItemToSuggestionMapping: Record<
    string,
    DocumentFolderSuggestionSetting
  > = {};
  data?.documentCategory?.category?.folder?.suggestionsettings?.forEach((s) => {
    suggestionItemToSuggestionMapping[s.id] = s;
  });

  const suggestedItemsUploaded =
    data?.documentCategory?.category?.folder?.suggestedItems ?? [];

  const suggestions =
    data?.documentCategory?.category?.folder?.suggestionsettings ?? [];

  return (
    <PageWrapper
      header={
        <>
          {!mobileLayout && (
            <div
              style={{
                alignItems: "center",
              }}
              className="flex-row"
            >
              <PylonTooltip
                spacingSide={SpacingSide.None}
                direction={TooltipPlacement.Right}
                hideTooltip={!documentCategory.category.isEditable}
                text={<Text colour={Colour.White}>Click to edit</Text>}
                mobileForcePosition={{ left: 0, top: 100 }}
              >
                <PageTitle
                  title={documentCategory.category.name}
                  editable={documentCategory.category.isEditable}
                  onEdit={(t) => {
                    updateFolderNameMutation({
                      variables: {
                        id: documentCategory.category.id,
                        name: t,
                      },
                    });
                  }}
                />
              </PylonTooltip>
            </div>
          )}
          {mobileLayout && (
            <PageTitle
              title={documentCategory.category.name}
              editable={documentCategory.category.isEditable}
              onEdit={(t) => {
                updateFolderNameMutation({
                  variables: {
                    id: documentCategory.category.id,
                    name: t,
                  },
                });
              }}
            />
          )}
        </>
      }
      actions={
        <div className="flex-row mobile-flex-column gap-half">
          {data?.documentCategory?.category &&
            !data.documentCategory.category.sharedWithMe && (
              <ShareFolderButton category={data.documentCategory.category} />
            )}
          <LeafButton
            buttonStyle={LeafButtonStyle.LeafSmallDense}
            onClick={() => showAddDocTray()}
          >
            Add Documents
          </LeafButton>
        </div>
      }
    >
      <div className="document-cards ">
        <DocumentRowCardList documents={documentCategory.documents} />
        {/* <div>
        {documentCategory.documents.map((d) => {
          const suggestion = suggestedItemsUploaded.find(
            (i) => i.documentID === d.id
          );
          return (
            <DocumentRowCard
              key={d.id}
              document={d}
              open={d.id === openDocumentID}
              suggestion={
                suggestion
                  ? suggestionItemToSuggestionMapping[
                      suggestion.suggestionItemID
                    ]
                  : undefined
              }
              onOpen={() => {
                const search = qs.stringify({
                  documentID: d.id,
                  folderID: folderID,
                  folderType: folderType,
                });
                history.push(`${DashboardPages.DocumentFolder}?${search}`);
              }}
              onClose={() => {
                const search = qs.stringify({
                  folderID: folderID,
                  folderType: folderType,
                });
                history.push(`${DashboardPages.DocumentFolder}?${search}`);
              }}
            />
          );
        })}
      </div> */}

        <div
          style={{
            paddingTop: "4rem",
          }}
        />
        {/* If there's suggestion setting, show the suggestions */}
        {suggestions.length > 0 && (
          <Collapsible
            open={true}
            trigger={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowSuggestions(!showSuggestions);
                }}
              >
                <Text style={TextStyle.TitlePoppins} type={TextType.Div}>
                  Recommended by Pylon
                </Text>
                <div
                  className={
                    showSuggestions
                      ? "accounts__section-carat"
                      : "accounts__section-carat-closed"
                  }
                ></div>
              </div>
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.id}
                  className="document-folder__recommendation-item"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={TextStyle.O10}
                          type={TextType.Div}
                          weight={600}
                          colour={Colour.Navy}
                          size={"1rem"}
                        >
                          {suggestion.itemTitle}
                        </Text>
                      </div>
                      <div>
                        <Text
                          style={TextStyle.O10}
                          type={TextType.Div}
                          weight={400}
                          colour={Colour.Gray70}
                          size={"0.875rem"}
                        >
                          {suggestion.description}
                        </Text>
                      </div>
                    </div>

                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => showAddDocTray(suggestion.id)}
                    >
                      <Text
                        type={TextType.Div}
                        style={TextStyle.L12}
                        format={TextFormat.UpperCase}
                        colour={Colour.Blue01}
                        size={"0.75rem"}
                        weight={700}
                      >
                        {!suggestedItemsUploaded.find(
                          (i) => i.suggestionItemID === suggestion.id
                        )
                          ? "Upload document"
                          : "Add More"}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Collapsible>
        )}
      </div>
    </PageWrapper>
  );
};
