import * as React from "react";
import { Moved } from "components/features/external/moved";
import { PylonRoute } from "pages";
import { Route, Switch } from "react-router";

export const ExternalPageLinks = {
  Moved: "/external/moved",
};

export const ExternalPage: React.FC<{}> = () => {
  return (
    <div>
      <Switch>{renderExternalRoutes()}</Switch>
    </div>
  );
};

export const renderExternalRoutes = () => {
  const routes: PylonRoute[] = [
    {
      index: 0,
      route: ExternalPageLinks.Moved,
      component: Moved,
      exact: true,
    },
  ];

  return routes.map((route) => (
    <Route
      exact={route.exact}
      key={route.index}
      path={route.route}
      component={route.component}
    />
  ));
};
