import * as React from "react";
import { IconAsset } from "components/core/icons";
import { OnboardingProps } from "components/features/onboarding/flows/utils";
import { OnboardingSelections } from "components/features/onboarding/models";
import {
  OnboardingCard,
  OnboardingIconSize,
} from "components/features/onboarding/components/onboarding-card";

export const OnboardingWhatIsYourMaritalStatus: React.FC<OnboardingProps> = ({
  state,
  optimisticUpdate,
}) => {
  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            What is your marital status
            <span style={{ color: "var(--blue-01)" }}>?</span>
          </div>
        </div>
      </div>
      <div className="onboarding-middle buttons-full-wide">
        <OnboardingCard
          id={OnboardingSelections.MartialStatusSingle}
          onClick={() => {
            optimisticUpdate(
              OnboardingSelections.MartialStatusSingle,
              !!!state?.[OnboardingSelections.MartialStatusSingle],
              [
                OnboardingSelections.MartialStatusMarried,
                OnboardingSelections.MartialStatusHeadOfHousehold,
              ]
            );
          }}
          iconSize={OnboardingIconSize.Large}
          asset={IconAsset.OnboardingMartialStatusSingle}
          text={"Single"}
          selected={state?.[OnboardingSelections.MartialStatusSingle]}
        />

        <OnboardingCard
          id={OnboardingSelections.MartialStatusMarried}
          onClick={() => {
            optimisticUpdate(
              OnboardingSelections.MartialStatusMarried,
              !!!state?.[OnboardingSelections.MartialStatusMarried],
              [
                OnboardingSelections.MartialStatusSingle,
                OnboardingSelections.MartialStatusHeadOfHousehold,
              ]
            );
          }}
          iconSize={OnboardingIconSize.Large}
          asset={IconAsset.OnboardingMartialStatusMarried}
          text={"Married"}
          selected={state?.[OnboardingSelections.MartialStatusMarried]}
        />

        <OnboardingCard
          id={OnboardingSelections.MartialStatusHeadOfHousehold}
          onClick={() => {
            optimisticUpdate(
              OnboardingSelections.MartialStatusHeadOfHousehold,
              !!!state?.[OnboardingSelections.MartialStatusHeadOfHousehold],
              [
                OnboardingSelections.MartialStatusSingle,
                OnboardingSelections.MartialStatusMarried,
              ]
            );
          }}
          iconSize={OnboardingIconSize.Large}
          asset={IconAsset.OnboardingMartialStatusHeadOfHousehold}
          text={"Head of Household"}
          selected={state?.[OnboardingSelections.MartialStatusHeadOfHousehold]}
        />
      </div>
    </div>
  );
};
