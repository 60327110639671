import { gql } from "@apollo/client";

export enum UserPreferenceSettingEnum {
  HideCollabSuggestionSpouse = "hide-collab-suggestion-spouse",
  HideCollabSuggestionAccountant = "hide-collab-suggestion-accountant",
  CashflowBreakdownSelectedType = "cashflow-breakdown-selected-type",

  NetworthHistoryOwnerType = "networth-history--owner-type",
  NetworthHistoryDataType = "networth-history--data-type",
  NetworthHistoryDays = "networth-history--days",

  PortfolioHistoryOwnerType = "portfolio-history--owner-type",
  PortfolioHistoryDataType = "portfolio-history--data-type",
  PortfolioHistoryDays = "portfolio-history--days",
  PortfolioHistoryChangeType = "portfolio-history--change-type",

  AccountsHistoryOwnerType = "accounts-history--owner-type",
  AccountsHistoryDataType = "accounts-history--data-type",
}

export interface UserPreferenceSetting {
  id: string;
  preference: UserPreferenceSettingEnum;
  setting?: string;
}

export interface FetchUserPreferenceSettingsInput {
  preferences: UserPreferenceSettingEnum[];
}

export interface FetchUserPreferenceSettingsResponse {
  preferenceSettings: UserPreferenceSetting[];
}

export const USER_PREFERENCE_SETTINGS_FIELD = gql`
  fragment UserPreferenceSettingsField on UserPreferenceSetting {
    id
    preference
    setting
  }
`;

export const FETCH_USER_PREFERENCES = gql`
  ${USER_PREFERENCE_SETTINGS_FIELD}

  query FetchUserPreferenceSettings($preferences: [String!]!) {
    preferenceSettings(preferences: $preferences) {
      ...UserPreferenceSettingsField
    }
  }
`;

export type SaveUserPreferenceSettingsInput = Pick<
  UserPreferenceSetting,
  "preference" | "setting"
> &
  Partial<Pick<UserPreferenceSetting, "id">>;

export interface SaveUserPreferenceSettingsResponse {
  saveUserPreferenceSetting: {
    savePreferenceSetting: UserPreferenceSetting;
  };
}

export const SAVE_USER_PREFERENCE = gql`
  ${USER_PREFERENCE_SETTINGS_FIELD}

  mutation saveUserPreferenceSetting($input: SaveUserPreferenceSettingInput!) {
    savePreferenceSetting(input: $input) {
      ...UserPreferenceSettingsField
    }
  }
`;
