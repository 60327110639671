import * as React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Account, Colour } from "core/models";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { AddDocumentTray } from "components/features/dashboard/components/add-document-tray";
import { ConfirmationModalOld } from "components/core/modal";
import { CryptoConnectWrapper } from "components/features/dashboard/components/crypto-connect-wrapper";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { DocumentCategoryType } from "../../../../../core/models/documents";
import { Icon, IconAsset } from "components/core/icons";
import { IntegrationSource } from "components/features/dashboard/models/integration-source";
import { PlaidWrapper } from "components/features/dashboard/components/plaid-wrapper";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SessionContext } from "components/contexts/session-context-provider";
import { ShareTray } from "components/features/dashboard/components/share-tray";
import { ShareTrayHeader } from "components/features/dashboard/components/share-tray/share-tray-header";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { Text } from "components/core/text";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import "./styles.css";
import {
  AccountSubType,
  AccountType,
  IsAccountTypeConnectable,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  LoadingAnimation,
  ManageMenuOption,
} from "components/features/dashboard/components/manage-menu-option";
import {
  accountCategoriesExamplesQuery,
  accountCategoriesQuery,
  accountsQuery,
  deleteAccountMutation,
  FETCH_ACCOUNT_DOCUMENTS,
  refreshAccountMutation,
} from "core/queries/accounts";

interface Props {
  account: Account;
  isConnected: boolean;
  onRemove?: () => void;
}

export const ManageAccountOptions: React.FC<Props> = ({
  account,
  isConnected,
  onRemove,
}) => {
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const { setConvertToManualProps } = React.useContext(AddAccountsContext);

  const [refreshAccount, { loading: refreshLoading }] = useMutation(
    refreshAccountMutation,
    {
      variables: {
        id: account.account_id,
      },
      refetchQueries: [
        { query: accountsQuery },
        { query: accountCategoriesQuery },
        { query: accountCategoriesExamplesQuery },
      ],
      onCompleted: () => {
        toast(
          <PylonToastBody
            title={"Account successfully updated"}
            body={
              "We've refreshed your account to show the latest information!"
            }
          />,
          {
            closeOnClick: false,
            closeButton: true,
          }
        );
      },
    }
  );
  const [deleteAccount] = useMutation(deleteAccountMutation, {
    refetchQueries: onUpdateRefetchQueries,
    onCompleted: () => {
      onRemove?.();

      toast(
        <PylonToastBody
          title={"Account Removed"}
          body={`Account successfully removed.`}
        />
      );
    },
    onError: (err) => {
      console.error("failed to delete account", err);
      toast(
        <PylonToastBody
          title={"Error Occurred"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
  });
  const [updateAccountDocuments] = useLazyQuery(FETCH_ACCOUNT_DOCUMENTS, {
    fetchPolicy: "network-only",

    variables: {
      id: account.account_id,
    },
  });

  const { pushTray, popTray } = React.useContext(SideTrayContext);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showConvertModal, setShowConvertModal] = React.useState(false);
  const [showConnectionDialog, setShowConnectionDialog] = React.useState(false);
  const { userID } = React.useContext(SessionContext.context);

  const getConnectionDialog = () => {
    switch (account.subtype) {
      case AccountSubType[AccountType.NonRetirement]["Cryptocurrency"]:
      case AccountSubType[AccountType.DigitalWallet]["Cryptocurrency"]:
        return (
          <CryptoConnectWrapper
            showingModal={showConnectionDialog}
            onClose={() => {
              setShowConnectionDialog(false);
            }}
          />
        );
      default:
        return (
          <PlaidWrapper
            onClose={() => {
              setShowConnectionDialog(false);
            }}
          />
        );
    }
  };

  const isSharedWithMe = account.owner?.id !== userID;

  const addDocumentButton = (
    <ManageMenuOption
      bottomBorder
      iconAsset={IconAsset.Document}
      onClick={() =>
        pushTray({
          id: `account-add-document`,
          header: <SimpleSideTrayHeader text="Add Document" />,
          children: (
            <AddDocumentTray
              defaultCategory={{
                id: account.id || "",
                type: DocumentCategoryType.Account,
                name: account.name,
              }}
              onUpload={() => updateAccountDocuments()}
            />
          ),
        })
      }
      optionText={"Add Document"}
    />
  );

  const renderManualMenu = () => {
    return (
      <>
        {addDocumentButton}
        {IsAccountTypeConnectable(account.type, account.subtype) && (
          <ManageMenuOption
            bottomBorder
            iconAsset={IconAsset.Connect}
            onClick={() => setShowConvertModal(true)}
            optionText={"Convert to connected"}
          />
        )}
        {!isSharedWithMe && (
          <>
            <ManageMenuOption
              bottomBorder
              iconAsset={IconAsset.RemoveAsset}
              onClick={() => setShowDeleteModal(true)}
              optionText={"Delete Account"}
            />
            <ManageMenuOption
              iconAsset={IconAsset.ShareAsset}
              optionText={"Share Account"}
              onClick={() => {
                pushTray({
                  header: <ShareTrayHeader account={account} />,
                  children: <ShareTray asset={account} />,
                });
              }}
            />
          </>
        )}
      </>
    );
  };

  const renderConnectedMenu = () => {
    return (
      <>
        {addDocumentButton}
        {!isSharedWithMe && (
          <>
            <ManageMenuOption
              bottomBorder
              iconAsset={IconAsset.Disconnect}
              onClick={() => setShowDeleteModal(true)}
              optionText={"Disconnect Account"}
            />

            <ManageMenuOption
              bottomBorder
              iconAsset={IconAsset.ShareAsset}
              optionText={"Share Account"}
              onClick={() => {
                pushTray({
                  header: <ShareTrayHeader account={account} />,
                  children: <ShareTray asset={account} />,
                });
              }}
            />
          </>
        )}
        {account.integration_source === IntegrationSource.Plaid &&
          !account.loginRequired && (
            <ManageMenuOption
              loading={refreshLoading}
              loadingAnimation={LoadingAnimation.SpinLeftToRight}
              disabled={refreshLoading}
              disabledToolTip={"Refreshing..."}
              iconAsset={IconAsset.RefreshAccount}
              onClick={() => refreshAccount()}
              optionText={"Refresh Account"}
            />
          )}
      </>
    );
  };
  return (
    <div
      style={{ alignSelf: "center" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown bsPrefix="pylon" alignRight>
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          <Icon
            asset={IconAsset.Ellipsis}
            width="16px"
            height="16px"
            colour={Colour.Gray70}
          />
        </DropdownToggle>
        <DropdownMenu className="pylon-dropdown-menu">
          {isConnected ? renderConnectedMenu() : renderManualMenu()}
        </DropdownMenu>
      </Dropdown>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showDeleteModal}
        children={
          <>
            <Text weight={600}>
              Are you sure you want to delete this account?
            </Text>
            <Text weight={400}>
              This action is irreversible and cannot be undone.
            </Text>{" "}
            <Text weight={400}>
              Documents associated with this account will still be accessible
              from the documents page.
            </Text>
          </>
        }
        onAction={() => {
          deleteAccount({
            variables: {
              id: account.account_id,
            },
          });
          setShowDeleteModal(false);
          popTray();
        }}
        actionButtonText="Delete"
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        cancelButtonText="Cancel"
      />

      <ConfirmationModalOld
        width={"22rem"}
        showModal={showConvertModal}
        children={
          <>
            <Text weight={600}>Sharing settings</Text>
            <Text weight={400}>
              Do you want to keep existing sharing settings?
            </Text>
          </>
        }
        onAction={() => {
          setConvertToManualProps({
            keepShared: true,
            accountID: account.account_id,
          });
          setShowConvertModal(false);
          setShowConnectionDialog(true);
        }}
        actionButtonText="Yes"
        onCancel={() => {
          setConvertToManualProps({
            keepShared: false,
            accountID: account.account_id,
          });
          setShowConvertModal(false);
          setShowConnectionDialog(true);
        }}
        cancelButtonText="No"
      />

      {showConnectionDialog && getConnectionDialog()}
    </div>
  );
};
