import _ from "lodash";
import Toggle from "react-toggle";
import { Colour } from "core/models";
import { Input } from "components/core/input";
import { LeafButton } from "components/core/leaf-button";
import { ManageCategoryButton } from "components/features/dashboard/components/transactions/manage-category-button";
import { MutationWrapper } from "core/queries/mutation";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  CreateTransactionCategory,
  CreateTransactionCategoryInput,
  CreateTransactionCategoryResponse,
  CustomTransactionCategory,
  GetCustomTransactionCategoryQuery,
} from "core/queries/transaction-category";

export interface Props {
  defaultMerchant?: string;
  noManage?: boolean;
  onChange: (newCategory: CustomTransactionCategory) => void;
}

export const AddNewCategoryTray: React.FC<Props> = ({
  defaultMerchant,
  noManage,
  onChange,
}) => {
  const [createCategory, { loading: createLoading }] = useMutation<
    CreateTransactionCategoryResponse,
    MutationWrapper<CreateTransactionCategoryInput>
  >(CreateTransactionCategory, {
    onCompleted: (data) => {
      if (data.createCustomTransactionCategories) {
        onChange(data.createCustomTransactionCategories);
      }
    },
    refetchQueries: [
      {
        query: GetCustomTransactionCategoryQuery,
      },
    ],
  });

  const [newCategory, setNewCategory] = useState("");
  const [applyToFuture, setApplyToFuture] = useState(
    defaultMerchant ? true : false
  );

  return (
    <div>
      {!noManage && (
        <div
          style={{
            width: "fit-content",
          }}
        >
          <ManageCategoryButton showBack />
        </div>
      )}
      {defaultMerchant && (
        <>
          <div className="mt2" />
          <div
            className="flex-row between"
            style={{
              gap: "1rem",
            }}
          >
            <Text style={TextStyle.FieldLabel} type={TextType.Div}>
              Apply to future transactions
            </Text>
            <Toggle
              className="transaction-row-exclude-toggle"
              icons={false}
              checked={applyToFuture}
              onChange={() => {
                setApplyToFuture(!applyToFuture);
              }}
            />
          </div>

          {applyToFuture && (
            <div
              className="flex-row align-center between"
              style={{
                paddingTop: "0.5rem",
                paddingLeft: "1rem",
              }}
            >
              <Text
                style={TextStyle.FieldLabel}
                type={TextType.Div}
                colour={Colour.Text03}
              >
                When transaction is from "{defaultMerchant}"
              </Text>
            </div>
          )}
        </>
      )}

      <div className="mt1" />

      <div className="flex-column gap-half">
        <Text style={TextStyle.FieldLabel} type={TextType.Div}>
          Category Name
        </Text>

        <div
          style={{
            width: "100%",
          }}
        >
          <Input
            value={newCategory}
            onChange={(v) => {
              setNewCategory(v.currentTarget.value);
            }}
            maxLength={128}
            placeholder="My personal category..."
          />
        </div>
      </div>

      <div
        className="flex-column"
        style={{
          paddingTop: "0.5rem",
        }}
      >
        <LeafButton
          loading={createLoading}
          disabled={_.isEmpty(newCategory)}
          onClick={() => {
            createCategory({
              variables: {
                input: {
                  category: newCategory,
                  merchants:
                    applyToFuture && defaultMerchant ? [defaultMerchant] : [],
                },
              },
            });
          }}
        >
          Create
        </LeafButton>
      </div>
    </div>
  );
};
