import React, { useEffect, useState } from "react";
import { Account } from "core/models";
import { AccountSubtypeOptions } from "components/features/dashboard/components/manage-account-modal/details/account-subtype-options";
import { AccountTypeOptions } from "components/features/dashboard/components/manage-account-modal/details/account-type-options";
import { balanceText } from "components/features/dashboard/components/account-balance";
import { formatNumberToString } from "components/features/dashboard/components/manage-account-modal/details/utils";
import { ModalInput } from "components/core/input/modal-input";
import { ModalInputWrapper } from "components/core/input/modal-input-wrapper";
import "./styles.css";
import {
  AccountSubType,
  AccountSubtypeEnum,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";

export interface Props {
  account: Account;
  isConnected: boolean;
  disabled?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const EditAccountInformation: React.FC<Props> = ({
  account,
  isConnected,
  onUpdate,
}) => {
  const [name, setName] = useState(account.name);
  const [balance, setBalance] = useState<string>(
    formatNumberToString(account.balances?.current) || "0"
  );
  const [accountType, setAccountType] = useState(account.type);

  const [subtype, setSubtype] = useState(account.subtype);

  useEffect(() => {
    if (account.name !== name) {
      setName(account.name);
    }

    const newBalance = account.balances.current?.toFixed(2);
    if (newBalance !== balance) {
      setBalance(newBalance);
    }

    if (accountType !== account.type) {
      setAccountType(accountType);
    }

    if (subtype !== account.subtype) {
      setSubtype(subtype);
    }
    // Only update if account changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const keydownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  const onBlurUpdateName = () => {
    if (name !== account.name) {
      onUpdate?.({
        name: name,
      });
    }
  };

  const onBlurUpdateBalance = () => {
    const newBalance = balance ? Number.parseFloat(balance) : 0;
    if (balance !== formatNumberToString(account.balances?.current)) {
      onUpdate?.({
        balances: {
          limit: account.balances.limit,
          available: account.balances.available,
          iso_currency_code: account.balances.iso_currency_code,
          current: newBalance,
        },
      });
    }
  };

  const getDefaultSubtype = (accountType: AccountType) => {
    return Object.values(AccountSubType[accountType])?.[0];
  };

  return (
    <>
      <ModalInputWrapper label="Type">
        <AccountTypeOptions
          accountType={accountType as AccountType}
          onSelect={(newType) => {
            const newAccType = newType as AccountType;
            if (newAccType) {
              getDefaultSubtype(newAccType);
              const sub = getDefaultSubtype(newAccType);

              setAccountType(newAccType);
              setSubtype(sub);
              onUpdate?.({
                type: newAccType,
                subtype: sub,
              });
            }
          }}
        />
      </ModalInputWrapper>

      <ModalInputWrapper label="Subtype">
        <AccountSubtypeOptions
          accountType={accountType as AccountType}
          accountSubtype={subtype}
          onSelect={(s) => {
            if (s) {
              setSubtype(s);
              onUpdate?.({
                subtype: s,
              });
            }
          }}
        />
      </ModalInputWrapper>

      <ModalInput
        id="account-name"
        label={"Account Name"}
        value={name}
        onChange={(v) => {
          if (v) {
            setName(v);
          }
        }}
        stringOverrides={{
          onBlur: onBlurUpdateName,
          onKeyPress: keydownHandler,
        }}
      />

      {!isConnected &&
        !AccountTypesWithCalculatedBalance.includes(account.subtype) && (
          <ModalInput
            id="account-balance"
            type="money"
            label={balanceText(account)}
            value={balance}
            onChange={(v) => {
              if (v) {
                setBalance(v);
              }
            }}
            currencyOverrides={{
              onBlur: onBlurUpdateBalance,
              onKeyPress: keydownHandler,
            }}
          />
        )}
    </>
  );
};

const AccountTypesWithCalculatedBalance: string[] = [
  AccountSubtypeEnum.PrivateStock,
  AccountSubtypeEnum.Cryptocurrency,
  AccountSubtypeEnum.PrivateCompany,
];
