import * as React from "react";
import Toggle from "react-toggle";
import { Account } from "core/models";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { canSubtypeHaveEstimatedValueDetails } from "core/models/account";
import { PylonALink } from "components/core/alink";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { SimpleDropDown } from "components/core/simple-drop-down";
import { Text, TextStyle, TextType } from "components/core/text";
import { toAccountString, toMoneyString } from "core/utils";
import {
  AccountSubType,
  AccountSubtypeEnum,
  AccountType,
  IsAccountTypeConnectable,
} from "components/features/dashboard/components/add-account-tray/models";

interface Option {
  value: AccountType | string;
  label: AccountType | string;
}

interface AccountSelection {
  selected: boolean;
  type: Option;
  subtype: Option;
  estimatedValue: string;
}

interface SelectionState {
  [key: string]: AccountSelection;
}

interface Props {
  updateParentState: (
    accountID: string,
    type: string,
    subtype: string,
    estimatedValue: string
  ) => void;

  allowConvert?: boolean;
  showConvertOption: boolean;
  selectedAccountToConvert?: string;
  updateAccountToConvert?: (accID: string) => void;
}

export const PlaidLinkSuccessModal: React.FC<Props> = ({
  updateParentState,
  allowConvert,
  showConvertOption,
  selectedAccountToConvert,
  updateAccountToConvert,
}) => {
  const { exchangeResponse } = React.useContext(AddAccountsContext);
  const [state, setState] = React.useState<SelectionState>(() => {
    const data: SelectionState = {};
    for (let a of exchangeResponse.accounts) {
      data[a.account_id] = {
        selected: true,
        estimatedValue: "",
        type: {
          label: a.type,
          value: a.type,
        },
        subtype: {
          label: a.subtype,
          value: a.subtype,
        },
      };
    }
    return data;
  });

  React.useEffect(() => {
    const data: SelectionState = {};
    for (let a of exchangeResponse.accounts) {
      data[a.account_id] = {
        selected: true,
        estimatedValue: "",
        type: {
          label: a.type,
          value: a.type,
        },
        subtype: {
          label: a.subtype,
          value: a.subtype,
        },
      };
      updateParentState(
        a.account_id,
        state[a.account_id].type.value,
        state[a.account_id].subtype.value,
        state[a.account_id].estimatedValue
      );
    }
    setState(data);
  }, [setState, exchangeResponse]);

  const getTypeOptions = () => {
    let ret: Option[] = [];
    for (let type in AccountType) {
      // Skip types that cannot be a connected account
      if (
        !IsAccountTypeConnectable(AccountType[type as keyof typeof AccountType])
      ) {
        continue;
      }
      ret.push({
        label: AccountType[type as keyof typeof AccountType] as AccountType,
        value: AccountType[type as keyof typeof AccountType] as AccountType,
      });
    }

    return ret;
  };

  const getSubtypeOptions = (type: AccountType) => {
    let ret: Option[] = [];
    if (!type) {
      return [];
    }

    for (let subtype in AccountSubType[type]) {
      ret.push({
        label: subtype,
        value: subtype,
      });
    }

    return ret;
  };

  const renderNewAccounts = () => {
    return exchangeResponse.accounts.map((account, index) => (
      <div
        key={account.account_id}
        style={{
          margin: "2rem 0",
          borderBottom:
            index !== exchangeResponse.accounts.length - 1
              ? "4px solid var(--gray-10)"
              : "none",
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: "1rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{showAccountDisplayName(account)}</div>
          <div>{toMoneyString(account.balances.current)}</div>
        </div>
        {allowConvert && showConvertOption && (
          <div
            style={{
              display: "flex",
              paddingTop: "0.5rem",
              alignItems: "center",
            }}
          >
            <Toggle
              icons={false}
              checked={selectedAccountToConvert === account.account_id}
              onChange={() => {
                if (selectedAccountToConvert === account.account_id) {
                  updateAccountToConvert?.("");
                } else {
                  updateAccountToConvert?.(account.account_id);
                }
              }}
            />
            <div style={{ paddingRight: "1rem" }} />
            <Text type={TextType.Div} style={TextStyle.Hint}>
              Convert to this account
            </Text>
          </div>
        )}

        <div
          style={{
            height: "1px",
            margin: "1rem 0",
            borderBottom: "1px solid var(--gray-10)",
          }}
        ></div>
        <div>
          <div className="manage-account__input-title">
            What type of account is this?
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "0.5rem 0", width: "100%" }}>
              <SimpleDropDown
                selectedValue={state[account.account_id].type}
                onSelect={(e) => {
                  if (!e) {
                    return;
                  }
                  setState({
                    ...state,
                    [account.account_id]: {
                      ...state[account.account_id],
                      type: e,
                      subtype: {
                        label: "",
                        value: "",
                      },
                    },
                  });
                  updateParentState(
                    account.account_id,
                    e.value,
                    "",
                    state[account.account_id].estimatedValue
                  );
                }}
                disabled={!state[account.account_id].type.value}
                options={getTypeOptions()}
                toDisplay={(v) => v.label}
              />
            </div>
            <div style={{ margin: "0.5rem 0" }}>
              <SimpleDropDown
                selectedValue={state[account.account_id].subtype}
                onSelect={(e) => {
                  if (!e) {
                    return;
                  }

                  setState({
                    ...state,
                    [account.account_id]: {
                      ...state[account.account_id],
                      subtype: e,
                    },
                  });
                  updateParentState(
                    account.account_id,
                    state[account.account_id].type.value,
                    e.value,
                    state[account.account_id].estimatedValue
                  );
                }}
                disabled={!state[account.account_id].type.value}
                options={getSubtypeOptions(
                  state[account.account_id].type.value as AccountType
                )}
                toDisplay={(v) => v.label}
              />
            </div>
          </div>
          {canSubtypeHaveEstimatedValueDetails(
            state[account.account_id].subtype.value as AccountSubtypeEnum
          ) && (
            <div className="add-account-tray__step-selection">
              <div className="manage-account__input-title">Estimated value</div>
              <PylonCurrencyInput
                id="estimated-value-input"
                placeholder="$700,000"
                className="manage-account__input pylon-input"
                onValueChange={(v) => {
                  const sanitizedVal = sanitizeStringNumberToString(v) || "";
                  setState({
                    ...state,
                    [account.account_id]: {
                      ...state[account.account_id],
                      estimatedValue: sanitizedVal,
                    },
                  });
                  updateParentState(
                    account.account_id,
                    state[account.account_id].type.value,
                    state[account.account_id].subtype.value,
                    sanitizedVal
                  );
                }}
                value={state[account.account_id].estimatedValue}
                prefix={"$"}
                decimalScale={2}
              />
              <Text type={TextType.Div} style={TextStyle.Hint}>
                Not sure? Try{" "}
                <PylonALink
                  href="https://www.redfin.com/what-is-my-home-worth"
                  target="_blank"
                >
                  Redfin{" "}
                </PylonALink>
                or{" "}
                <PylonALink
                  href="https://www.zillow.com/how-much-is-my-home-worth/"
                  target="_blank"
                >
                  Zillow{" "}
                </PylonALink>
                to get an estimate
              </Text>
            </div>
          )}
        </div>
      </div>
    ));
  };

  const renderDuplicateAccounts = () => {
    return exchangeResponse.duplicateAccounts.map((account, index) => (
      <div
        key={account.account_id}
        style={{
          margin: "2rem 0",
          borderBottom:
            index !== exchangeResponse.duplicateAccounts.length - 1
              ? "4px solid var(--gray-10)"
              : "none",
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>{showAccountDisplayName(account)}</div>
          <div>{toMoneyString(account.balances.current)}</div>
        </div>
        <div
          style={{
            width: "100 %",
            display: "flex",
            justifyContent: " center",
            alignItems: " center",
            height: "5rem",
            color: "var(--green-04)",
            fontSize: " 1.25rem",
            backgroundColor: " var(--green-01)",
            fontWeight: 600,
            borderRadius: " 10px",
            margin: "1rem 0",
            padding: "2rem",
          }}
        >
          You've already added this account! 🎉
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div
        style={{
          fontSize: "1.25rem",
          fontWeight: 600,
          color: "var(--navy)",
        }}
      >{`We found these accounts on your ${exchangeResponse.institutionName} online banking account`}</div>
      <div
        style={{
          borderBottom:
            exchangeResponse.duplicateAccounts.length > 0 &&
            exchangeResponse.accounts.length > 0
              ? "4px solid var(--gray-10)"
              : "none",
        }}
      >
        {renderNewAccounts()}
      </div>
      <div>{renderDuplicateAccounts()}</div>
    </div>
  );
};

const showAccountDisplayName = (acc: Account) => {
  if (acc.mask) {
    return `${acc.name} ${toAccountString(acc.mask)}`;
  }

  return acc.name;
};
