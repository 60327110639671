import Select, { GroupTypeBase, StylesConfig } from "react-select";
import { DocumentCategoryType } from "../../../../../core/models/documents";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  AccountType,
  getColorForType,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  CategoryData,
  DocumentCategoriesResponse,
  FETCH_DOCUMENT_CATEGORIES,
} from "../../../../../core/queries/documents";

// Option type of react-select
export interface Option {
  value: string;
  label: string;
  type: string;
  tag: CategoryData;
  isNewItemOption: boolean;
}

export interface OptionGroup {
  label: string;
  options: Option[];
}

export interface Props {
  onSelect: (accounts: CategoryData[]) => void;

  excludeAccountID?: string; // If specified, the account ID will be excluded from list of options
  excludeAccountIDs?: string[];
  selectedValues?: CategoryData[];
  disabled?: boolean;
  forceMenuOpen?: boolean;
}

export const FolderPicker: React.FC<Props> = ({
  selectedValues,
  onSelect,
  excludeAccountIDs,
  excludeAccountID,
}) => {
  const { data } = useQuery<DocumentCategoriesResponse>(
    FETCH_DOCUMENT_CATEGORIES
  );

  const optionGroupsMap: Map<string, Option[]> = new Map();
  const allOptions: Option[] = [];

  if (data) {
    data.documentCategories.forEach((c) => {
      const category = c.category;
      if (
        category.type !== DocumentCategoryType.Account &&
        category.type !== DocumentCategoryType.Folder
      ) {
        return;
      }

      let options: Option[] = optionGroupsMap.get(category.type) ?? [];
      const opt = {
        value: category.id,
        label: category.name,
        type: category.type,
        isNewItemOption: false,
        tag: category,
      };
      options.push(opt);

      optionGroupsMap.set(category.type, options);
      allOptions.push(opt);
    });
  }

  const optionGroup: OptionGroup[] = [];
  optionGroupsMap.forEach((x, y) => {
    optionGroup.push({
      label: y,
      options: x.sort((a, b) => {
        return ("" + a.label).localeCompare(b.label);
      }),
    });
  });

  let selectedOptions: Option[] | undefined = undefined;
  if (selectedValues) {
    selectedOptions = [];
    selectedValues.forEach((x) => {
      const opt = allOptions.find((y) => {
        return y.value === x.id;
      });
      if (opt) {
        selectedOptions!.push(opt);
      }
    });
  }

  const customStyles: StylesConfig<Option, false, GroupTypeBase<Option>> = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: getColorForType(data.type as AccountType).Light,
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginTop: "0px",
      };
    },
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Select
        styles={customStyles}
        menuIsOpen={true}
        isClearable
        isDisabled={false}
        isMulti={false}
        disabled={false}
        value={[]}
        filterOption={(option) => {
          if (option.value === excludeAccountID) {
            return false;
          }

          if (excludeAccountIDs?.includes(option.value)) {
            return false;
          }

          return true;
        }}
        onChange={(e) => {
          if (!e) {
            onSelect([]);
            return;
          }

          if (e instanceof Array) {
            onSelect(e.map((x) => x.tag));
          } else {
            onSelect([e.tag]);
          }
        }}
        options={optionGroup}
      />
    </div>
  );
};
