import classnames from "classnames";
import { Icon, IconAsset } from "components/core/icons";
import { PylonLink } from "components/core/link";
import { Text, TextFormat, TextStyle, TextType } from "components/core/text";
import { DashboardPages } from "components/features/dashboard/pages";
import { PublicPageLinks } from "components/features/public";
import { Colour } from "core/models";
import * as React from "react";
import "./styles.css";

interface InfoBoxProps {
  title: string;
  description: string;
  footer: React.ReactNode;
  smallSize?: boolean;
}

export const SecurityInfoBox: React.FC<InfoBoxProps> = ({
  title,
  description,
  footer,
  smallSize,
}) => {
  return (
    <div className="security-info-box">
      <div
        style={{
          margin: "1.5rem",
          marginBottom: "0",
        }}
      >
        <h6>
          {title}
          <span style={{ color: "var(--blue-01)" }}>.</span>
        </h6>
      </div>
      <div
        style={{
          margin: smallSize ? "0 1.5rem" : "1.5rem",
        }}
      >
        {description}
      </div>
      <div
        style={{
          margin: "0.5rem",
          padding: "1rem",
          backgroundColor: "var(--bg-01)",
          borderRadius: "6px",
        }}
      >
        {footer}
      </div>
    </div>
  );
};

interface CollabsibleProps {
  title: string;
  body: React.ReactNode;
}

export const SecurityInfoCollapsible: React.FC<CollabsibleProps> = ({
  title,
  body,
}) => {
  const [open, setOpen] = React.useState(false);

  const bodyClasses = classnames({
    collapsible: true,
    open: open,
  });
  return (
    <div
      style={{
        width: "100%",
        padding: "2rem",
        margin: "1rem 0",
        border: "2px solid var(--gray-10)",
        borderRadius: "12px",
        backgroundColor: open ? "var(--bg-01)" : "",
      }}
    >
      <div
        onClick={() => setOpen(!open)}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text size={"1rem"} colour={Colour.Navy} weight={600}>
          {title}
        </Text>
        {open ? (
          <Icon
            strokeColour={Colour.Navy}
            width={"21px"}
            height={"13px"}
            asset={IconAsset.OpenedArrow}
          />
        ) : (
          <Icon
            strokeColour={Colour.Navy}
            width={"21px"}
            height={"13px"}
            asset={IconAsset.ClosedArrow}
          />
        )}
      </div>

      <div className={bodyClasses}>{body}</div>
    </div>
  );
};

interface Props {
  publicPage?: boolean;
}
export const SecurityOverview: React.FC<Props> = ({ publicPage }) => {
  return (
    <div className="dashboard-content-inner">
      <h5>
        Security Overview<span style={{ color: "var(--blue-01)" }}>.</span>
      </h5>
      <div className="security-info-boxes">
        <SecurityInfoBox
          title={"Data Encryption"}
          description={
            "Your data is encrypted with best-in-class Advanced Encryption Standard (AES) 256-bit encryption protocol."
          }
          footer={
            <Text
              colour={Colour.Blue01}
              format={TextFormat.UpperCase}
              type={TextType.Div}
              size={"0.75rem"}
              weight={700}
            >
              Encryption keeps your data private and safe.
            </Text>
          }
        />
        <SecurityInfoBox
          title={"At Rest Encryption"}
          description={
            "All of our databases and file storage servers, including all automated back ups, are encrypted."
          }
          footer={
            <Text
              colour={Colour.Blue01}
              format={TextFormat.UpperCase}
              type={TextType.Div}
              size={"0.75rem"}
              weight={700}
            >
              At rest encryption prevents unauthorized data retrieval.
            </Text>
          }
        />
        <SecurityInfoBox
          title={"In Transit Encryption"}
          description={
            "All data is encrypted in transit via Transport Layer Security (TLS) protocol when moving between your browser, and all Pylon systems."
          }
          footer={
            <Text
              colour={Colour.Blue01}
              format={TextFormat.UpperCase}
              type={TextType.Div}
              size={"0.75rem"}
              weight={700}
            >
              TLS securely connects your browser to our servers.
            </Text>
          }
        />
      </div>
      <div style={{ margin: "3rem 0" }}>
        <div style={{ marginBottom: "3rem" }}>
          <h6>
            We enforce security best practices
            <span style={{ color: "var(--blue-01)" }}>.</span>
          </h6>
        </div>
        <div className="security-statement">
          <div>
            <Text type={TextType.Div} weight={400} style={TextStyle.Poppins}>
              Two factor authentication
            </Text>
          </div>
          <div>
            <Text
              type={TextType.Div}
              weight={400}
              size={"0.875rem"}
              style={TextStyle.Default}
            >
              Two factor authentication is an added layer of security for Pylon
              accounts, and is enabled on all accounts by default. With two
              factor authentication enabled, an attacker who only knows your
              password would not be able to access your account. Authenticated
              sessions with Pylon are terminated when logging out or after a
              short period of inactivity.
            </Text>
          </div>
        </div>
        <hr />
        <div className="security-statement">
          <div>
            <Text type={TextType.Div} weight={400} style={TextStyle.Poppins}>
              Constant Improvement
            </Text>
          </div>
          <div>
            <Text
              type={TextType.Div}
              weight={400}
              size={"0.875rem"}
              style={TextStyle.Default}
            >
              Pylon adheres to financial industry best practices and continually
              reviews and updates our security policies to ensure your data is
              protected. We have policies in place around data security, access
              control, physical security, network security, and more.
            </Text>
          </div>
        </div>
        <hr />
        <div className="security-statement">
          <div>
            <Text type={TextType.Div} weight={400} style={TextStyle.Poppins}>
              Compliance
            </Text>
          </div>
          <div>
            <Text
              type={TextType.Div}
              weight={400}
              size={"0.875rem"}
              style={TextStyle.Default}
            >
              Pylon is constantly engaging with industry security experts to
              review and audit our systems to ensure we exceed industry
              compliance standards.
            </Text>
          </div>
        </div>
      </div>
      <div style={{ margin: "3rem 0" }}>
        <div style={{ marginBottom: "3rem" }}>
          <h6>
            Commonly asked questions and answers
            <span style={{ color: "var(--blue-01)" }}>.</span>
          </h6>
        </div>
        <div>
          <SecurityInfoCollapsible
            title={"Does Pylon have access to my credentials?"}
            body={
              <>
                Pylon does not store nor has access to any of your login
                credentials. We integrate with industry leading partners like{" "}
                <a
                  href="https://plaid.com/safety/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Plaid
                </a>
                , and{" "}
                <a
                  href="https://www.array.com/company/for-consumers"
                  target="_blank"
                  rel="noreferrer"
                >
                  Array
                </a>{" "}
                to communicate with financial institutions. Our partners provide
                read only access to Pylon, meaning we don't transact or change
                your accounts.
              </>
            }
          />
          <SecurityInfoCollapsible
            title={"Can Pylon employees see my data?"}
            body={
              <>
                Pylon employees cannot see your data. Your data is encrypted
                which means it is incomprehensible without being decrypted. We
                have strict processes in place around decryption and data access
                requiring Pylon executive team approval and user permission.
              </>
            }
          />
          <SecurityInfoCollapsible
            title={"Does Pylon sell my data?"}
            body={
              <>
                Pylon never sells your data or shares it with any third parties.
              </>
            }
          />
          <SecurityInfoCollapsible
            title={"What data can my collaborators see?"}
            body={
              <>
                Nothing is shared by default. Your collaborators can only see
                accounts and documents that you've explicitly shared with them.
              </>
            }
          />
          <SecurityInfoCollapsible
            title={"Can I disconnect my financial accounts at any time?"}
            body={
              <>
                Yes, you can remove any and all of your Pylon connections to
                your financial institutions at any time. Once an account is
                disconnected, all of your data for that account is also deleted.
              </>
            }
          />
          <SecurityInfoCollapsible
            title={"Can I delete my account at any time?"}
            body={
              <>
                Yes, deleting your Pylon account will remove all of your
                connected and manually added accounts from Pylon completely. If
                you were to open a new account with Pylon in the future, you
                would have to reconnect and add data as if your prior account
                never existed.
              </>
            }
          />
          <SecurityInfoCollapsible
            title={"Want to report an issue?"}
            body={
              <>
                Security is a top priority for Pylon. If you suspect a security
                issue please send a report to{" "}
                <a href="mailto:security@hellopylon.com">
                  security@hellopylon.com
                </a>
                .
              </>
            }
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>
          <PylonLink
            to={
              publicPage ? PublicPageLinks.Term : DashboardPages.TermsOverview
            }
          >
            <Text
              type={TextType.Div}
              colour={Colour.Blue01}
              size={"0.75rem"}
              weight={600}
              format={TextFormat.UpperCase}
              textAlign={"center"}
            >
              Terms
            </Text>
          </PylonLink>
        </p>
        <p style={{ margin: "0 0.5rem" }}>
          <Text
            type={TextType.Div}
            colour={Colour.Text03}
            size={"0.75rem"}
            weight={600}
            format={TextFormat.UpperCase}
            textAlign={"center"}
          >
            |
          </Text>
        </p>
        <p>
          <PylonLink
            to={
              publicPage
                ? PublicPageLinks.Privacy
                : DashboardPages.PrivacyOverview
            }
          >
            <Text
              type={TextType.Div}
              colour={Colour.Blue01}
              size={"0.75rem"}
              weight={600}
              format={TextFormat.UpperCase}
              textAlign={"center"}
            >
              Privacy
            </Text>
          </PylonLink>
        </p>
      </div>
    </div>
  );
};
