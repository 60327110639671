import qs from "qs";
import React, {
  useContext,
  useEffect,
  useRef,
  useState
  } from "react";
import { AccountsHeader } from "components/features/login/components/header";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Input } from "components/core/input";
import { LinkType, PylonLink } from "components/core/link";
import { LoginContext } from "components/contexts/login-context-provider";
import { LoginFormWrapper } from "components/features/login/components/login-form-input";
import { LoginPages } from "components/features/login/pages";
import { loginUser } from "core/api";
import { PublicPageLinks } from "components/features/public";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useSessionStorage } from "core/utils/browser-storage";
import "../styles/styles.css";
import "./styles.css";
import {
  LAST2FA_TOKEN_KEY,
  SessionContext,
} from "components/contexts/session-context-provider";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  Text,
  TextD,
  TextFormat,
  TextStyle,
  TextType,
} from "components/core/text";

export const Login: React.FC = () => {
  const { sessionToken, setSessionToken } = useContext(SessionContext.context);
  const [authToken, setAuthToken] = useState("");
  const [emailVerifyRequired, setEmailVerifyRequired] = useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useSessionStorage(
    "email-verification-code",
    ""
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const loginCtx = useContext(LoginContext.context);
  const history = useHistory();
  if (sessionToken) {
    history.push(DashboardPages.Summary);
  }

  const mounted = useRef<boolean>();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (authToken) {
        setSessionToken(authToken);
      }
    }

    const query = qs.parse(document.location.hash, {
      ignoreQueryPrefix: true,
    });
    if (Object.keys(query).includes("#/verify-email?ev")) {
      setEmailVerificationCode(query["#/verify-email?ev"]);
    }
  });

  const handleLogin = () => {
    const last2FAToken = localStorage.getItem(LAST2FA_TOKEN_KEY) as string;

    if (!email || !password) {
      return;
    }

    setLoading(true);
    loginUser(
      email,
      password,
      last2FAToken,
      emailVerificationCode,
      (data) => {
        setLoading(false);
        if (!data || (!data.identityToken && !data.emailVerificationRequired)) {
          console.error("Error logging in ", data);
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={`Message: ${data?.message}`}
              type={ToastType.Error}
              userError={data.status === 400 || data.status === 429}
            />
          );
        } else if (data.authToken) {
          setAuthToken(data.authToken);
          if (history.location.hash && history.location.hash.length > 1) {
            loginCtx.setRedirectTo(history.location.hash.substr(1));
          }
          history.push(DashboardPages.Summary);
        } else if (data.emailVerificationRequired) {
          setEmailVerifyRequired(true);
        } else {
          loginCtx.setTwoFARequest(data);
          if (history.location.hash && history.location.hash.length > 1) {
            loginCtx.setRedirectTo(history.location.hash.substr(1));
          }
          history.push(LoginPages.LoginTwoFA);
        }
      },
      (err) => {
        setLoading(false);
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={`${err}`}
            type={ToastType.Error}
          />
        );
      }
    );
  };

  return (
    <div className="account-page">
      <AccountsHeader redirectToHome />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>Log In</h2>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <LoginFormWrapper>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            autoComplete="username"
            value={email}
            onChange={(t) => setEmail(t.currentTarget.value)}
            onEnter={handleLogin}
            required
          />
          {emailVerifyRequired && (
            <TextD style={TextStyle.Error}>
              Email Verification is Required to access your account. Check your
              email for a Welcome to Pylon message with Verify Email link.
            </TextD>
          )}
        </LoginFormWrapper>
        <LoginFormWrapper>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            autoComplete="current-password"
            required
            value={password}
            onChange={(t) => setPassword(t.currentTarget.value)}
            onEnter={handleLogin}
          />
        </LoginFormWrapper>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text
            colour={Colour.Text03}
            type={TextType.Div}
            style={TextStyle.Hint}
            format={TextFormat.UpperCase}
            weight={600}
            size={"0.75rem"}
          >
            <PylonLink
              linkType={LinkType.NoStyle}
              to={LoginPages.ForgotPassword}
            >
              Forgot your password?
            </PylonLink>
          </Text>
        </div>
        <div className="login-button-wrapper">
          <LeafButton
            id="login-button"
            buttonColour={LeafButtonColour.Black}
            buttonStyle={LeafButtonStyle.LeafSmall}
            fullWidth
            loading={loading}
            onClick={handleLogin}
          >
            Login
          </LeafButton>
        </div>
        <Text
          type={TextType.Div}
          colour={Colour.Text03}
          style={TextStyle.Hint}
          format={TextFormat.UpperCase}
          weight={600}
          size={"0.75rem"}
        >
          Need help? Contact us at support@hellopylon.com
        </Text>
      </form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "75%",
          margin: "1rem auto",
        }}
      >
        <Text
          type={TextType.Div}
          colour={Colour.Text03}
          size={"0.75rem"}
          weight={600}
          format={TextFormat.UpperCase}
          textAlign={"center"}
        >
          Pylon takes your security and privacy seriously.
          <br />
          256-bit AES bank level encryption.
          <br />
          Complete data privacy, even from our employees.
          <br />
        </Text>
        {/* <Text>
          Pylon takes your privacy seriously. Not only do we encrypt and secure
          your data, we've also prevented Pylon employees from ever viewing your
          personal information and account balances.
        </Text> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              margin: "1rem 0",
            }}
          >
            <PylonLink to={PublicPageLinks.Term}>
              <Text
                type={TextType.Div}
                colour={Colour.Blue01}
                size={"0.75rem"}
                weight={600}
                format={TextFormat.UpperCase}
                textAlign={"center"}
              >
                Terms
              </Text>
            </PylonLink>
          </div>

          <div
            style={{
              margin: "1rem 0.5rem",
            }}
          >
            <Text
              type={TextType.Div}
              colour={Colour.Text03}
              size={"0.75rem"}
              weight={600}
              format={TextFormat.UpperCase}
              textAlign={"center"}
            >
              |
            </Text>
          </div>

          <div
            style={{
              margin: "1rem 0",
            }}
          >
            <PylonLink to={PublicPageLinks.Privacy}>
              <Text
                type={TextType.Div}
                colour={Colour.Blue01}
                size={"0.75rem"}
                weight={600}
                format={TextFormat.UpperCase}
                textAlign={"center"}
              >
                Privacy
              </Text>
            </PylonLink>
          </div>
        </div>
      </div>
    </div>
  );
};
