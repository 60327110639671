import { GoogleAdWordsConversionIDs } from "core/distribution/distribution";
import { LoginPages } from "components/features/login/pages";
import { useHistory, useLocation } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";

export interface Props {
  alwaysCenter?: boolean;
  optionalParam?: string;
}

export const RegisterButton: React.FC<Props> = ({
  alwaysCenter,
  optionalParam,
}) => {
  const history = useHistory();
  const mobileLayout = useMobileLayout();
  const location = useLocation();
  const invitationCode =
    location.pathname === "/landing/invitation"
      ? "06bffe26b47e"
      : "7fdf0e32e42";
  return (
    <div
      className={`flex-row ${
        mobileLayout || alwaysCenter ? "justify-center" : ""
      }`}
    >
      <button
        className="landing-page__register"
        onClick={() => {
          gtag("event", "conversion", {
            send_to: GoogleAdWordsConversionIDs.StartedRegistration,
          });
          history.push(
            `${LoginPages.Register}?invitationCode=${invitationCode}${
              optionalParam ? `&${optionalParam}` : ""
            }`
          );
        }}
      >
        Get Started
      </button>
    </div>
  );
};
