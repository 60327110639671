import * as React from "react";
import qs from "qs";
import { AccountTransactionContextProvider } from "components/contexts/account-transaction-context";
import { BudgetGoalsTable } from "components/features/dashboard/components/budget/goals/budget-goal-table";
import { ChartDailyCashFlow2 } from "components/features/dashboard/components/chart-cashflow-daily-2";
import { ChartExpenses } from "components/features/dashboard/components/chart-expenses";
import { DuplicatedExpensesTable } from "components/features/dashboard/components/transactions/duplicated-expenses";
import { format, parse } from "date-fns";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { RecurringExpenseTable } from "components/features/dashboard/components/transactions/recurring-expense";
import { TransactionMonthSelector } from "components/features/dashboard/components/transaction-month-selector";
import { TransactionsTable } from "components/features/dashboard/components/transactions";
import "./styles.css";
interface Props {}

export const CashFlow: React.FC<Props> = (props: Props) => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  const month = query["month"] as string;
  let initialMonth = format(new Date(), "yyyy-MM");
  if (parse(month, "yyyy-MM", new Date())) {
    // Check if url param is vaalid
    initialMonth = month;
  }

  return (
    <AccountTransactionContextProvider initialMonth={initialMonth}>
      <PageWrapper
        header={
          <PageTitle
            title="Cash Flow"
            subtitle="See where you're spending and getting paid."
          />
        }
        actions={<TransactionMonthSelector />}
        setWidth
      >
        <div className="cashflow">
          <div className="body ">
            <ChartDailyCashFlow2 showAccounts showStatementLink />
          </div>

          <div className="body mt3">
            <ChartExpenses />
          </div>

          <div className="body mt3">
            <BudgetGoalsTable />
          </div>

          <div className="body mt3">
            <RecurringExpenseTable />
          </div>

          <div className="body mt3">
            <DuplicatedExpensesTable />
          </div>

          <div className="body mt3">
            <TransactionsTable />
          </div>
        </div>
      </PageWrapper>
    </AccountTransactionContextProvider>
  );
};
