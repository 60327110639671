import { Emoji } from "components/core/emoji";
import { Text, TextStyle, TextType } from "components/core/text";
import { LandingPageDefinition } from "components/features/landing";
import { RegisterButtonV2 } from "components/features/landing/components/register-version-2";
import SVGLandingNetworth from "components/features/landing/dashboard-recent.svg";
import { useMobileLayout } from "core/hooks/responsive";
import { Colour } from "core/models";
import desktopBackground from "./desktop-background.png";
import mobileBackground from "./mobile-background.png";

export const LandingNetworthPageB: LandingPageDefinition = {
  Path: "/landing2/networth/landing-b",
  Component: () => {
    const mobileLayout = useMobileLayout();
    const renderMobile = (
      <div
        className="flex-column"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${mobileBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            padding: "1rem",
          }}
        >
          <div>
            <Text type={TextType.Div} style={TextStyle.Sharp24Bold}>
              Pylon
            </Text>
          </div>
          <div className="mt2" />
          <div>
            <Text type={TextType.Div} style={TextStyle.Sharp35Bold}>
              Calculate your net worth with Pylon
            </Text>
            <div className="mt1" />
            <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
              <div style={{ display: "flex" }}>
                <Emoji emoji={"💰"} />
                <div>14% year over year increase in net worth</div>
              </div>
            </Text>
            <div className="mt1" />
            <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
              <div style={{ display: "flex" }}>
                <Emoji emoji={"📈"} />
                <div>Net worth $2.8 million by 2031</div>
              </div>
            </Text>
            <div className="mt1" />
            <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
              <div style={{ display: "flex" }}>
                <Emoji emoji={"⛵️"} />
                <div>Will retire early with over $8.8 million</div>
              </div>
            </Text>
            <div className="mt1" />
            <RegisterButtonV2
              text="Get started →"
              roundCorner
              optionalParam="skip=true"
            />

            <div
              style={{
                paddingTop: "60px",
              }}
            />
            <div
              style={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",
              }}
            >
              <img
                width="100%"
                src={SVGLandingNetworth}
                alt="product illustration"
              />
            </div>
            <div className="mt4" />
          </div>
        </div>
      </div>
    );
    const renderWeb = (
      <div
        style={{
          width: "100%",
          maxWidth: "1440px",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "calc(50% - 10px)",
          backgroundPositionY: "calc(50% + 90px)",
          backgroundImage: `url(${desktopBackground})`,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "16px",
            left: "56px",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.Sharp24Bold}
            colour={Colour.Black}
          >
            Pylon
          </Text>
        </div>

        <div
          className="flex-row align-center"
          style={{
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              width: "544px",
              margin: "0 3.5rem",
            }}
          >
            <div style={{ marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp56Bold}
                colour={Colour.Black}
                size={"56px"}
                lineHeight="64px"
              >
                Calculate your net worth with Pylon
              </Text>
            </div>
            <div style={{ lineHeight: "24px" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp17Regular}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"💰"} />
                  <div>14% year over year increase in net worth</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", margin: "1.5rem 0" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp17Regular}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"📈"} />
                  <div>Net worth $2.8 million by 2031</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp17Regular}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"⛵️"} />
                  <div>Will retire early with over $8.8 million</div>
                </div>
              </Text>
            </div>
            <RegisterButtonV2
              roundCorner
              text="Get started →"
              optionalParam="skip=true"
            />
          </div>

          <div
            style={{
              width: "50%",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",
            }}
          >
            <img
              src={SVGLandingNetworth}
              width="100%"
              alt="Calculate Networth"
            />
          </div>
        </div>
      </div>
    );
    return mobileLayout ? renderMobile : renderWeb;
  },
};
