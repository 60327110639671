import React from "react";
import { AccountInput } from "core/queries/accounts";
import { accountShortName } from "core/models/account";
import { CollapsibleSection } from "components/core/collapsible-section";
import { CreditCardDetailCard } from "./credit-card-details";
import { CryptoPortfolioDetails } from "./crypto-details";
import { IntegrationSource } from "components/features/dashboard/models/integration-source";
import { ManageCarHomeHealthInsuranceDetails } from "./car-home-health-insurance-details";
import { ManageCDDetails } from "./cd-details";
import { ManageDetailsProps } from "./model";
import { ManageHomeConstructionDetails } from "./home-construction-details";
import { ManageHomeEquityLineOfCreditDetails } from "./home-equity-line-of-credit-details";
import { ManageHomeEquityLoanDetails } from "./home-equity-loan-details";
import { ManageLifeInsuranceDetails } from "./life-insurance-details";
import { ManageLoanDetails } from "./loan-details";
import { ManageMortgageDetails } from "./mortgage-details";
import { ManagePrivateCompanyDetails } from "./private-company-details";
import { ManagePrivateStockDetails } from "./private-stock-details";
import { ManageRentalPropertyDetails } from "./rental-property-details";
import { ManageReverseMortgageDetails } from "./reverse-mortgage-details";
import { ManageSavingsDetails } from "./savings-details";
import { UserDisplayName } from "core/utils/profile";
import "./styles.css";
import {
  AccountSubtypeEnum,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  AccountInformationValueCard,
  AccountRecipientCard,
  ValueCardType,
} from "components/features/dashboard/components/manage-account-modal/details/detail-card";

export const DisplayAccountDetails: React.FC<ManageDetailsProps> = (props) => {
  const { account } = props;

  return (
    <div>
      <CollapsibleSection
        title="Details"
        stateKey={ACCOUNT_DETAILS_COLLAPSIBLE_KEY}
      >
        <div className="flex-column">
          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Name"
            value={accountShortName(account)}
          />

          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Category"
            value={account.type}
          />
          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Subcategory"
            value={account.subtype}
          />
          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Account Type"
            value={
              account.integration_source === IntegrationSource.Manual
                ? "Manual"
                : "Connected"
            }
          />
          {!account.isOwner && (
            <AccountInformationValueCard
              type={ValueCardType.String}
              title="Owned by"
              value={UserDisplayName(account.owner)}
            />
          )}
          <AccountRecipientCard account={account} />
          {getComponentToRender({
            ...props,
            displayOnly: true,
          })}
        </div>
      </CollapsibleSection>
    </div>
  );
};

export const getComponentToRender = ({
  account,
  isConnected,
  displayOnly,
  errorFields,
  onUpdate,
}: ManageDetailsProps & {
  onUpdate?: (input: Partial<AccountInput>) => void;
}) => {
  switch (account.subtype) {
    case AccountSubtypeEnum.Mortgage: {
      return (
        <ManageMortgageDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
          errorFields={errorFields}
        />
      );
    }
    case AccountSubtypeEnum.RentalProperty: {
      return (
        <ManageRentalPropertyDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.SavingsAccounts: {
      return (
        <ManageSavingsDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.PrivateStock: {
      return (
        <ManagePrivateStockDetails
          account={account}
          onUpdate={onUpdate}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.PrivateCompany: {
      return (
        <ManagePrivateCompanyDetails
          account={account}
          onUpdate={onUpdate}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.CD: {
      return (
        <ManageCDDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.HomeEquityLineOfCredit: {
      return (
        <ManageHomeEquityLineOfCreditDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.HomeEquityLoan: {
      return (
        <ManageHomeEquityLoanDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.HomeConstruction: {
      return (
        <ManageHomeConstructionDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.Cryptocurrency: {
      return (
        <CryptoPortfolioDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountSubtypeEnum.CarInsurance:
    case AccountSubtypeEnum.HomeInsurance:
    case AccountSubtypeEnum.HealthInsurance:
      return (
        <ManageCarHomeHealthInsuranceDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    case AccountSubtypeEnum.LifeInsurance:
      return (
        <ManageLifeInsuranceDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    case AccountSubtypeEnum.ReverseMortgage:
      return (
        <ManageReverseMortgageDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    default:
  }

  switch (account.type) {
    case AccountType.CreditCard: {
      return (
        <CreditCardDetailCard
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
    case AccountType.Loans: {
      return (
        <ManageLoanDetails
          account={account}
          onUpdate={onUpdate}
          isConnected={isConnected}
          displayOnly={displayOnly}
        />
      );
    }
  }
  return null;
};

const ACCOUNT_DETAILS_COLLAPSIBLE_KEY = "account_details-details--collapsible";
