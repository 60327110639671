import * as React from 'react';
import { useEffect, useRef } from 'react';

export const useClickOutDetector = <T extends HTMLElement>(
  clickOutCallback: () => void
): React.RefObject<T> => {
  const ref = useRef<T>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        clickOutCallback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, clickOutCallback]);

  return ref;
};
