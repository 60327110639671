import React, { useEffect, useState } from "react";
import { Account, Colour, PylonCoinType } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { ConfirmationModalOld } from "components/core/modal";
import { Icon, IconAsset } from "components/core/icons";
import { ModalBufferedInput } from "components/core/input/modal-buffered-input";
import { ModalInput } from "components/core/input/modal-input";
import { PylonALink } from "components/core/alink";
import { sanitizeStringNumberToFloat } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useHover } from "core/hooks/hover";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const CryptoPortfolioDetails: React.FC<Props> = ({
  account,
  onUpdate,
  displayOnly,
  isConnected,
}) => {
  const inputDetails = account.details?.cryptoDetails;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [holdingsMap, setHoldingsMap] = useState<PylonCoinType[]>(
    inputDetails?.holdings?.map((v) => ({
      id: v.id,
      symbol: v.symbol,
      name: v.name,
      price: v.price,
      total: v.total,
    })) || []
  );

  const [indexToDelete, setIndexToDelete] = useState<number>();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (inputDetails && inputDetails.holdings?.length > 0) {
      setHoldingsMap(
        inputDetails?.holdings?.map((v) => ({
          id: v.id,
          symbol: v.symbol,
          name: v.name,
          price: v.price,
          total: v.total,
        })) || []
      );
    }
  }, [inputDetails]);

  const onBlurUpdate = (m: PylonCoinType[]) => {
    onUpdate?.({
      details: {
        cryptoDetails: {
          holdings: m,
        },
      },
    });
  };

  const onChange = (index: number, val: string) => {
    if (holdingsMap.length > index) {
      const h = [...holdingsMap];
      const holdingCopy = {
        id: h[index].id,
        symbol: h[index].symbol,
        name: h[index].name,
        price: h[index].price,
        total: h[index].total,
      };
      holdingCopy.total = sanitizeStringNumberToFloat(val) || 0;
      h.splice(index, 1, holdingCopy);

      const newH = [...h];
      setHoldingsMap(newH);

      return newH;
    }

    return holdingsMap;
  };

  const onSymbolChange = (index: number, val: string) => {
    const h = [...holdingsMap];
    if (holdingsMap.length > index) {
      h[index].symbol = val;
      setHoldingsMap([...h]);
    }
  };

  const onRemove = (index: number) => {
    setIndexToDelete(index);
    setShowModal(true);
  };

  const renderHolding = (h: PylonCoinType, i: number) => (
    <CryptoHoldingItem
      key={i}
      holding={h}
      index={i}
      onBlurUpdate={onBlurUpdate}
      onChange={onChange}
      onRemove={onRemove}
    />
  );

  const renderHoldingInput = (h: PylonCoinType, i: number) => (
    <CryptoHoldingItemInput
      key={i}
      holding={h}
      index={i}
      onSymbolChange={onSymbolChange}
      onChange={onChange}
      onRemove={onRemove}
    />
  );

  if (displayOnly) {
    return (
      <>
        {holdingsMap.map((h) => {
          return (
            <AccountInformationValueCard
              key={h.symbol}
              type={ValueCardType.Money}
              title={`${h.name} (${h.symbol})`}
              value={h.price * h.total}
            />
          );
        })}
      </>
    );
  }

  if (isConnected) {
    return null;
  }

  return (
    <div>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        children={
          <>
            <Text>
              Are you sure you want to delete your{" "}
              <b>{`${holdingsMap[indexToDelete || 0]?.name}`}</b> holdings from
              your <b>{`${account.name}`}</b> portfolio?
            </Text>
          </>
        }
        onAction={() => {
          const h = [...holdingsMap];
          if (h.length > (indexToDelete || 0)) {
            h.splice(indexToDelete || 0, 1);
            setHoldingsMap([...h]);
          }
          onUpdate?.({
            details: {
              cryptoDetails: {
                holdings: [...h],
              },
            },
          });
          setIsDirty(false);
          setIndexToDelete(undefined);
          setShowModal(false);
        }}
        actionButtonText={"Delete"}
        onCancel={() => {
          setIndexToDelete(undefined);
          setShowModal(false);
        }}
        cancelButtonText="Cancel"
      />

      <div className="add-account-tray__step-selection">
        <div className="add-account-tray__step-selection">
          <TextD style={TextStyle.M15} colour={Colour.Black}>
            Holdings
          </TextD>
          <div>
            {holdingsMap.map((h, i) =>
              h.name !== "" ? renderHolding(h, i) : renderHoldingInput(h, i)
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0.5rem 0",
            }}
          >
            <LeafButton
              buttonColour={LeafButtonColour.Transparent}
              buttonStyle={LeafButtonStyle.LeafSmallDense}
              onClick={() => {
                setHoldingsMap([
                  ...holdingsMap.concat([
                    {
                      id: "",
                      symbol: "",
                      name: "",
                      price: 0,
                      total: 0,
                    },
                  ]),
                ]);
                setIsDirty(true);
              }}
            >
              <div
                style={{
                  marginRight: ".5rem",
                  fontSize: ".75rem",
                  textTransform: "uppercase",
                }}
              >
                Add Crypto
              </div>
            </LeafButton>
            {isDirty && (
              <LeafButton
                buttonColour={LeafButtonColour.Black}
                buttonStyle={LeafButtonStyle.LeafSmallDense}
                onClick={() => {
                  onUpdate?.({
                    details: {
                      cryptoDetails: {
                        holdings: holdingsMap,
                      },
                    },
                  });
                  setIsDirty(false);
                }}
              >
                Save
              </LeafButton>
            )}
          </div>
          <div>
            <Text type={TextType.Div} style={TextStyle.Hint}>
              Cryptocurrency prices powered by{" "}
              <PylonALink href="https://www.coingecko.com/en" target="_blank">
                CoinGecko
              </PylonALink>
              .
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

interface HoldingProps {
  index: number;
  holding: PylonCoinType;
  onChange: (i: number, val: string) => PylonCoinType[];
  onRemove: (i: number) => void;
}

interface UpdateProps {
  onBlurUpdate: (m: PylonCoinType[]) => void;
}

type HoldingItemProps = HoldingProps & UpdateProps;
export const CryptoHoldingItem: React.FC<HoldingItemProps> = ({
  index,
  holding,
  onBlurUpdate,
  onChange,
  onRemove,
}) => {
  const [hoverRef, isHovering] = useHover<HTMLDivElement>();

  return (
    <div ref={hoverRef} style={{ width: "100%", margin: "1rem 0" }}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginRight: "0.5rem",
          }}
        >
          <Text colour={Colour.Gray70} weight={400} type={TextType.Div}>
            {holding.symbol.toUpperCase()}
          </Text>
        </div>
        <div style={{ marginRight: "0.5rem" }}>
          <Text colour={Colour.Navy} weight={600} type={TextType.Div}>
            {holding.name}
          </Text>
        </div>
        <Text colour={Colour.Navy} type={TextType.Div}>
          {toMoneyString(holding.price * holding.total)}
        </Text>
        <div
          style={{
            cursor: "pointer",
            visibility: isHovering ? "visible" : "hidden",
          }}
          onClick={() => {
            onRemove(index);
          }}
        >
          <Icon asset={IconAsset.Trashcan} width="36px" height="24px" />
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: "13rem",
          }}
        >
          <ModalBufferedInput
            id="account-crypto-number-input"
            type="number"
            onChange={(e) => {
              if (e) {
                onBlurUpdate(onChange(index, e));
              }
            }}
            value={holding.total || ""}
            currencyOverrides={{
              decimalsLimit: 10,
            }}
          />
        </div>
        <div style={{ margin: "0 0.5rem" }}>
          <Text size={"0.875rem"} colour={Colour.Gray70} type={TextType.Div}>
            {" @ "}
          </Text>
        </div>
        <Text size={"0.875rem"} colour={Colour.Gray70} type={TextType.Div}>
          {toMoneyString(holding.price, undefined, holding.price < 1 ? 4 : 2)}
        </Text>
      </div>
    </div>
  );
};

interface InputProps {
  onSymbolChange: (i: number, val: string) => void;
}

type HoldingInputProps = HoldingProps & InputProps;
export const CryptoHoldingItemInput: React.FC<HoldingInputProps> = ({
  holding,
  index,
  onChange,
  onSymbolChange,
  onRemove,
}) => {
  const [hoverRef, isHovering] = useHover<HTMLDivElement>();

  return (
    <div ref={hoverRef} style={{ width: "13rem", margin: "1rem 0" }}>
      <div
        className="flex-column gap-half"
        style={{
          width: "13rem",
        }}
      >
        <div style={{ display: "flex" }}>
          <TextD style={TextStyle.M15}>Symbol (e.g. BTC, ETH)</TextD>
          <div
            style={{
              cursor: "pointer",
              visibility: isHovering ? "visible" : "hidden",
            }}
            onClick={() => {
              onRemove(index);
            }}
          >
            <Icon asset={IconAsset.Trashcan} width="36px" height="24px" />
          </div>
        </div>
        <ModalInput
          id="add-new-coin"
          type="string"
          onChange={(v) => {
            if (v) {
              onSymbolChange(index, v);
            }
          }}
          value={holding.symbol}
        />
      </div>
      <ModalBufferedInput
        id="num-coin"
        type="number"
        label="Number of Coins"
        onChange={(e) => {
          if (e) {
            onChange(index, e);
          }
        }}
        value={holding.total}
        currencyOverrides={{
          decimalsLimit: 10,
        }}
      />
    </div>
  );
};
