import { AssetType } from "components/features/dashboard/models/sharing";
import { gql } from "@apollo/client";

export enum UserNotificationType {
  AssetShared = "AssetShared",
  AssetRemoved = "AssetRemoved",
  AssetUnshared = "AssetUnshared",
  NewCollaboration = "NewCollaboration",
  AccessRequested = "AccessRequested",
  AccountBalanceUpdated = "AccountBalanceUpdated",
  PermissionUpdated = "PermissionUpdated",
  UserRevokedAccountPermission = "UserRevokedAccountPermission",
}

export interface UserNotification {
  id: string;
  notificationType: UserNotificationType;
  message: string;
  assetID?: string;
  assetType?: AssetType;
  createdAt: string;
  viewedAt?: string;
}

export interface GetPendingNotificationCountResponse {
  pendingNotificationCount: {
    count: number;
  };
}

export interface GetPendingNotificationRequest {
  limit: number;
  offset: number;
}

export interface GetPendingNotificationResponse {
  pendingNotifications: {
    total: number;
    notifications: UserNotification[];
  };
}

export const GetPendingNotfications = gql`
  query GetPendingNotifications($offset: Int, $limit: Int) {
    pendingNotifications(offset: $offset, limit: $limit) {
      notifications {
        id
        notificationType
        message
        assetID
        assetType
        createdAt
        viewedAt
      }
      total
    }
  }
`;

export const GetPendingNotficationCount = gql`
  query GetPendingNotificationCount {
    pendingNotificationCount {
      count
    }
  }
`;

export const MarkNotificationsAsViewedMutation = gql`
  mutation MarkNotificationAsViewed {
    markNotificationAsViewed
  }
`;
