import * as React from "react";
import { DefaultPreviewIcon } from "components/core/file-viewer/default-preview-icon";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { useState } from "react";
import "./styles.css";

export interface Props {
  src: string;
  preview?: boolean;
  className?: string;
}

export const PDFViewer: React.FC<Props> = ({ src, preview, className }) => {
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  let pages: React.ReactNode[] = [];

  for (let i = 1; i <= numPages; i++) {
    pages.push(<Page key={i} pageNumber={i} />);
  }

  if (preview) {
    const firstPage = pages.length > 0 ? pages[0] : null;

    return (
      <Document
        file={{
          url: src,
        }}
        className={`${className} preview`}
        onLoadSuccess={onDocumentLoadSuccess}
        error={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <DefaultPreviewIcon format={"pdf"} />
          </div>
        }
      >
        {firstPage}
      </Document>
    );
  }

  return (
    <div className={className} style={{ position: "relative" }}>
      <Document
        file={{
          url: src,
        }}
        className="pdf-container-size"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pages}
      </Document>
    </div>
  );
};
