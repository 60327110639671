import { LandingPageDefinition } from "components/features/landing";
import { RegisterButton } from "components/features/landing/components/register";
import SVGLandingNetworth from "components/features/landing/dashboard-recent.svg";
import { PublicMobileNavigation } from "components/features/login/components/mobile-navigation";
import { PublicHeader } from "components/features/public/header";
import { HomeEmails } from "components/home/components/home-emails";
import { useMobileLayout } from "core/hooks/responsive";
import "../all-landing.css";
import "./styles.css";

export const LandingPageNetworth1: React.FC = () => {
  const mobileLayout = useMobileLayout();
  return (
    <div>
      {!mobileLayout && <PublicHeader transparentBackground hideIcon />}
      {mobileLayout && (
        <PublicMobileNavigation redirectToHome={true} hideMenu={true} />
      )}
      <div className="home">
        <HomeEmails title="Calculate your Networth with Pylon." />
      </div>
    </div>
  );
};

export const LandingPageNetworth1a: LandingPageDefinition = {
  Path: "/landing/networth/calculate-your-networth-a",
  Component: () => {
    return (
      <div className="taxes-1">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <h1 className="title-line-2">
              Calculate your Networth with Pylon.
            </h1>
            <div>
              <RegisterButton optionalParam="skip=true" />
            </div>
          </div>
          <div className="landing-right">
            <div style={{ border: "2px solid #eeeeee" }}>
              <img
                src={SVGLandingNetworth}
                width="100%"
                alt="Calculate Networth"
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
};
