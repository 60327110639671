import * as React from "react";
import { Colour } from "core/models";
import { ConfirmationModal } from "components/core/modal";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { DELETE_DOCUMENT } from "core/queries/documents";
import { DocumentData } from "components/features/dashboard/models/document";
import { Icon, IconAsset } from "components/core/icons";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { SessionContext } from "../../../../contexts/session-context-provider";
import { Text, TextStyle, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

interface Props {
  document?: DocumentData | null;
  onDelete: () => void;
}

export const DeleteDocumentQuickAction: React.FC<Props> = ({
  document,
  onDelete,
}) => {
  const [showDelete, setShowDelete] = React.useState<boolean>(false);
  const { userID } = React.useContext(SessionContext.context);
  const isSharedWithMe = document?.owner?.id !== userID;
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const [deleteDocument, { loading }] = useMutation(DELETE_DOCUMENT, {
    onCompleted: () => {
      toast(
        <PylonToastBody
          title={"Document deleted"}
          body={"Document has been deleted"}
        />
      );
      onDelete();
      setShowDelete(false);
    },
    refetchQueries: onUpdateRefetchQueries,
  });

  if (!document || isSharedWithMe) {
    return null;
  }

  return (
    <>
      <ConfirmationModal
        title="Delete Document"
        children={<div>Are you sure you want to delete this document?</div>}
        showModal={showDelete}
        onAction={() => {
          deleteDocument({
            variables: {
              id: document.id,
            },
          });
        }}
        onClose={() => {
          setShowDelete(false);
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
        cancelButtonText="Cancel"
        actionButtonText="Delete"
        actionButtonLoading={loading}
        defaultStyling
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <div
        className="flex-row align-center"
        onClick={() => {
          setShowDelete(true);
        }}
        style={{
          cursor: "pointer",
          gap: "0.5rem",
        }}
      >
        <Icon
          asset={IconAsset.TrashcanAlt}
          width="16px"
          height="16px"
          colour={Colour.Black}
        />
        <Text style={TextStyle.M15} type={TextType.Div}>
          Delete
        </Text>
      </div>
    </>
  );
};
