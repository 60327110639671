import * as React from "react";

import { Request2FAVerificationResponse } from "core/api";
import { createContext } from "core/utils/context";

export interface LoginContextValue {
  email: string;
  redirectTo?: string;
  identityToken?: string;
  twoFARequest?: Request2FAVerificationResponse;
  phoneNumber?: string; // Only present if user is going through invitation signup

  setTwoFARequest: (twoFARequest: Request2FAVerificationResponse) => void;
  setEmail: (email: string) => void;

  setPhoneNumber: (phone: string) => void;
  setIdentityToken: (token: string) => void;
  setRedirectTo: (page: string) => void;
}

export const LoginContext = createContext<LoginContextValue>({
  email: "",

  setTwoFARequest: () => {},
  setEmail: () => {},

  setPhoneNumber: () => {},
  setRedirectTo: () => {},
  setIdentityToken: () => {},
});

export const withLoginContext = LoginContext.withContext;

interface Props {}

export class LoginContextProvider extends React.Component<
  Props,
  LoginContextValue
> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      email: "",

      setTwoFARequest: this.setTwoFARequest,
      setIdentityToken: this.setIdentityToken,
      setEmail: this.setEmail,
      setRedirectTo: this.setRedirectTo,
      setPhoneNumber: this.setPhoneNumber,
    };
  }

  private setIdentityToken = (token: string) => {
    this.setState({
      identityToken: token,
    });
  };
  private setPhoneNumber = (phone: string) => {
    this.setState({
      phoneNumber: phone,
    });
  };

  private setEmail = (email: string) => {
    this.setState({
      email: email,
    });
  };

  private setRedirectTo = (redirectTo: string) => {
    this.setState({
      redirectTo: redirectTo,
    });
  };

  private setTwoFARequest = (request: Request2FAVerificationResponse) => {
    this.setState({
      twoFARequest: request,
    });
  };

  public render() {
    return (
      <LoginContext.InnerProvider value={this.state}>
        {this.props.children}
      </LoginContext.InnerProvider>
    );
  }
}
