import { PylonTip } from "components/core/pylon-tip";
import { Text, TextFormat, TextStyle } from "components/core/text";
import PngTaxes from "core/assets/onboarding-taxes.png";
import { useMobileLayout } from "core/hooks/responsive";
import { Colour } from "core/models";
import React from "react";

export const TaxPlanningPresentation: React.FC = () => {
  const mobileLayout = useMobileLayout();
  const rendered = React.useMemo(() => {
    const o = [
      <>
        <div className="onboarding-screenshots">
          <div
            style={{
              margin: "1rem 0",
            }}
          >
            <PylonTip />
          </div>
          <h4
            style={{
              margin: "1rem 0",
              marginBottom: "2rem",
              fontSize: "3.5vh",
              lineHeight: "3.5vh",
            }}
          >
            Tax planning just got easier.
          </h4>
          <div
            style={{
              margin: "1rem 0",
              marginBottom: "2rem",
            }}
          >
            <Text style={TextStyle.Poppins} weight={400} size={"2.5vh"}>
              Pylon's tax estimator helps you determine if you're going to owe
              federal or state taxes for all 50 states.
            </Text>
          </div>
          <div
            style={{
              marginTop: "4rem",
            }}
          >
            <div>
              <Text
                style={TextStyle.FieldLabel}
                weight={700}
                size={"0.875rem"}
                format={TextFormat.UpperCase}
              >
                Feature Set
              </Text>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                  paddingRight: "1rem",
                }}
              >
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Federal
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    State
                  </Text>
                </div>
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    Capital Gains
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "1rem 0",
                  paddingRight: "1rem",
                }}
              >
                <div className="onboarding-pill">
                  <Text
                    colour={Colour.Navy}
                    weight={700}
                    size={"0.75rem"}
                    format={TextFormat.UpperCase}
                  >
                    exemptions
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      <>
        <div className={"manage-img"}>
          <img src={PngTaxes} alt="Taxes" />
        </div>
      </>,
    ];
    return mobileLayout ? o.reverse() : o;
  }, [mobileLayout]);

  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            Let Pylon help you{" "}
            <span style={{ color: "var(--blue-01)" }}>plan</span>, for any
            situation
            <span style={{ color: "var(--blue-01)" }}>.</span>
          </div>
        </div>
      </div>
      <div className="onboarding-middle">{rendered}</div>
    </div>
  );
};
