import React, { useEffect, useState } from "react";
import { AuthorizedPage } from "components/features/login/components/authorized-page";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { Logo } from "components/core/logo";
import { OnboardingHowDoYouTrack } from "components/features/onboarding/flows/standard/how-do-you-track";
import { OnboardingSecurity } from "components/features/onboarding/flows/standard/security-page";
import { OnboardingSharingPresentation } from "components/features/onboarding/flows/standard/sharing-presentation";
import { OnboardingSummary } from "components/features/onboarding/flows/standard/summary";
import { OnboardingUnifiedViewPresentation } from "components/features/onboarding/flows/standard/unified-view-presentation";
import { OnboardingWhatElseDoYouWant } from "components/features/onboarding/flows/standard/what-else-do-you-want";
import { OnboardingWhatIsYourMaritalStatus } from "components/features/onboarding/flows/standard/what-is-your-marital-status";
import { SelectionState } from "components/features/onboarding/flows/utils";
import { TaxPlanningPresentation } from "components/features/onboarding/flows/standard/tax-planning-presentation";
import { Text, TextType } from "components/core/text";
import { trackEvent } from "core/distribution/distribution";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import { useMutation, useQuery } from "@apollo/client";
import { WelcomePage } from "components/features/onboarding/flows/standard/welcome";
import "./styles.css";
import {
  OnboardingCard,
  OnboardingCardSize,
} from "components/features/onboarding/components/onboarding-card";
import {
  comingSoon,
  currentOfferings,
  WhatToDoSelections,
} from "components/features/onboarding/models";
import {
  completeMutation,
  OnboardingState,
  OnboardingStateQuery,
  SaveOnboardingPreferences,
} from "core/queries/onboarding";
import {
  makeHeight100Percent,
  makeHeight100VH,
  scrollToTop,
} from "core/utils/scrolling";

const MAX_PAGES = 8;

export const StandardOnboardingPage: React.FC = () => {
  const mobileLayout = useMobileLayout();
  const { data, loading } = useQuery<OnboardingState>(OnboardingStateQuery);
  const [state, setState] = useState<SelectionState>({});
  const [pageState, setPageState] = useState<number>(0);
  const history = useHistory();

  const [saveOnboardingPreference] = useMutation(SaveOnboardingPreferences, {
    refetchQueries: [{ query: OnboardingStateQuery }],
  });
  const [completeOnboardingMutation] = useMutation(completeMutation, {
    variables: { flow: "standard" },
  });

  const optimisticUpdate = (
    dataKey: string,
    selected: boolean,
    // optionally disable keys, used for select
    keysToMakeFalse?: string[]
  ) => {
    const mutationObj = {
      [dataKey]: selected,
    };

    if (keysToMakeFalse) {
      for (let k of keysToMakeFalse) {
        mutationObj[k] = false;
      }
    }

    saveOnboardingPreference({
      variables: {
        prefs: Object.keys(mutationObj).map((k) => ({
          key: k,
          value: mutationObj[k],
        })),
      },
    });
    setState({
      ...state,
      ...mutationObj,
    });
  };

  const selectionToCard = {
    [WhatToDoSelections.Networth]: (
      <OnboardingCard
        id={WhatToDoSelections.Networth}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.Networth,
            !!!state?.[WhatToDoSelections.Networth]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingMoney}
        text={"See my net worth"}
        selected={false}
      />
    ),

    [WhatToDoSelections.Share]: (
      <OnboardingCard
        id={WhatToDoSelections.Share}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.Share,
            !!!state?.[WhatToDoSelections.Share]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingShare}
        text={"Collaborate on my finances"}
        selected={false}
      />
    ),

    [WhatToDoSelections.TaxObligation]: (
      <OnboardingCard
        id={WhatToDoSelections.TaxObligation}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.TaxObligation,
            !!!state?.[WhatToDoSelections.TaxObligation]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingTax}
        text={"See my real-time tax obligation"}
        selected={false}
      />
    ),

    [WhatToDoSelections.SaveDocuments]: (
      <OnboardingCard
        id={WhatToDoSelections.SaveDocuments}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.SaveDocuments,
            !!!state?.[WhatToDoSelections.SaveDocuments]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingDocuments}
        text={"Save important documents"}
        selected={false}
      />
    ),

    [WhatToDoSelections.SeeRetirementAge]: (
      <OnboardingCard
        id={WhatToDoSelections.SeeRetirementAge}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.SeeRetirementAge,
            !!!state?.[WhatToDoSelections.SeeRetirementAge]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingOldGuy}
        text={"See my current retirement age"}
        selected={false}
      />
    ),

    [WhatToDoSelections.TrackCreditScore]: (
      <OnboardingCard
        id={WhatToDoSelections.TrackCreditScore}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.TrackCreditScore,
            !!!state?.[WhatToDoSelections.TrackCreditScore]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingCreditScore}
        text={"Track my credit score"}
        selected={false}
      />
    ),
    [WhatToDoSelections.SellPrivateStock]: (
      <OnboardingCard
        id={WhatToDoSelections.SellPrivateStock}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.SellPrivateStock,
            !!!state?.[WhatToDoSelections.SellPrivateStock]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingSellPrivateStock}
        text={"Sell privately owned stock options"}
        selected={false}
      />
    ),

    [WhatToDoSelections.LowerInterestRate]: (
      <OnboardingCard
        id={WhatToDoSelections.LowerInterestRate}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.LowerInterestRate,
            !!!state?.[WhatToDoSelections.LowerInterestRate]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingLowerInterest}
        text={"Lower any interest rate"}
        selected={false}
      />
    ),

    [WhatToDoSelections.PayOffDebt]: (
      <OnboardingCard
        id={WhatToDoSelections.PayOffDebt}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.PayOffDebt,
            !!!state?.[WhatToDoSelections.PayOffDebt]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingPayOffDebt}
        text={"Pay off debt faster"}
        selected={false}
      />
    ),

    [WhatToDoSelections.Open529]: (
      <OnboardingCard
        id={WhatToDoSelections.Open529}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.Open529,
            !!!state?.[WhatToDoSelections.Open529]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingOpen529}
        text={"Open a 529 plan for my kids"}
        selected={false}
      />
    ),

    [WhatToDoSelections.CompareNetworth]: (
      <OnboardingCard
        id={WhatToDoSelections.CompareNetworth}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.CompareNetworth,
            !!!state?.[WhatToDoSelections.CompareNetworth]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingCompareNetworth}
        text={"Compare my net worth with others"}
        selected={false}
      />
    ),

    [WhatToDoSelections.HelpParentsOrganize]: (
      <OnboardingCard
        id={WhatToDoSelections.HelpParentsOrganize}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.HelpParentsOrganize,
            !!!state?.[WhatToDoSelections.HelpParentsOrganize]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingHelpParents}
        text={"Help my parents get organized"}
        selected={false}
      />
    ),

    [WhatToDoSelections.SetEmergencyContact]: (
      <OnboardingCard
        id={WhatToDoSelections.SetEmergencyContact}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.SetEmergencyContact,
            !!!state?.[WhatToDoSelections.SetEmergencyContact]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingSetEmergencyContact}
        text={"Emergency Contacts"}
        selected={false}
      />
    ),

    [WhatToDoSelections.CheckCredit]: (
      <OnboardingCard
        id={WhatToDoSelections.CheckCredit}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.CheckCredit,
            !!!state?.[WhatToDoSelections.CheckCredit]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingCheckCredit}
        text={"Check my credit"}
        selected={false}
      />
    ),

    [WhatToDoSelections.TrackCashflow]: (
      <OnboardingCard
        id={WhatToDoSelections.TrackCashflow}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.TrackCashflow,
            !!!state?.[WhatToDoSelections.TrackCashflow]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingTrackCashflow}
        text={"Track monthly cash flow"}
        selected={false}
      />
    ),

    [WhatToDoSelections.TrackInvestmentsInOnePlace]: (
      <OnboardingCard
        id={WhatToDoSelections.TrackInvestmentsInOnePlace}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.TrackInvestmentsInOnePlace,
            !!!state?.[WhatToDoSelections.TrackInvestmentsInOnePlace]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingTrackInvestments}
        text={"Track all of my investments"}
        selected={false}
      />
    ),

    [WhatToDoSelections.BuyProperty]: (
      <OnboardingCard
        id={WhatToDoSelections.BuyProperty}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.BuyProperty,
            !!!state?.[WhatToDoSelections.BuyProperty]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingBuyProperty}
        text={"Buy property"}
        selected={false}
      />
    ),

    [WhatToDoSelections.BuyVehicle]: (
      <OnboardingCard
        id={WhatToDoSelections.BuyVehicle}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.BuyVehicle,
            !!!state?.[WhatToDoSelections.BuyVehicle]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingBuyVehicle}
        text={"Buy a vehicle"}
        selected={false}
      />
    ),

    [WhatToDoSelections.InvestInPrivateCompanies]: (
      <OnboardingCard
        id={WhatToDoSelections.InvestInPrivateCompanies}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.InvestInPrivateCompanies,
            !!!state?.[WhatToDoSelections.InvestInPrivateCompanies]
          );
        }}
        disabled={true}
        asset={IconAsset.OnboardingInvestPrivately}
        text={"Invest in private companies"}
        selected={false}
      />
    ),
    [WhatToDoSelections.EstimateMyTaxes]: (
      <OnboardingCard
        id={WhatToDoSelections.EstimateMyTaxes}
        size={OnboardingCardSize.Summary}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.EstimateMyTaxes,
            !!!state?.[WhatToDoSelections.EstimateMyTaxes]
          );
        }}
        disabled={true}
        asset={IconAsset.IncomeAndTaxes}
        text={"Invest in private companies"}
        selected={false}
      />
    ),
  };

  const getCurrentSummaryCards = () => {
    const ret = [];
    if (state) {
      for (let k of Object.keys(state)) {
        if (state[k] && selectionToCard[k as WhatToDoSelections]) {
          if (currentOfferings.includes(k as WhatToDoSelections)) {
            ret.push(selectionToCard[k as WhatToDoSelections]);
          }
        }
      }
    }
    return ret;
  };

  const getComingSoonSummaryCards = () => {
    const ret = [];
    if (state) {
      for (let k of Object.keys(state)) {
        if (state[k] && selectionToCard[k as WhatToDoSelections]) {
          if (comingSoon.includes(k as WhatToDoSelections)) {
            ret.push(selectionToCard[k as WhatToDoSelections]);
          }
        }
      }
    }
    return ret;
  };

  useEffect(() => {
    if (!loading && data) {
      setState(JSON.parse(data.onboardingState.preferences));
    }
  }, [loading, data]);

  useEffect(() => {
    scrollToTop();
    if (pageState === 8) {
      makeHeight100Percent();
    } else {
      makeHeight100VH();
    }
  }, [pageState]);

  const updatePageState = (i: number) => {
    setPageState(i);
    trackEvent(
      `Onboarding ${i}`,
      data?.onboardingState?.preferences
        ? JSON.parse(data?.onboardingState?.preferences)
        : {}
    );
  };
  const renderPage = () => {
    switch (pageState) {
      case 0:
        return (
          <WelcomePage
            state={state}
            optimisticUpdate={optimisticUpdate}
            onNext={() => {
              updatePageState(1);
            }}
          />
        );
      case 1:
        return (
          <OnboardingHowDoYouTrack
            state={state}
            optimisticUpdate={optimisticUpdate}
          />
        );
      case 2:
        return <OnboardingUnifiedViewPresentation />;
      case 3:
        return <OnboardingSecurity />;
      case 4:
        return (
          <OnboardingWhatIsYourMaritalStatus
            state={state}
            optimisticUpdate={optimisticUpdate}
          />
        );
      case 5:
        return <OnboardingSharingPresentation />;
      case 6:
        return <TaxPlanningPresentation />;
      case 7:
        return (
          <OnboardingWhatElseDoYouWant
            titleElement={
              <div>
                What else do you want to{" "}
                <span style={{ color: "var(--blue-01)" }}>track</span>,{" "}
                <span style={{ color: "var(--blue-01)" }}>share</span>, and{" "}
                <span style={{ color: "var(--blue-01)" }}>plan?</span>
              </div>
            }
            state={state}
            optimisticUpdate={optimisticUpdate}
          />
        );
      case 8:
        return (
          <OnboardingSummary
            comingSoonCards={getComingSoonSummaryCards()}
            currentSummaryCards={getCurrentSummaryCards()}
          />
        );
      default:
        return (
          <WelcomePage state={state} optimisticUpdate={optimisticUpdate} />
        );
    }
  };

  const complete = () => {
    makeHeight100VH();
    completeOnboardingMutation();

    history.push({
      pathname: DashboardPages.Integrations,
      search: "ft=",
    });
  };

  const footerStyle: React.CSSProperties = mobileLayout
    ? {
        marginTop: "1rem",
        width: "100%",
        textAlign: "center",
        fontStyle: "italic",
        marginBottom: "2rem",
      }
    : {
        height: "4rem",
        display: "flex",
        justifyContent: "center",
        fontStyle: "italic",
      };
  return (
    <AuthorizedPage>
      <div className="onboarding-page__bg-wrapper">
        {mobileLayout && (
          <div style={{ padding: "2vh 4vw" }}>
            <Logo />
          </div>
        )}
        <div className="onboarding-page-wrapper">
          {renderPage()}
          <div
            className="onboarding-bottom"
            style={{
              height: pageState === 7 ? "8vh" : "15vh",
            }}
          >
            {pageState > 0 && (
              <>
                <div
                  id={`previous-step-${pageState}`}
                  style={{
                    height: "3.5rem",
                    width: "3.5rem",
                    marginRight: "0.5rem",
                    color: "var(--text-03)",
                    border: "2px solid var(--separator-02)",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "10px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => updatePageState(pageState - 1)}
                >
                  <Icon
                    asset={IconAsset.BackButton}
                    height={"19px"}
                    width={"11px"}
                  />
                </div>
                <LeafButton
                  id={`next-step-${pageState}`}
                  onClick={() => {
                    pageState < MAX_PAGES
                      ? updatePageState(pageState + 1)
                      : complete();
                  }}
                >
                  {pageState < MAX_PAGES ? "Next Step" : "Get Started"}
                </LeafButton>
              </>
            )}
          </div>
          {pageState === 7 && (
            <div style={footerStyle}>
              <Text type={TextType.Div}>* Coming soon or in development!</Text>
            </div>
          )}
        </div>
      </div>
    </AuthorizedPage>
  );
};
