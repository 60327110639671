import React, { KeyboardEventHandler } from "react";
import { Text, TextStyle, TextType } from "components/core/text";
import "./styles.css";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface ValidationProps {
  // Only one of regex or validation function should be provided
  inputRegex?: RegExp;
  validationFunc?: (s: string) => boolean; // Return true if s is valid input
  errorMessage?: string;
}

interface PublicProps {
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  onEnter?: () => void;
  keyPressValidation?: ValidationProps; // If specified, every key press will trigger this validation
  onBlurValidate?: ValidationProps[]; // If specified, validation will run on blur
}

export type Props = PublicProps & InputProps;

export const Input: React.FC<Props> = (props) => {
  const {
    value,
    children,
    inputWrapperClassName,
    wrapperClassName,
    onEnter,
    keyPressValidation,
    onBlurValidate,
    onKeyPress,
    onChange,
    ...inputProps
  } = props;
  const [errMessage, setErrMessage] = React.useState("");

  const onKeyPressHandler = withKeyPressValidation(
    () => setErrMessage(""),
    (s) => setErrMessage(s),
    keyPressValidation,
    onEnter,
    onKeyPress
  );

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrMessage("");
    onChange?.(e);
  };

  return (
    <div className={wrapperClassName}>
      <div className={inputWrapperClassName}>
        <input
          {...inputProps}
          value={value === null ? undefined : value}
          onBlur={(e) => {
            if (onBlurValidate && onBlurValidate.length > 0) {
              for (const validation of onBlurValidate) {
                if (
                  validation.inputRegex?.test(e.currentTarget.value) ||
                  validation.validationFunc?.(e.currentTarget.value)
                ) {
                  inputProps?.onBlur?.(e);
                } else {
                  if (validation.errorMessage) {
                    setErrMessage(validation.errorMessage);
                  }
                  e.preventDefault();
                }
              }
            } else {
              inputProps?.onBlur?.(e);
            }
          }}
          className={`pylon-input ${inputProps.className}`}
          onKeyPress={onKeyPressHandler}
          onChange={onChangeHandler}
        />
        {children}
      </div>
      {errMessage && (
        <Text type={TextType.Div} style={TextStyle.Error}>
          {errMessage}
        </Text>
      )}
    </div>
  );
};

const withOnEnter = (
  onEnter?: () => void,
  previous?: KeyboardEventHandler<HTMLInputElement>
) => {
  if (!onEnter) {
    return previous;
  }

  return (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEnter();
    } else {
      previous?.(e);
    }
  };
};

const withKeyPressValidation = (
  onValid: () => void,
  onError: (msg: string) => void,
  keyPressValidation?: ValidationProps,
  onEnter?: () => void,
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>
) => {
  const previousChain = withOnEnter(onEnter, onKeyPress);
  if (!keyPressValidation) {
    return previousChain;
  }

  return (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && e.key === "Enter") {
      onEnter();
    } else if (
      keyPressValidation.inputRegex?.test(e.key) ||
      keyPressValidation.validationFunc?.(e.key)
    ) {
      onValid();
      previousChain?.(e); // Chain to previous validation as input is valid
    } else {
      if (keyPressValidation.errorMessage) {
        // trigger onerror callback when input is invalid
        onError(keyPressValidation.errorMessage);
      }
      e.preventDefault();
    }
  };
};
