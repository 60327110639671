import { Account } from "core/models";
import { gql } from "@apollo/client";

export interface InvestmentHolding {
  id: string;
  userID: string;
  costBasis: number;
  closePrice: number;
  institutionPrice: number;
  institutionValue: number;
  quantity: number;
  name: string;
  ticker: string;
  isCash: boolean;
  account: Account;
  type: string;
}

export interface InvestmentHoldings {
  holdings: InvestmentHolding[];
  total: number;
}

export interface InvestmentHoldingsResponse {
  investmentHoldings: InvestmentHoldings;
}

export const INVESTMENT_HOLDING_FIELDS = gql`
  fragment InvestmentHoldingFields on InvestmentHolding {
    id
    userID
    costBasis
    closePrice
    institutionPrice
    institutionValue
    quantity
    name
    ticker
    isCash
    type
    accountID
    account {
      logo
      type
      primaryColorHex
      institutionName
      account_id
      owner {
        id
      }
    }
  }
`;

export const FETCH_INVESTMENT_HOLDINGS = gql`
  ${INVESTMENT_HOLDING_FIELDS}
  query fetchInvestmentHoldings($accountID: String, $offset: Int, $limit: Int) {
    investmentHoldings(accountID: $accountID, offset: $offset, limit: $limit) {
      total
      holdings {
        ...InvestmentHoldingFields
      }
    }
  }
`;

export const INVESTMENT_TRANSACTION_FIELDS = gql`
  fragment InvestmentTransactionFields on InvestmentTransaction {
    id
    amount
    quantity
    price
    notes
    fees
    transactionType
    transactionSubtype
    securityHoldingID
    date
    remainingShares
    createdAt
  }
`;

// export const GetInvestmentTaxes = gql`
//   ${ACCOUNT_FIELDS}
//   ${INVESTMENT_TRANSACTION_FIELDS}
//   query InvestmentTaxes($taxYear: Int) {
//     investmentTaxes(taxYear: $taxYear) {
//       accountID
//       # totalLoss
//       # totalGain
//       # allTransactions {
//       #   ...InvestmentTransactionFields
//       # }
//       # sellOnlyTransactions {
//       #   ...InvestmentTransactionFields
//       # }
//       # buysWithNoHoldings {
//       #   ...InvestmentTransactionFields
//       # }
//       account {
//         ...AccountFields
//       }
//     }
//   }
// `;
