import * as React from "react";
import { AssetType } from "components/features/dashboard/models/sharing";
import { RecipientListRow } from "components/features/dashboard/components/manage-account-modal/recipient-list/components/recipient-list-row";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  Asset,
  isAccount,
  isCreditHistory,
  isDocument,
  isDocumentCategory,
  isScenarioPlan,
} from "core/models/asset";
import {
  recipientsForAssetQuery,
  recipientsForAssetQueryResponse,
} from "core/queries/collaborations";

interface Props {
  asset: Asset;
  compressedRows?: boolean;
}

export const RecipientList: React.FC<Props> = ({ asset, compressedRows }) => {
  let assetID: string;
  let assetType: AssetType;

  if (isAccount(asset)) {
    assetID = asset.account_id;
    assetType = AssetType.Account;
  } else if (isDocument(asset)) {
    assetID = asset.id;
    assetType = AssetType.Document;
  } else if (isDocumentCategory(asset)) {
    assetID = asset.id;
    assetType = AssetType.DocumentFolder;
  } else if (isScenarioPlan(asset)) {
    assetID = asset.id;
    assetType = AssetType.ScenarioPlan;
  } else if (isCreditHistory(asset)) {
    assetID = asset.ownerid || "";
    assetType = AssetType.CreditHistory;
  } else {
    console.error("Unrecognized asset in recipient", asset);
    throw new Error("Unrecognized asset in recipient");
  }

  const { loading, data, error } = useQuery(recipientsForAssetQuery, {
    variables: {
      assetID: assetID,
      assetType: assetType,
    },
  });

  const filteredRecipients = React.useMemo<
    recipientsForAssetQueryResponse[]
  >(() => {
    return data?.recipientsForAsset?.filter(
      (r: recipientsForAssetQueryResponse) => {
        return !r.isDeleted;
      }
    );
  }, [data]);

  if (error || loading) {
    return null;
  }

  return (
    <div style={{ marginTop: compressedRows ? "0.5rem" : "1rem" }}>
      {filteredRecipients?.map((r: recipientsForAssetQueryResponse) => (
        <RecipientListRow
          key={r.sharedDataID}
          recipient={r}
          assetID={assetID}
          assetType={assetType}
          compressedRow={compressedRows}
        />
      ))}
    </div>
  );
};
