import { CashflowCategorizedSummary } from "core/queries/transactions";
import { Colour } from "core/models";
import { SpouseToggle } from "components/features/dashboard/components/chart-cashflow-daily/spouse-toggle";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { toMoneyString } from "core/utils";
import "./styles.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipWrapperDisplay,
} from "components/core/tooltip";

export interface Props {
  expenses: CashflowCategorizedSummary[];
  income: CashflowCategorizedSummary[];
  total: number;
  showAll: boolean;
  onShowAllToggle: () => void;
}

export const CashflowSummary: React.FC<Props> = ({
  expenses,
  income,
  total,
  showAll,
  onShowAllToggle,
}) => {
  return (
    <div
      style={{
        paddingTop: "1.5rem",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem",
          height: "25.5rem",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "0.25rem",
        }}
      >
        <div
          style={{
            position: "sticky",
            background: "var(--bg-01)",
            top: 0,
          }}
        >
          <Text style={TextStyle.M18} type={TextType.Div}>
            Summary
          </Text>

          <SpouseToggle showAll={showAll} onShowAllToggle={onShowAllToggle} />
        </div>

        <div
          style={{
            paddingTop: "1rem",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={TextStyle.B14}
            format={TextFormat.UpperCase}
            weight={700}
            colour={Colour.Text02}
            type={TextType.Div}
          >
            Income & Credits
          </Text>
        </div>
        <div
          style={{
            paddingTop: "0.25rem",
          }}
        />
        {income.length === 0 && (
          <Text
            style={TextStyle.L14}
            weight={400}
            colour={Colour.Text02}
            type={TextType.Div}
          >
            No Transactions Yet
          </Text>
        )}
        {income.map((x) => {
          return <CategoryItemEntry key={x.category} item={x} />;
        })}
        <div
          style={{
            paddingTop: "1rem",
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={TextStyle.B14}
            format={TextFormat.UpperCase}
            weight={700}
            colour={Colour.Text02}
            type={TextType.Div}
          >
            Expenses
          </Text>
        </div>
        <div
          style={{
            paddingTop: "0.25rem",
          }}
        />
        {expenses.length === 0 && (
          <Text
            style={TextStyle.L14}
            weight={400}
            colour={Colour.Text02}
            type={TextType.Div}
          >
            No Transactions Yet
          </Text>
        )}
        {expenses.map((x) => {
          return <CategoryItemEntry key={x.category} item={x} />;
        })}

        <div
          style={{
            paddingTop: "2rem",
          }}
        />
      </div>

      <div
        style={{
          margin: "0.75rem 0.5rem",
          padding: "1rem 0.5rem",
          background: "white",
          borderRadius: "4px",
        }}
      >
        <CategoryItemEntry
          item={{
            category: "MONTH TO DATE:",
            total: total,
          }}
          bold
        />
      </div>
    </div>
  );
};

const CategoryItemEntry: React.FC<{
  item: CashflowCategorizedSummary;
  bold?: boolean;
}> = ({ item, bold = false }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      className="cashflow-categorized-summary"
    >
      <div style={{ flexGrow: 1, flexShrink: 1 }} className="ellipsis">
        <Text
          style={TextStyle.L14}
          type={TextType.Div}
          weight={bold ? 700 : 400}
          colour={Colour.Text02}
        >
          <PylonTooltip
            text={item.category}
            spacingSide={SpacingSide.None}
            wrapperDisplay={TooltipWrapperDisplay.None}
          >
            <div className="category-item-entry__category">{item.category}</div>
          </PylonTooltip>
        </Text>
      </div>
      <div
        style={{
          flexShrink: 0,
          width: "fit-content",
          minWidth: "4rem",
          textAlign: "right",
        }}
      >
        <Text
          style={TextStyle.L14}
          type={TextType.Div}
          weight={400}
          colour={Colour.Text02}
        >
          {toMoneyString(item.total)}
        </Text>
      </div>
    </div>
  );
};
