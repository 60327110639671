import { Account } from "core/models/plaid";
import {
  AccountSubtypeEnum,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";

export const accountShortName = (
  acc: Pick<Account, "name" | "official_name" | "institutionName">
) => {
  const nameParts = [acc.name];
  if (acc.official_name) {
    nameParts.push(acc.official_name);
  }

  return nameParts.join(" - ");
};

// Return true if the account _should be_ treated as account for their beneficiaries
// https://www.notion.so/hellopylon/c1a8d7cca28c435490bb74cc078659ed?v=831781693e944bfab46a8a1c46e6a66b&p=d540d38c396b49b68df19d6f2b974ee3
export const IsAccountBeneficiaryable = (acc: Account): boolean => {
  if (acc.subtype === AccountSubtypeEnum.Mortgage) {
    return true;
  }
  if (acc.type === AccountType.Insurance) {
    if (acc.subtype === AccountSubtypeEnum.CarInsurance) {
      return false;
    }
    return true;
  }

  return !acc.typeProperties?.isLiability;
};

export const canSubtypeHaveEstimatedValueDetails = (
  subtype: AccountSubtypeEnum
): boolean => {
  return (
    subtype === AccountSubtypeEnum.Mortgage ||
    subtype === AccountSubtypeEnum.RentalProperty
  );
};
