import { UserData } from "components/features/dashboard/models/sharing";

export const UserDisplayName = (u?: UserData): string => {
  if (!u) {
    return "unknown";
  }
  if (u.firstName) {
    return `${u.firstName} ${u.lastName}`;
  }

  return u.email;
};

export const ageFromDOB = (dob?: number): number => {
  if (!dob) {
    return 0;
  }

  var today = new Date();
  var birthDate = new Date(dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
