import * as React from "react";
import { Colour } from "core/models";
import { FinancialPlanProgressBar } from "components/features/dashboard/components/financial-plan-status-block";
import { FinancialPlanTable } from "components/features/dashboard/components/financial-plan-table/financial-plan-table";
import { Icon, IconAsset } from "components/core/icons";
import { TextD, TextStyle } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  FetchFinancialPlanProgress,
  FetchFinancialPlanProgressResponse,
} from "core/queries/dashboard";

export interface PublicProps {
  closeParentModal: () => void;
}

export const FinancialPlanView: React.FC<PublicProps> = ({
  closeParentModal,
}) => {
  const mobileLayout = useMobileLayout();
  const { data } = useQuery<FetchFinancialPlanProgressResponse>(
    FetchFinancialPlanProgress,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const totalSteps = (data?.setupProgress?.steps?.length || 0) + 1;
  const completeSteps =
    (data?.setupProgress?.steps?.filter((v) => v.isCompleted)?.length || 0) + 1;
  return (
    <TextD style={TextStyle.M15}>
      <div
        className="flex-column"
        style={{
          color: Colour.Black,
          width: mobileLayout ? "100vw" : "100%",
        }}
      >
        <div
          style={{
            height: "8rem",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), var(--yellow-500)`,
          }}
        >
          <div
            style={{
              paddingTop: "6rem",
            }}
          />
          <div
            style={{
              paddingLeft: mobileLayout ? "1rem" : "7rem",
              width: "fit-content",
              height: "fit-content",
              maxWidth: mobileLayout ? "100vw" : undefined,
            }}
          >
            <div
              className="account__icon"
              style={{
                height: `64px`,
                width: `64px`,
                borderRadius: `16px`,
                position: "relative",
                marginRight: "1rem",
              }}
            >
              <Icon
                width={`64px`}
                height={`64px`}
                asset={IconAsset.PylonLogoIcon}
              />
            </div>
          </div>
        </div>
        <div className="mt3" />
        <div
          className="flex-column"
          style={{
            padding: mobileLayout ? "0 1rem" : "0 7rem",
          }}
        >
          <TextD style={TextStyle.M31B}>Financial Onboarding: Incomplete</TextD>
          <FinancialPlanProgressBar
            steps={totalSteps}
            completeSteps={completeSteps}
            showEmoji
          />
          <div className="mt2" />
          <FinancialPlanTable
            steps={data?.setupProgress?.steps || []}
            closeParentModal={closeParentModal}
          />
        </div>
      </div>
    </TextD>
  );
};
