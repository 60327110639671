import * as React from "react";
import classnames from "classnames";
import { TaxContext } from "components/contexts/tax-context";
import { toMoneyString } from "core/utils";
import "./styles.css";

interface Props {
  fullWidth?: boolean;
  compact?: boolean;
  shouldNotPop?: boolean;
}

export const TaxResults: React.FC<Props> = ({
  fullWidth,
  compact,
  shouldNotPop,
}) => {
  const { taxResults } = React.useContext(TaxContext);

  const mainResultClasses = classnames({
    "tax-results": !compact,
    "compact-tax-results": compact,
    "full-width": fullWidth,
  });

  return (
    <div
      className={mainResultClasses}
      style={{
        borderBottomRightRadius: ".5rem",
        borderBottomLeftRadius: ".5rem",
      }}
    >
      <div>
        <div
          style={{ margin: compact ? "0.5rem 3rem" : "auto 3rem" }}
          className={"main-tax-result"}
        >
          <h6>Tax Result</h6>
          <div>
            <div
              style={{
                alignItems: "center",
              }}
              className="flex-row mobile-flex-column between"
            >
              <div
                style={{
                  fontSize: "1rem",
                  width: "45%",
                }}
              >
                {taxResults?.taxOutcome.total > 0 && (
                  <>
                    Uh, oh! You're on track to{" "}
                    <span className="red-01">owe taxes</span> at the end of the
                    year.
                  </>
                )}
                {taxResults?.taxOutcome.total < 0 && (
                  <>
                    Congratulations! You're on track to get a{" "}
                    <span className="green-01">refund.</span>
                  </>
                )}
                {taxResults?.taxOutcome.total === 0 && (
                  <>
                    Everything appears to have added up. You won't owe taxes
                    this year.
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h5
                  style={{
                    lineHeight: "2.5rem",
                    color:
                      taxResults?.taxOutcome.total > 0
                        ? "var(--red-01)"
                        : "var(--green-01)",
                  }}
                >
                  {toMoneyString(Math.abs(taxResults?.taxOutcome.total))}
                </h5>
                <div className="label text-03" style={{ textAlign: "center" }}>
                  Total Tax Return
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", width: "55%" }}>
        <div
          className="text-01"
          style={{
            flexGrow: 1,
            fontSize: "1.5rem",
            lineHeight: "1.75rem",
            textAlign: "center",
            display: "flex",
            borderRight: "2px solid var(--separator-01)",
            alignItems: "center",
            padding: "0 2rem",
          }}
        >
          <div style={{ width: "100%", margin: "auto" }}>
            <h6
              className={`${
                taxResults?.taxOutcome.federal <= 0 ? "green-01" : "red-01"
              }`}
              style={{
                fontSize: "1.5rem",
                lineHeight: "1.5rem",
              }}
            >
              {toMoneyString(Math.abs(taxResults?.taxOutcome.federal))}
            </h6>
            <span className="label text-03" style={{ fontSize: "0.75rem" }}>
              Total Federal Tax
            </span>
            {!compact && (
              <>
                <hr className="no-mobile"></hr>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <div>
                    <h6
                      className={`${
                        taxResults?.federal.shortTermCapitalGains.total <= 0
                          ? "green-01"
                          : "red-01"
                      }`}
                      style={{ fontSize: "0.75rem" }}
                    >
                      {taxResults?.calculated.netCapitalGains < 0
                        ? toMoneyString(
                            Math.abs(
                              Math.max(
                                -3000,
                                taxResults?.calculated.netCapitalGains
                              )
                            )
                          )
                        : taxResults?.taxInput.shortTermCapitalGains < 0
                        ? toMoneyString(
                            Math.abs(taxResults?.taxInput.shortTermCapitalGains)
                          )
                        : toMoneyString(
                            Math.abs(
                              taxResults?.federal.shortTermCapitalGains.total
                            )
                          )}
                    </h6>
                    <div
                      className="label text-03"
                      style={{ fontSize: "0.75rem", width: "auto" }}
                    >
                      {taxResults?.calculated.netCapitalGains < 0 ||
                      taxResults?.taxInput.shortTermCapitalGains < 0
                        ? "Loss Deduction"
                        : "Short Term"}
                    </div>
                  </div>
                  <div>
                    <h6
                      className={`${
                        taxResults?.federal.longTermCapitalGains.total <= 0
                          ? "green-01"
                          : "red-01"
                      }`}
                      style={{ fontSize: "0.75rem" }}
                    >
                      {toMoneyString(
                        Math.abs(taxResults?.federal.longTermCapitalGains.total)
                      )}
                    </h6>
                    <div className="label text-03">Long Term</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "0.75rem",
                  }}
                >
                  <hr style={{ width: "33%" }} />
                  <div
                    className="label text-03"
                    style={{
                      marginBottom: 0,
                      width: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    capital gains
                  </div>
                  <hr style={{ width: "33%" }} />
                </div>{" "}
              </>
            )}
          </div>
        </div>
        <div
          className="left text-01"
          style={{
            flexGrow: 1,
            display: "flex",
            fontSize: "1.5rem",
            lineHeight: "1.75rem",
            textAlign: "center",
            alignItems: "center",
            padding: "0 2rem",
          }}
        >
          <div style={{ width: "100%", margin: "auto" }}>
            <h6
              className={`${
                taxResults?.taxOutcome.state <= 0 ? "green-01" : "red-01"
              }`}
              style={{
                fontSize: "1.5rem",
                lineHeight: "1.5rem",
              }}
            >
              {toMoneyString(Math.abs(taxResults?.taxOutcome.state))}
            </h6>
            <span className="label text-03">Total State Tax</span>
            {!compact && (
              <>
                <hr className="no-mobile" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    marginBottom: "0.5rem",
                  }}
                >
                  <div>
                    <h6
                      className={`${
                        (taxResults?.state.shortTermCapitalGains.total || 0) <=
                        0
                          ? "green-01"
                          : "red-01"
                      }`}
                      style={{ fontSize: "0.75rem" }}
                    >
                      {toMoneyString(
                        Math.abs(
                          taxResults?.state.shortTermCapitalGains.total || 0
                        )
                      )}
                    </h6>
                    <div className="label text-03">Short Term</div>
                  </div>
                  <div>
                    <h6
                      className={`${
                        (taxResults?.state.longTermCapitalGains.total || 0) <= 0
                          ? "green-01"
                          : "red-01"
                      }`}
                      style={{ fontSize: "0.75rem" }}
                    >
                      {toMoneyString(
                        Math.abs(
                          taxResults?.state.longTermCapitalGains.total || 0
                        )
                      )}
                    </h6>
                    <div className="label text-03">Long Term</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "0.75rem",
                  }}
                >
                  <hr style={{ width: "33%" }} />
                  <div
                    className="label text-03"
                    style={{
                      marginBottom: 0,
                      width: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    capital gains
                  </div>
                  <hr style={{ width: "33%" }} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
