import { Text, TextStyle, TextType } from "components/core/text";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { Account, Colour } from "core/models";
import { accountShortName } from "core/models/account";
import { toMoneyString } from "core/utils";
import React from "react";
import Toggle from "react-toggle";

export interface Props {
  account: Account;
  isShared: boolean;
  disabled: boolean;

  onRemove: () => void;
  onAdd: () => void;
}

export const AccountRow: React.FC<Props> = ({
  account,
  isShared,
  disabled,
  onRemove,
  onAdd,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1rem 0",
        borderBottom: "1px solid var(--separator-02)",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "75%",
        }}
      >
        <AccountIcon account={account} />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.B16}
            weight={600}
            colour={Colour.Navy}
          >
            <div className="collaborator-edit-tab__account-name ellipsis">
              {accountShortName(account)}
            </div>
          </Text>
          <Text
            type={TextType.Div}
            style={TextStyle.B16}
            weight={700}
            colour={Colour.Text02}
          >
            {toMoneyString(
              account.balances?.current,
              account.typeProperties?.signMultiplier
            )}
          </Text>
        </div>
      </div>
      <Toggle
        checked={isShared}
        disabled={disabled}
        icons={false}
        onChange={() => {
          if (disabled) {
            return;
          }

          if (isShared) {
            onRemove();
          } else {
            onAdd();
          }
        }}
      />
    </div>
  );
};
