export const sanitizeStringNumber = (value: string | undefined): string => {
  if (!value) {
    return "";
  }
  if (Number.isNaN(value)) {
    return "";
  }
  return value;
};

export const sanitizeStringNumberToString = (
  value: string | undefined
): string | undefined => {
  if (!value) {
    return undefined;
  }
  if (Number.isNaN(value)) {
    return undefined;
  }
  return value;
};

export const sanitizeStringNumberToInt = (
  value: string | undefined
): number | undefined => {
  if (!value) {
    return undefined;
  }
  if (Number.isNaN(value)) {
    return undefined;
  }
  return parseInt(value);
};

export const sanitizeStringNumberToFloat = (
  value: string | undefined
): number | undefined => {
  if (!value) {
    return undefined;
  }
  if (Number.isNaN(value)) {
    return undefined;
  }
  return parseFloat(value);
};
