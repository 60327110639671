import { GoogleAdWordsConversionIDs } from "core/distribution/distribution";

export let GQL_URL = "https://graphql.hellopylon.technology/graphql";
export let API_URL = "https://api-public.hellopylon.technology";

if (process.env.REACT_APP_ENVIRONMENT === "local") {
  GQL_URL = "http://localhost:4000/graphql";
  API_URL = "http://localhost:8081";
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  GQL_URL = "https://staging.graphql.hellopylon.technology/graphql";
  API_URL = "https://staging.service.hellopylon.technology	";
}

export interface ErrorResponse {
  code: string;
  msg: string;
}

export interface ServerResponse {
  status?: number;
  message?: string; // only present when there's an error
}

export type NewUserRegisterResponse = {
  authToken?: string;
  initiated2FA: boolean;
} & ServerResponse &
  Partial<Request2FAVerificationResponse>;

export type LoginResponse = {
  loginToken: string;
} & ServerResponse;

export type Request2FAVerificationResponse = {
  identityToken: string;
  maskedPhoneNumber: string;
  authToken?: string;
  emailVerificationRequired?: boolean;
} & ServerResponse;

export type InviteTokenResponse = Request2FAVerificationResponse & {
  email: string;
  phone: string;
};

export type Verify2FAResponse = {
  identityToken: string;
  last2FAToken: string;
} & ServerResponse;

export type LoggedPasswordResetTokenResponse = {
  idToken: string;
} & ServerResponse;

export type AuthorizeUserResponse = {
  authToken?: string; // Only present if user's account is created and user is logging in
  userInfo?: {
    // Only present if user is going through invitation sign up
    email: string;
    phone: string;
  };
} & ServerResponse;

export type ResetEmailResponse = {
  authToken?: string;
} & ServerResponse;

export const submitBetaEmailNew = (
  email: string,
  fullName: string,
  onSuccess: () => void,
  onFailure: (err: ErrorResponse) => void
) => {
  fetch(`${API_URL}/public/beta`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ email, fullName }),
  })
    .then((data) => {
      if (data?.status !== 200) {
        onFailure({
          code: data?.status.toString(),
          msg: data?.statusText + ".",
        });
        return;
      }

      gtag("event", "conversion", {
        send_to: GoogleAdWordsConversionIDs.Waitlist,
        email,
        fullName,
      });
      onSuccess();
    })
    .catch((err) => {
      console.error(err);
      onFailure(err);
    });
};
const GoogleAdInvitationCodes = ["7fdf0e32e42"];

export const newUser = (
  email: string,
  mobileNumber: string,
  firstName: string,
  lastName: string,
  password: string,
  dob: number | undefined,
  invitationCode: string | null | undefined,
  onSuccess: (data: NewUserRegisterResponse) => void,
  onFailure: (err: Object) => void,
  adParams?: string
) => {
  fetch(`${API_URL}/public/registerUser`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      email: email,
      mobileNumber: mobileNumber,
      firstName: firstName,
      lastName: lastName,
      password: password,
      invitationCode: invitationCode,
      dob,
      adParams,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }

      if (invitationCode && GoogleAdInvitationCodes.includes(invitationCode)) {
        gtag("event", "conversion", {
          send_to: GoogleAdWordsConversionIDs.CompletedRegistration,
          email,
          firstName,
          lastName,
          invitationCode,
        });
      }
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const resetPassword = (
  newPassword: string,
  code: string,
  otp: string,
  onSuccess: (data: LoginResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/resetPassword`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      otp: otp,
      code: code,
      newPassword: newPassword,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const loginUser = (
  email: string,
  password: string,
  last2FAToken: string,
  emailVerificationCode: string,
  onSuccess: (data: Request2FAVerificationResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/login`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      email: email,
      password: password,
      last2FAToken: last2FAToken,
      emailVerificationCode: emailVerificationCode,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const obtainInviteToken = (
  invitationCode: string,
  onSuccess: (data: InviteTokenResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/invitation`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ invitationCode }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const obtainPasswordResetToken = (
  code: string,
  onSuccess: (data: Request2FAVerificationResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/resolvePasswordResetCode`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ code: code }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const requestPasswordReset = (
  email: string,
  onSuccess: (data: ServerResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/requestPasswordReset`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ email: email }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const validateTwoFactorCode = (
  identityToken: string,
  code: string,
  genLast2FAToken: boolean,
  onSuccess: (data: Verify2FAResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/verifyTwoFACode`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      identityToken: identityToken,
      code: code,
      genLast2FAToken: genLast2FAToken,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const resendTwoFA = (
  identityToken: string,
  onSuccess: (data: Request2FAVerificationResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/resendTwoFA`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      identityToken: identityToken,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const loggedInUserRequestPasswordReset = (
  sessionToken: string,
  password: string,
  onSuccess: (data: LoggedPasswordResetTokenResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/user/requestPasswordReset`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({ password: password, sessionToken: sessionToken }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const loggedInUserResetPassword = (
  newPassword: string,
  sessionToken: string,
  idToken: string,
  onSuccess: (data: LoginResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/user/resetPassword`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      idToken: idToken,
      sessionToken: sessionToken,
      newPassword: newPassword,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const loggedInUserRequestEmailReset = (
  sessionToken: string,
  onSuccess: (data: Request2FAVerificationResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/user/requestEmailReset`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    method: "POST",
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => onFailure(err));
};

export const loggedInUserResetEmail = (
  newEmail: string,
  sessionToken: string,
  idToken: string,
  onSuccess: (data: ResetEmailResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/user/resetEmail`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    method: "POST",
    body: JSON.stringify({
      idToken: idToken,
      newEmail: newEmail,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};

export const loggedInUserVerifyEmail = (
  verificationCode: string,
  sessionToken: string,
  onSuccess: (data: ResetEmailResponse) => void,
  onFailure: (err: Object) => void
) => {
  fetch(`${API_URL}/public/user/verifyemail`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionToken}`,
    },
    method: "POST",
    body: JSON.stringify({
      verificationCode,
      sessionToken,
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.code && data?.msg) {
        onFailure({ code: data.code, msg: data.msg + "." });
        return;
      }
      onSuccess(data);
    })
    .catch((err) => {
      onFailure(err);
    });
};
