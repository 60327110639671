import { Colour } from "core/models";
import { PylonCurrencyInput } from "components/core/currency-input";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import "./currency-input-styles.css";

export interface Props {
  id: string;
  label: string;
  value: string | undefined;
  onChange: (v: string | undefined) => void;
}

export const CurrencyInput: React.FC<Props> = ({
  id,
  label,
  value,
  onChange,
}) => {
  return (
    <div className="pylon__currency-input">
      <Text
        style={TextStyle.B16}
        colour={Colour.Text03}
        type={TextType.Div}
        format={TextFormat.UpperCase}
        size="0.65rem"
        weight="bold"
      >
        {label}
      </Text>
      <div
        style={{
          paddingTop: "0.25rem",
        }}
      />
      <PylonCurrencyInput
        id={id}
        value={value}
        onValueChange={onChange}
        allowNegativeValue={false}
        decimalsLimit={0}
        prefix="$"
        placeholder="$   Enter Amount"
        className="pylon__currency-input__numeric-input"
      />
    </div>
  );
};
