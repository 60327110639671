import classNames from "classnames";
import { Colour } from "core/models";
import { GoogleAdWordsConversionIDs } from "core/distribution/distribution";
import { LoginPages } from "components/features/login/pages";
import { Text, TextStyle, TextType } from "components/core/text";
import { useHistory } from "react-router";
import "./styles.css";

export interface Props {
  text: string;
  roundCorner?: boolean;
  optionalParam?: string;
  blackBackground?: boolean;
}

export const RegisterButtonV2: React.FC<Props> = ({
  text,
  roundCorner,
  blackBackground,
  optionalParam,
}) => {
  const history = useHistory();

  const classes = classNames({
    "landing-page__register_button_2": true,
    "landing-page__register_button_2__round-corner": roundCorner,
    "landing-page__register_button_2__black-background": blackBackground,
  });
  return (
    <button
      className={classes}
      onClick={() => {
        gtag("event", "conversion", {
          send_to: GoogleAdWordsConversionIDs.StartedRegistration,
        });
        history.push(
          `${LoginPages.Register}?invitationCode=7fdf0e32e42${
            optionalParam ? `&${optionalParam}` : ""
          }`
        );
      }}
    >
      <Text
        type={TextType.Div}
        style={TextStyle.Sharp17Regular}
        colour={Colour.White}
      >
        {text}
      </Text>
    </button>
  );
};
