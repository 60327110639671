import * as React from "react";
import { AuditLogRow } from "components/features/dashboard/components/audit-log/components/audit-log-row";
import { CollapsibleSection } from "components/core/collapsible-section";
import { Colour } from "core/models";
import { Spinner } from "react-bootstrap";
import { Text, TextType } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  getAuditLogForAssetIDQuery,
  GetAuditLogResponse,
} from "core/queries/shared-data";

interface Props {
  assetID: string;
  assetType: string;
}

const MAX_AUDITS = 5;

export const AuditLog: React.FC<Props> = ({ assetID, assetType }) => {
  const { data, loading } = useQuery<GetAuditLogResponse>(
    getAuditLogForAssetIDQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        assetID,
        assetType,
      },
    }
  );

  const [showAll, setShowAll] = React.useState(false);

  const renderLogs = () => {
    if (!data || loading) {
      return <Spinner animation="border" size="sm" />;
    }
    const logs: JSX.Element[] = [];
    data?.auditLogs.some((a, index) => {
      if (index > MAX_AUDITS - 1) {
        logs.push(
          <div
            key={a.id}
            style={{
              marginTop: "0.5rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div onClick={() => setShowAll(true)}>
              <Text type={TextType.Div} colour={Colour.Blue01} weight={500}>
                view all
              </Text>
            </div>
          </div>
        );
        return true;
      }

      logs.push(
        <div key={a.id}>
          <AuditLogRow log={a} key={a.id} />
        </div>
      );
      return false;
    });

    return logs;
  };

  const renderAllLogs = () => {
    if (!data || loading) {
      return <Spinner animation="border" size="sm" />;
    }
    return data?.auditLogs.map((a, index) => {
      return (
        <div key={a.id}>
          <AuditLogRow log={a} key={a.id} />
          {index < data?.auditLogs?.length - 1 ? (
            <></>
          ) : (
            <div
              style={{
                marginTop: "0.5rem",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div onClick={() => setShowAll(false)}>
                <Text type={TextType.Div} colour={Colour.Blue01} weight={500}>
                  show less
                </Text>
              </div>
            </div>
          )}
        </div>
      );
    });
  };
  return (
    <CollapsibleSection
      title={"Activity"}
      stateKey={AUDIT_LOG_SESSION_STORAGE_KEY}
    >
      {!showAll ? renderLogs() : renderAllLogs()}
    </CollapsibleSection>
  );
};

const AUDIT_LOG_SESSION_STORAGE_KEY = "audit-log--collapsible";
