import {
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from "@apollo/client";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { CollaboratorPermission } from "components/features/dashboard/models/sharing";
import { DEFAULT_STATE } from "components/features/dashboard/pages/settings/address/components/state-dropdown";
import {
  AddCollaborationMutation,
  AddCollaborationMutationInput,
  AddCollaborationMutationResponse,
  Collaboration,
  CollaborationRelationships,
} from "core/queries/collaborations";
import { BeneficiaryLevel } from "core/queries/contact-info";
import { MutationWrapper } from "core/queries/mutation";
import * as React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const PROGRESS_INC = 20;

export interface TrustedSourceContextValue {
  progress: number;
  clickNextRef?: React.RefObject<HTMLAnchorElement>;
  state: State;
  goNext: () => void;
  goBack: () => void;
  trustedContactValidation: () => boolean;
  updateState: (update: Partial<State>) => void;
  submitTrustedContact: (onSuccess?: () => void, onError?: () => void) => void;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
}

interface State {
  firstName: string;
  lastName: string;
  age: number | undefined;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  state: string;
  relationship: string;
  executor: boolean;
  eighteenPlus: boolean | undefined;
  benefitLevel: BeneficiaryLevel;
  permission: CollaboratorPermission;
  autoshareNewAccount: boolean;
  collaboration?: Collaboration;
  sharedAccountIDs: string[];
}

const defaultState = (): State => {
  return {
    firstName: "",
    lastName: "",
    age: undefined,
    email: "",
    phone: "",
    address1: "",
    address2: "",
    zipCode: "",
    city: "",
    relationship: CollaborationRelationships.Spouse,
    state: DEFAULT_STATE,
    executor: false,
    eighteenPlus: true,
    benefitLevel: BeneficiaryLevel.None,
    permission: CollaboratorPermission.Editor,
    collaboration: undefined,
    autoshareNewAccount: false,
    sharedAccountIDs: [],
  };
};

export const TrustedSourceContext =
  React.createContext<TrustedSourceContextValue>({
    state: defaultState(),
    progress: 0,
    updateState: (_: Partial<State>) => null,
    submitTrustedContact: () => null,
    trustedContactValidation: () => false,
    setProgress: () => null,
    goNext: () => null,
    goBack: () => null,
  });

export interface DataProps {
  onComplete?: (data: {}) => void;
  defaultOverrides?: Partial<State>;
  refetchQueries?:
    | (string | PureQueryOptions)[]
    | RefetchQueriesFunction
    | undefined;
}

interface ContextProps {
  children: React.ReactNode;
}
export type Props = ContextProps & DataProps;

export const TrustedSourceContextProvider: React.FC<Props> = ({
  children,
  refetchQueries,
  onComplete,
  defaultOverrides,
}) => {
  const history = useHistory();
  const clickNextRef = React.useRef<HTMLAnchorElement>(null);
  const [progress, setProgress] = React.useState<number>(0);
  const [state, setState] = React.useState<State>({
    ...defaultState(),
    ...defaultOverrides,
  });

  const [addCollaboration] = useMutation<
    AddCollaborationMutationResponse,
    MutationWrapper<AddCollaborationMutationInput>
  >(AddCollaborationMutation, {
    refetchQueries: refetchQueries || [],
    onCompleted: (data) => {
      setState(defaultState());
      onComplete?.(data);
      toast(
        <PylonToastBody title={"Successfully Added Contact"}>
          Your contact has been successfully added.
        </PylonToastBody>,
        {
          closeOnClick: false,
          closeButton: true,
        }
      );
    },
    onError: (err) => {
      console.error("Failed to create collaboration", err);
      toast(
        <PylonToastBody
          type={ToastType.Error}
          title={"Error adding contact"}
          body={`${err}`}
        />,
        {
          closeOnClick: false,
          closeButton: true,
        }
      );
    },
  });

  const submitTrustedContact = (
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    addCollaboration({
      variables: {
        input: {
          email: state.email,
          phone: state.phone,
          permission: state.permission,
          relationship: state.relationship,
          executor: state.executor,
          lastName: state.lastName,
          firstName: state.firstName,
          autoShareNewAccount: state.autoshareNewAccount,
          sharedAccountIDs: state.sharedAccountIDs,
        },
      },
    })
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.();
      });
  };

  const updateState = (updates: Partial<State>) => {
    setState({
      ...state,
      ...updates,
    });
  };

  const trustedContactValidation = () => {
    const validName = state.firstName !== "" && state.lastName !== "";

    let validContact = true;
    if (state.eighteenPlus) {
      validContact = state.email !== "" && state.phone !== "";
    }

    return (
      validName &&
      validContact &&
      state.eighteenPlus !== undefined &&
      state.relationship !== ""
    );
  };

  const goNext = React.useCallback(() => {
    if (clickNextRef?.current) {
      clickNextRef.current.click();
      setProgress(progress + PROGRESS_INC);
    }
  }, [clickNextRef, progress]);

  const goBack = React.useCallback(() => {
    history.goBack();
    setProgress(progress - PROGRESS_INC);
  }, [history, progress]);

  return (
    <TrustedSourceContext.Provider
      value={{
        trustedContactValidation,
        submitTrustedContact,
        updateState,
        goNext,
        goBack,
        state,
        progress,
        clickNextRef,
        setProgress,
      }}
    >
      {children}
    </TrustedSourceContext.Provider>
  );
};
