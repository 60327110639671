import * as React from "react";
import { DisplayModal } from "components/core/modal/display";
import { Spinner } from "react-bootstrap";
import { Text } from "components/core/text";

interface Props {
  onEvent: (eventName: string, metadata: any) => void;
  onConnect: (connectionID: string, metadata: any) => void;
  onClose: () => void;
}

export const SavvyWidget: React.FC<Props> = ({
  onEvent,
  onConnect,
  onClose,
}) => {
  const [gotFirstSavvyEvent, setGotFirstSavvyEvent] = React.useState(false);

  const handler = window.TrellisConnect.configure({
    urlTrackingParams:
      "?utm_source=65425cf2-af19-4108-92b5-258a53136b7d&utm_medium=incentive",
    client_id: "65425cf2-af19-4108-92b5-258a53136b7d",

    experience: "connect",
    onClose: () => {
      handler.destroy();
    },
    onConnect: onConnect,
    onEvent: (eventName: string, metadata: any) => {
      if (!gotFirstSavvyEvent) {
        setGotFirstSavvyEvent(true);
      }
      onEvent(eventName, metadata);
    },
  });

  if (!gotFirstSavvyEvent) {
    handler.open();
  }
  return (
    <>
      <DisplayModal
        height="fit-content"
        width="fit-content"
        showModal={!gotFirstSavvyEvent}
        onClose={() => {}}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text>Connecting to Trellis</Text>
          <Spinner animation="border" size="sm" />
        </div>
      </DisplayModal>
    </>
  );
};
