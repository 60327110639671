import { Account } from "core/models";
import { ACCOUNT_FIELDS } from "core/queries/accounts";
import { gql } from "@apollo/client";
import { UserData } from "components/features/dashboard/models/sharing";

export const orderCreditReportMutation = gql`
  mutation orderCreditReport($arrayID: ID!, $userToken: ID!) {
    orderCreditReport(arrayID: $arrayID, userToken: $userToken)
  }
`;

interface CreditResponse {
  score: number;
  date: Date;
}

export class CreditHistory {
  ownerid: string | undefined;
  __typename: string = "CreditHistory";
}

export interface CreditScoreResponse {
  creditScore: CreditResponse;
}

export interface CreditHistoryItem {
  score: number;
  transUnion: number;
  equifax: number;
  experian: number;
  date: Date;
}

export interface GetCreditHistoryResponse {
  creditHistory: CreditHistoryResponse;
}

export interface CreditHistoryResponse {
  total: number;
  creditHistory: CreditHistoryItem[];
  owner: UserData;
}

export interface CreditHistorysResponse {
  allCreditHistory: CreditHistoryResponse[];
}

export const getCreditScore = gql`
  query creditScore {
    creditScore {
      score
      date
    }
  }
`;

export const getCreditHistory = gql`
  query CreditHistory($offset: Int, $limit: Int) {
    creditHistory(offset: $offset, limit: $limit) {
      total
      creditHistory {
        score
        transUnion
        equifax
        experian
        date
      }
    }
  }
`;

export const getAllCreditHistory = gql`
  query AllCreditHistory {
    allCreditHistory {
      total
      creditHistory {
        score
        date
      }
      owner {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export interface UtilizationResponse {
  account: Account;
  limit: number;
  balance: number;
}

export interface GetCreditUtilizationResponse {
  creditUtilization: UtilizationResponse[];
}

export const getCreditUtilization = gql`
  ${ACCOUNT_FIELDS}
  query CreditUtilization {
    creditUtilization {
      account {
        ...AccountFields
      }
      balance
      limit
    }
  }
`;
