import React from "react";
import { PublicHeader } from "components/features/public/header";
import { PublicMobileNavigation } from "components/features/login/components/mobile-navigation";
import { SecurityOverview } from "components/features/dashboard/pages/security-overview";
import { useMobileLayout } from "core/hooks/responsive";
import { WithBackground } from "components/features/public/with-background";
import "./styles.css";

export const SecurityPage: React.FC = () => {
  const mobileLayout = useMobileLayout();
  return (
    <>
      {mobileLayout && <PublicMobileNavigation redirectToHome={true} />}
      <WithBackground>
        {!mobileLayout ? <PublicHeader /> : <></>}
        <div
          style={{
            backgroundColor: "white",
            padding: "1rem",
          }}
          className="dashboard-content-wrapper"
        >
          <SecurityOverview publicPage />
        </div>
      </WithBackground>
    </>
  );
};
