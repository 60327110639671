import * as React from "react";
import Collapsible from "react-collapsible";
import Select from "react-select";
import { blurOnEnter } from "core/utils/form-helpers";
import { FilingStatus } from "@pylon/taxes";
import { paymentPeriods, TaxContext } from "components/contexts/tax-context";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { SELECT__MODAL_INPUT_STYLE } from "components/core/input/styles";
import { setValue } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/util";
import { Text, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useLocalStorage } from "core/utils/browser-storage";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
export const TaxSectionEmployerWithholding: React.FC = () => {
  const [isEmployerWithholdingOpen, setIsEmployerWithholdingOpen] =
    useLocalStorage("taxes.withholding", true);

  const {
    useEstimatedWithholdings,
    filingStatus,
    paymentPeriod,
    setPaymentPeriod,
    federalWithholding,
    setFederalWithholding,
    spousePaymentPeriod,
    setSpousePaymentPeriod,
    spouseFederalWithholding,
    setSpouseFederalWithholding,
    spouseStateWithholding,
    setSpouseStateWithholding,
    stateWithholding,
    setStateWithholding,
    saveWithholdingFieldValue,

    totalFederalWithholding,
    totalStateWithholding,
  } = React.useContext(TaxContext);

  const _useEstimateWithholdings = () => {
    useEstimatedWithholdings();
  };

  return (
    <Collapsible
      open={isEmployerWithholdingOpen}
      trigger={
        <div
          className="table-row"
          onClick={() => {
            setIsEmployerWithholdingOpen(!isEmployerWithholdingOpen);
          }}
        >
          <div style={{ fontSize: "14px" }} className="table-cell name">
            Employer Withholding
          </div>

          <div className="table-cell state-amount no-mobile">
            {toMoneyString(totalStateWithholding())}
          </div>

          <div className="table-cell federal-amount">
            {toMoneyString(totalFederalWithholding())}
          </div>
        </div>
      }
    >
      <div className="left" style={{ width: "14rem" }}>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: 600,
            fontFamily: "Montserrat",
            color: "var(--text-01)",
            lineHeight: "1.75rem",
          }}
        ></div>
        <div className="mt1 text-01">
          Tell us about taxes that are already being withheld, either{" "}
          <b>by your employer</b> or as <b>estimated tax payments</b>.
          <br />
          <br />
          You should exclude Social Security and Medicare payments from these
          totals.
        </div>
        <div className="mt4">
          <LeafButton
            buttonStyle={LeafButtonStyle.LeafSmallDense}
            buttonColour={LeafButtonColour.Black}
            onClick={() => {
              _useEstimateWithholdings();
            }}
          >
            Estimate this for me
          </LeafButton>
        </div>
      </div>
      <div className="right">
        <h6 style={{ fontWeight: 600 }}>Regular Withholding</h6>
        <div className="flex-row between mt2">
          <div>
            <div className="label">
              How often are you paid?&#160;
              <PylonTooltip
                spacingSide={SpacingSide.Right}
                text={
                  <Text type={TextType.Div}>
                    <p>This is the frequency with which you are paid.</p>
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <Select
              styles={SELECT__MODAL_INPUT_STYLE}
              onChange={(e) => {
                if (!e) return;
                setPaymentPeriod(e.value);
                saveWithholdingFieldValue("paymentPeriodsPerYear", e.value);
              }}
              menuPortalTarget={document.body}
              isSearchable={false}
              value={paymentPeriods.find((v) => {
                return v.value === paymentPeriod;
              })}
              options={paymentPeriods}
            />
          </div>
        </div>
        <div className="flex-row between mt2 mobile-flex-column">
          <div>
            <div className="label">
              Federal Taxes Withheld per Pay Period&#160;
              <PylonTooltip
                spacingSide={SpacingSide.Right}
                text={
                  <Text type={TextType.Div}>
                    <p>
                      This is the amount of Federal taxes, excluding Social
                      Security and Medicare taxes, that are taken out of your
                      pay check every time you are paid.
                    </p>
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <PylonCurrencyInput
              placeholder="Taxes withheld"
              value={federalWithholding}
              maxLength={12}
              onValueChange={(v) => setValue(v || "", setFederalWithholding)}
              onBlur={(e) =>
                saveWithholdingFieldValue(
                  "federalIncomeTax",
                  sanitizeCurrencyString(e.target.value)
                )
              }
              allowDecimals={true}
              decimalSeparator={"."}
              decimalsLimit={2}
              allowNegativeValue={false}
              prefix="$"
              onKeyDown={blurOnEnter}
            />
          </div>
          <div>
            <div className="label">
              State Taxes Withheld per Pay Period&#160;
              <PylonTooltip
                spacingSide={SpacingSide.Right}
                text={
                  <Text type={TextType.Div}>
                    <p>
                      This is the amount of State taxes that are taken out of
                      your pay check every time you are paid.
                    </p>
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <PylonCurrencyInput
              placeholder="Taxes withheld"
              value={stateWithholding}
              maxLength={12}
              onValueChange={(v) => setValue(v || "", setStateWithholding)}
              onBlur={(e) =>
                saveWithholdingFieldValue(
                  "stateIncomeTax",
                  sanitizeCurrencyString(e.target.value)
                )
              }
              allowDecimals={true}
              decimalSeparator={"."}
              decimalsLimit={2}
              allowNegativeValue={false}
              prefix="$"
              onKeyDown={blurOnEnter}
            />
          </div>
        </div>

        {filingStatus === FilingStatus.MarriedFilingJointly && (
          <>
            <h6 className="mt2" style={{ fontWeight: 600 }}>
              Your Spouse's Withholding
            </h6>
            <div className="flex-row between mt2 mobile-flex-column">
              <div>
                <div className="label">
                  How often is <b>your spouse</b> paid?&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>This is the frequency with which you are paid.</p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <Select
                  onChange={(e) => {
                    if (!e) return;
                    setSpousePaymentPeriod(e.value);
                    saveWithholdingFieldValue(
                      "spousePaymentPeriodsPerYear",
                      e.value
                    );
                  }}
                  menuPortalTarget={document.body}
                  isSearchable={false}
                  value={paymentPeriods.find((v) => {
                    return v.value === spousePaymentPeriod;
                  })}
                  styles={SELECT__MODAL_INPUT_STYLE}
                  options={paymentPeriods}
                />
              </div>
            </div>
            <div className="flex-row between mt2 mobile-flex-column">
              <div>
                <div className="label">
                  Federal Taxes Withheld per Pay Period&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>
                          This is the amount of Federal taxes, excluding Social
                          Security and Medicare taxes, that are taken out of
                          your pay check every time you are paid.
                        </p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <PylonCurrencyInput
                  placeholder="Taxes withheld"
                  value={spouseFederalWithholding}
                  maxLength={12}
                  onValueChange={(v) =>
                    setValue(v || "", setSpouseFederalWithholding)
                  }
                  onBlur={(e) =>
                    saveWithholdingFieldValue(
                      "spouseFederalIncomeTax",
                      sanitizeCurrencyString(e.target.value)
                    )
                  }
                  allowDecimals={true}
                  decimalSeparator={"."}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  prefix="$"
                  onKeyDown={blurOnEnter}
                />
              </div>
              <div>
                <div className="label">
                  State Taxes Withheld per Pay Period&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>
                          This is the amount of State taxes that are taken out
                          of your pay check every time you are paid.
                        </p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <PylonCurrencyInput
                  placeholder="Taxes withheld"
                  value={spouseStateWithholding}
                  maxLength={12}
                  onValueChange={(v) =>
                    setValue(v || "", setSpouseStateWithholding)
                  }
                  onBlur={(e) =>
                    saveWithholdingFieldValue(
                      "spouseStateIncomeTax",
                      sanitizeCurrencyString(e.target.value)
                    )
                  }
                  allowDecimals={true}
                  decimalSeparator={"."}
                  decimalsLimit={2}
                  allowNegativeValue={false}
                  prefix="$"
                  onKeyDown={blurOnEnter}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Collapsible>
  );
};
