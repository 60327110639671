import amberBackgroundMobile from "../../../../core/assets/landing/amber-fread-mobile.jpg";
import desktopBackground from "./desktop-background-exp-c.png";
import illustration from "./illustration-experiment-c.png";
import mobileBackground from "./mobile-background-exp-c.png";
import vidBackground from "../../../../core/assets/landing/lady-with-baby.mov";
import { Colour } from "core/models";
import { CSSProperties } from "react";
import { Emoji } from "components/core/emoji";
import { LandingPageDefinition } from "components/features/landing";
import { RegisterButtonV2 } from "components/features/landing/components/register-version-2";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";

export const LandingPageTrackYourMoneyC: LandingPageDefinition = {
  Path: "/landing2/track/track-your-money",
  Component: () => {
    const mobileLayout = useMobileLayout();

    const style: CSSProperties = {
      backgroundAttachment: "local",
      textAlign: "center",
      opacity: 0.3,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    };

    if (mobileLayout) {
      style.backgroundImage = `url(${mobileBackground})`;
      style.backgroundPositionY = "500px";
      style.backgroundSize = "550px";
      style.backgroundRepeat = "no-repeat";
    } else {
      style.backgroundImage = `url(${desktopBackground})`;
      style.backgroundPosition = "bottom";
      style.backgroundSize = "1360px";
      style.backgroundRepeat = "repeat-x";
    }

    return (
      <div
        style={{
          minHeight: "calc(100vh - 64px)",
          paddingTop: "64px",
          paddingLeft: "16px",
          paddingRight: "16px",
          backgroundColor: "#F7EFE5",
          position: "relative",
        }}
      >
        <div style={style}></div>
        <div
          className="flex-column align-center"
          style={{
            textAlign: "center",
            position: "relative",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.SGM25}
            size={"4rem"}
            lineHeight={"125%"}
            colour={Colour.Black}
          >
            Track your money
          </Text>

          <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
            Connect your accounts to track your monthly cashflows
          </Text>
          <div
            style={{
              paddingTop: "1.5rem",
            }}
          />
          <RegisterButtonV2
            text="Monitor your cashflow →"
            optionalParam="skip=true"
          />
          <div
            style={{
              paddingTop: "4rem",
            }}
          />
          <img
            src={illustration}
            alt="Illustration"
            style={{
              width: mobileLayout ? "95vw" : "1200px",
            }}
          />
          <div
            style={{
              paddingTop: mobileLayout ? "20rem" : "3rem",
            }}
          />
        </div>
      </div>
    );
  },
};

export const LandingEstimateSavings: LandingPageDefinition = {
  Path: "/landing2/track/estimate-savings",
  Component: () => {
    const mobileLayout = useMobileLayout();
    const renderMobile = (
      <div
        className="flex-column "
        style={{
          backgroundColor: "#FEBE1E",
        }}
      >
        <div style={{}}>
          <div
            style={{
              backgroundColor: "white",
              padding: "1.5rem",
              textAlign: "left",
              margin: "1rem",
            }}
          >
            <div
              style={{
                marginBottom: "8px",
              }}
            >
              <Text
                type={TextType.Div}
                style={TextStyle.SGM25}
                colour={Colour.Black}
                size={"14px"}
                lineHeight={"20px"}
              >
                MOM&nbsp;&nbsp;/&nbsp;&nbsp;PARTNER&nbsp;&nbsp;/&nbsp;&nbsp;LAWYER&nbsp;&nbsp;/&nbsp;&nbsp;DOG
                LOVER
              </Text>
            </div>
            <div style={{ marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGM25}
                colour={Colour.Black}
                size={"34px"}
                lineHeight="48px"
              >
                Amber Fread
              </Text>
            </div>
            <div style={{ lineHeight: "24px" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"💰"} />
                  <div>Estimated $21,238 in taxes owed for New York</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", margin: "1.5rem 0" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"📈"} />
                  <div>Net worth $2.8 million by 2031</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"⛵️"} />
                  <div>Will retire early with over $8.8 million</div>
                </div>
              </Text>
            </div>
            <RegisterButtonV2
              text="Track your networth →"
              optionalParam="skip=true"
            />
          </div>
        </div>
        <div style={{}}>
          <img
            width={"100%"}
            src={amberBackgroundMobile}
            alt="woman sitting in chair"
          />
        </div>
      </div>
    );
    const renderWeb = (
      <div
        style={{
          backgroundColor: "#FEBE1E",
        }}
      >
        <div
          className="flex-column "
          style={{
            maxWidth: "1440px",
            margin: "auto",
            paddingTop: "4rem",
            minHeight: "calc(100vh - 64px)",
            backgroundImage: `url(${amberBackgroundMobile})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
            backgroundSize: "55%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "calc(100vh - 192px)",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "2rem",
                width: "656px",
                textAlign: "left",
                margin: "0 3.5rem",
              }}
            >
              <div
                style={{
                  marginBottom: "8px",
                }}
              >
                <Text
                  type={TextType.Div}
                  style={TextStyle.SGM25}
                  colour={Colour.Black}
                  size={"14px"}
                  lineHeight={"20px"}
                >
                  MOM&nbsp;&nbsp;/&nbsp;&nbsp;PARTNER&nbsp;&nbsp;/&nbsp;&nbsp;LAWYER&nbsp;&nbsp;/&nbsp;&nbsp;DOG
                  LOVER
                </Text>
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                <Text
                  type={TextType.Div}
                  style={TextStyle.SGM25}
                  colour={Colour.Black}
                  size={"56px"}
                  lineHeight="64px"
                >
                  Amber Fread
                </Text>
              </div>
              <div style={{ lineHeight: "24px" }}>
                <Text
                  type={TextType.Div}
                  style={TextStyle.SGB20}
                  colour={Colour.Black}
                  size={"17px"}
                >
                  <div style={{ display: "flex" }}>
                    <Emoji emoji={"💰"} />
                    <div>Estimated $21,238 in taxes owed for New York</div>
                  </div>
                </Text>
              </div>
              <div style={{ lineHeight: "24px", margin: "1.5rem 0" }}>
                <Text
                  type={TextType.Div}
                  style={TextStyle.SGB20}
                  colour={Colour.Black}
                  size={"17px"}
                >
                  <div style={{ display: "flex" }}>
                    <Emoji emoji={"📈"} />
                    <div>Net worth $2.8 million by 2031</div>
                  </div>
                </Text>
              </div>
              <div style={{ lineHeight: "24px", marginBottom: "1.5rem" }}>
                <Text
                  type={TextType.Div}
                  style={TextStyle.SGB20}
                  colour={Colour.Black}
                  size={"17px"}
                >
                  <div style={{ display: "flex" }}>
                    <Emoji emoji={"⛵️"} />
                    <div>Will retire early with over $8.8 million</div>
                  </div>
                </Text>
              </div>
              <RegisterButtonV2
                text="Track your networth →"
                optionalParam="skip=true"
              />
            </div>
          </div>
        </div>
      </div>
    );
    return mobileLayout ? renderMobile : renderWeb;
  },
};

export const LandingFinancialDecisions: LandingPageDefinition = {
  Path: "/landing2/track/financial-decisions",
  Component: () => {
    const mobileLayout = useMobileLayout();
    const renderMobile = (
      <div className="flex-column" style={{}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "1.5rem",
              textAlign: "left",
            }}
          >
            <div style={{ marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGM25}
                colour={Colour.Black}
                size={"35px"}
                lineHeight="48px"
              >
                Financial decisions
              </Text>
              <Text
                type={TextType.Div}
                style={TextStyle.SGM25}
                colour={Colour.Black}
                size={"35px"}
                lineHeight="48px"
              >
                made simple
              </Text>
            </div>
            <div style={{ lineHeight: "24px" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"💰"} />
                  <div>Track all your cash, investments, assets, and taxes</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", margin: "1.5rem 0" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"📈"} />
                  <div>Create a simple financial report</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"⛵️"} />
                  <div>Ask our CFPs and CFAs any question</div>
                </div>
              </Text>
            </div>
            <RegisterButtonV2 text="Get started →" optionalParam="skip=true" />
          </div>
        </div>

        <div
          style={{
            overflowX: "hidden",
          }}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            style={{
              // position: "absolute",
              // marginTop: "4rem",
              width: "100%",
              minWidth: "125%",
              minHeight: "125%",
              // top: "50%",
              // left: "50%",
              transform: "translate(-10%, 0)",
            }}
          >
            <source src={vidBackground}></source>
          </video>
        </div>
      </div>
    );
    const renderWeb = (
      <div
        className="flex-column"
        style={{
          paddingTop: "4rem",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "-50%",
            left: "-50%",
            width: "200%",
            height: "200%",
            zIndex: -100,
          }}
        >
          <video
            playsInline
            autoPlay
            muted
            loop
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
              minWidth: "50%",
              minHeight: "50%",
            }}
          >
            <source src={vidBackground}></source>
          </video>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "calc(100vh - 192px)",
          }}
        >
          <div
            style={{
              background: "rgba(255, 255, 255, 0.4)",
              backdropFilter: "blur(20px)",
              padding: "2rem",
              width: "656px",
              textAlign: "left",
              margin: "0 3.5rem",
            }}
          >
            <div style={{ marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGM25}
                colour={Colour.Black}
                size={"56px"}
                lineHeight="64px"
              >
                Financial decisions
              </Text>
              <Text
                type={TextType.Div}
                style={TextStyle.SGM25}
                colour={Colour.Black}
                size={"56px"}
                lineHeight="64px"
              >
                made simple
              </Text>
            </div>
            <div style={{ lineHeight: "24px" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"💰"} />
                  <div>Track all your cash, investments, assets, and taxes</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", margin: "2rem 0" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"📈"} />
                  <div>Create a simple financial report</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", marginBottom: "2rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.SGB20}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"⛵️"} />
                  <div>Ask our CFPs and CFAs any question</div>
                </div>
              </Text>
            </div>
            <RegisterButtonV2 text="Get started →" optionalParam="skip=true" />
          </div>
        </div>
      </div>
    );
    return mobileLayout ? renderMobile : renderWeb;
  },
};
