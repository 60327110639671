import * as React from "react";

export const DropDownMenu: React.FC = ({ children }) => {
  return (
    <div
      style={{
        cursor: "pointer",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
        borderRadius: "2px",
      }}
    >
      {children}
    </div>
  );
};
