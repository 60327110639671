import { Colour } from "core/models";
import { ConfirmationModal, ModalPriority } from "components/core/modal";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { EditAccountInformation } from "./account-information-edit";
import { getComponentToRender } from ".";
import { ManageDetailsProps } from "./model";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { TextD, TextStyle } from "components/core/text";
import { toast } from "react-toastify";
import { useCallback, useContext, useState } from "react";
import { useMobileLayout } from "core/hooks/responsive";
import { useMutation } from "@apollo/client";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  AccountInput,
  updateAccountMutation,
  UpdateAccountMutationInput,
  UpdateAccountMutationResponse,
} from "core/queries/accounts";

type Props = ManageDetailsProps & {
  show: boolean;
  onClose: () => void;
};

export const EditAccountInformationModal: React.FC<Props> = ({
  account,
  show,
  onClose,
  ...props
}) => {
  const { onUpdateRefetchQueries } = useContext(DataPageContext);
  const mobileLayout = useMobileLayout();

  const [updateAccount, { loading: updateLoading }] = useMutation<
    UpdateAccountMutationResponse,
    UpdateAccountMutationInput
  >(updateAccountMutation, {
    refetchQueries: onUpdateRefetchQueries,
    onCompleted: () => {
      cleanupAndClose();
    },
    onError: (err) => {
      console.error("failed to update account", err);

      toast(
        <PylonToastBody
          type={ToastType.Error}
          title={"Error updating account information"}
          body={`There's an error updating your account. Please try again`}
        />
      );
    },
  });

  const [edited, setEdited] = useState(false);
  const [input, setInput] = useState<Partial<AccountInput>>({});
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const updateAccountFunc = (acc: Partial<AccountInput>) => {
    setInput({
      ...input,
      ...acc,
    });
    setEdited(true);
  };

  const editCompProps = {
    ...props,
    account: account,
    onUpdate: updateAccountFunc,
  };

  const detailsSection = getComponentToRender(editCompProps);

  const cleanupAndClose = useCallback(() => {
    setEdited(false);
    setShowDiscardConfirmation(false);
    setInput({});
    onClose();
  }, [onClose]);

  const onCloseWithConfirm = useCallback(() => {
    if (edited) {
      setShowDiscardConfirmation(true);
    } else {
      cleanupAndClose();
    }
  }, [edited, cleanupAndClose]);

  return (
    <ConfirmationModal showModal={show} onClose={onCloseWithConfirm}>
      <ConfirmationModal
        showModal={showDiscardConfirmation}
        onClose={onCloseWithConfirm}
        defaultStyling
        modalPriority={ModalPriority.High}
        title="Unsaved changes"
        onCancel={() => {
          setShowDiscardConfirmation(false);
        }}
        cancelButtonText="Keep Editing"
        onAction={() => {
          cleanupAndClose();
        }}
        actionButtonText="Discard"
      >
        Changes you made will not be saved
      </ConfirmationModal>
      <div
        style={{
          padding: mobileLayout ? "2.5rem 1rem" : "2.5rem",
          width: mobileLayout ? "95vw" : "40rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          color: Colour.Black,
        }}
      >
        <TextD style={TextStyle.M31B}>Edit Account Information</TextD>
        <div className="mt1" />
        <EditAccountInformation {...editCompProps} />
        {detailsSection}

        <div
          className="flex-row gap-1"
          style={{
            paddingTop: "0.5rem",
            justifyContent: "flex-end",
          }}
        >
          <LeafButton
            onClick={() => {
              onCloseWithConfirm();
            }}
            buttonStyle={LeafButtonStyle.LeafSmall}
            buttonColour={LeafButtonColour.Transparent}
          >
            Cancel
          </LeafButton>

          <LeafButton
            loading={updateLoading}
            onClick={() => {
              updateAccount({
                variables: {
                  account: {
                    ...input,
                    id: account.account_id,
                    account_id: account.account_id,
                  },
                },
              });
            }}
            buttonStyle={LeafButtonStyle.LeafSmall}
            buttonColour={LeafButtonColour.Black}
          >
            Ok
          </LeafButton>
        </div>
      </div>
    </ConfirmationModal>
  );
};
