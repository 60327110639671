import * as React from "react";
import classnames from "classnames";
import { DashboardPages } from "components/features/dashboard/pages";
import { FinancialPlanView } from "components/features/dashboard/components/financial-plan-view";
import { Spinner } from "react-bootstrap";
import { TableRowModal } from "components/core/modal/table-row-modal";
import { Text, TextStyle, TextType } from "components/core/text";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  FetchFinancialPlanProgress,
  FetchFinancialPlanProgressResponse,
} from "core/queries/dashboard";

export interface PublicProps {
  onClick?: () => void;
}

export const FinancialPlanStatusBlock: React.FC<PublicProps> = (
  props: PublicProps
) => {
  const isMobileLayout = useMobileLayout();
  const history = useHistory();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const { data, loading } = useQuery<FetchFinancialPlanProgressResponse>(
    FetchFinancialPlanProgress,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const totalSteps = (data?.setupProgress?.steps?.length || 0) + 1;
  const completeSteps =
    (data?.setupProgress?.steps?.filter((v) => v.isCompleted)?.length || 0) + 1;

  const isAllDone = completeSteps === totalSteps;
  const planStatusText = isAllDone ? "Active" : "Incomplete";

  if (isAllDone) {
    return null; // don't show the plan if all of the steps are done
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <>
      <TableRowModal
        hideChrome={true}
        width={isMobileLayout ? "100vw" : "55rem"}
        onClose={() => {
          setShowModal(false);
        }}
        quickActions={[]}
        showModal={showModal}
        hasPrevious={false}
        hasNext={false}
        onClickNext={() => {}}
        onClickPrevious={() => {}}
      >
        <FinancialPlanView closeParentModal={() => setShowModal(false)} />
      </TableRowModal>
      <div
        data-selector="financial-plan-status-block"
        className="financial-plan-status-block"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.onClick?.();
          if (isMobileLayout) {
            history.push(DashboardPages.FinancialPlan);
          } else {
            setShowModal(true);
          }
        }}
      >
        <Text style={TextStyle.M15} type={TextType.Div}>
          Financial Onboarding: <b>{planStatusText}</b>
        </Text>
        <FinancialPlanProgressBar
          steps={totalSteps}
          completeSteps={completeSteps}
        />
      </div>
    </>
  );
};

interface ProgressBarProps {
  steps: number;
  completeSteps: number;
  disableCursor?: boolean;
  showEmoji?: boolean;
}
export const FinancialPlanProgressBar: React.FC<ProgressBarProps> = ({
  steps,
  completeSteps,
  disableCursor,
  showEmoji,
}) => {
  const bars = [];
  for (let i = 0; i < steps; i++) {
    const classes = classnames({
      bar: true,
      done: i < completeSteps,
    });
    bars.push(<div className={classes} key={i}></div>);
  }
  if (showEmoji) {
    bars.push(<div key={steps}>🎉</div>);
  }
  return (
    <div
      className="financial-plan-status-block"
      style={{ cursor: disableCursor ? "unset" : "pointer" }}
    >
      <div
        className="flex-row between status-bar"
        style={{ display: "flex", alignItems: "center" }}
      >
        {bars}
      </div>
    </div>
  );
};
