import React from "react";
import { Colour } from "core/models";
import "./styles.css";

export enum LinkStyle {
  None = "",
  Default = "pylon-a-link__default",
}

export interface PublicProps {
  linkStyle?: LinkStyle;
  colour?: Colour;
}

type Props = PublicProps &
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >;

// Link using <a> tags instead of react dom's <Link>
export const PylonALink: React.FC<Props> = (props) => {
  const { linkStyle, colour, style, ...linkProps } = props;

  return (
    <a
      className={`pylon-a-link ${linkStyle ?? LinkStyle.Default}`}
      style={{
        ...style,
        color: colour ?? Colour.Navy,
      }}
      {...linkProps}
    >
      {props.children}
    </a>
  );
};
