import * as React from "react";
import { ageFromDOB } from "core/utils/profile";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { InvestmentHoldingsDonutChart } from "components/features/dashboard/components/investment-holdings-highlights/holdings-donut-chart";
import { Link } from "react-router-dom";
import { profileQuery, profileQueryResponse } from "core/queries/profiles";
import { toMoneyString } from "core/utils";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  Text,
  TextD,
  TextFormat,
  TextStyle,
  TextType,
} from "components/core/text";
import {
  AccountSubType,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  accountsBySubtypesQuery,
  AccountsBySubtypesResponse,
} from "core/queries/accounts";
import {
  FETCH_INVESTMENT_HOLDINGS,
  InvestmentHoldingsResponse,
} from "core/queries/investments";

const equities = ["equity", "derivative"];
const safe = ["etf", "fixed income", "mutual fund"];

interface Props {
  accountID?: string;
  showAllocationChart?: boolean;
}
export const InvestmentHoldingsHighlightsSection: React.FC<Props> = ({
  accountID,
  showAllocationChart,
}) => {
  const { data: profileData } = useQuery<profileQueryResponse>(profileQuery);
  const { data } = useQuery<InvestmentHoldingsResponse>(
    FETCH_INVESTMENT_HOLDINGS,
    {
      variables: {
        accountID,
      },
    }
  );

  const { data: privateHoldingsData } = useQuery<AccountsBySubtypesResponse>(
    accountsBySubtypesQuery,
    {
      variables: {
        subtypes: [
          AccountSubType[AccountType.NonRetirement]["Private Company"],
          AccountSubType[AccountType.NonRetirement]["Private Stock"],
        ],
      },
    }
  );

  const privateHoldings = React.useMemo(() => {
    return privateHoldingsData?.accountsBySubtypes;
  }, [privateHoldingsData?.accountsBySubtypes]);

  const processedPrivateHoldingsValue = React.useMemo(() => {
    return privateHoldings
      ?.map((holding) => {
        if (
          holding.details?.privateCompanyDetails?.amountInvestedPercentage &&
          holding.details?.privateCompanyDetails?.estimatedInvestmentValue
        ) {
          return (
            (parseFloat(
              holding.details.privateCompanyDetails.amountInvestedPercentage
            ) /
              100) *
            parseFloat(
              holding.details?.privateCompanyDetails?.estimatedInvestmentValue
            )
          );
        }

        if (
          holding.details?.privateStockDetails?.sharesOwned &&
          holding.details?.privateStockDetails?.valuePerShare
        ) {
          return (
            parseFloat(holding.details.privateStockDetails?.sharesOwned) *
            parseFloat(holding.details.privateStockDetails?.valuePerShare)
          );
        }

        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }, [privateHoldings]);

  const colorClass = (n: number) => {
    return n < 0 ? "red-01" : "green-01";
  };

  const [holdingsMap, setHoldingsMap] = React.useState({
    safe: 0,
    equities: 0,
    other: 0,
    private: 0,
  });

  const processedData = React.useMemo(() => {
    return data?.investmentHoldings?.holdings?.map((holding) => {
      return {
        ...holding,
        totalReturn: holding.institutionValue - (holding.costBasis || 0),
      };
    });
  }, [data]);

  const { total, best, worst } = React.useMemo(() => {
    if (!processedData || !processedData.length) {
      return {
        total: 0,
        best: {
          name: "",
          totalReturn: 0,
        },
        worst: {
          name: "",
          totalReturn: 0,
        },
      };
    }

    let total = 0;
    processedData.forEach((holding) => {
      total += holding.totalReturn;
    });

    let sortedByReturn = processedData.slice().sort((a, b) => {
      return a.totalReturn - b.totalReturn;
    });

    const bestPosition = sortedByReturn.length - 1;
    const worstPosition = 0;

    return {
      total: total,
      best: sortedByReturn[bestPosition],
      worst: sortedByReturn[worstPosition],
    };
  }, [processedData]);

  const emptyState = null;

  React.useEffect(() => {
    const allocationMap = { safe: 0, equities: 0, other: 0, private: 0 };
    for (const h of processedData || []) {
      if (equities.includes(h.type)) {
        allocationMap.equities += h.institutionValue;
      } else if (safe.includes(h.type)) {
        allocationMap.safe += h.institutionValue;
      } else {
        allocationMap.other += h.institutionValue;
      }
    }
    if (processedPrivateHoldingsValue) {
      allocationMap.private = processedPrivateHoldingsValue;
    }
    setHoldingsMap(allocationMap);
  }, [processedData, processedPrivateHoldingsValue]);

  const age = ageFromDOB(profileData?.profile?.dob);
  const getEquitiesFromAge = (age: number) => {
    const low = 100 - age;
    const high = 120 - age;
    return `${low}%-${high}%`;
  };

  const getSafeAssetsFromAge = (age: number) => {
    const low = Math.abs(100 - age - 100);
    const high = Math.abs(120 - age - 100);
    return `${high}%-${low}%`;
  };

  const getYourAllocation = () => {
    const allocTotal =
      holdingsMap.equities + holdingsMap.safe + holdingsMap.other;
    const allocString = (
      <span>
        {
          <span style={{ color: "var(--blue-01)" }}>
            {Math.round((holdingsMap.equities / allocTotal) * 100)}%
          </span>
        }{" "}
        equities,{" "}
        {
          <span style={{ color: "var(--blue-01)" }}>
            {Math.round((holdingsMap.safe / allocTotal) * 100)}%
          </span>
        }{" "}
        mutual funds, bonds, and other safe assets
      </span>
    );
    return holdingsMap.other <= 0 ? (
      <span>{allocString}.</span>
    ) : (
      <span>
        {allocString}, and{" "}
        {
          <span style={{ color: "var(--blue-01)" }}>
            {Math.round((holdingsMap.other / allocTotal) * 100)}%
          </span>
        }{" "}
        alternative assets.
      </span>
    );
  };

  return (
    ((processedData?.length || processedPrivateHoldingsValue || 0) > 0 && (
      <div className="investment-holdings-highlights mt2">
        <div className="flex-row between">
          <div className="section-title">Public Stock Highlights</div>
          {!showAllocationChart && (
            <div>
              <Link to={DashboardPages.InvestmentHoldings}>
                <TextD style={TextStyle.M15SM} colour={Colour.Blue01}>
                  INVESTMENTS AND STOCKS
                </TextD>
              </Link>
            </div>
          )}
        </div>
        <div className="summary-box flex-row between mt1 mobile-flex-column">
          <div>
            <div>
              <div className={`number ${colorClass(total)}`}>
                {toMoneyString(total)}
              </div>
              <div className="holding-name">&nbsp;</div>
              <div className="label">Total Return</div>
            </div>
          </div>
          <div>
            <div>
              <div className={`number ${colorClass(best.totalReturn)}`}>
                {toMoneyString(best.totalReturn)}
              </div>
              <div className="holding-name">{best.name}</div>
              <div className="label">Best Performance</div>
            </div>
          </div>
          <div>
            <div>
              <div className={`number ${colorClass(worst.totalReturn)}`}>
                {toMoneyString(worst.totalReturn)}
              </div>
              <div className="holding-name">{worst.name}</div>
              <div className="label">Worst Performance</div>
            </div>
          </div>
        </div>

        {showAllocationChart && (
          <div className="mt3">
            <h6>Portfolio Allocation</h6>
            <div className="flex-row between mobile-flex-column allocation-area">
              <div
                style={{
                  border: "2px solid var(--gray-10)",
                  borderRadius: "4px",
                  padding: "1rem",
                }}
              >
                <InvestmentHoldingsDonutChart
                  equities={holdingsMap.equities}
                  safe={holdingsMap.safe}
                  privateHoldings={holdingsMap.private}
                  other={holdingsMap.other}
                />
              </div>
              <div
                style={{
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="allocation-section"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderBottom: "1px solid var(--gray-30)",
                    flexGrow: 1,
                  }}
                >
                  <div>
                    {age > 0 && (
                      <Text
                        type={TextType.Div}
                        weight={500}
                        colour={Colour.Navy}
                      >
                        Depending on your risk tolerance, Investopedia suggests
                        at age{" "}
                        <span style={{ color: "var(--blue-01)" }}>
                          {age || 30}
                        </span>
                        , your allocation should be between{" "}
                        <span style={{ color: "var(--blue-01)" }}>
                          {getEquitiesFromAge(age || 30)}
                        </span>{" "}
                        equities and{" "}
                        <span style={{ color: "var(--blue-01)" }}>
                          {getSafeAssetsFromAge(age || 30)}
                        </span>{" "}
                        mutual funds, bonds, and other safe assets.
                      </Text>
                    )}
                    <br />
                    {age === 0 && (
                      <div
                        style={{ marginTop: "0.5rem", marginBottom: "1rem" }}
                      >
                        <Link to={DashboardPages.Settings}>
                          <button className="button-as-link">
                            <Text
                              type={TextType.Div}
                              size={"0.75rem"}
                              weight={700}
                              format={TextFormat.UpperCase}
                            >
                              Enter your age to learn about your suggested
                              Portfolio Allocation.
                            </Text>
                          </button>
                        </Link>
                      </div>
                    )}
                    <Text
                      type={TextType.Div}
                      size={"1rem"}
                      weight={700}
                      colour={Colour.Navy}
                    >
                      Your Allocation
                    </Text>{" "}
                    <Text type={TextType.Div} weight={500} colour={Colour.Navy}>
                      Looks like your allocation is {getYourAllocation()}
                    </Text>
                    <div>
                      <br />
                      <Link to={DashboardPages.Integrations}>
                        <button className="button-as-link">
                          <Text type={TextType.Div}>
                            Look off? Connect all your investment accounts.
                          </Text>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  <Text
                    type={TextType.Div}
                    size={"1rem"}
                    weight={700}
                    colour={Colour.Navy}
                  >
                    Pylon Users
                  </Text>
                  <div
                    style={{
                      margin: "auto",
                    }}
                  >
                    <Text type={TextType.Div} weight={500} colour={Colour.Navy}>
                      Pylon users on average have an allocation of{" "}
                      <span style={{ color: "var(--blue-01)" }}>45%</span>{" "}
                      equities, and{" "}
                      <span style={{ color: "var(--blue-01)" }}>33%</span>{" "}
                      mutual funds, bonds, and other safe assets, and{" "}
                      <span style={{ color: "var(--blue-01)" }}>22%</span> other
                      investments.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )) ||
    emptyState
  );
};
