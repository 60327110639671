import classnames from "classnames";
import React, { useState } from "react";
import { Colour } from "core/models";
import { ConfirmationModalOld } from "components/core/modal";
import { Icon, IconAsset } from "components/core/icons";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { Text, TextStyle, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { useHover } from "core/hooks/hover";
import { useMutation } from "@apollo/client";
import "../styles.css";
import {
  Collaboration,
  FETCH_MY_COLLABORATORS,
  RemoveCollaboratorMutationInput,
  REMOVE_COLLABORATOR_MUTATION,
} from "core/queries/collaborations";

export interface Props {
  collaborator: Collaboration;
  editable?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

export const TrustedBeneficiaryCard: React.FC<Props> = ({
  collaborator,
  editable,
  selected,
  onClick,
}) => {
  const [hoverRef, isHovering] = useHover<HTMLDivElement>();
  const [showRemoveContactModal, setShowRemoveContactModal] = useState(false);

  const isSelected = selected || isHovering;
  const [removeCollaborator] = useMutation<{}, RemoveCollaboratorMutationInput>(
    REMOVE_COLLABORATOR_MUTATION,
    {
      refetchQueries: [{ query: FETCH_MY_COLLABORATORS }],
      onCompleted: () =>
        toast(
          <PylonToastBody
            title={"Removed Contact"}
            body={"Contact has been successfully removed."}
          />
        ),
      onError: (err: Error) => {
        console.error("failed to update relationship", err);
        toast(
          <PylonToastBody
            type={ToastType.Error}
            title={"Contact Error"}
            body={
              err.message ?? "An unexpected error occurred. Please try again"
            }
          />
        );
      },
    }
  );

  const attributes = [];

  if (collaborator.contactInfo?.executor) {
    attributes.push(
      <ItemAttribute
        selected={isSelected}
        key="executor"
        text={"Emergency Contact"}
      />
    );
  }

  if (collaborator.relationship) {
    attributes.push(
      <ItemAttribute
        selected={isSelected}
        key="relationship"
        text={collaborator.relationship}
      />
    );
  }

  if (parseInt(collaborator.contactInfo?.age || "") < 18) {
    attributes.push(
      <ItemAttribute selected={isSelected} key="Minor" text={"Minor"} />
    );
  }
  const displayName = `${collaborator.contactInfo?.firstName} ${collaborator.contactInfo?.lastName}`;

  const getStatus = () => {
    if (collaborator?.recipient?.isPhoneVerified) {
      return (
        <>
          <div
            style={{
              paddingRight: "0.5rem",
            }}
          >
            <Icon
              asset={IconAsset.GreenCheckMark}
              colour={isSelected ? Colour.White : Colour.Navy}
              height="15px"
              width="12px"
            />
          </div>
          Pylon Verified
        </>
      );
    }

    return (
      <>
        <div
          style={{
            paddingRight: "0.5rem",
          }}
        >
          <Icon
            asset={IconAsset.RedCross}
            colour={isSelected ? Colour.White : Colour.Navy}
            height="15px"
            width="12px"
          />
        </div>
        Not Verified
      </>
    );
  };
  const cardClasses = classnames({
    "trustee-user-card": true,
    // "trustee-user-card-editable": editable,
    "trustee-user-card-selected": isSelected,
  });
  return (
    <>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showRemoveContactModal}
        children={
          <>
            <Text weight={600}>
              Are you sure you want to remove {displayName} from your contacts
              list?
            </Text>
            <Text weight={400}>
              This will remove everything you've shared with them, as well as
              any collaborator settings you've set. You'll have to reinvite them
              if you want to share more with them in the future.
            </Text>
          </>
        }
        onAction={() => {
          removeCollaborator({
            variables: {
              collaborationID: collaborator.id,
            },
          });
          setShowRemoveContactModal(false);
        }}
        actionButtonText="Delete"
        onCancel={() => {
          setShowRemoveContactModal(false);
        }}
        cancelButtonText="Cancel"
      />
      <Text type={TextType.Div} colour={Colour.Gray70} size="1rem">
        <div
          ref={hoverRef}
          className={cardClasses}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick?.();
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text
                style={TextStyle.Highlight}
                colour={isSelected ? Colour.White : Colour.Navy}
              >
                {displayName}
              </Text>
              {editable && (
                <div
                  style={{
                    height: "2rem",
                    width: "2rem",
                    borderRadius: "6px",
                    backgroundColor: "#F3F6FC",
                  }}
                  className="delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowRemoveContactModal(true);
                  }}
                >
                  <Icon
                    asset={IconAsset.Garbage}
                    height={"32px"}
                    width={"32px"}
                    colour={Colour.Gray50}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                paddingBottom: "1rem",
              }}
            />
            {attributes}
          </div>
          <div
            style={{
              paddingBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
              }}
              className="trusted-user-card-status"
            >
              {getStatus()}
            </div>
          </div>
        </div>
      </Text>
    </>
  );
};

export const ItemAttribute: React.FC<{
  text: string;
  selected?: boolean;
}> = ({ text, selected }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
      }}
    >
      <BlueDot selected={selected} />
      {text}
    </div>
  );
};

export const BlueDot: React.FC<{ selected?: boolean }> = ({ selected }) => {
  return (
    <div
      className={classnames({
        "trustee-user-card__dot": !selected,
        "trustee-user-card__dot-selected": selected,
      })}
    />
  );
};
