import * as React from "react";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { TextD, TextStyle } from "components/core/text";
import "./styles.css";

interface Props {
  text: React.ReactNode | string;
  styleOverride?: React.CSSProperties;
}

export const PylonDropDownToggle: React.FC<Props> = ({
  text,
  styleOverride,
}) => {
  return (
    <div
      className={"pylon-dropdown"}
      style={
        styleOverride
          ? styleOverride
          : {
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              padding: "0.5rem 1rem",
            }
      }
    >
      <TextD style={TextStyle.M15}>{text}</TextD>
      <Icon
        asset={IconAsset.DropdownCaret}
        height={"24px"}
        width={"24px"}
        colour={Colour.Gray500}
      />
    </div>
  );
};
