import * as React from "react";
import Chart from "react-apexcharts";
import { toMoneyString } from "core/utils";

interface Props {
  equities: number;
  safe: number;
  privateHoldings: number;
  other: number;
}
export const InvestmentHoldingsDonutChart: React.FC<Props> = ({
  equities,
  safe,
  privateHoldings,
  other,
}) => {
  const labels = ["Equities", "Mutual Funds, ETFs, bonds", "Private Holdings"];
  const series = [equities, safe, privateHoldings];

  if (other > 0) {
    labels.push("Other");
    series.push(other);
  }

  return (
    <div
      style={{
        margin: "0 auto",
        paddingBottom: "0.5rem",
      }}
    >
      <Chart
        options={{
          dataLabels: {
            enabled: false,
          },
          labels: labels,
          legend: {
            position: "bottom",
            fontFamily: "Inter",
            fontWeight: 700,
          },
          tooltip: {
            y: {
              formatter: (v) => toMoneyString(v),
            },
          },
          colors: ["#A1C84E", "#E040D0", "#EDD34B", "#1E90FF"],
        }}
        series={series}
        type="donut"
      />
    </div>
  );
};
