import * as React from "react";
import { AuditLog } from "components/features/dashboard/components/audit-log";
import { CollapsibleSection } from "components/core/collapsible-section";

interface Props {
  assetID: string;
  assetType: string;
}
export const ActivityLog: React.FC<Props> = ({ assetID, assetType }) => {
  return (
    <CollapsibleSection
      title={"Activity"}
      stateKey={`collapsible.${assetType}.${assetID}.activity.isopen`}
    >
      <AuditLog assetID={assetID} assetType={assetType} />
    </CollapsibleSection>
  );
};
