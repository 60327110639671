import * as React from "react";
import { profileQuery, profileQueryResponse } from "core/queries/profiles";
import { Spinner } from "react-bootstrap";
import { stateCodeToState } from "components/features/dashboard/pages/settings/address/util/stateCodeToString";
import { useQuery } from "@apollo/client";

const ARRAY_KBA = "array-authentication-kba";

interface ArrayDetail {
  metadata: {
    "section-name": string;
    "user-token": string;
  };
  component: string;
  componentName: string;
  tagName: string;
  event: string;
}

interface ArrayEvent {
  detail: ArrayDetail;
}

interface Props {
  arrayID: string;
  onNext: () => void;
  onOrder: (userToken: string) => void;
}

export const ArrayAccountEnroll: React.FC<Props> = ({
  arrayID,
  onNext,
  onOrder,
}) => {
  const { data } = useQuery<profileQueryResponse>(profileQuery, {
    fetchPolicy: "network-only",
  });

  const getAddress = () => {
    let address = data?.profile.addressLineOne;

    if (data?.profile.addressLineTwo) {
      address += `, ${data.profile.addressLineTwo}`;
    }

    if (data?.profile.addressLineThree) {
      address += `, ${data.profile.addressLineThree}`;
    }
    return address ? address : "";
  };

  const getDOB = () => {
    const dob = new Date(data?.profile.dob || "");
    let date: string | number = dob.getDate();
    let month: string | number = dob.getMonth() + 1;
    if (Math.floor(date / 10) === 0) {
      date = `0${date}`;
    }
    if (Math.floor(month / 10) === 0) {
      month = `0${month}`;
    }
    return [date, month, dob.getFullYear()];
  };

  const state = stateCodeToState(data?.profile.stateProvinceRegion || "");
  const dob = getDOB();

  React.useEffect(() => {
    window.addEventListener(
      "array-event",
      function arrayEvent(arrayEvent: any) {
        const ev = arrayEvent as ArrayEvent;
        if (
          ev.detail.component === ARRAY_KBA &&
          ev.detail.event === "success" &&
          ev.detail.metadata["user-token"]
        ) {
          onOrder(ev.detail.metadata["user-token"]);
          onNext();
        }
      }
    );
    return () => {
      window.removeEventListener(
        "array-event",
        function arrayEvent(arrayEvent) {}
      );
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data?.profile ? (
        <array-account-enroll
          appKey={"211A27EA-FC54-4153-B275-13E8FCB622E0"}
          // appKey={"3F03D20E-5311-43D8-8A76-E4B5D77793BD"}
          // sandbox={true}
          userID={arrayID}
          firstName={data?.profile.firstName}
          lastName={data?.profile.lastName}
          streetAddress={getAddress()}
          city={data?.profile.city}
          state={state}
          zipCode={data?.profile.postalCode}
          dobDay={dob[0]}
          dobMonth={dob[1]}
          dobYear={dob[2]}
          showQuickView={true}
        ></array-account-enroll>
      ) : (
        <Spinner animation="border" size="sm" />
      )}
    </div>
  );
};
