import React from "react";
import { AccountDetails } from "core/models";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeStringNumber } from "components/features/dashboard/components/add-account-tray/details-entry/utils";

export interface Props {
  balance?: string;
  onBalanceUpdate: (balance: string) => void;

  details?: AccountDetails;
  onDetailsUpdate: (details: AccountDetails) => void;

  onEnter?: () => void;
}

export const AddCDDetailsEntry: React.FC<Props> = ({
  balance,
  onBalanceUpdate,
  details,
  onDetailsUpdate,
  onEnter,
}) => {
  return (
    <>
      <div>
        <div>Balance (Estimates okay)</div>
        <PylonCurrencyInput
          id="validation-example-2-field"
          placeholder="$100.00"
          onValueChange={(v) => {
            onBalanceUpdate(sanitizeStringNumber(v));
          }}
          value={balance || ""}
          prefix={"$"}
          step={1}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
    </>
  );
};
