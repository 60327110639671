import { DocumentCategory } from "core/queries/documents";
import { gql } from "@apollo/client/core";
import {
  DOCUMENT_FIELDS,
  DOCUMENT_FOLDER_FIELDS,
} from "core/queries/document-fragments";

export const UPDATE_DOCUMENT_FOLDER_MUTATION = gql`
  mutation EditDocumentFolder($id: ID!, $name: String) {
    editDocumentFolder(id: $id, name: $name) {
      folder {
        id
        name
      }
    }
  }
`;

export interface UpdateDocumentFolderInput {
  id: string;
  name: string;
}

export interface UpdateDocumentFolderResponse {
  updateDocumentFolder: {
    folder: {
      id: string;
      name: string;
    };
  };
}

export interface FetchDocumentCategoryQueryResponse {
  documentCategory: DocumentCategory;
}

export const FETCH_DOCUMENT_CATEGORY = gql`
  ${DOCUMENT_FIELDS}
  ${DOCUMENT_FOLDER_FIELDS}
  query fetchDocumentCategory($id: ID!, $type: String!) {
    documentCategory(id: $id, type: $type) {
      index
      category {
        id
        type
        name
        isEditable
        sharedWithMe
        folder {
          ...DocumentFolderFields
          suggestedItems {
            id
            documentID
            suggestionItemID
          }
        }
      }
      documents {
        ...DocumentFields
      }
    }
  }
`;
