import Dropdown from "react-bootstrap/Dropdown";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import React, { useContext } from "react";
import { AssetType } from "components/features/dashboard/models/sharing";
import { Colour } from "core/models";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { DELETE_DOCUMENT } from "core/queries/documents";
import { deleteSharedDataByAssetMutation } from "core/queries/shared-data";
import { DocumentData } from "components/features/dashboard/models/document";
import { DownloadDocumentButton } from "components/features/dashboard/components/document-download-button";
import { Icon, IconAsset } from "components/core/icons";
import { ManageMenuOption } from "components/features/dashboard/components/manage-menu-option";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { SessionContext } from "components/contexts/session-context-provider";
import { ShareTray } from "components/features/dashboard/components/share-tray";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import {
  ConfirmationModal,
  ConfirmationModalOld,
} from "../../../../core/modal";

export interface Props {
  document: DocumentData;
  keepTrapOpenOnRemove?: boolean;
}

export const ManageDocumentOptions: React.FC<Props> = ({
  document,
  keepTrapOpenOnRemove,
}) => {
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const { pushTray, popTray } = React.useContext(SideTrayContext);
  const { userID } = useContext(SessionContext.context);
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);

  const [documentToDeleteID, setDocumentToDeleteID] = React.useState<string>();
  const [showDocumentDeletionPrompt, setShowDocumentDeletionPrompt] =
    React.useState<boolean>(false);

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: () => {
      toast(
        <PylonToastBody
          title={"Document deleted"}
          body={"Document has been deleted"}
        />
      );

      if (!keepTrapOpenOnRemove) {
        popTray();
      }
    },
    refetchQueries: onUpdateRefetchQueries,
  });

  const [deleteSharedDataByAsset] = useMutation(
    deleteSharedDataByAssetMutation,
    {
      onCompleted: () => {
        toast(
          <PylonToastBody
            title={"Access removed"}
            body={"Access has been removed"}
          />
        );

        if (!keepTrapOpenOnRemove) {
          popTray();
        }
      },
      refetchQueries: onUpdateRefetchQueries,
    }
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ConfirmationModal
        children={
          <ShareTray asset={document} onClose={() => setShowShare(false)} />
        }
        showModal={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <Dropdown bsPrefix="pylon" alignRight>
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          {" "}
          <Icon
            asset={IconAsset.Ellipsis}
            width="16px"
            height="16px"
            colour={Colour.Gray70}
          />
        </DropdownToggle>
        <DropdownMenu className="pylon-dropdown-menu">
          {document.ownerID === userID ? (
            <>
              <ManageMenuOption
                bottomBorder
                iconAsset={IconAsset.RemoveAsset}
                optionText={"Delete Document"}
                onClick={() => {
                  setDocumentToDeleteID(document.id);
                  setShowDocumentDeletionPrompt(true);
                }}
              />
              <ManageMenuOption
                bottomBorder
                iconAsset={IconAsset.ShareAsset}
                optionText={"Share Document"}
                onClick={() => {
                  setShowShare(true);
                }}
              />
            </>
          ) : (
            <>
              <ManageMenuOption
                bottomBorder
                iconAsset={IconAsset.RemoveSharedAsset}
                optionText={"Remove my access"}
                onClick={() => {
                  deleteSharedDataByAsset({
                    variables: {
                      id: document.id,
                      type: AssetType.Document,
                    },
                  });
                }}
              />
            </>
          )}
          <ManageMenuOption
            iconAsset={IconAsset.Document}
            optionText={<DownloadDocumentButton document={document} asDiv />}
            onClick={() => null}
          />
        </DropdownMenu>
      </Dropdown>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showDocumentDeletionPrompt}
        children={
          <div>
            Are you sure you want to delete this document?
            <br /> <br />
          </div>
        }
        onAction={() => {
          deleteDocument({
            variables: {
              id: documentToDeleteID,
            },
          });
          setDocumentToDeleteID("");
          setShowDocumentDeletionPrompt(false);
        }}
        actionButtonText="Delete"
        onCancel={() => {
          setShowDocumentDeletionPrompt(false);
        }}
        cancelButtonText="Cancel"
      />
    </div>
  );
};
