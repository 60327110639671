import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropDownMenu } from "components/core/drop-down-menu";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import { useClickOutDetector } from "core/hooks/click-out";
import "./styles.css";

export enum AccountTypeFiltering {
  All = "All Account Types",
  Assets = "Assets",
  Liabilities = "Liabilities",
  LiquidAssets = "Liquid Assets",
  IlliquidAssets = "Illiquid Assets",
}

interface Props {
  accountType: AccountTypeFiltering;
  setAccountType: (t: AccountTypeFiltering) => void;
}

export const AccountTypeFilter: React.FC<Props> = ({
  accountType,
  setAccountType,
}) => {
  return (
    <>
      <Dropdown alignRight>
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          <PylonDropDownToggle text={accountType} />
        </DropdownToggle>

        <DropdownMenu
          className="asset-note__menu-item-wrapper"
          style={{ boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)" }}
        >
          <AccountTypeFilterMenu
            onSelect={(f: AccountTypeFiltering) => {
              setAccountType(f);
            }}
            onClose={() => {}}
          />
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

interface MenuProps {
  onSelect: (f: AccountTypeFiltering) => void;
  onClose: () => void;
}

export const AccountTypeFilterMenu: React.FC<MenuProps> = ({
  onSelect,
  onClose,
}) => {
  const clickOutRef = useClickOutDetector<HTMLDivElement>(onClose);
  const accountTypeMenuItems = Object.values(AccountTypeFiltering).map((f) => (
    <DropDownMenuItem
      key={f}
      text={<>{f}</>}
      onClick={() => {
        clickOutRef?.current?.click();
        onSelect(f);
        document.dispatchEvent(new MouseEvent("click"));
      }}
    />
  ));

  return (
    <div
      ref={clickOutRef}
      style={{
        cursor: "pointer",
        backgroundColor: "white",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
        borderRadius: "2px",
      }}
    >
      <DropDownMenu>{accountTypeMenuItems}</DropDownMenu>
    </div>
  );
};
