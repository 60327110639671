import classnames from "classnames";
import React, { useState } from "react";
import { addYears } from "date-fns";
import { AutomaticPaymentDeductionToggle } from "./auto-payment-deduction-toggle";
import { ModalDateInput } from "components/core/input/modal-date-input";
import { ModalInput } from "components/core/input/modal-input";
import { PylonALink } from "components/core/alink";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { shallowEquals } from "core/utils/compare";
import { Text, TextStyle, TextType } from "components/core/text";
import "./styles.css";
import {
  Account,
  CopyOptionalIntegratedFields,
  MortgageDetails,
} from "core/models";
import {
  AccountInformationValueCard,
  AutoDeductToggleDisplayCard,
  ValueCardType,
} from "./detail-card";
import {
  getProjectedPaymentsInfo,
  validNumberIntegrateField,
  validStringIntegrateField,
} from "./utils";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  errorFields?: string[];
  onUpdate?: (account: Partial<Account>) => void;
}

export const ManageMortgageDetails: React.FC<Props> = ({
  account,
  isConnected,
  displayOnly,
  errorFields,
  onUpdate,
}) => {
  const inputDetails = account.details?.mortgageDetails;
  const [edited, setEdited] = useState(false);
  const [highlightFields, setHighlightFields] = useState(errorFields ?? []);

  const [details, setDetails] = useState<MortgageDetails>({
    estimatedValue: inputDetails?.estimatedValue || undefined,

    monthlyPayment: inputDetails?.monthlyPayment || undefined,
    purchasePrice: inputDetails?.purchasePrice || undefined,
    purchaseDate: inputDetails?.purchaseDate || undefined,
    // Treat null as undefined for currency input
    originalBalance: CopyOptionalIntegratedFields(
      inputDetails?.originalBalance
    ),
    interestRate: CopyOptionalIntegratedFields(inputDetails?.interestRate),
    term: CopyOptionalIntegratedFields(inputDetails?.term),
    address: CopyOptionalIntegratedFields(inputDetails?.address),
    dueDate: inputDetails?.dueDate || undefined,
  });

  const [autoUpdate, setAutoUpdate] = useState(
    !!account.automaticPaymentDeduction
  );

  const clearHighlight = (field: keyof MortgageDetails) => {
    if (highlightFields.includes(field)) {
      setHighlightFields(highlightFields.filter((f) => f !== field));
    }
  };

  const onBlurUpdate = (field: keyof MortgageDetails) => {
    if (edited) {
      clearHighlight(field);
      setEdited(false);
      onUpdate?.({
        details: {
          mortgageDetails: details,
        },
      });
    }
  };

  const updateDetails = (newDetails: MortgageDetails) => {
    if (!shallowEquals(details, newDetails)) {
      setDetails(newDetails);

      if (!edited) {
        setEdited(true);
      }
    }
  };

  const paymentInfo = getProjectedPaymentsInfo(
    account.balances.current,
    details.interestRate?.value,
    details.monthlyPayment,
    details.dueDate
  );

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Estimated Value"
          value={account.details?.mortgageDetails?.estimatedValue}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Purchase Price"
          value={account.details?.mortgageDetails?.purchasePrice}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Original Loan Amount"
          value={account.details?.mortgageDetails?.originalBalance?.value}
        />

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Purchase Date"
          value={account.details?.mortgageDetails?.purchaseDate}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Term (years)"
          value={account.details?.mortgageDetails?.term?.value}
        />

        <AccountInformationValueCard
          type={ValueCardType.Percentage}
          title="Interest Rate"
          value={account.details?.mortgageDetails?.interestRate?.value}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Monthly Payment"
          value={account.details?.mortgageDetails?.monthlyPayment}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Address"
          value={account.details?.mortgageDetails?.address?.value}
        />

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Due Date"
          value={account.details?.mortgageDetails?.dueDate}
        />

        <AutoDeductToggleDisplayCard
          isConnected={isConnected}
          checked={account.automaticPaymentDeduction && paymentInfo.valid}
        />
      </>
    );
  }

  return (
    <>
      <div>
        <ModalInput
          id="account-estimated-value"
          type="money"
          className={stepClassNames("estimatedValue", highlightFields)}
          label={"Estimated Value"}
          value={details.estimatedValue}
          placeholder="$650,000"
          onChange={(v) => {
            updateDetails({
              ...details,
              estimatedValue: sanitizeStringNumberToString(v),
            });
          }}
          onBlur={() => onBlurUpdate("estimatedValue")}
        />
        <Text type={TextType.Div} style={TextStyle.Hint}>
          Not sure? Try{" "}
          <PylonALink
            href="https://www.redfin.com/what-is-my-home-worth"
            target="_blank"
          >
            Redfin{" "}
          </PylonALink>
          or{" "}
          <PylonALink
            href="https://www.zillow.com/how-much-is-my-home-worth/"
            target="_blank"
          >
            Zillow{" "}
          </PylonALink>
          to get an estimate
        </Text>
      </div>

      <ModalInput
        id="account-monthlyPayment"
        type="money"
        className={stepClassNames("monthlyPayment", highlightFields)}
        label={"Monthly Payment"}
        value={details.monthlyPayment}
        placeholder="$3,000"
        onChange={(v) => {
          updateDetails({
            ...details,
            monthlyPayment: v,
          });
        }}
        onBlur={() => onBlurUpdate("monthlyPayment")}
      />

      <ModalInput
        id="account-purchasePrice"
        type="money"
        className={stepClassNames("purchasePrice", highlightFields)}
        label={"Purchase Price"}
        value={details.purchasePrice}
        placeholder="$600,000"
        onChange={(v) => {
          updateDetails({
            ...details,
            purchasePrice: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={() => onBlurUpdate("purchasePrice")}
      />

      {!validNumberIntegrateField(details.originalBalance) && (
        <ModalInput
          id="account-originalBalance"
          type="money"
          className={stepClassNames("originalBalance", highlightFields)}
          label={"Original Loan Amount"}
          value={details.originalBalance?.value}
          placeholder="$600,000"
          onChange={(v) => {
            updateDetails({
              ...details,
              originalBalance: {
                isIntegrated: false,
                value: sanitizeStringNumberToString(v),
              },
            });
          }}
          onBlur={() => onBlurUpdate("originalBalance")}
        />
      )}

      {!validNumberIntegrateField(details.interestRate) && (
        <ModalInput
          id="account-interestRate"
          type="percentage"
          className={stepClassNames("interestRate", highlightFields)}
          label={"Interest Rate"}
          value={details.interestRate?.value}
          placeholder="5%"
          onChange={(v) => {
            updateDetails({
              ...details,
              interestRate: {
                value: sanitizeStringNumberToString(v),
                isIntegrated: false,
              },
            });
          }}
          onBlur={() => onBlurUpdate("interestRate")}
        />
      )}

      {!validStringIntegrateField(details.term) && (
        <ModalInput
          id="account-term"
          type="string"
          className={stepClassNames("term", highlightFields)}
          label={"Terms (years)"}
          value={details.term?.value}
          placeholder="30"
          onChange={(v) => {
            if (v) {
              const value = parseInt(v);
              if (value > 99) {
                // Reject values greater than 99
                return;
              }
            }

            updateDetails({
              ...details,
              term: {
                value: v,
                isIntegrated: false,
              },
            });
          }}
          onBlur={() => onBlurUpdate("term")}
        />
      )}

      {!validStringIntegrateField(details.address) && (
        <ModalInput
          id="account-address"
          type="string"
          className={stepClassNames("address", highlightFields)}
          label={"Address"}
          value={details.address?.value}
          placeholder="1600 Pennsylvania Avenue NW, Washington, DC 20500, USA"
          onChange={(v) => {
            updateDetails({
              ...details,
              address: {
                value: v,
                isIntegrated: false,
              },
            });
          }}
          onBlur={() => onBlurUpdate("address")}
        />
      )}

      <ModalDateInput
        id="account-purchase-date"
        label="Purchase Date"
        placeholderText="01/20/2020"
        className={stepClassNames("purchaseDate", highlightFields)}
        minDate={addYears(new Date(), -50)}
        maxDate={new Date()}
        selected={details.purchaseDate ? new Date(details.purchaseDate) : null}
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...details,
              purchaseDate: date?.toUTCString() ?? undefined,
            };

            clearHighlight("purchaseDate");
            setDetails(newDetails);
            onUpdate?.({
              details: {
                mortgageDetails: newDetails,
              },
            });
          }
        }}
      />

      <ModalDateInput
        id="account-due-date"
        label="Due Date"
        placeholderText="01/20/2030"
        className={stepClassNames("dueDate", highlightFields)}
        minDate={new Date()}
        maxDate={addYears(new Date(), 35)}
        selected={details.dueDate ? new Date(details.dueDate) : null}
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...details,
              dueDate: date?.toUTCString() ?? undefined,
            };

            clearHighlight("dueDate");
            setDetails(newDetails);
            onUpdate?.({
              details: {
                mortgageDetails: newDetails,
              },
            });
          }
        }}
      />

      {!isConnected && (
        <AutomaticPaymentDeductionToggle
          disabled={!paymentInfo.valid}
          checked={autoUpdate}
          onChange={() => {
            onUpdate?.({
              automaticPaymentDeduction: !autoUpdate,
            });
            setAutoUpdate(!autoUpdate);
          }}
        />
      )}
    </>
  );
};

const stepClassNames = (
  field: keyof MortgageDetails,
  errorFields: string[]
) => {
  const classes = classnames({
    "account-input-highlight-error": shouldHighlight(field, errorFields),
  });
  return classes;
};

const shouldHighlight = (
  field: keyof MortgageDetails,
  errorFields: string[]
) => {
  return errorFields.includes(field);
};
