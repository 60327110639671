import * as React from "react";
import CSS from "csstype";
import Modal from "react-modal";
import { BaseModalProps } from "components/core/modal/props";
import "../styles/modal.css";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

interface PublicProps {
  showModal: boolean;
  title?: string;
  overflow?: CSS.Property.Overflow;
  children: React.ReactNode;

  onCancel: () => void;
  cancelButtonText?: string;
  hideCancel?: boolean;

  onAction: () => void;
  actionButtonText: string;
  actionButtonDisabled?: boolean;
  hideAction?: boolean;
  additionalModalClassName?: string;

  buttonGrouping?: CSS.Property.JustifyContent;
}

type Props = PublicProps & BaseModalProps;

/*
 * Used to notify user to confirm their actions
 */
export const ConfirmationModalDC: React.FC<Props> = (props) => {
  return (
    <Modal
      isOpen={props.showModal}
      shouldCloseOnOverlayClick={true}
      className="modal-base-dc"
      overlayClassName="overlay-base"
      style={{
        overlay: {
          backgroundColor: "rgba(3, 12, 23, 0.7)",
        },
      }}
    >
      <div
        className={` ${props.additionalModalClassName}`}
        style={{
          overflow: props.overflow,
          height: props.height,
          width: props.width,
          maxWidth: "100vw",
        }}
      >
        <div>{props.children}</div>
        <div
          style={{
            display: "flex",
            justifyContent: props.buttonGrouping
              ? props.buttonGrouping
              : "flex-end",
          }}
        >
          {!props.hideCancel && (
            <>
              <LeafButton
                buttonColour={LeafButtonColour.Transparent}
                buttonStyle={LeafButtonStyle.LeafSmall}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onCancel();
                }}
              >
                {props.cancelButtonText ?? "Cancel"}
              </LeafButton>
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              />
            </>
          )}
          {!props.hideAction && (
            <LeafButton
              buttonColour={LeafButtonColour.Black}
              buttonStyle={LeafButtonStyle.LeafSmall}
              disabled={props.actionButtonDisabled}
              onClick={(e) => {
                e.stopPropagation();
                props.onAction();
              }}
            >
              {props.actionButtonText ?? "Confirm"}
            </LeafButton>
          )}
        </div>
      </div>
    </Modal>
  );
};
