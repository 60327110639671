import { IconAsset } from "components/core/icons";

export enum PlanOptions {
  Childcare = "childcare",
  Car = "car",
  House = "house",
  Millionaire = "millionaire",
  Billionaire = "billionaire",
}

// Order of this impacts listing order of the items
export const PlanOptionAsset: Record<
  PlanOptions,
  {
    asset: IconAsset;
    name: string;
  }
> = {
  [PlanOptions.Millionaire]: {
    asset: IconAsset.PlanMillionaire,
    name: "Millionaire",
  },
  [PlanOptions.Billionaire]: {
    asset: IconAsset.PlanBillionaire,
    name: "Billionaire",
  },
  [PlanOptions.Car]: {
    asset: IconAsset.PlanCar,
    name: "New Car",
  },
  [PlanOptions.House]: {
    asset: IconAsset.PlanHouse,
    name: "New House",
  },
  [PlanOptions.Childcare]: {
    asset: IconAsset.PlanChildcare,
    name: "Childcare",
  },
};
