import * as React from "react";
import qs from "qs";
import { DashboardPages } from "components/features/dashboard/pages";
import { format } from "date-fns";
import { Input } from "components/core/input";
import { LeafButton, LeafButtonStyle } from "components/core/leaf-button";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { PylonDatePicker } from "components/core/date-picker";
import { PylonLink } from "components/core/link";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SupportMessageModal } from "components/features/dashboard/components/support-message-modal";
import { Text } from "components/core/text";
import { toast } from "react-toastify";
import { useInputFields } from "core/hooks/use-input-fields";
import { useLazyQuery, useMutation } from "@apollo/client";
import "./styles.css";
import {
  clearAllSessionsMutation,
  ProfileInput,
  profileQuery,
  profileQueryResponse,
  SessionData,
  updateProfileMutation,
} from "core/queries/profiles";

interface Props {}

export const Settings: React.FC<Props> = () => {
  const [showSupportModal, setShowSupportModal] =
    React.useState<boolean>(false);
  const [fetchProfileQuery, { loading, data, error }] =
    useLazyQuery<profileQueryResponse>(profileQuery, {
      fetchPolicy: "network-only",
    });

  const [lastRefreshMarker, setLastRefreshMarker] = React.useState("");
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  const refresh = query["refresh"] ?? "0";
  React.useEffect(() => {
    if (refresh !== lastRefreshMarker) {
      setLastRefreshMarker(refresh as string);
      fetchProfileQuery();
    }
  }, [fetchProfileQuery, refresh, lastRefreshMarker]);
  const dateOfBirthChangeHandler = (date: Date) => {
    const data = {
      variables: {
        profile: {
          dob: date ? date.getTime() : null,
        },
      },
    };
    updateProfile(data);
  };

  const [updateProfile] = useMutation(updateProfileMutation, {
    refetchQueries: [{ query: profileQuery }],
    onError: (err) => {
      toast(
        <PylonToastBody
          title={"Unexpected Profile Error Occurred"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
  });

  const [clearAllSessions] = useMutation(clearAllSessionsMutation, {
    refetchQueries: [{ query: profileQuery }],
    onError: (err) => {
      toast(
        <PylonToastBody
          title={"Unexpected Error While Clearing Sessions"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
  });

  const initialValues: ProfileInput = {
    occupation: data?.profile?.occupation,
    income: data?.profile?.income || undefined,
  };

  const { createBlurHandler, createKeyDownHandler } =
    useInputFields<ProfileInput>(
      initialValues,
      (key: string, value: string) => {
        value = cleanInputFieldValue(value);

        const profile: ProfileInput = {};
        profile[key] = value;
        const data = {
          variables: {
            profile: profile,
          },
        };
        updateProfile(data);
      }
    );

  const cleanInputFieldValue = (s: string) => {
    if (!s) return s;
    return s.replace(/&nbsp;/g, "");
  };

  if (loading || error || !data) return null;

  const renderSessions = () => {
    const nodes: JSX.Element[] = [];
    data?.profile?.sessionData.forEach((session: SessionData) => {
      const addedAt = new Date(session.addedAt);
      nodes.push(
        <div className="table-row  mobile-flex-column" key={nodes.length}>
          <div className="table-cell status">Active</div>
          <div className="table-cell device">{session.userAgent}</div>
          <div className="table-cell location">{session.location}</div>
          <div className="table-cell last-activity">
            {addedAt.toLocaleDateString() + " " + addedAt.toLocaleTimeString()}
          </div>
          <div className="table-cell ip-address">{session.ipAddress}</div>
        </div>
      );
    });

    return nodes;
  };

  const renderSessionsSection = () => {
    if (!data?.profile?.sessionData || !data.profile.sessionData.length) {
      return "";
    }
    const nodes: JSX.Element = (
      <div className="settings-section sessions  mt2">
        <div>
          <div className="flex-column between">
            <div>
              <h2 style={{ marginBottom: "0" }}>Recent Activity</h2>
            </div>

            <div>
              Below is a list of all devices that have logged in to your account
              recently.
            </div>
            <div className="mt1">
              <LeafButton
                onClick={() => clearAllSessions()}
                buttonStyle={LeafButtonStyle.LeafSmallDense}
              >
                End all sessions
              </LeafButton>
            </div>
          </div>
        </div>
        <div className="table-wrapper sessions-table mt2">
          <div className="table-row head">
            <div className="table-cell status">Status</div>
            <div className="table-cell device">Device</div>
            <div className="table-cell location">Location</div>
            <div className="table-cell last-activity">Last activity</div>
            <div className="table-cell ip-address">IP address</div>
          </div>
          {renderSessions()}
        </div>
      </div>
    );
    return nodes;
  };

  return (
    <PageWrapper header={<PageTitle title="Settings" />}>
      <div>
        <div className="settings-section">
          <h2>Account Information</h2>
          <div className="table-wrapper">
            <div className="table-row mobile-flex-column">
              <div className="table-cell">
                <div className="label">Account Name</div>
                <div className="field-data">
                  {data.profile.firstName + " " + data.profile.lastName}
                </div>
              </div>
            </div>
            <div className="table-row mobile-flex-column">
              <div className="table-cell">
                <div className="label">Phone number</div>
                <div className="field-data">
                  {data.profile.phone}
                  {data.profile.phoneIsVerified && (
                    <div className="verified">
                      <div className="settings__green-check"></div>
                      Verified
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            Want to update your account information or close your account?{" "}
            <div
              className="button-as-link"
              onClick={() => {
                setShowSupportModal(true);
              }}
            >
              Please contact Pylon Support
            </div>
            <SupportMessageModal
              showModal={showSupportModal}
              onClose={() => {
                setShowSupportModal(false);
              }}
            />
          </div>
        </div>

        <div className="settings-section mt2">
          <h2>Email & Password</h2>
          <div className="table-wrapper">
            <div className="table-row  mobile-flex-column">
              <div className="table-cell">
                <div className="label">Email</div>
                <div>{data.profile.email}</div>
              </div>
              <div className="table-cell">
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PylonLink to={DashboardPages.SettingsEmail}>
                    Change email
                  </PylonLink>
                </div>
              </div>
            </div>
            <div className="table-row mobile-flex-column">
              <div className="table-cell">
                <PylonLink to={DashboardPages.SettingsPassword}>
                  Change password
                </PylonLink>
              </div>
            </div>
          </div>
        </div>

        <div className="settings-section  mt2">
          <h2>Profile Information</h2>
          <div className="table-wrapper">
            <div
              className="flex-row mobile-flex-column gap-1"
              style={{ paddingLeft: "1rem", paddingBottom: "1rem" }}
            >
              <div>
                <div className="label">Date of birth</div>
                <PylonDatePicker
                  placeholderText={"MM / DD / YYYY"}
                  value={
                    data?.profile?.dob
                      ? format(new Date(data?.profile?.dob), "MM/dd/yyyy")
                      : undefined
                  }
                  onChange={(date: any) => dateOfBirthChangeHandler(date)}
                />
              </div>
              <div>
                <div className="label">Occupation</div>
                <Input
                  type="text"
                  defaultValue={initialValues.occupation}
                  onBlur={createBlurHandler("occupation")}
                  onKeyDown={createKeyDownHandler("occupation")}
                />
              </div>
            </div>
          </div>
        </div>
        {renderSessionsSection()}
        <div className="settings-section  mt2">
          <Text>
            <PylonLink to={DashboardPages.TermsOverview}>Terms</PylonLink>
          </Text>
          <Text>
            <PylonLink to={DashboardPages.PrivacyOverview}>Privacy</PylonLink>
          </Text>
        </div>
      </div>
    </PageWrapper>
  );
};
