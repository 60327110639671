import { AccountSubtypeEnum } from "components/features/dashboard/components/add-account-tray/models";
import { SavvyPolicy } from "core/models/insurance";

export type OptionalIntegratedField<T> = {
  value: T | undefined;
  isIntegrated: boolean;
};

export interface RentalPropertyDetails {
  estimatedValue?: string;
  monthlyPayment?: string;
  purchaseDate?: string;

  originalBalance?: OptionalIntegratedField<string>;
  interestRate?: OptionalIntegratedField<string>;
  term?: OptionalIntegratedField<string>;
  address?: OptionalIntegratedField<string>;

  dueDate?: string;

  monthlyExpenses?: string;
  monthlyIncome?: string;
}

export interface MortgageDetails {
  estimatedValue?: string;
  monthlyPayment?: string;
  purchasePrice?: string;
  purchaseDate?: string;

  originalBalance?: OptionalIntegratedField<string>;
  interestRate?: OptionalIntegratedField<string>;
  term?: OptionalIntegratedField<string>;
  address?: OptionalIntegratedField<string>;

  dueDate?: string;
}

export interface SavingsDetails {
  apy?: string;
}

export interface PrivateStockDetails {
  sharesOwned?: string;
  valuePerShare?: string;
  remainingShares?: string;
  remainingShareType?: PrivateStockType;
  areRSUShares?: boolean;
  areOptionShares?: boolean;
  shareVestStartDate?: string;
  shareVestSchedule?: string;
  intentToSell?: boolean;
}
export interface PrivateCompanyDetails {
  estimatedValueAtInvestmentTime?: string;
  amountInvested?: string;
  amountInvestedPercentage?: string;
  investmentDate?: string;
  estimatedInvestmentValue?: string;
  dividendsPaid?: string;
  dividendsInterval?: string;
}

export interface CDDetails {
  term?: string;
  apy?: string;
  minimumDeposit?: string;
}

export interface HomeEquityLineOfCreditDetails {
  term?: string;
  interestRate?: string;
  creditLimit?: string;
  propertyAddress?: string;
  monthlyPayment?: string;
  dueDate?: string;
}

export interface HomeEquityLoanDetails {
  term?: string;
  interestRate?: string;
  amountBorrowed?: string;
  amountAvailable?: string;
  propertyAddress?: string;
  monthlyPayment?: string;
  dueDate?: string;
}

export interface HomeConstructionDetails {
  term?: string;
  interestRate?: string;
  amountBorrowed?: string;
  propertyAddress?: string;
  monthlyPayment?: string;
  dueDate?: string;
}

export interface AutoInsuranceDetails {
  policy: SavvyPolicy;
}

export interface PylonCoinType {
  id: string;
  symbol: string;
  name: string;
  price: number;
  total: number;
}

export interface CryptoDetails {
  holdings: PylonCoinType[];
}

export class InsuranceDetails {
  monthlyPremium?: string;
  deductible?: string;
}

export class LoanDetails {
  monthlyPayment?: string;
  dueDate?: string;
  interestRate?: string;
}

export class LifeInsuranceDetails {
  lifeInsuranceType?: string;
  lengthInYears?: string;
  annualPremium?: string;
  effectiveStartDate?: string;
  accountNumber?: string;
}

export interface ReverseMortgageDetails {
  interestRate?: string;
  monthlyPayment?: string;
  dueDate?: string;
}

export class CreditCardDetails {
  monthlyPayment?: string;
  dueDate?: string;
  interestRate?: string;
  __typename?: string;
}

export interface AccountDetails {
  mortgageDetails?: MortgageDetails;
  savingsDetails?: SavingsDetails;
  privateStockDetails?: PrivateStockDetails;
  cdDetails?: CDDetails;
  homeEquityLineOfCreditDetails?: HomeEquityLineOfCreditDetails;
  homeEquityLoanDetails?: HomeEquityLoanDetails;
  homeConstructionDetails?: HomeConstructionDetails;
  autoInsuranceDetails?: AutoInsuranceDetails;
  insuranceDetails?: InsuranceDetails;
  cryptoDetails?: CryptoDetails;
  loanDetails?: LoanDetails;
  lifeInsuranceDetails?: LifeInsuranceDetails;
  creditCardDetails?: CreditCardDetails;
  reverseMortgageDetails?: ReverseMortgageDetails;
  rentalPropertyDetails?: RentalPropertyDetails;
  privateCompanyDetails?: PrivateCompanyDetails;
}

export type PrivateStockType = "rsu" | "option";

export const PrivateStockValueName: Record<PrivateStockType, string> = {
  rsu: "RSU",
  option: "Option",
};

export const CopyOptionalIntegratedFields = <T>(
  f: OptionalIntegratedField<T> | undefined
): OptionalIntegratedField<T> => {
  if (!f) {
    return {
      value: undefined,
      isIntegrated: false,
    };
  }

  return {
    value: f.value,
    isIntegrated: f.isIntegrated,
  };
};

export const GenerateDetailsForAccountSubtype = (
  subtype: string,
  details: Object
): Partial<AccountDetails> => {
  let deets: Partial<AccountDetails>;
  switch (subtype) {
    case AccountSubtypeEnum.Mortgage:
      deets = {
        mortgageDetails: {
          ...details,
        } as MortgageDetails,
      };
      break;
    case AccountSubtypeEnum.RentalProperty:
      deets = {
        rentalPropertyDetails: {
          ...details,
        } as RentalPropertyDetails,
      };
      break;
    default:
      deets = {};
  }
  return deets;
};
