import { AccountTransactionContextProvider } from "components/contexts/account-transaction-context";
import {
  GlobalFilteringContext,
  GlobalFilteringContextProvider,
} from "components/contexts/global-filtering-context";
import { CollaboratorFilter } from "components/features/dashboard/components/collaborator-filter";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { TransactionMonthSelector } from "components/features/dashboard/components/transaction-month-selector";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { CreditSummaryCard } from "components/features/dashboard/pages/summary/credit-summary";
import { ExpensesSummaryCard } from "components/features/dashboard/pages/summary/expenses-summary";
import { NetWorthSummaryCard } from "components/features/dashboard/pages/summary/networth-summary";
import { TransactionsSummary } from "components/features/dashboard/pages/summary/transactions-summary";
import * as React from "react";
import { SummaryContextProvider } from "../../../../contexts/summary-context";
import "./styles.css";

export const Summary: React.FC = () => {
  return (
    <GlobalFilteringContextProvider>
      <SummaryWrapper />
    </GlobalFilteringContextProvider>
  );
};

const SummaryWrapper: React.FC = () => {
  const { ownerIDs } = React.useContext(GlobalFilteringContext);
  return (
    <SummaryContextProvider>
      <AccountTransactionContextProvider limitToUserIDs={ownerIDs}>
        <PageWrapper
          setWidth
          header={<PageTitle title="Dashboard"></PageTitle>}
          actions={
            <>
              <div className="flex-row align-center mobile-flex-column">
                <div style={{ marginRight: "1rem" }}>
                  <CollaboratorFilter />
                </div>
                <TransactionMonthSelector updateGloablFilter />
              </div>
            </>
          }
        >
          <div className="overview layout-wide">
            <div className="overview-vertical-section">
              <div
                className="flex-row between mobile-flex-column topline"
                style={{ width: "100%" }}
              >
                <NetWorthSummaryCard />
                <ExpensesSummaryCard />
                <CreditSummaryCard />
              </div>
            </div>
            <div className="overview-vertical-section">
              <TransactionsSummary />
            </div>
          </div>
        </PageWrapper>
      </AccountTransactionContextProvider>
    </SummaryContextProvider>
  );
};
