import classnames from "classnames";
import React from "react";
import { Icon, IconAsset } from "components/core/icons";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";

export enum OnboardingIconSize {
  Normal,
  Large,
}

export enum OnboardingCardSize {
  Summary,
  Normal,
  Smaller,
}
interface Props {
  inDevelopment?: boolean;
  id: string;
  asset: IconAsset;
  text: string;
  selected: boolean;
  onClick: () => void;
  size?: OnboardingCardSize;
  iconSize?: OnboardingIconSize;
  disabled?: boolean;
}

export const OnboardingCard: React.FC<Props> = ({
  inDevelopment,
  id,
  asset,
  text,
  selected,
  onClick,
  size,
  iconSize,
  disabled,
}) => {
  const mobileLayout = useMobileLayout();
  const cardClasses = classnames({
    "onboarding-card": !selected,
    "onboarding-card__selected": selected,
    summary: size === OnboardingCardSize.Summary,
    smaller: size === OnboardingCardSize.Smaller,
    large: iconSize === OnboardingIconSize.Large,
    disabled: disabled,
  });
  const iconClasses = classnames({
    "onboarding-card__icon": true,
    smaller: size === OnboardingCardSize.Smaller,
  });

  const getIconSize = () => {
    switch (iconSize) {
      case OnboardingIconSize.Large:
        return mobileLayout ? "15vw" : "9vh";
      case OnboardingIconSize.Normal:
      default:
        return "64px";
    }
  };
  return (
    <div
      id={id}
      className={cardClasses}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <div className={iconClasses}>
        <Icon
          height={getIconSize()}
          width={getIconSize()}
          asset={asset}
          svgClass={selected ? "green-01" : "text-03"}
        />
      </div>
      <div className="onboarding-card__text">
        {text}
        <>{inDevelopment && "*"}</>
      </div>
    </div>
  );
};
