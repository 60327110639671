import { SimpleDropDown } from "components/core/simple-drop-down";
import {
  AccountSubType,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";

export interface Props {
  accountType: AccountType;
  accountSubtype: string;
  onSelect: (newSubtype?: string) => void;
}

export const AccountSubtypeOptions: React.FC<Props> = ({
  accountType,
  accountSubtype,
  onSelect,
}) => {
  const subtypeOptions = AccountSubType[accountType]
    ? Object.values(AccountSubType[accountType])
    : [];

  return (
    <div>
      <SimpleDropDown
        options={subtypeOptions}
        selectedValue={accountSubtype}
        onSelect={onSelect}
        menuMaxHeight="12rem"
      />
    </div>
  );
};
