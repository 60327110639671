import { gql } from "@apollo/client";
import { Account, AccountDetails, Aprs, Balance } from "core/models";
import { DOCUMENT_FIELDS } from "core/queries/document-fragments";
import { SavvyPolicyFields } from "core/queries/insurance";

export interface AccountMetadataInput {
  id: string;
  mask: string;
  name: string;
  subtype: string;
  type: string;
}

export interface AccountInput {
  id: string;
  account_id: string;
  name?: string;
  type?: string;
  subtype?: string;
  balances?: Partial<Balance>;
  aprs?: Aprs;
  details?: AccountDetails;
}

export interface InstitutionMetadata {
  institutionID: string;
  institutionName: string;
}

export interface ExchangeTokenMetadata {
  institution: InstitutionMetadata;
  accounts: AccountMetadataInput[];
}

export const OptionalIntegratedFieldStringFragment = gql`
  fragment OptionalIntegratedFieldStringFields on OptionalIntegratedFieldString {
    value
    isIntegrated
  }
`;

export const ACCOUNT_FIELDS = gql`
  ${OptionalIntegratedFieldStringFragment}
  ${SavvyPolicyFields}
  fragment AccountFields on Account {
    id
    account_id
    balances {
      current
      available
      limit
    }
    billOverdue
    billDueDate
    minimumPayment
    lastStatementBalance
    aprs {
      cashPercentage
      specialPercentage
      transferPercentage
      purchasePercentage
    }
    mask
    name
    official_name
    subtype
    type
    notes
    beneficiaryNote
    created_at
    modified_at
    integration_source
    integration_id
    owner {
      id
      firstName
      lastName
    }
    recipients {
      id
      firstName
      lastName
      email
    }
    logo
    primaryColorHex
    institutionName
    typeProperties {
      isLiability
      isAsset
      creditUtilization {
        limit
        usedCredit
      }
      signMultiplier
      isLiquid
    }
    loginRequired
    isOwner
    isEditor
    isOwnerOrEditor
    automaticPaymentDeduction
    details {
      mortgageDetails {
        estimatedValue
        monthlyPayment
        purchasePrice
        purchaseDate

        originalBalance {
          ...OptionalIntegratedFieldStringFields
        }
        interestRate {
          ...OptionalIntegratedFieldStringFields
        }

        term {
          ...OptionalIntegratedFieldStringFields
        }
        address {
          ...OptionalIntegratedFieldStringFields
        }
        dueDate
      }
      rentalPropertyDetails {
        estimatedValue
        monthlyPayment
        originalBalance {
          ...OptionalIntegratedFieldStringFields
        }
        interestRate {
          ...OptionalIntegratedFieldStringFields
        }

        term {
          ...OptionalIntegratedFieldStringFields
        }
        address {
          ...OptionalIntegratedFieldStringFields
        }
        purchaseDate
        dueDate
        monthlyExpenses
        monthlyIncome
      }
      savingsDetails {
        apy
      }
      privateStockDetails {
        sharesOwned
        valuePerShare
        remainingShares
        areRSUShares
        areOptionShares
        remainingShareType
        shareVestStartDate
        shareVestSchedule
        intentToSell
      }
      privateCompanyDetails {
        estimatedValueAtInvestmentTime
        amountInvested
        amountInvestedPercentage
        investmentDate
        estimatedInvestmentValue
        dividendsPaid
        dividendsInterval
      }
      cdDetails {
        apy
        term
        minimumDeposit
      }
      homeEquityLineOfCreditDetails {
        interestRate
        term
        propertyAddress
        creditLimit
        monthlyPayment
        dueDate
      }
      homeEquityLoanDetails {
        interestRate
        term
        propertyAddress
        amountBorrowed
        amountAvailable
        monthlyPayment
        dueDate
      }
      homeConstructionDetails {
        interestRate
        term
        propertyAddress
        amountBorrowed
        monthlyPayment
        dueDate
      }
      reverseMortgageDetails {
        monthlyPayment
        dueDate
        interestRate
      }
      autoInsuranceDetails {
        policy {
          ...SavvyPolicyFields
        }
      }
      cryptoDetails {
        holdings {
          id
          symbol
          name
          price
          total
        }
      }
      insuranceDetails {
        deductible
        monthlyPremium
      }
      loanDetails {
        monthlyPayment
        dueDate
        interestRate
      }
      lifeInsuranceDetails {
        lifeInsuranceType
        lengthInYears
        annualPremium
        effectiveStartDate
        accountNumber
      }
      creditCardDetails {
        monthlyPayment
        dueDate
        interestRate
      }
      reverseMortgageDetails {
        monthlyPayment
        dueDate
        interestRate
      }
    }
    integrationItemID
    oneDayChange
    itemHasInvestments
    contacts {
      id
      name
      email
      phone
      website
    }
  }
`;

export const ACCOUNT_TAG_TYPE_FIELDS = gql`
  fragment AccountTagTypeFields on Account {
    account_id
    name
    type
    institutionName
    official_name
    isOwner
    isEditor
    isOwnerOrEditor
    owner {
      id
    }
  }
`;

export const addAccountMutation = gql`
  ${ACCOUNT_FIELDS}

  mutation AddAccountMutation($account: AccountInput!) {
    addAccount(account: $account) {
      ...AccountFields
    }
  }
`;

export const addCryptoPortfolio = gql`
  mutation AddCryptoPortfolio($input: AddCryptoPortfolioInput!) {
    AddCryptoPortfolio(input: $input) {
      id
      account_id
    }
  }
`;
export interface AddAccountMutationResponse {
  addAccount: Account;
}

export interface GetAccountResponse {
  account: Account;
}

export interface BalanceHistoryData {
  date: number;
  balance: number;
}

export interface BalanceHistory {
  history: BalanceHistoryData[];
}

export interface GetBalanceHistoryResponse {
  balanceHistory: BalanceHistory;
}

export const getAccountByID = gql`
  ${ACCOUNT_FIELDS}
  query GetAccountByID($id: ID!) {
    account(id: $id) {
      ...AccountFields
    }
  }
`;

export const FETCH_ACCOUNT_DOCUMENTS = gql`
  ${DOCUMENT_FIELDS}
  query fetchAccountDocuments($id: ID!) {
    account(id: $id) {
      id
      documents {
        ...DocumentFields
      }
    }
  }
`;

export interface UpdateAccountMutationResponse {
  updateAccount: Account;
}

export const updateAccountMutation = gql`
  ${ACCOUNT_FIELDS}
  mutation UpdateAccountMutation(
    $account: AccountInput!
    $convert: ConvertAccountInput
  ) {
    updateAccount(account: $account, convert: $convert) {
      ...AccountFields
    }
  }
`;

export interface UpdateAccountMutationInput {
  account: AccountInput;
  convert?: {
    accountID: string;
    keepShared: boolean;
  };
}

export interface AccountsResponse {
  accounts: Account[];
}

export interface AccountsBySubtypeResponse {
  accountsBySubtype: Account[];
}

export interface AccountsBySubtypesResponse {
  accountsBySubtypes: Account[];
}

export const accountsQueryForIDs = gql`
  query GetAccounts {
    accounts {
      id
    }
  }
`;

export const accountsQuery = gql`
  ${ACCOUNT_FIELDS}
  query GetAccounts {
    accounts {
      ...AccountFields
    }
  }
`;

export const accountCategoriesQuery = gql`
  ${ACCOUNT_FIELDS}
  query GetAccountCategories {
    accountCategories {
      index
      category
      total
      accounts {
        ...AccountFields
      }
    }
  }
`;

export const accountCategoriesExamplesQuery = gql`
  ${ACCOUNT_FIELDS}
  query GetAccountCategoriesExamples {
    accountCategoriesExamples {
      index
      category
      total
      accounts {
        ...AccountFields
      }
    }
  }
`;

export const accountsBySubtypeQuery = gql`
  ${ACCOUNT_FIELDS}
  query GetAccountsBySubtype($subtype: String!) {
    accountsBySubtype(subtype: $subtype) {
      ...AccountFields
    }
  }
`;

export const accountsBySubtypesQuery = gql`
  ${ACCOUNT_FIELDS}
  query GetAccountsBySubtypes($subtypes: [String!]!) {
    accountsBySubtypes(subtypes: $subtypes) {
      ...AccountFields
    }
  }
`;

export const updateAccountCategories = gql`
  mutation UpdateAccountCategories($categories: [CategoryDataInput]!) {
    updateAccountCategories(categories: $categories) {
      category
      index
    }
  }
`;

export const deleteAccountMutation = gql`
  mutation DeleteAccount($id: ID!) {
    deleteAccount(id: $id)
  }
`;

export const refreshAccountMutation = gql`
  ${ACCOUNT_FIELDS}
  mutation RefreshAccount($id: ID!, $integrationSource: String) {
    refreshAccount(id: $id, integrationSource: $integrationSource) {
      ...AccountFields
    }
  }
`;

export const getPlaidLinkTokenQuery = gql`
  query GetPlaidLinkToken($itemID: ID) {
    getLinkToken(itemID: $itemID)
  }
`;

export const reconnectPlaidSuccessMutation = gql`
  mutation ReconnectPlaidSuccess($itemID: ID!) {
    reconnectSuccess(itemID: $itemID)
  }
`;

export const exchangeTokenMutaion = gql`
  ${ACCOUNT_FIELDS}
  mutation ExchangePlaidTokenMutation(
    $token: String!
    $metadata: ExchangeTokenMetadata
  ) {
    exchangeLinkToken(token: $token, metadata: $metadata) {
      itemID
      institutionName
      accounts {
        ...AccountFields
      }
      duplicateAccounts {
        ...AccountFields
      }
    }
  }
`;

export interface LinkTokenResponse {
  getLinkToken: string;
}

export const addPlaidAccountsMutation = gql`
  ${ACCOUNT_FIELDS}

  mutation AddPlaidAccountMutation(
    $itemID: String!
    $accounts: [AccountInput!]!
  ) {
    addPlaidAccounts(itemID: $itemID, accounts: $accounts) {
      ...AccountFields
    }
  }
`;

export interface AddPlaidAccountsMutationResponse {
  addPlaidAccounts: Account[];
}

export const FETCH_ACCOUNT_BALANCE_HISTORY = gql`
  query BalanceHistory($accountID: ID!) {
    balanceHistory(accountID: $accountID) {
      history {
        date
        balance
      }
    }
  }
`;

export const FETCH_ACCOUNT_NAME_TYPE = gql`
  ${ACCOUNT_TAG_TYPE_FIELDS}
  query FetchAccountNames {
    accounts {
      ...AccountTagTypeFields
    }
  }
`;

export type AccountTagType = Pick<
  Account,
  | "account_id"
  | "name"
  | "owner"
  | "type"
  | "institutionName"
  | "official_name"
  | "isOwner"
  | "isEditor"
  | "isOwnerOrEditor"
>;

export interface AccountNameTypeResponse {
  accounts: AccountTagType[];
}

export interface FetchAccountsResponse {
  accounts: Account[];
}

export interface AccountCategory {
  index: number;
  category: string;
  total: number;
  accounts: Account[];
  hidden: boolean;
}

export interface AccountCategoriesResponse {
  accountCategoriesExamples: AccountCategory[];
}

export interface Transaction {
  id: string;
  accountID: string;
  userID: string;
  createdAt: string;
  date: string;

  integrationID: string;
  integrationItemID: string;
  integrationSource: string;
  transactionType: string;
  transactionSubtype: string;
  description: string;

  amount: number;
  quantity: number;
  price: number;
  fees: number;
  remainingShares: number;

  notes: string;
  isoCurrencyCode: string;
  securityHoldingID: string;
  pending: boolean;
}

export interface GetInvestmentTransactionsResponse {
  transactions: {
    total: number;
    transactions: Transaction[];
  };
}

export const GetInvestmentTransactions = gql`
  query GetInvestmentTransactions($accountID: ID!) {
    transactions(accountID: $accountID) {
      transactions {
        id
        amount
        quantity
        price
        notes
        fees
        date
        remainingShares
        createdAt
      }
      total
    }
  }
`;

export enum InvestmentTransactionType {
  PrivateStock = "PRIVATE_STOCK",
}

export enum InvestmentTransactionSubtype {
  SecondarySale = "SECONDARY_SALE",
}

export interface CreateInvestmentTransactionResponse {
  createInvestmentTransaction: Transaction;
}

export const CreateInvestmentTransaction = gql`
  mutation CreateInvestmentTransaction($input: InvestmentTransactionInput!) {
    createInvestmentTransaction(input: $input) {
      id
      amount
      quantity
      price
      notes
      fees
      date
      remainingShares
      createdAt
    }
  }
`;

export const UpdateInvestmentTransaction = gql`
  mutation UpdateInvestmentTransaction(
    $input: UpdateInvestmentTransactionInput!
  ) {
    updateInvestmentTransaction(input: $input) {
      id
      amount
      quantity
      price
      notes
      fees
      date
      remainingShares
      createdAt
    }
  }
`;

export const DeleteInvestmentTransaction = gql`
  mutation DelelteInvestmentTransaction($accountID: ID!, $id: ID!) {
    deleteInvestmentTransaction(accountID: $accountID, id: $id)
  }
`;

export interface AccountContactResponse {
  name: string;
  phone: string;
  email: string;
  website: string;
}

export const CreateAccountContact = gql`
  mutation CreateAccountContact($input: AccountContactInput!) {
    createAccountContact(input: $input) {
      name
      phone
      email
      website
    }
  }
`;

export const UpdateAccountContact = gql`
  mutation UpdateAccountContact($input: AccountContactInput!) {
    updateAccountContact(input: $input) {
      name
      phone
      email
      website
    }
  }
`;

export const DeleteAccountContact = gql`
  mutation DeleteAccountContact($accountID: ID!, $contactID: ID!) {
    deleteAccountContact(accountID: $accountID, contactID: $contactID)
  }
`;

export const handlePlaidError = gql`
  mutation HandlePlaidError(
    $linkSessionID: String!
    $institutionID: String!
    $error: String!
    $requestID: String!
    $eventName: String
  ) {
    handleError(
      linkSessionID: $linkSessionID
      institutionID: $institutionID
      error: $error
      requestID: $requestID
      eventName: $eventName
    )
  }
`;

export interface InstitutionStatusResponse {
  plaidInstitutionStatus: {
    institutionID: string;
    name: string;
    status: string;
    percentage: string;
  };
}

export const getInstitutionStatus = gql`
  query PlaidInstitutionStatus($institutionID: String!) {
    plaidInstitutionStatus(institutionID: $institutionID) {
      institutionID
      name
      status
      percentage
    }
  }
`;
