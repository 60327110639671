import * as React from "react";
import Chart from "react-apexcharts";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { ChartCreditUtilization } from "components/features/dashboard/components/chart-credit-utilization";
import { Colour } from "core/models";
import { CreditScoreContainer } from "components/features/dashboard/components/credit-summary-section/components/credit-score-container";
import { DashboardPages } from "components/features/dashboard/pages";
import { Link } from "react-router-dom";
import { PylonTooltip, TooltipIconSize } from "components/core/tooltip";
import { toMoneyString } from "core/utils";
import { useMobileLayout } from "core/hooks/responsive";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  Text,
  TextD,
  TextFormat,
  TextStyle,
  TextType,
} from "components/core/text";
import {
  getCreditUtilization,
  GetCreditUtilizationResponse,
  UtilizationResponse,
} from "core/queries/credit";
interface Props {
  showUtilizationDetails?: boolean;
  showLinkToCreditHistoryPage?: boolean;
}
export const CreditSummarySection: React.FC<Props> = ({
  showUtilizationDetails,
  showLinkToCreditHistoryPage,
}) => {
  const mobileLayout = useMobileLayout();
  const { data } = useQuery<GetCreditUtilizationResponse>(
    getCreditUtilization,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const getUtilizationColor = React.useCallback((u: UtilizationResponse) => {
    const utilization = (u.balance / u.limit) * 100;
    return utilization === 0
      ? "var(--yellow-01)"
      : utilization >= 50
      ? "var(--red-01)"
      : utilization > 0 && utilization < 10
      ? "var(--green-01)"
      : utilization >= 10 && utilization < 30
      ? "var(--yellow-01)"
      : "var(--orange-01)";
  }, []);

  const getSeries = React.useCallback<any>(
    (u: UtilizationResponse) => {
      return {
        series: [Math.round((u.balance / u.limit) * 100)],
        options: {
          chart: {
            type: "radialBar",
          },
          fill: {
            colors: [getUtilizationColor(u)],
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "30%",
              },
              track: {
                background: "var(--gray-10)",
              },
              dataLabels: {
                enabled: true,
                value: { fontSize: ".75rem", offsetY: "-12" },
              },
            },
          },
          labels: [""],
        },
      };
    },
    [getUtilizationColor]
  );

  const renderMobile = () => {
    return (
      <div className="flex-row between mobile-flex-column">
        {data?.creditUtilization.map((v) => (
          <div
            key={v.account.account_id}
            className="flex-row mobile-flex-column credit-summary-mobile-row"
          >
            <div
              className="flex-row"
              style={{ alignItems: "center", marginBottom: "1rem" }}
            >
              <AccountIcon account={v.account} scale={1} />
              <Text
                type={TextType.Div}
                size={"1rem"}
                colour={Colour.Navy}
                weight={700}
              >
                {v.account.name}
              </Text>
            </div>
            <div className="flex-row between" style={{ marginBottom: "1rem" }}>
              <div className="flex-row mobile-flex-column">
                <Text
                  type={TextType.Div}
                  size={"0.75rem"}
                  weight={700}
                  format={TextFormat.UpperCase}
                >
                  BALANCE
                </Text>
                <Text type={TextType.Div} size={"1rem"} colour={Colour.Navy}>
                  {toMoneyString(v.balance)}
                </Text>
              </div>
              <div className="flex-row mobile-flex-column">
                <Text
                  type={TextType.Div}
                  size={"0.75rem"}
                  weight={700}
                  format={TextFormat.UpperCase}
                >
                  LIMIT
                </Text>
                <Text type={TextType.Div} size={"1rem"} colour={Colour.Navy}>
                  {toMoneyString(v.limit)}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  width: "8rem",
                  height: "3rem",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "-2rem",
                }}
              >
                <Chart
                  options={getSeries(v).options}
                  series={getSeries(v).series}
                  type="radialBar"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderDesktop = () => {
    return (
      data?.creditUtilization &&
      data.creditUtilization.length > 0 && (
        <div
          className="flex-column"
          style={{ padding: "0 4rem", marginTop: "1rem" }}
        >
          <div className="flex-row">
            <div style={{ width: "55%" }}>
              <Text
                type={TextType.Div}
                size={"0.75rem"}
                weight={700}
                format={TextFormat.UpperCase}
              >
                CREDIT CARD
              </Text>
            </div>
            <div
              className="flex-row"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "25%",
              }}
            >
              <Text
                type={TextType.Div}
                size={"0.75rem"}
                weight={700}
                format={TextFormat.UpperCase}
              >
                BALANCE
              </Text>

              <Text
                type={TextType.Div}
                size={"0.75rem"}
                weight={700}
                format={TextFormat.UpperCase}
              >
                LIMIT
              </Text>
            </div>
          </div>
          <div>
            {data?.creditUtilization.map((v) => (
              <div
                key={v.account.account_id}
                className="flex-row"
                style={{ alignItems: "center", width: "100%" }}
              >
                <div
                  className="flex-row"
                  style={{ width: "55%", alignItems: "center" }}
                >
                  <AccountIcon account={v.account} scale={0.75} />
                  <Text
                    type={TextType.Div}
                    size={"1rem"}
                    colour={Colour.Navy}
                    weight={700}
                  >
                    {v.account.name}
                  </Text>
                </div>
                <div
                  className="flex-row"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "25%",
                  }}
                >
                  <Text type={TextType.Div} size={"1rem"} colour={Colour.Navy}>
                    {toMoneyString(v.balance)}
                  </Text>
                  <Text type={TextType.Div} size={"1rem"} colour={Colour.Navy}>
                    {toMoneyString(v.limit)}
                  </Text>
                </div>
                <div
                  style={{
                    width: "8rem",
                  }}
                >
                  <Chart
                    options={getSeries(v).options}
                    series={getSeries(v).series}
                    type="radialBar"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  const showUtilization = () => {
    return mobileLayout ? renderMobile() : renderDesktop();
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem 0",
          marginTop: "2rem",
          fontFamily: "'Montserrat', sans-serif",
        }}
      >
        <Text
          type={TextType.Div}
          weight={700}
          colour={Colour.Navy}
          size={"1.25rem"}
        >
          Credit
        </Text>

        <div>
          {showLinkToCreditHistoryPage && (
            <Link to={DashboardPages.CreditHistory}>
              <TextD style={TextStyle.M15SM} colour={Colour.Blue01}>
                VIEW CREDIT HISTORY
              </TextD>
            </Link>
          )}
        </div>
      </div>
      <div
        className={"overview-component credit-summary"}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          marginBottom: showUtilizationDetails ? "1rem" : "0",
        }}
      >
        {!showUtilizationDetails && (
          <div className="credit-summary-score">
            <div className="flex-row between mobile-flex-column">
              <div className="summary-section-title">
                <Text
                  type={TextType.Span}
                  colour={Colour.Navy}
                  weight={700}
                  size={"1.25rem"}
                  format={TextFormat.Capitalize}
                >
                  Credit Score{" "}
                </Text>{" "}
                <PylonTooltip
                  text={
                    <Text>
                      We provide you with your credit score for free so you can
                      see any changes as quickly as possible. Although your
                      credit generally doesn't change day to day, we'll keep you
                      posted of any major changes to this number. This number is
                      important for getting better interest rates, credit cards,
                      or loans you may want.
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <div className="chart-section">
                <CreditScoreContainer />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            borderTop: showUtilizationDetails
              ? "none"
              : "1px solid var(--separator-02)",
          }}
          className="credit-summary-utilization"
        >
          <div className="flex-row between mobile-flex-column">
            <div className="summary-section-title">
              <Text
                type={TextType.Span}
                colour={Colour.Navy}
                weight={700}
                size={"1.25rem"}
                format={TextFormat.Capitalize}
              >
                Credit Utilization
              </Text>
              <PylonTooltip
                text={
                  <Text>
                    This represents how much money you've spent versus how much
                    you have available via credit cards and loans. Your credit
                    score can be impacted by up to 30% on a monthly basis if
                    this number is too high. Try to keep this number above 0%
                    and below 30% at any given time so you don't negatively
                    impact your credit score. The ideal range is between 1% -
                    10%.
                  </Text>
                }
                size={TooltipIconSize.Small}
              />
            </div>
            <div className="chart-section">
              <ChartCreditUtilization />
            </div>
          </div>
        </div>
        {showUtilizationDetails &&
          data?.creditUtilization &&
          data.creditUtilization.length > 0 && (
            <div
              style={{
                borderTop: "1px solid var(--separator-02)",
                marginTop: "1rem",
              }}
            >
              {showUtilization()}
            </div>
          )}
      </div>
    </div>
  );
};
