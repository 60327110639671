import * as React from "react";
import "./terms.css";

export interface PublicProps {}

type Props = PublicProps;
export const Terms: React.FC<Props> = (props) => {
  return (
    <div className="terms dashboard-content-inner">
      <p className="c19">
        <span className="c16">TERMS OF SERVICE</span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <p className="c8">
        <span className="c0">Date Posted: May 1, 2021</span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <p className="c8">
        <span className="c0">
          These terms of service and license agreement (the
          &ldquo;Agreement&rdquo;) are issued by Pylon, Inc.
          (&ldquo;Pylon&rdquo;), a Delaware corporation, and governs your use of
          and access to its products and services, including the Pylon service
          and application that provides users with the ability to view aggregate
          financial and related information, and any other Pylon products or
          services.
        </span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <p className="c8">
        <span className="c0">
          In this Agreement, the above are referred to collectively as the
          &ldquo;Services.&rdquo; These terms are provided in connection with
          the Pylon Privacy Policy (the &ldquo;Privacy Policy&rdquo;), which can
          be found at www.hellopylon.com Please read this Agreement and the
          Privacy Policy carefully. By using the Services you agree to be bound
          by this Agreement and the Privacy Policy.{" "}
        </span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <p className="c8">
        <span className="c0">
          Services include software and services that we own and operate
          directly. As part of the Services, you may interact with third parties
          that provide services to us, such as services to allow you to extract
          information from financial service providers for display in the
          Services. Your interaction with those third parties is governed by
          their individual terms of service and privacy policies, which we
          advise you to review.
        </span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <p className="c8">
        <span className="c0">
          This Agreement may be updated periodically to account for changes to
          existing services, the introduction of new services, products,
          improvements, or features, or a change in the legal or regulatory
          framework regarding the Services. If there is a material change to the
          Agreement, Pylon agrees to provide notice of the revised Agreement,
          which may consist of a public notice on Pylon&rsquo;s web site, no
          less than thirty days before it will become effective.
        </span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <p className="c8">
        <span className="c0">
          As of the date posted above, the Services are available only in a
          &ldquo;beta service status.&rdquo; &nbsp;For specific terms of the
          Agreement applicable during the beta period, please carefully review
          Section X below. &nbsp;Pylon makes no guarantee that the Services will
          ever be publicly announced or made generally available to anyone in
          the future, and you agree that Pylon has no express or implied
          obligation to you to announce or introduce the Services. If Pylon
          makes the Services commercially available, Pylon will post a revised
          version of this Agreement without references to the beta status of the
          Services.
        </span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <p className="c8">
        <span className="c0">
          IF YOU DO NOT WISH TO BE BOUND BY THIS AGREEMENT OR ANY REVISED
          AGREEMENT IN THE FUTURE, YOU MAY NOT USE ANY OF THE SERVICES, MUST
          STOP ALL USE IMMEDIATELY, AND TERMINATE ANY ACCOUNTS YOU MAY HAVE WITH
          PYLON.
        </span>
      </p>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0 start" start={1}>
        <li className="c8 c10 li-bullet-0">
          <span className="c9">WHAT YOU PROMISE TO US</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c9"></span>
      </p>
      <ol className="c6 lst-kix_list_9-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            You are at least eighteen years old and have the authority to enter
            into legally binding agreements. You are using the Services for
            yourself, or you are actively monitoring and supervising your minor
            child&rsquo;s use of the Services. You remain responsible for any
            such use.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_9-0" start={2}>
        <li className="c5">
          <span className="c0">
            You have read and agree to the terms and conditions of the Privacy
            Policy.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_9-0" start={3}>
        <li className="c5">
          <span className="c0">
            You will not misuse the Services in any manner, nor will you assist,
            support, or suggest that anyone else do so, including in order to:
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_9-1 start" start={1}>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Probe, scan, or test the vulnerability of any system or network,
          </span>
        </li>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Breach or otherwise circumvent any security or authentication
            measures,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Access, tamper with, or use non-public areas or parts of the
            Services or shared areas of the Services that you do not have
            permission to access,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Interfere with or disrupt any user, host, or network (for example,
            by sending a virus, overloading, flooding, spamming, or mail-bombing
            any part of the Services),
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Access, search, or create accounts for the Services by any means
            other than our publicly supported interfaces (for example,
            &ldquo;scraping&rdquo; or creating accounts in bulk),
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Send unsolicited communications, promotions or advertisements, or
            spam,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Send altered, deceptive, or false source-identifying information,
            including &ldquo;spoofing&rdquo; or &ldquo;phishing,&rdquo;
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Promote or advertise products or services other than your own
            without appropriate authorization,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Resell, repackage, rebrand, or otherwise distribute the Services
            unless specifically authorized in writing to do so,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Publish or share materials that are unlawfully pornographic or
            indecent or contain extreme acts of violence,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Advocate bigotry or hatred against any person or group of people
            based on their race, religion, ethnicity, sex, gender identity,
            sexual preference, disability, or impairment,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Violate the law in any way, including storing, publishing, or
            sharing material that is fraudulent, defamatory, or misleading, or
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Violate the privacy or infringe the rights of others.
          </span>
        </li>
      </ol>
      <p className="c1 c13">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_9-0" start={4}>
        <li className="c5">
          <span className="c0">
            You will use the Services only for a valid legal purpose and only as
            permitted by applicable law, including federal and state data
            privacy regulations and export control laws.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_9-0" start={5}>
        <li className="c5">
          <span className="c0">
            With respect to export control laws, you agree that you will not
            provide or export the Services to (i) nationals or residents of
            Cuba, North Korea, Iran, Sudan, Syria, the Crimea region of Ukraine,
            or any other country or region as to which the United States has
            imposed a trade embargo or related restrictions; or (ii) any person
            or entity included in the U.S. Treasury Department&rsquo;s list of
            Specially Designated Nationals and Blocked Persons or the U.S.
            Commerce Department&rsquo;s Denied Persons List, Entity List, or
            Unverified List.{" "}
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_9-0" start={6}>
        <li className="c5">
          <span className="c0">
            You will not try to undermine our security safeguards, source code
            protections, or digital signing mechanisms, or unlawfully copy,
            reproduce, distribute, publish, or publicly display any Pylon
            intellectual property, including any copyrights, trademarks,
            patents, software, domain names, trade secrets, or information that
            any person would reasonably believe to be confidential.
          </span>
        </li>
      </ol>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={2}>
        <li className="c8 c10 li-bullet-0">
          <span className="c9">SOFTWARE USAGE</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c9"></span>
      </p>
      <ol className="c6 lst-kix_list_10-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            The Services include software (&ldquo;Software&rdquo;) and your
            right to use the Software depends on your compliance with the terms
            of this Agreement.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_10-0" start={2}>
        <li className="c5">
          <span className="c0">
            Pylon hereby grants you a limited, personal, internal use,
            non-exclusive, non-transferable, revocable license to use the
            Software, solely in connection with this Agreement and the Services.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_10-0" start={3}>
        <li className="c5">
          <span className="c0">
            This Agreement does not convey to you any rights of ownership in the
            Software. By accepting this Agreement you agree that the Software is
            licensed to you by Pylon and is not being sold or otherwise
            transferred to you.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_10-0" start={4}>
        <li className="c5">
          <span className="c0">
            As described further in Section V below, Pylon expressly disclaims
            any warranties of non-infringement, merchantability, and fitness for
            a particular purpose. Pylon does not warrant that the software will
            (i) achieve specific results, (ii) operate without interruption, or
            (iii) be error free. Pylon uses commercially reasonable efforts to
            protect your data, but does not warrant that your data will be
            secure in all circumstances, foreseen and unforeseen.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_10-0" start={5}>
        <li className="c5">
          <span className="c0">
            As described further in Section VII below, Pylon expressly disclaims
            all liability for the Software, including any loss or liability
            resulting from lost or compromised data caused by the Software. In
            no event will Pylon be liable for any damages, including lost
            profits or data, or other incidental or consequential damages,
            arising out of the use or inability to use the Software or any data
            supplied therewith, even if Pylon has been advised of the
            possibility of such damages, or for any claim by any other party.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={3}>
        <li className="c8 c10 li-bullet-0">
          <span className="c9">INTELLECTUAL PROPERTY RIGHTS</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c9"></span>
      </p>
      <ol className="c6 lst-kix_list_2-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            Your use of the Services constitutes your agreement to comply with
            all applicable intellectual property laws, including copyright laws.
            You agree not to upload, download, display, perform, transmit, or
            otherwise distribute any information in any format
            (&ldquo;Content&rdquo;) in violation of any person&rsquo;s or
            entity&rsquo;s copyrights, trademarks, or other intellectual or
            proprietary rights. You also agree that you are solely responsible
            for any violation of any intellectual property law or any
            infringement of any person&rsquo;s or entity&rsquo;s intellectual
            property rights caused by any Content that you use or transmit by
            means of our Services, networks, systems, or servers, or that is
            used or transmitted by another person by means of our networks,
            systems, or servers using the Services you have licensed or
            purchased from Pylon. You acknowledge that Pylon&rsquo;s policy
            generally is to cooperate in connection with investigations and
            litigation involving claims of infringement of intellectual property
            rights.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_2-0" start={2}>
        <li className="c5">
          <span className="c0">
            You agree not to upload, download, display, perform, transmit or
            otherwise distribute any Content that (i) is libelous, defamatory,
            obscene, pornographic, abusive, or threatening; (ii) advocates or
            encourages conduct that could constitute a criminal offense, give
            rise to civil liability, or otherwise constitute a violation of
            applicable law; or (iii) advertises or otherwise solicits funds or
            is a solicitation for the purchase or sale of goods or services.
            Pylon reserves the rights to terminate your ability to upload,
            download, display, perform, transmit or otherwise distribute such
            Content; terminate your Software license and your access to
            Services; and delete any such Content from Pylon&rsquo;s servers.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_2-0" start={3}>
        <li className="c5">
          <span className="c0">
            You grant us an unlimited, worldwide, non-revocable, transferable,
            perpetual license to Content you provide in connection with the
            Services. &nbsp;You acknowledge that we may incidentally retain
            copies of such Content for the term of your Agreement with us and
            thereafter. &nbsp;Your Content may be available to other users of
            the Services after termination of this Agreement. &nbsp;
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_2-0" start={4}>
        <li className="c5">
          <span className="c0">
            The services provided herein are protected by copyright, trademark,
            patent, and other United States and foreign laws. These terms do not
            grant you any right, title, or interest in the Services or in any
            PYLON trademark, logos, and other brand features. You may not use or
            display any trademarks or service marks owned by PYLON without
            PYLON&rsquo;s prior written consent.
          </span>
        </li>
      </ol>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={4}>
        <li className="c8 c10 li-bullet-0">
          <span className="c11">TERMINATION</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c9"></span>
      </p>
      <ol className="c6 lst-kix_list_3-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            You are free to stop using the Services at any time. We reserve the
            right to suspend or terminate your access to some or all of the
            Services with notice to you if:
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_3-1 start" start={1}>
        <li className="c4 li-bullet-0">
          <span className="c0">You are in breach of this Agreement, or</span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            You are using the Services in a manner that would cause a real risk
            of harm or loss to us or other users.
          </span>
        </li>
      </ol>
      <p className="c1 c13">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_3-0" start={2}>
        <li className="c5">
          <span className="c0">
            If we do terminate your access, we will provide you with reasonable
            advance notice via the email address associated with your account to
            remedy the activity that prompted us to contact you. If after such
            notice you fail to take the steps we ask of you, we will terminate
            or suspend your access to the Services.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_3-0" start={3}>
        <li className="c5">
          <span className="c0">
            In certain instances, however, we will terminate your account
            immediately without first providing you notice. This typically
            occurs if:
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_3-1" start={3}>
        <li className="c4 li-bullet-0">
          <span className="c0">
            You are in material breach of this Agreement,
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Providing you notice of termination would cause us legal liability
            or hinder our ability to service our other customers, or
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            We are prohibited from doing so by applicable law.
          </span>
        </li>
      </ol>
      <p className="c1 c13">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_3-0" start={4}>
        <li className="c5">
          <span className="c0">
            We may decide to discontinue the Services in response to new laws or
            regulations or other unforeseen circumstances beyond our control. If
            we stop offering some of all of the Services, we will give you
            reasonable prior notice so that you can export your data. If we
            discontinue a Product before your Annual or monthly subscription is
            over, you will receive a pro-rated portion of the fees you have
            pre-paid.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={5}>
        <li className="c8 c10 li-bullet-0">
          <span className="c11">NO WARRANTIES</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_4-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            WE STRIVE TO PROVIDE GREAT SERVICES, BUT THERE ARE CERTAIN THINGS
            THAT WE CANNOT GUARANTEE. THEREFORE, TO THE FULLEST EXTENT PERMITTED
            BY LAW, PYLON AND ITS AFFILIATES, SUPPLIERS, AND DISTRIBUTORS MAKE
            NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE
            SERVICES ARE PROVIDED &ldquo;AS IS,&rdquo; INCLUDING ANY SERVICES
            PROVIDED DURING A &ldquo;BETA PERIOD&rdquo; OR ON A SIMILAR BASIS.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_4-0" start={2}>
        <li className="c5">
          <span className="c0">
            WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, SATISFACTORY QUALITY, AND NON-INFRINGEMENT.
            PYLON ADVISES YOU TO SAFEGUARD IMPORTANT DATA, USE CAUTION, AND NOT
            RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR PERFORMANCE OF THE
            SERVICES.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={6}>
        <li className="c8 c10 li-bullet-0">
          <span className="c11">INDEMNITY</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_5-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            You agree to indemnify, defend, and hold harmless Pylon, its parents
            and affiliates, and its and their officers, directors, employees,
            consultants, and agents from liability for any and all third-party
            claims, liabilities, demands, disputes, causes of action, losses,
            damages, and costs and expenses of any kind resulting from:
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_5-1 start" start={1}>
        <li className="c4 li-bullet-0">
          <span className="c0">
            Any use of the Services in a manner not authorized by this
            Agreement;
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Any material breach by you of the provisions in this Agreement or
            the Privacy Policy; and
          </span>
        </li>
        <li className="c8 c12">
          <span className="c0">
            Any acts or omissions on your behalf which infringe, misappropriate,
            or otherwise violate the intellectual property rights of any other
            person.
          </span>
        </li>
      </ol>
      <p className="c1 c13">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_5-0" start={2}>
        <li className="c5">
          <span className="c0">
            You agree to notify Pylon immediately if you become aware of (i) an
            act of infringement, violation, or misappropriation of the
            intellectual property of any other person, or (ii) any unauthorized
            use of your account or any other breach of security known to you.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={7}>
        <li className="c8 c10 li-bullet-0">
          <span className="c11">LIMITATION OF LIABILITY</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_6-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, PYLON, ITS AFFILIATES,
            SUPPLIERS, CONTRACTORS, AND DISTRIBUTORS WILL NOT BE LIABLE FOR ANY
            INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL
            DAMAGES, OR ANY DAMAGES RELATED TO LOSS OF USE, DATA, BUSINESS, OR
            PROFITS, REGARDLESS OF LEGAL THEORY.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_6-0" start={2}>
        <li className="c5">
          <span className="c0">
            THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER
            PYLON OR ANY OF ITS AFFILIATES HAS BEEN WARNED OF THE POSSIBILITY OF
            SUCH DAMAGES.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_6-0" start={3}>
        <li className="c5">
          <span className="c0">
            IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS, OR RE-SALE
            PURPOSE, PYLON, ITS AFFILIATES, SUPPLIERS, AND DISTRIBUTORS WILL
            HAVE NO LIABILITY TO YOU FOR ANY DAMAGES RELATED TO LOSS OF PROFIT,
            LOSS OF BUSINESS, BUSINESS INTERRUPTION, OR LOSS OF BUSINESS
            OPPORTUNITY. PYLON AND ITS AFFILIATES ARE NOT RESPONSIBLE FOR THE
            CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SERVICES.
          </span>
        </li>
      </ol>
      <p className="c3">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={8}>
        <li className="c8 c10 li-bullet-0">
          <span className="c11">DISPUTE RESOLUTION</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0 start" start={1}>
        <li className="c5">
          <span className="c15">
            Prior to starting any formal legal proceedings, we would like the
            chance to resolve your issue ourselves. You agree that before filing
            a claim against Pylon, you will contact us, by email at{" "}
          </span>
          <span className="c14">support@hellopylon.com</span>
          <span className="c0">
            &nbsp;Upon receiving notice of your issue, we will attempt to
            resolve the issue informally. If Pylon is unable to resolve the
            problem within thirty days, you or Pylon may bring a formal legal
            proceeding.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={2}>
        <li className="c5">
          <span className="c0">
            If we cannot resolve your issue as described above, you agree to
            resolve any claims relating to this Agreement through final and
            binding arbitration by a single arbitrator, except as set forth
            below. This includes disputes arising out of or relating to
            interpretation or application of these arbitration-related
            provisions, including their enforceability, revocability, or
            validity.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={3}>
        <li className="c5">
          <span className="c0">
            You acknowledge that arbitration is a substitute for litigation, and
            that you agree to present any dispute to the arbitrator and not a
            governmental agency, body, or court.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={4}>
        <li className="c5">
          <span className="c15">
            You can decline this agreement to arbitrate by emailing us at{" "}
          </span>
          <span className="c14">support@hellopylon.com</span>
          <span className="c0">
            &nbsp;within thirty days of first registering your account. However,
            if you agreed to a previous version of this Agreement that allowed
            you to opt out of arbitration, your previous choice to opt out or
            not opt out remains binding.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={5}>
        <li className="c5">
          <span className="c0">
            The American Arbitration Association (&ldquo;AAA&rdquo;) will
            administer the arbitration under its Commercial Arbitration Rules
            and the Supplementary Procedures for Consumer Related Disputes. The
            arbitration will be held in the United States county where you live
            or work; Montgomery County, Maryland; or any other location we agree
            to. The AAA rules will govern payment of all arbitration fees.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={6}>
        <li className="c5" id="h.gjdgxs">
          <span className="c0">
            You may only resolve disputes with us on an individual basis, and
            may not bring a claim as a plaintiff or a class member in a class,
            consolidated, or representative action. Class arbitrations, class
            actions, private attorney general actions, and consolidation with
            other arbitrations are not allowed. If this specific paragraph is
            held unenforceable, then the entirety of this Section VIII will be
            deemed void.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={9}>
        <li className="c8 c10 li-bullet-0">
          <span className="c11">MISCELLANEOUS</span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_1-0 start" start={1}>
        <li className="c5">
          <span className="c0">
            This Agreement is governed by Delaware law except for its conflicts
            of laws principles. You and Pylon agree that any judicial proceeding
            to resolve claims relating to this Agreement or the Services will be
            brought in the federal or state courts in the State of Maryland,
            subject to the mandatory arbitration provisions above. Both you and
            Pylon consent to venue and personal jurisdiction in such courts.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_1-0" start={2}>
        <li className="c5">
          <span className="c0">
            This Agreement constitutes the entire agreement between you and
            Pylon with respect to the subject matter of this Agreement and
            supersede and replace any other prior or contemporaneous agreements,
            or other terms and conditions applicable to the subject matter of
            this Agreement. These Agreement creates no third-party beneficiary
            rights. You acknowledge that any adjustment or amendment to this
            Agreement must be in writing, signed by you and an authorized
            employee of Pylon.
          </span>
        </li>
      </ol>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_1-0" start={3}>
        <li className="c5">
          <span className="c0">
            Pylon&rsquo;s failure to enforce a provision is not a waiver of its
            right to do so later. Our rights under this Agreement survive any
            transfer or termination of this Agreement.
          </span>
        </li>
      </ol>
      <p className="c8 c7">
        <span className="c0">&nbsp;</span>
      </p>
      <ol className="c6 lst-kix_list_1-0" start={4}>
        <li className="c5">
          <span className="c0">
            You may not assign any of your rights under this Agreement, and any
            such attempt will be void. Pylon may assign its rights to any of its
            affiliates or subsidiaries, or to any successor in interest of any
            business associated with the Services.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_1-0" start={5}>
        <li className="c5">
          <span className="c0">
            If any part of this Agreement is held or found to be invalid or
            unenforceable, that portion of the Agreement will be construed to be
            consistent with applicable law while the remaining portions of the
            Agreement remain in full force and effect.{" "}
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-0" start={10}>
        <li className="c8 c10 li-bullet-0">
          <span className="c9">BETA PERIOD TERMS</span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-1 start" start={1}>
        <li className="c5">
          <span className="c0">
            Pylon may terminate the beta period at any time in its sole
            discretion, with or without notice. With respect to the Services
            provided during the beta period, this Section X supersedes any other
            terms and conditions contained in this Agreement, but only to the
            extent necessary to resolve conflict.
          </span>
        </li>
      </ol>
      <p className="c1 c7">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-1" start={2}>
        <li className="c5">
          <span className="c0">
            By using the Services, you understand and acknowledge that Pylon is
            providing the Services as a &ldquo;beta&rdquo; version and making
            them available on an &ldquo;as is&rdquo; or &ldquo;as
            available&rdquo; basis. The Services may contain bugs, errors, and
            other problems. Pylon strongly recommends that you back up all data
            and information prior to using the Services. YOU ASSUME ALL RISKS
            AND ALL COSTS ASSOCIATED WITH YOUR USE OF THE SERVICES, INCLUDING
            WITHOUT LIMITATION ANY INTERNET ACCESS FEES, BACKUP EXPENSES, COSTS
            INCURRED FOR THE USE OF YOUR DEVICES AND PERIPHERALS, AND ANY DAMAGE
            TO ANY EQUIPMENT, SOFTWARE, INFORMATION, OR DATA. Pylon is not
            obligated to provide any support for the Services.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-1" start={3}>
        <li className="c5">
          <span className="c0">
            Pylon is making the Services available to you for evaluation and
            feedback purposes without any compensation or reimbursement of any
            kind from Pylon. You acknowledge the importance of communication
            between Pylon and you during your use of the Services and agree to
            receive related communications and updates from Pylon. During the
            beta period, Pylon may ask you for feedback relating to your use of
            the Services. You agree to grant to Pylon a perpetual, nonrevocable,
            royalty-free, worldwide license to use or incorporate feedback into
            any Pylon product or service, at our sole discretion.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-1" start={4}>
        <li className="c5">
          <span className="c0">
            Pylon expressly represents that the Services are not a final product
            and, as such, may contain errors or defects and may be unstable.
            Pylon recommends that you only access the Services if you are an
            experienced user of similar computer products and services.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c6 lst-kix_list_8-1" start={5}>
        <li className="c5">
          <span className="c0">
            The Services and their features and functionality are considered
            confidential information of Pylon. You agree to keep all such
            information confidential and not disclose it to any person except as
            permitted under this Section X(5). This confidentiality obligation
            does not apply to the disclosure of confidential information that
            (i) is or comes into the public domain, except through breach of
            confidentiality obligations under this clause; (ii) comes lawfully
            into your possession from a third party not bound by a
            confidentiality obligation; (iii) is required to be disclosed by any
            court of competent jurisdiction or any competent judicial,
            governmental, supervisory, or regulatory body, or any law; or (iv)
            you disclose with Pylon&rsquo;s consent. Notwithstanding the
            foregoing, the confidentiality obligations in this Section X(5) do
            not restrict you from recommending that others use the Services or
            otherwise make people aware of the Services.
          </span>
        </li>
      </ol>
    </div>
  );
};
