import * as React from "react";
import { Account, Colour } from "core/models";
import { ChartAccountBalanceHistory } from "components/features/dashboard/components/chart-account-balance-history";
import { ChartAccountGrowth } from "components/features/dashboard/components/chart-account-growth";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

interface Props {
  account: Account;
  isConnected: boolean;
}

export const ChartAccountCombo: React.FC<Props> = ({
  account,
  isConnected,
}) => {
  const [showHistory, setShowHistory] = React.useState<boolean>(false);
  const [growthYears, setGrowthYears] = React.useState(5);
  const mobileLayout = useMobileLayout();
  return (
    <>
      <div className="account-combo">
        <div className={mobileLayout ? "vertical" : "flex-row align-center"}>
          <LeafButton
            id="account-chart-combo--history"
            buttonStyle={LeafButtonStyle.LeafSmallDense}
            buttonColour={LeafButtonColour.Transparent}
            onClick={() => {
              setShowHistory(true);
            }}
          >
            <div
              className={"pick-chart"}
              style={{
                color: showHistory ? Colour.Black : Colour.Blue01,
              }}
            >
              Balance History
            </div>
          </LeafButton>
          <div
            className="no-mobile"
            style={{
              margin: ".25rem",
              display: "inline-block",
              fontSize: ".625rem",
            }}
          >
            |
          </div>
          <LeafButton
            id="account-chart-combo--growth"
            buttonStyle={LeafButtonStyle.LeafSmallDense}
            buttonColour={LeafButtonColour.Transparent}
            onClick={() => {
              setShowHistory(false);
            }}
          >
            <div
              className={"pick-chart"}
              style={{
                color: showHistory ? Colour.Blue01 : Colour.Black,
              }}
            >
              Growth Chart{" "}
            </div>
          </LeafButton>
        </div>
        <div>
          {showHistory ? (
            <ChartAccountBalanceHistory
              account={account}
              chartType={
                new Date().getTime() -
                  new Date(
                    parseInt(account?.created_at || "") * 1000
                  ).getTime() >
                864000000
                  ? "area"
                  : "bar"
              }
            />
          ) : (
            <ChartAccountGrowth
              account={account}
              isConnected={isConnected}
              initYears={growthYears}
              onUpdateYears={(y) => setGrowthYears(y)}
            />
          )}
        </div>
      </div>
    </>
  );
};
