import CSS from "csstype";
import React, { CSSProperties } from "react";
import { Colour } from "core/models";
import "./styles.css";

export enum TextType {
  Default = "p",
  P = "p",
  Div = "div", // Useful as high level wrapper
  Span = "span", // Useful for multiple types in same line
}

export enum TextStyle {
  Default = "pylon-text",
  TitlePoppins = "pylon-text-title-Montserrat",
  Poppins = "pylon-text-Montserrat",
  Highlight = "pylon-text-highlight",
  Hint = "pylon-text-hint",
  FieldLabel = "pylon-text-field-label",
  Error = "pylon-text-error",
  B12 = "pylon-text-B-12",
  B14 = "pylon-text-B-14",
  B16 = "pylon-text-B-16",
  B18 = "pylon-text-B-18",
  L12 = "pylon-text-L-12",
  L14 = "pylon-text-L-14",
  O10 = "pylon-text-O-10",

  M11 = "pylon-text-M-11",
  M15 = "pylon-text-M-15",
  M15SM = "pylon-text-M-15-semi-bold",
  M17SB = "pylon-text-M-17-semi-bold",
  M18 = "pylon-text-M-18",
  M31B = "pylon-text-M-31-B",
  M35R = "pylon-text-M-35-R",
  M64 = "pylon-text-M-64",

  S20 = "pylon-text-S-20",

  Sharp7Regular = "pylon-text-sharp-7-regular",
  Sharp9Regular = "pylon-text-sharp-9-regular",
  Sharp14Regular = "pylon-text-sharp-14-regular",
  Sharp17Regular = "pylon-text-sharp-17-regular",
  Sharp9Bold = "pylon-text-sharp-9-bold",
  Sharp17Bold = "pylon-text-sharp-17-bold",
  Sharp24Bold = "pylon-text-sharp-24-bold",
  Sharp35Bold = "pylon-text-sharp-35-bold",
  Sharp56Bold = "pylon-text-sharp-56-bold",

  SGM25 = "font-sg-m25",
  SGB20 = "font-sgb-20",
  SGSMB20 = "font-sg-smb20",
}

export type TextWeight = (number & {}) | "bold";

export interface TextProps {
  textAlign?: CSS.Property.TextAlign;
  style?: TextStyle;
  type?: TextType;
  weight?: TextWeight;
  colour?: Colour;
  size?: CSS.Property.FontSize;
  lineHeight?: string;
  format?: TextFormat;
  ellipsis?: boolean;
  whiteSpace?: CSS.Property.WhiteSpace;
}

export enum TextFormat {
  None = "none",
  Capitalize = "capitalize",
  UpperCase = "uppercase",
  LowerCase = "lowercase",
}

export const TextD: React.FC<TextProps> = (props) => {
  return <Text type={TextType.Div} {...props} />;
};

export const Text: React.FC<TextProps> = ({
  style,
  type,
  weight,
  size,
  colour,
  format,
  lineHeight,
  textAlign,
  ellipsis,
  whiteSpace,
  children,
}) => {
  let className: string;
  if (style) {
    className = style;
  } else {
    className = TextType.Default;
  }

  if (ellipsis) {
    className = `${className} ellipsis`;
  }

  const cssStyle: CSSProperties = {};
  if (weight) {
    cssStyle.fontWeight = weight;
  }

  if (colour) {
    cssStyle.color = colour;
  }

  if (size) {
    cssStyle.fontSize = size;
  }
  if (format) {
    cssStyle.textTransform = format;
  }

  if (textAlign) {
    cssStyle.textAlign = textAlign;
  }

  if (lineHeight) {
    cssStyle.lineHeight = lineHeight;
  }

  if (whiteSpace) {
    cssStyle.whiteSpace = whiteSpace;
  }

  switch (type) {
    case TextType.Div:
      return (
        <div className={className} style={cssStyle}>
          {children}
        </div>
      );
    case TextType.Span:
      return (
        <span className={className} style={cssStyle}>
          {children}
        </span>
      );
    default:
      return (
        <p className={className} style={cssStyle}>
          {children}
        </p>
      );
  }
};
