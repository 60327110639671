import { AccountType } from "components/features/dashboard/components/add-account-tray/models";
import { SimpleDropDown } from "components/core/simple-drop-down";

export interface Props {
  accountType: AccountType;
  onSelect: (newType?: string) => void;
}

export const AccountTypeOptions: React.FC<Props> = ({
  accountType,
  onSelect,
}) => {
  const subtypeOptions = Object.values(AccountType);

  return (
    <div>
      <SimpleDropDown
        options={subtypeOptions}
        selectedValue={accountType}
        onSelect={onSelect}
        menuMaxHeight="16rem"
      />
    </div>
  );
};
