import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { AccountAddMenu } from "components/features/dashboard/components/account-category-section/components/account-add-menu";
import { AccountType } from "components/features/dashboard/components/add-account-tray/models";
import { AddAccountDetailsEntry } from "components/features/dashboard/components/add-account-tray/details-entry/account-details-entry";
import { AddAccountHeader } from "components/features/dashboard/components/add-account-tray/add-account-header";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { Colour } from "core/models";
import { Dropdown } from "react-bootstrap";
import { GetCategoryGroupsV2 } from "core/utils/account";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { Text, TextType } from "components/core/text";
import "./styles.css";

interface Props {
  category: string;
  connectable: boolean;
  openMenuUpwards?: boolean;
}

export const AccountAddRow: React.FC<Props> = ({
  category,
  connectable,
  openMenuUpwards,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  // const [showPlaid, setShowPlaid] = React.useState<boolean>(false);
  // const [showCoinbase, setShowCoinbase] = React.useState(false);
  const { optionGroupsMap } = GetCategoryGroupsV2();

  const { pushTray } = React.useContext(SideTrayContext);
  const {
    clear,
    setAccountType,
    setAccountSubType,
    setShowPlaid,
    setShowCoinbase,
  } = React.useContext(AddAccountsContext);

  const opts = optionGroupsMap.get(category) || [];
  return (
    <div ref={wrapperRef}>
      <Dropdown className="asset-note__menu" bsPrefix="pylon">
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          <div
            className="table-row selectable draggable"
            style={{
              cursor: "pointer",
            }}
          >
            <Text
              type={TextType.Div}
              colour={Colour.Blue01}
              size={"15px"}
              lineHeight="24px"
            >
              + Add
            </Text>
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="asset-note__menu-item-wrapper"
          style={{ boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)" }}
        >
          <AccountAddMenu
            connectable={connectable}
            subtypes={opts}
            openMenuUpwards={openMenuUpwards}
            showCryptoConnect={category === AccountType.NonRetirement}
            onSelect={(t) => {
              if (t === "connect") {
                setShowPlaid(true);
              } else if (t === "crypto") {
                setShowCoinbase(true);
              } else {
                setAccountType(category as AccountType);
                setAccountSubType(t);
                pushTray({
                  onClose: () => {
                    clear();
                  },
                  children: <AddAccountDetailsEntry />,
                  header: <AddAccountHeader />,
                });
              }
              wrapperRef?.current?.click();
            }}
            onClose={() => {}}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
