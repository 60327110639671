import _ from "lodash";
import { Colour } from "core/models";
import { DEFAULT_CATEGORY_LIST } from "components/features/dashboard/components/transactions/utils";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { MutationWrapper } from "core/queries/mutation";
import { SimpleDropDown } from "components/core/simple-drop-down";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  CreateTransactionCategory,
  CreateTransactionRuleInput,
  CreateTransactionRuleResponse,
  CustomTransactionCategoryResponse,
  GetCustomTransactionCategoryQuery,
  GetTransactionMerchants,
  GetTransactionRulesQuery,
  TransactionMerchantsResponse,
} from "core/queries/transaction-category";

export interface Props {
  onChange: () => void;
}

export const AddTransactionRuleTray: React.FC<Props> = ({ onChange }) => {
  const { data: merchantData } = useQuery<TransactionMerchantsResponse>(
    GetTransactionMerchants,
    {
      fetchPolicy: "cache-first",
    }
  );
  const { data: categoryData } = useQuery<CustomTransactionCategoryResponse>(
    GetCustomTransactionCategoryQuery,
    {
      fetchPolicy: "cache-first",
    }
  );

  const [createRule, { loading }] = useMutation<
    CreateTransactionRuleResponse,
    MutationWrapper<CreateTransactionRuleInput>
  >(CreateTransactionCategory, {
    onCompleted: (data) => {
      if (data.createCustomTransactionCategories) {
        onChange();
      }
    },
    refetchQueries: [
      {
        query: GetCustomTransactionCategoryQuery,
      },

      {
        query: GetTransactionRulesQuery,
      },
    ],
  });

  const [category, setCategory] = useState("");
  const [merchants, setMerchants] = useState<string[]>([]);

  const categories = (
    categoryData?.customTransactionCategories?.map((c) => c.category) ?? []
  ).concat(DEFAULT_CATEGORY_LIST);
  const allMerchants = merchantData?.transactionMerchants ?? [];

  return (
    <div>
      <div
        className="flex-column"
        style={{
          gap: "1rem",
        }}
      >
        <Text style={TextStyle.FieldLabel} type={TextType.Div}>
          Select a Category
        </Text>
        <div
          style={{
            flex: "1 1",
          }}
        >
          <SimpleDropDown
            options={categories}
            selectedValue={category}
            onSelect={(v) => {
              if (v) {
                setCategory(v);
              }
            }}
          />
        </div>
      </div>

      {category && (
        <>
          <div
            className="flex-column mt1"
            style={{
              gap: "1rem",
            }}
          >
            <Text style={TextStyle.FieldLabel} type={TextType.Div}>
              Add a Merchant
            </Text>
            <div
              style={{
                flex: "1 1",
              }}
            >
              <SimpleDropDown
                options={allMerchants.filter((x) => !merchants.includes(x))}
                selectedValue={undefined}
                onSelect={(v) => {
                  if (v) {
                    setMerchants([...merchants, v]);
                  }
                }}
              />
            </div>
          </div>
          {merchants.map((m) => (
            <div key={m} className="flex-row align-center between mt1">
              <Text
                style={TextStyle.FieldLabel}
                type={TextType.Div}
                colour={Colour.Text03}
              >
                When merchant is "{m}", set category to "{category}"
              </Text>

              <div
                style={{
                  flex: "0 0",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setMerchants(merchants.filter((merchant) => merchant !== m));
                }}
              >
                <Icon asset={IconAsset.Trashcan} width="24px" height="24px" />
              </div>
            </div>
          ))}
        </>
      )}

      <div className="mt1" />

      <div className="flex-column">
        <LeafButton
          loading={loading}
          disabled={_.isEmpty(category) || merchants.length === 0}
          onClick={() => {
            createRule({
              variables: {
                input: {
                  category: category,
                  merchants: merchants,
                },
              },
            });
          }}
        >
          {"Create"}
        </LeafButton>
      </div>
    </div>
  );
};
