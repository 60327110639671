import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import {
  AccountSubType,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";
import { CryptoConnectWrapper } from "components/features/dashboard/components/crypto-connect-wrapper";
import { PlaidWrapper } from "components/features/dashboard/components/plaid-wrapper";
import { SavvyWrapper } from "components/features/dashboard/components/savvy-wrapper";
import * as React from "react";
import { TrayDivider } from "../../../../../core/tray-divider";

export const OrConnect: React.FC = () => {
  const { accountSubType, accountIDToConvert } =
    React.useContext(AddAccountsContext);
  const [showPlaid, setShowPlaid] = React.useState(false);
  const [showSavvy, setShowSavvy] = React.useState(false);
  const [showCoinbase, setShowCoinbase] = React.useState(false);
  return (
    <div>
      <div className="add-account-tray__step-action">
        <h5 style={{ marginBottom: ".5rem" }}>
          Know your login<span style={{ color: "var(--blue-01)" }}>?</span>{" "}
        </h5>
        <div
          style={{
            fontWeight: "normal",
            fontSize: "1rem",
            marginBottom: "2rem",
          }}
        >
          Connect to keep your account automatically updated
          <span style={{ color: "var(--blue-01)" }}>.</span>
        </div>
        <LeafButton
          onClick={() => {
            switch (accountSubType) {
              case AccountSubType[AccountType.Insurance]["Car Insurance"]:
                setShowSavvy(true);
                break;
              case AccountSubType[AccountType.NonRetirement]["Cryptocurrency"]: // Fallthrough
              case AccountSubType[AccountType.DigitalWallet]["Cryptocurrency"]:
                setShowCoinbase(true);
                break;
              default:
                setShowPlaid(true);
            }
          }}
          buttonColour={LeafButtonColour.Black}
          buttonStyle={LeafButtonStyle.LeafSmall}
        >
          Click to Connect
        </LeafButton>
      </div>
      {showPlaid && <PlaidWrapper onClose={() => setShowPlaid(false)} />}
      {showSavvy && (
        <SavvyWrapper
          onClose={() => setShowSavvy(false)}
          accountIDToConvert={accountIDToConvert}
        />
      )}
      {showCoinbase && (
        <CryptoConnectWrapper
          showingModal={showCoinbase}
          onClose={() => setShowCoinbase(false)}
        />
      )}
      <TrayDivider />
    </div>
  );
};
