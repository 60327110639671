import React, { useContext } from "react";
import { accountsQuery, AccountsResponse } from "core/queries/accounts";
import { SessionContext } from "components/contexts/session-context-provider";
import { ShareAccountList } from "components/features/dashboard/components/account-share-list";
import { Spinner } from "react-bootstrap";
import { TrustedSourceContext } from "components/contexts/trusted-source-context";
import { useQuery } from "@apollo/client";

export interface Props {}

export const ShareAccounts: React.FC<Props> = () => {
  const { userID } = useContext(SessionContext.context);

  const { state, updateState } = useContext(TrustedSourceContext);

  const { data: accountData, loading: accountLoading } =
    useQuery<AccountsResponse>(accountsQuery, {
      fetchPolicy: "cache-and-network",
    });

  const disabled = false;

  const myAccounts =
    accountData?.accounts?.filter((acc) => acc.owner?.id === userID) || [];

  const isAccShared = (accID: string) => {
    return state.sharedAccountIDs.includes(accID);
  };

  const onAccRemove = (accID: string) => {
    updateState({
      ...state,
      sharedAccountIDs: state.sharedAccountIDs.filter((x) => x !== accID),
    });
  };

  const onAccAdd = (accID: string) => {
    if (!isAccShared(accID)) {
      updateState({
        ...state,
        sharedAccountIDs: state.sharedAccountIDs.concat(accID),
      });
    }
  };

  const unshareAll = () => {
    updateState({
      ...state,
      sharedAccountIDs: [],
    });
  };

  const shareAll = () => {
    updateState({
      ...state,
      sharedAccountIDs: myAccounts.map((acc) => acc.account_id),
    });
  };

  const body =
    !accountData && accountLoading ? (
      <div
        className="flex-row"
        style={{
          gap: "1rem",
        }}
      >
        <div>Loading your accounts...</div>
        <Spinner animation="border" size="sm" />
      </div>
    ) : (
      <ShareAccountList
        accounts={myAccounts}
        disabled={disabled}
        isAccShared={isAccShared}
        onAccAdd={onAccAdd}
        onAccRemove={onAccRemove}
        shareAll={shareAll}
        unshareAll={unshareAll}
      />
    );

  return (
    <div className="mt2">
      {body}
      <div className="mt3"></div>
    </div>
  );
};
