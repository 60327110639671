import * as React from "react";
import { AuthorizedPage } from "components/features/login/components/authorized-page";
import { DashboardComponent } from "components/features/dashboard/component";

interface PublicProps {}

type Props = PublicProps;

export const Dashboard: React.FC<Props> = (props: Props) => {
  return (
    <AuthorizedPage>
      {/* <OnboardingGate> */}
      <DashboardComponent />
      {/* </OnboardingGate> */}
    </AuthorizedPage>
  );
};
