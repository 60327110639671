import _ from "lodash";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { GET_TAX_LIABILITY, TaxLiabilityInterface } from "core/queries/taxes";
import { MutationWrapper } from "core/queries/mutation";
import { PlanContext } from "components/contexts/plan-context-provider";
import { PylonALink } from "components/core/alink";
import { PylonCurrencyInput } from "components/core/currency-input";
import { TaxCalculator } from "@pylon/taxes";
import { Text, TextStyle, TextType } from "components/core/text";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  currentTaxYear,
  toTaxCalculationInput,
} from "components/features/dashboard/components/planning/taxes";
import {
  FetchScenarioPlanSettingResponse,
  FETCH_SCENARIO_PLAN_SETTING,
  SaveScenarioPlanSettingMutationInput,
  SAVE_SCENARIO_PLAN_SETTING_MUTATION,
  ScenarioPlanSetting,
} from "core/queries/scenario-plan-settings";

export const ModifyIncomeAndExpenseTray: React.FC = () => {
  const {
    householdIncome,
    taxes,
    monthlyExpense,
    setTaxes,
    setHouseholdIncome,
    setMonthlyExpense,
  } = useContext(PlanContext);

  const { data: liabilityData } = useQuery<{
    taxLiability?: TaxLiabilityInterface;
  }>(GET_TAX_LIABILITY, {
    fetchPolicy: "cache-first",
    variables: {
      taxYear: currentTaxYear,
    },
  });

  const { data: settingData, refetch } =
    useQuery<FetchScenarioPlanSettingResponse>(FETCH_SCENARIO_PLAN_SETTING, {
      fetchPolicy: "cache-first",
    });

  const currentSettings = settingData?.scenarioPlanSetting;
  const [hasUpdate, setHasUpdate] = useState(false);

  const [saveSettings, { loading: saveLoading }] = useMutation<
    {
      saveScenarioPlanSetting: ScenarioPlanSetting;
    },
    MutationWrapper<SaveScenarioPlanSettingMutationInput>
  >(SAVE_SCENARIO_PLAN_SETTING_MUTATION, {
    variables: {
      input: {
        householdIncome: householdIncome,
        taxes: taxes,
        monthlyExpenses: monthlyExpense,
      },
    },
    onCompleted: () => {
      refetch();
    },
  });

  const onBlurSave = () => {
    if (
      householdIncome !== currentSettings?.householdIncome ||
      taxes !== currentSettings?.taxes ||
      monthlyExpense !== currentSettings?.monthlyExpenses
    ) {
      if (!hasUpdate) {
        setHasUpdate(true);
      }

      saveSettings();
    }
  };

  let statusIndicator = null;
  if (hasUpdate) {
    if (saveLoading) {
      statusIndicator = (
        <div className="account-details__manage-save-update">
          <Text type={TextType.Div} style={TextStyle.Hint}>
            Saving...
          </Text>
        </div>
      );
    } else {
      statusIndicator = (
        <div className="account-details__manage-save-update account-details__manage-save-saved">
          <Text type={TextType.Div} style={TextStyle.Hint}>
            Saved...
          </Text>
        </div>
      );
    }
  }

  return (
    <div>
      {statusIndicator}
      <form autoComplete="off" onSubmit={() => {}}>
        <div className="add-account-tray__step-selection">
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Annual Household Income
          </Text>
          <PylonCurrencyInput
            id="plan-household-income"
            placeholder="$150,000"
            prefix="$"
            className="account-details__input"
            onValueChange={(v) => {
              if (!_.isNil(v)) {
                setHouseholdIncome(v);

                const calc = new TaxCalculator(
                  toTaxCalculationInput({
                    ...liabilityData,
                    taxYear: currentTaxYear,
                    earnedIncome: v,
                  })
                );

                const outcome = calc.getTaxOutcome();
                setTaxes((outcome.federalOwed + outcome.stateOwed).toFixed(0));
              }
            }}
            onBlur={onBlurSave}
            allowNegativeValue={false}
            value={householdIncome}
            decimalScale={0}
          />
          <Text style={TextStyle.Hint} type={TextType.Div}>
            To get a more comprehensive view of your income and taxes, please
            visit the{" "}
            <PylonALink
              href={DashboardPages.IncomeAndTaxes}
              colour={Colour.Blue01}
            >
              Income & Taxes
            </PylonALink>{" "}
            page.
          </Text>
        </div>

        <div className="add-account-tray__step-selection">
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Expected Taxes (Federal & State)
          </Text>
          <PylonCurrencyInput
            id="plan-taxes"
            placeholder="$30,000"
            className="account-details__input"
            prefix="$"
            onValueChange={(v) => {
              if (!_.isNil(v)) {
                setTaxes(v);
              }
            }}
            value={taxes}
            allowNegativeValue={false}
            decimalScale={0}
            onBlur={onBlurSave}
          />
        </div>

        <div className="add-account-tray__step-selection">
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Monthly Expenses
          </Text>
          <PylonCurrencyInput
            id="plan-monthly-expense"
            placeholder="$20,000"
            className="account-details__input"
            prefix="$"
            onValueChange={(v) => {
              if (!_.isNil(v)) {
                setMonthlyExpense(v);
              }
            }}
            value={monthlyExpense}
            allowNegativeValue={false}
            decimalScale={0}
            onBlur={onBlurSave}
          />
        </div>
      </form>
    </div>
  );
};
