import { TextD, TextStyle } from "components/core/text";

export interface Props {
  format?: string;
}

export const DefaultPreviewIcon: React.FC<Props> = ({ format }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <TextD style={TextStyle.M17SB}>{format ? format : "Unknown"}</TextD>
    </div>
  );
};
