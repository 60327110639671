import React from "react";
import { AccountDetails } from "core/models";
import { PylonCurrencyInput } from "components/core/currency-input";
import {
  sanitizeStringNumber,
  sanitizeStringNumberToString,
} from "components/features/dashboard/components/add-account-tray/details-entry/utils";

export interface Props {
  balance?: string;
  onBalanceUpdate: (balance: string) => void;

  details?: AccountDetails;
  onDetailsUpdate: (details: AccountDetails) => void;

  onEnter?: () => void;
}

export const AddHomeConstructionDetailsEntry: React.FC<Props> = ({
  balance,
  onBalanceUpdate,
  details,
  onDetailsUpdate,
  onEnter,
}) => {
  const homeConstructionDetails = details?.homeConstructionDetails || {};

  return (
    <>
      <div>
        <div>Current Balance (Estimates okay)</div>
        <PylonCurrencyInput
          id="validation-example-2-field"
          placeholder="$100,000.00"
          onValueChange={(v) => {
            onBalanceUpdate(sanitizeStringNumber(v));
          }}
          value={balance || ""}
          prefix={"$"}
          step={1}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>

      <div>
        <div>Total Amount Borrowed</div>

        <PylonCurrencyInput
          id="apy-input"
          placeholder="$80,000.00"
          onValueChange={(v) => {
            onDetailsUpdate({
              homeConstructionDetails: {
                ...homeConstructionDetails,
                amountBorrowed: sanitizeStringNumberToString(v),
              },
            });
          }}
          value={homeConstructionDetails.amountBorrowed}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
    </>
  );
};
