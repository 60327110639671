import * as React from "react";
import Chart from "react-apexcharts";
import { Account } from "core/models";
import { addYears } from "date-fns";
import { ApexOptions } from "apexcharts";
import { EditAccountInformationModal } from "components/features/dashboard/components/manage-account-modal/details/edit-details-modal";
import { Input } from "components/core/input";
import { useMobileLayout } from "core/hooks/responsive";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  FETCH_ACCOUNT_BALANCE_HISTORY,
  GetBalanceHistoryResponse,
} from "../../../../../core/queries/accounts";
import {
  pluralize,
  toMoneyString,
  toMoneyStringNoDecimal,
} from "../../../../../core/utils";

interface Props {
  account: Account;
  isConnected: boolean;
  initYears?: number;
  onUpdateYears?: (y: number) => void;
}

export const ChartAccountGrowth: React.FC<Props> = ({
  account,
  isConnected,
  initYears,
  onUpdateYears,
}) => {
  const mobileLayout = useMobileLayout();
  const { data } = useQuery<GetBalanceHistoryResponse>(
    FETCH_ACCOUNT_BALANCE_HISTORY,
    {
      fetchPolicy: "cache-first",
      variables: {
        accountID: account?.account_id,
      },
      skip: !account,
    }
  );

  function growthValue(
    previousValue: number,
    interestRate: number,
    years: number
  ) {
    var x = 1 + interestRate / 100;
    var value = previousValue * Math.pow(x, years);

    return value;
  }

  const [showEditModal, setShowEditModal] = React.useState(false);
  const [growthCount, setGrowthCount] = React.useState(0);
  const [years, setYears] = React.useState(initYears || 5);
  const [valueInYears, setValueInYears] = React.useState(0);
  const [growthFromToday, setGrowthFromToday] = React.useState(0);

  const apr = account.details?.savingsDetails?.apy
    ? Number(account.details?.savingsDetails?.apy)
    : 0;

  const dataset = React.useMemo<(number | Date)[][]>(() => {
    if (!data || !data.balanceHistory) return [];
    let history = data.balanceHistory.history
      .map((h) => {
        return [new Date(h.date), h.balance];
      })
      .sort((a: any[], b: any[]) => {
        return a[0].getTime() - b[0].getTime();
      });

    let [startDate, pv] = history[history.length - 1] as [Date, number];
    let growth: (number | Date)[][] = [];

    for (let i = 0; i < years; i++) {
      let forDate = addYears(startDate, i + 1);
      let d = growthValue(pv, apr, i + 1);

      growth[i] = [forDate, d];
    }

    let gl = growth.length;
    let growthEndValue = growth[gl - 1][1] as number;
    setGrowthCount(gl);
    setValueInYears(growthEndValue);
    setGrowthFromToday(growthEndValue - pv);

    return history.concat(growth);
  }, [data, years, apr]);

  const state = React.useMemo<{
    series: Array<any>;
    options: ApexOptions;
  }>(() => {
    return {
      series: [
        {
          name: "Growth",
          data: dataset,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
            enabled: false,
          },
        },
        forecastDataPoints: {
          count: growthCount,
          dashArray: 5,
        },
        dataLabels: {
          enabled: false,
          formatter: function (val: number) {
            return toMoneyStringNoDecimal(val);
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        stroke: {
          width: 5,
          curve: "straight",
        },
        xaxis: {
          tickAmount: 4,
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "",
              minute: "hh:mm tt",
            },
          },
          type: "datetime" as "datetime",
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val: number) {
              return toMoneyStringNoDecimal(val);
            },
          },
        },
      },
    };
  }, [dataset, growthCount]);

  return (
    <div className="growth-chart">
      <EditAccountInformationModal
        show={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        account={account}
        isConnected={isConnected}
      />
      <div
        className={
          mobileLayout ? "growth-chart-header-mobile" : "growth-chart-header"
        }
      >
        <div className="set-years">
          <div className="label-small text-03 lhs">Duration</div>
          <div>
            <Input
              wrapperClassName="input-years"
              defaultValue={years}
              onBlurValidate={[
                {
                  inputRegex: /\d$/,
                  errorMessage: "Please enter a valid number",
                },
              ]}
              onChange={(v) => {
                if (v.target.value) {
                  let n = parseInt(v.target.value);
                  if (n > 0 && n < 100) {
                    setYears(n);
                    onUpdateYears && onUpdateYears(n);
                  }
                }
              }}
            />
          </div>
          <div className="label-small text-03 rhs">Years</div>
        </div>
      </div>

      <div
        className="chart balance-history"
        style={{ display: "flex", width: "100%" }}
      >
        <Chart
          options={state.options}
          series={state.series}
          type="line"
          height="200"
          width="100%"
        />
      </div>

      <div className={"summary-box between " + (mobileLayout ? "mobile" : "")}>
        <div className="">
          <div>
            <div className="body-small label-large">
              Growth from today based on these values.
            </div>
          </div>
          <div className="growth-row">
            <div className="growth-value">
              <span
                className={growthFromToday >= 0 ? "green-01" : "red-01"}
                style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
              >
                {toMoneyString(growthFromToday)}
                {growthFromToday > 0 && <>&uarr;</>}
                {growthFromToday < 0 && <>&darr;</>}
              </span>
            </div>

            <div className="growth-row">
              <div className="interest-rate">{apr}%</div>
              <div className="section-label label-small text-03">interest</div>
              <div className="edit-apr">
                <div
                  className="button-as-link label-small text-03 blue-01"
                  onClick={() => {
                    setShowEditModal(true);
                  }}
                >
                  edit
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"source-type " + (mobileLayout ? "mobile" : "")}>
          <div className="value-in-year">
            Value in {years} {pluralize("year", years)}
          </div>
          <div className="value-in-amount">{toMoneyString(valueInYears)}</div>
        </div>
      </div>
    </div>
  );
};
