import * as React from "react";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { blurOnEnter } from "core/utils/form-helpers";
import { CapGainsCalculator } from "components/features/dashboard/pages/income-and-taxes/components/cap-gains-calculator";
import { setValue } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/util";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { TaxContext } from "components/contexts/tax-context";
import { TaxPageSection } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section";
import { toMoneyString } from "core/utils";

interface Props {
  fetchAccountsTrigger: number;
}
export const TaxSectionInvestmentIncome: React.FC<Props> = ({
  fetchAccountsTrigger,
}) => {
  const { pushTray } = React.useContext(SideTrayContext);
  const { setAccountType, setAccountSubType, clear } =
    React.useContext(AddAccountsContext);
  const {
    longTermInvestmentIncome,
    shortTermInvestmentIncome,
    showAdvancedCG,
    saveLiabilityFieldValue,
    setInvestmentSTCG,
    setLTCG,
    setSTCG,
    stcg,
    investmentSTCG,
    ltcg,
    accountsBySubtypeResponse,
    refetchAccountsBySubtypeResponse,
  } = React.useContext(TaxContext);

  return (
    <TaxPageSection
      localStorageKey="taxes.investmentincome"
      headerLeft="Investment Income"
      headerRight={
        <>
          <div className="table-cell long-term-amount no-mobile">
            {toMoneyString(longTermInvestmentIncome || 0)} (Long term)
          </div>
          <div className="table-cell short-term-amount">
            {toMoneyString(shortTermInvestmentIncome || 0)}
          </div>
        </>
      }
      bodyLeft={
        <>
          <div>
            Provide the amounts for any investment gains or losses.
            <br />
          </div>
        </>
      }
      bodyRight={
        <>
          <CapGainsCalculator
            advancedCG={false}
            longTermInvestmentIncome={longTermInvestmentIncome}
            shortTermInvestmentIncome={shortTermInvestmentIncome}
            handleKeyDown={blurOnEnter}
            saveLiabilityFieldValue={saveLiabilityFieldValue}
            setValue={setValue}
            setInvestmentSTCG={setInvestmentSTCG}
            setLTCG={setLTCG}
            setSTCG={setSTCG}
            stcg={stcg}
            investmentSTCG={investmentSTCG}
            ltcg={ltcg}
            accountsBySubtypeResponse={accountsBySubtypeResponse}
          />
        </>
      }
    />
  );
};
