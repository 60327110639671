import NoNotificationsSVG from "./no-notifications.svg";
import React, { useState } from "react";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon, IconAsset } from "components/core/icons";
import { NotificationRow } from "components/features/dashboard/pages/notifications/row/notification-row";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { PylonLink } from "components/core/link";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useMutation, useQuery } from "@apollo/client";
import "./styles.css";
import {
  GetPendingNotficationCount,
  GetPendingNotfications,
  GetPendingNotificationRequest,
  GetPendingNotificationResponse,
  MarkNotificationsAsViewedMutation,
} from "core/queries/notifications";

export const NotificationPage: React.FC = () => {
  const [markNotificationsAsViewed] = useMutation(
    MarkNotificationsAsViewedMutation,
    {
      refetchQueries: [
        {
          query: GetPendingNotficationCount,
        },
      ],
    }
  );

  const itemsPerPage = 10;

  const [page, setPage] = useState(0);

  const {
    data: pendingNotificationData,
    loading,
    refetch,
  } = useQuery<GetPendingNotificationResponse, GetPendingNotificationRequest>(
    GetPendingNotfications,
    {
      onCompleted: () => markNotificationsAsViewed(),
      variables: {
        offset: page * itemsPerPage,
        limit: itemsPerPage,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const goToPage = (p: number) => {
    if (loading) {
      return;
    }

    setPage(p);
    refetch({
      offset: p * itemsPerPage,
      limit: itemsPerPage,
    });
  };

  const total = pendingNotificationData?.pendingNotifications?.total || 0;

  const hasPrevious = page > 0;
  const hasNext = total > (page + 1) * itemsPerPage;

  const totalPages = Math.ceil(total / itemsPerPage);

  const messages =
    pendingNotificationData?.pendingNotifications?.notifications?.map((x) => (
      <>
        <NotificationRow key={x.id} notification={x} />
      </>
    ));

  return (
    <PageWrapper header={<PageTitle title="Notifications" />}>
      <div>
        {messages && messages.length > 0 ? (
          <>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Gray02}
              format={TextFormat.UpperCase}
            >
              <div className="notification-row notification-header">
                <div className="notification-cell notification-cell__date">
                  Date
                </div>
                <div className="notification-cell notification-cell__message">
                  Message
                </div>
                <div className="notification-cell notification-cell__action">
                  Action
                </div>
              </div>
            </Text>
            <Text
              type={TextType.Div}
              style={TextStyle.B16}
              colour={Colour.Navy}
              size={"1rem"}
            >
              {messages}
            </Text>

            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
              size="0.75rem"
            >
              <div
                style={{
                  paddingTop: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  onClick={() => {
                    if (hasPrevious) {
                      goToPage(page - 1);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Icon
                      asset={IconAsset.LeftDirectionArrow}
                      height="9.6px"
                      width="6.4px"
                      colour={"none"}
                      strokeColour={hasPrevious ? Colour.Blue01 : Colour.Text03}
                    />
                    <div style={{ paddingLeft: "0.25rem" }} />
                    <Text
                      type={TextType.Div}
                      colour={hasPrevious ? Colour.Blue01 : Colour.Text03}
                    >
                      prev
                    </Text>
                  </div>
                </div>
                <div>
                  {page + 1} of {totalPages}
                </div>
                <div
                  onClick={() => {
                    if (hasNext) {
                      goToPage(page + 1);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      type={TextType.Div}
                      colour={hasNext ? Colour.Blue01 : Colour.Text03}
                    >
                      Next
                    </Text>
                    <div style={{ paddingLeft: "0.25rem" }} />
                    <Icon
                      asset={IconAsset.RightDirectionArrow}
                      height="9.6px"
                      width="6.4px"
                      colour={"none"}
                      strokeColour={hasNext ? Colour.Blue01 : Colour.Text03}
                    />
                  </div>
                </div>
              </div>
            </Text>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingTop: "5rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "24.5rem",
                textAlign: "center",
              }}
            >
              <h4>No Notifcations</h4>
              <div
                style={{
                  padding: "2rem",
                }}
              />
              <svg>
                <use xlinkHref={`${NoNotificationsSVG}#illustration`} />
              </svg>
              <Text style={TextStyle.B16} size="1rem">
                Congrats! You currently have no new notifications, have you
                considered adding some people?{" "}
              </Text>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "0.5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "0.5rem",
                    width: "1.7rem",
                    borderBottom: "2px solid var(--blue-01)",
                  }}
                >
                  {" "}
                </div>
              </div>
              <PylonLink to={`${DashboardPages.ManageContact}?addNew=1`}>
                <Text
                  style={TextStyle.L12}
                  type={TextType.Div}
                  size="0.75rem"
                  format={TextFormat.UpperCase}
                >
                  Add Collaborators
                </Text>
              </PylonLink>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
