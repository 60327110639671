import * as React from "react";
import Modal from "react-modal";
import { BaseModalProps } from "components/core/modal/props";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { PylonLink } from "components/core/link";
import "../styles/modal.css";
import "./styles.css";

interface PublicProps {
  showModal: boolean;
  hasPrevious: boolean;
  hasNext: boolean;
  onClose: () => void;
  onClickPrevious: () => void;
  onClickNext: () => void;

  quickActions: React.ReactElement[];

  detailPageLink?: string;
  hideChrome?: boolean;
}

export type Props = PublicProps & BaseModalProps;

export const TableRowModal: React.FC<Props> = ({
  showModal,
  hasPrevious,
  hasNext,
  onClose,
  onClickPrevious,
  onClickNext,
  quickActions,
  detailPageLink,
  children,
  width = "55rem",
  height = "95vh",
  hideChrome,
}) => {
  return (
    <Modal
      isOpen={showModal}
      className="table-row-modal"
      overlayClassName="overlay-base overlay-base--low-priority"
      style={{
        overlay: {
          backgroundColor: "rgba(3, 12, 23, 0.7)",
        },
      }}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div
        className="flex-column"
        style={{
          width: width,
          height: height,
          overflow: "auto",
        }}
      >
        {!hideChrome && (
          <div
            className="flex-row between"
            style={{
              padding: "0.75rem 1.5rem",
            }}
          >
            <div className="flex-row align-center">
              {detailPageLink && (
                <PylonLink
                  className="table-row__open-as-page"
                  to={detailPageLink}
                  // target="_blank"
                >
                  <div
                    className="flex-row align-center"
                    style={{
                      padding: "0 1rem",
                      cursor: "pointer",
                      gap: "0.5rem",
                    }}
                  >
                    <Icon
                      asset={IconAsset.Expand}
                      width="16px"
                      height="16px"
                      colour={Colour.Black}
                    />

                    <div>Open as page</div>
                    <div
                      style={{
                        paddingLeft: "0.75rem",
                      }}
                    />
                    <Icon
                      asset={IconAsset.VerticalDivider}
                      height="24px"
                      width="1px"
                      colour={Colour.Gray70}
                    />
                  </div>
                </PylonLink>
              )}
              <div
                style={{
                  userSelect: "none",
                  cursor: hasPrevious ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (hasPrevious) {
                    onClickPrevious();
                  }
                }}
              >
                <Icon
                  asset={IconAsset.UpArrow}
                  width="24px"
                  height="24px"
                  colour={hasPrevious ? Colour.Black : Colour.Gray50}
                />
              </div>
              <div
                style={{
                  paddingRight: "0.5rem",
                }}
              />
              <div
                style={{
                  userSelect: "none",
                  cursor: hasNext ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (hasNext) {
                    onClickNext();
                  }
                }}
              >
                <Icon
                  asset={IconAsset.DownArrow}
                  width="24px"
                  height="24px"
                  colour={hasNext ? Colour.Black : Colour.Gray50}
                />
              </div>
            </div>

            <div style={{ display: "flex", gap: "1.5rem" }}>{quickActions}</div>
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};
