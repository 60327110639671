import { gql } from "@apollo/client";

// Separate file for fragments to avoid importing loop
export const DOCUMENT_FOLDER_FIELDS = gql`
  fragment DocumentFolderFields on Folder {
    id
    suggestionID
    suggestionsettings {
      id
      itemTitle
      description
      ignored
    }
  }
`;

export const DOCUMENT_FIELDS = gql`
  fragment DocumentFields on Document {
    id
    ownerID
    name
    filename
    mimeType
    categories {
      id
      name
      type
    }
    notes
    beneficiaryNote
    createdAt
    modifiedAt
    owner {
      id
      firstName
      lastName
    }
    recipients {
      id
      firstName
      lastName
      email
    }
    isOwner
    isEditor
    isOwnerOrEditor
    previewURL
  }
`;
