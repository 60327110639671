import React from "react";
import { Icon, IconAsset } from "components/core/icons";
import { NotificationAction } from "components/features/dashboard/pages/notifications/row/notification-action";
import { Text, TextType } from "components/core/text";
import { UserNotification } from "core/queries/notifications";

export interface Props {
  notification: UserNotification;
}

export const NotificationRow: React.FC<Props> = ({ notification }) => {
  const isNew = !notification.viewedAt;
  return (
    <div className="notification-row">
      <div className="notification-cell notification-cell__date">
        <Text type={TextType.Div}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {new Date(notification.createdAt).toLocaleDateString()}
            {isNew && (
              <>
                <div
                  style={{
                    paddingLeft: "0.5rem",
                  }}
                />
                <Icon
                  asset={IconAsset.NotificationDot}
                  width="6px"
                  height="6px"
                />
              </>
            )}
          </div>
        </Text>
      </div>
      <div className="notification-cell notification-cell__message">
        {notification.message}
      </div>
      <div className="notification-cell notification-cell__action">
        <NotificationAction notification={notification} />
      </div>
    </div>
  );
};
