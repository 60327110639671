import * as React from "react";
import { blurOnEnter } from "core/utils/form-helpers";
import { FilingStatus } from "@pylon/taxes";
import { Icon, IconAsset } from "components/core/icons";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SupplementalIncomeTray } from "components/features/dashboard/pages/income-and-taxes/components/supplemental-income-tray";
import { Text, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import {
  TaxContext,
  TaxContextProvider,
} from "components/contexts/tax-context";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
import {
  TaxIncomeEventInterface,
  TaxIncomeEventType,
} from "core/queries/taxes";

interface Props {
  incomeEvent?: TaxIncomeEventInterface;
  incomeEventType: TaxIncomeEventType;
  fieldLabel: string;
  tooltipText: React.ReactElement;
}
export const TaxSectionIncomeEventRow: React.FC<Props> = ({
  incomeEvent,
  incomeEventType,
  fieldLabel,
  tooltipText,
}) => {
  const { pushTray } = React.useContext(SideTrayContext);
  const { filingStatus, removeIncomeEvent, saveSupplementalIncome } =
    React.useContext(TaxContext);

  return (
    <>
      <div className="flex-row between mobile-flex-column">
        <div>
          <div className="label">
            Your {fieldLabel} &#160;
            <PylonTooltip
              spacingSide={SpacingSide.Right}
              text={
                <Text type={TextType.Div}>
                  <p>{tooltipText}</p>
                </Text>
              }
              size={TooltipIconSize.Small}
            />
          </div>
          <PylonCurrencyInput
            placeholder={`Your ${fieldLabel}`}
            defaultValue={incomeEvent?.amount || 0}
            maxLength={12}
            onBlur={(e) =>
              saveSupplementalIncome(
                parseFloat(sanitizeCurrencyString(e.target.value)),
                false,
                incomeEventType,
                incomeEvent?.id,
                true
              )
            }
            onKeyDown={blurOnEnter}
            allowDecimals={true}
            decimalSeparator={"."}
            decimalsLimit={2}
            allowNegativeValue={false}
            prefix="$"
            style={{ marginBottom: "0.5rem" }}
          />
          {incomeEvent && incomeEvent.amount != null && (
            <div
              className="blue-01 clickable"
              style={{ textAlign: "right" }}
              onClick={() => {
                pushTray({
                  header: <h5>{fieldLabel}</h5>,
                  children: (
                    <TaxContextProvider>
                      <SupplementalIncomeTray incomeEvent={incomeEvent} />
                    </TaxContextProvider>
                  ),
                });
              }}
            >
              Taxes Withheld{" "}
              <b>
                {toMoneyString(
                  parseFloat(incomeEvent?.federalWithholding || "0") +
                    parseFloat(incomeEvent?.stateWithholding || "0")
                )}
              </b>
            </div>
          )}
        </div>
        <div className="flex-row">
          {filingStatus === FilingStatus.MarriedFilingJointly && (
            <div>
              <div className="label">
                Spouse's {fieldLabel}&#160;
                <PylonTooltip
                  spacingSide={SpacingSide.Right}
                  text={
                    <Text type={TextType.Div}>
                      <p>{tooltipText}</p>
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <PylonCurrencyInput
                placeholder={`Spouse's ${fieldLabel}`}
                style={{ marginBottom: "0.5rem" }}
                defaultValue={incomeEvent?.spouseAmount || 0}
                maxLength={12}
                onBlur={(e) => {
                  saveSupplementalIncome(
                    parseFloat(sanitizeCurrencyString(e.target.value)),
                    true,
                    incomeEventType,
                    incomeEvent?.id,
                    true
                  );
                }}
                allowDecimals={true}
                decimalSeparator={"."}
                decimalsLimit={2}
                allowNegativeValue={false}
                prefix="$"
                onKeyDown={blurOnEnter}
              />
              {incomeEvent && incomeEvent.spouseAmount != null && (
                <div
                  className="blue-01 clickable"
                  style={{ textAlign: "right" }}
                  onClick={() => {
                    pushTray({
                      header: <h5>{fieldLabel}</h5>,
                      children: (
                        <TaxContextProvider>
                          <SupplementalIncomeTray
                            incomeEvent={incomeEvent}
                            isSpouse={true}
                          />
                        </TaxContextProvider>
                      ),
                    });
                  }}
                >
                  Taxes Withheld{" "}
                  <b>
                    {toMoneyString(
                      parseFloat(incomeEvent?.spouseFederalWithholding || "0") +
                        parseFloat(incomeEvent?.spouseStateWithholding || "0")
                    )}
                  </b>
                </div>
              )}
            </div>
          )}
          {incomeEvent && (
            <div
              className="flex-column center ml1 clickable"
              onClick={() => {
                removeIncomeEvent(incomeEvent);
              }}
            >
              <Icon asset={IconAsset.Trashcan} width="16px" height="16px" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
