import * as React from "react";
import qs from "qs";
import { Dashboard } from "components/features/dashboard";
import { ExternalPage } from "components/features/external";
import { Home } from "components/home";
import { LandingPageCashflow1 } from "components/features/landing/cashflow";
import { LandingPageNetworth1 } from "components/features/landing/networth";
import { LandingPages } from "components/features/landing";
import { LandingPageV2 } from "components/features/landing/landing-v2";
import { Login } from "components/features/login";
import { PublicPage } from "components/features/public";
import { Route } from "react-router";
import { SecurityPage } from "components/home/components/home-security";
import { SideTray } from "components/core/side-tray";
import { StandardOnboardingPage } from "components/features/onboarding";
import { TaxesOnboardingPage } from "components/features/onboarding/flows/taxes/index-2";
import { ToastContainer } from "react-toastify";
import { useSessionStorage } from "core/utils/browser-storage";
import { VerifyEmail } from "components/features/verify-email";
export interface PylonRoute {
  index: number;
  exact?: boolean;
  route: string;
  component: React.ComponentClass | React.FC;
  percentBased?: boolean;
  documentTitle?: string;
}
// hoc
export function renderRoutes() {
  const routes: PylonRoute[] = [
    {
      index: 15,
      route: "/landing2/budgeting",
      component: wrap(LandingPageWrapper(Home), "marketing home-2"),
    },
    {
      index: 14,
      route: "/landing/invitation",
      component: wrap(LandingPageWrapper(Home), "marketing home-2"),
    },
    {
      index: 13,
      route: "/landing2",
      component: wrap(LandingPageV2, "marketingv2"),
    },
    {
      index: 12,
      route: "/landing/cashflow/track-monthly-cashflow-from-all-accounts",
      component: wrap(
        LandingPageWrapper(LandingPageCashflow1),
        "marketing cashflow-1"
      ),
    },
    {
      index: 11,
      route: "/landing/networth/calculate-your-networth",
      component: wrap(
        LandingPageWrapper(LandingPageNetworth1),
        "marketing networth-1"
      ),
    },
    {
      index: 10,
      route: "/landing/main",
      component: wrap(LandingPageWrapper(Home), "marketing home-2"),
    },
    {
      index: 9,
      route: "/landing",
      component: wrap(LandingPages, "marketing"),
    },
    {
      index: 8,
      route: "/public",
      component: wrap(PublicPage, "marketing"),
    },
    {
      index: 6,
      route: "/security",
      component: wrap(SecurityPage, "marketing"),
    },
    {
      index: 5,
      route: "/external",
      component: wrap(ExternalPage, "marketing"),
    },
    {
      index: 4,
      route: "/tax-onboarding",
      component: wrap(TaxesOnboardingPage),
    },
    { index: 4, route: "/onboarding", component: wrap(StandardOnboardingPage) },
    { index: 16, route: "/verify-email", component: wrap(VerifyEmail) },
    { index: 3, route: "/login", component: wrap(Login) },
    { index: 2, route: "/dashboard", component: wrap(Dashboard) },
    { index: 0, route: "/", component: wrap(Home, "marketing") },
  ];

  return routes.map((route) => (
    <Route
      exact={route.exact}
      key={route.index}
      path={route.route}
      component={route.component}
    />
  ));
}

const wrap = (
  WrappedComponent: React.ComponentClass | React.FC,
  additionalClasses?: string
) => {
  return (props: any) => (
    <div className={`main-container desktop ${additionalClasses || ""}`}>
      <WrappedComponent {...props} />
      <SideTray />
      <ToastContainer
        className="pylon-toast-container"
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false} // Must be false for auto close to work. magic is in css
        newestOnTop={false}
        closeButton={true}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </div>
  );
};

export const LandingPageWrapper = (
  WrappedComponent: React.ComponentClass | React.FC
) => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const [adParams, setAdParams] = useSessionStorage("landing-params", "");
  if (
    Object.keys(query).length &&
    (!adParams || adParams === "" || adParams === "{}")
  ) {
    setAdParams(JSON.stringify({ ...query, location: document.location }));
  }
  return (props: any) => <WrappedComponent {...props} />;
};
