import { GroupTypeBase, Styles } from "react-select";

export const SELECT__MODAL_INPUT_STYLE: Partial<
  Styles<
    {
      value: any;
      label: string | any;
    },
    false,
    GroupTypeBase<{
      value: any;
      label: string | any;
    }>
  >
> = {
  control: (base: any) => {
    return {
      ...base,
      border: "2px solid transparent",
      borderRadius: "0px",
      background: "#f6f6f6",
      padding: "0rem 1rem",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #afafaf",
      },
      "&:active": {
        border: "2px solid #afafaf",
        background: "#e2e2e2",
      },

      "&:focus": {
        border: "2px solid #000000",
        background: "#f6f6f6",
      },
    };
  },
  menuList: (base: any) => {
    return {
      ...base,
      maxHeight: "15.5rem",
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0",
  }),
  input: (base: any) => {
    return {
      ...base,
      ...{ margin: "0", border: "none" },
    };
  },
  option: (base: any) => {
    return {
      ...base,
      overflowWrap: "break-word",
    };
  },
};
