import * as React from "react";
import { ArrayAccountEnroll } from "components/features/dashboard/components/array-flow-wrapper/components/array-enroll";
import { ArrayComplete } from "components/features/dashboard/components/array-flow-wrapper/components/array-complete";
import { ArrayLegalAck } from "components/features/dashboard/components/array-flow-wrapper/components/array-legal-ack";
import { Spinner } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import {
  getCreditHistory,
  getCreditScore,
  orderCreditReportMutation,
} from "core/queries/credit";

interface ControlProps {
  onComplete: () => void;
  onClose: () => void;
}

type Props = ControlProps;

enum ArraySteps {
  LegalAck = "LEGAL",
  AccountEnroll = "ACCOUNT_ENROLL",
  AccountKBA = "ACCOUNT_KBA",
  ScoreLoading = "SCORE_LOADING",
  Finish = "FINISH",
}

export const ArrayFlowWrapper: React.FC<Props> = ({ onComplete }) => {
  const [step, setStep] = React.useState<ArraySteps>(ArraySteps.LegalAck);

  // generate a new uuid for sending into array
  const [arrayID] = React.useState<string>(v4());

  const [orderCreditReport, { data }] = useMutation(orderCreditReportMutation, {
    refetchQueries: [{ query: getCreditScore }, { query: getCreditHistory }],
    onCompleted: () => {
      setStep(ArraySteps.Finish);
    },
  });

  const renderStep = (
    step: ArraySteps,
    setStep: React.Dispatch<React.SetStateAction<ArraySteps>>
  ) => {
    switch (step) {
      case ArraySteps.LegalAck:
        return (
          <ArrayLegalAck onNext={() => setStep(ArraySteps.AccountEnroll)} />
        );
      case ArraySteps.AccountEnroll:
        return (
          <ArrayAccountEnroll
            arrayID={arrayID}
            onOrder={(userToken: string) =>
              orderCreditReport({
                variables: {
                  arrayID,
                  userToken,
                },
              })
            }
            onNext={() => setStep(ArraySteps.ScoreLoading)}
          />
        );

      case ArraySteps.ScoreLoading:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "3rem 0",
            }}
          >
            <Spinner animation="border" size="sm" />
          </div>
        );

      case ArraySteps.Finish:
        return (
          <ArrayComplete
            score={data?.orderCreditReport}
            onComplete={onComplete}
          />
        );
      default:
        return null;
    }
  };

  return renderStep(step, setStep);
};
