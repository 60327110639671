import * as React from "react";
import Modal from "react-modal";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { sendSupportMessage } from "../../../../../core/queries/profiles";
import { Text, TextStyle, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { UploadFileDropzone } from "components/features/dashboard/components/add-document-tray/upload-file-dropzone";
import { UploadFileProps } from "components/features/dashboard/components/add-document-tray";
import { useMutation } from "@apollo/client";
import { v4 } from "uuid";
import "./index.css";

interface Props {
  showModal: boolean;
  onClose: () => void;
}

export const SupportMessageModal: React.FC<Props> = ({
  showModal,
  onClose,
}) => {
  const [idCounter, setIDCounter] = React.useState(0);
  const [files, setFiles] = React.useState<UploadFileProps[]>([]);

  const [message, setMessage] = React.useState<string>("");
  const [showErrorMessage, setShowErrorMessage] =
    React.useState<boolean>(false);
  const [sendMessageMutation, { loading: sendLoading }] = useMutation(
    sendSupportMessage,
    {
      onCompleted: (err) => {
        toast(
          <PylonToastBody
            title={"Message Sent!"}
            body={`Your message has been sent and will be reviewed shortly.`}
            type={ToastType.Default}
          />
        );
        setMessage("");
        setFiles([]);
        onClose();
      },
    }
  );

  const doSetMessage = (value: string) => {
    setShowErrorMessage(false);
    if (value.length > 1000) {
      value = value.substring(0, 1000);
    }
    setMessage(value);
  };

  const sendMessage = () => {
    if (message.length === 0) {
      setShowErrorMessage(true);
      return;
    }
    sendMessageMutation({
      variables: {
        message,
        files: files ? files.map((f) => f.file) : [],
      },
    });
  };

  return (
    <Modal
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      isOpen={showModal}
      onRequestClose={onClose}
      className="modal-base contact-support-modal"
      overlayClassName="overlay-base"
      style={{
        overlay: {
          backgroundColor: "rgba(3, 12, 23, 0.7)",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "1rem",
          right: "1.5rem",
          width: "auto",
          cursor: "pointer",
        }}
        onClick={() => {
          onClose();
        }}
      >
        <Icon
          asset={IconAsset.Close}
          width="32px"
          height="32px"
          colour={Colour.Text02}
        />
      </div>
      <div>
        <h6>
          Contact Pylon
          <Icon
            asset={IconAsset.Dot}
            width="4px"
            height="4px"
            colour={Colour.Blue01}
          />
        </h6>
        <div className="subtitle-02">How can we help you?</div>
        <textarea
          style={{
            border: "2px solid var(--separator-02)",
            resize: "vertical",
          }}
          placeholder="Enter your message or paste screenshot..."
          value={message}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            doSetMessage(e.currentTarget.value);
          }}
          onPaste={(e) => {
            // We need to check if event.clipboardData is supported (Chrome & IE)
            if (e.clipboardData && e.clipboardData.items) {
              // Get the items from the clipboard
              var items = e.clipboardData.items;

              // Loop through all items, looking for any kind of image
              for (var i = 0; i < items.length; i++) {
                if (items[i].type.indexOf("image") !== -1) {
                  // We need to represent the image as a file
                  var blob = items[i].getAsFile();

                  if (!blob) {
                    continue;
                  }

                  const id = v4();
                  setFiles(
                    files.concat([
                      {
                        id: id,
                        documentName: "pasted-" + id,
                        file: blob,
                        categories: [],
                      },
                    ])
                  );

                  // Prevent the image (or URL) from being pasted into the contenteditable element
                  e.preventDefault();
                }
              }
            }
          }}
        />
        {showErrorMessage && (
          <div style={{ color: "var(--red-01)" }}>
            You need to put a message in this box.
          </div>
        )}
        {!showErrorMessage && <br />}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              alignSelf: "flex-start",
            }}
          >
            <Text style={TextStyle.FieldLabel} type={TextType.Div}>
              Attachments (max 30mb):
            </Text>
          </div>
          <UploadFileDropzone
            allowMulti
            onChange={(toAdd: File[]) => {
              let newFiles = files;
              let j = idCounter;

              toAdd.forEach((x) => {
                const id = j++;

                newFiles = [
                  ...newFiles,
                  {
                    id: `${id}`,
                    file: x,
                    categories: [],
                    documentName: `${x.name
                      ?.split(".")
                      .slice(0, -1)
                      .join(".")}`,
                  },
                ];
              });

              setFiles(newFiles);
              setIDCounter(idCounter + toAdd.length);
            }}
          />

          <Text style={TextStyle.Hint} type={TextType.Div}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {files.length > 0 &&
                files.map((f) => {
                  return (
                    <div
                      key={f.id}
                      className="contact-support-modal__selected_file"
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <img
                          alt="preview"
                          width="60"
                          height="60"
                          src={URL.createObjectURL(f.file)}
                        />
                        <div
                          style={{
                            paddingLeft: "0.5rem",
                            cursor: "pointer",
                            height: "fit-content",
                          }}
                          onClick={() => {
                            setFiles(files.filter((rf) => rf.id !== f.id));
                          }}
                        >
                          <Icon
                            asset={IconAsset.SmallCross}
                            width="8px"
                            height="8px"
                            colour={Colour.Text02}
                          />
                        </div>
                      </div>
                      <div className="contact-support-modal__file-text">
                        {f.documentName}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Text>
        </div>
        <br />
        <div className="flex-row justify-center">
          <LeafButton
            disabled={!message}
            onClick={() => sendMessage()}
            loading={sendLoading}
          >
            Send Message
          </LeafButton>
        </div>
        <div
          style={{
            paddingTop: "1rem",
          }}
        />
      </div>
    </Modal>
  );
};
