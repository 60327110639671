import ChildcareIllustration from "./childcare-illustration.svg";
import { Colour } from "core/models";
import { PlanContext } from "components/contexts/plan-context-provider";
import { PlanDataSerializer } from "components/features/dashboard/components/planning/calculators/data-serializer";
import { PlanOptions } from "components/features/dashboard/components/planning/models";
import { PlanTitle } from "components/features/dashboard/components/planning/calculators/plan-title";
import { PylonCurrencyInput } from "components/core/currency-input";
import { SavedScenario } from "components/features/dashboard/components/planning/saved-scenario";
import { ScenarioPlan } from "core/queries/scenario-plans";
import { SerializablePlan } from "components/features/dashboard/components/planning/calculators/models";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useContext, useState } from "react";
import "./styles.css";
import {
  CashflowResult,
  ExpenseResult,
  ScenarioResultScreen,
} from "components/features/dashboard/components/planning/calculators/result-screen";

type ChildcareData = {
  hoursPerWeek?: string;
  costPerHour?: string;
} & SerializablePlan;

export const ChildcareCalculator: React.FC = () => {
  const { monthlyCashflow } = useContext(PlanContext);

  const getDefaultScenarioData = () => {
    return {
      hoursPerWeek: "35",
      costPerHour: "14.50",
    };
  };

  const [scenario, setScenario] = useState<Partial<ChildcareData>>(
    getDefaultScenarioData()
  );

  const [savedScenario, setSavedScenario] = useState<ScenarioPlan | undefined>(
    undefined
  );

  const costPerDay =
    scenario.hoursPerWeek !== undefined && scenario.costPerHour !== undefined
      ? (Number.parseFloat(scenario.hoursPerWeek) *
          Number.parseFloat(scenario.costPerHour)) /
        7
      : undefined;

  const calculatedMonthlyCashflow = costPerDay
    ? monthlyCashflow - costPerDay * 30
    : undefined;

  const namingParts = [
    scenario.costPerHour ?? "Scenario",
    scenario.hoursPerWeek ?? "",
  ];

  const defaultName = namingParts.join(" - ");

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <SavedScenario
        selectedOption={PlanOptions.Childcare}
        onLoad={(d) => {
          if (!d) {
            setScenario(getDefaultScenarioData());
            setSavedScenario(undefined);
            return;
          }
          const data = PlanDataSerializer.deserialize<ChildcareData>(d.value);

          setScenario(data);
          setSavedScenario(d);
        }}
      />
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <PlanTitle scenario={savedScenario} />

          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Hours needed per week
            </Text>
            <PylonCurrencyInput
              className="plan-input-box"
              value={scenario.hoursPerWeek}
              onValueChange={(v) =>
                setScenario({
                  ...scenario,
                  hoursPerWeek: v,
                })
              }
              placeholder="35"
              decimalsLimit={2}
              allowNegativeValue={false}
            />
          </div>

          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Cost per hour
            </Text>
            <PylonCurrencyInput
              className="plan-input-box"
              value={scenario.costPerHour}
              onValueChange={(v) =>
                setScenario({
                  ...scenario,
                  costPerHour: v,
                })
              }
              placeholder="$14.50"
              decimalsLimit={2}
              allowNegativeValue={false}
            />
          </div>
        </div>

        <div
          style={{
            paddingTop: "2rem",
          }}
        />

        <ScenarioResultScreen
          saveButtonProps={{
            selectedOption: PlanOptions.Childcare,
            serializedPlan: PlanDataSerializer.serialize({
              ...scenario,
              monthlyCashflow: calculatedMonthlyCashflow,
            }),
            defaultName: defaultName,
            onSave: (sp) => {
              setSavedScenario(sp);
            },
            scenario: savedScenario,
          }}
        >
          <ExpenseResult
            monthlyCost={costPerDay ? costPerDay * 30 : undefined}
          />
          <CashflowResult newCashflow={calculatedMonthlyCashflow} />
        </ScenarioResultScreen>

        <div
          style={{
            paddingTop: "2rem",
          }}
        >
          <svg width="688" height="328">
            <use xlinkHref={`${ChildcareIllustration}#illustration`} />
          </svg>
        </div>
      </div>
    </div>
  );
};
