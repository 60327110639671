import * as React from "react";

export interface PlaidWrapperContextValue {
  institutionErrored?: string;
  linkError?: string;
  shouldExit?: boolean;
  setShouldExit: React.Dispatch<React.SetStateAction<boolean>>;
  setInstitutionErrored: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setLinkError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const PlaidWrapperContext =
  React.createContext<PlaidWrapperContextValue>({
    shouldExit: false,
    setShouldExit: () => null,
    institutionErrored: undefined,
    linkError: undefined,
    setInstitutionErrored: () => undefined,
    setLinkError: () => undefined,
  });

export const PlaidWrapperContextProvider: React.FC = ({ children }) => {
  const [shouldExit, setShouldExit] = React.useState<boolean>(false);
  const [institutionErrored, setInstitutionErrored] = React.useState<string>();
  const [linkError, setLinkError] = React.useState<string>();

  return (
    <PlaidWrapperContext.Provider
      value={{
        shouldExit,
        setShouldExit,
        institutionErrored,
        linkError,
        setInstitutionErrored,
        setLinkError,
      }}
    >
      {children}
    </PlaidWrapperContext.Provider>
  );
};
