import * as React from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import "./styles.css";

export interface Props {
  title: string;
  subtitle?: string;

  editable?: boolean;
  onEdit?: (newTitle: string) => void;
  noBlueDot?: boolean;
}

export const PageTitle: React.FC<Props> = ({
  title,
  subtitle,
  editable,
  onEdit,
  noBlueDot,
}) => {
  const pageTitle = React.useRef(title);

  const handleChange = (evt: ContentEditableEvent) => {
    pageTitle.current = evt.target.value;
  };

  const handleNameBlur = () => {
    onEdit?.(pageTitle.current);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  return (
    <div>
      <h4>
        <div
          style={{
            display: "flex",
          }}
        >
          <div>
            {editable ? (
              <ContentEditable
                disabled={!editable}
                className=""
                html={pageTitle.current || ""}
                onChange={handleChange}
                onBlur={handleNameBlur}
                onKeyDown={handleKeyDown}
              />
            ) : (
              title
            )}
          </div>
        </div>
      </h4>
    </div>
  );
};
