import { CreditHistoryItem } from "core/queries/credit";
import * as React from "react";
import { Account } from "../../../../../core/models";
import { toAccountString } from "../../../../../core/utils";
import { AccountIcon } from "../account-icon";

export interface Props {
  account?: Account;
  creditHistory?: CreditHistoryItem;
}

export const ShareTrayHeader: React.FC<Props> = ({
  account,
  creditHistory,
}) => {
  return (
    <div className="">
      <div className="row-card__content" style={{ flexDirection: "unset" }}>
        {account && (
          <>
            <AccountIcon account={account} />
            <div>
              <div className="row-card__content-title-text">
                {account?.name}
              </div>
              <div className="row-card__content-subtext-wrapper">
                {account?.official_name && (
                  <div className="row-card__content-subtext">
                    {account?.official_name}
                  </div>
                )}
                {!account?.official_name && account?.subtype && (
                  <div className="row-card__content-subtext">
                    {account?.subtype[0].toUpperCase() +
                      account?.subtype.substring(1)}
                  </div>
                )}
                {account?.mask && (
                  <div className="row-card__content-subtext">
                    {toAccountString(account?.mask)}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {creditHistory && (
          <>
            {/* <AccountIcon account={account} /> */}
            <div>
              <h5>Credit Score History</h5>
              {/* { <div className="row-card__content-subtext-wrapper">
                {account?.official_name && (
                  <div className="row-card__content-subtext">
                    {account?.official_name}
                  </div>
                )}
                {!account?.official_name && account?.subtype && (
                  <div className="row-card__content-subtext">
                    {account?.subtype[0].toUpperCase() +
                      account?.subtype.substring(1)}
                  </div>
                )} */}
              {/* {creditHistory.total > 0 && (
                <div className="row-card__content-subtext">
                  {creditHistory.creditHistory[0].date}
                </div>
              )} */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
