import * as React from "react";
import { usePreferenceSettingStringEnum } from "core/hooks/user-preference-setting";
import { useQuery } from "@apollo/client";
import { UserPreferenceSettingEnum } from "core/queries/user-preference-settings";
import {
  FetchOverviewDataResponse,
  FETCH_OVERVIEW_DATA,
  OverviewDataGroupFields,
} from "../../../core/queries/overview";

export enum OwnerTypeKey {
  Me = "me",
  Spouse = "spouse",
  Any = "shared",
}

const switchToSpouse = (
  ownerType: OwnerTypeKey,
  overviewdata: FetchOverviewDataResponse
) => {
  // If the ownerType is me and I have no account data
  // but my spouse does then switch to that.
  if (
    ownerType === OwnerTypeKey.Me &&
    overviewdata.overviewData?.my &&
    overviewdata.overviewData?.spouse
  ) {
    const my = overviewdata.overviewData?.my;
    if (
      (!my.assets && !my.liabilities) ||
      (my.assets?.count === 0 && my.liabilities?.count === 0)
    ) {
      const spouse = overviewdata.overviewData?.spouse;
      if (
        (spouse.assets?.count && spouse.assets.count > 0) ||
        (spouse.liabilities?.count && spouse.liabilities.count > 0)
      ) {
        return true;
      }
    }
  }

  return false;
};

export enum DataTypeFiltering {
  NetWorth = "Total Net Worth",
  Assets = "Assets",
  Liabilities = "Liabilities",
  LiquidAssets = "Liquid Assets",
  IlliquidAssets = "Illiquid Assets",
}

export interface SummaryContextValue {
  ownerTypeForFiltering: string;
  overviewData: any;

  ownerType: OwnerTypeKey;
  setOwnerType: (ownerType: OwnerTypeKey) => void;

  dataType: DataTypeFiltering;
  setDataType: (dataType: DataTypeFiltering) => void;

  filteredOverviewData: OverviewDataGroupFields | null;
}
interface Props {}

export const SummaryContext = React.createContext<SummaryContextValue>({
  ownerType: OwnerTypeKey.Me,
  ownerTypeForFiltering: "my",
  setOwnerType: () => null,
  overviewData: undefined,
  filteredOverviewData: {},
  dataType: DataTypeFiltering.NetWorth,
  setDataType: () => null,
});

export const SummaryContextProvider: React.FC<Props> = ({ children }) => {
  const [ownerType, setOwnerType] = usePreferenceSettingStringEnum(
    OwnerTypeKey.Me,
    UserPreferenceSettingEnum.NetworthHistoryOwnerType
  );
  const [dataType, setDataType] = usePreferenceSettingStringEnum(
    DataTypeFiltering.NetWorth,
    UserPreferenceSettingEnum.NetworthHistoryDataType
  );
  const ownerTypeForFiltering = React.useMemo<string>(() => {
    return ownerType === OwnerTypeKey.Me ? "my" : "spouse";
  }, [ownerType]);
  const { data: overviewData } = useQuery<FetchOverviewDataResponse>(
    FETCH_OVERVIEW_DATA,
    {
      fetchPolicy: "network-only",
    }
  );
  const [ownerInit, setOwnerInit] = React.useState<boolean>(false);

  if (!ownerInit && overviewData && ownerType) {
    setOwnerInit(true);
    if (switchToSpouse(ownerType, overviewData)) {
      setOwnerType(OwnerTypeKey.Spouse);
    }
  }

  const filteredOverviewData = React.useMemo(() => {
    if (!overviewData || !overviewData.overviewData) return null;
    switch (ownerTypeForFiltering) {
      case "my":
        return overviewData.overviewData.my;
      case "spouse":
        return overviewData.overviewData.spouse;
      case "shared":
        return overviewData.overviewData.shared;
      default:
        return null;
    }
  }, [overviewData, ownerTypeForFiltering]);

  return (
    <SummaryContext.Provider
      value={{
        ownerType,
        setOwnerType,
        ownerTypeForFiltering,
        overviewData: overviewData?.overviewData,
        filteredOverviewData,
        dataType,
        setDataType,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};
