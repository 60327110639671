import { Account } from "core/models";
import { Collaboration } from "components/features/dashboard/models/sharing";
import { DocumentData } from "components/features/dashboard/models/document";
import { gql } from "@apollo/client";

export interface UpdateOverviewCardMutationResponse {
  updateOverviewCard: OverviewCardData;
}
export interface OverviewCardsQueryResponse {
  overviewCards: OverviewCardData[];
}

export interface OverviewCardData {
  id: string;
  userID: string;
  index: number;
  type: string;
  accountID: string;
  account?: Account;
}

export interface CreditUtilization {
  debt: number;
  limit: number;
  utilization: number;
  accountsWithoutLimit: number;
  accountsIncluded: number;
}

export interface NameNumberValue {
  name: string;
  value: number;
}
export interface ValueCard {
  total: number;
  count: number;
  change: number;
  categories: NameNumberValue[];
}

export const VALUE_CARD_FIELDS = gql`
  fragment ValueCardFields on ValueCard {
    total
    count
    change
    categories {
      name
      value
    }
  }
`;

export const OVERVIEW_DATA_GROUP = gql`
  ${VALUE_CARD_FIELDS}
  fragment OverviewDataGroupFields on OverviewDataGroup {
    netWorth {
      ...ValueCardFields
    }
    assets {
      ...ValueCardFields
    }
    liabilities {
      ...ValueCardFields
    }
    liquidAssets {
      ...ValueCardFields
    }
    illiquidAssets {
      ...ValueCardFields
    }
    credit {
      debt
      limit
      utilization
      accountsWithoutLimit
      accountsIncluded
    }
  }
`;

export interface NetworthHistory {
  date: Date;
  networth: number;
  assets: number;
  liabilities: number;
  liquidAsset: number;
  illiquidAsset: number;
}

export interface FetchNetworthHistoryResponse {
  networthHistory: {
    recipientCollaborationCount?: number;
    history?: NetworthHistory[];
  };
}

export interface FetchPortfolioNetworthHistoryResponse {
  portfolioNetworthHistory: {
    accountID?: string;
    history?: NetworthHistory[];
  }[];
}

export const FETCH_NETWORTH_HISTORY = gql`
  query fetchNetworthHistory($ownerType: String, $days: String) {
    networthHistory(ownerType: $ownerType, days: $days) {
      recipientCollaborationCount
      history {
        date
        networth
        assets
        liabilities
        liquidAsset
        illiquidAsset
      }
    }
  }
`;

export const FETCH_PORTFOLIO_NETWORTH_HISTORY = gql`
  query fetchPortfolioNetworthHistory(
    $dataType: String
    $ownerType: String
    $days: String
  ) {
    portfolioNetworthHistory(
      dataType: $dataType
      ownerType: $ownerType
      days: $days
    ) {
      accountID
      history {
        date
        networth
        assets
        liabilities
        liquidAsset
        illiquidAsset
      }
    }
  }
`;

export interface OverviewValueFields {
  total?: number;
  count?: number;
  change?: number;
  categories?: {
    name?: string;
    value?: number;
  }[];
}

export interface OverviewDataGroupFields {
  netWorth?: OverviewValueFields;
  assets?: OverviewValueFields;
  liabilities?: OverviewValueFields;
  liquidAssets?: OverviewValueFields;
  illiquidAssets?: OverviewValueFields;
  credit?: {
    debt?: number;
    limit?: number;
    utilization?: number;
    accountsWithoutLimit?: number;
    accountsIncluded?: number;
  };
}

export interface FetchOverviewDataResponse {
  overviewData: {
    my: OverviewDataGroupFields;
    spouse: OverviewDataGroupFields;
    shared: OverviewDataGroupFields;
  };
}

export const FETCH_OVERVIEW_DATA = gql`
  ${OVERVIEW_DATA_GROUP}
  query fetchOverviewData {
    overviewData {
      my {
        ...OverviewDataGroupFields
      }
      spouse {
        ...OverviewDataGroupFields
      }
      shared {
        ...OverviewDataGroupFields
      }
    }
  }
`;

export const overviewCardsQuery = gql`
  {
    overviewCards {
      id
      userID
      index
      type
      accountID
    }
  }
`;

export const addOverviewCard = gql`
  mutation AddOverviewCard($type: String!, $accountID: String) {
    createOverviewCard(type: $type, accountID: $accountID) {
      id
      userID
      index
      type
      accountID
    }
  }
`;

export const getOverviewCardByID = gql`
  query GetOverviewCardByID($id: ID!) {
    overviewCard(id: $id) {
      balance
      change
      account {
        account_id
        balances {
          current
          available
        }
        mask
        name
        official_name
        subtype
        type
        notes
        created_at
        integration_source
        integration_id
      }
    }
  }
`;
export const updateOverviewCard = gql`
  mutation UpdateOverviewCard($id: ID!, $index: Int!) {
    updateOverviewCard(id: $id, index: $index) {
      id
      index
      userID
      type
    }
  }
`;

export const deleteOverviewCard = gql`
  mutation DeleteOverviewCard($id: ID!) {
    deleteOverviewCard(id: $id)
  }
`;

export interface ProgressQueryResponse {
  collaborations: Pick<Collaboration, "id">[];
  accounts: Pick<Account, "id" | "owner">[];
  documents: Pick<DocumentData, "id" | "owner">[];
}

export const progressQuery = gql`
  query Progress {
    collaborations {
      id
    }
    documents {
      id
      owner {
        id
      }
    }
    accounts {
      id
      owner {
        id
      }
    }
  }
`;
