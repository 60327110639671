import * as React from "react";
import classnames from "classnames";
import Collapsible from "react-collapsible";

interface Props {
  localStorageKey: string;
  headerLeft: string | React.ReactNode;
  headerRight: string | React.ReactNode;
  bodyLeft?: string | React.ReactNode;
  bodyRight?: string | React.ReactNode;
  defaultClosed?: boolean;
  classes?: string;
}

export const TaxPageSection: React.FC<Props> = ({
  localStorageKey,
  headerLeft,
  headerRight,
  bodyLeft,
  bodyRight,
  defaultClosed,
  classes,
}) => {
  const [isOpen, setIsOpen] = React.useState(
    defaultClosed != null ? defaultClosed : false
  );
  const caratStyles = classnames({
    "accounts__section-carat": isOpen,
    "accounts__section-carat-closed": !isOpen,
  });

  return (
    <Collapsible
      open={isOpen}
      contentOuterClassName={defaultClosed ? "default-closed" : ""}
      trigger={
        <div
          className="table-row"
          onClick={() => {
            if (!bodyRight) return;
            setIsOpen(!isOpen);
          }}
        >
          <>
            <div className="table-cell">{headerLeft}</div>
            {headerRight}
          </>
        </div>
      }
      handleTriggerClick={bodyRight ? undefined : () => null}
    >
      <div className="left" style={{ width: "14rem" }}>
        <div className="text-02 mt1">{bodyLeft}</div>
      </div>
      <div className="right">{bodyRight}</div>
    </Collapsible>
  );
};
