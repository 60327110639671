import {
  AccountTransaction,
  AccountTransactionsInput,
  AccountTransactionsResponse,
  FETCH_TRANSACTIONS
  } from "core/queries/transactions";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { addMonths, format } from "date-fns";
import { ChangeInValueLabel } from "components/features/dashboard/pages/summary/change-in-value-label";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { dateFormatYYYY_MM, toMoneyStringNoDecimal } from "core/utils";
import { GlobalFilteringContext } from "components/contexts/global-filtering-context";
import { Link, useHistory } from "react-router-dom";
import { Text, TextStyle, TextType } from "components/core/text";
import { useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";

export const ExpensesSummaryCard: React.FC = () => {
  const history = useHistory();
  const { month, transactionData, transactionDataLoading, monthDate } =
    useContext(AccountTransactionContext);
  const { ownerIDs, hasAccounts } = useContext(GlobalFilteringContext);

  const previousMonth = addMonths(monthDate, -1);
  const { data, loading } = useQuery<
    AccountTransactionsResponse,
    AccountTransactionsInput
  >(FETCH_TRANSACTIONS, {
    fetchPolicy: "cache-first",
    variables: {
      month: dateFormatYYYY_MM(previousMonth),
      userIDs: ownerIDs,
    },
  });

  const monthName = format(monthDate, "MMMM");

  const currentMonthExpenses = hasAccounts
    ? sumExpenses(transactionData?.accountTransactions?.transactions)
    : 12345;

  const previousMonthExpenses = sumExpenses(
    data?.accountTransactions?.transactions
  );

  const cashflowURLParam = useMemo(() => {
    const u = new URLSearchParams();
    u.append("month", month);
    return u.toString();
  }, [month]);

  const showChangeDirection =
    !loading &&
    !transactionDataLoading &&
    currentMonthExpenses !== previousMonthExpenses;

  const textColor = hasAccounts ? Colour.Black : Colour.Gray400;

  return (
    <div
      onClick={() => {
        history.push(`${DashboardPages.CashFlow}?${cashflowURLParam}`);
      }}
      id="dashboard-cash-flow-box"
    >
      <div>
        <Text style={TextStyle.M17SB} colour={Colour.Black}>
          {monthName} Expenses
        </Text>
        <Text style={TextStyle.M35R} colour={textColor}>
          {transactionDataLoading
            ? toMoneyStringNoDecimal(12345)
            : toMoneyStringNoDecimal(currentMonthExpenses)}
        </Text>
        {hasAccounts && (
          <ChangeInValueLabel
            direction={undefined
            }
          >
            {loading
              ? "Loading..."
              : `${toMoneyStringNoDecimal(
                  previousMonthExpenses
                )} previous month`}
          </ChangeInValueLabel>
        )}{" "}
        {!hasAccounts && <ChangeInValueLabel>Example</ChangeInValueLabel>}
        {!hasAccounts && (
          <Link
            to={DashboardPages.Integrations}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Text
              style={TextStyle.M11}
              colour={Colour.Blue500}
              type={TextType.Span}
            >
              Connect your accounts
            </Text>
          </Link>
        )}
      </div>
    </div>
  );
};

const sumExpenses = (transactions?: AccountTransaction[]): number => {
  if (!transactions) {
    return 0;
  }

  return (
    transactions
      .filter((t) => t.amount > 0 && !t.excludeFromCashflow)
      .map((t) => t.amount)
      .reduce((a, b) => a + b, 0) ?? 0
  );
};
