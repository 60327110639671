import React, { useState } from "react";
import { Account, PrivateStockDetails } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { Checkbox } from "components/core/checkbox";
import { ModalDateInput } from "components/core/input/modal-date-input";
import { ModalInput } from "components/core/input/modal-input";
import { ModalInputWrapper } from "components/core/input/modal-input-wrapper";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { shallowEquals } from "core/utils/compare";
import { useQuery } from "@apollo/client";
import {
  GetInvestmentTransactions,
  GetInvestmentTransactionsResponse,
} from "core/queries/accounts";

export interface Props {
  account: Account;
  displayOnly?: boolean;
  onUpdate?: (acc: Partial<Account>) => void;
}

export const ManagePrivateStockDetails: React.FC<Props> = ({
  account,
  displayOnly,
  onUpdate,
}) => {
  const { data } = useQuery<GetInvestmentTransactionsResponse>(
    GetInvestmentTransactions,
    {
      variables: {
        accountID: account.id,
      },
    }
  );

  const inputDetails = account.details?.privateStockDetails;

  const [edited, setEdited] = useState(false);
  const [details, setDetails] = useState<PrivateStockDetails>({
    sharesOwned: inputDetails?.sharesOwned || undefined,
    valuePerShare: inputDetails?.valuePerShare || undefined,
    remainingShares: inputDetails?.remainingShares || undefined,
    remainingShareType: inputDetails?.remainingShareType || undefined,
    areRSUShares: inputDetails?.areRSUShares || undefined,
    areOptionShares: inputDetails?.areOptionShares || undefined,
    shareVestStartDate: inputDetails?.shareVestStartDate || undefined,
    shareVestSchedule: inputDetails?.shareVestSchedule || undefined,
    intentToSell: inputDetails?.intentToSell || undefined,
  });

  const onBlurUpdate = () => {
    if (edited) {
      setEdited(false);
      onUpdate?.({
        details: {
          privateStockDetails: details,
        },
      });
    }
  };

  const immediateUpdate = (newDetails: PrivateStockDetails) => {
    onUpdate?.({
      details: {
        privateStockDetails: newDetails,
      },
    });
    updateDetails(newDetails);
    setEdited(false);
  };

  const updateDetails = (newDetails: PrivateStockDetails) => {
    if (!shallowEquals(details, newDetails)) {
      setDetails(newDetails);

      if (!edited) {
        setEdited(true);
      }
    }
  };

  const sharesOwned = account.details?.privateStockDetails?.sharesOwned;

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Shares owned"
          value={sharesOwned}
        />

        {sharesOwned &&
          parseFloat(sharesOwned) > 0 &&
          data?.transactions?.transactions &&
          data?.transactions?.transactions.length > 0 && (
            <AccountInformationValueCard
              type={ValueCardType.String}
              title="Shares remaining"
              value={
                sharesOwned &&
                parseFloat(sharesOwned) -
                  (data?.transactions?.transactions
                    ?.map((t) => t.quantity)
                    .reduce((a, b) => a + b) || 0)
              }
            />
          )}

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Fair Market Value"
          value={account.details?.privateStockDetails?.valuePerShare}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Shared remaining to vest"
          value={account.details?.privateStockDetails?.remainingShares}
        />

        {account.details?.privateStockDetails?.areOptionShares && (
          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Share Type"
            value={"Option"}
          />
        )}

        {account.details?.privateStockDetails?.areRSUShares && (
          <AccountInformationValueCard
            type={ValueCardType.String}
            title="Share Type"
            value={"RSU"}
          />
        )}

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Vest start date"
          value={account.details?.privateStockDetails?.shareVestStartDate}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Vest share schedule"
          value={account.details?.privateStockDetails?.shareVestSchedule}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Intent to sell"
          value={
            account.details?.privateStockDetails?.intentToSell ? "Yes" : "No"
          }
        />
      </>
    );
  }

  return (
    <>
      <ModalInput
        id="account-shares-owned"
        type="number"
        label={"How many fully diluted shares or options do you own?"}
        placeholder="123,456"
        value={details.sharesOwned}
        onChange={(v) => {
          updateDetails({
            ...details,
            sharesOwned: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-fmw"
        type="money"
        label={"Estimated Fair Market Value (FMV)"}
        placeholder="$123.45"
        value={details.valuePerShare}
        onChange={(v) => {
          updateDetails({
            ...details,
            valuePerShare: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-shares-left"
        type="number"
        label={"How many shares or options are left for you to vest?"}
        placeholder="654,321"
        value={details.remainingShares}
        onChange={(v) => {
          updateDetails({
            ...details,
            remainingShares: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />
      <ModalInputWrapper label="What type of shares are these?">
        <div className="flex-column gap-half">
          <Checkbox
            id={"share-type--options"}
            style={{
              width: "1rem",
              marginRight: "1rem",
            }}
            label={"Options"}
            checked={details.areOptionShares || false}
            onChange={() => {
              const newDetails = {
                ...details,
                areOptionShares: !details.areOptionShares,
              };
              immediateUpdate(newDetails);
            }}
          />
          <Checkbox
            id={"share-type--rsu"}
            style={{
              width: "1rem",
              marginRight: "1rem",
            }}
            type={"checkbox"}
            label={"RSUs"}
            checked={details.areRSUShares || false}
            onChange={() => {
              const newDetails = {
                ...details,
                areRSUShares: !details.areRSUShares,
              };
              immediateUpdate(newDetails);
            }}
          />
        </div>
      </ModalInputWrapper>

      <ModalDateInput
        id="account-vest-date"
        label="On which date did your equity start to vest (usually your start date)?"
        placeholderText="01/20/2030"
        selected={
          details.shareVestStartDate
            ? new Date(details.shareVestStartDate)
            : null
        }
        onChange={(date) => {
          if (!Array.isArray(date)) {
            updateDetails({
              ...details,
              shareVestStartDate: date?.toUTCString() ?? undefined,
            });
          }
        }}
        onSelect={(date) => {
          const newDetails = {
            ...details,
            shareVestStartDate: date?.toUTCString() ?? undefined,
          };
          immediateUpdate(newDetails);
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-vest-schedule"
        type="string"
        label={"How long is your vest?"}
        placeholder="2 years 6 months"
        value={details.shareVestSchedule}
        onChange={(v) => {
          updateDetails({
            ...details,
            shareVestSchedule: v,
          });
        }}
        onBlur={onBlurUpdate}
      />

      <Checkbox
        id={"account-share--intent-to-sell"}
        style={{
          width: "1rem",
          marginRight: "1rem",
        }}
        label={
          "Are you interested in selling your shares in the private market?"
        }
        onChange={() => {
          const newDetails = {
            ...details,
            intentToSell: !details.intentToSell,
          };
          immediateUpdate(newDetails);
        }}
        checked={details.intentToSell || false}
      />
    </>
  );
};
