import * as React from "react";
import { IconAsset } from "components/core/icons";
import { OnboardingProps } from "components/features/onboarding/flows/utils";
import {
  OnboardingCard,
  OnboardingCardSize,
} from "components/features/onboarding/components/onboarding-card";
import {
  currentOfferings,
  WhatToDoSelections,
} from "components/features/onboarding/models";

interface ContainerProps {}

function shuffle<T>(array: T[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
type Props = OnboardingProps & ContainerProps;

export const OnboardingCardContainer: React.FC<Props> = ({
  state,
  optimisticUpdate,
}) => {
  const selectionArray = React.useMemo(
    () => [
      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.SellPrivateStock)
        }
        id={WhatToDoSelections.SellPrivateStock}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.SellPrivateStock,
            !!!state?.[WhatToDoSelections.SellPrivateStock]
          );
        }}
        asset={IconAsset.OnboardingSellPrivateStock}
        text={"Sell privately owned stock"}
        selected={state?.[WhatToDoSelections.SellPrivateStock]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.LowerInterestRate)
        }
        id={WhatToDoSelections.LowerInterestRate}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.LowerInterestRate,
            !!!state?.[WhatToDoSelections.LowerInterestRate]
          );
        }}
        asset={IconAsset.OnboardingLowerInterest}
        text={"Lower any interest rate"}
        selected={state?.[WhatToDoSelections.LowerInterestRate]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.PayOffDebt)
        }
        id={WhatToDoSelections.PayOffDebt}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.PayOffDebt,
            !!!state?.[WhatToDoSelections.PayOffDebt]
          );
        }}
        asset={IconAsset.OnboardingPayOffDebt}
        text={"Pay off debt faster"}
        selected={state?.[WhatToDoSelections.PayOffDebt]}
      />,

      <OnboardingCard
        inDevelopment={!currentOfferings.includes(WhatToDoSelections.Open529)}
        id={WhatToDoSelections.Open529}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.Open529,
            !!!state?.[WhatToDoSelections.Open529]
          );
        }}
        asset={IconAsset.OnboardingOpen529}
        text={"Open a 529 plan for my kids"}
        selected={state?.[WhatToDoSelections.Open529]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.CompareNetworth)
        }
        id={WhatToDoSelections.CompareNetworth}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.CompareNetworth,
            !!!state?.[WhatToDoSelections.CompareNetworth]
          );
        }}
        asset={IconAsset.OnboardingCompareNetworth}
        text={"Compare my net worth with others"}
        selected={state?.[WhatToDoSelections.CompareNetworth]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.HelpParentsOrganize)
        }
        id={WhatToDoSelections.HelpParentsOrganize}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.HelpParentsOrganize,
            !!!state?.[WhatToDoSelections.HelpParentsOrganize]
          );
        }}
        asset={IconAsset.OnboardingHelpParents}
        text={"Help my parents get organized"}
        selected={state?.[WhatToDoSelections.HelpParentsOrganize]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.SetEmergencyContact)
        }
        id={WhatToDoSelections.SetEmergencyContact}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.SetEmergencyContact,
            !!!state?.[WhatToDoSelections.SetEmergencyContact]
          );
        }}
        asset={IconAsset.OnboardingSetEmergencyContact}
        text={"Emergency contacts"}
        selected={state?.[WhatToDoSelections.SetEmergencyContact]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.CheckCredit)
        }
        id={WhatToDoSelections.CheckCredit}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.CheckCredit,
            !!!state?.[WhatToDoSelections.CheckCredit]
          );
        }}
        asset={IconAsset.OnboardingCheckCredit}
        text={"Check my credit"}
        selected={state?.[WhatToDoSelections.CheckCredit]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.TrackCashflow)
        }
        id={WhatToDoSelections.TrackCashflow}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.TrackCashflow,
            !!!state?.[WhatToDoSelections.TrackCashflow]
          );
        }}
        asset={IconAsset.OnboardingTrackCashflow}
        text={"Track monthly cash flow"}
        selected={state?.[WhatToDoSelections.TrackCashflow]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(
            WhatToDoSelections.TrackInvestmentsInOnePlace
          )
        }
        id={WhatToDoSelections.TrackInvestmentsInOnePlace}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.TrackInvestmentsInOnePlace,
            !!!state?.[WhatToDoSelections.TrackInvestmentsInOnePlace]
          );
        }}
        asset={IconAsset.OnboardingTrackInvestments}
        text={"Track all of my investments"}
        selected={state?.[WhatToDoSelections.TrackInvestmentsInOnePlace]}
      />,

      <OnboardingCard
        inDevelopment={!currentOfferings.includes(WhatToDoSelections.Networth)}
        id={WhatToDoSelections.Networth}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.Networth,
            !!!state?.[WhatToDoSelections.Networth]
          );
        }}
        asset={IconAsset.OnboardingMoney}
        text={"See my net worth"}
        selected={state?.[WhatToDoSelections.Networth]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.BuyProperty)
        }
        id={WhatToDoSelections.BuyProperty}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.BuyProperty,
            !!!state?.[WhatToDoSelections.BuyProperty]
          );
        }}
        asset={IconAsset.OnboardingBuyProperty}
        text={"Buy property"}
        selected={state?.[WhatToDoSelections.BuyProperty]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.BuyVehicle)
        }
        id={WhatToDoSelections.BuyVehicle}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.BuyVehicle,
            !!!state?.[WhatToDoSelections.BuyVehicle]
          );
        }}
        asset={IconAsset.OnboardingBuyVehicle}
        text={"Buy a vehicle"}
        selected={state?.[WhatToDoSelections.BuyVehicle]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(
            WhatToDoSelections.InvestInPrivateCompanies
          )
        }
        id={WhatToDoSelections.InvestInPrivateCompanies}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.InvestInPrivateCompanies,
            !!!state?.[WhatToDoSelections.InvestInPrivateCompanies]
          );
        }}
        asset={IconAsset.OnboardingInvestPrivately}
        text={"Invest in private companies"}
        selected={state?.[WhatToDoSelections.InvestInPrivateCompanies]}
      />,

      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.SaveDocuments)
        }
        id={WhatToDoSelections.SaveDocuments}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.SaveDocuments,
            !!!state?.[WhatToDoSelections.SaveDocuments]
          );
        }}
        asset={IconAsset.OnboardingDocuments}
        text={"Save important documents"}
        selected={state?.[WhatToDoSelections.SaveDocuments]}
      />,
      <OnboardingCard
        inDevelopment={!currentOfferings.includes(WhatToDoSelections.Share)}
        id={WhatToDoSelections.Share}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.Share,
            !!!state?.[WhatToDoSelections.Share]
          );
        }}
        asset={IconAsset.OnboardingShare}
        text={"Collaborate on my finances"}
        selected={state?.[WhatToDoSelections.Share]}
      />,
      <OnboardingCard
        inDevelopment={
          !currentOfferings.includes(WhatToDoSelections.EstimateMyTaxes)
        }
        id={WhatToDoSelections.EstimateMyTaxes}
        size={OnboardingCardSize.Smaller}
        onClick={() => {
          optimisticUpdate(
            WhatToDoSelections.EstimateMyTaxes,
            !!!state?.[WhatToDoSelections.EstimateMyTaxes]
          );
        }}
        asset={IconAsset.OnboardingTaxes}
        text={"Estimate My Taxes"}
        selected={state?.[WhatToDoSelections.EstimateMyTaxes]}
      />,
    ],
    [state, optimisticUpdate]
  );

  const [selections, setSelections] = React.useState(
    shuffle<number>(selectionArray.map((_, i) => i))
  );
  return (
    <>
      {selections.map((i) => (
        <>{selectionArray[i]}</>
      ))}
    </>
  );
};
