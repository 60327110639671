import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Account } from "core/models";
import { AssetType } from "components/features/dashboard/models/sharing";
import { ConfirmationModalOld } from "components/core/modal";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { Dropdown } from "react-bootstrap";
import { IconAsset } from "components/core/icons";
import { IntegrationSource } from "components/features/dashboard/models/integration-source";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { refreshAccountMutation } from "../../../../../core/queries/accounts";
import { SessionContext } from "components/contexts/session-context-provider";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { Text } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  LoadingAnimation,
  ManageMenuOption,
} from "components/features/dashboard/components/manage-menu-option";
import {
  accountCategoriesExamplesQuery,
  accountCategoriesQuery,
  accountsQuery,
} from "core/queries/accounts";
import {
  recipientsForAssetQuery,
  recipientsForAssetQueryResponse,
} from "core/queries/collaborations";
import {
  deleteSharedDataByAssetMutation,
  deleteSharedDataMutation,
} from "core/queries/shared-data";

interface Props {
  account: Account;
  isConnected: boolean;
}

export const ViewAccountMenu: React.FC<Props> = (props: Props) => {
  const { userID } = React.useContext(SessionContext.context);
  const { data } = useQuery(recipientsForAssetQuery, {
    variables: {
      assetID: props.account.account_id,
      assetType: AssetType.Account,
    },
  });
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const [refreshAccount, { loading: refreshLoading }] = useMutation(
    refreshAccountMutation,
    {
      variables: {
        id: props.account.account_id,
      },
      refetchQueries: [
        { query: accountsQuery },
        { query: accountCategoriesQuery },
        { query: accountCategoriesExamplesQuery },
      ],
      onCompleted: () => {
        toast(
          <PylonToastBody
            title={"Account successfully updated"}
            body={
              "We've refreshed your account to show the latest information!"
            }
          />,
          {
            closeOnClick: false,
            closeButton: true,
          }
        );
      },
    }
  );

  const [deleteSharedData] = useMutation(deleteSharedDataMutation, {
    refetchQueries: onUpdateRefetchQueries,
  });
  const [deleteSharedDataByAsset] = useMutation(
    deleteSharedDataByAssetMutation,
    {
      onCompleted: () => {
        toast(
          <PylonToastBody
            title={"Access removed"}
            body={"Access has been removed"}
          />
        );

        popTray();
      },
      refetchQueries: onUpdateRefetchQueries,
    }
  );

  const { popTray } = React.useContext(SideTrayContext);
  const [showModal, setShowModal] = React.useState(false);

  // todo look this up on the backend and just fire a mutation to delete this shared data
  const sharedDataID = data?.recipientsForAsset?.find(
    (r: recipientsForAssetQueryResponse) => r.user.id === userID
  );
  const renderManualMenu = () => {
    return (
      <>
        <ManageMenuOption
          bottomBorder
          iconAsset={IconAsset.RemoveSharedAsset}
          optionText={"Remove my access"}
          onClick={() => {
            deleteSharedDataByAsset({
              variables: {
                id: props.account.account_id,
                type: AssetType.Account,
              },
            });
          }}
        />
        {(props.account.isOwner || props.account.isEditor) &&
          props.account.integration_source === IntegrationSource.Plaid &&
          !props.account.loginRequired && (
            <ManageMenuOption
              loading={refreshLoading}
              loadingAnimation={LoadingAnimation.SpinLeftToRight}
              disabled={refreshLoading}
              disabledToolTip={"Refreshing..."}
              iconAsset={IconAsset.RefreshAccount}
              onClick={() => refreshAccount()}
              optionText={"Refresh Account"}
            />
          )}
      </>
    );
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown bsPrefix="pylon" alignRight>
        <DropdownToggle
          as="div"
          className="elipsis-more"
          bsPrefix="pylon-dropdown-toggle"
        ></DropdownToggle>
        <DropdownMenu className="pylon-dropdown-menu">
          {renderManualMenu()}
        </DropdownMenu>
      </Dropdown>

      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        children={
          <>
            <Text weight={600}>
              Are you sure you want to delete this shared account?
            </Text>
            <Text weight={400}>
              This action is irreversible and cannot be undone.
            </Text>
          </>
        }
        onAction={() => {
          if (sharedDataID) {
            deleteSharedData({
              variables: {
                id: sharedDataID,
              },
            });
          }
          setShowModal(false);
          popTray();
        }}
        actionButtonText="Delete"
        onCancel={() => {
          setShowModal(false);
        }}
        cancelButtonText="Cancel"
      />
    </div>
  );
};
