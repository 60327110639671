import illustration from "./illustration.svg";
import { Colour } from "core/models";
import { Emoji } from "components/core/emoji";
import { LandingPageDefinition } from "components/features/landing";
import { RegisterButtonV2 } from "components/features/landing/components/register-version-2";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";

export const LandingNetworthPageA: LandingPageDefinition = {
  Path: "/landing2/networth/landing-a",
  Component: () => {
    const mobileLayout = useMobileLayout();
    const renderMobile = (
      <div
        className="flex-column "
        style={{
          minHeight: "100vh",
          backgroundColor: "#FFBF1F",
        }}
      >
        <div
          style={{
            padding: "1rem",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.Sharp24Bold}
            colour={Colour.Black}
          >
            Pylon
          </Text>
        </div>
        <div>
          <div
            style={{
              padding: "1rem",
            }}
          >
            <Text type={TextType.Div} style={TextStyle.Sharp35Bold}>
              Calculate your net worth with Pylon
            </Text>
            <div className="mt1" />
            <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
              <div style={{ display: "flex" }}>
                <Emoji emoji={"💰"} />
                <div>14% year over year increase in net worth</div>
              </div>
            </Text>
            <div className="mt1" />
            <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
              <div style={{ display: "flex" }}>
                <Emoji emoji={"📈"} />
                <div>Net worth $2.8 million by 2031</div>
              </div>
            </Text>
            <div className="mt1" />
            <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
              <div style={{ display: "flex" }}>
                <Emoji emoji={"⛵️"} />
                <div>Will retire early with over $8.8 million</div>
              </div>
            </Text>
            <div className="mt1" />
            <RegisterButtonV2 text="Get started →" blackBackground />
          </div>
          <div
            className="flex-row justify-center"
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "60px",
            }}
          >
            <img
              width={"100%"}
              src={illustration}
              style={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",
              }}
              alt="product illustration"
            />
          </div>
          <div className="mt4" />
        </div>
      </div>
    );
    const renderWeb = (
      <div
        style={{
          backgroundColor: "#FFBF1F",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "16px",
            left: "56px",
          }}
        >
          <Text
            type={TextType.Div}
            style={TextStyle.Sharp24Bold}
            colour={Colour.Black}
          >
            Pylon
          </Text>
        </div>
        <div
          className="flex-row align-center"
          style={{
            minHeight: "100vh",
          }}
        >
          <div
            style={{
              width: "544px",
              margin: "0 3.5rem",
            }}
          >
            <div style={{ marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp56Bold}
                colour={Colour.Black}
                size={"56px"}
                lineHeight="64px"
              >
                Calculate your net worth with Pylon
              </Text>
            </div>
            <div style={{ lineHeight: "24px" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp17Regular}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"💰"} />
                  <div>14% year over year increase in net worth</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", margin: "1.5rem 0" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp17Regular}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"📈"} />
                  <div>Net worth $2.8 million by 2031</div>
                </div>
              </Text>
            </div>
            <div style={{ lineHeight: "24px", marginBottom: "1.5rem" }}>
              <Text
                type={TextType.Div}
                style={TextStyle.Sharp17Regular}
                colour={Colour.Black}
                size={"17px"}
              >
                <div style={{ display: "flex" }}>
                  <Emoji emoji={"⛵️"} />
                  <div>Will retire early with over $8.8 million</div>
                </div>
              </Text>
            </div>
            <RegisterButtonV2
              blackBackground
              text="Get started →"
              optionalParam="skip=true"
            />
          </div>
          <div>
            <img
              width={"100%"}
              src={illustration}
              style={{
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.16)",
              }}
              alt="product illustration"
            />
          </div>
        </div>
      </div>
    );
    return mobileLayout ? renderMobile : renderWeb;
  },
};
