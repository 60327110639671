import * as React from "react";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { Terms } from "components/features/dashboard/pages/settings/terms";

export const DashboardTerms: React.FC = () => {
  return (
    <PageWrapper>
      <Terms />
    </PageWrapper>
  );
};
