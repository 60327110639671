import React from "react";
import { PureQueryOptions } from "@apollo/client";

export interface DataPageContextProps {
  onUpdateRefetchQueries?: Array<string | PureQueryOptions>;
  setOnUpdateRefetchQueries: (
    refetchQueries?: Array<string | PureQueryOptions>
  ) => void;
}

export const DataPageContext = React.createContext<DataPageContextProps>({
  onUpdateRefetchQueries: undefined,
  setOnUpdateRefetchQueries: () => {},
});

export type Props = Partial<DataPageContextProps>;

export class DataPageContextProvider extends React.Component<
  Props,
  DataPageContextProps
> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      onUpdateRefetchQueries: props.onUpdateRefetchQueries,

      setOnUpdateRefetchQueries: this.setOnUpdateRefetchQueries,
    };
  }

  private setOnUpdateRefetchQueries = (
    refetchQueries?: Array<string | PureQueryOptions>
  ) => {
    this.setState({
      onUpdateRefetchQueries: refetchQueries,
    });
  };

  public render() {
    return (
      <DataPageContext.Provider value={this.state}>
        {this.props.children}
      </DataPageContext.Provider>
    );
  }
}
