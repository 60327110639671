import * as React from "react";
import { Account } from "../../../../../core/models";
import { AccountSubtypeEnum, AccountType } from "../add-account-tray/models";
import { millisecondsReadable, toMoneyString } from "../../../../../core/utils";
import "./styles.css";

interface Props {
  account: Account;
}

export const AccountBalance: React.FC<Props> = ({ account }) => {
  const text = balanceText(account);

  return (
    (
      <div>
        <div className="label-small text-03">{text}</div>
        <h5 className="editable-name">
          {toMoneyString(account.balances.current)}
        </h5>
        <div className="body-small">
          {account.modified_at
            ? `${millisecondsReadable(
                new Date().getTime() -
                  new Date(parseInt(account.modified_at) * 1000).getTime()
              )} ago`
            : "1 minute ago"}
        </div>
      </div>
    ) || null
  );
};

export const isEditable = (account: Account): boolean => {
  switch (account.subtype) {
    case AccountSubtypeEnum.PrivateStock:
      return false;
    case AccountSubtypeEnum.Cryptocurrency:
      return false;
    case AccountSubtypeEnum.PrivateCompany:
      return false;
  }

  return true;
};

export const balanceText = (account: Account): string => {
  switch (account.type) {
    case AccountType.Insurance:
      return "Coverage Amount";
  }

  switch (account.subtype) {
    case AccountSubtypeEnum.PrivateCompany:
      return "Estimated Value";
  }
  return "Current Balance";
};
