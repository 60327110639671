import React, { useState } from "react";
import { Account, PrivateCompanyDetails } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { ModalDateInput } from "components/core/input/modal-date-input";
import { ModalInput } from "components/core/input/modal-input";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { shallowEquals } from "core/utils/compare";

export interface Props {
  account: Account;
  displayOnly?: boolean;
  onUpdate?: (acc: Partial<Account>) => void;
}

export const ManagePrivateCompanyDetails: React.FC<Props> = ({
  account,
  displayOnly,
  onUpdate,
}) => {
  const inputDetails = account.details?.privateCompanyDetails;

  const [edited, setEdited] = useState(false);
  const [details, setDetails] = useState<PrivateCompanyDetails>({
    estimatedValueAtInvestmentTime:
      inputDetails?.estimatedValueAtInvestmentTime || undefined,
    amountInvested: inputDetails?.amountInvested || undefined,
    amountInvestedPercentage:
      inputDetails?.amountInvestedPercentage || undefined,
    investmentDate: inputDetails?.investmentDate || undefined,
    estimatedInvestmentValue:
      inputDetails?.estimatedInvestmentValue || undefined,
    dividendsPaid: inputDetails?.dividendsPaid || undefined,
    dividendsInterval: inputDetails?.dividendsInterval || undefined,
  });

  const onBlurUpdate = () => {
    if (edited) {
      setEdited(false);
      onUpdate?.({
        details: {
          privateCompanyDetails: details,
        },
      });
    }
  };

  const updateDetails = (newDetails: PrivateCompanyDetails) => {
    if (!shallowEquals(details, newDetails)) {
      setDetails(newDetails);

      if (!edited) {
        setEdited(true);
      }
    }
  };

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Estimated value at time of investment"
          value={
            account.details?.privateCompanyDetails
              ?.estimatedValueAtInvestmentTime
          }
        />
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Amount invested"
          value={account.details?.privateCompanyDetails?.amountInvested}
        />
        <AccountInformationValueCard
          type={ValueCardType.Percentage}
          title="Amount of investment as a percentage"
          value={
            account.details?.privateCompanyDetails?.amountInvestedPercentage
          }
        />
        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Investment date"
          value={account.details?.privateCompanyDetails?.investmentDate}
        />
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Estimated value of investment"
          value={
            account.details?.privateCompanyDetails?.estimatedInvestmentValue
          }
        />
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Dividends paid"
          value={account.details?.privateCompanyDetails?.dividendsPaid}
        />
        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Dividends interval"
          value={account.details?.privateCompanyDetails?.dividendsInterval}
        />
      </>
    );
  }

  return (
    <>
      <ModalInput
        id="account-estimated-value"
        type="money"
        label={"Current estimated value of the entire business?"}
        placeholder="$10,000,000.00"
        value={details.estimatedInvestmentValue}
        onChange={(v) => {
          updateDetails({
            ...details,
            estimatedInvestmentValue: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-est-value-at-investment"
        type="money"
        label={
          "Estimated value of the entire business at time of original investment?"
        }
        placeholder="$2,000,000.00"
        value={details.estimatedValueAtInvestmentTime}
        onChange={(v) => {
          updateDetails({
            ...details,
            estimatedValueAtInvestmentTime: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-amt-invested"
        type="money"
        label={"How much have you invested in this business?"}
        placeholder="$200,000.00"
        value={details.amountInvested}
        onChange={(v) => {
          let p = "";
          if (details.estimatedValueAtInvestmentTime && v) {
            p = Math.round(
              (parseFloat(v) /
                parseFloat(details.estimatedValueAtInvestmentTime)) *
                100
            ).toString();
          }
          updateDetails({
            ...details,
            amountInvested: sanitizeStringNumberToString(v),
            amountInvestedPercentage: p,
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-est-pct-at-investment"
        type="percentage"
        label={"How much have you invested in this business as a percentage?"}
        placeholder="10%"
        value={details.amountInvestedPercentage}
        onChange={(v) => {
          let amountInvested = details.amountInvested;

          if (details.estimatedValueAtInvestmentTime && v) {
            amountInvested = (
              parseFloat(details.estimatedValueAtInvestmentTime) *
              (parseFloat(v) / 100)
            ).toString();
          }
          updateDetails({
            ...details,
            amountInvestedPercentage: sanitizeStringNumberToString(v),
            amountInvested: sanitizeStringNumberToString(amountInvested),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalDateInput
        id="account-date-of-investment"
        label="Date of Investment"
        placeholderText="04/20/2019"
        selected={
          details.investmentDate ? new Date(details.investmentDate) : null
        }
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...details,
              investmentDate: date?.toUTCString() ?? undefined,
            };

            setDetails(newDetails);

            onUpdate?.({
              details: {
                privateCompanyDetails: newDetails,
              },
            });
          }
        }}
      />

      <ModalInput
        id="account-dividends-paid"
        type="money"
        label={"Dividends Paid"}
        placeholder="$1,000.00"
        value={details.dividendsPaid}
        onChange={(v) => {
          updateDetails({
            ...details,
            dividendsPaid: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-dividends-interval"
        type="string"
        label={"Dividends Interval (e.g. Monthly/Quarterly/Yearly)"}
        placeholder="Quarterly"
        value={details.dividendsInterval}
        onChange={(v) => {
          updateDetails({
            ...details,
            dividendsInterval: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />
    </>
  );
};
