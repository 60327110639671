import React, { useState } from "react";
import { Account } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { ModalInput } from "components/core/input/modal-input";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import "./styles.css";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const ManageSavingsDetails: React.FC<Props> = ({
  account,
  isConnected,
  displayOnly,
  onUpdate,
}) => {
  const inputDetails = account.details?.savingsDetails;
  const [details, setDetails] = useState({
    apy: inputDetails?.apy || undefined,
  });

  const onBlurUpdate = () => {
    onUpdate?.({
      details: {
        savingsDetails: details,
      },
    });
  };

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Percentage}
          title="Annual Percentage Yield"
          value={account.details?.savingsDetails?.apy}
        />
      </>
    );
  }

  return (
    <ModalInput
      id="account-apy"
      type="percentage"
      label={"Annual Percentage Yield"}
      placeholder="2%"
      value={details.apy}
      onChange={(v) =>
        setDetails({
          ...details,
          apy: sanitizeStringNumberToString(v),
        })
      }
      onBlur={onBlurUpdate}
    />
  );
};
