import * as React from "react";
import { Text, TextType } from "components/core/text";
import "./styles.css";

export enum ToastType {
  Default = "pylon-toast-body__success", // An action was completed successfully
  Error = "pylon-toast-body__error", // Something went wrong
  Notification = "pylon-toast-body__notification", // Neutral action
}

export interface Props {
  title: string;

  body?: string;
  type?: ToastType;
  userError?: boolean;
}

export const PylonToastBody: React.FC<Props> = ({
  title,
  body,
  children,
  userError,
  type,
}) => {
  const toastType = type ?? ToastType.Default;

  return (
    <div className={`pylon-toast-body ${toastType}`}>
      <div className="pylon-toast-body__title">{title}</div>
      {body ? <Text type={TextType.Div}>{body}</Text> : children}
      {toastType === ToastType.Error && !userError && (
        <div style={{ marginTop: "1rem" }}>
          Pylon saw that you experienced this error and we're working on it
        </div>
      )}
    </div>
  );
};
