import qs from "qs";
import { AccountTransactionContextProvider } from "components/contexts/account-transaction-context";
import { CashflowStatement } from "components/features/dashboard/components/cashflow-statements";
import { format, parse } from "date-fns";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { TransactionMonthSelector } from "components/features/dashboard/components/transaction-month-selector";

export const CashflowStatementPage: React.FC = () => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  const month = query["month"] as string;
  let initialMonth = format(new Date(), "yyyy-MM");
  if (parse(month, "yyyy-MM", new Date())) {
    // Check if url param is vaalid
    initialMonth = month;
  }
  return (
    <AccountTransactionContextProvider initialMonth={initialMonth}>
      <PageWrapper
        header={<PageTitle title="Monthly Cashflow Statement" />}
        actions={<TransactionMonthSelector />}
      >
        <CashflowStatement />
      </PageWrapper>
    </AccountTransactionContextProvider>
  );
};
