import * as React from "react";
import { DayFiltering } from "components/features/dashboard/components/investments-summary-section/models";
import { getEnumKeyByEnumValue } from "core/utils/typescript-helpers";
import { usePreferenceSettingStringEnum } from "core/hooks/user-preference-setting";
import { useQuery } from "@apollo/client";
import { UserPreferenceSettingEnum } from "core/queries/user-preference-settings";
import {
  FetchPortfolioNetworthHistoryResponse,
  FETCH_PORTFOLIO_NETWORTH_HISTORY,
  NetworthHistory,
} from "core/queries/overview";

export enum OwnerTypeKey {
  Me = "me",
  Spouse = "spouse",
  Any = "shared",
}

export enum DataTypeFiltering {
  All = "All Investments",
  Everything = "Detailed Accounts",
  IndividualBrokerages = "Brokerages",
  RealEstate = "Real Estate",
  PrivateStock = "Private Stock",
}

export enum ChangeTypeFiltering {
  Value = "Show Value Change",
  Percent = "Show Percent Change",
}

export const investmentTypeToValue = (
  dataType: DataTypeFiltering,
  data?: NetworthHistory
) => {
  if (!data) {
    return 0;
  }

  switch (dataType) {
    case DataTypeFiltering.PrivateStock:
    case DataTypeFiltering.IndividualBrokerages:
      return data.assets;
    case DataTypeFiltering.RealEstate:
      return data.networth;
    default:
      return data.networth;
  }
};

export interface InvestmentSummaryContextValue {
  ownerTypeForFiltering: string;

  days: DayFiltering;
  setDays: (day: DayFiltering) => void;

  ownerType: OwnerTypeKey;
  setOwnerType: (ownerType: OwnerTypeKey) => void;

  dataType: DataTypeFiltering;
  setDataType: (dataType: DataTypeFiltering) => void;

  changeType: ChangeTypeFiltering;
  setChangeType: (dataType: ChangeTypeFiltering) => void;

  portfolioData?: FetchPortfolioNetworthHistoryResponse;
}

interface Props {
  children: React.ReactNode;
}

export const InvestmentSummaryContext =
  React.createContext<InvestmentSummaryContextValue>({
    ownerType: OwnerTypeKey.Me,

    days: DayFiltering.ThirtyDays,
    setDays: (day: DayFiltering) => null,

    ownerTypeForFiltering: "my",
    setOwnerType: () => null,

    dataType: DataTypeFiltering.Everything,
    setDataType: () => null,

    changeType: ChangeTypeFiltering.Value,
    setChangeType: () => null,

    portfolioData: undefined,
  });

export const InvestmentSummaryContextProvider: React.FC<Props> = (
  props: Props
) => {
  const [ownerType, setOwnerType] = usePreferenceSettingStringEnum(
    OwnerTypeKey.Me,
    UserPreferenceSettingEnum.PortfolioHistoryOwnerType
  );
  const [dataType, setDataType] = usePreferenceSettingStringEnum(
    DataTypeFiltering.Everything,
    UserPreferenceSettingEnum.PortfolioHistoryDataType
  );

  const [changeType, setChangeType] = usePreferenceSettingStringEnum(
    ChangeTypeFiltering.Value,
    UserPreferenceSettingEnum.PortfolioHistoryChangeType
  );

  const [days, setDays] = usePreferenceSettingStringEnum(
    DayFiltering.NintetyDays,
    UserPreferenceSettingEnum.PortfolioHistoryDays
  );

  const ownerTypeForFiltering = React.useMemo<string>(() => {
    return ownerType === OwnerTypeKey.Me ? "my" : "spouse";
  }, [ownerType]);

  const { data: portfolioData } =
    useQuery<FetchPortfolioNetworthHistoryResponse>(
      FETCH_PORTFOLIO_NETWORTH_HISTORY,
      {
        fetchPolicy: "network-only",
        variables: {
          ownerType,
          dataType,
          days: getEnumKeyByEnumValue(DayFiltering, days),
        },
      }
    );

  return (
    <InvestmentSummaryContext.Provider
      value={{
        ownerType,
        days,
        setDays,
        ownerTypeForFiltering,
        setOwnerType,
        dataType,
        setDataType,
        changeType,
        setChangeType,
        portfolioData,
      }}
    >
      {props.children}
    </InvestmentSummaryContext.Provider>
  );
};
