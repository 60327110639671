import * as React from "react";
import { CategoryData } from "core/queries/documents";
import { DocumentCategoryType } from "core/models/documents";
import { Icon, IconAsset } from "../../../../core/icons";
import "./styles.css";

export interface Props {
  category: CategoryData;
  scale?: number;
}

export const CategoryIcon: React.FC<Props> = ({ category, scale = 1 }) => {
  const isSharedWithMe = category.sharedWithMe || false;

  const titleIcon = category.logo ? (
    <img
      width={`${40 * scale}px`}
      height={`${40 * scale}px`}
      src={`data:image/png;base64, ${category.logo}`}
      alt="institution logo"
    />
  ) : category.type === DocumentCategoryType.Account ? (
    <Icon
      width={`${32 * scale}px`}
      height={`${32 * scale}px`}
      asset={IconAsset.Account}
    />
  ) : (
    <Icon
      width={`${32 * scale}px`}
      height={`${32 * scale}px`}
      asset={IconAsset.Document}
    />
  );

  return (
    <div
      className="category__icon "
      style={{
        height: `${3 * scale}rem`,
        width: `${3 * scale}rem`,
        borderRadius: `${0.75 * scale}rem`,
        position: "relative",
        backgroundColor: "var(--bg-01)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {titleIcon}
        {isSharedWithMe && <div className="row-card__shared-indicator" />}
      </div>
    </div>
  );
};
