import React, { useContext, useEffect, useState } from "react";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { EMAIL_REGEX } from "core/utils";
import { Icon, IconAsset } from "components/core/icons";
import { Input } from "components/core/input";
import { LeafButton } from "components/core/leaf-button";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SessionContext } from "components/contexts/session-context-provider";
import { Text, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { TwoFactorAuthComponent } from "components/features/login/components/2fa";
import { useHistory } from "react-router";
import "./styles.css";
import {
  loggedInUserRequestEmailReset,
  loggedInUserResetEmail,
  Request2FAVerificationResponse,
} from "core/api";

export const EmailReset: React.FC = () => {
  const history = useHistory();
  const { setSessionToken, sessionToken } = useContext(SessionContext.context);

  const [resetRequest, setResetRequest] =
    useState<Request2FAVerificationResponse>();
  const [resetToken, setResetToken] = useState<string>();
  const [email, setEmail] = React.useState("");
  const [inflight, setInflight] = React.useState(false);

  useEffect(() => {
    if (resetRequest) {
      return;
    }
    loggedInUserRequestEmailReset(
      sessionToken,

      (data) => {
        if (!data) {
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={`An unexpected error occurred. Please refresh the page and try again`}
              type={ToastType.Error}
            />
          );
          return;
        }
        setResetRequest(data);
      },
      (err) => {
        console.error("Error loading user info to reset email ", err);
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={`An unexpected error occurred. Please refresh the page and try again`}
            type={ToastType.Error}
          />
        );
      }
    );
  }, [sessionToken, resetRequest]);

  const isValidEmail = EMAIL_REGEX.test(email);

  const resetUserEmail = () => {
    if (!resetToken) {
      return;
    }

    if (!isValidEmail) {
      return;
    }

    if (inflight) {
      return;
    }

    setInflight(true);

    loggedInUserResetEmail(
      email,
      sessionToken,
      resetToken,

      async (data) => {
        setInflight(false);

        if (!data?.authToken) {
          console.error("Email reset failed", data?.message);
          toast(
            <PylonToastBody
              title={"Email Reset failed"}
              body={
                data?.message ?? `An error occurred. Please try again later`
              }
              type={ToastType.Error}
            />
          );
          return;
        }
        setSessionToken(data.authToken);
        toast(
          <PylonToastBody
            title={"Email Reset"}
            body={`Email reset successfully`}
          />
        );

        history.push(
          `${DashboardPages.Settings}?refresh=${new Date().getTime()}`
        );
      },
      (err) => {
        setInflight(false);

        toast(
          <PylonToastBody
            title={"Email Reset failed"}
            body={`An error occurred. Please try again later`}
            type={ToastType.Error}
          />
        );
      }
    );
  };

  if (!resetRequest) {
    return <div>Loading information...</div>;
  }
  if (!resetToken) {
    return (
      <div className="email-reset-page-border">
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => history.push(DashboardPages.Settings)}
        >
          {" "}
          <div
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Icon
              asset={IconAsset.Back}
              colour={Colour.Navy}
              height="24px"
              width="24px"
            />
          </div>
        </div>
        <TwoFactorAuthComponent
          noHeader
          title="Two Factor Verification"
          res={resetRequest}
          onCodeVerified={(rawData) => {
            const data = rawData;
            if (!data?.identityToken) {
              console.error("Reset request errored, ", data?.message);
              toast(
                <PylonToastBody
                  title={"Information load failed"}
                  body={
                    data?.message ??
                    `An error occurred. Please refresh the page and try again`
                  }
                  type={ToastType.Error}
                />
              );
              return;
            }
            setResetToken(data.identityToken);
          }}
        />
      </div>
    );
  }

  return (
    <div className="email-reset-page-border">
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={() => history.push(DashboardPages.Settings)}
      >
        <Icon asset={IconAsset.LeftArrow} height="32px" width="32px" />
      </div>
      <div className="email-reset-page-wrapper">
        <div className="email-reset-page-field">
          <div
            className="label"
            style={{
              width: "unset",
            }}
          >
            Enter your new email
          </div>

          <div className="field-data ">
            <Input
              autoFocus
              type="email"
              placeholder="New Email"
              autoComplete="chrome-off"
              value={email}
              onEnter={() => {
                resetUserEmail();
              }}
              onChange={(e) => setEmail(e.target.value)}
              onBlurValidate={[
                {
                  inputRegex: EMAIL_REGEX,
                  errorMessage: "Please enter a valid email address",
                },
              ]}
              required
            />
          </div>
        </div>
        <div
          style={{
            padding: "1rem 0rem",
          }}
        >
          <Text type={TextType.Div}>
            An email will be sent to the new address upon reset. Check your spam
            folder if you do not see it
          </Text>
        </div>

        <LeafButton
          onClick={resetUserEmail}
          disabled={!isValidEmail || inflight}
          loading={inflight}
        >
          {"Set new email"}
        </LeafButton>
      </div>
    </div>
  );
};
