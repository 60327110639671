import { AccountGroup } from "components/features/dashboard/components/personal-balance-sheet/models";
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View
  } from "@react-pdf/renderer";
import { format } from "date-fns";
import { toMoneyString } from "core/utils";

export interface Props {
  assets: AccountGroup[];
  liabilities: AccountGroup[];
  assetsTotal: number;
  liabilitiesTotal: number;
}

export const BalanceSheetPDF: React.FC<Props> = ({
  assets,
  liabilities,
  assetsTotal,
  liabilitiesTotal,
}) => {
  const timestamp = format(new Date(), "MMMM dd, yyyy");
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Personal Balance Sheet</Text>
        <Text style={styles.subtitle}>{timestamp}</Text>
        <Text style={styles.rowTitle}>Assets</Text>
        <View style={styles.table}>
          {assets.map((r) => {
            return (
              <>
                <TitleRow group={r.group} />
                <div>
                  {r.accounts.accounts.map((acc, i) => {
                    return (
                      <PDFRow i={i} category={acc.name} value={acc.amount} />
                    );
                  })}
                </div>
              </>
            );
          })}
        </View>
        <SummaryRow text="Total Asset" value={assetsTotal} />
        <Text style={styles.paddingTop8} />
        <Text style={[styles.rowTitle]}>Liabilities</Text>
        <View style={styles.table}>
          {liabilities.map((r) => {
            return (
              <>
                <TitleRow group={r.group} />
                <div>
                  {r.accounts.accounts.map((acc, i) => {
                    return (
                      <PDFRow i={i} category={acc.name} value={acc.amount} />
                    );
                  })}
                </div>
              </>
            );
          })}
        </View>
        <SummaryRow text="Total Liability" value={liabilitiesTotal} />
        <Text style={styles.paddingTop8} />
        <View style={[styles.rowSummary, styles.borderTop]}>
          <Text style={[styles.cell, styles.resultTitle]}>Net Worth</Text>
          {numberCell(assetsTotal - liabilitiesTotal)}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 32,
    paddingBottom: 65,
    paddingHorizontal: 35,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 32,
  },
  rowTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    backgroundColor: "#EEEEEE",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    alignItem: "center",
    flexWrap: "wrap",
    alignItems: "stretch",
    paddingRight: "8px",
    marginLeft: "16px",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    paddingRight: "8px",
    backgroundColor: "#E2E2E2",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  cell: {
    paddingLeft: "8px",
    paddingTop: "4px",
    paddingBottom: "4px",
    borderColor: "#000000",
    borderStyle: "solid",
  },
  description: {
    flexGrow: 1,
    flexShrink: 1,
    color: "#536882",
  },
  border: {
    flexGrow: 1,
    flexShrink: 1,
  },
  number: {
    fontFamily: "Helvetica",
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    textAlign: "right",
  },
  positive: {
    color: "#57ba8d",
  },
  negative: {
    color: "#f35151",
  },
  rowAlter: {
    backgroundColor: "#EEEEEE",
  },
  rowSummary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    paddingRight: "8px",
  },
  summaryTitle: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
  },
  resultTitle: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  borderTop: {
    borderTop: "1px solid #000000",
  },
  placeHolder: {},
  paddingTop8: {
    paddingTop: 8,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const SummaryRow: React.FC<{
  text: string;
  value: number;
}> = ({ text, value }) => {
  return (
    <View style={[styles.rowSummary]}>
      <Text style={[styles.cell, styles.description, styles.summaryTitle]}>
        {text}
      </Text>
      {numberCell(value, true)}
    </View>
  );
};

const TitleRow: React.FC<{ group: string }> = ({ group }) => {
  return (
    <View style={[styles.titleRow]}>
      <Text style={[styles.cell, styles.description]}>{group}</Text>
    </View>
  );
};

const PDFRow: React.FC<{
  i: number;
  category: string;
  value: number;
}> = ({ i, category, value }) => {
  return (
    <View style={i % 2 === 1 ? [styles.row] : [styles.row, styles.rowAlter]}>
      <Text style={[styles.cell, styles.description]}>{category}</Text>
      {numberCell(value)}
    </View>
  );
};

const numberCell = (n: number, borderTop?: boolean) => {
  return (
    <Text
      style={[
        styles.number,
        styles.cell,
        // n < 0 ? styles.negative : styles.positive,
        borderTop ? styles.borderTop : styles.placeHolder,
      ]}
    >
      {toMoneyString(n)}
    </Text>
  );
};
