import * as React from "react";
import { Account } from "../../../../../core/models";
import { Icon, IconAsset } from "../../../../core/icons";
import "./styles.css";
import {
  AccountColorHex,
  AccountType,
  getColorForType,
} from "../add-account-tray/models";
export interface Props {
  account: Account;
  scale?: number;
  backgroundColor?: string;
}

export const AccountIcon: React.FC<Props> = ({
  account,
  scale = 1,
  backgroundColor,
}) => {
  const titleIcon = account.logo ? (
    <img
      width={`${40 * scale}px`}
      height={`${40 * scale}px`}
      src={`data:image/png;base64, ${account.logo}`}
      alt="institution logo"
    />
  ) : (
    <Icon
      width={`${22 * scale}px`}
      height={`${22 * scale}px`}
      asset={IconAsset.Account}
      colour={
        backgroundColor || AccountColorHex[account.type as AccountType].Primary
      }
    />
  );

  return (
    <div
      className="account__icon"
      style={{
        height: `${3 * scale}rem`,
        width: `${3 * scale}rem`,
        borderRadius: `${0.75 * scale}rem`,
        position: "relative",
        marginRight: "1rem",
        backgroundColor: account?.logo
          ? backgroundColor || account.primaryColorHex
          : getColorForType(account.type as AccountType).Light,
      }}
    >
      {titleIcon}
    </div>
  );
};
