import React from "react";
import { Account, Colour } from "core/models";
import { AssetType } from "components/features/dashboard/models/sharing";
import { ConfirmationModal } from "components/core/modal";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { deleteSharedDataByAssetMutation } from "core/queries/shared-data";
import { Icon, IconAsset } from "components/core/icons";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { Text, TextStyle, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

export interface Props {
  account: Account;
  onRemove?: () => void;
}

export const RemoveAccountQuickAction: React.FC<Props> = ({
  account,
  onRemove,
}) => {
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [deleteSharedDataByAsset, { loading }] = useMutation(
    deleteSharedDataByAssetMutation,
    {
      onCompleted: () => {
        toast(
          <PylonToastBody
            title={"Access removed"}
            body={"Access has been removed"}
          />
        );
        setShowConfirm(false);
        onRemove?.();
      },
      refetchQueries: onUpdateRefetchQueries,
    }
  );

  // Don't show the button if user is the owner
  if (account.isOwner) {
    return null;
  }

  return (
    <>
      <ConfirmationModal
        defaultStyling
        children={`Do you want to remove your access to this account?`}
        showModal={showConfirm}
        actionButtonText={"Delete"}
        actionButtonLoading={loading}
        onAction={() => {
          deleteSharedDataByAsset({
            variables: {
              id: account.account_id,
              type: AssetType.Account,
            },
          });
        }}
        onCancel={() => {
          setShowConfirm(false);
        }}
        onClose={() => {
          setShowConfirm(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <div
        id="remove-my-access"
        key="remove-my-access"
        className="flex-row align-center"
        onClick={() => {
          setShowConfirm(true);
        }}
        style={{
          cursor: "pointer",
          gap: "0.5rem",
        }}
      >
        <Icon
          asset={IconAsset.Disconnect}
          width="16px"
          height="16px"
          colour={Colour.Black}
        />
        <Text style={TextStyle.M15} type={TextType.Div}>
          Remove my access
        </Text>
      </div>
    </>
  );
};
