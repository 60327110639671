import React from "react";
import { DashboardPages } from "components/features/dashboard/pages";
import "./styles.css";

export interface Props {
  redirectTo?: string;
  noRedirect?: boolean;
  onClick?: () => void;
  scale?: number;
  inverse?: boolean;
}

export const Logo: React.FC<Props> = ({
  redirectTo,
  noRedirect,
  onClick,
  scale = 1,
  inverse = false,
}) => {
  const logo = (
    <div className={inverse ? "inverse-logo" : "logo"}>
      Pylon<div className="early-access">Early Access</div>
    </div>
  );

  return (
    <div
      className="pylon-logo"
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {noRedirect ? (
        logo
      ) : (
        <a href={redirectTo || DashboardPages.Summary}>{logo}</a>
      )}
    </div>
  );
};
