import React from "react";
import { TextD, TextProps, TextStyle } from "components/core/text";
import "./styles.css";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface PublicProps {
  id: string;
  label: string;
  checked?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  labelProps?: TextProps;
}

export type Props = PublicProps & InputProps;

export const Checkbox: React.FC<Props> = ({
  id,
  label,
  checked,
  onChange,
  labelProps,
}) => {
  return (
    <div className="pylon-input__checkbox">
      <input id={id} type={"checkbox"} checked={checked} onChange={onChange} />
      <label htmlFor={id} className="pylon-input__label">
        <span />
        <TextD style={TextStyle.M15} {...labelProps}>
          {label}
        </TextD>
      </label>
    </div>
  );
};
