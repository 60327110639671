import { ConfirmationModalOld } from "components/core/modal";
import { Icon, IconAsset } from "components/core/icons";
import { useState } from "react";

export interface Props {
  name: string;
  onConfirm: () => void;
}

export const DeleteCategoryOrRuleButton: React.FC<Props> = ({
  name,
  onConfirm,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        onAction={() => {
          onConfirm();
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
        actionButtonText="Delete"
      >
        Are you sure you want to delete {name}?
        <br />
        <br />
      </ConfirmationModalOld>

      <div
        onClick={() => setShowModal(true)}
        style={{
          flex: "0 0",
          cursor: "pointer",
        }}
      >
        <Icon asset={IconAsset.Trashcan} width="24px" height="24px" />
      </div>
    </>
  );
};
