import React, { useContext } from "react";
import { EMAIL_REGEX, formatMobileNumber, PHONE_REGEX } from "core/utils";
import { Input } from "components/core/input";
import { TrustedSourceContext } from "components/contexts/trusted-source-context";
import { useQuery } from "@apollo/client";
import {
  FetchMyCollaboratorsResponse,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";

interface ControlProps {
  valid: boolean;
  goNext: () => void;
}

export const AddTrustedContactContactInfo: React.FC<ControlProps> = ({
  valid,
  goNext,
}) => {
  const { state, updateState } = useContext(TrustedSourceContext);
  const { data } = useQuery<FetchMyCollaboratorsResponse>(
    FETCH_MY_COLLABORATORS,
    {
      fetchPolicy: "cache-first",
    }
  );
  return (
    <div>
      <div>
        <div className="add-account-tray__step-action">
          <h5>
            What is your collaborator's contact information
            <span className="text-highlight-blue">?</span>
            <br />
            <span className="text-highlight-blue">＿</span>
          </h5>
        </div>
        <div>
          <Input
            autoFocus
            type="email"
            id="email"
            name="email"
            placeholder="Email address"
            value={state.email}
            required
            onEnter={() => {
              if (valid) {
                goNext();
              }
            }}
            onChange={(e) =>
              updateState({
                email: e.target.value,
              })
            }
            onBlurValidate={[
              {
                inputRegex: EMAIL_REGEX,
                errorMessage: "Please enter a valid email address",
              },
              {
                validationFunc: (s) =>
                  !data?.collaborations?.find((v) => v.recipient?.email === s),
                errorMessage: "This user is already a contact",
              },
            ]}
          />
        </div>

        <div className="mt1">
          <Input
            type="tel"
            id="mobileNumber"
            name="mobileNumber"
            placeholder="Mobile number"
            required
            value={state.phone}
            onEnter={() => {
              if (valid) {
                goNext();
              }
            }}
            onChange={(e) =>
              updateState({
                phone: formatMobileNumber(e.target.value),
              })
            }
            onBlurValidate={[
              {
                inputRegex: PHONE_REGEX,
                errorMessage: "Please enter a valid phone number",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
