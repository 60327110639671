import * as React from "react";
import classnames from "classnames";
import { Colour } from "core/models";
import { ConfirmationModalDC } from "components/core/modal/confirmation-dc";
import { Icon, IconAsset } from "components/core/icons";
import { TempAccountTypes } from "components/features/dashboard/components/add-account-tray/models";
import { Text, TextType } from "components/core/text";
import "./styles.css";

interface Props {
  existingCategories: string[];
  onAdd: (category: string) => void;
}

export const AccountCategoryAdd: React.FC<Props> = ({
  onAdd,
  existingCategories,
}) => {
  const categories = Object.values(TempAccountTypes);
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);

  return (
    <>
      <ConfirmationModalDC
        width={"30rem"}
        showModal={showAddModal}
        children={
          <div style={{ marginBottom: "2rem" }}>
            <Text
              colour={Colour.Black}
              size="31px"
              lineHeight="40px"
              weight={700}
            >
              Add Account Category
            </Text>
            <div
              style={{
                height: "1px",
                width: "100%",
                borderTop: "1px solid #EEEEEE",
              }}
            ></div>
            {categories.map((e) => {
              const existing = existingCategories.includes(e);
              const itemClasses = classnames({
                "account-category-add-item": !existing,
              });

              return (
                <div
                  key={e}
                  className={itemClasses}
                  style={{
                    borderBottom: "1px solid #EEEEEE",
                    padding: "1rem",
                    cursor: !existing ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (!existing) {
                      onAdd(e);
                      setShowAddModal(false);
                    }
                  }}
                >
                  <Text
                    type={TextType.Div}
                    colour={existing ? Colour.Gray10 : Colour.Black}
                  >
                    {e}
                  </Text>
                </div>
              );
            })}
          </div>
        }
        onAction={() => {
          setShowAddModal(false);
        }}
        actionButtonText={"Add"}
        hideAction={true}
        onCancel={() => {
          setShowAddModal(false);
        }}
        cancelButtonText="Cancel"
      />
      <div
        onClick={() => setShowAddModal(true)}
        className="account-category-add"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 2rem",
          cursor: "pointer",
          marginBottom: "0.5rem",
        }}
      >
        <div
          style={{
            height: "1px",
            borderTop: "1px solid #0029FF",
            flexGrow: 1,
          }}
        ></div>
        <Icon asset={IconAsset.AddCategory} height="32px" width={"42px"} />
        <div
          style={{
            height: "1px",
            borderTop: "1px solid #0029FF",
            flexGrow: 1,
          }}
        ></div>
      </div>
    </>
  );
};
