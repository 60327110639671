import React, { useEffect, useState } from "react";
import { AuthorizedPage } from "components/features/login/components/authorized-page";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { Logo } from "components/core/logo";
import { OnboardingHowDoYouTrack } from "components/features/onboarding/flows/standard/how-do-you-track";
import { OnboardingUnifiedViewPresentation } from "components/features/onboarding/flows/standard/unified-view-presentation";
import { OnboardingWhatElseDoYouWant } from "components/features/onboarding/flows/standard/what-else-do-you-want";
import { OnboardingWhatIsYourMaritalStatus } from "components/features/onboarding/flows/standard/what-is-your-marital-status";
import { SelectionState } from "components/features/onboarding/flows/utils";
import { TaxContextProvider } from "components/contexts/tax-context";
import { TaxOnboardingIncomeEntry } from "components/features/onboarding/flows/taxes/tell-us-income";
import { Text, TextType } from "components/core/text";
import { trackEvent } from "core/distribution/distribution";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import { useMutation, useQuery } from "@apollo/client";
import { WelcomePage } from "components/features/onboarding/flows/standard/welcome";
import "../../styles.css";
import {
  completeMutation,
  OnboardingState,
  OnboardingStateQuery,
  SaveOnboardingPreferences,
} from "core/queries/onboarding";
import {
  makeHeight100Percent,
  makeHeight100VH,
  scrollToTop,
} from "core/utils/scrolling";

const MAX_PAGES = 5;

export const TaxesOnboardingPage: React.FC = () => {
  const mobileLayout = useMobileLayout();
  const { data, loading } = useQuery<OnboardingState>(OnboardingStateQuery);
  const [state, setState] = useState<SelectionState>({});
  const [pageState, setPageState] = useState<number>(0);
  const history = useHistory();

  const [saveOnboardingPreference] = useMutation(SaveOnboardingPreferences, {
    refetchQueries: [{ query: OnboardingStateQuery }],
  });
  const [completeOnboardingMutation] = useMutation(completeMutation, {
    variables: { flow: "tax" },
  });

  const optimisticUpdate = (
    dataKey: string,
    selected: boolean,
    // optionally disable keys, used for select
    keysToMakeFalse?: string[]
  ) => {
    const mutationObj = {
      [dataKey]: selected,
    };

    if (keysToMakeFalse) {
      for (let k of keysToMakeFalse) {
        mutationObj[k] = false;
      }
    }

    saveOnboardingPreference({
      variables: {
        prefs: Object.keys(mutationObj).map((k) => ({
          key: k,
          value: mutationObj[k],
        })),
      },
    });
    setState({
      ...state,
      ...mutationObj,
    });
  };

  useEffect(() => {
    if (!loading && data) {
      setState(JSON.parse(data.onboardingState.preferences));
    }
  }, [loading, data]);

  useEffect(() => {
    scrollToTop();
    if (pageState === 8) {
      makeHeight100Percent();
    } else {
      makeHeight100VH();
    }
  }, [pageState]);

  const updatePageState = (i: number) => {
    setPageState(i);
    trackEvent(
      `Tax Onboarding ${i}`,
      data?.onboardingState?.preferences
        ? JSON.parse(data?.onboardingState?.preferences)
        : {}
    );
  };

  const renderPage = () => {
    switch (pageState) {
      case 0:
        return (
          <WelcomePage
            state={state}
            optimisticUpdate={optimisticUpdate}
            onNext={() => {
              updatePageState(1);
            }}
          />
        );
      case 1:
        return (
          <OnboardingHowDoYouTrack
            state={state}
            optimisticUpdate={optimisticUpdate}
          />
        );
      case 2:
        return (
          <OnboardingWhatElseDoYouWant
            titleElement={
              <div>
                What do you want to{" "}
                <span style={{ color: "var(--blue-01)" }}>track</span>,{" "}
                <span style={{ color: "var(--blue-01)" }}>share</span>, and{" "}
                <span style={{ color: "var(--blue-01)" }}>plan?</span>
              </div>
            }
            state={state}
            optimisticUpdate={optimisticUpdate}
          />
        );

      case 3:
        return <OnboardingUnifiedViewPresentation />;
      case 4:
        return (
          <OnboardingWhatIsYourMaritalStatus
            state={state}
            optimisticUpdate={optimisticUpdate}
          />
        );
      case 5:
        return <TaxOnboardingIncomeEntry onboardingState={state} />;

      // case 6:
      // return <TaxOnboardingMoreIncomeDetails />;
      // case 7:
      // return <TaxOnboardingAddBrokerage />;
      // case 8:
      //   return <TaxOnboardingCapitalGainsCalculations />;
      // case 9:
      default:
        return (
          <WelcomePage state={state} optimisticUpdate={optimisticUpdate} />
        );
    }
  };

  const complete = () => {
    makeHeight100VH();
    completeOnboardingMutation();

    history.push({
      pathname: DashboardPages.IncomeAndTaxes,
      search: "ft=",
    });
  };

  const footerStyle: React.CSSProperties = mobileLayout
    ? {
        marginTop: "1rem",
        width: "100%",
        textAlign: "center",
        fontStyle: "italic",
        marginBottom: "2rem",
      }
    : {
        position: "absolute",
        marginTop: "4rem",
        fontStyle: "italic",
      };

  return (
    <AuthorizedPage>
      <TaxContextProvider>
        <div className="onboarding-page__bg-wrapper">
          {mobileLayout && (
            <div style={{ padding: "2vh 4vw" }}>
              <Logo />
            </div>
          )}
          <div className="onboarding-page-wrapper">
            {renderPage()}
            <div
              className="onboarding-bottom"
              style={{
                height: mobileLayout ? "10vh" : "15vh",
                flexDirection: mobileLayout ? "column" : "row",
              }}
            >
              {pageState > 0 && (
                <div className="onboarding-bottom">
                  <div
                    id={`previous-step-${pageState}`}
                    style={{
                      height: "3.5rem",
                      width: "3.5rem",
                      marginRight: "0.5rem",
                      color: "var(--text-03)",
                      border: "2px solid var(--separator-02)",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "10px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => updatePageState(pageState - 1)}
                  >
                    <Icon
                      asset={IconAsset.BackButton}
                      height={"19px"}
                      width={"11px"}
                    />
                  </div>
                  <LeafButton
                    id={`next-step-${pageState}`}
                    fullWidth={mobileLayout ? true : false}
                    onClick={() => {
                      pageState < MAX_PAGES
                        ? updatePageState(pageState + 1)
                        : complete();
                    }}
                  >
                    {pageState < MAX_PAGES ? "Next Step" : "Get Started"}
                  </LeafButton>
                </div>
              )}
              {pageState === 2 && (
                <div style={footerStyle}>
                  <Text type={TextType.Div}>
                    * Coming soon or in development!
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </TaxContextProvider>
    </AuthorizedPage>
  );
};
