import * as React from "react";
import { Logo } from "components/core/logo";

export const ErrorDisplay: React.FC = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div
      style={{
        height: "6rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Logo />
    </div>
    <div
      style={{
        margin: "0 3rem",
        marginTop: "-6rem",

        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <h4>Pylon saw that you experienced an error and we're working on it.</h4>
    </div>
  </div>
);
