import { ConfirmationModal } from "components/core/modal";
import { StepProgressIDs } from "components/features/dashboard/components/financial-plan-table/financial-plan-table";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

interface Props {
  showModal: boolean;
  onClose: () => void;
  complete: boolean;
}

export const FinancialPlanStepModal: React.FC<Props> = ({
  showModal,
  onClose,
  complete,
  children,
}) => {
  return (
    <div>
      <ConfirmationModal
        width={"512px"}
        showModal={showModal}
        onClose={onClose}
      >
        <div
          className="flex-column"
          style={{
            overflow: "auto",
          }}
        >
          {children}
        </div>
      </ConfirmationModal>
    </div>
  );
};

interface ButtonsProps {
  step: StepProgressIDs;
  onClose: () => void;
  onNext: () => void;
}
export const FinancialPlanStepModalButtons: React.FC<ButtonsProps> = ({
  step,
  onClose,
  onNext,
}) => {
  return (
    <div
      style={{
        marginTop: "1.5rem",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <LeafButton
        id={`financial-plan-step-modal-${step}-cancel`}
        buttonStyle={LeafButtonStyle.LeafSmall}
        buttonColour={LeafButtonColour.Transparent}
        onClick={onClose}
      >
        Cancel
      </LeafButton>
      <LeafButton
        id={`financial-plan-step-modal-${step}-next`}
        buttonStyle={LeafButtonStyle.LeafSmall}
        buttonColour={LeafButtonColour.Black}
        onClick={onNext}
      >
        Next →
      </LeafButton>
    </div>
  );
};
