import * as React from "react";
import { handlePlaidError } from "core/queries/accounts";
import { PlaidWrapperContext } from "components/features/dashboard/components/plaid-wrapper/context/plaid-wrapper-context";
import { trackEvent } from "core/distribution/distribution";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  PlaidLinkOnEventMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";

interface Props {
  linkToken: string;
  oauthRedirectURI?: string;

  onSuccess: (token: string, metadata: PlaidLinkOnSuccessMetadata) => void;
  onExit: () => void;
}

export const PlaidLinkWrapper: React.FC<Props> = ({
  linkToken,
  oauthRedirectURI,
  onExit,
  onSuccess,
}) => {
  const {
    setShouldExit,
    institutionErrored,
    setInstitutionErrored,
    linkError,
    setLinkError,
  } = React.useContext(PlaidWrapperContext);
  const [handlePlaidLinkError] = useMutation(handlePlaidError);

  const cleanupPlaidIFrame = () => {
    try {
      // Force clean up possible undeleted iframe by plaid
      // Note as of react-plaid-link 3.2.1, the iframe is not properly destroyed on unmount on Safari 14.1.2.
      // The plaid iframe forces focus on itself, which creates all sorts of problem such as input element not usable.
      // The code here attempts to remove any left over iframes created by plaid link.
      //
      // We may not need this code in future when plaid/Safari fixes the issue.
      const allIFrames = document.querySelectorAll("iframe") ?? [];
      allIFrames.forEach((d) => {
        if ((d?.id ?? "").startsWith("plaid-link-iframe")) {
          d?.remove();
        }
      });
    } catch (err) {
      console.error("caught error cleaning up plaid", err);
    }
  };

  const postExit = React.useCallback(() => {
    cleanupPlaidIFrame();

    if (
      !institutionErrored &&
      !linkError &&
      institutionErrored !== "" &&
      linkError !== ""
    ) {
      setShouldExit(true);
    }
  }, [institutionErrored, linkError, setShouldExit]);

  const config: PlaidLinkOptions = {
    token: linkToken,
    receivedRedirectUri: oauthRedirectURI,
    onSuccess: (token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      onSuccess(token, metadata);
      setLinkError("");
      setInstitutionErrored("");

      cleanupPlaidIFrame();
    },
    onEvent: (eventName: string, metadata: PlaidLinkOnEventMetadata) => {
      trackEvent("plaid-event", {
        eventName,
        ...metadata,
      });
      if (
        eventName === "ERROR" ||
        metadata.error_code === "institution-poor-health-error"
      ) {
        handlePlaidLinkError({
          variables: {
            linkSessionID: metadata.link_session_id,
            institutionID: metadata.institution_id,
            error: metadata.error_code,
            requestID: metadata.request_id,
            eventName,
          },
        });
        if (metadata.error_code !== "INVALID_CREDENTIALS") {
          setLinkError(metadata?.error_code || "");
          setInstitutionErrored(metadata?.institution_id || "");
        }
      }
    },
    onExit: () => postExit(),
  };

  const { open, ready } = usePlaidLink(config);

  // this opens link as soon as it's ready
  useEffect(() => {
    if (!ready) {
      return;
    }
    open();
  }, [ready, open]);

  // don't render anything, just open Link
  return null;
};
