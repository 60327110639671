import React from "react";
import { Account, Colour } from "core/models";
import { FETCH_INVESTMENT_HOLDINGS } from "core/queries/investments";
import { Icon, IconAsset } from "components/core/icons";
import { IntegrationSource } from "components/features/dashboard/models/integration-source";
import { LoadingAnimation } from "components/features/dashboard/components/manage-menu-option";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { refreshAccountMutation } from "core/queries/accounts";
import { SessionContext } from "components/contexts/session-context-provider";
import { TextD, TextStyle } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

const REFRESH_SUPPORTED_SOURCES: string[] = [
  IntegrationSource.Plaid,
  IntegrationSource.Coinbase,
  IntegrationSource.CoinbasePro,
];

export interface Props {
  account: Account;
}

export const RefreshAccountQuickAction: React.FC<Props> = ({ account }) => {
  const { userID } = React.useContext(SessionContext.context);

  const [refreshAccount, { loading: refreshLoading }] = useMutation(
    refreshAccountMutation,
    {
      variables: {
        id: account.account_id,
        integrationSource: account.integration_source,
      },
      refetchQueries: [
        {
          query: FETCH_INVESTMENT_HOLDINGS,
          variables: { accountID: account?.id },
        },
      ],
      onCompleted: () => {
        toast(
          <PylonToastBody
            title={"Account successfully updated"}
            body={
              "We've refreshed your account to show the latest information!"
            }
          />
        );
      },
    }
  );

  if (
    account.owner?.id !== userID ||
    !REFRESH_SUPPORTED_SOURCES.includes(account.integration_source ?? "") ||
    account.loginRequired
  ) {
    return null;
  }

  return (
    <div
      key="refresh-account"
      id="refresh-account"
      className="flex-row align-center"
      style={{
        cursor: "pointer",
        gap: "0.5rem",
      }}
      onClick={() => {
        if (!refreshLoading) {
          refreshAccount();
        }
      }}
    >
      <div
        className={
          refreshLoading ? LoadingAnimation.SpinLeftToRight : undefined
        }
      >
        <Icon
          asset={IconAsset.RefreshAccount}
          width="16px"
          height="16px"
          colour={Colour.Black}
        />
      </div>
      <TextD style={TextStyle.M15}>Refresh</TextD>
    </div>
  );
};
