import Toggle from "react-toggle";
import { AccountShareList } from "components/features/dashboard/components/manage-collaborator/account-share-list";
import { AGE_REGEX } from "core/utils";
import { Colour } from "core/models";
import { ContactInfo } from "core/queries/contact-info";
import { default as React, MutableRefObject } from "react";
import { EditContactRelationshipSelector } from "components/features/dashboard/pages/contacts/components/relationship-select";
import { Input } from "components/core/input";
import { parseInt } from "lodash";
import { PylonTooltip, TooltipIconSize } from "components/core/tooltip";
import { StateDropdown } from "components/features/dashboard/pages/settings/address/components/state-dropdown";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs
  } from "react-tabs";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import "./styles.css";
import {
  Collaboration,
  UpdateCollaborationMutationInput,
} from "core/queries/collaborations";

export interface Props {
  collaborator: Collaboration;
  updateInput: MutableRefObject<Partial<UpdateCollaborationMutationInput>>;
  onUpdateInput: (input: Partial<UpdateCollaborationMutationInput>) => void;
  triggerUpdate: () => void;
}

export const ManageCollaboratorTrayBody: React.FC<Props> = ({
  collaborator,
  updateInput,
  onUpdateInput,
  triggerUpdate,
}) => {
  const canEditCollaboratorDetails = !collaborator.recipient.isPhoneVerified;

  const [state, setStatePrivateDoNotCallDirectly] = React.useState({
    ...collaborator,
  });

  const setState = (newInfo: Partial<UpdateCollaborationMutationInput>) => {
    const ret = {
      ...state,
      ...newInfo,
    };

    const newInput: Partial<UpdateCollaborationMutationInput> = {
      ...updateInput.current,
      ...newInfo,
    };

    if (newInfo.contactInfo) {
      ret.contactInfo = {
        ...state.contactInfo,
        ...newInfo.contactInfo,
      };

      newInput.contactInfo = {
        ...updateInput?.current?.contactInfo,
        ...newInfo.contactInfo,
      };
    }

    setStatePrivateDoNotCallDirectly({
      ...(ret as Collaboration), // Force conversion needed due to type script not recognizing contact info + partial contact info as ContactInfo
    });
    onUpdateInput(newInput);
  };

  const onEnter = () => triggerUpdate();
  return (
    <div>
      <Tabs>
        <TabList className="collaborator-edit-tab-list">
          <Tab
            className="collaborator-edit-tab"
            selectedClassName="collaborator-edit-tab__selected"
          >
            Share Access
          </Tab>
          <Tab
            className="collaborator-edit-tab"
            selectedClassName="collaborator-edit-tab__selected"
          >
            User Information
          </Tab>
        </TabList>

        <TabPanel>
          <div>
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                marginTop: "1rem",
              }}
            ></div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    type={TextType.Div}
                    style={TextStyle.O10}
                    format={TextFormat.UpperCase}
                  >
                    Account
                  </Text>
                  <Text
                    type={TextType.Div}
                    style={TextStyle.O10}
                    format={TextFormat.UpperCase}
                  >
                    Share Status
                  </Text>
                </div>

                <div
                  style={{
                    padding: "1rem 0",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Auto Share All Future Accounts
                  </Text>
                  <div
                    style={{
                      paddingRight: "0.5rem",
                    }}
                  />
                  <Toggle
                    icons={false}
                    checked={state.autoShareNewAccount}
                    onChange={() => {
                      setState({
                        autoShareNewAccount: !state.autoShareNewAccount,
                      });
                      setTimeout(() => triggerUpdate(), 10);
                    }}
                  />
                </div>

                <AccountShareList collaborator={collaborator} />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div>
            {!canEditCollaboratorDetails && (
              <>
                <div style={{ minWidth: "21rem" }}>
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Name
                  </Text>
                </div>
                <div>
                  {collaborator?.recipient?.firstName}{" "}
                  {collaborator?.recipient?.lastName}
                </div>
                <div style={{ minWidth: "21rem" }} className="mt1">
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Email
                  </Text>
                </div>
                <div>{collaborator?.recipient?.email || ""}</div>
              </>
            )}

            {canEditCollaboratorDetails && (
              <div className="flex-column gap-half">
                <div style={{ minWidth: "21rem" }}>
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Name
                  </Text>
                </div>
                <div>
                  <Input
                    type="firstName"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    value={state.contactInfo?.firstName}
                    onEnter={onEnter}
                    onChange={(e) => {
                      setState({
                        contactInfo: {
                          firstName: e.currentTarget.value,
                        },
                      });
                    }}
                    onBlurValidate={[
                      {
                        validationFunc: (v) => v !== "",
                        errorMessage: "Please enter a valid first name",
                      },
                    ]}
                  />
                </div>
                <div>
                  <Input
                    type="lastName"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    value={state.contactInfo?.lastName}
                    onEnter={onEnter}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.currentTarget.blur();
                      }
                    }}
                    onChange={(e) => {
                      setState({
                        contactInfo: {
                          lastName: e.currentTarget.value,
                        },
                      });
                    }}
                    onBlurValidate={[
                      {
                        validationFunc: (v) => v !== "",
                        errorMessage: "Please enter a valid last name",
                      },
                    ]}
                  />
                </div>
                <div style={{ minWidth: "21rem" }} className="mt1">
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Email
                  </Text>
                </div>
                <div>{collaborator?.recipient?.email || ""}</div>
              </div>
            )}
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div style={{ minWidth: "21rem" }}>
              <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                Relationship
              </Text>
            </div>
            <div style={{ minWidth: "21rem", margin: "0.5rem 0" }}>
              <EditContactRelationshipSelector
                collaborator={state}
                onUpdate={(rel) =>
                  setState({
                    relationship: rel,
                  })
                }
              />
            </div>
          </div>
          {canEditCollaboratorDetails && (
            <>
              <div
                style={{
                  minWidth: "21rem",
                  marginTop: "1rem",
                  display: "flex",
                }}
              >
                <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                  Age
                </Text>
                <div style={{ paddingRight: "0.25rem" }} />
                <Text
                  type={TextType.Div}
                  size={"0.875rem"}
                  colour={Colour.Gray70}
                >
                  <i>(Optional)</i>
                </Text>
              </div>
              <div>
                <Input
                  type="age"
                  id="age"
                  name="age"
                  placeholder="Age"
                  value={state.contactInfo?.age ?? ""}
                  onChange={(e) => {
                    const d: Partial<ContactInfo> = {
                      age: e.currentTarget.value,
                    };
                    if (Number.parseFloat(e.currentTarget.value) < 18) {
                      d.executor = false;
                    }
                    setState({
                      contactInfo: d,
                    });
                  }}
                  onEnter={onEnter}
                  keyPressValidation={{
                    inputRegex: AGE_REGEX,
                  }}
                />
              </div>
            </>
          )}

          {/* Only show this option if age is not provided or if user is over 18 */}
          {(state.contactInfo?.age == null ||
            parseInt(state.contactInfo.age) >= 18) && (
            <div
              style={{
                marginTop: "2rem",
                minWidth: "21rem",
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <div className="flex-row">
                <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                  Emergency Contact&nbsp;
                </Text>
                <Text
                  type={TextType.Div}
                  size={"0.875rem"}
                  colour={Colour.Gray70}
                >
                  <i>(Optional)</i>
                </Text>

                <PylonTooltip
                  text={
                    <Text>
                      An <b>emergency contact</b> is the person we will contact
                      if something happens to you. This should be{" "}
                      <b>someone you trust</b> with all of your information.
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <div style={{ paddingRight: ".5rem" }}>
                <Toggle
                  icons={false}
                  checked={state.contactInfo?.executor}
                  onChange={() => {
                    setState({
                      contactInfo: {
                        executor: !state.contactInfo?.executor,
                      },
                    });
                  }}
                />
              </div>
            </div>
          )}

          <div>
            <div
              style={{ minWidth: "21rem", marginTop: "2rem", display: "flex" }}
            >
              <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                Address{" "}
              </Text>
              <div style={{ paddingRight: "0.25rem" }} />
              <Text
                type={TextType.Div}
                size={"0.875rem"}
                colour={Colour.Gray70}
              >
                <i>(Optional)</i>
              </Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                margin: "0 0",
                justifyContent: "space-evenly",
                flexDirection: "column",
                height: "60%",
                gap: "0.5rem",
              }}
            >
              <div>
                <Input
                  type="address1"
                  id="address1"
                  name="address1"
                  placeholder="Street Address"
                  value={state.contactInfo?.addressLineOne}
                  onEnter={onEnter}
                  onChange={(e) => {
                    setState({
                      contactInfo: {
                        addressLineOne: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div>
                <Input
                  type="address2"
                  id="address2"
                  name="address2"
                  placeholder="Address line 2"
                  value={state.contactInfo?.addressLineTwo}
                  onEnter={onEnter}
                  onChange={(e) => {
                    setState({
                      contactInfo: {
                        addressLineTwo: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div>
                <Input
                  type="city"
                  id="city"
                  name="city"
                  placeholder="City"
                  value={state.contactInfo?.city}
                  onEnter={onEnter}
                  onChange={(e) => {
                    setState({
                      contactInfo: {
                        city: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div>
                <StateDropdown
                  openUp
                  userState={state.contactInfo?.stateProvinceRegion}
                  onSave={(s) => {
                    setState({
                      contactInfo: {
                        stateProvinceRegion: s,
                      },
                    });
                  }}
                />
              </div>

              <div>
                <Input
                  type="zipCode"
                  id="zipCode"
                  name="zipCode"
                  placeholder="20005"
                  value={state.contactInfo?.postalCode}
                  onEnter={onEnter}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.currentTarget.blur();
                    }
                  }}
                  onChange={(e) => {
                    setState({
                      contactInfo: {
                        postalCode: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};
