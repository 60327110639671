import React, { useContext, useState } from "react";
import { Account } from "core/models";
import { AddDocumentTray } from "components/features/dashboard/components/add-document-tray";
import { DashboardPages } from "components/features/dashboard/pages";
import { DeleteDocumentQuickAction } from "components/features/dashboard/components/delete-document-quick-action";
import { DocumentCategoryType } from "core/models/documents";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentDetailView } from "components/features/dashboard/components/document-detail-view";
import { DocumentRowCard } from "components/features/dashboard/components/document-row-card";
import { DownloadDocumentQuickAction } from "components/features/dashboard/components/download-document-quick-action";
import { LeafButton } from "components/core/leaf-button";
import { ShareDocumentQuickAction } from "components/features/dashboard/components/share-document-quick-action";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { TableRowModal } from "components/core/modal/table-row-modal";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import {
  DataPageContext,
  DataPageContextProvider,
} from "components/contexts/data-page-context-provider";

interface Props {
  documents: DocumentData[];
  showUploadButton?: boolean;
  account?: Account;
  onUpload?: () => void;
}

export const DocumentRowCardList: React.FC<Props> = ({
  account,
  documents,
  showUploadButton,
  onUpload,
}) => {
  const isMobileLayout = useMobileLayout();
  const history = useHistory();
  const { pushTray } = React.useContext(SideTrayContext);
  const [showModal, setShowModal] = React.useState(false);
  const { onUpdateRefetchQueries } = useContext(DataPageContext);
  const [openDocumentID, setOpenDocumentID] = useState("");

  const refetchQueries = onUpdateRefetchQueries;
  //   ? onUpdateRefetchQueries.concat(refetchAccDocQuery)
  //   : refetchAccDocQuery;

  const [selectedDocumentIndex, setSelectedDocumentIndex] = React.useState(-1);
  const selectedDocument = React.useMemo(() => {
    if (selectedDocumentIndex === -1) {
      return null;
    }
    return documents[selectedDocumentIndex];
  }, [documents, selectedDocumentIndex]);

  return (
    <DataPageContextProvider onUpdateRefetchQueries={refetchQueries}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TableRowModal
          width={isMobileLayout ? "100vw" : "55rem"}
          onClose={() => {
            setSelectedDocumentIndex(-1);
            setShowModal(false);
          }}
          quickActions={[
            <ShareDocumentQuickAction
              key="share"
              document={selectedDocument}
            />,
            <DownloadDocumentQuickAction
              key="download"
              document={selectedDocument}
            />,
            <DeleteDocumentQuickAction
              key="delete"
              document={selectedDocument}
              onDelete={() => setSelectedDocumentIndex(-1)}
            />,
          ]}
          showModal={showModal}
          hasPrevious={selectedDocumentIndex > 0}
          hasNext={selectedDocumentIndex < documents.length - 1}
          onClickNext={() => {
            setSelectedDocumentIndex(selectedDocumentIndex + 1);
          }}
          onClickPrevious={() => {
            setSelectedDocumentIndex(selectedDocumentIndex - 1);
          }}
        >
          <DocumentDetailView document={selectedDocument} />
        </TableRowModal>
        {documents.length > 0 && (
          <div className="table accounts">
            <div className="table-head">
              <div
                className="table-cell"
                style={{
                  width: "60%",
                  flex: "53%",
                  whiteSpace: "nowrap",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "left",
                }}
              >
                Name
              </div>
              <div className="table-cell owner">Owned by</div>
              <div className="table-cell value">Date</div>
            </div>
            <div className="table-body">
              <div className="draggable-table-rows documents">
                {documents.map((x, i) => {
                  return (
                    <DocumentRowCard
                      key={x.id}
                      document={x}
                      open={openDocumentID === x.id}
                      onOpen={() => {
                        if (isMobileLayout) {
                          history.push(
                            `${DashboardPages.DocumentDetails}?documentID=${x.id}`
                          );
                          return;
                        }
                        setSelectedDocumentIndex(i);
                        setShowModal(true);
                      }}
                      onClose={() => {
                        setOpenDocumentID("");
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {showUploadButton && account && (
          <div
            className="flex-row"
            style={{
              marginTop: "1rem",
              justifyContent: "flex-start",
            }}
          >
            <LeafButton
              id="add-new-doc"
              onClick={() => {
                pushTray({
                  id: `account-add-document`,
                  header: (
                    <SimpleSideTrayHeader text="Let's add your document" />
                  ),
                  children: (
                    <AddDocumentTray
                      defaultCategory={{
                        id: account.id || "",
                        type: DocumentCategoryType.Account,
                        name: account.name,
                      }}
                      onUpload={() => onUpload?.()}
                    />
                  ),
                });
              }}
            >
              Upload Documents
            </LeafButton>
          </div>
        )}
      </div>
    </DataPageContextProvider>
  );
};
