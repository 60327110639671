import * as React from "react";
import _ from "lodash";
import { Account } from "core/models";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { differenceInMonths } from "date-fns";
import { EditAccountInformationModal } from "components/features/dashboard/components/manage-account-modal/details/edit-details-modal";
import { toMoneyStringNoDecimal } from "core/utils";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  AccountSubType,
  AccountSubtypeEnum,
  AccountType,
  isAccountConnected,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  accountsBySubtypesQuery,
  AccountsBySubtypesResponse,
} from "core/queries/accounts";

interface Props {}

export const RealEstateHoldingsSection: React.FC<Props> = () => {
  const { data } = useQuery<AccountsBySubtypesResponse>(
    accountsBySubtypesQuery,
    {
      variables: {
        subtypes: [
          AccountSubType[AccountType.HomeAndRentalProperty][
            AccountSubtypeEnum.Mortgage
          ],
          AccountSubType[AccountType.HomeAndRentalProperty][
            AccountSubtypeEnum.RentalProperty
          ],
        ],
      },
    }
  );

  const accounts = React.useMemo(() => {
    return data?.accountsBySubtypes;
  }, [data?.accountsBySubtypes]);

  const total = accounts?.length ?? 0;

  if (!accounts || !total) {
    return null;
  }

  const rows = accounts.map((acc) => <RealEstateHoldingRow account={acc} />);

  return (
    <div className="investment_real_estate_property_table">
      <h6>Real Estate Property</h6>
      <div className="table">
        <div className="table-head">
          <div className="table-cell name-column">Name</div>
          <div className="table-cell purchase-price-column no-mobile">
            Purchase Price
          </div>
          <div className="table-cell current-value-column mobile-last">
            Current Value
          </div>
          <div className="table-cell period-column no-mobile">Years</div>
          <div className="table-cell annualized-column no-mobile">
            Annualized Return
          </div>
          <div className="table-cell total-return-column no-mobile">
            Total Return
          </div>
        </div>
        <div className="table-body">{rows}</div>
      </div>
    </div>
  );
};

const RealEstateHoldingRow: React.FC<{ account: Account }> = ({
  account: acc,
}) => {
  const [showEditModal, setShowEditModal] = React.useState(false);

  let purchasePrice: number | null = null;
  let purchaseDate: Date | null = null;
  let currentValue: number | null = null;
  switch (acc.subtype) {
    case AccountSubtypeEnum.Mortgage: {
      const detail = acc.details?.mortgageDetails;
      purchaseDate = detail?.purchaseDate
        ? new Date(detail.purchaseDate)
        : null;
      purchasePrice = detail?.purchasePrice
        ? Number.parseFloat(detail.purchasePrice)
        : null;
      currentValue = detail?.estimatedValue
        ? Number.parseFloat(detail.estimatedValue)
        : null;
      break;
    }
    case AccountSubtypeEnum.RentalProperty: {
      const detail = acc.details?.rentalPropertyDetails;
      purchaseDate = detail?.purchaseDate
        ? new Date(detail.purchaseDate)
        : null;
      purchasePrice = detail?.originalBalance?.value
        ? Number.parseFloat(detail.originalBalance.value)
        : null;
      currentValue = detail?.estimatedValue
        ? Number.parseFloat(detail.estimatedValue)
        : null;

      break;
    }
  }

  const totalMonth: number | null = purchaseDate
    ? differenceInMonths(new Date(), purchaseDate)
    : null;
  let totalReturn: number | null = null;
  let annualizedReturn: number | null = null;
  if (totalMonth && purchasePrice && currentValue) {
    const r = currentValue - purchasePrice;
    totalReturn = (r / purchasePrice) * 100;
    annualizedReturn =
      (Math.pow(1 + totalReturn / 100, 1 / (totalMonth / 12)) - 1) * 100;
  }

  return (
    <div>
      <EditAccountInformationModal
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        account={acc}
        isConnected={isAccountConnected(acc.integration_source)}
      />
      <div
        key={acc.id}
        className="table-row investment_real_estate_property-row-wrapper"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setShowEditModal(true);
        }}
      >
        <div className="table-cell name-column flex-row">
          <div style={{ marginRight: ".5rem" }}>
            <AccountIcon account={acc} scale={1} />
          </div>
          {acc.name}
        </div>
        <div className="table-cell purchase-price-column no-mobile">
          {purchasePrice ? toMoneyStringNoDecimal(purchasePrice) : "N/A"}
        </div>
        <div className="table-cell current-value-column mobile-last">
          {currentValue ? toMoneyStringNoDecimal(currentValue) : "N/A"}
        </div>
        <div className="table-cell period-column no-mobile">
          {totalMonth ? `${(totalMonth / 12).toFixed(1)}` : "N/A"}
        </div>
        <div
          className={valueClasses(
            "table-cell annualized-column no-mobile",
            annualizedReturn
          )}
        >
          {annualizedReturn ? `${annualizedReturn.toFixed(2)}%` : "N/A"}
        </div>
        <div
          className={valueClasses(
            "table-cell total-return-column no-mobile",
            totalReturn
          )}
        >
          {totalReturn ? `${totalReturn.toFixed(2)}%` : "N/A"}
        </div>
      </div>
    </div>
  );
};

const valueClasses = (defaultClasses: string, val: number | null) => {
  if (_.isNil(val) || val === 0) {
    return defaultClasses;
  }
  if (val > 0) {
    return `${defaultClasses} positive`;
  }
  return `${defaultClasses} negative`;
};
