import * as React from "react";
import { ChartNetWorthSeries } from "components/features/dashboard/components/chart-networth-series";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Link } from "react-router-dom";
import { NetworthAccountOwnerFilter } from "components/features/dashboard/components/networth-summary-section/components/networth-account-owner-filter";
import { NetworthAccountTypeFilter } from "components/features/dashboard/components/networth-summary-section/components/networth-account-type-filter";
import { NetWorthSummary } from "components/features/dashboard/components/networth-summary";
import { NetworthTimeFilter } from "components/features/dashboard/components/networth-summary-section/components/networth-time-filter";
import { Text, TextD, TextType } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";
import { usePreferenceSettingStringEnum } from "core/hooks/user-preference-setting";
import { UserPreferenceSettingEnum } from "core/queries/user-preference-settings";
import "./styles.css";

export enum DayFiltering {
  ThirtyDays = "30 Days",
  SixtyDays = "60 Days",
  NintetyDays = "90 Days",
  ThisYear = "This Year",
}

export const NetworthSummarySection: React.FC = () => {
  const mobileLayout = useMobileLayout();
  const [interactedWithChart, setInteractedWithChart] =
    React.useState<boolean>(false);
  const [chartContext, setChartContext] = React.useState<any>();
  const [days, setDays] = usePreferenceSettingStringEnum(
    DayFiltering.NintetyDays,
    UserPreferenceSettingEnum.NetworthHistoryDays
  );
  const resetSeries = () => {
    if (!chartContext) return;
    chartContext.resetSeries();
    setInteractedWithChart(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0",
          fontFamily: "'Montserrat', sans-serif",
        }}
      >
        <div
          className="flex-row align-center"
          style={{
            gap: "1rem",
          }}
        >
          <Text
            type={TextType.Div}
            weight={700}
            colour={Colour.Navy}
            size={"1.25rem"}
          >
            Net Worth History
          </Text>

          <Link to={DashboardPages.BalanceSheet}>
            <TextD>Balance Sheet</TextD>
          </Link>
        </div>
        <div
          style={{
            padding: "0.25rem",
          }}
        />
        {!mobileLayout && (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <NetworthAccountTypeFilter />
            <NetworthTimeFilter days={days} setDays={setDays} />
            <NetworthAccountOwnerFilter />
            {interactedWithChart && (
              <div
                style={{
                  display: "flex",
                  paddingLeft: "0.25rem",
                }}
              >
                <button
                  onClick={() => resetSeries()}
                  className="button-as-link"
                >
                  <strong>Reset</strong>
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className="overview-component flex-row mobile-flex-column"
        style={{ display: "flex" }}
      >
        <ChartNetWorthSeries
          chartContext={chartContext}
          setChartContext={setChartContext}
          interactedWithChart={interactedWithChart}
          days={days}
          onInteraction={() => setInteractedWithChart(true)}
        />

        <NetWorthSummary />
      </div>
    </div>
  );
};
