import classnames from "classnames";
import { Colour } from "core/models";
import { ConfirmationModalOld } from "components/core/modal";
import { displayColouredNumber } from "components/features/dashboard/components/planning/coloured-number";
import { Icon, IconAsset } from "components/core/icons";
import { PlanDataSerializer } from "components/features/dashboard/components/planning/calculators/data-serializer";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SerializablePlan } from "components/features/dashboard/components/planning/calculators/models";
import { SessionContext } from "components/contexts/session-context-provider";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { UserDisplayName } from "core/utils/profile";
import "./styles.css";
import {
  DeleteScenarioPlanMutationInput,
  DELETE_SCENARIO_PLAN_MUTATION,
  ScenarioPlan,
} from "core/queries/scenario-plans";

export interface Props {
  id: string;
  plan: ScenarioPlan;

  onLoad: () => void;
  onDelete?: () => void;
}

export const ScenarioCard: React.FC<Props> = ({
  id,
  plan,
  onLoad,
  onDelete,
}) => {
  const { userID } = useContext(SessionContext.context);
  const [deleteMutation, { loading }] = useMutation<
    {},
    DeleteScenarioPlanMutationInput
  >(DELETE_SCENARIO_PLAN_MUTATION, {
    onCompleted: () => {
      onDelete?.();
      setShowDeleteModal(false);
    },
    onError: (err) => {
      toast(
        <PylonToastBody
          title={"Failed to delete scenario"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
    variables: {
      id: id,
    },
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLoadModal, setShowLoadModal] = useState(false);

  const cost = PlanDataSerializer.deserialize<SerializablePlan>(
    plan.value
  )?.monthlyCashflow;

  const deleteClassNames = classnames({
    "delete-scenario": true,
    disabled: !plan.isOwnerOrEditor,
  });

  return (
    <div>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showLoadModal}
        children={
          <Text style={TextStyle.B16} weight={600}>
            Do you want to load this scenario?
          </Text>
        }
        onAction={() => {
          onLoad();
          setShowLoadModal(false);
        }}
        actionButtonText={"Load"}
        onCancel={() => {
          setShowLoadModal(false);
        }}
        cancelButtonText="Cancel"
      />

      <ConfirmationModalOld
        width={"22rem"}
        showModal={showDeleteModal}
        children={
          <Text style={TextStyle.B16} weight={600}>
            Are you sure you want to delete this scenario?
          </Text>
        }
        onAction={() => {
          if (!loading) {
            deleteMutation();
          }
        }}
        actionButtonText={"Delete"}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        cancelButtonText="Cancel"
      />

      <div className="saved-scenario-card">
        <div
          className={deleteClassNames}
          onClick={(e) => {
            if (plan.isOwnerOrEditor) {
              e.preventDefault();
              e.stopPropagation();
              setShowDeleteModal(true);
            }
          }}
        >
          <Icon asset={IconAsset.Trashcan} width="24px" height="24px" />
        </div>

        <div
          className="scenario-card-main"
          onClick={() => {
            setShowLoadModal(true);
          }}
        >
          <Text style={TextStyle.L14} type={TextType.Div} weight={700}>
            {plan.scenarioName}
          </Text>
          {userID !== plan.owner?.id && (
            <div
              style={{
                display: "flex",
              }}
            >
              <Text
                style={TextStyle.L14}
                type={TextType.Div}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Text03}
              >
                Shared by
              </Text>
              &nbsp;
              <Text
                style={TextStyle.L14}
                type={TextType.Div}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Blue01}
              >
                {UserDisplayName(plan.owner)}
              </Text>
            </div>
          )}
          <div
            style={{
              paddingTop: "0.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text style={TextStyle.B18} type={TextType.Div}>
              {cost ? displayColouredNumber(cost) : "unknown"}
            </Text>
            <Text
              style={TextStyle.L14}
              type={TextType.Div}
              weight={700}
              format={TextFormat.UpperCase}
              colour={Colour.Text03}
            >
              MO/CASH FLOW
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
