import * as React from "react";
import { useState } from "react";

export interface PlanContextValue {
  householdIncome: string;
  taxes: string;
  monthlyNetIncome: string;
  monthlyExpense: string;
  monthlyCashflow: number;

  setHouseholdIncome: (s: string) => void;
  setTaxes: (s: string) => void;
  setMonthlyExpense: (s: string) => void;
}

export const PlanContext = React.createContext<PlanContextValue>({
  householdIncome: "",
  taxes: "",
  monthlyNetIncome: "",
  monthlyExpense: "",
  monthlyCashflow: 0,

  setHouseholdIncome: () => null,
  setTaxes: () => null,
  setMonthlyExpense: () => null,
});

export const PlanContextProvider: React.FC = ({ children }) => {
  const [householdIncome, setHouseholdIncome] = useState<string>("150000");
  const [taxes, setTaxes] = useState<string>("30000");
  const [monthlyExpense, setMonthlyExpense] = useState<string>("3000");

  const monthlyIncome =
    (Number.parseFloat(householdIncome) - Number.parseFloat(taxes)) / 12;

  return (
    <PlanContext.Provider
      value={{
        householdIncome: householdIncome,
        taxes: taxes,
        monthlyNetIncome: monthlyIncome.toFixed(0),
        monthlyExpense: monthlyExpense,
        monthlyCashflow: monthlyIncome - Number.parseFloat(monthlyExpense),
        setHouseholdIncome: setHouseholdIncome,
        setTaxes: setTaxes,
        setMonthlyExpense: setMonthlyExpense,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};
