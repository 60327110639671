import * as React from "react";
import { DefaultPreviewIcon } from "components/core/file-viewer/default-preview-icon";
import { extension } from "mime-types";
import { ImageViewer } from "./image-viewer";
import { PDFViewer } from "components/core/file-viewer/pdf-viewer";
import { Text } from "components/core/text";

export interface Props {
  fileURL: string;
  mimeType: string;
  unsupportedComponent: React.ReactNode;
  displayClassName?: string;
  preview?: boolean;
}

export const FileViewer: React.FC<Props> = ({
  fileURL,
  mimeType,
  unsupportedComponent,
  displayClassName,
  preview,
}) => {
  const format = extension(mimeType) || "unknown";
  switch (format) {
    case "bmp":
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return <ImageViewer className={displayClassName} src={fileURL} />;
    case "pdf":
      return (
        <PDFViewer
          className={displayClassName}
          src={fileURL}
          preview={preview}
        />
      );
    default:
      if (preview) {
        return <DefaultPreviewIcon format={format} />;
      }
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Text>{format} files are not supported for preview</Text>
          {unsupportedComponent}
        </div>
      );
  }
};
