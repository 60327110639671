import * as React from "react";
import Illustration404 from "./404.svg";
import { Account, Colour } from "../../../../../core/models";
import { AccountContactsComponent } from "components/features/dashboard/components/account-contacts";
import { AccountDetailsOverviewPanel } from "components/features/dashboard/components/account-detail-view/overview";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { accountShortName } from "core/models/account";
import { AssetNotes } from "components/features/dashboard/components/asset-notes";
import { AssetType } from "components/features/dashboard/models/sharing";
import { AuditLog } from "../../components/audit-log";
import { DashboardPages } from "components/features/dashboard/pages";
import { DeleteAccountQuickActionButton } from "components/features/dashboard/components/delete-account-quick-action";
import { DisplayAccountDetails } from "../../components/manage-account-modal/details";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentRowCardList } from "components/features/dashboard/components/document-row-card-list";
import { EditAccountQuickAction } from "components/features/dashboard/components/edit-account-quick-action";
import { FETCH_ACCOUNT_DOCUMENTS } from "core/queries/accounts";
import { isAccountConnected } from "components/features/dashboard/components/add-account-tray/models";
import { PylonLink } from "components/core/link";
import { RefreshAccountQuickAction } from "components/features/dashboard/components/refresh-account-quick-action";
import { RemoveAccountQuickAction } from "components/features/dashboard/components/remove-access-quick-action";
import { ShareAccountQuickAction } from "components/features/dashboard/components/share-account-quick-action";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs
  } from "react-tabs";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import { useQuery } from "@apollo/client";
import "./styles.css";

interface Props {
  account: Account;
  loading?: boolean;
  fullPageView?: boolean;
}

interface AccountDocumentResponse {
  account: {
    id: string;
    documents: DocumentData[];
  };
}

export const AccountDetailView: React.FC<Props> = ({
  account,
  loading,
  fullPageView,
}) => {
  const mobileLayout = useMobileLayout();
  const history = useHistory();
  const backToAccount = () => {
    history.push(DashboardPages.Integrations);
  };
  const isOwnerOrEditor = account?.isOwner || account?.isEditor || false;
  const isConnected = isAccountConnected(account?.integration_source);

  const { data, refetch: refetchAccountDocuments } =
    useQuery<AccountDocumentResponse>(FETCH_ACCOUNT_DOCUMENTS, {
      fetchPolicy: "cache-and-network",

      variables: {
        id: account.account_id,
      },
    });

  if (!loading && !account) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: "5rem 0",
            maxWidth: "25.275rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            colour={Colour.Navy}
            style={TextStyle.TitlePoppins}
            type={TextType.Div}
          >
            <h1>404</h1>
          </Text>

          <div
            style={{
              padding: "2rem",
            }}
          />
          <svg>
            <use xlinkHref={`${Illustration404}#illustration`} />
          </svg>

          <div style={{ textAlign: "center" }}>
            <Text style={TextStyle.B16}>
              The account you are trying to view has been deleted or you may no
              longer have access to it.
            </Text>
          </div>

          <PylonLink to={DashboardPages.Integrations}>
            <Text type={TextType.Div} style={TextStyle.L12}>
              BACK TO ACCOUNTS
            </Text>
          </PylonLink>
        </div>
      </div>
    );
  }

  if (!account) {
    return null;
  }

  return (
    <TextD style={TextStyle.M15}>
      <div
        className="flex-column"
        style={{
          color: Colour.Black,
        }}
      >
        <div
          style={{
            height: "8rem",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${account.primaryColorHex}`,
          }}
        >
          <div
            style={{
              paddingTop: "6rem",
            }}
          />
          <div
            style={{
              paddingLeft: mobileLayout ? "1rem" : "7rem",
              width: "fit-content",
              height: "fit-content",
              maxWidth: mobileLayout ? "100vw" : undefined,
            }}
          >
            <AccountIcon account={account} scale={4 / 3} />
          </div>
        </div>
        <div className="mt3" />
        <div
          className="flex-column"
          style={{
            padding: mobileLayout ? "0 1rem" : "0 7rem",
          }}
        >
          <TextD style={TextStyle.M31B}>{accountShortName(account)}</TextD>
          {fullPageView && (
            <div className="flex-row gap-half">
              <EditAccountQuickAction account={account} />
              <ShareAccountQuickAction account={account} />
              <RefreshAccountQuickAction account={account} />
              <DeleteAccountQuickActionButton
                account={account}
                onRemove={backToAccount}
              />
              <RemoveAccountQuickAction
                account={account}
                onRemove={backToAccount}
              />
            </div>
          )}
          <div className="mt2" />
          <Tabs>
            <TabList className="account-detail-tab-list">
              <Tab
                id="account-detail-tab__overview"
                className="account-detail-tab-item"
                selectedClassName="account-detail-tab-item--selected"
              >
                Overview
              </Tab>
              <Tab
                id="account-detail-tab__notes"
                className="account-detail-tab-item"
                selectedClassName="account-detail-tab-item--selected"
              >
                Notes
              </Tab>
              <Tab
                id="account-detail-tab__files"
                className="account-detail-tab-item"
                selectedClassName="account-detail-tab-item--selected"
              >
                Files
              </Tab>
              <Tab
                id="account-detail-tab__more"
                className="account-detail-tab-item"
                selectedClassName="account-detail-tab-item--selected"
              >
                More
              </Tab>
            </TabList>
            <TabPanel>
              <AccountDetailsOverviewPanel
                account={account}
                isConnected={isConnected}
              />
            </TabPanel>
            <TabPanel>
              <AssetNotes
                assetID={account.account_id}
                assetType={AssetType.Account}
                owner={account.isOwner === true}
                editor={account.isEditor === true}
              />
            </TabPanel>
            <TabPanel>
              <DocumentRowCardList
                account={account}
                documents={data?.account?.documents || []}
                showUploadButton
                onUpload={() => refetchAccountDocuments()}
              />
            </TabPanel>
            <TabPanel>
              <div
                className="flex-column"
                style={{
                  gap: "2.5rem",
                }}
              >
                <DisplayAccountDetails
                  isConnected={isConnected}
                  account={account}
                  displayOnly
                />

                <div>
                  <AccountContactsComponent account={account} />
                </div>

                {isOwnerOrEditor && (
                  <div>
                    <AuditLog
                      assetID={account.account_id}
                      assetType={AssetType.Account}
                    />
                  </div>
                )}
              </div>
            </TabPanel>
            <div className="mt5" />
          </Tabs>
        </div>
      </div>
    </TextD>
  );
};
