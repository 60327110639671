import * as React from "react";
import Modal from "react-modal";
import { BaseModalProps } from "components/core/modal/props";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { useClickOutDetector } from "core/hooks/click-out";
import "../styles/modal.css";
import "./styles.css";

interface PublicProps {
  showModal: boolean;
  onClose: () => void;
}

type Props = PublicProps & BaseModalProps;

/*
 * Modal that takes over entire screenspace to display contents.
 */
export const DisplayModal: React.FC<Props> = ({
  showModal,
  onClose,
  height,
  width,
  children,
}) => {
  const clickOutRef = useClickOutDetector<HTMLDivElement>(onClose);
  return (
    <Modal
      isOpen={showModal}
      className="modal-base"
      overlayClassName="overlay-base"
      style={{
        overlay: {
          backgroundColor: "rgba(3, 12, 23, 0.7)",
        },
      }}
      overlayElement={(props, contentElement) => (
        <div
          {...props}
          style={{
            width: "100vw",
            height: "100vh",
          }}
        >
          <div className="display-modal__close-modal-button">
            <Icon
              asset={IconAsset.Close}
              height="24px"
              width="24px"
              colour={Colour.Gray10}
            />
          </div>
          <div>{contentElement}</div>
        </div>
      )}
    >
      <div
        ref={clickOutRef}
        style={{
          maxHeight: "90vh",
          maxWidth: "80vw",
          height: height,
          width: width,
          padding: "1rem",
          overflow: "auto",
        }}
      >
        <div>{children}</div>
      </div>
    </Modal>
  );
};
