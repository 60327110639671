import * as React from "react";
import { Account } from "../../../../../core/models";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { ChartAccountBalanceHistory } from "../../components/chart-account-balance-history";
import { ChartAccountCombo } from "components/features/dashboard/components/chart-account-combo";
import { ChartExpenses } from "components/features/dashboard/components/chart-expenses";
import { InvestmentAccountTransactions } from "components/features/dashboard/components/investment-account-transactions";
import { InvestmentHoldingsHighlightsSection } from "components/features/dashboard/components/investment-holdings-highlights";
import { InvestmentHoldingsSection } from "components/features/dashboard/components/investment-holdings";
import { millisecondsReadable, toMoneyString } from "core/utils";
import { RealEstateChart } from "components/features/dashboard/components/manage-account-modal/charts/real-estate-chart";
import { RecurringExpenseTable } from "components/features/dashboard/components/transactions/recurring-expense";
import { Spinner } from "react-bootstrap";
import { TextD, TextStyle } from "components/core/text";
import { TransactionsTable } from "components/features/dashboard/components/transactions";
import "./styles.css";
import {
  AccountSubtypeEnum,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";

export interface Props {
  account: Account;
  isConnected: boolean;
}

export const AccountDetailsOverviewPanel: React.FC<Props> = ({
  account,
  isConnected,
}) => {
  const accountChart = getAccountChart(account, isConnected);

  return (
    <div className="flex-column">
      <TextD style={TextStyle.M64}>
        {toMoneyString(account.balances.current)}
      </TextD>
      <div
        style={{
          paddingTop: "2px",
        }}
      />
      <div className="flex-row between">
        {account.modified_at
          ? `${millisecondsReadable(
              new Date().getTime() -
                new Date(parseInt(account.modified_at) * 1000).getTime()
            )} ago`
          : "1 minute ago"}
      </div>

      <div className="mt2" />
      {accountChart}

      <div className="mt2" />
      {account.type === AccountType.NonRetirement &&
        account.itemHasInvestments &&
        isConnected && (
          <>
            <InvestmentHoldingsHighlightsSection
              accountID={account.account_id}
            />
            <InvestmentHoldingsSection
              accountID={account.account_id}
              limit={10}
              enablePagination={true}
              hideEmptyState={true}
            />
          </>
        )}

      {account.subtype === AccountSubtypeEnum.PrivateStock && (
        <div>
          <span className="section-label overline">
            <b>Transactions</b>
          </span>
          <div>
            <InvestmentAccountTransactions accountID={account.account_id} />
          </div>
        </div>
      )}
      <AccountDetailsOverviewTransactionsSection
        accountID={account.account_id}
      />
    </div>
  );
};

interface TransactionSectionProps {
  accountID: string;
}

const AccountDetailsOverviewTransactionsSection: React.FC<TransactionSectionProps> =
  ({ accountID }) => {
    const { setAccountID, transactionDataLoading } = React.useContext(
      AccountTransactionContext
    );

    React.useEffect(() => {
      setAccountID(accountID);
    }, [accountID, setAccountID]);

    return (
      <>
        {transactionDataLoading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <div className="mt1" />
            <ChartExpenses showMonthSelector />
            <div className="mt1" />
            <RecurringExpenseTable />
            <div className="mt2" />
            <TransactionsTable />
          </>
        )}
      </>
    );
  };
const getAccountChart = (account: Account, isConnected: boolean) => {
  if (account.subtype === AccountSubtypeEnum.SavingsAccounts) {
    return <ChartAccountCombo account={account} isConnected={isConnected} />;
  }

  if (account.subtype === AccountSubtypeEnum.Mortgage) {
    return <RealEstateChart account={account} isConnected={isConnected} />;
  }

  return (
    <div style={{ height: "17rem" }}>
      <span className="section-label overline">Balance History</span>
      <div>
        <ChartAccountBalanceHistory
          account={account}
          chartType={
            // new Date().getTime() -
            //   new Date(parseInt(account.created_at || "") * 1000).getTime() >
            // 864000000 ?
            "area"
            // : "area"
          }
        />
      </div>
    </div>
  );
};
