import Icons from "./icons.svg";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { useMobileLayout } from "core/hooks/responsive";
import { v4 } from "uuid";

export enum TooltipPlacement {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left",
}

export enum TooltipIconSize {
  Default,
  Small,
}

export enum SpacingSide {
  Left,
  Right,
  None,
}

export enum TooltipWrapperDisplay {
  Default = "inline-flex",
  None = "",
}

export interface Props {
  wrapperDisplay?: TooltipWrapperDisplay;
  hideTooltip?: boolean;
  tooltip?: boolean;
  text: string | React.ReactNode;
  direction?: TooltipPlacement;
  size?: TooltipIconSize;
  spacingSide?: SpacingSide;
  mobileForcePosition?: { left: number; top: number };
  fullWidth?: boolean;
}

export const PylonTooltip: React.FC<Props> = ({
  wrapperDisplay = TooltipWrapperDisplay.Default,
  text,
  direction,
  size,
  hideTooltip,
  spacingSide = SpacingSide.Left,
  fullWidth = false,
  children,
  mobileForcePosition,
}) => {
  const mobileLayout = useMobileLayout();
  const [tooltipID] = useState(v4().toString());

  let sizeProps = {
    width: "24",
    height: "24",
  };

  switch (size) {
    case TooltipIconSize.Small: {
      sizeProps = {
        width: "16",
        height: "16",
      };
    }
  }
  const dataAttributes: {
    "data-for"?: any;
    "data-tip"?: any;
  } = {
    "data-for": tooltipID,
  };
  if (!hideTooltip) {
    dataAttributes["data-tip"] = true;
  }

  return (
    <div
      className={fullWidth ? "full-width" : ""}
      style={{
        display: wrapperDisplay,
        alignItems: "center",
        marginLeft: spacingSide === SpacingSide.Left ? ".5rem" : "0",
        marginRight: spacingSide === SpacingSide.Right ? ".5rem" : "0",
      }}
    >
      {!children && (
        <svg className="icon" {...dataAttributes} {...sizeProps}>
          <use xlinkHref={`${Icons}#icon-tooltip`} />
        </svg>
      )}
      {children && <span {...dataAttributes}>{children}</span>}

      <ReactTooltip
        id={tooltipID}
        place={direction ?? TooltipPlacement.Top}
        backgroundColor="#12274A"
        textColor="#FFFFFF"
        effect="solid"
        overridePosition={(position: { left: number; top: number }) => {
          if (!mobileLayout) {
            return position;
          }
          return { left: 0, top: 0 };
        }}
        arrowColor={mobileLayout ? "transparent" : ""}
        multiline
      >
        <div
          style={{
            maxWidth: "25rem",
            whiteSpace: "pre-line",
          }}
        >
          {text}
        </div>
      </ReactTooltip>
    </div>
  );
};
