import * as React from "react";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import { Input } from "components/core/input";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeStringNumber } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMutation } from "@apollo/client";
import {
  CreateInvestmentTransactionResponse,
  GetInvestmentTransactions,
  Transaction,
  UpdateInvestmentTransaction,
} from "core/queries/accounts";
// import "./styles.css";

interface State {
  sharesSold: string;
  pricePerShare: string;
  dateSold: Date;
  notes: string;
}

interface Props {
  accountID: string;
  transaction: Transaction;
  onClose: () => void;
}

export const ManageTransactionTray: React.FC<Props> = ({
  accountID,
  transaction,
  onClose,
}) => {
  const [state, setState] = React.useState<State>({
    sharesSold: transaction.quantity.toString(),
    pricePerShare: transaction.price.toString(),
    dateSold: new Date(transaction.date),
    notes: transaction.notes,
  });
  const [updateTransaction] = useMutation<CreateInvestmentTransactionResponse>(
    UpdateInvestmentTransaction,
    {
      refetchQueries: [
        {
          query: GetInvestmentTransactions,
          variables: {
            accountID,
          },
        },
      ],
    }
  );
  const disabled = state.sharesSold === "" || state.pricePerShare === "";

  const submitTransaction = () => {
    updateTransaction({
      variables: {
        input: {
          transactionID: transaction.id,
          accountID,
          amount:
            parseFloat(state.pricePerShare) * parseFloat(state.sharesSold),
          date: state.dateSold,
          quantity: parseFloat(state.sharesSold),
          price: parseFloat(state.pricePerShare),
          notes: state.notes,
        },
      },
    });
    onClose();
  };

  return (
    <div>
      <div className="add-document-tray__content">
        <div className="add-document-tray__step-selection">
          <div style={{ padding: "0 2px" }}>
            <div>
              <div style={{ minWidth: "23rem" }}>
                <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                  Shares sold
                </Text>
              </div>
              <div>
                <Input
                  type="sharesSold"
                  id="sharesSold"
                  name="sharesSold"
                  placeholder="Number of shares sold"
                  value={state.sharesSold}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.currentTarget.blur();
                    }
                  }}
                  onChange={(e) =>
                    setState({
                      ...state,
                      sharesSold: e.currentTarget.value,
                    })
                  }
                  onBlurValidate={[
                    {
                      validationFunc: (v) => v !== "",
                      errorMessage: "Please enter a valid amount of shares",
                    },
                  ]}
                  onEnter={() => {
                    if (disabled) {
                      return;
                    }
                    submitTransaction();
                  }}
                  onBlur={() => {
                    if (disabled) {
                      return;
                    }
                    submitTransaction();
                  }}
                />
              </div>
              <div>
                <div style={{ minWidth: "23rem" }}>
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Price per share
                  </Text>
                </div>
                <PylonCurrencyInput
                  placeholder="$100.00"
                  className="pylon-input"
                  onValueChange={(v) =>
                    setState({
                      ...state,
                      pricePerShare: sanitizeStringNumber(v),
                    })
                  }
                  value={state.pricePerShare}
                  prefix={"$"}
                  step={1}
                  decimalScale={2}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      if (disabled) {
                        return;
                      }
                      submitTransaction();
                    }
                  }}
                  onBlur={() => {
                    if (disabled) {
                      return;
                    }
                    submitTransaction();
                  }}
                />
              </div>
              <div>
                <div style={{ minWidth: "23rem" }}>
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Date sold
                  </Text>
                </div>
                <DatePicker
                  wrapperClassName={"login-form-input-wrapper"}
                  customInput={
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                    />
                  }
                  open={false}
                  selected={state.dateSold}
                  placeholderText={"MM / DD / YYYY"}
                  onBlur={() => {
                    if (disabled) {
                      return;
                    }
                    submitTransaction();
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      if (disabled) {
                        return;
                      }
                      submitTransaction();
                    }
                  }}
                  onChange={(date: Date) =>
                    setState({ ...state, dateSold: date })
                  }
                />
              </div>
              <div>
                <div style={{ minWidth: "23rem" }}>
                  <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                    Notes
                  </Text>
                </div>
                <Input
                  type="notes"
                  id="notes"
                  name="notes"
                  placeholder=""
                  value={state.notes}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.currentTarget.blur();
                    }
                  }}
                  onChange={(e) =>
                    setState({
                      ...state,
                      notes: e.currentTarget.value,
                    })
                  }
                  onBlur={(e) => {
                    if (disabled) {
                      return;
                    }
                    submitTransaction();
                  }}
                  onEnter={() => {
                    if (disabled) {
                      return;
                    }
                    submitTransaction();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
