import * as React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DayFiltering } from "components/features/dashboard/components/investments-summary-section/models";
import { NetworthHistory } from "core/queries/overview";
import { shortNumber, toMoneyString, toMoneyStringNoDecimal } from "core/utils";
import "./styles.css";
import {
  ChangeTypeFiltering,
  DataTypeFiltering,
  InvestmentSummaryContext,
  investmentTypeToValue,
} from "components/features/dashboard/components/investments-summary-section/context/investments-summary-context";

interface ChartState {
  options: ApexOptions;
  series: Array<any>;
}

interface Props {
  interactedWithChart: boolean;
  days: DayFiltering;
  onInteraction: () => void;

  chartContext: any;
  setChartContext: (args: any) => void;
}

export const ChartInvestmentPortfolio: React.FC<Props> = ({
  days,
  setChartContext,
  onInteraction,
}) => {
  const {
    changeType,
    portfolioData: data,
    dataType,
  } = React.useContext(InvestmentSummaryContext);
  const noData =
    !data?.portfolioNetworthHistory ||
    data.portfolioNetworthHistory?.length === 0;
  const dataset = React.useMemo<
    {
      accountID?: string;
      history?: NetworthHistory[];
    }[]
  >(() => {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    if (noData)
      return [
        // {
        //   date: yesterday,
        //   networth: 0,
        //   assets: 0,
        //   liabilities: 0,
        //   illiquidAsset: 0,
        //   liquidAsset: 0,
        // },
        // {
        //   date: new Date(),
        //   networth: 5,
        //   assets: 0,
        //   liabilities: 0,
        //   illiquidAsset: 0,
        //   liquidAsset: 0,
        // },
      ];

    if (data?.portfolioNetworthHistory?.length === 1) {
      // const newHistory = data.portfolioNetworthHistory.history.map((o: any) => {
      //   return o;
      // });
      // newHistory.push({
      //   date: yesterday,
      //   networth: 0,
      //   assets: 0,
      //   liabilities: 0,
      //   illiquidAsset: 0,
      //   liquidAsset: 0,
      // });
      // return newHistory;
    }
    return (
      data?.portfolioNetworthHistory?.filter((v) => {
        if (dataType === DataTypeFiltering.All) {
          return v.accountID === "Total Investments";
        } else {
          return v.accountID !== "Total Investments";
        }
      }) || []
    );
  }, [data, noData]);

  const networthValues = React.useMemo<number[]>(() => {
    const ret =
      dataset?.map((d) => {
        return investmentTypeToValue(dataType, d?.history?.[0]);
      }) || [];

    return ret;
  }, [dataset]);

  const networthValuesEarliest = React.useMemo<number[]>(() => {
    const ret =
      dataset?.map((d) => {
        return investmentTypeToValue(
          dataType,
          d?.history?.[d?.history?.length - 1]
        );
      }) || [];

    return ret;
  }, [dataset]);

  const commonInteraction = React.useCallback(
    (thisChartContext: any, config: any) => {
      onInteraction();
    },
    [onInteraction]
  );

  const state = React.useMemo<ChartState>(() => {
    return {
      options: {
        // colors: ["#57BA8D"],
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          events: {
            click: commonInteraction,
            mouseDown: commonInteraction,
            selection: commonInteraction,
            dataPointSelection: commonInteraction,
            scrolled: commonInteraction,
            zoomed: commonInteraction,
            legendClick: commonInteraction,
            markerClick: commonInteraction,
            mounted: (thisChartContext: any, config: any) => {
              setChartContext(thisChartContext);
            },
          },
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "round",
          colors: undefined,
          width: 3,
          dashArray: 0,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickAmount: 4,

          labels: {
            show: !noData,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM 'yy",
              day: "dd MMM",
              hour: "",
              minute: "hh:mm tt",
            },
          },
          type: "datetime" as "datetime",
        },
        yaxis: {
          show: true,
          forceNiceScale: true,
          labels: {
            show: !noData,
            formatter: (value: any) => {
              return `${shortNumber(value)}`;
            },
          },
        },
        markers: {
          discrete: [],
        },
        tooltip: {
          enabled: !noData,
          y: {
            formatter: (value: any) => {
              return `${toMoneyString(value)}`;
            },
          },
        },
      },
      series: dataset
        .filter((v) => {
          if (dataType === DataTypeFiltering.All) {
            return v.accountID === "Total Investments";
          } else {
            return v.accountID !== "Total Investments";
          }
        })
        .map((v) => {
          return {
            name: v.accountID,
            data: v.history
              ?.map((d) => {
                return {
                  x: new Date(d.date),
                  y: investmentTypeToValue(dataType, d),
                };
              })
              .sort((a: { x: Date; y: number }, b: { x: Date; y: number }) => {
                return a.x.getTime() - b.x.getTime();
              }),
          };
        }),
    };
  }, [commonInteraction, noData, dataset, setChartContext, dataType]);

  const networthChange =
    networthValues.reduce((a, b) => a + b, 0) -
    networthValuesEarliest.reduce((a, b) => a + b, 0);

  const percent =
    (networthChange / networthValuesEarliest.reduce((a, b) => a + b, 0)) * 100;

  const percentChange = `${
    (percent < 1 && percent > 0) || (percent > -1 && percent < 0)
      ? percent.toFixed(2)
      : Math.round(percent)
  }%`;

  const renderChange = React.useMemo(() => {
    switch (changeType) {
      case ChangeTypeFiltering.Value:
        return (
          networthChange !== 0 && (
            <>
              <span
                className={networthChange > 0 ? "green-01" : "red-01"}
                style={{ fontWeight: "bold" }}
              >
                {networthChange > 0 && <>&uarr;</>}
                {networthChange < 0 && <>&darr;</>}
                {toMoneyStringNoDecimal(networthChange)}
                {` over ${days}`}
              </span>
            </>
          )
        );
      case ChangeTypeFiltering.Percent:
        return (
          networthChange !== 0 && (
            <>
              <span
                className={networthChange > 0 ? "green-01" : "red-01"}
                style={{ fontWeight: "bold" }}
              >
                {networthChange > 0 && <>&uarr;</>}
                {networthChange < 0 && <>&darr;</>}
                {percentChange}
                {` over ${days}`}
              </span>
            </>
          )
        );
    }
  }, [networthChange, percentChange, changeType]);
  return (
    <div
      style={{
        flexGrow: 1,
        margin: "1rem",
        marginRight: "0",
        position: "relative",
      }}
      className="networth-series"
    >
      {noData && (
        <div
          style={{
            backgroundColor: "black",
            opacity: "0.1",
            width: "100%",
            height: "100%",
            position: "absolute",
            borderRadius: "6px",
          }}
        ></div>
      )}
      <div
        className="flex-row between text-01 mobile-flex-column"
        style={{ paddingTop: "1rem" }}
      >
        <div />
        {networthValues?.length > 0 && (
          <div style={{ textAlign: "left" }}>
            <h6 style={{ fontWeight: "normal" }}>
              Total Value:{" "}
              {toMoneyStringNoDecimal(
                networthValues.reduce((a, b) => a + b, 0)
              )}
            </h6>
            {renderChange}
          </div>
        )}
      </div>
      <div>
        <Chart
          style={{
            opacity: noData ? "0.31" : "1",
          }}
          options={state.options}
          series={state.series}
          type="line"
        />
      </div>
    </div>
  );
};
