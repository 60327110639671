import React from "react";
import "./styles.css";

// Link using <a> tags instead of react dom's <Link>
export const TrayDivider: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "1.5rem 0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "40px",
          justifyContent: "center",
          width: "50%",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            borderBottom: "2px solid  var(--separator-02)",
            alignItems: "center",
            height: "2px",
          }}
        ></div>
      </div>
      <div style={{ margin: "auto 2rem" }}> or </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "40px",
          justifyContent: "center",
          alignSelf: "center",
          width: "50%",
        }}
      >
        <div
          style={{
            borderBottom: "2px solid  var(--separator-02)",
            alignItems: "center",
            height: "2px",
          }}
        ></div>
      </div>
    </div>
  );
};
