import { logout } from "App";
import { DropDownMenu } from "components/core/drop-down-menu";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { Icon, IconAsset } from "components/core/icons";
import { Logo } from "components/core/logo";
import { FinancialPlanStatusBlock } from "components/features/dashboard/components/financial-plan-status-block";
import { NotificationBell } from "components/features/dashboard/components/notification-bell";
import { SupportMessageModal } from "components/features/dashboard/components/support-message-modal";
import { useClickOutDetector } from "core/hooks/click-out";
import { useMobileLayout } from "core/hooks/responsive";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { LeftNavItem } from "../../../../core/left-nav-item";
import { DashboardPages } from "../../pages";
import "./styles.css";

export interface PublicProps {
  onClick?: () => void;
}

type Props = PublicProps;
export const DashLeftNav: React.FC<Props> = (props: Props) => {
  const clickOutRef = useClickOutDetector<HTMLDivElement>(() => {
    setShowSecondaryNavOptions(false);
  });
  const mobileLayout = useMobileLayout();
  const [showSupportModal, setShowSupportModal] =
    React.useState<boolean>(false);
  const history = useHistory();

  const leftPadding = mobileLayout ? "1.5rem" : "1rem";
  const [showSecondaryNavOptions, setShowSecondaryNavOptions] =
    React.useState<boolean>(false);
  return (
    <div className="left-nav-container">
      {showSecondaryNavOptions && (
        <div className="left-nav__options-menu" ref={clickOutRef}>
          <DropDownMenu>
            <DropDownMenuItem
              borderBottom
              text={"Invite a Collaborator"}
              onClick={() => {
                history.push(`${DashboardPages.ManageContact}?addNew=1`);
                setShowSecondaryNavOptions(false);
              }}
            />

            <DropDownMenuItem
              text={<>Help & Support</>}
              onClick={() => {
                setShowSecondaryNavOptions(false);
                setShowSupportModal(true);
              }}
            />

            <DropDownMenuItem
              text="Collaborators"
              onClick={() => {
                setShowSecondaryNavOptions(false);
                history.push(DashboardPages.ManageContact);
              }}
            />

            <DropDownMenuItem
              text="Profile"
              onClick={() => {
                setShowSecondaryNavOptions(false);
                history.push(DashboardPages.Settings);
              }}
              borderBottom
            />

            <DropDownMenuItem
              text={<>Logout</>}
              onClick={() => {
                setShowSecondaryNavOptions(false);
                logout();
              }}
            />
          </DropDownMenu>
        </div>
      )}
      <div className="flex-row between">
        <Logo />
        <div
          className="flex-row between"
          style={{
            marginTop: "-1rem",
            alignSelf: "center",
            marginRight: "2rem",
            cursor: "pointer",
          }}
        >
          <div>
            <NotificationBell />
          </div>
          <div
            style={{ marginLeft: "1rem", zIndex: 10 }}
            onClick={() => {
              setShowSecondaryNavOptions(!showSecondaryNavOptions);
            }}
          >
            <Icon asset={IconAsset.MenuSettings} width="24px" height="24px" />
          </div>
        </div>
      </div>
      <div
        className="left-nav__contents"
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
      >
        <LeftNavItem
          sectionKey="Summary"
          section="Dashboard"
          linkTo={DashboardPages.Summary}
          sectionIcon={IconAsset.MenuDashboard}
        />
        <LeftNavItem
          sectionKey="accounts"
          section="Accounts"
          linkTo={DashboardPages.Integrations}
          sectionIcon={IconAsset.MenuAccounts}
        />
        <LeftNavItem
          sectionKey="cashflow"
          section="Cash Flow"
          linkTo={DashboardPages.CashFlow}
          sectionIcon={IconAsset.MenuCashflow}
        />
        <LeftNavItem
          sectionKey="investments"
          section="Investments"
          linkTo={DashboardPages.InvestmentHoldings}
          sectionIcon={IconAsset.MenuInvestments}
        />
        <LeftNavItem
          sectionKey="credit"
          section="Credit"
          linkTo={DashboardPages.CreditHistory}
          sectionIcon={IconAsset.MenuCredit}
        />
        <LeftNavItem
          sectionKey="income-and-taxes"
          section="Income & Taxes"
          linkTo={DashboardPages.IncomeAndTaxes}
          sectionIcon={IconAsset.MenuTaxes}
        />
        <LeftNavItem
          sectionKey="documents"
          section="Documents"
          linkTo={DashboardPages.Documents}
          sectionIcon={IconAsset.MenuDocuments}
        />

        {mobileLayout && (
          <div className="left-nav__contents-bottom">
            <div className="left-nav-divider"></div>
            <LeftNavItem
              sectionKey="collaborators"
              section="Collaborators"
              linkTo={DashboardPages.ManageContact}
              sectionIcon={IconAsset.MenuCollaborators}
            />
            <div className="left-nav-item-container">
              <div onClick={() => setShowSupportModal(true)} className="">
                <div className="left-nav-item">
                  <div className="left-nav-item__section-header">
                    <Icon
                      asset={IconAsset.MenuSupport2}
                      width="24px"
                      height="24px"
                    />
                    <div style={{ paddingLeft: leftPadding }}></div>
                    <span className="p">Help & Support</span>
                  </div>
                </div>
              </div>
            </div>
            <LeftNavItem
              sectionKey="settings"
              section="Settings"
              linkTo={DashboardPages.Settings}
              sectionIcon={IconAsset.MenuSettings}
            />
            <div className="left-nav-item-container">
              <div
                onClick={() => {
                  logout();
                }}
              >
                <div className="left-nav-item">
                  <div className="left-nav-item__section-header">
                    <Icon
                      asset={IconAsset.MenuLogoutMain}
                      width="24px"
                      height="24px"
                    />
                    <div style={{ paddingLeft: leftPadding }}></div>
                    <span className="p">Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div id="financial-plan">
          <FinancialPlanStatusBlock
            onClick={() => {
              props.onClick?.();
            }}
          />
        </div>
      </div>
      <SupportMessageModal
        showModal={showSupportModal}
        onClose={() => {
          setShowSupportModal(false);
        }}
      />
    </div>
  );
};
