import React from "react";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";

export interface Props {
  hasPrevious: boolean;
  hasNext: boolean;

  page: number;
  totalPages: number;

  goToPage: (n: number) => void;
}

export const PaginatedFooter: React.FC<Props> = ({
  hasPrevious,
  hasNext,
  page,
  totalPages,
  goToPage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        fontSize: "15px",
        lineHeight: "24px",
        marginTop: "-2rem",
      }}
    >
      <div
        style={{
          display: "flex",
          marginRight: "1rem",
        }}
      >
        <div
          style={{
            marginRight: "1rem",
            color: "var(--text-03)",
            lineHeight: "26px",
          }}
        >
          {totalPages === 0 ? 0 : page + 1} of {totalPages}
        </div>
        <div
          onClick={() => {
            if (hasPrevious) {
              goToPage(page - 1);
            }
          }}
          style={{ marginRight: "1rem", cursor: "pointer" }}
        >
          <Icon
            asset={IconAsset.LeftPaginationArrow}
            height="24px"
            width="24px"
            colour={hasPrevious ? Colour.Text01 : "var(--text-03)"}
          />
        </div>
        <div
          onClick={() => {
            if (hasNext) {
              goToPage(page + 1);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <Icon
            asset={IconAsset.RightPaginationArrow}
            height="24px"
            width="24px"
            colour={hasNext ? Colour.Text01 : "var(--text-03)"}
          />
        </div>
      </div>
    </div>
  );
};
