import * as React from "react";
import { AuthorizedPage } from "components/features/login/components/authorized-page";
import { VerifyEmailComponent } from "components/features/verify-email/component";

interface PublicProps {}

type Props = PublicProps;

export const VerifyEmail: React.FC<Props> = (props: Props) => {
  return (
    <AuthorizedPage>
      <VerifyEmailComponent />
    </AuthorizedPage>
  );
};
