// This must be the first line in src/index.js to support older browsers
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// Regular react imports
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { pdfjs } from "react-pdf";
import App from "./App";
import { SessionContextProvider } from "./components/contexts/session-context-provider";
import "./index.css";
import "./react-toggle.css";
import * as serviceWorker from "./serviceWorker";

// https://github.com/wojtekmaj/react-pdf/tree/91dfebab754c97a84bdc0291113aabcab6fb85af#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

Modal.setAppElement("#root");

ReactDOM.render(
  <SessionContextProvider>
    <App />
  </SessionContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
