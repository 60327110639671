import * as React from "react";
import Toggle from "react-toggle";
import { Account, Colour } from "core/models";
import { AccountIcon } from "components/features/dashboard/components/account-icon";
import { AccountsBySubtypeResponse } from "core/queries/accounts";
import { DashboardPages } from "components/features/dashboard/pages";
import { Link } from "react-router-dom";
import { PylonCurrencyInput } from "components/core/currency-input";
import { sanitizeCurrencyString } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { TaxLiabilityInterface } from "core/queries/taxes";
import { Text, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useMobileLayout } from "core/hooks/responsive";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";

export const parseInvestmentSTCG = (s?: string): AccountCG => {
  if (!s || s === "") return {};

  return JSON.parse(s);
};

export const sumInvestmentSTCG = (
  istcg?: AccountCG,
  accountsResponse?: AccountsBySubtypeResponse,
  accountsLoading?: boolean
) => {
  let sum = 0;
  if (!istcg) {
    return sum;
  }

  if (!accountsLoading) {
    const accounts =
      accountsResponse?.accountsBySubtype?.map((x) => x.account_id) ?? [];

    for (const accountID of Object.keys(istcg)) {
      if (!accounts.includes(accountID)) {
        continue;
      }
      // only add this up with the STCG if we have all the values
      const { included, balance, deposits, withdrawls } = istcg[accountID];
      if (included) {
        sum +=
          parseFloat(balance) -
          parseFloat(deposits || "0") +
          parseFloat(withdrawls || "0");
      }
    }
  }
  return sum;
};

export const sumInvestmentLTCG = (
  istcg?: AccountCG,
  accountsResponse?: AccountsBySubtypeResponse,
  accountsLoading?: boolean
) => {
  let sum = 0;
  if (!istcg) {
    return sum;
  }

  if (!accountsLoading) {
    const accounts =
      accountsResponse?.accountsBySubtype?.map((x) => x.account_id) ?? [];

    for (const accountID of Object.keys(istcg)) {
      if (!accounts.includes(accountID)) {
        continue;
      }
      // only add this up with the STCG if we have all the values
      const { included, longTerm } = istcg[accountID];
      if (included) {
        sum += parseFloat(longTerm || "0");
      }
    }
  }
  return sum;
};

export interface AccountCG {
  [key: string]: {
    balance: string;
    included: boolean;
    withdrawls?: string;
    deposits?: string;
    longTerm?: string;
  };
}

interface Props {
  onboardingMode?: boolean;
  stcg?: string;
  investmentSTCG?: AccountCG;
  ltcg?: string;
  advancedCG: boolean;
  shortTermInvestmentIncome: number;
  longTermInvestmentIncome: number;
  setValue: (v: string, set: (i: string) => any) => void;
  setInvestmentSTCG: React.Dispatch<
    React.SetStateAction<AccountCG | undefined>
  >;
  setSTCG: React.Dispatch<React.SetStateAction<string | undefined>>;
  setLTCG: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  saveLiabilityFieldValue: (
    key: keyof Omit<TaxLiabilityInterface, "taxYear">,
    value: string
  ) => void;
  accountsBySubtypeResponse?: AccountsBySubtypeResponse;
}

export const CapGainsCalculator: React.FC<Props> = ({
  onboardingMode,
  longTermInvestmentIncome,
  shortTermInvestmentIncome,
  stcg,
  investmentSTCG,
  ltcg,
  setValue,
  setSTCG,
  setLTCG,
  handleKeyDown,
  saveLiabilityFieldValue,
  accountsBySubtypeResponse,
}) => {
  const [balanceMap, setBalanceMap] = React.useState<AccountCG>({});
  const mobileLayout = useMobileLayout();

  React.useEffect(() => {
    const bm: AccountCG = investmentSTCG || {};

    const accs = accountsBySubtypeResponse?.accountsBySubtype || [];
    for (const a of accs) {
      if (!bm[a.account_id]) {
        bm[a.account_id] = {
          balance: a?.balances?.current?.toString() ?? "0",
          included: true,
        };
      } else {
        bm[a.account_id] = {
          ...bm[a.account_id],
          balance: a?.balances?.current?.toString() ?? "0",
        };
      }
    }

    setBalanceMap({
      ...bm,
    });
  }, [accountsBySubtypeResponse, investmentSTCG]);

  const valueChange = (accountID: string, key: string, v: string) => {
    setBalanceMap({
      ...balanceMap,
      [accountID]: {
        ...balanceMap[accountID],
        balance: balanceMap[accountID]?.balance,
        [key]: v,
      },
    });
  };

  const handleToggle = (accountID: string, v: boolean) => {
    const newBM = {
      ...balanceMap,
      [accountID]: {
        ...balanceMap[accountID],
        included: v,
      },
    };
    setBalanceMap(newBM);
    saveLiabilityFieldValue(
      "investmentCapitalGainsShortTerm",
      JSON.stringify(newBM)
    );
  };

  const updateSTCG = () => {
    saveLiabilityFieldValue(
      "investmentCapitalGainsShortTerm",
      JSON.stringify(balanceMap)
    );
  };

  const renderSimpleCG = () => {
    return (
      <div
        className="flex-row between mt2 mobile-flex-column"
        style={{ width: "100%" }}
      >
        <div>
          <div className="label">
            Additional Short Term Capital Gains/Losses&#160;
            <PylonTooltip
              spacingSide={SpacingSide.Right}
              text={
                <Text type={TextType.Div}>
                  <p>
                    If there any short term capital gains or losses that aren't
                    captured elsewhere on this page, enter those here.
                  </p>
                </Text>
              }
              size={TooltipIconSize.Small}
            />
          </div>
          <PylonCurrencyInput
            placeholder="Short Term Capital Gains/Losses"
            value={parseFloat(stcg || "0")}
            maxLength={12}
            onValueChange={(v) => {
              setValue(v || "", setSTCG);
            }}
            onBlur={(e) => {
              saveLiabilityFieldValue(
                "capitalGainsShortTerm",
                sanitizeCurrencyString(e.target.value)
              );
            }}
            allowDecimals={true}
            decimalSeparator={"."}
            decimalsLimit={2}
            allowNegativeValue={true}
            prefix="$"
            onKeyDown={handleKeyDown}
          />
        </div>
        <div>
          <div className="label">
            Additional Long Term Capital Gains/Losses&#160;
            <PylonTooltip
              spacingSide={SpacingSide.Right}
              text={
                <Text type={TextType.Div}>
                  <p>
                    If there any long term capital gains or losses that aren't
                    captured elsewhere on this page, enter those here.
                  </p>
                </Text>
              }
              size={TooltipIconSize.Small}
            />
          </div>
          <PylonCurrencyInput
            placeholder="Long Term Capital Gains/Losses"
            value={ltcg}
            maxLength={12}
            onValueChange={(v) => {
              setValue(v || "", setLTCG);
            }}
            onBlur={(e) =>
              saveLiabilityFieldValue(
                "capitalGainsLongTerm",
                sanitizeCurrencyString(e.target.value)
              )
            }
            allowDecimals={true}
            decimalSeparator={"."}
            decimalsLimit={2}
            allowNegativeValue={true}
            prefix="$"
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    );
  };

  const renderAdvancedCG = () => {
    const width = mobileLayout ? "100%" : "35%";
    const alignItems = mobileLayout ? "unset" : "flex-end";
    return (
      <div style={{ width: "100%" }}>
        {accountsBySubtypeResponse?.accountsBySubtype?.map((v: Account) => (
          <div
            key={v.account_id}
            style={{ width: "100%", marginBottom: "2rem" }}
          >
            <div>
              <div
                className="flex-row between mobile-flex-column"
                style={{
                  alignItems: mobileLayout ? "unset" : "center",
                  marginBottom: "1rem",
                }}
              >
                <Link
                  to={`${DashboardPages.AccountDetails}?accountID=${v?.account_id}`}
                >
                  <div
                    className="flex-row"
                    style={{ alignItems: "center", width: "60%" }}
                    onClick={() => {}}
                  >
                    <div style={{ width: "3rem", marginRight: "1rem" }}>
                      <AccountIcon account={v} scale={1} />
                    </div>
                    <Text
                      type={TextType.Div}
                      size={"1rem"}
                      colour={Colour.Navy}
                      weight={700}
                    >
                      {v.name}
                    </Text>

                    {onboardingMode && (
                      <div style={{ marginLeft: "1rem" }}>
                        <div
                          className="label"
                          style={{ justifyContent: "flex-end" }}
                        >
                          Today's Balance
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {toMoneyString(v.balances.current)}
                        </div>
                      </div>
                    )}
                  </div>
                </Link>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: mobileLayout ? "unset" : "flex-end",
                  }}
                >
                  <div className="label">Include in 2021 taxes</div>
                  <div style={{ alignSelf: "flex-end" }}>
                    <Toggle
                      icons={false}
                      checked={balanceMap[v.account_id]?.included}
                      onChange={() =>
                        handleToggle(
                          v.account_id,
                          !balanceMap[v.account_id]?.included
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex-row between mobile-flex-column"
              style={{ alignItems }}
            >
              <div style={{ width }}>
                <div className="label">
                  Total Deposits in 2021&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>
                          This is the total amount that you deposited into this
                          account at any time in the tax year.
                        </p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <PylonCurrencyInput
                  placeholder="$5,000"
                  value={balanceMap[v.account_id]?.deposits}
                  maxLength={12}
                  onValueChange={(val) =>
                    valueChange(v.account_id, "deposits", val || "")
                  }
                  onBlur={(e) => updateSTCG()}
                  allowDecimals={true}
                  decimalSeparator={"."}
                  decimalsLimit={2}
                  allowNegativeValue={true}
                  prefix="$"
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div style={{ width }}>
                <div className="label">
                  Total Withdrawls in 2021&#160;
                  <PylonTooltip
                    spacingSide={SpacingSide.Right}
                    text={
                      <Text type={TextType.Div}>
                        <p>
                          This is the total amount that you withdew from this
                          account at any time in the tax year.
                        </p>
                      </Text>
                    }
                    size={TooltipIconSize.Small}
                  />
                </div>
                <PylonCurrencyInput
                  placeholder="$5,000"
                  value={balanceMap[v.account_id]?.withdrawls}
                  maxLength={12}
                  onValueChange={(val) =>
                    valueChange(v.account_id, "withdrawls", val || "")
                  }
                  onBlur={(e) => updateSTCG()}
                  allowDecimals={true}
                  decimalSeparator={"."}
                  decimalsLimit={2}
                  allowNegativeValue={true}
                  prefix="$"
                  onKeyDown={handleKeyDown}
                />
              </div>
              {onboardingMode && (
                <div style={{ width }}>
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="label" style={{ width: "fit-content" }}>
                        Total proceeds from holdings held longer than 12 months
                      </div>
                      <div
                        style={{
                          marginLeft: "0.5rem",
                          marginBottom: "0.25rem",
                        }}
                      >
                        <PylonTooltip
                          text={
                            <Text>
                              Proceeds from holdings that have been held for
                              longer than 12 months will be counted towards your
                              Long Term Capital Gains.
                            </Text>
                          }
                          spacingSide={SpacingSide.None}
                          size={TooltipIconSize.Small}
                        />
                      </div>
                    </div>
                    <PylonCurrencyInput
                      placeholder="$5,000"
                      value={balanceMap[v.account_id]?.longTerm}
                      maxLength={12}
                      onValueChange={(val) =>
                        valueChange(v.account_id, "longTerm", val || "")
                      }
                      onBlur={(e) => updateSTCG()}
                      allowDecimals={true}
                      decimalSeparator={"."}
                      decimalsLimit={2}
                      allowNegativeValue={true}
                      prefix="$"
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
              )}
              {!onboardingMode && (
                <div
                  style={{
                    width: mobileLayout ? "100%" : "25%",
                    textAlign: "right",
                  }}
                >
                  <div className="label" style={{ justifyContent: "flex-end" }}>
                    Today's Balance
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "3rem",
                      marginBottom: "1rem",
                      justifyContent: "flex-end",
                    }}
                  >
                    {toMoneyString(v.balances.current)}
                  </div>
                </div>
              )}
            </div>
            {!onboardingMode && (
              <div>
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="label" style={{ width: "fit-content" }}>
                      Total proceeds from holdings held longer than 12 months
                    </div>
                    <div
                      style={{ marginLeft: "0.5rem", marginBottom: "0.25rem" }}
                    >
                      <PylonTooltip
                        text={
                          <Text>
                            Proceeds from holdings that have been held for
                            longer than 12 months will be counted towards your
                            Long Term Capital Gains.
                          </Text>
                        }
                        spacingSide={SpacingSide.None}
                        size={TooltipIconSize.Small}
                      />
                    </div>
                  </div>
                  <PylonCurrencyInput
                    placeholder="$5,000"
                    value={balanceMap[v.account_id]?.longTerm}
                    maxLength={12}
                    onValueChange={(val) =>
                      valueChange(v.account_id, "longTerm", val || "")
                    }
                    onBlur={(e) => updateSTCG()}
                    allowDecimals={true}
                    decimalSeparator={"."}
                    decimalsLimit={2}
                    allowNegativeValue={true}
                    prefix="$"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            )}
          </div>
        ))}

        {(accountsBySubtypeResponse?.accountsBySubtype?.length || 0) > 0 && (
          <div style={{ width: "100%" }} className="flex-row between ">
            <div>
              <div className="label" style={{ color: "var(--navy)" }}>
                Your short term taxable liability
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "3rem",
                  fontSize: "1.25rem",
                  fontWeight: 500,
                }}
              >
                {toMoneyString(shortTermInvestmentIncome || 0)}
              </div>
            </div>
            <div>
              <div className="label" style={{ color: "var(--navy)" }}>
                Your long term taxable liability
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "3rem",
                  fontSize: "1.25rem",
                  fontWeight: 500,
                }}
              >
                {toMoneyString(longTermInvestmentIncome || 0)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {/* {renderAdvancedCG()} */}
      {renderSimpleCG()}
    </>
  );
};
