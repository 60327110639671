import React from "react";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Icon, IconAsset } from "components/core/icons";
import { Link } from "react-router-dom";
import { Logo } from "components/core/logo";
import { PublicPageLinks } from "components/features/public";
import "./styles.css";

interface Props {
  redirectToHome: boolean;
  hideMenu?: boolean;
}
export const PublicMobileNavigation: React.FC<Props> = ({
  redirectToHome,
  hideMenu,
}) => {
  const [showMenuClose, setShowMenuClose] = React.useState<boolean>(false);
  return (
    <>
      {hideMenu && (
        <div style={{ padding: "2vh 4vw" }}>
          <div className="flex-row between">
            <div>
              <Logo
                redirectTo={
                  redirectToHome ? "/landing/main" : DashboardPages.Summary
                }
                inverse={showMenuClose}
                scale={1.25}
              />
            </div>
            <div className="login-link">
              <Link to="/login">Log In</Link>
            </div>
          </div>
        </div>
      )}
      {!hideMenu && showMenuClose && (
        <div className="mobile-login-menu">
          <div className="flex-row between">
            <div>
              <Logo
                redirectTo={redirectToHome ? "/" : DashboardPages.Summary}
                inverse={showMenuClose}
                scale={1.25}
              />
            </div>
            <div></div>
            <div>
              <div
                style={{
                  display: `${!showMenuClose ? "flex" : "none"}`,
                  marginTop: "2vw",
                }}
                onClick={() => {
                  setShowMenuClose(true);
                }}
              >
                <Icon asset={IconAsset.Menu} width="40px" height="20px" />
              </div>
              <div
                style={{
                  display: `${showMenuClose ? "flex" : "none"}`,
                  marginTop: "2vw",
                }}
                onClick={() => {
                  setShowMenuClose(false);
                }}
              >
                <Icon
                  asset={IconAsset.MenuCloseWhite}
                  width="40px"
                  height="20px"
                  colour={Colour.White}
                />
              </div>
            </div>
          </div>
          <div
            className="links"
            onClick={() => {
              setShowMenuClose(false);
            }}
          >
            <div>
              <div className="spacer"></div>
              <div>
                <Link to={PublicPageLinks.Privacy}>
                  <h3>Privacy</h3>
                </Link>
              </div>
            </div>
            <div>
              <div className="spacer"></div>
              <div>
                <Link to={PublicPageLinks.Term}>
                  <h3>Terms</h3>
                </Link>
              </div>
            </div>
            <div>
              <div className="spacer"></div>
              <div>
                <Link to="/security">
                  <h3>Security</h3>
                </Link>
              </div>
            </div>

            <div>
              <div className="spacer"></div>
              <div>
                <Link to="/login">
                  <h3>Log In</h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {!hideMenu && !showMenuClose && (
        <div className="mobile-login-menu-closed">
          <div className="flex-row between">
            <div>
              <Logo
                redirectTo={
                  redirectToHome ? "/landing/main" : DashboardPages.Summary
                }
                scale={1.25}
              />
            </div>
            <div></div>
            <div>
              <div
                style={{
                  display: `${!showMenuClose ? "flex" : "none"}`,
                  marginTop: "2vw",
                }}
                onClick={() => {
                  setShowMenuClose(true);
                }}
              >
                <Icon asset={IconAsset.Menu} width="40px" height="20px" />
              </div>
              <div
                style={{ display: `${showMenuClose ? "flex" : "none"}` }}
                onClick={() => {
                  setShowMenuClose(false);
                  //onMenuCloseClick?.();
                }}
              >
                <Icon
                  asset={IconAsset.MenuClose}
                  width="40px"
                  height="20px"
                  colour={Colour.Gray02}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
