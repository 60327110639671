import * as React from "react";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { Privacy } from "components/features/dashboard/pages/settings/privacy";

export const DashboardPrivacy: React.FC = () => {
  return (
    <PageWrapper>
      <Privacy />
    </PageWrapper>
  );
};
