import React, { useState } from "react";
import { AccountsHeader } from "../../../components/header";
import { Input } from "components/core/input";
import { LeafButton } from "components/core/leaf-button";
import { LoginFormWrapper } from "components/features/login/components/login-form-input";
import { LoginPages } from "components/features/login/pages";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { requestPasswordReset } from "core/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import "./styles.css";

export const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    requestPasswordReset(
      email,
      (data) => {
        if (!data || data.message) {
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={data.message ?? "Unknown error"}
              type={ToastType.Error}
            />
          );
          return;
        }
        toast(
          <PylonToastBody
            title={"Password Reset"}
            body={"An email has been sent to the requested email address"}
          />
        );

        history.push(LoginPages.Login);
      },
      (err) => {
        const msg = err instanceof Error ? err.message : "unknown error";
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={msg}
            type={ToastType.Error}
          />
        );
      }
    );
  };

  return (
    <div className="account-page">
      <AccountsHeader redirectToHome />

      <h2>Forgot your password?</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <LoginFormWrapper>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            required
            value={email}
            onChange={(v) => setEmail(v.currentTarget.value)}
          />
        </LoginFormWrapper>

        <div className="newuser-button-wrapper">
          <LeafButton onClick={handleSubmit}>Continue</LeafButton>
        </div>
      </form>
    </div>
  );
};
