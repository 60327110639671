import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import React, { useState } from "react";
import { Colour } from "core/models";
import { ConfirmationModalOld } from "components/core/modal";
import { differenceInSeconds } from "date-fns";
import { Dropdown } from "react-bootstrap";
import { EditNoteModal } from "components/features/dashboard/components/asset-notes/edit-note-modal";
import { Icon, IconAsset } from "components/core/icons";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { Text, TextD, TextStyle } from "components/core/text";
import { timeSince } from "core/utils";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { UserDisplayName } from "core/utils/profile";
import "./styles.css";
import {
  AssetNote,
  AssetNoteVisibilityPermission,
  DeleteAssetNoteMutationInput,
  DELETE_ASSET_NOTE_MUTATION,
} from "core/queries/asset-notes";

export interface Props {
  note: AssetNote;
  owner: boolean;
  editor: boolean;
  onDelete: () => void;
}

export const AssetNoteRow: React.FC<Props> = ({
  note,
  owner,
  editor,
  onDelete,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const menuRef = React.useRef<HTMLDivElement>(null); // Hack to dismiss menu on clicking an item

  const createdAt = new Date(note.createdAt);
  const modifiedAt = new Date(note.lastModifiedAt);

  const [deleteNoteMutation, { loading: deleteLoading }] = useMutation<
    {},
    DeleteAssetNoteMutationInput
  >(DELETE_ASSET_NOTE_MUTATION, {
    variables: {
      id: note.id,
    },
    onCompleted: () => {
      toast(
        <PylonToastBody
          title={`Successfully deleted note`}
          body={`Your note has been deleted`}
        />
      );
      onDelete?.();
    },
    onError: (err) => {
      console.error("error deleting note", err);
      toast(
        <PylonToastBody
          title={`Failed to delete note`}
          body={`We couldn't delete the note. Please try again`}
          type={ToastType.Error}
        />
      );
    },
  });

  const privateNote =
    note.visibility === AssetNoteVisibilityPermission.VisibleToOwnerOnly;
  const disabled = deleteLoading;

  return (
    <div className="flex-column">
      <div className="flex-row gap-half align-center">
        <TextD
          style={TextStyle.M15SM}
          colour={privateNote ? Colour.Purple800 : Colour.Black}
        >
          {UserDisplayName(note.owner)}
        </TextD>
        {privateNote && (
          <div
            style={{
              backgroundColor: Colour.Purple50,
              padding: "0.125rem 0.25rem",
            }}
          >
            <TextD style={TextStyle.M11} colour={Colour.Purple800}>
              Marked as private
            </TextD>
          </div>
        )}
        <TextD
          style={TextStyle.M11}
          colour={privateNote ? Colour.Purple300 : Colour.Gray500}
        >
          {timeSince(createdAt)} ago
        </TextD>
        {differenceInSeconds(modifiedAt, createdAt) > 1 && (
          <TextD
            style={TextStyle.M11}
            colour={privateNote ? Colour.Purple300 : Colour.Gray500}
          >
            (Edited)
          </TextD>
        )}

        {(owner || editor) && (
          <div
            style={{
              marginLeft: "auto",
            }}
            ref={menuRef}
          >
            <Dropdown alignRight className="asset-note__menu" bsPrefix="pylon">
              <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
                <Icon
                  asset={IconAsset.HamburgDot}
                  width="24px"
                  height="24px"
                  colour={Colour.Black}
                />
              </DropdownToggle>
              <DropdownMenu className="asset-note__menu-item-wrapper">
                <div
                  className="asset-note__menu-item"
                  onClick={() => {
                    setShowEditModal(true);
                    menuRef?.current?.click();
                  }}
                >
                  <div
                    style={{
                      padding: "0.5rem",
                    }}
                  >
                    <Icon
                      asset={IconAsset.Edit}
                      width="24px"
                      height="24px"
                      colour={Colour.Black}
                    />
                  </div>
                  <TextD style={TextStyle.M15} colour={Colour.Black}>
                    Edit
                  </TextD>
                </div>
                <div
                  className="asset-note__menu-item"
                  onClick={() => {
                    setShowDeleteModal(true);
                    menuRef?.current?.click();
                  }}
                >
                  <div
                    style={{
                      padding: "0.5rem",
                    }}
                  >
                    <Icon
                      asset={IconAsset.Trashcan}
                      width="24px"
                      height="24px"
                      colour={Colour.Black}
                    />
                  </div>
                  <TextD style={TextStyle.M15} colour={Colour.Black}>
                    Delete
                  </TextD>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>

      <TextD
        style={TextStyle.M15}
        whiteSpace="break-spaces"
        colour={privateNote ? Colour.Purple600 : Colour.Black}
      >
        {note.note}
      </TextD>

      <EditNoteModal
        show={showEditModal}
        note={note}
        onClose={() => setShowEditModal(false)}
        onUpdate={() => {
          setShowEditModal(false);
        }}
      />

      <ConfirmationModalOld
        width={"22rem"}
        showModal={showDeleteModal}
        children={
          <>
            <Text weight={600}>Are you sure you want to delete this note?</Text>
          </>
        }
        onAction={() => {
          if (!disabled) {
            deleteNoteMutation();
          }

          setShowDeleteModal(false);
        }}
        actionButtonText={"Delete"}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        cancelButtonText="Cancel"
      />
    </div>
  );
};
