import _ from "lodash";
import React, { useContext } from "react";
import { BillionaireCalculator } from "components/features/dashboard/components/planning/calculators/billionaire";
import { CarCalculator } from "components/features/dashboard/components/planning/calculators/car";
import { ChildcareCalculator } from "components/features/dashboard/components/planning/calculators/childcare";
import { Colour } from "core/models";
import { HouseCalculator } from "components/features/dashboard/components/planning/calculators/house";
import { IncomeExpenseSection } from "components/features/dashboard/components/planning/income-expense-section";
import { MillionaireCalculator } from "components/features/dashboard/components/planning/calculators/millionaire";
import { PlanContext } from "components/contexts/plan-context-provider";
import { SelectPlanOption } from "components/features/dashboard/components/planning/option-selector";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  PlanOptionAsset,
  PlanOptions,
} from "components/features/dashboard/components/planning/models";
import {
  FetchScenarioPlanSettingResponse,
  FETCH_SCENARIO_PLAN_SETTING,
} from "core/queries/scenario-plan-settings";

export interface Props {
  selectedOption?: PlanOptions;
}

export const Planning: React.FC<Props> = ({ selectedOption }) => {
  const { setHouseholdIncome, setTaxes, setMonthlyExpense } =
    useContext(PlanContext);

  useQuery<FetchScenarioPlanSettingResponse>(FETCH_SCENARIO_PLAN_SETTING, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const setting = data?.scenarioPlanSetting;
      // Update default values once data is loaded
      if (setting) {
        if (!_.isNil(setting.householdIncome)) {
          setHouseholdIncome(setting.householdIncome);
        }

        if (!_.isNil(setting.taxes)) {
          setTaxes(setting.taxes);
        }

        if (!_.isNil(setting.monthlyExpenses)) {
          setMonthlyExpense(setting.monthlyExpenses);
        }
      }
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            paddingTop: "3rem",
            width: "40%",
          }}
        >
          <IncomeExpenseSection />
        </div>
        <div
          style={{
            paddingTop: "6rem",
            paddingLeft: "2rem",
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SelectPlanOption selectedOption={selectedOption} />
        </div>
      </div>
      {selectedOption && (
        <div className="plan-detail-section">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text
              type={TextType.Div}
              style={TextStyle.L14}
              format={TextFormat.UpperCase}
              colour={Colour.Navy}
            >
              {PlanOptionAsset[selectedOption].name} CALCULATOR
            </Text>
            <div
              style={{
                marginLeft: "3rem",
                marginRight: "1rem",
                border: "1px solid var(--separator-01)",
                flexGrow: 1,
              }}
            ></div>
          </div>
          <div
            style={{
              paddingTop: "2rem",
            }}
          >
            {OptionToComponent[selectedOption]}
          </div>
        </div>
      )}
    </div>
  );
};

const OptionToComponent: Record<PlanOptions, React.ReactElement> = {
  [PlanOptions.Billionaire]: <BillionaireCalculator />,
  [PlanOptions.Car]: <CarCalculator />,
  [PlanOptions.Childcare]: <ChildcareCalculator />,
  [PlanOptions.House]: <HouseCalculator />,
  [PlanOptions.Millionaire]: <MillionaireCalculator />,
};
