import * as React from "react";
import _ from "lodash";
import MaskedInput from "react-text-mask";
import { Account } from "core/models";
import { accountsQuery, AccountsResponse } from "core/queries/accounts";
import { BufferedInput } from "core/components/buffered-input";
import { differenceInYears } from "date-fns";
import { PylonCurrencyInput } from "components/core/currency-input";
import { PylonDatePicker } from "components/core/date-picker";
import { SimpleDropDown } from "components/core/simple-drop-down";
import { TaxContext } from "components/contexts/tax-context";
import { Text, TextStyle, TextType } from "components/core/text";
import { toMoneyStringNoDecimal } from "core/utils";
import { useMutation, useQuery } from "@apollo/client";
import "./styles.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";
import {
  AccountSubtypeEnum,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  profileQuery,
  profileQueryResponse,
  updateProfileMutation,
} from "core/queries/profiles";

const SAVINGS_ACCOUNT_ENUMS: string[] = [
  AccountSubtypeEnum.SavingsAccounts,
  AccountSubtypeEnum.CheckingAccounts,
  AccountSubtypeEnum.MoneyMarketAccounts,
];

export const InsuranceCalculator: React.FC = () => {
  const [updateProfile] = useMutation(updateProfileMutation);

  const { data: profileData, loading: profileLoading } =
    useQuery<profileQueryResponse>(profileQuery);

  const { earnedIncome, totalBonusIncome, totalCommissionIncome } =
    React.useContext(TaxContext);

  const incomeTotal = React.useMemo(() => {
    let income = 0;
    if (earnedIncome) {
      income += Number.parseFloat(earnedIncome);
    }
    if (totalBonusIncome) {
      income += totalBonusIncome;
    }
    if (totalCommissionIncome) {
      income += totalCommissionIncome;
    }

    return income;
  }, [earnedIncome, totalBonusIncome, totalCommissionIncome]);
  const [income, setIncome] = React.useState("0");
  React.useEffect(() => {
    if (incomeTotal !== Number.parseFloat(income)) {
      setIncome(incomeTotal?.toFixed(0));
    }
    // Set value to default once we pull
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeTotal]);

  const [mortgage, setMortgage] = React.useState("0");
  const [otherDebt, setOtherDebt] = React.useState("0");
  const [savings, setSavings] = React.useState("0");
  const [childEducation, setChildEducation] = React.useState("0");

  const { loading: accountsLoading } = useQuery<AccountsResponse>(
    accountsQuery,
    {
      onCompleted: (d) => {
        setMortgage(
          sumAccounts(
            d,
            (acc) => acc.subtype === AccountSubtypeEnum.Mortgage
          ).toFixed(0)
        );

        setOtherDebt(
          sumAccounts(d, (acc) => {
            return (
              acc.subtype !== AccountSubtypeEnum.Mortgage &&
              !!acc.typeProperties?.isLiability
            );
          }).toFixed(0)
        );

        setSavings(
          sumAccounts(d, (acc) => {
            return (
              SAVINGS_ACCOUNT_ENUMS.includes(acc.subtype) ||
              acc.type === AccountType.NonRetirement
            );
          }).toFixed(0)
        );
      },
    }
  );

  const postalCodeChangeHandler = (postalCode?: string) => {
    if (!postalCode) {
      return;
    }
    updateProfile({
      variables: {
        profile: {
          postalCode: postalCode,
        },
      },
    });
  };
  const genderChangeHandler = (gender?: string) => {
    if (!gender) {
      return;
    }
    updateProfile({
      variables: {
        profile: {
          gender: gender,
        },
      },
    });
  };
  const dateOfBirthChangeHandler = (date: Date) => {
    updateProfile({
      variables: {
        profile: {
          dob: date ? date.getTime() : null,
        },
      },
    });
  };

  const age = React.useMemo(() => {
    if (!profileData?.profile?.dob) {
      return null;
    }
    const dob = new Date(profileData?.profile?.dob);
    return differenceInYears(new Date(), dob);
  }, [profileData?.profile?.dob]);

  const insuranceAmount = age
    ? (67 - age) * v(income) +
      v(mortgage) +
      v(otherDebt) +
      v(childEducation) -
      v(savings)
    : null;

  let insuranceText = "Please fill out the information below";
  if (!_.isNil(insuranceAmount)) {
    if (insuranceAmount > 0) {
      insuranceText = toMoneyStringNoDecimal(insuranceAmount);
    } else {
      insuranceText = "You might be OK without a life insurance right now.";
    }
  }

  return (
    <div className="flex-column insurance-fields">
      <div className="flex-row align-center">
        <Text style={TextStyle.TitlePoppins} type={TextType.Div}>
          Life Insurance Amount You need
        </Text>
        <PylonTooltip
          text="We calculate the amount needed based on what would be needed to cover your income in case something were to happen to you."
          size={TooltipIconSize.Small}
          spacingSide={SpacingSide.Left}
        />
        <Text style={TextStyle.S20} type={TextType.Div}>
          : {insuranceText}
        </Text>
      </div>

      <div
        className="mt2"
        style={{
          fontWeight: 700,
          color: "var(--navy)",
          fontSize: "1.25rem",
        }}
      >
        Basic Information
      </div>
      <div
        className="flex-row align-center insurance-personal-data-input-fields "
        style={{
          paddingLeft: "1rem",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <div
          className="flex-row align-center"
          style={{
            gap: "0.5rem",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Gender
          </Text>
          <div
            style={{
              width: "8rem",
              fontSize: "1rem",
              fontWeight: 500,
            }}
          >
            <SimpleDropDown
              disabled={profileLoading}
              onSelect={genderChangeHandler}
              selectedValue={profileData?.profile.gender}
              options={["Male", "Female", "Other"]}
            />
          </div>
        </div>

        <div
          className="flex-row align-center"
          style={{
            gap: "0.5rem",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Date of birth
          </Text>
          <PylonDatePicker
            disabled={profileLoading}
            className="insurance-date-picker"
            yearOrder="desc"
            customInput={
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
              />
            }
            selected={
              profileData?.profile?.dob
                ? new Date(profileData?.profile?.dob)
                : null
            }
            placeholderText={"MM / DD / YYYY"}
            onChange={(date: any) => dateOfBirthChangeHandler(date)}
          />
        </div>

        <div
          className="flex-row align-center"
          style={{
            gap: "0.5rem",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Income
          </Text>
          <PylonCurrencyInput
            prefix="$"
            value={income}
            onValueChange={(v) => {
              if (v) {
                setIncome(v);
              }
            }}
            style={{
              margin: "unset",
              marginBottom: "unset",
              width: "8.5rem",
            }}
          />
        </div>

        <div
          className="flex-row align-center"
          style={{
            gap: "0.5rem",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Zip Code
          </Text>
          <BufferedInput
            id="zip-code"
            value={profileData?.profile?.postalCode}
            onChange={postalCodeChangeHandler}
            type="number"
            overrides={{
              disabled: profileLoading,
              disableGroupSeparators: true,
              allowNegativeValue: false,
              allowDecimals: false,
              maxLength: 5,
              style: {
                border: "2px solid var(--separator-01) ",
                width: "6rem",
                fontSize: "1rem",
                fontWeight: 500,
              },
            }}
          />
        </div>
      </div>

      <div
        className="mt2"
        style={{
          fontWeight: 700,
          color: "var(--navy)",
          fontSize: "1.25rem",
        }}
      >
        Debt
      </div>

      <div
        className="flex-row align-center"
        style={{
          paddingLeft: "1rem",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <div
          className="flex-row align-center"
          style={{
            gap: "1rem",
          }}
        >
          <div className="insurance-additional-field-label">
            <Text style={TextStyle.FieldLabel} type={TextType.Div}>
              Mortgage
            </Text>
          </div>
          <PylonCurrencyInput
            id="total-debt"
            prefix="$"
            disabled={accountsLoading}
            allowNegativeValue={false}
            value={mortgage}
            onValueChange={(v) => {
              if (v) {
                setMortgage(v);
              }
            }}
            style={{
              marginBottom: "unset",
            }}
          />
        </div>

        <div
          className="flex-row align-center"
          style={{
            gap: "1rem",
          }}
        >
          <div className="insurance-additional-field-label">
            <Text style={TextStyle.FieldLabel} type={TextType.Div}>
              Other Debts
            </Text>
          </div>

          <PylonCurrencyInput
            id="other-debt"
            disabled={accountsLoading}
            prefix="$"
            allowNegativeValue={false}
            value={otherDebt}
            onValueChange={(v) => {
              if (v) {
                setOtherDebt(v);
              }
            }}
            style={{
              marginBottom: "unset",
            }}
          />
        </div>
      </div>

      <div
        className="mt2"
        style={{
          fontWeight: 700,
          color: "var(--navy)",
          fontSize: "1.25rem",
        }}
      >
        Additional Information
      </div>
      <div
        className="flex-row align-center"
        style={{
          paddingLeft: "1rem",
          paddingTop: "1rem",
          gap: "1rem",
        }}
      >
        <div
          className="flex-row align-center"
          style={{
            gap: "1rem",
          }}
        >
          <div className="insurance-additional-field-label">
            <Text style={TextStyle.FieldLabel} type={TextType.Div}>
              Savings & Investments
            </Text>
          </div>

          <PylonCurrencyInput
            id="saving-investment"
            prefix="$"
            disabled={accountsLoading}
            allowNegativeValue={false}
            value={savings}
            onValueChange={(v) => {
              if (v) {
                setSavings(v);
              }
            }}
            style={{
              marginBottom: "unset",
            }}
          />
        </div>

        <div
          className="flex-row align-center"
          style={{
            gap: "1rem",
          }}
        >
          <div className="insurance-additional-field-label flex-row align-center">
            <Text style={TextStyle.FieldLabel} type={TextType.Div}>
              Child Education
            </Text>
            {/*  data from https://research.collegeboard.org/pdf/trends-college-pricing-student-aid-2021.pdf */}
            <PylonTooltip
              text={
                "Average undergraduate cost according to college board for 2021 ~ 2022: \n" +
                "- Public 2 Year In-District: $18,830\n" +
                "- Public 4 Year In-State: $27,330 \n" +
                "- Public 4 Year Out-of-State: $44,150 \n" +
                "- Private 4 Year NonProfit: $55,800 \n"
              }
              size={TooltipIconSize.Small}
              spacingSide={SpacingSide.None}
            />
          </div>

          <PylonCurrencyInput
            id="child-education"
            prefix="$"
            allowNegativeValue={false}
            value={childEducation}
            onValueChange={(v) => {
              if (v) {
                setChildEducation(v);
              }
            }}
            style={{
              marginBottom: "unset",
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginTop: "3rem",
        }}
      />
    </div>
  );
};

const sumAccounts = (
  resp: AccountsResponse,
  filter: (acc: Account) => boolean
) => {
  const accounts = resp?.accounts ?? [];

  return accounts
    .filter(filter)
    .map((acc) => acc.balances?.current ?? 0)
    .reduce((a, b) => {
      return a + b;
    }, 0);
};

const v = (s?: string): number => {
  if (!s) {
    return 0;
  }

  const p = Number.parseFloat(s);
  if (!p) {
    return 0;
  }

  return p;
};
