import { Colour } from "core/models";
import { TextD, TextStyle } from "components/core/text";
import "./styles.css";

export interface Props {
  label: string;
}

export const ModalInputWrapper: React.FC<Props> = ({ label, children }) => {
  if (!label) {
    return <>{children}</>;
  }

  return (
    <div className="flex-column gap-half">
      <TextD style={TextStyle.M15} colour={Colour.Black}>
        {label}
      </TextD>
      {children}
    </div>
  );
};
