import * as React from "react";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { Logo } from "components/core/logo";
import { NotificationBell } from "components/features/dashboard/components/notification-bell";
import { SessionContext } from "components/contexts/session-context-provider";
import { useContext } from "react";
import "./styles.css";

interface Props {
  onMenuOpenClick?: () => void;
  onMenuCloseClick?: () => void;
  isOpen?: boolean;
}
export const MobileHeader: React.FC<Props> = ({
  onMenuOpenClick,
  onMenuCloseClick,
  isOpen,
}) => {
  const [showMenuClose, setShowMenuClose] = React.useState<boolean>(
    isOpen || false
  );

  React.useEffect(() => {
    setShowMenuClose(isOpen || false);
  }, [isOpen]);
  const { sessionToken } = useContext(SessionContext.context);

  if (!sessionToken) {
    return <div className="wizard-header"></div>;
  }

  return (
    <>
      <div className="wizard-header-mobile">
        <div>{showMenuClose && <Logo scale={1.2} />}</div>
        <div></div>
        <div className="flex-row between" style={{ marginTop: "1vh" }}>
          <div className="flex-row between">
            <div style={{ marginRight: "1rem", marginTop: "-5px" }}>
              <NotificationBell />
            </div>
            <div
              style={{ display: `${!showMenuClose ? "flex" : "none"}` }}
              onClick={() => {
                setShowMenuClose(true);
                onMenuOpenClick?.();
              }}
            >
              <Icon asset={IconAsset.Menu} width="40px" height="20px" />
            </div>
            <div
              style={{ display: `${showMenuClose ? "flex" : "none"}` }}
              onClick={() => {
                setShowMenuClose(false);
                onMenuCloseClick?.();
              }}
            >
              <Icon
                asset={IconAsset.MenuClose}
                width="40px"
                height="20px"
                colour={Colour.Gray02}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
