import React, { useState } from "react";
import { Account } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { ModalInput } from "components/core/input/modal-input";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import "./styles.css";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const ManageCarHomeHealthInsuranceDetails: React.FC<Props> = ({
  account,
  isConnected,
  displayOnly,
  onUpdate,
}) => {
  const inputDetails = account.details?.insuranceDetails;
  const [details, setDetails] = useState({
    deductible: inputDetails?.deductible || undefined,
    monthlyPremium: inputDetails?.monthlyPremium || undefined,
  });

  const onBlurUpdate = () => {
    onUpdate?.({
      details: {
        insuranceDetails: details,
      },
    });
  };
  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Monthly Premium"
          value={account.details?.insuranceDetails?.monthlyPremium}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Deductible"
          value={account.details?.insuranceDetails?.deductible}
        />
      </>
    );
  }

  return (
    <>
      <ModalInput
        id="account-purchase-monthly-premium"
        type="money"
        label={"Monthly Premium"}
        value={details.monthlyPremium}
        placeholder="$100"
        onChange={(v) => {
          setDetails({
            ...details,
            monthlyPremium: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-purchase-deductible"
        type="money"
        label={"Deductible"}
        value={details.deductible}
        placeholder="$20,000"
        onChange={(v) => {
          setDetails({
            ...details,
            deductible: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />
    </>
  );
};
