import { Colour } from "core/models";
import { Text, TextStyle, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";

export const displayColouredNumber = (
  n: string | number,
  percision: number = 0,
  forceNegative: boolean = false,
  size: string = "1.125rem"
) => {
  let value = Number.parseFloat(n as string);

  if (forceNegative) {
    value = Math.abs(value) * -1;
  }

  const colour = value >= 0 ? Colour.Text01 : Colour.Orange;

  return (
    <Text type={TextType.Div} style={TextStyle.B16} colour={colour} size={size}>
      {toMoneyString(value, undefined, percision)}
    </Text>
  );
};
