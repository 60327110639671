import { CategorizedTransactions } from "components/features/dashboard/components/cashflow-statements/models";
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View
  } from "@react-pdf/renderer";
import { format } from "date-fns";
import { toMoneyString } from "core/utils";

export interface Props {
  date: Date;
  incomes: CategorizedTransactions[];
  expenses: CategorizedTransactions[];
  incomeTotal: number;
  expenseTotal: number;
}

export const CashflowStatementPDF: React.FC<Props> = ({
  date,
  incomes,
  expenses,
  incomeTotal,
  expenseTotal,
}) => {
  const month = format(date, "MMMM yyyy");
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Cashflow Statement</Text>
        <Text style={styles.subtitle}>{month}</Text>
        <Text style={styles.rowTitle}>Income</Text>
        <View style={styles.table}>
          {incomes.map((r, i) => {
            return (
              <PDFRow
                key={r.category}
                i={i}
                category={r.category}
                value={r.total}
              />
            );
          })}
        </View>
        <SummaryRow text="Total Income" value={incomeTotal} />
        <Text style={styles.paddingTop8} />
        <Text style={[styles.rowTitle]}>Expense</Text>
        <View style={styles.table}>
          {expenses.map((r, i) => {
            return (
              <PDFRow
                key={r.category}
                i={i}
                category={r.category}
                value={r.total}
              />
            );
          })}
        </View>
        <SummaryRow text="Total Expense" value={expenseTotal} />
        <Text style={styles.paddingTop8} />
        <View style={[styles.rowSummary, styles.borderTop]}>
          <Text style={[styles.cell, styles.resultTitle]}>Net Cash Flow</Text>
          {colouredNumber(incomeTotal + expenseTotal)}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 10,
    textAlign: "center",
    marginBottom: 40,
  },
  rowTitle: {
    fontSize: 12,
    fontWeight: 500,
    padding: "8px",
    backgroundColor: "#EEEEEE",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    paddingLeft: "8px",
    paddingRight: "8px",
    marginLeft: "16px",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
  },
  cell: {
    paddingTop: "8px",
    paddingBottom: "8px",
    borderColor: "#000000",
    borderStyle: "solid",
    alignSelf: "stretch",
  },
  description: {
    flexGrow: 1,
    flexShrink: 1,
    color: "#536882",
  },
  border: {
    flexGrow: 1,
    flexShrink: 1,
  },
  number: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    textAlign: "right",
  },
  positive: {
    color: "#57ba8d",
  },
  negative: {
    color: "#f35151",
  },
  rowAlter: {
    backgroundColor: "#EEEEEE",
  },

  rowSummary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    paddingRight: "8px",
  },
  summaryTitle: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
  },
  resultTitle: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
  },
  borderTop: {
    borderTop: "1px solid #000000",
  },
  placeHolder: {},
  paddingTop8: {
    paddingTop: 8,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const SummaryRow: React.FC<{
  text: string;
  value: number;
}> = ({ text, value }) => {
  return (
    <View style={[styles.rowSummary]}>
      <Text style={[styles.cell, styles.description, styles.summaryTitle]}>
        {text}
      </Text>
      {colouredNumber(value, true)}
    </View>
  );
};
const PDFRow: React.FC<{
  i: number;
  category: string;
  value: number;
}> = ({ i, category, value }) => {
  return (
    <View style={i % 2 === 0 ? [styles.row] : [styles.row, styles.rowAlter]}>
      <Text style={[styles.cell, styles.description]}>{category}</Text>
      {colouredNumber(value)}
    </View>
  );
};

const colouredNumber = (n: number, borderTop?: boolean) => {
  return (
    <Text
      style={[
        styles.number,
        styles.cell,
        n < 0 ? styles.negative : styles.positive,
        borderTop ? styles.borderTop : styles.placeHolder,
      ]}
    >
      {toMoneyString(n)}
    </Text>
  );
};
