import { gql } from "@apollo/client";
import { UserData } from "components/features/dashboard/models/sharing";

export enum AssetNoteVisibilityPermission {
  VisibleToOwnerOnly = "VisibleToOwnerOnly",
  VisibleToAll = "VisibleToAll",
}

export interface AssetNote {
  id: string;
  owner: UserData;
  lastModifiedBy: UserData;
  note: string;
  createdAt: string;
  lastModifiedAt: string;
  visibility: AssetNoteVisibilityPermission;
}

export interface FetchAssetNotesResponse {
  getAssetNotes?: {
    notes: AssetNote[];
    total: number;
  };
}

export const ASSET_NOTE_FIELDS = gql`
  fragment AssetNoteFields on AssetNote {
    id
    owner {
      id
      firstName
      lastName
      email
    }
    lastModifiedBy {
      id
      firstName
      lastName
      email
    }
    note
    createdAt
    lastModifiedAt
    visibility
  }
`;

export const FETCH_ASSET_NOTES = gql`
  ${ASSET_NOTE_FIELDS}

  query AddAssetNote(
    $assetID: ID!
    $assetType: String!
    $offset: Int!
    $limit: Int
  ) {
    getAssetNotes(
      assetID: $assetID
      assetType: $assetType
      offset: $offset
      limit: $limit
    ) {
      notes {
        ...AssetNoteFields
      }
      total
    }
  }
`;

export interface CreateAssetNoteMutationInput {
  note: string;
  visibility: AssetNoteVisibilityPermission;
  assetID: string;
  assetType: string;
}

export const CREATE_NEW_ASSET_NOTE_MUTATION = gql`
  ${ASSET_NOTE_FIELDS}

  mutation createNewAssetNote($input: AddAssetNoteInput!) {
    addAssetNote(input: $input) {
      ...AssetNoteFields
    }
  }
`;

export interface EditAssetNoteMutationInput {
  id: string;
  note: string;
  visibility: AssetNoteVisibilityPermission;
}

export const EDIT_ASSET_NOTE_MUTATION = gql`
  ${ASSET_NOTE_FIELDS}

  mutation editNewAssetNote($input: EditAssetNoteInput!) {
    editAssetNote(input: $input) {
      ...AssetNoteFields
    }
  }
`;

export interface DeleteAssetNoteMutationInput {
  id: string;
}

export const DELETE_ASSET_NOTE_MUTATION = gql`
  mutation deleteAssetNote($id: ID!) {
    deleteAssetNote(id: $id)
  }
`;
