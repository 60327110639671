import _ from "lodash";
import Illustration from "./car-illustration.svg";
import { Colour } from "core/models";
import { PlanContext } from "components/contexts/plan-context-provider";
import { PlanDataSerializer } from "components/features/dashboard/components/planning/calculators/data-serializer";
import { PlanOptions } from "components/features/dashboard/components/planning/models";
import { PlanTitle } from "components/features/dashboard/components/planning/calculators/plan-title";
import { PylonCurrencyInput } from "components/core/currency-input";
import { SavedScenario } from "components/features/dashboard/components/planning/saved-scenario";
import { ScenarioPlan } from "core/queries/scenario-plans";
import { SerializablePlan } from "components/features/dashboard/components/planning/calculators/models";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useContext, useState } from "react";
import "./styles.css";
import {
  CashflowResult,
  ExpenseResult,
  ScenarioResultScreen,
} from "components/features/dashboard/components/planning/calculators/result-screen";

type CarData = {
  totalCost?: string;
  downPayment?: string;
  term?: string;
  interestRate?: string;
} & SerializablePlan;

export const CarCalculator: React.FC = () => {
  const { monthlyCashflow } = useContext(PlanContext);

  const getDefaultScenarioData = () => {
    return {
      totalCost: "20000",
      downPayment: "2000",
      term: "72",
      interestRate: "3.25",
    };
  };

  const [scenario, setScenario] = useState<Partial<CarData>>(
    getDefaultScenarioData()
  );

  const [savedScenario, setSavedScenario] = useState<ScenarioPlan | undefined>(
    undefined
  );

  let monthlyPayment: number | undefined = undefined;
  if (
    !_.isNil(scenario.totalCost) &&
    !_.isNil(scenario.downPayment) &&
    !_.isNil(scenario.term) &&
    !_.isNil(scenario.interestRate)
  ) {
    const cost = Number.parseFloat(scenario.totalCost);
    const down = Number.parseFloat(scenario.downPayment);
    const months = Number.parseFloat(scenario.term);
    const rate = Number.parseFloat(scenario.interestRate);

    const amountBorrowed = cost - down;
    if (months > 0 && rate >= 0 && amountBorrowed > 0) {
      const normalizedRate = rate / 100 / 12;

      const rn = Math.pow(1 + normalizedRate, months);

      if (normalizedRate === 0) {
        monthlyPayment = amountBorrowed / months;
      } else {
        monthlyPayment = amountBorrowed * ((normalizedRate * rn) / (rn - 1));
      }
    }
  }

  const calculatedMonthlyCashflow = monthlyPayment
    ? monthlyCashflow - monthlyPayment
    : undefined;

  const namingParts = [scenario.totalCost ?? "Scenario", scenario.term ?? ""];

  const defaultName = namingParts.join(" - ");

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <SavedScenario
        selectedOption={PlanOptions.Car}
        onLoad={(d) => {
          if (!d) {
            setSavedScenario(undefined);
            setScenario(getDefaultScenarioData());
            return;
          }
          const data = PlanDataSerializer.deserialize<CarData>(d.value);

          setScenario({
            ...data,
          });
          setSavedScenario(d);
        }}
      />

      <div
        style={{
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <PlanTitle scenario={savedScenario} />

          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Total Cost (Including taxes and other fees)
            </Text>
            <PylonCurrencyInput
              className="plan-input-box"
              value={scenario.totalCost}
              onValueChange={(v) =>
                setScenario({
                  ...scenario,
                  totalCost: v,
                })
              }
              placeholder="$20,000"
              prefix="$"
              decimalsLimit={2}
              allowNegativeValue={false}
            />
          </div>

          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Term (Months)
            </Text>
            <PylonCurrencyInput
              className="plan-input-box"
              value={scenario.term}
              onValueChange={(v) =>
                setScenario({
                  ...scenario,
                  term: v,
                })
              }
              placeholder="72"
              decimalsLimit={0}
              allowNegativeValue={false}
            />
          </div>

          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Down Payment
            </Text>
            <PylonCurrencyInput
              className="plan-input-box"
              value={scenario.downPayment}
              onValueChange={(v) =>
                setScenario({
                  ...scenario,
                  downPayment: v,
                })
              }
              placeholder="$2,000"
              prefix="$"
              decimalsLimit={2}
              allowNegativeValue={false}
            />
          </div>

          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.L12}
              colour={Colour.Text03}
              format={TextFormat.UpperCase}
            >
              Interest Rate
            </Text>
            <PylonCurrencyInput
              className="plan-input-box"
              value={scenario.interestRate}
              onValueChange={(v) =>
                setScenario({
                  ...scenario,
                  interestRate: v,
                })
              }
              placeholder="3.00%"
              suffix="%"
              decimalsLimit={2}
              allowNegativeValue={false}
            />
          </div>
        </div>

        <div
          style={{
            paddingTop: "2rem",
          }}
        />

        <ScenarioResultScreen
          saveButtonProps={{
            selectedOption: PlanOptions.Car,
            serializedPlan: PlanDataSerializer.serialize({
              ...scenario,
              monthlyCashflow: calculatedMonthlyCashflow,
            }),
            defaultName: defaultName,
            onSave: (sp) => {
              setSavedScenario(sp);
            },
            scenario: savedScenario,
          }}
        >
          <ExpenseResult monthlyCost={monthlyPayment} />
          <CashflowResult newCashflow={calculatedMonthlyCashflow} />
        </ScenarioResultScreen>

        <div
          style={{
            paddingTop: "2rem",
          }}
        >
          <svg width="688" height="220">
            <use xlinkHref={`${Illustration}#illustration`} />
          </svg>
        </div>
      </div>
    </div>
  );
};
