import { ConfirmationModalOld } from "components/core/modal";
import { Input } from "components/core/input";
import { LeafButton, LeafButtonColour } from "components/core/leaf-button";
import { MutationWrapper } from "core/queries/mutation";
import { PlanOptions } from "components/features/dashboard/components/planning/models";
import { SessionContext } from "components/contexts/session-context-provider";
import { ShareTray } from "components/features/dashboard/components/share-tray";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  FETCH_SCENARIO_PLANS,
  SaveScenarioPlanMutationInput,
  SAVE_SCENARIO_PLAN_MUTATION,
  ScenarioPlan,
} from "core/queries/scenario-plans";

export interface Props {
  selectedOption: PlanOptions;
  serializedPlan: string;
  defaultName: string;

  scenario?: ScenarioPlan;

  onSave: (sp: ScenarioPlan) => void;
}

export const SaveScenarioButton: React.FC<Props> = ({
  selectedOption,
  serializedPlan,
  defaultName,
  scenario,
  onSave,
}) => {
  const { userID } = useContext(SessionContext.context);
  const { pushTray } = useContext(SideTrayContext);

  const [saveMutation, { loading }] = useMutation<
    { saveScenarioPlan: ScenarioPlan },
    MutationWrapper<SaveScenarioPlanMutationInput>
  >(SAVE_SCENARIO_PLAN_MUTATION, {
    refetchQueries: [
      {
        query: FETCH_SCENARIO_PLANS,
        variables: {
          type: selectedOption,
        },
      },
    ],
    onCompleted: (data) => {
      onSave(data.saveScenarioPlan);
      setShowModal(false);

      // if (shareAfterSave) {
      //   shareScenarioFunc(data.saveScenarioPlan);
      //   setShareAfterSave(false);
      // }
    },
  });

  // const [shareAfterSave, setShareAfterSave] = useState(false);
  const [name, setName] = useState("");
  const [showModal, setShowModal] = useState(false);

  const shareScenarioFunc = (scenario: ScenarioPlan) => {
    pushTray({
      header: <SimpleSideTrayHeader text="Share Scenario" />,
      children: <ShareTray asset={scenario} />,
    });
  };

  const showPlanName = () => {
    setName(defaultName);
    setShowModal(true);
  };

  return (
    <div
      style={{
        display: "flex",
        paddingTop: "2rem",
      }}
    >
      <LeafButton
        id="save-scenario-button"
        loading={loading}
        onClick={showPlanName}
      >
        {"Save Scenario"}
      </LeafButton>

      {(!scenario || scenario?.owner.id === userID) && (
        <>
          <div style={{ paddingLeft: "1rem" }} />

          <LeafButton
            id="share-scenario-plan"
            disabled={!scenario}
            buttonColour={LeafButtonColour.Transparent}
            onClick={() => {
              if (scenario) {
                shareScenarioFunc(scenario);
              }
              // else {
              //   setShareAfterSave(true);
              //   showPlanName();
              // }
            }}
          >
            <Text type={TextType.Div} style={TextStyle.B16} weight={600}>
              Share Scenario
            </Text>
          </LeafButton>
        </>
      )}

      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        children={
          <div>
            <Text
              type={TextType.Div}
              style={TextStyle.B16}
              weight={600}
              format={TextFormat.UpperCase}
            >
              Enter a name
            </Text>

            <Input
              value={name}
              onChange={(v) => setName(v.currentTarget.value)}
            />
          </div>
        }
        onAction={() => {
          const input: SaveScenarioPlanMutationInput = {
            id: scenario?.id,
            scenarioType: selectedOption,
            scenarioName: name,
            value: serializedPlan,
          };

          saveMutation({
            variables: {
              input: input,
            },
          });
        }}
        actionButtonText={"Save"}
        onCancel={() => {
          setShowModal(false);
        }}
        cancelButtonText="Cancel"
      />
    </div>
  );
};
