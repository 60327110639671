import { Text, TextFormat, TextStyle, TextType } from "components/core/text";
import { SecurityInfoBox } from "components/features/dashboard/pages/security-overview";
import { Colour } from "core/models";
import * as React from "react";

export const OnboardingSecurity: React.FC = () => {
  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            We take your security seriously
            <span style={{ color: "var(--blue-01)" }}>.</span>
          </div>
        </div>
      </div>
      <div className="security-section">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "4vw",
            paddingRight: "4vw",
            height: "100%",
          }}
        >
          <div className="security-info-boxes">
            <SecurityInfoBox
              smallSize={true}
              title={"Data Encryption"}
              description={
                "Your data is encrypted with best-in-class Advanced Encryption Standard (AES) 256-bit encryption protocol."
              }
              footer={
                <Text
                  colour={Colour.Blue01}
                  format={TextFormat.UpperCase}
                  type={TextType.Div}
                  size={"0.75rem"}
                  weight={700}
                >
                  Encryption keeps your data private and safe.
                </Text>
              }
            />
            <SecurityInfoBox
              smallSize={true}
              title={"At Rest Encryption"}
              description={
                "All of our databases and file storage servers, including all automated back ups, are encrypted."
              }
              footer={
                <Text
                  colour={Colour.Blue01}
                  format={TextFormat.UpperCase}
                  type={TextType.Div}
                  size={"0.75rem"}
                  weight={700}
                >
                  At rest encryption prevents unauthorized data retrieval.
                </Text>
              }
            />
            <SecurityInfoBox
              smallSize={true}
              title={"In Transit Encryption"}
              description={
                "All data is encrypted via Transport Layer Security (TLS) when moving between your browser and Pylon."
              }
              footer={
                <Text
                  colour={Colour.Blue01}
                  format={TextFormat.UpperCase}
                  type={TextType.Div}
                  size={"0.75rem"}
                  weight={700}
                >
                  TLS securely connects your browser to our servers.
                </Text>
              }
            />
          </div>
          <div>
            <div className="security-statement">
              <div>
                <Text
                  type={TextType.Div}
                  weight={400}
                  style={TextStyle.Poppins}
                >
                  We don't store your credentials.
                </Text>
              </div>
              <div>
                <Text
                  type={TextType.Div}
                  weight={400}
                  size={"0.875rem"}
                  style={TextStyle.Default}
                >
                  <>
                    Pylon does not store nor has access to any of your login
                    credentials. We integrate with industry leading partners
                    like{" "}
                    <a
                      href="https://plaid.com/safety/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Plaid
                    </a>
                    , and{" "}
                    <a
                      href="https://www.array.com/company/for-consumers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Array
                    </a>{" "}
                    to communicate with financial institutions. Our partners
                    provide read only access to Pylon, meaning we don't transact
                    or change your accounts.
                  </>
                </Text>
              </div>
            </div>
            <hr />
            <div className="security-statement">
              <div>
                <Text
                  type={TextType.Div}
                  weight={400}
                  style={TextStyle.Poppins}
                >
                  We don't look at your data.
                </Text>
              </div>
              <div>
                <Text
                  type={TextType.Div}
                  weight={400}
                  size={"0.875rem"}
                  style={TextStyle.Default}
                >
                  Pylon employees cannot see your data. Your data is encrypted
                  which means it is incomprehensible without being decrypted. We
                  have strict processes in place around decryption and data
                  access requiring Pylon executive team approval and user
                  permission.
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
