import { Colour } from "core/models";
import { Text, TextStyle } from "components/core/text";

export interface Props {
  bannerBackgroundColor: Colour;
}

export const LandingPageBanner: React.FC<Props> = ({
  bannerBackgroundColor,
}) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          backgroundColor: bannerBackgroundColor,
          height: "64px",
          zIndex: 10000,
        }}
      >
        <div
          style={{
            paddingLeft: "56px",
          }}
        >
          <Text style={TextStyle.Sharp24Bold} colour={Colour.White}>
            Pylon
          </Text>
        </div>
      </div>

      {/* Filler component to adjust the height */}
      <div
        style={{
          height: "64px",
        }}
      />
    </>
  );
};
