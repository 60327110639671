import * as React from "react";
import qs from "qs";
import { DashboardPages } from "components/features/dashboard/pages";
import { DocumentIcon } from "components/features/dashboard/components/document-icon";
import { DocumentTags } from "components/features/dashboard/components/document-tags";
import { Input } from "components/core/input";
import { LinkType, PylonLink } from "components/core/link";
import { Text, TextStyle, TextType } from "components/core/text";
import { UploadFileProps } from "components/features/dashboard/components/add-document-tray";
import { validDocumentName } from "core/utils";
import "./styles.css";

export interface Props {
  fileToAdd: UploadFileProps;
  disabled: boolean;

  unremovableTagID?: string;

  onChange: (fileToAdd: UploadFileProps) => void;
  onRemove: () => void;
}
export const DocumentUploadComponent: React.FC<Props> = ({
  fileToAdd,
  disabled,
  unremovableTagID,
  onChange,
  onRemove,
}) => {
  const [nameError, setNameError] = React.useState("");

  let actionText = "Remove";
  if (disabled) {
    actionText = "Uploading...";
  }

  return (
    <>
      <div className="add-document-tray__file-group">
        <DocumentIcon file={fileToAdd.file} scale={1.33} />

        <div className="add-document-tray__input-wrapper">
          <form autoComplete="off">
            <Input
              type="text"
              disabled={disabled}
              id={`document-name-${fileToAdd.id}`}
              name="documentname"
              value={fileToAdd.documentName}
              required
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.currentTarget.blur();
                }
              }}
              onChange={(e) => {
                if (e.target.value && !validDocumentName(e.target.value)) {
                  setNameError(
                    `Name need to be less than 250 characters and does not contain special charactesr (/\\?*:|"><)`
                  );
                } else {
                  setNameError("");
                  onChange({
                    ...fileToAdd,
                    documentName: e.target.value,
                  });
                }
              }}
              onBlur={() => {
                setNameError("");
              }}
            />
            {nameError && (
              <Text style={TextStyle.Error} type={TextType.Div}>
                {nameError}
              </Text>
            )}
            <div className="add-document-tray__file-name">
              <Text style={TextStyle.Hint} type={TextType.Div}>
                {fileToAdd.file.name}
              </Text>
            </div>
          </form>
        </div>
        <div
          style={{
            paddingLeft: "0.5rem",
            alignSelf: "start",
            paddingTop: "1.25rem",
            cursor: "pointer",
          }}
        >
          {fileToAdd.documentID ? (
            <PylonLink
              to={`${DashboardPages.Documents}?${qs.stringify({
                documentID: fileToAdd.documentID,
              })}`}
              linkType={LinkType.Hint}
            >
              View
            </PylonLink>
          ) : (
            <div
              onClick={() => {
                if (!disabled) {
                  onRemove();
                }
              }}
            >
              <Text style={TextStyle.Hint} type={TextType.Div}>
                {actionText}
              </Text>
            </div>
          )}
        </div>
      </div>
      <div>
        <DocumentTags
          unremovableTagID={unremovableTagID}
          assets={fileToAdd.categories}
          onAdd={(category) =>
            onChange({
              ...fileToAdd,
              categories: fileToAdd.categories.concat(category),
            })
          }
          onRemove={(category) => {
            onChange({
              ...fileToAdd,
              categories: fileToAdd.categories.filter(
                (x) => category.type !== x.type && x.id !== category.id
              ),
            });
          }}
          disabled={disabled}
          openTagsInNewTab
        />
      </div>
      <div className="add-document__bottom-border" />
    </>
  );
};
