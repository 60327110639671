import qs from "qs";
import React from "react";
import { DashboardPages } from "components/features/dashboard/pages";
import { LinkType, PylonLink } from "components/core/link";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useLocalStorage } from "core/utils/browser-storage";
import { useMutation } from "@apollo/client";
import {
  accountCategoriesExamplesQuery,
  accountCategoriesQuery,
  accountsQuery,
} from "core/queries/accounts";
import {
  ExchangeCoinbaseCode,
  ExchangeCoinbaseMutationResponse,
} from "core/queries/coinbase";
import {
  FETCH_NETWORTH_HISTORY,
  FETCH_OVERVIEW_DATA,
} from "core/queries/overview";

export const CoinbaseOauthCallbackPage: React.FC = () => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  const [exchangeCoinbaseCode] = useMutation<ExchangeCoinbaseMutationResponse>(
    ExchangeCoinbaseCode,
    {
      refetchQueries: [
        { query: accountsQuery },
        { query: accountCategoriesQuery },
        { query: accountCategoriesExamplesQuery },
        { query: FETCH_OVERVIEW_DATA },
        { query: FETCH_NETWORTH_HISTORY },
      ],
      onCompleted: (data) => {
        setExistingState("");
        localStorage.removeItem("cbConvertProps");
        localStorage.removeItem("cbState");
        const search = qs.stringify({
          accountID: data.exchangeCoinbaseCode.account_id,
        });

        toast(
          <PylonToastBody title={"Account added successfully"}>
            We just added your account Coinbase for tracking!&nbsp;{" "}
            <PylonLink
              to={`${DashboardPages.AccountDetails}?${search}`}
              linkType={LinkType.Highlight}
            >
              View Account
            </PylonLink>
          </PylonToastBody>,
          {
            closeOnClick: false,
            closeButton: true,
          }
        );
      },
    }
  );
  const history = useHistory();
  const [existingState, setExistingState] = useLocalStorage("cbState", "");
  const [convertToManualProps] = useLocalStorage("cbConvertProps", undefined);
  const oauthCallbackState = query["state"];
  const oauthCallbackCode = query["code"];

  if (
    oauthCallbackCode &&
    oauthCallbackCode !== "" &&
    existingState === oauthCallbackState
  ) {
    exchangeCoinbaseCode({
      variables: {
        code: oauthCallbackCode,
        convert: convertToManualProps && {
          accountID: convertToManualProps?.accountID,
          keepShared: convertToManualProps?.keepShared,
        },
      },
    });
  }

  // Else user entered the page by accident? redirect them to the base integration page instead
  history.replace(DashboardPages.Integrations);

  return <div>Looks like you've accessed this page by accident!</div>;
};
