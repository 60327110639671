import { Account } from "core/models";
import { gql } from "@apollo/client";

export interface PolicyResponse {
  policies: Account[];
}

export interface SaveSavvyPolicyResponse {
  saveSavvyPolicy: PolicyResponse;
}

export const saveSavvyPolicyMutation = gql`
  mutation SaveSavvyPolicy($connectionID: ID!, $accountIDToConvert: ID) {
    saveSavvyPolicy(
      connectionID: $connectionID
      accountIDToConvert: $accountIDToConvert
    ) {
      policies {
        account_id
        name
      }
    }
  }
`;

export const SAVVY_POLICY_HOLDER_FIELDS = gql`
  fragment SavvyPolicyHolderFields on SavvyPolicyHolder {
    address {
      number
      street
      type
      sec_unit_type
      sec_unit_num
      city
      state
      zip
      plus4
      suffix
      prefix
    }
    addressRaw
    email
    name {
      firstName
      middleName
      lastName
    }
    phoneNumber
    isHomeOwner
    isMilitary
    residenceStatus
    tenureInYears
  }
`;
export const SavvyOperatorFields = gql`
  fragment SavvyOperatorFields on SavvyOperator {
    name {
      firstName
      middleName
      lastName
    }
    gender
    maritalStatus
    occupation
    education
    rawEducation
    relationship
    rawRelationship
    birthday
    birthdayRange {
      start
      end
    }
    ageLicensed
    ageLicensedInternationally
    isPrimary
    driversLicenseState
    driversLicenseStatus
    driversLicenseNumber
    hasFullDriversLicenseNumber
    email
    experienceInYears
    addressRaw
    address {
      number
      street
      type
      sec_unit_type
      sec_unit_num
      city
      state
      zip
      plus4
      suffix
      prefix
    }
  }
`;

export const SavvyVehicleFields = gql`
  fragment SavvyVehicleFields on SavvyVehicle {
    year
    inStorage
    isRideSharing
    vin
    make
    model
    type
    annualMileage
    driver {
      firstName
      middleName
      lastName
    }
    use
    garagingLocationRaw
    garagingLocation {
      number
      street
      type
      sec_unit_type
      sec_unit_num
      city
      state
      zip
      plus4
      suffix
      prefix
    }
    discounts {
      name
      discountCents
      rawName
    }
    discountTotalCents
    purchaseDate
    antiTheftDevices
    bodyStyle
    ownershipStatus
    lienHolder
    lienHolderAddress {
      number
      street
      type
      sec_unit_type
      sec_unit_num
      city
      state
      zip
      plus4
      suffix
      prefix
    }
    lienHolderAddressRaw
    premiumCents
    coverages {
      name
      premiumCents
      isDeclined
      perPersonLimitCents
      perAccidentLimitCents
      deductibleCents
      glassDeductibleCents
      perDayLimitCents
      perMonthLimitCents
      perWeekLimitCents
    }
  }
`;

export const SavvyDiscountFields = gql`
  fragment SavvyDiscountFields on SavvyDiscount {
    name
    discountCents
    rawName
  }
`;

export const SavvyIncidentFields = gql`
  fragment SavvyIncidentFields on SavvyIncident {
    id
    driverNameRaw
    driver
    descriptionRaw
    date
    state
    atFault
    paidAmountCents
    liabilityMedicalPaidAmountCents
    type
    typeDetails
  }
`;

export const SavvyDocumentFields = gql`
  fragment SavvyDocumentFields on SavvyDocument {
    url
    urlExpiration
    type
  }
`;

export const SavvyPolicyFields = gql`
  ${SAVVY_POLICY_HOLDER_FIELDS}
  ${SavvyOperatorFields}
  ${SavvyVehicleFields}
  ${SavvyDiscountFields}
  ${SavvyIncidentFields}
  ${SavvyDocumentFields}
  fragment SavvyPolicyFields on SavvyPolicy {
    id
    issuer
    policyType
    policyHolder {
      ...SavvyPolicyHolderFields
    }
    policyNumber
    dateRetrieved
    renewalDate
    issueDate
    canceledDate
    paymentScheduleMonths
    numberOfPayments
    policyTermMonths
    premiumCents
    discountTotalCents
    hasAtFaultAccident
    hasAtFaultClaim
    active
    operators {
      ...SavvyOperatorFields
    }
    vehicles {
      ...SavvyVehicleFields
    }
    discounts {
      ...SavvyDiscountFields
    }
    incidents {
      ...SavvyIncidentFields
    }
    documents {
      ...SavvyDocumentFields
    }
  }
`;
