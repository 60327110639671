import React from "react";
import { Colour } from "core/models";
import { CreditHistoryItem } from "core/queries/credit";
import { getColorForScore } from "components/features/dashboard/components/credit-summary-section/components/credit-score-container";
import { getMonth } from "date-fns";
import { LeafButton, LeafButtonColour } from "components/core/leaf-button";
import { SharedCreditPicker } from "components/features/dashboard/components/credit-history-chart-daily/components/shared-credit-picker";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { UserData } from "components/features/dashboard/models/sharing";

export interface Props {
  history?: CreditHistoryItem[];
  openModal: () => void;
  users?: UserData[];
  setSelectUserID: (userID: string) => void;
}

export const CreditHistorySummary: React.FC<Props> = ({
  history,
  openModal,
  users,
  setSelectUserID,
}) => {
  const monthNames = React.useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const latestScore = history?.[0];

  return (
    <div
      style={{
        paddingTop: "1.5rem",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem",
          height: "25.5rem",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "0.25rem",
        }}
      >
        <div
          style={{
            position: "sticky",
            background: "var(--bg-01)",
            top: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text style={TextStyle.M18} type={TextType.Div}>
            Summary
          </Text>
          <SharedCreditPicker
            users={users || []}
            setSelectUserID={setSelectUserID}
          />
        </div>
        <div
          style={{
            paddingTop: "1rem",
          }}
        />
        <div
          style={{
            display: "flex",
            margin: "0 -0.5rem",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "1rem 0.5rem",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Text
              style={TextStyle.B14}
              format={TextFormat.UpperCase}
              weight={700}
              size={"0.75rem"}
              colour={Colour.Text02}
              type={TextType.Div}
            >
              {latestScore ? "CURRENT CREDIT SCORE" : "Free Credit Score"}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              {latestScore ? (
                <Text size={"1rem"} colour={Colour.Navy} type={TextType.Div}>
                  {monthNames[getMonth(new Date(latestScore.date))]}
                </Text>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      type={TextType.Div}
                      colour={Colour.Gray70}
                      weight={400}
                      size={"1rem"}
                    >
                      Track your credit score for free.{" "}
                      <i>This will not impact your credit score.</i>
                    </Text>
                  </div>

                  <div style={{ marginTop: "1rem" }}>
                    <LeafButton
                      buttonColour={LeafButtonColour.Black}
                      fullWidth
                      onClick={() => openModal()}
                    >
                      Get Started
                    </LeafButton>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                color: getColorForScore(latestScore?.score),
                fontWeight: 700,
              }}
            >
              {latestScore?.score}
            </div>
          </div>
        </div>
        <div
          style={{
            paddingTop: "1rem",
          }}
        />
        {history && history.length > 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={TextStyle.B14}
              format={TextFormat.UpperCase}
              weight={700}
              colour={Colour.Text02}
              type={TextType.Div}
              size={"0.75rem"}
            >
              Credit Score
            </Text>
            {history.map((v, i) => {
              // skip first entry because we render it above
              if (i === 0) {
                return null;
              }
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0.25rem 0",
                  }}
                >
                  <Text
                    size={"0.875rem"}
                    colour={Colour.Gray70}
                    type={TextType.Div}
                  >
                    {monthNames[getMonth(new Date(v.date))]}
                  </Text>
                  <div style={{ display: "flex" }}>
                    <Text
                      size={"0.875rem"}
                      colour={Colour.Navy}
                      type={TextType.Div}
                    >
                      {v.score}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
