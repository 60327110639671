import * as React from "react";
import { Colour } from "core/models";
import { CreditModal } from "components/features/dashboard/components/credit-modal";
import { CreditScoreResponse, getCreditScore } from "core/queries/credit";
import { LeafButton } from "components/core/leaf-button";
import {
  Text,
  TextD,
  TextFormat,
  TextType
  } from "components/core/text";
import { timeSince } from "core/utils";
import { useMobileLayout } from "core/hooks/responsive";
import { useQuery } from "@apollo/client";
// import "./styles.css";

export const getColorForScore = (score?: number) => {
  if (!score) {
    return Colour.Green02;
  }
  if (score < 630) {
    return Colour.Red01;
  } else if (score >= 630 && score < 689) {
    return Colour.Yellow01;
  } else if (score >= 690 && score < 719) {
    return Colour.Green01;
  } else {
    return Colour.Green02;
  }
};

export const CreditScoreContainer: React.FC = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { data } = useQuery<CreditScoreResponse>(getCreditScore);

  const mobileLayout = useMobileLayout();

  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {data?.creditScore?.score ? (
          <div
            style={{
              width: "100%",
            }}
            className="flex-row between mobile-flex-column summary-data-section"
          >
            <div>
              <h3
                style={{
                  color: getColorForScore(data.creditScore?.score),
                }}
              >
                {data.creditScore?.score}
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                type={TextType.Div}
                size={"0.75rem"}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Navy}
              >
                {timeSince(new Date(data.creditScore?.date))} ago
              </Text>
              <Text
                type={TextType.Div}
                size={"1.125rem"}
                weight={500}
                colour={Colour.Text03}
                format={TextFormat.Capitalize}
              >
                Provided by TransUnion
              </Text>
            </div>
          </div>
        ) : (
          <div
            style={{
              margin: mobileLayout ? "1rem 0" : "2.75rem 2rem",
              alignItems: mobileLayout ? "flex-start" : "center",
            }}
            className="flex-row between mobile-flex-column gap-1"
          >
            <div
              style={{
                flex: "1 1",
              }}
            >
              <TextD colour={Colour.Gray70} weight={400} size={"1rem"}>
                Track your credit score for free.{" "}
                <i>This will not impact your credit score.</i>
              </TextD>
            </div>

            <div
              style={{
                flex: "0",
                width: "fit-content",
                whiteSpace: "nowrap",
                alignSelf: mobileLayout ? "center" : undefined,
              }}
            >
              <LeafButton onClick={() => setShowModal(true)}>
                Get Started
              </LeafButton>
            </div>
          </div>
        )}
      </div>
      <CreditModal showModal={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};
