import PngLandingCashflow from "core/assets/landing-cashflow.png";
import { HomeEmails } from "components/home/components/home-emails";
import { LandingPageDefinition } from "components/features/landing";
import { PublicHeader } from "components/features/public/header";
import { PublicMobileNavigation } from "components/features/login/components/mobile-navigation";
import { RegisterButton } from "components/features/landing/components/register";
import { useMobileLayout } from "core/hooks/responsive";
import "../all-landing.css";
import "./styles.css";

export const LandingPageCashflow1: React.FC = () => {
  const mobileLayout = useMobileLayout();
  return (
    <div>
      {!mobileLayout && <PublicHeader transparentBackground hideIcon />}
      {mobileLayout && (
        <PublicMobileNavigation redirectToHome={true} hideMenu={true} />
      )}
      <div className="home">
        <HomeEmails title="Track your monthly cashflow from all your accounts." />
      </div>
    </div>
  );
};

export const LandingPageCashflow1a: LandingPageDefinition = {
  Path: "/landing/cashflow/track-monthly-cashflow-from-all-accounts-a",
  Component: () => {
    return (
      <div className="taxes-1">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <h1 className="title-line-2">
              Track your monthly cashflow from all your accounts.
            </h1>
            <div>
              <RegisterButton optionalParam="skip=true" />
            </div>
          </div>
          <div className="landing-right">
            <img
              src={PngLandingCashflow}
              width="100%"
              alt="Track Monthly Cashflow"
            />
          </div>
        </div>
      </div>
    );
  },
};
