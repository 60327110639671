import { Account } from "core/models";
import { gql } from "@apollo/client";
import { TaxItemizedDeductionInterface } from "core/queries/taxes";

export enum RecurringExpenseType {
  Subscription = "subscription",
  Recurring = "recurring",
  None = "none",
}
export interface AccountTransaction {
  id: string;
  accountID: string;
  date: number;
  authorizedDate: number;
  transactionType: string;
  transactionSubtype: string;
  amount: number;
  merchantName: string;
  categoryID: string;
  category: string;
  paymentChannel: string;
  paymentMeta: string;
  pending: boolean;
  pendingTransactionID: string;
  transactionID: string;
  location: string;
  account: Account;
  name: string;
  excludeFromCashflow: boolean;
  recurringExpenseType: RecurringExpenseType;
  itemizedDeduction?: TaxItemizedDeductionInterface;
}

export interface AccountTransactionsResponse {
  accountTransactions: AccountTransactions;
}

export interface AccountTransactionsInput {
  month: string;
  accountID?: string;
  userIDs?: string[];
}

export interface AccountTransactionSummary {
  maxDate: number;
  minDate: number;
  count: number;
}

export interface AccountTransactions {
  transactions: AccountTransaction[];
  summary: AccountTransactionSummary;
  accounts: Account[];
  count: number;
}

export const ACCOUNT_TRANSACTION_FIELDS = gql`
  fragment AccountTransactionFields on AccountTransaction {
    id
    transactionID
    accountID
    date
    amount
    name
    merchantName
    category
    pending
    recurringExpenseType
    excludeFromCashflow
    itemizedDeduction {
      id
      type
    }
  }
`;

export const FETCH_TRANSACTIONS = gql`
  ${ACCOUNT_TRANSACTION_FIELDS}
  query fetchTransactionsAll(
    $accountID: ID
    $month: String
    $userIDs: [String!]
    $limit: Int
    $offset: Int
  ) {
    accountTransactions(
      accountID: $accountID
      month: $month
      userIDs: $userIDs
      limit: $limit
      offset: $offset
    ) {
      count
      transactions {
        ...AccountTransactionFields
      }
      summary {
        maxDate
        minDate
        count
      }
      accounts {
        account_id
        name
        logo
        mask
        owner {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export interface CashFlow {
  month: number;
  cashflow: number[];
}

export interface CashflowCategorizedSummary {
  category: string;
  total: number;
}

export interface ExpenseIncomePair {
  income: number;
  expenses: number;
}

export interface CashFlowResponse {
  cashflow: {
    monthlyCashflows: {
      ownerID: string;
      monthStr: string;
      cashflow: number[];
      dailyCashflowBreakdown: ExpenseIncomePair[];
      expenseFlow: number[];
      expense: CashflowCategorizedSummary[];
      income: CashflowCategorizedSummary[];
    }[];
    accountIDs: string[];
  };
}

export const FETCH_CASH_FLOW = gql`
  query fetchCashFlow($timezone: String, $month: String) {
    cashflow(timezone: $timezone, month: $month) {
      monthlyCashflows {
        ownerID
        monthStr
        cashflow
        expenseFlow
        dailyCashflowBreakdown {
          income
          expenses
        }
        expense {
          category
          total
        }
        income {
          category
          total
        }
      }
      accountIDs
    }
  }
`;

export interface CategoryExpense {
  category: string;
  expense: number;
}

export interface Expenses {
  categorizedExpenses: CategoryExpense[];
  total: number;
}

export interface ExpensesResponse {
  expenses: Expenses;
}
export const FETCH_EXPENSES = gql`
  query fetchExpenses($month: String!, $accountID: ID) {
    expenses(month: $month, accountID: $accountID) {
      categorizedExpenses {
        category
        expense
      }
      total
    }
  }
`;

export interface UpdateAccountTransactionMutationInput {
  accountID: string;
  transactionID: string;
  excludeFromCashflow?: boolean;
  recurringExpenseType?: string;
  category?: string;
}

export interface UpdateAccountTransactionResponse {
  updateAccountTransaction: AccountTransaction;
}

export const UPDATE_TRANSACTION = gql`
  ${ACCOUNT_TRANSACTION_FIELDS}

  mutation updateAccountTransaction($input: UpdateAccountTransactionInput!) {
    updateAccountTransaction(input: $input) {
      ...AccountTransactionFields
    }
  }
`;
