import * as React from "react";
import { Colour } from "core/models";
import { TextD } from "components/core/text";

export enum PillSize {
  Small = "small",
}

interface Props {
  size: PillSize;
  textColor: Colour;
  backgroundColor: Colour;
}

export const Pill: React.FC<Props> = ({
  textColor,
  size,
  backgroundColor,
  children,
}) => {
  let pillSize = "9px";
  let lineHeight = "12px";
  let padding = "4px 8px";

  switch (size) {
    case PillSize.Small:
      padding = "4px 8px";
      pillSize = "9px";
      lineHeight = "12px";
      break;
    default:
      padding = "4px 8px";
      pillSize = "9px";
      lineHeight = "12px";
      break;
  }

  return (
    <div
      style={{
        background: backgroundColor,
        color: textColor,
        borderRadius: ".5rem",
        padding,
        width: "fit-content",
      }}
    >
      <TextD size={pillSize} lineHeight={lineHeight} weight={600}>
        {children}
      </TextD>
    </div>
  );
};
