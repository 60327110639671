import React from "react";
import Toggle from "react-toggle";
import { Colour } from "core/models";
import { InviteNewUserButton } from "components/features/dashboard/components/invite-new-user-button";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./styles.css";
import {
  CollaborationRelationships,
  FetchMyCollaboratorsResponse,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";

export interface ToggleProps {
  showAll: boolean;
  onShowAllToggle: () => void;
}

export const SpouseToggle: React.FC<ToggleProps> = ({
  showAll,
  onShowAllToggle,
}) => {
  const { data, loading } = useQuery<FetchMyCollaboratorsResponse>(
    FETCH_MY_COLLABORATORS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const hasSpouse = React.useMemo(() => {
    if (data) {
      const collabs = data?.collaborations ?? [];
      return !!collabs.find(
        (c) =>
          c.relationship === CollaborationRelationships.Spouse ||
          c.relationship === CollaborationRelationships.Partner
      );
    }
    return false;
  }, [data]);

  let toggleOption: React.ReactElement;

  if (loading || hasSpouse) {
    toggleOption = (
      <Toggle
        icons={false}
        checked={showAll}
        onChange={onShowAllToggle}
        disabled={loading}
      />
    );
  } else {
    toggleOption = <InviteNewUserButton />;
  }

  return (
    <div
      style={{
        paddingTop: "1rem",
        paddingBottom: "0.5rem",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid var(--separator-01)",
        width: "100%",
        gap: "0.25rem",
        alignItems: "center",
      }}
    >
      <Text
        style={TextStyle.O10}
        type={TextType.Div}
        format={TextFormat.UpperCase}
        size={"0.75rem"}
        colour={Colour.Text02}
      >
        Include spouse financials
      </Text>

      {toggleOption}
    </div>
  );
};
