import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import qs from "qs";
import React, { useContext } from "react";
import { AccountsHeader } from "../../components/header";
import { addUserProperties, trackEvent } from "core/distribution/distribution";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { EMAIL_REGEX, formatMobileNumber, PHONE_REGEX } from "core/utils";
import { Icon, IconAsset } from "components/core/icons";
import { Input } from "components/core/input";
import { LeafButton } from "components/core/leaf-button";
import { LoginContext } from "components/contexts/login-context-provider";
import { LoginFormWrapper } from "components/features/login/components/login-form-input";
import { LoginPages } from "components/features/login/pages";
import { newUser } from "core/api";
import { PublicPageLinks } from "components/features/public";
import { PylonPasswordChecker } from "components/features/password-checker";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SessionContext } from "components/contexts/session-context-provider";
import { SetNewUser } from "components/session-storage/new-user";
import { Text, TextFormat, TextStyle } from "components/core/text";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useSessionStorage } from "core/utils/browser-storage";
import "../styles/styles.css";
import "./styles.css";

interface State {
  email: string;
  password: string;
  confirmedPassword: string;
  mobileNumber: string;
  firstName: string;
  lastName: string;
  dob?: Date;
}

const defaultState = () => ({
  email: "",
  password: "",
  confirmedPassword: "",
  mobileNumber: "",
  firstName: "",
  lastName: "",
});

export const NewUser: React.FC = () => {
  const loginCtx = useContext(LoginContext.context);
  const history = useHistory();
  const { setSessionToken } = useContext(SessionContext.context);
  const [adParams, setAdParams] = useSessionStorage("landing-params", "");

  const [state, setState] = React.useState<State>({
    ...defaultState(),
    email: loginCtx.email ?? "",
    mobileNumber: loginCtx.phoneNumber ?? "",
  });
  const [registrationState, setRegistrationState] = React.useState<number>(0);

  const [creating, setCreating] = React.useState(false);

  const disabled =
    state.password !== state.confirmedPassword ||
    !isPasswordValid(state.password) ||
    creating;

  let nextButtonDisabled = false;

  if (registrationState === 0) {
    nextButtonDisabled = !(
      EMAIL_REGEX.test(state.email) && PHONE_REGEX.test(state.mobileNumber)
    );
  } else if (registrationState === 1) {
    nextButtonDisabled = !(
      (state.firstName && state.lastName)
      //state.dob &&
      //differenceInYears(new Date(), state.dob) >= 18
    );
  } else if (registrationState === 2) {
    nextButtonDisabled = !(
      isPasswordValid(state.password) &&
      state.password === state.confirmedPassword &&
      !creating
    );
  }

  // Let html5 form handle the rest that doesn't require complex logic

  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const invitationCode = query["invitationCode"] as string;
  const taxOnboarding = (query["to"] as string) === "true";
  const skipOnboarding = true;

  const onHandleSubmit = () => {
    if (disabled) {
      return;
    }

    setCreating(true);
    const f = () => {
      handleSubmit(() => {
        setCreating(false);
      });
    };
    f();
  };

  const updateRegistrationState = (i: number) => {
    setRegistrationState(i);
    persistRegistrationState(i);
  };
  const persistRegistrationState = (i: number) => {
    const emailParts = state.email?.split("@");
    gtag("event", `registration_page_${i}`, {
      registrationData: (emailParts?.length || 0) > 0 ? emailParts[0] : "",
    });
    trackEvent(`Registration Page ${i}`, {
      email: state?.email,
      invitationCode,
      taxOnboarding,
    });
    addUserProperties({ email: state?.email, invitationCode, skipOnboarding });
  };

  const handleSubmit = (onComplete: () => void) => {
    const { email, mobileNumber, password, firstName, lastName, dob } = state;
    if (!email || !mobileNumber || !firstName || !lastName) {
      onComplete();
      return;
    }

    newUser(
      email,
      mobileNumber,
      firstName,
      lastName,
      password,
      dob?.getTime(),
      invitationCode,
      (data) => {
        const onboardingCompleteRedirect = `${DashboardPages.Summary}`;

        if (data?.authToken) {
          SetNewUser();
          setSessionToken(data.authToken);
          history.push(onboardingCompleteRedirect);
        } else if (data?.identityToken && data?.maskedPhoneNumber) {
          SetNewUser();
          // user requires login
          loginCtx.setTwoFARequest({
            identityToken: data.identityToken,
            maskedPhoneNumber: data.maskedPhoneNumber,
          });
          loginCtx.setRedirectTo(onboardingCompleteRedirect);
          history.push(LoginPages.LoginTwoFA);
        } else {
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={data?.message ?? "Unknown error"}
              type={ToastType.Error}
            />
          );
        }
        onComplete();
        setAdParams("");
        return;
      },
      (err) => {
        const msg = err instanceof Error ? err.message : "unknown error";
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={msg}
            type={ToastType.Error}
          />
        );
        onComplete();
        setAdParams("");
        return;
      },
      adParams
    );
  };

  const renderForm = () => {
    switch (registrationState) {
      case 0:
        return (
          <div key="0">
            <LoginFormWrapper>
              <Input
                autoFocus
                type="email"
                id="id"
                name="id"
                placeholder="Email address"
                value={state.email}
                onChange={(e) =>
                  setState({
                    ...state,
                    email: e.target.value,
                  })
                }
                onEnter={() => {
                  if (!nextButtonDisabled) {
                    updateRegistrationState(1);
                  }
                }}
                onBlurValidate={[
                  {
                    inputRegex: EMAIL_REGEX,
                    errorMessage: "Please enter a valid email address",
                  },
                ]}
                required
              />
            </LoginFormWrapper>
            <LoginFormWrapper>
              <Input
                type="tel"
                id="mobileNumber"
                name="mobileNumber"
                placeholder="Mobile number"
                value={state.mobileNumber}
                onChange={(e) =>
                  setState({
                    ...state,
                    mobileNumber: formatMobileNumber(e.target.value),
                  })
                }
                onEnter={() => {
                  if (!nextButtonDisabled) {
                    updateRegistrationState(1);
                  }
                }}
                onBlurValidate={[
                  {
                    inputRegex: PHONE_REGEX,
                    errorMessage: "Please enter a valid phone number",
                  },
                ]}
                required
              />
            </LoginFormWrapper>
            <Text
              colour={Colour.Text03}
              textAlign="center"
              size={"0.75rem"}
              weight={600}
              format={TextFormat.UpperCase}
            >
              By entering your mobile number we are able to secure your account
              with two factor authentication.
            </Text>
          </div>
        );
      case 1:
        return (
          <div key="1">
            <LoginFormWrapper>
              <Input
                autoFocus
                type="text"
                id="fname"
                name="fname"
                placeholder="First name"
                autoComplete="given-name"
                value={state.firstName}
                onChange={(e) =>
                  setState({
                    ...state,
                    firstName: e.target.value,
                  })
                }
                onEnter={() => {
                  if (!nextButtonDisabled) {
                    updateRegistrationState(2);
                  }
                }}
                maxLength={50}
              />
            </LoginFormWrapper>
            <LoginFormWrapper>
              <Input
                type="text"
                id="lname"
                name="lname"
                maxLength={50}
                placeholder="Last name"
                autoComplete="family-name"
                value={state.lastName}
                onEnter={() => {
                  if (!nextButtonDisabled) {
                    updateRegistrationState(2);
                  }
                }}
                onChange={(e) =>
                  setState({
                    ...state,
                    lastName: e.target.value,
                  })
                }
              />
            </LoginFormWrapper>
            <div style={{ display: "none" }}>
              <LoginFormWrapper>
                <DatePicker
                  name="bday"
                  wrapperClassName={"login-form-input-wrapper"}
                  className="pylon-login-datepicker"
                  customInput={
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                    />
                  }
                  open={false}
                  selected={state.dob}
                  placeholderText={"MM / DD / YYYY"}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === "Enter") {
                      event.currentTarget.blur();
                      if (!nextButtonDisabled) {
                        updateRegistrationState(2);
                      }
                    }
                  }}
                  onChange={(date: Date) => setState({ ...state, dob: date })}
                />
              </LoginFormWrapper>
            </div>
            <Text
              colour={Colour.Text03}
              textAlign="center"
              size={"0.75rem"}
              weight={600}
              format={TextFormat.UpperCase}
            >
              By entering your age, you confirm you are 18 or older.
            </Text>
          </div>
        );
      case 2:
        return (
          <div key="2">
            {/* Hidden input form to help chrome automatically pick up username and password */}
            <input type="hidden" id="id" name="id" value={state.email} />
            <LoginFormWrapper>
              <Input
                autoFocus
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                autoComplete="new-password"
                value={state.password}
                onEnter={() => {
                  if (!nextButtonDisabled) {
                    onHandleSubmit();
                  }
                }}
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
                onBlurValidate={[
                  {
                    validationFunc: (pw) => !pw || isPasswordValid(pw),
                    errorMessage:
                      "Please use a password that satisfies requirement below",
                  },
                ]}
                required
              />
            </LoginFormWrapper>
            <LoginFormWrapper>
              <Input
                type="password"
                id="confirm-password"
                name="password"
                placeholder="Confirm password"
                autoComplete="new-password"
                value={state.confirmedPassword}
                onEnter={() => {
                  if (!nextButtonDisabled) {
                    onHandleSubmit();
                  }
                }}
                onChange={(e) =>
                  setState({ ...state, confirmedPassword: e.target.value })
                }
                onBlurValidate={[
                  {
                    validationFunc: (pw) => !pw || pw === state.password,
                    errorMessage: "Password mismatch",
                  },
                ]}
                required
              />
            </LoginFormWrapper>
            <PylonPasswordChecker password={state.password} />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <AccountsHeader redirectToHome />

      <div className="account-page">
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h2>Create Your Account</h2>
        </div>
        <div>
          {renderForm()}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {registrationState >= 1 && (
              <div
                style={{
                  width: "3rem",
                  marginRight: "0.5rem",
                  color: "var(--text-03)",
                  border: "2px solid var(--separator-01)",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => updateRegistrationState(registrationState - 1)}
              >
                <Icon
                  asset={IconAsset.BackButton}
                  height={"19px"}
                  width={"11px"}
                />
              </div>
            )}
            <div
              style={{
                minWidth: "10rem",
              }}
            >
              <LeafButton
                fullWidth
                onClick={() => {
                  registrationState === 2
                    ? onHandleSubmit()
                    : updateRegistrationState(registrationState + 1);
                }}
                disabled={nextButtonDisabled}
              >
                {registrationState === 2 ? "Create Account" : "Next"}
              </LeafButton>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "6rem",
            }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => updateRegistrationState(0)}
              className={
                registrationState === 0
                  ? "trustee-user-card__dot"
                  : "trustee-user-card__gray-dot"
              }
            ></div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => updateRegistrationState(1)}
              className={
                registrationState === 1
                  ? "trustee-user-card__dot"
                  : "trustee-user-card__gray-dot"
              }
            ></div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => updateRegistrationState(2)}
              className={
                registrationState === 2
                  ? "trustee-user-card__dot"
                  : "trustee-user-card__gray-dot"
              }
            ></div>
          </div>
        </div>
        <div>
          <Text
            colour={Colour.Text03}
            textAlign="center"
            size={"0.75rem"}
            weight={600}
            format={TextFormat.UpperCase}
            style={TextStyle.Hint}
          >
            By clicking continue I agree to the Pylon{" "}
            <span
              className={"new-user__span-as-link"}
              onClick={() => {
                window.open(PublicPageLinks.Term);
              }}
            >
              terms of services
            </span>{" "}
            and{" "}
            <span
              className={"new-user__span-as-link"}
              onClick={() => {
                window.open(PublicPageLinks.Privacy);
              }}
            >
              privacy policy
            </span>
          </Text>
        </div>
      </div>
    </div>
  );
};

export const isPasswordValid = (password: string): boolean => {
  const hasLowerCase = /.*[a-z].*/;
  const hasUpperCase = /.*[A-Z].*/;
  const hasNumber = /.*[0-9].*/;
  const res =
    !!password &&
    password.length >= 8 &&
    password.length <= 32 &&
    matches(password, hasLowerCase) &&
    matches(password, hasUpperCase) &&
    matches(password, hasNumber);

  return res;
};

const matches = (str: string, regex: RegExp) => {
  const matches = str.match(regex);
  return !!matches && matches.length > 0;
};
