import { gql } from "@apollo/client";
import { UserData } from "components/features/dashboard/models/sharing";

export interface ScenarioPlanSetting {
  id: string;
  taxes?: string;
  householdIncome?: string;
  monthlyExpenses?: string;

  owner: UserData;

  createdAt: string;
  modifiedAt: string;
}

export const SCENARIO_PLAN_SETTING_FIELDS = gql`
  fragment ScenarioPlanSettingFields on ScenarioPlanSetting {
    id
    owner {
      id
      firstName
      lastName
      email
    }
    taxes
    householdIncome
    monthlyExpenses
    createdAt
    modifiedAt
  }
`;

export interface FetchScenarioPlanSettingResponse {
  scenarioPlanSetting: ScenarioPlanSetting;
}

export const FETCH_SCENARIO_PLAN_SETTING = gql`
  ${SCENARIO_PLAN_SETTING_FIELDS}

  query FetchScenarioPlanSetting {
    scenarioPlanSetting {
      ...ScenarioPlanSettingFields
    }
  }
`;

export type SaveScenarioPlanSettingMutationInput = Pick<
  ScenarioPlanSetting,
  "monthlyExpenses" | "householdIncome" | "taxes"
>;

export const SAVE_SCENARIO_PLAN_SETTING_MUTATION = gql`
  ${SCENARIO_PLAN_SETTING_FIELDS}

  mutation saveScenarioPlanSetting($input: SaveScenarioPlanSettingInput!) {
    saveScenarioPlanSetting(input: $input) {
      ...ScenarioPlanSettingFields
    }
  }
`;
