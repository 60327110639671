import * as React from "react";
import { AddTransactionTray } from "components/features/dashboard/components/add-transaction-tray";
import { Colour } from "core/models";
import { ConfirmationModalOld } from "components/core/modal";
import { dateFormatMMMMDDYY, toMoneyString } from "core/utils";
import { Icon, IconAsset } from "components/core/icons";
import { ManageTransactionTray } from "components/features/dashboard/components/manage-transaction-tray";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { Text, TextFormat, TextType } from "components/core/text";
import { useHover } from "core/hooks/hover";
import { useMutation, useQuery } from "@apollo/client";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  DeleteInvestmentTransaction,
  GetInvestmentTransactions,
  GetInvestmentTransactionsResponse,
  Transaction,
} from "core/queries/accounts";

interface Props {
  accountID: string;
}
export const InvestmentAccountTransactions: React.FC<Props> = ({
  accountID,
}) => {
  const { pushTray, popTray } = React.useContext(SideTrayContext);

  const { data } = useQuery<GetInvestmentTransactionsResponse>(
    GetInvestmentTransactions,
    {
      variables: {
        accountID,
      },
    }
  );

  React.useEffect(() => {
    setTransactions(data?.transactions?.transactions);
  }, [data]);

  const [transactions, setTransactions] = React.useState<Transaction[]>();

  return (
    <div>
      {transactions && transactions.length > 0 && (
        <div style={{ marginBottom: "1rem" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "7rem" }}>
              <Text
                type={TextType.Div}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Text03}
                size={"0.625rem"}
              >
                Shares Sold
              </Text>
            </div>
            <div style={{ width: "7rem" }}>
              <Text
                type={TextType.Div}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Text03}
                size={"0.625rem"}
              >
                Price Per Share
              </Text>
            </div>
            <div style={{ width: "7rem" }}>
              <Text
                type={TextType.Div}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Text03}
                size={"0.625rem"}
              >
                Date Sold
              </Text>
            </div>
            <div style={{ width: "7rem" }}>
              <Text
                type={TextType.Div}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Text03}
                size={"0.625rem"}
              >
                Notes
              </Text>
            </div>
            <div style={{ width: "7rem" }}>
              <Text
                type={TextType.Div}
                weight={600}
                format={TextFormat.UpperCase}
                colour={Colour.Text03}
                size={"0.625rem"}
              >
                Proceeds
              </Text>
            </div>
          </div>
          {transactions?.map((t) => (
            <TransactionRow accountID={accountID} transaction={t} />
          ))}
        </div>
      )}
      <LeafButton
        buttonStyle={LeafButtonStyle.LeafSmallDense}
        buttonColour={LeafButtonColour.Transparent}
        onClick={() => {
          pushTray({
            id: `account-add-transaction`,
            header: <SimpleSideTrayHeader text="Add Transaction" />,
            children: (
              <AddTransactionTray
                accountID={accountID}
                onClose={() => popTray()}
              />
            ),
          });
        }}
      >
        <div
          style={{
            marginRight: ".5rem",
            fontSize: ".75rem",
            textTransform: "uppercase",
          }}
        >
          Add Transaction
        </div>
      </LeafButton>
    </div>
  );
};

interface RowProps {
  accountID: string;
  transaction: Transaction;
}

export const TransactionRow: React.FC<RowProps> = ({
  accountID,
  transaction,
}) => {
  const { pushTray, popTray } = React.useContext(SideTrayContext);
  const [hoverRef, isHovering] = useHover<HTMLDivElement>();
  const [showModal, setShowModal] = React.useState(false);

  const [deleteTransaction] = useMutation(DeleteInvestmentTransaction, {
    refetchQueries: [
      {
        query: GetInvestmentTransactions,
        variables: {
          accountID,
        },
      },
    ],
  });

  return (
    <div
      ref={hoverRef}
      style={{
        position: "relative",
        display: "flex",
        cursor: "pointer",
        padding: "0.5rem",
        backgroundColor: isHovering ? "var(--bg-01)" : "",
        borderRadius: "6px",
      }}
      onClick={(e) => {
        e.preventDefault();
        pushTray({
          id: `account-manage-transaction`,
          header: <SimpleSideTrayHeader text="Manage Transaction" />,
          children: (
            <ManageTransactionTray
              accountID={accountID}
              transaction={transaction}
              onClose={() => popTray()}
            />
          ),
          onClose: () => {
            popTray();
          },
        });
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <div style={{ width: "7rem" }}>{transaction.quantity}</div>
        <div style={{ width: "7rem" }}>
          {toMoneyString(transaction.price || 0)}
        </div>
        <div style={{ width: "7rem" }}>
          {dateFormatMMMMDDYY(new Date(transaction.createdAt))}
        </div>
        <div style={{ width: "7rem" }}>{transaction.notes}</div>
        <div style={{ width: "7rem" }}>
          {toMoneyString(transaction.quantity * transaction.price)}
        </div>
      </div>

      <div
        style={{
          display: isHovering ? undefined : "none",
          cursor: "pointer",
          position: "absolute",
          right: 0,
          marginRight: "0.5rem",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
      >
        <Icon
          height="24px"
          width="24px"
          asset={IconAsset.RemoveAsset}
          colour={Colour.Navy}
        />
      </div>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        children={
          <>
            <Text weight={600}>
              Are you sure you want to delete this transaction?
            </Text>
          </>
        }
        onAction={() => {
          deleteTransaction({
            variables: {
              accountID,
              id: transaction.id,
            },
          });
          setShowModal(false);
        }}
        actionButtonText={"Delete"}
        onCancel={() => {
          setShowModal(false);
        }}
        cancelButtonText="Cancel"
      />
    </div>
  );
};
