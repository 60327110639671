import { AccountDetailsv2 } from "components/features/dashboard/pages/account-details/index-version-2";
import { BalanceSheetPage } from "components/features/dashboard/pages/balance-sheet";
import { CashFlow } from "components/features/dashboard/pages/cashflow";
import { CashflowStatementPage } from "components/features/dashboard/pages/cashflow-statements";
import { CoinbaseOauthCallbackPage } from "components/features/dashboard/pages/coinbase-oauth-callback";
import { Collaborations } from "components/features/dashboard/pages/collaborations";
import { ManageTrustedContact } from "components/features/dashboard/pages/contacts/index-version-2";
import { CreditPage } from "components/features/dashboard/pages/credit-history";
import { DocumentCards } from "components/features/dashboard/pages/documents/document-cards";
import { DocumentDetails } from "components/features/dashboard/pages/documents/document-details";
import { DocumentFolderPage } from "components/features/dashboard/pages/documents/document-folder";
import { FinancialPlanPage } from "components/features/dashboard/pages/financial-plan";
import { TaxPageConatiner } from "components/features/dashboard/pages/income-and-taxes/container";
import { InsurancePage } from "components/features/dashboard/pages/insurances";
import { Integrations } from "components/features/dashboard/pages/integrations";
import { InvestmentHoldings } from "components/features/dashboard/pages/investment-holdings";
import { NotificationPage } from "components/features/dashboard/pages/notifications";
import { PlaidOauthCallbackPage } from "components/features/dashboard/pages/plaid-oauth-callback";
import { CalculatorsPage } from "components/features/dashboard/pages/planning";
import { DashboardPrivacy } from "components/features/dashboard/pages/privacy";
import { SecurityOverview } from "components/features/dashboard/pages/security-overview";
import { Settings } from "components/features/dashboard/pages/settings";
import { EmailReset } from "components/features/dashboard/pages/settings/email-reset";
import { PasswordReset } from "components/features/dashboard/pages/settings/password-reset";
import { Summary } from "components/features/dashboard/pages/summary";
import { DashboardTerms } from "components/features/dashboard/pages/terms";
import { useDocumentTitle } from "core/hooks/use-document-title";
import { PylonRoute } from "pages";
import { Redirect, Route, RouteProps, Switch } from "react-router";

export const DashboardPages = {
  Summary: "/dashboard/private/summary",
  Balances: "/dashboard/private/balances",
  IncomeAndTaxes: "/dashboard/private/income-and-taxes",
  Integrations: "/dashboard/private/integrations",
  AccountDetails: "/dashboard/private/integrations/account-details",
  Documents: "/dashboard/private/documents",
  DocumentDetails: "/dashboard/private/documents/document-details",
  DocumentFolder: "/dashboard/private/documents/folder",
  Collaborations: "/dashboard/collaboration",
  CollaborationSummary: "/dashboard/collaboration",
  Settings: "/dashboard/settings",
  SettingsPassword: "/dashboard/settings/password",
  SettingsEmail: "/dashboard/settings/changeemail",
  Notifications: "/dashboard/notifications",
  AddContact: "/dashboard/contacts/add",
  ManageContact: "/dashboard/contacts/manage",
  PlaidOauthCallback: "/dashboard/plaid/oauth-callback",
  CoinbaseOauthCallback: "/dashboard/coinbase/oauth-callback",
  InvestmentHoldings: "/dashboard/investments/holdings",
  Calculators: "/dashboard/private/calculators",
  Onboarding: "/onboarding",
  TaxOnboarding: "/tax-onboarding",
  CashFlow: "/dashboard/cashflow",
  SecurityOverview: "/dashboard/security-overview",
  TermsOverview: "/dashboard/terms-overview",
  PrivacyOverview: "/dashboard/privacy-overview",
  CreditHistory: "/dashboard/private/credit",
  Insurnace: "/dashboard/insurance",
  CashflowStatement: "/dashboard/cashflow/statements",
  BalanceSheet: "/dashboard/private/balance-sheet",
  FinancialPlan: "/dashboard/financial-plan",
};

export const renderDashboardRoutes = () => {
  const routes: PylonRoute[] = [
    {
      index: 37,
      route: DashboardPages.FinancialPlan,
      component: FinancialPlanPage,
      documentTitle: "Financial Onboarding",
    },
    {
      index: 37,
      route: DashboardPages.BalanceSheet,
      component: BalanceSheetPage,
      documentTitle: "Balance Sheet",
    },
    {
      index: 36,
      route: DashboardPages.CashflowStatement,
      component: CashflowStatementPage,
      documentTitle: "Cashflow Statement",
    },
    {
      index: 35,
      route: DashboardPages.Insurnace,
      component: InsurancePage,
      documentTitle: "Insurance",
    },
    {
      index: 34,
      route: DashboardPages.CreditHistory,
      component: CreditPage,
      documentTitle: "Credit",
    },
    {
      index: 33,
      route: DashboardPages.PrivacyOverview,
      component: DashboardPrivacy,
      documentTitle: "Privacy",
    },
    {
      index: 32,
      route: DashboardPages.TermsOverview,
      component: DashboardTerms,
      documentTitle: "Terms",
    },
    {
      index: 31,
      route: DashboardPages.SecurityOverview,
      component: SecurityOverview,
      documentTitle: "Security",
    },
    {
      index: 30,
      route: DashboardPages.Calculators,
      component: CalculatorsPage,
      documentTitle: "Planning",
    },
    {
      index: 21,
      route: DashboardPages.CashFlow,
      component: CashFlow,
      documentTitle: "Cash Flow",
    },
    {
      index: 20,
      route: DashboardPages.PlaidOauthCallback,
      component: PlaidOauthCallbackPage,
      documentTitle: "",
    },
    {
      index: 19,
      route: DashboardPages.CoinbaseOauthCallback,
      component: CoinbaseOauthCallbackPage,
      documentTitle: "",
    },
    {
      index: 16,
      route: DashboardPages.InvestmentHoldings,
      component: InvestmentHoldings,
      documentTitle: "Investments",
    },
    {
      index: 14,
      route: DashboardPages.ManageContact,
      component: ManageTrustedContact,
      documentTitle: "Collaborators",
    },
    {
      index: 13,
      route: DashboardPages.DocumentFolder,
      component: DocumentFolderPage,
      exact: true,
      documentTitle: "Document Folder",
    },
    {
      index: 11,
      route: DashboardPages.DocumentDetails,
      component: DocumentDetails,
      exact: true,
      documentTitle: "Document Details",
    },
    {
      index: 10,
      route: DashboardPages.IncomeAndTaxes,
      component: TaxPageConatiner,
      exact: true,
      documentTitle: "Income and Taxes",
    },
    {
      index: 9,
      route: DashboardPages.Notifications,
      component: NotificationPage,
      exact: true,
      documentTitle: "Notifications",
    },
    {
      index: 8,
      route: DashboardPages.AccountDetails,
      component: AccountDetailsv2,
      exact: true,
      documentTitle: "Account Details",
    },
    {
      index: 6,
      route: DashboardPages.SettingsEmail,
      component: EmailReset,
      exact: true,
      documentTitle: "Email Reset",
    },
    {
      index: 5,
      route: DashboardPages.SettingsPassword,
      component: PasswordReset,
      exact: true,
      documentTitle: "Password Reset",
    },
    {
      index: 4,
      route: DashboardPages.Settings,
      component: Settings,
      exact: true,
      documentTitle: "Settings",
    },
    {
      index: 3,
      route: DashboardPages.Collaborations,
      component: Collaborations,
      exact: true,
      documentTitle: "Collaborators",
    },
    {
      index: 2,
      route: DashboardPages.Integrations,
      component: Integrations,
      documentTitle: "Accounts",
    },
    {
      index: 1,
      route: DashboardPages.Documents,
      component: DocumentCards,
      documentTitle: "Documents",
    },
    {
      index: 0,
      route: DashboardPages.Summary,
      component: Summary,
      documentTitle: "Dashboard",
    },
  ];

  const reactRoutes = routes.map((route) => wrap(route));

  return (
    <Switch>
      <Redirect
        key="redirect-0"
        from="/dashboard/"
        to={DashboardPages.Summary}
        exact
      />
      {reactRoutes}
    </Switch>
  );
};

const wrap = (route: PylonRoute) => {
  return (
    <Route
      exact={route.exact}
      key={route.index}
      path={route.route}
      render={(props: RouteProps) => {
        return (
          <>
            <UseDocumentTitle documentTitle={route.documentTitle} />
            <route.component {...props} />
          </>
        );
      }}
    />
  );
};

const UseDocumentTitle: React.FC<{ documentTitle?: string }> = ({
  documentTitle,
}) => {
  useDocumentTitle(documentTitle || "Pylon");
  return null;
};
