import { AssetType } from "components/features/dashboard/models/sharing";
import { RowCardRecipients } from "components/features/dashboard/components/row-card-recipients";
import { ScenarioPlan } from "core/queries/scenario-plans";
import { useQuery } from "@apollo/client";
import {
  RecipientForAssetQueryResponse,
  recipientsForAssetQuery,
} from "core/queries/collaborations";

export interface Props {
  scenario?: ScenarioPlan;
}

export const ScenarioRecipients: React.FC<Props> = ({ scenario }) => {
  const { data } = useQuery<RecipientForAssetQueryResponse>(
    recipientsForAssetQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        assetID: scenario?.id,
        assetType: AssetType.ScenarioPlan,
      },
      skip: !scenario,
    }
  );

  const recipients = data?.recipientsForAsset?.map((r) => r.user) || [];

  if (recipients.length > 0) {
    return (
      <div>
        <RowCardRecipients recipients={recipients} noDivider />
      </div>
    );
  }
  return null;
};
