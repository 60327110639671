import React from "react";
import { AccountDetails } from "core/models";
import { PylonCurrencyInput } from "components/core/currency-input";
import {
  sanitizeStringNumber,
  sanitizeStringNumberToString,
} from "components/features/dashboard/components/add-account-tray/details-entry/utils";

export interface Props {
  balance?: string;
  onBalanceUpdate: (balance: string) => void;

  details?: AccountDetails;
  onDetailsUpdate: (details: AccountDetails) => void;

  onEnter?: () => void;
}

export const AddCarHomeHealthInsuranceDetailsEntry: React.FC<Props> = ({
  balance,
  onBalanceUpdate,
  details,
  onDetailsUpdate,
  onEnter,
}) => {
  const insuranceDetails = details?.insuranceDetails || {};

  return (
    <>
      <div>
        <div>How much is your coverage</div>
        <PylonCurrencyInput
          id="coverage-balance"
          placeholder="$250,000"
          onValueChange={(v) => {
            onBalanceUpdate(sanitizeStringNumber(v));
          }}
          value={balance || ""}
          prefix={"$"}
          step={1}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>

      <div>
        <div>How much is your monthly premium</div>
        <PylonCurrencyInput
          id="monthly-premium"
          placeholder="$100"
          onValueChange={(v) => {
            onDetailsUpdate({
              insuranceDetails: {
                ...insuranceDetails,
                monthlyPremium: sanitizeStringNumberToString(v),
              },
            });
          }}
          value={insuranceDetails.monthlyPremium}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>

      <div>
        <div>How much is your deductible</div>
        <PylonCurrencyInput
          id="deductible"
          placeholder="$1,000"
          onValueChange={(v) => {
            onDetailsUpdate({
              insuranceDetails: {
                ...insuranceDetails,
                deductible: sanitizeStringNumberToString(v),
              },
            });
          }}
          value={insuranceDetails.deductible}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
    </>
  );
};
