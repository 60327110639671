import { gql } from "@apollo/client";

export interface ProfileInput {
  occupation?: string;
  income?: string;
  spouseIncome?: string;
  filingStatus?: string;
  dob?: number;

  addressLineOne?: string;
  addressLineTwo?: string;
  addressLineThree?: string;
  city?: string;
  stateProvinceRegion?: string;
  postalCode?: string;
  country?: string;

  [index: string]: any;
}

export interface SessionData {
  userID: string;
  sessionToken: string;
  userAgent: string;
  ipAddress: string;
  location: string;
  lastActive: string;
  addedAt: number;
}

export interface UpdateProfileResponse {
  updateProfile: ProfileData;
}

export const PROFILE_FIELDS = gql`
  fragment ProfileFields on Profile {
    id
    firstName
    lastName
    email
    phone
    phoneIsVerified
    occupation
    income
    filingStatus
    spouseIncome
    dob
    sessionData {
      userID
      sessionToken
      userAgent
      ipAddress
      location
      addedAt
    }
    addressLineOne
    addressLineTwo
    addressLineThree
    city
    stateProvinceRegion
    postalCode
    country
    beneficiarySettings
    gender
  }
`;

export const sendSupportMessage = gql`
  mutation SendSupportMessage($message: String!, $files: [Upload!]) {
    sendSupportMessage(message: $message, files: $files)
  }
`;

export const updateProfileMutation = gql`
  ${PROFILE_FIELDS}
  mutation UpdateProfileMutation($profile: ProfileInput!) {
    updateProfile(profile: $profile) {
      ...ProfileFields
    }
  }
`;

export const profileQuery = gql`
  ${PROFILE_FIELDS}
  query GetProfile {
    profile {
      ...ProfileFields
    }
  }
`;

export const clearAllSessionsMutation = gql`
  ${PROFILE_FIELDS}
  mutation ClearAllSessionsMutation {
    # GraphQL requires a parameter for all mutations
    clearAllSessions(profile: null) {
      ...ProfileFields
    }
  }
`;

export interface ProfileData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneIsVerified: string;
  occupation: string;
  income: string;
  spouseIncome: string;
  filingStatus: string;
  dob: number;
  gender: string;
  sessionData: SessionData[];

  addressLineOne?: string;
  addressLineTwo?: string;
  addressLineThree?: string;
  city?: string;
  stateProvinceRegion?: string;
  postalCode?: string;
  country?: string;
  beneficiarySettings?: string;
}

export interface profileQueryResponse {
  profile: ProfileData;
}
