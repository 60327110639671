import { ItemizedDeductionType } from "@pylon/taxes";

export const itemizedDeductionTypeOptions = () => {
  return Object.keys(ItemizedDeductionType).map((key) => {
    return {
      label: key?.split(/(?=[A-Z])/)?.join(" "),
      value: key,
    };
  });
};
