import * as React from "react";
import { Account, Colour } from "../../../../../core/models";
import { EditAccountInformationModal } from "components/features/dashboard/components/manage-account-modal/details/edit-details-modal";
import { Icon, IconAsset } from "components/core/icons";
import { isAccountConnected } from "components/features/dashboard/components/add-account-tray/models";
import { Text, TextStyle, TextType } from "components/core/text";

interface Props {
  account: Account;
}

export const EditAccountQuickAction: React.FC<Props> = ({ account }) => {
  const [showEditModal, setShowEditModal] = React.useState(false);

  if (!account.isOwnerOrEditor) {
    return null;
  }

  const isConnected = isAccountConnected(account.integration_source);

  return (
    <>
      <EditAccountInformationModal
        show={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
        account={account}
        isConnected={isConnected}
      />

      <div
        id="account-details--edit-account"
        className="flex-row align-center"
        onClick={() => {
          setShowEditModal(true);
        }}
        style={{
          cursor: "pointer",
          gap: "0.5rem",
        }}
      >
        <Icon
          asset={IconAsset.Edit}
          width="16px"
          height="16px"
          colour={Colour.Black}
        />
        <Text style={TextStyle.M15} type={TextType.Div}>
          Edit
        </Text>
      </div>
    </>
  );
};
