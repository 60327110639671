import React from "react";
import { AccountDetails } from "core/models";
import { PylonCurrencyInput } from "components/core/currency-input";
import {
  sanitizeStringNumber,
  sanitizeStringNumberToString,
} from "components/features/dashboard/components/add-account-tray/details-entry/utils";

export interface Props {
  balance?: string;
  onBalanceUpdate: (balance: string) => void;

  details?: AccountDetails;
  onDetailsUpdate: (details: AccountDetails) => void;

  onEnter?: () => void;
}

export const AddHomeEquityLineOfCreditDetailsEntry: React.FC<Props> = ({
  balance,
  onBalanceUpdate,
  details,
  onDetailsUpdate,
  onEnter,
}) => {
  const homeEquityLineOfCreditDetails =
    details?.homeEquityLineOfCreditDetails || {};

  return (
    <>
      <div>
        <div>Remaining Balance (Estimates okay)</div>
        <PylonCurrencyInput
          id="validation-example-2-field"
          placeholder="$1,000.00"
          onValueChange={(v) => {
            onBalanceUpdate(sanitizeStringNumber(v));
          }}
          value={balance || ""}
          prefix={"$"}
          step={1}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>

      <div>
        <div>Total Home Equity Line</div>
        <PylonCurrencyInput
          id="minimum-deposit-input"
          placeholder="$100,000"
          onValueChange={(v) => {
            onDetailsUpdate({
              homeEquityLineOfCreditDetails: {
                ...homeEquityLineOfCreditDetails,
                creditLimit: sanitizeStringNumberToString(v),
              },
            });
          }}
          value={homeEquityLineOfCreditDetails.creditLimit}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
    </>
  );
};
