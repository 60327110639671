import * as React from "react";
import { Colour } from "core/models";
import { ConfirmationModalOld } from "components/core/modal";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { Icon, IconAsset } from "components/core/icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { SharedSuccessToast } from "components/core/toasts/components/shared-success-toast";
import { Text } from "components/core/text";
import { toast } from "react-toastify";
import { useHover } from "core/hooks/hover";
import { useMutation } from "@apollo/client";
import { UserData } from "components/features/dashboard/models/sharing";
import { useState } from "react";
import {
  recipientsForAssetQuery,
  recipientsForAssetQueryResponse,
} from "core/queries/collaborations";
import {
  createSharedDataMutation,
  deleteSharedDataMutation,
  getAuditLogForAssetIDQuery,
} from "core/queries/shared-data";

interface Props {
  assetID: string;
  assetType: string;
  recipient: recipientsForAssetQueryResponse;
  compressedRow?: boolean;
}
export const RecipientListRow: React.FC<Props> = (props: Props) => {
  const [hoverRef, isHovering] = useHover<HTMLDivElement>();
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const [showModal, setShowModal] = useState(false);
  const additionalRefetchQueries = onUpdateRefetchQueries ?? [];
  const [createSharedData] = useMutation(createSharedDataMutation, {
    refetchQueries: [
      {
        query: recipientsForAssetQuery,
        variables: {
          assetID: props.assetID,
          assetType: props.assetType,
        },
      },
      {
        query: getAuditLogForAssetIDQuery,
        variables: {
          assetID: props.assetID,
          assetType: props.assetType,
        },
      },
      ...additionalRefetchQueries,
    ],
    onCompleted: () => {
      toast(SharedSuccessToast(props.assetType.toLowerCase()));
    },
    onError: (err) => {
      console.error("Failed to create shared data", err);
    },
  });

  const [deleteSharedData] = useMutation(deleteSharedDataMutation, {
    refetchQueries: [
      {
        query: recipientsForAssetQuery,
        variables: {
          assetID: props.assetID,
          assetType: props.assetType,
        },
      },
      {
        query: getAuditLogForAssetIDQuery,
        variables: {
          assetID: props.assetID,
          assetType: props.assetType,
        },
      },
      ...additionalRefetchQueries,
    ],
  });

  const renderName = (r: UserData) => {
    return r.firstName
      ? `${r.firstName} ${r.lastName}`
      : `${r.email} (pending)`;
  };

  const renderRow = () => (
    <div
      style={{
        border: "1px solid var(--gray-10)",
        borderRadius: ".5rem",
        padding: "1rem",
        marginBottom: "1rem",
      }}
      className={`${props.recipient.isDeleted ? "not-sharing" : "sharing"}`}
    >
      <div className={`recipient-list__row`}>
        <div style={{ flexDirection: "column" }}>
          <div
            style={{
              fontWeight: 500,
              fontSize: "1rem",
            }}
          >
            {renderName(props.recipient.user)}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`shared-label-${props.assetID}`}>
                {props.recipient.isDeleted ? "Share" : "Remove access"}
              </Tooltip>
            }
          >
            {props.recipient.isDeleted ? (
              <>
                <div
                  style={{ margin: "0 0.5rem", cursor: "pointer" }}
                  onClick={() => setShowModal(true)}
                >
                  <Icon
                    height="24px"
                    width="24px"
                    asset={IconAsset.Shared}
                    colour={Colour.Navy}
                  />
                </div>
              </>
            ) : (
              <div
                style={{ margin: "0 0.5rem", cursor: "pointer" }}
                onClick={() => setShowModal(true)}
              >
                <Icon
                  height="24px"
                  width="24px"
                  asset={IconAsset.RemoveAsset}
                  colour={Colour.Navy}
                />
              </div>
            )}
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );

  const renderCompressedRow = () => (
    <div
      ref={hoverRef}
      style={{
        borderBottom: "2px solid var(--gray-10)",
        padding: "0.5rem",
        backgroundColor: (isHovering && "var(--bg-01)") || "",
      }}
    >
      <div className={`recipient-list__row`}>
        <div style={{ flexDirection: "column" }}>
          <div
            style={{
              fontWeight: 400,
              fontSize: "0.875rem",
            }}
          >
            {renderName(props.recipient.user)}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            visibility: isHovering ? "visible" : "hidden",
          }}
        >
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`shared-label-${props.assetID}`}>
                {props.recipient.isDeleted ? "Share" : "Remove access"}
              </Tooltip>
            }
          >
            <div
              style={{ margin: "0 0.5rem", cursor: "pointer" }}
              onClick={() => setShowModal(true)}
            >
              <Icon
                height="24px"
                width="24px"
                asset={IconAsset.Trashcan}
                colour={Colour.Blue01}
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        children={
          <>
            {props.recipient.isDeleted ? (
              <>
                <Text weight={600}>
                  Are you sure you want to reshare this{" "}
                  {props.assetType.toLowerCase()}?
                </Text>
              </>
            ) : (
              <>
                <Text weight={600}>
                  Are you sure you want to remove access for this user?
                </Text>
                <Text weight={400}>You can always reshare it later!</Text>
              </>
            )}
          </>
        }
        onAction={() => {
          if (props.recipient.isDeleted) {
            createSharedData({
              variables: {
                collaborationID: props.recipient.collaborationID,
                assetID: props.assetID,
                assetType: props.assetType,
              },
            });
          } else {
            deleteSharedData({
              variables: {
                id: props.recipient.sharedDataID,
              },
            });
          }
          setShowModal(false);
        }}
        actionButtonText={props.recipient.isDeleted ? "Share" : "Delete"}
        onCancel={() => {
          setShowModal(false);
        }}
        cancelButtonText="Cancel"
      />
      {props.compressedRow ? renderCompressedRow() : renderRow()}
    </>
  );
};
