import qs from "qs";
import React from "react";
import { AccountsHeader } from "../../../components/header";
import { Input } from "components/core/input";
import { isPasswordValid } from "components/features/login/pages/new-user";
import { LeafButton } from "components/core/leaf-button";
import { LoginFormWrapper } from "components/features/login/components/login-form-input";
import { LoginPages } from "components/features/login/pages";
import { PylonPasswordChecker } from "components/features/password-checker";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { resetPassword } from "core/api";
import { SectionHeader } from "components/core/section-header";
import { Text } from "components/core/text";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import "../../styles/styles.css";
import "./styles.css";

export const NewPasswordPage: React.FC = () => {
  const history = useHistory();

  const [password, setPassword] = React.useState("");
  const [confirmedPassword, setConfirmedPassword] = React.useState("");

  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const code = query["code"] as string;
  const otp = query["otp"] as string;

  if (!code || !otp) {
    toast(
      <PylonToastBody
        title={"Error Occurred"}
        body={
          "Something went wrong. Please check your email and click the link again."
        }
        type={ToastType.Error}
      />
    );

    history.push(LoginPages.Login);
  }

  const handleSubmit = () => {
    if (disabled) {
      return;
    }

    resetPassword(
      password,
      code,
      otp,
      (data) => {
        if (!data || data.message) {
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={data?.message ?? "Unknown error"}
              type={ToastType.Error}
            />
          );
          return;
        }

        toast(
          <PylonToastBody
            title={"Password reset"}
            body={"Password reset success"}
          />
        );
        history.push(LoginPages.Login);
      },
      (err) => {
        const msg = err instanceof Error ? err.message : "unknown error";
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={msg}
            type={ToastType.Error}
          />
        );
      }
    );
  };

  const disabled = password !== confirmedPassword || !isPasswordValid(password);

  return (
    <div className="account-page">
      <AccountsHeader redirectToHome />
      <SectionHeader>Reset your password</SectionHeader>
      <Text>Enter a new password for your Pylon account</Text>
      <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
        <LoginFormWrapper>
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="New Password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlurValidate={[
              {
                validationFunc: (pw) => !pw || isPasswordValid(pw),
                errorMessage:
                  "Please use a password that satisfies requirement below",
              },
            ]}
            required
          />
        </LoginFormWrapper>
        <LoginFormWrapper>
          <Input
            type="password"
            id="confirm-password"
            name="password"
            placeholder="Confirm new password"
            autoComplete="new-password"
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
            onBlurValidate={[
              {
                validationFunc: (pw) => !pw || pw === password,
                errorMessage: "Password mismatch",
              },
            ]}
            required
          />
        </LoginFormWrapper>
        <PylonPasswordChecker password={password} />

        <div
          style={{
            paddingTop: "1rem",
          }}
        />
        <div className="newuser-button-wrapper">
          <LeafButton disabled={disabled} onClick={handleSubmit}>
            Reset Password
          </LeafButton>
        </div>
      </form>
    </div>
  );
};
