import * as React from "react";
import CoinbaseIcon from "core/assets/coinbase.png";
import PlaidIcon from "core/assets/plaid-icon.png";
import { Colour } from "core/models";
import { DropDownMenu } from "components/core/drop-down-menu";
import { Option } from "core/utils/account";
import { Text, TextType } from "components/core/text";
import { useClickOutDetector } from "core/hooks/click-out";
import {
  DropDownMenuItem,
  DropDownMenuItemType,
} from "components/core/drop-down-menu-item";

interface Props {
  subtypes: Option[];
  onSelect: (key: string) => void;
  onClose: () => void;
  connectable: boolean;
  showCryptoConnect?: boolean;
  showTrellisConnect?: boolean;
  openMenuUpwards?: boolean;
}

export const AccountAddMenu: React.FC<Props> = ({
  showCryptoConnect,
  showTrellisConnect,
  connectable,
  subtypes,
  onSelect,
  onClose,
  openMenuUpwards,
}) => {
  const clickOutRef = useClickOutDetector<HTMLDivElement>(onClose);
  const menuDirection = openMenuUpwards
    ? {
        bottom: "0",
      }
    : {
        top: "0",
      };

  return (
    <div
      ref={clickOutRef}
      style={{
        cursor: "pointer",
        width: "20rem",
        backgroundColor: "white",
        borderRadius: "2px",
        ...menuDirection,
      }}
    >
      <DropDownMenu>
        {connectable && (
          <>
            <div>
              <DropDownMenuItem
                // borderBottom={true}
                text={
                  <div
                    data-selector={"add-account-plaid-link"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0.5rem 0",
                    }}
                  >
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        alt="Plaid Icon"
                        src={PlaidIcon}
                        height="40px"
                        width="40px"
                        style={{ borderRadius: "0.5rem" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div>Connect Account</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ marginRight: "0.5rem" }}>
                          <Text
                            type={TextType.Div}
                            size="0.688rem"
                            lineHeight="1rem"
                            colour={Colour.Gray70}
                          >
                            Auto link accounts
                          </Text>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#CEF5DD",
                            padding: "0.25rem 0.5rem ",
                            borderRadius: "6.25rem",
                          }}
                        >
                          <Text
                            type={TextType.Div}
                            size="0.563rem"
                            lineHeight="0.75rem"
                            colour={"#2C7A4A" as Colour}
                          >
                            Recommended
                          </Text>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                onClick={() => {
                  onSelect("connect");
                  onClose();
                }}
              />
              {showCryptoConnect && (
                <DropDownMenuItem
                  // borderBottom={true}
                  text={
                    <div
                      data-selector={"add-account-crypto-link"}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "1rem" }}>
                        <img
                          alt="Coinbase Icon"
                          src={CoinbaseIcon}
                          height="40px"
                          width="40px"
                          style={{ borderRadius: "0.5rem" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>Connect Coinbase</div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ marginRight: "0.5rem" }}>
                            <Text
                              type={TextType.Div}
                              size="0.688rem"
                              lineHeight="1rem"
                              colour={Colour.Gray70}
                            >
                              Connect Coinbase or Coinbase Pro
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  onClick={() => {
                    onSelect("crypto");
                    onClose();
                  }}
                />
              )}
            </div>

            <div style={{ borderBottom: "1px solid #EEEEEE" }}></div>
            <DropDownMenuItem
              text={"Add manually"}
              type={DropDownMenuItemType.AccountAdd}
              onClick={() => {}}
            />
          </>
        )}
        {subtypes.map((s: Option) => (
          <DropDownMenuItem
            key={s.label}
            text={s.label}
            onClick={() => {
              onSelect(s.value);
              onClose();
            }}
          />
        ))}
      </DropDownMenu>
    </div>
  );
};
