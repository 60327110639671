import React, { useContext } from "react";
import { AddTrustedContactContactInfo } from "components/features/dashboard/components/add-new-trusted-contact/contact-info";
import { AddTrustedContactName } from "components/features/dashboard/components/add-new-trusted-contact/name";
import { AddTrustedContactRoles } from "components/features/dashboard/components/add-new-trusted-contact/roles";
import { EMAIL_REGEX, PHONE_REGEX } from "core/utils";
import { Text, TextFormat, TextType } from "components/core/text";
import { TrustedSourceContext } from "components/contexts/trusted-source-context";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  ManageCollaboratorTrayBody,
  Props as ManageProps,
} from "components/features/dashboard/components/manage-collaborator";

export type Props = {
  showBackButton?: boolean;
  manageUserInput?: ManageProps;
  onClose: () => void;
};

export const ManageCollaboratorChildTray: React.FC<Props> = ({
  onClose,
  showBackButton,
  manageUserInput,
}) => {
  const mobileLayout = useMobileLayout();
  const [pageID, setPageID] = React.useState<number>(0);
  const { state, submitTrustedContact } = useContext(TrustedSourceContext);
  const [submitting, setSubmitting] = React.useState(false);

  const goNext = React.useCallback(() => setPageID(pageID + 1), [pageID]);

  const pages = React.useCallback(() => {
    const p = !manageUserInput
      ? [
          {
            component: (
              <AddTrustedContactName
                goNext={goNext}
                valid={state.firstName !== "" && state.lastName !== ""}
              />
            ),
            validation:
              state.firstName &&
              state.firstName !== "" &&
              state.lastName &&
              state.lastName !== "",
          },
          {
            component: (
              <AddTrustedContactContactInfo
                goNext={goNext}
                valid={
                  EMAIL_REGEX.test(state.email) && PHONE_REGEX.test(state.phone)
                }
              />
            ),
            validation:
              EMAIL_REGEX.test(state.email) && PHONE_REGEX.test(state.phone),
          },
          {
            // no control props on last page
            component: <AddTrustedContactRoles />,
            validation: true,
          },
        ]
      : [
          {
            component: <ManageCollaboratorTrayBody {...manageUserInput} />,
            validation:
              state.firstName &&
              state.firstName !== "" &&
              state.lastName &&
              state.lastName !== "" &&
              state.email &&
              state.email !== "" &&
              state.phone &&
              state.phone !== "",
          },
        ];

    return p;
  }, [state, manageUserInput, goNext]);

  return (
    <div
      className="flex-column gap-1"
      style={{
        padding: mobileLayout ? "2.5rem 1rem" : "2.5rem",
        width: mobileLayout ? "95vw" : "32rem",
        color: "black",
        overflow: "auto",
      }}
    >
      <div className="flex-column gap-1">
        {pages()[pageID]?.component}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "2rem",
            gap: "1rem",
          }}
        >
          {pageID > 0 ? (
            <LeafButton
              arrowLeft
              buttonColour={LeafButtonColour.Transparent}
              onClick={() => {
                setPageID(pageID - 1);
              }}
            >
              Prev
            </LeafButton>
          ) : (
            <LeafButton
              buttonColour={LeafButtonColour.Transparent}
              onClick={onClose}
            >
              Cancel
            </LeafButton>
          )}
          {pages().length > 1 && (
            <LeafButton
              disabled={!pages()[pageID]?.validation}
              buttonStyle={LeafButtonStyle.LeafSmall}
              buttonColour={LeafButtonColour.Black}
              loading={submitting}
              onClick={() => {
                if (pageID !== pages().length - 1) {
                  setPageID(pageID + 1);
                } else {
                  setSubmitting(true);
                  if (!submitting) {
                    submitTrustedContact(
                      () => {
                        setSubmitting(false);
                        onClose?.();
                      },
                      () => setSubmitting(false)
                    );
                  }
                }
              }}
            >
              <Text format={TextFormat.UpperCase} type={TextType.Div}>
                {pageID === pages().length - 1 ? `Finish` : `Next`}
              </Text>
            </LeafButton>
          )}
        </div>
      </div>
    </div>
  );
};
