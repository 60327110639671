import * as React from "react";
import { IncomeAndTaxes2 } from "components/features/dashboard/pages/income-and-taxes/index-version-2";
import { TaxContextProvider } from "components/contexts/tax-context";

export const TaxPageConatiner: React.FC = () => {
  return (
    <TaxContextProvider>
      <IncomeAndTaxes2 />
    </TaxContextProvider>
  );
};
