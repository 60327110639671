import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Dropdown } from "react-bootstrap";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import {
  DataTypeFiltering,
  InvestmentSummaryContext,
} from "components/features/dashboard/components/investments-summary-section/context/investments-summary-context";

export const InvestmentDataTypeFilter: React.FC = () => {
  const { dataType, setDataType } = React.useContext(InvestmentSummaryContext);

  const dataTypeMenuItems = Object.values(DataTypeFiltering).map((f) => (
    <DropDownMenuItem
      text={f}
      onClick={() => {
        setDataType(f);
        document.dispatchEvent(new MouseEvent("click"));
      }}
    />
  ));

  return (
    <Dropdown alignRight>
      <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
        <PylonDropDownToggle text={dataType} />
      </DropdownToggle>
      <DropdownMenu>
        <div
          style={{
            width: "15rem",
            position: "absolute",
            zIndex: 1000,
            cursor: "pointer",
            backgroundColor: "white",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
            borderRadius: "2px",
            top: "0",
            right: "0",
          }}
        >
          {dataTypeMenuItems}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
