import * as React from "react";
import Illustration404 from "./404.svg";
import qs from "qs";
import { Colour } from "../../../../../core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { DataPageContext } from "../../../../contexts/data-page-context-provider";
import { DocumentDetailView } from "components/features/dashboard/components/document-detail-view";
import { FETCH_DOCUMENT, GetDocumentResponse } from "core/queries/documents";
import { Link } from "react-router-dom";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { scrollToTop } from "core/utils/scrolling";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import { useQuery } from "@apollo/client";
import "./index-version-2.css";

export const DocumentDetails: React.FC = () => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const documentID = query["documentID"] as string;

  const { setOnUpdateRefetchQueries } = React.useContext(DataPageContext);

  React.useEffect(() => {
    setOnUpdateRefetchQueries([
      {
        query: FETCH_DOCUMENT,
        variables: {
          id: documentID,
        },
      },
    ]);
  }, [setOnUpdateRefetchQueries, documentID]);

  const { data, loading } = useQuery<GetDocumentResponse>(FETCH_DOCUMENT, {
    fetchPolicy: "cache-first",
    variables: {
      id: documentID,
    },
  });

  const doc = data?.document;

  React.useEffect(() => {
    scrollToTop();
  }, []);

  if (!loading && !doc) {
    return (
      <PageWrapper>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: "5rem 0",
              maxWidth: "25.275rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              colour={Colour.Navy}
              style={TextStyle.TitlePoppins}
              type={TextType.Div}
            >
              <h1>404</h1>
            </Text>

            <div
              style={{
                padding: "2rem",
              }}
            />
            <svg>
              <use xlinkHref={`${Illustration404}#illustration`} />
            </svg>

            <div style={{ textAlign: "center" }}>
              <Text style={TextStyle.B16}>
                The document you are trying to view has been deleted or you may
                no longer have access to it.
              </Text>
            </div>

            <Link to={DashboardPages.Documents}>
              <TextD style={TextStyle.M15SM}>BACK TO DOCUMENTS</TextD>
            </Link>
          </div>
        </div>
      </PageWrapper>
    );
  }

  if (!doc) {
    return null;
  }

  return (
    <PageWrapper>
      <DocumentDetailView document={doc} loading={loading} fullPageView />
    </PageWrapper>
  );
};
