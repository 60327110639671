import qs from "qs";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { Planning } from "components/features/dashboard/components/planning";
import { PlanOptions } from "components/features/dashboard/components/planning/models";

export const CalculatorsPage: React.FC = () => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  const option = query["option"] as PlanOptions | undefined;

  const selectedOption: PlanOptions | undefined =
    option && Object.values(PlanOptions).includes(option) ? option : undefined;

  return (
    <PageWrapper
      header={
        <PageTitle
          title="Calculators"
          subtitle="Plan for anything using our calculators and templates below."
        />
      }
    >
      <Planning selectedOption={selectedOption} />
    </PageWrapper>
  );
};
