import MaskedInput from "react-text-mask";
import React from "react";
import { AccountDetails, PrivateCompanyDetails } from "core/models";
import { Input } from "components/core/input";
import { PylonCurrencyInput } from "components/core/currency-input";
import { PylonDatePicker } from "components/core/date-picker";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";

export interface Props {
  balance?: string;
  onBalanceUpdate: (balance: string) => void;
  details?: AccountDetails;
  onDetailsUpdate: (details: AccountDetails) => void;

  onEnter?: () => void;
}

export const PrivateCompanyDetailsEntry: React.FC<Props> = ({
  balance,
  onBalanceUpdate,
  details,
  onDetailsUpdate,
  onEnter,
}) => {
  const privateCompanyDetails: PrivateCompanyDetails =
    details?.privateCompanyDetails || {};
  // const [state, setState] = React.useState<PrivateCompanyDetails>(
  //   privateCompanyDetails
  // );

  return (
    <>
      <div>
        <div>Current estimated value of the entire business?</div>
        <PylonCurrencyInput
          id="investment-estimated-value-input"
          required
          placeholder="$123.45"
          onValueChange={(v) => {
            onDetailsUpdate({
              privateCompanyDetails: {
                ...privateCompanyDetails,
                estimatedInvestmentValue: sanitizeStringNumberToString(v),
              },
            });
          }}
          value={privateCompanyDetails.estimatedInvestmentValue || ""}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
      <div>
        <div>
          Estimated value of the entire business at time of original investment?
        </div>
        <PylonCurrencyInput
          id="investment-amount-value-input"
          required
          placeholder="$123.45"
          onValueChange={(v) => {
            onDetailsUpdate({
              privateCompanyDetails: {
                ...privateCompanyDetails,
                estimatedValueAtInvestmentTime: sanitizeStringNumberToString(v),
              },
            });
          }}
          prefix={"$"}
          decimalScale={2}
          value={privateCompanyDetails.estimatedValueAtInvestmentTime || ""}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
      <div>
        <div>How much have you invested in this business?</div>
        <PylonCurrencyInput
          id="investment-amount-value-input"
          placeholder="$123.45"
          onValueChange={(v) => {
            let p = "";
            if (privateCompanyDetails.estimatedValueAtInvestmentTime && v) {
              p = Math.round(
                (parseInt(v) /
                  parseInt(
                    privateCompanyDetails.estimatedValueAtInvestmentTime
                  )) *
                  100
              ).toString();
            }
            onDetailsUpdate({
              privateCompanyDetails: {
                ...privateCompanyDetails,
                amountInvested: sanitizeStringNumberToString(v),
                amountInvestedPercentage: p,
              },
            });
          }}
          value={privateCompanyDetails.amountInvested || ""}
          prefix={"$"}
          decimalScale={2}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
      <div>
        <div>How much have you invested in this business as a percentage?</div>
        <Input
          id="investment-amount-value-percentage-input"
          placeholder="10%"
          onChange={(v) => {
            const cv = v.currentTarget.value;
            let amountInvested = privateCompanyDetails.amountInvested;

            if (privateCompanyDetails.estimatedValueAtInvestmentTime && cv) {
              amountInvested = (
                parseInt(privateCompanyDetails.estimatedValueAtInvestmentTime) *
                (parseInt(cv) / 100)
              ).toString();
            }

            onDetailsUpdate({
              privateCompanyDetails: {
                ...privateCompanyDetails,
                amountInvestedPercentage: sanitizeStringNumberToString(cv),
                amountInvested: sanitizeStringNumberToString(amountInvested),
              },
            });
          }}
          value={privateCompanyDetails.amountInvestedPercentage || ""}
          prefix={"%"}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>
      <div>
        <div>Date of investment?</div>
        <PylonDatePicker
          id="private-company-date-of-investment"
          customInput={
            <MaskedInput
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          }
          selected={
            privateCompanyDetails.investmentDate
              ? new Date(privateCompanyDetails.investmentDate)
              : null
          }
          placeholderText={"MM / DD / YYYY"}
          onChange={(date) => {
            if (!Array.isArray(date)) {
              // const newDetails = {
              //   ...privateCompanyDetails,
              //   investmentDate: date?.toUTCString() ?? undefined,
              // };

              //setState(newDetails);

              onDetailsUpdate?.({
                privateCompanyDetails: {
                  ...privateCompanyDetails,
                  investmentDate: date?.toUTCString() ?? undefined,
                },
              });
            }
          }}
        />
      </div>
      <div>
        <div>Dividends paid?</div>
        <PylonCurrencyInput
          id="investment-dividends-value"
          placeholder="$123.45"
          onValueChange={(v) => {
            onDetailsUpdate({
              privateCompanyDetails: {
                ...privateCompanyDetails,
                dividendsPaid: sanitizeStringNumberToString(v),
              },
            });
          }}
          value={privateCompanyDetails.dividendsPaid || ""}
          decimalScale={2}
          prefix={"$"}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onEnter?.();
            }
          }}
        />
      </div>

      <div>
        <div>Dividends interval (e.g. monthly, quarterly, yearly, etc)</div>
        <div>
          <Input
            id="investment-dividends-interval-value"
            height="1rem"
            type="text"
            onChange={(v) =>
              onDetailsUpdate({
                privateCompanyDetails: {
                  ...privateCompanyDetails,
                  dividendsInterval: v.currentTarget.value,
                },
              })
            }
            value={privateCompanyDetails.dividendsInterval || ""}
            // onEnter={onBlurUpdate}
            // onBlur={onBlurUpdate}
          />
        </div>
      </div>
    </>
  );
};
