import React, { useContext } from "react";
import Toggle from "react-toggle";
import { CollaborationRelationships } from "core/queries/collaborations";
import { PylonTooltip, TooltipIconSize } from "components/core/tooltip";
import { SimpleDropDown } from "components/core/simple-drop-down";
import { Text, TextStyle, TextType } from "components/core/text";
import { TrustedSourceContext } from "components/contexts/trusted-source-context";

export const AddTrustedContactRoles: React.FC = () => {
  const { state, updateState } = useContext(TrustedSourceContext);
  return (
    <div>
      <div className="flex-column gap-1">
        <div className="add-account-tray__step-action">
          <h5>
            What is your relationship to the collaborator
            <span className="text-highlight-blue">?</span>
            <br />
            <span className="text-highlight-blue">＿</span>
          </h5>
        </div>
        <div style={{ minWidth: "21rem" }}>
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            Relationship
          </Text>
          <SimpleDropDown
            selectedValue={state.relationship}
            onSelect={(e) => {
              if (!e) {
                return;
              }

              updateState({
                relationship: e,
              });
            }}
            options={Object.values(CollaborationRelationships).map((x) => {
              return x;
            })}
          />
        </div>
        {state.eighteenPlus !== undefined && state.eighteenPlus && (
          <>
            <div
              style={{
                display: "flex",
              }}
            >
              <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                Auto Share All Future Accounts
              </Text>
              <Text size={"0.75rem"} style={TextStyle.Hint} type={TextType.Div}>
                &nbsp;(Optional)
              </Text>
              <div
                style={{
                  paddingRight: "0.5rem",
                }}
              />
              <Toggle
                icons={false}
                checked={state.autoshareNewAccount}
                onChange={() => {
                  updateState({
                    autoshareNewAccount: !state.autoshareNewAccount,
                  });
                }}
              />
            </div>

            <div
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  minWidth: "21rem",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <Text style={TextStyle.FieldLabel} type={TextType.Div}>
                  Emergency Contact&nbsp;
                </Text>
                <Text
                  size={"0.75rem"}
                  style={TextStyle.Hint}
                  type={TextType.Div}
                >
                  (Optional)
                </Text>

                <PylonTooltip
                  text={
                    <Text>
                      An <b>emergency contact</b> is the person we will contact
                      if something happens to you. This should be{" "}
                      <b>someone you trust</b> with all of your information.
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
                <div
                  style={{
                    paddingRight: "0.5rem",
                  }}
                />
                <Toggle
                  icons={false}
                  checked={state.executor}
                  onChange={() => {
                    updateState({
                      executor: !state.executor,
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div style={{ paddingBottom: "2rem" }} />
      </div>
    </div>
  );
};
