import path from "path";
import React, { useState } from "react";
import { FileError, useDropzone } from "react-dropzone";
import { Text, TextStyle, TextType } from "components/core/text";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

export interface Props {
  allowMulti?: boolean;
  filename?: string;
  onChange: (files: File[]) => void;
}

const allowedSuffix = [
  ".png",
  ".jpeg",
  ".pdf",
  ".gif",
  ".jpg",
  ".bmp",
  ".txt",
  ".doc",
  ".xlsx",
  ".csv",
  ".xls",
];
export const UploadFileDropzone: React.FC<Props> = ({
  allowMulti,
  filename,
  onChange,
}) => {
  const [errors, setErrors] = useState<FileError[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      setErrors([]);

      onChange(files);
    },
    onDropRejected: (files) => {
      setErrors(files.map((f) => f.errors).flat());
    },
    multiple: allowMulti,
    maxSize: 10 * 1000 * 1000,
    validator: (f) => {
      if (!f.name) {
        return {
          message: "missing file name",
          code: "file-invalid-type",
        };
      }
      const extension = path.extname(f.name).toLowerCase();
      if (!allowedSuffix.includes(extension)) {
        return {
          message: `${extension} is not supported format`,
          code: "file-invalid-type",
        };
      }
      return null;
    },
  });

  return (
    <div>
      <div
        style={{ backgroundColor: "var(--gray-06)" }}
        className="file-upload-form-boarder"
      >
        <div {...getRootProps({ className: "file-upload-dropzone" })}>
          <input {...getInputProps()} />
          <span style={{ color: "var(--navy)" }}>
            {filename ? (
              filename
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div>Drop file or&nbsp;</div>
                <LeafButton
                  buttonStyle={LeafButtonStyle.LeafSmallDense}
                  buttonColour={LeafButtonColour.Black}
                >
                  Choose file
                </LeafButton>
              </div>
            )}
          </span>
        </div>
      </div>
      {errors.length > 0 && (
        <Text style={TextStyle.Error} type={TextType.Div}>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>
                {e.code} - {e.message}
              </li>
            ))}
          </ul>
        </Text>
      )}
    </div>
  );
};
