import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { DayFiltering } from "components/features/dashboard/components/investments-summary-section/models";
import { Dropdown } from "react-bootstrap";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { InvestmentSummaryContext } from "components/features/dashboard/components/investments-summary-section/context/investments-summary-context";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";

export const InvestmentTimeFilter: React.FC = () => {
  const { days, setDays } = React.useContext(InvestmentSummaryContext);

  const daysTypeMenuItems = Object.values(DayFiltering).map((f) => (
    <DropDownMenuItem
      text={f}
      onClick={() => {
        setDays(f);
        document.dispatchEvent(new MouseEvent("click"));
      }}
    />
  ));

  return (
    <Dropdown alignRight>
      <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
        <PylonDropDownToggle text={days} />
      </DropdownToggle>
      <DropdownMenu>
        <div
          style={{
            width: "15rem",
            position: "absolute",
            zIndex: 1000,
            cursor: "pointer",
            backgroundColor: "white",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
            borderRadius: "2px",
            top: "0",
            right: "0",
          }}
        >
          {daysTypeMenuItems}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};
