import { DocumentData } from "components/features/dashboard/models/document";
import { gql } from "@apollo/client";
import { UserData } from "components/features/dashboard/models/sharing";
import {
  DOCUMENT_FIELDS,
  DOCUMENT_FOLDER_FIELDS,
} from "core/queries/document-fragments";

export const FETCH_DOCUMENTS = gql`
  ${DOCUMENT_FIELDS}
  query fetchDocuments {
    documents {
      ...DocumentFields
    }
  }
`;

export const FETCH_DOCUMENT_CATEGORIES = gql`
  ${DOCUMENT_FIELDS}
  ${DOCUMENT_FOLDER_FIELDS}

  query fetchDocumentCategories {
    documentCategories {
      index
      category {
        id
        type
        name
        logo
        sharedWithMe
        isEditable
        recipients {
          id
          firstName
          lastName
          email
        }
        folder {
          ...DocumentFolderFields
        }
      }
      documents {
        ...DocumentFields
      }
    }
  }
`;

export const FETCH_DOCUMENT = gql`
  ${DOCUMENT_FIELDS}
  query fetchDocument($id: ID!) {
    document(id: $id) {
      ...DocumentFields
      downloadURL
      previewURL
    }
  }
`;

export const CREATE_DOCUMENT_FOLDER = gql`
  mutation createDocumentFolder($name: String!) {
    createDocumentFolder(name: $name) {
      folder {
        id
        name
      }
    }
  }
`;

export const UPDATE_DOCUMENT_FOLDER = gql`
  mutation updateDocumentFolder($name: String!, $id: ID!) {
    updateDocumentFolder(name: $name, id: $id) {
      folder {
        id
        name
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      success
    }
  }
`;

export const DELETE_FOLDER = gql`
  mutation deleteDocumentFolder($id: ID!) {
    deleteDocumentFolder(id: $id) {
      success
    }
  }
`;

export const FETCH_DOCUMENT_ID_OWNER_ID = gql`
  query fetchDocuments {
    documents {
      id
      owner {
        id
      }
    }
  }
`;

export interface FetchDocumentIDOwnerIDResponse {
  documents: {
    id: string;
    owner: {
      id: string;
    };
  }[];
}

export const updateDocumentCategories = gql`
  mutation UpdateDocumentCategories(
    $categories: [OrderedDocumentCategoryInput]!
  ) {
    updateDocumentCategories(categories: $categories) {
      category {
        id
        type
        name
      }
      index
    }
  }
`;

export interface DocumentFolderSuggestionSetting {
  id: string;
  itemTitle: string;
  description: string;
  ignored: boolean;
}
export interface DocumentFolderSuggestedItem {
  id: string;
  documentFolderID: string;
  documentID: string;
  suggestionItemID: string;
}

export interface DocumentFolder {
  id: string;
  ownerID: string;
  name: string;
  suggestionID?: string;
  suggestionsettings?: DocumentFolderSuggestionSetting[];
  suggestedItems?: DocumentFolderSuggestedItem[];
}

export interface CategoryData {
  id: string;
  type: string;
  name: string;
  sharedWithMe?: boolean;
  logo?: string;
  recipients?: UserData[];
  isEditable?: boolean;
  folder?: DocumentFolder;
  __typename?: "DocumentCategory";
}

export interface DocumentCategory {
  index: number;
  category: CategoryData;
  documents: DocumentData[];
}

export interface DocumentCategoriesResponse {
  documentCategories: DocumentCategory[];
}

export const ADD_DOCUMENT_TAG = gql`
  mutation ($documentID: ID!, $categoryData: DocumentCategoryInput!) {
    addDocumentTag(documentID: $documentID, categoryData: $categoryData) {
      document {
        id
      }
    }
  }
`;

export const REMOVE_DOCUMENT_TAG = gql`
  mutation ($documentID: ID!, $categoryData: DocumentCategoryInput!) {
    removeDocumentTag(documentID: $documentID, categoryData: $categoryData) {
      document {
        id
        categories {
          id
          type
          name
        }
      }
    }
  }
`;

export const UPDATE_DOCUMENT_METADATA = gql`
  mutation (
    $id: ID!
    $newName: String
    $newSecondaryAssetIDs: [String!]
    $notes: String
  ) {
    editDocument(
      id: $id
      newName: $newName
      newSecondaryAssetIDs: $newSecondaryAssetIDs
      notes: $notes
    ) {
      document {
        id
        name
        categories {
          id
          type
          name
        }
        notes
      }
    }
  }
`;

export interface UpdateDocumentMetadata {
  id: string;
  newName?: string;
  newSecondaryAssetIDs?: string[];
}

export interface GetDocumentResponse {
  document: DocumentData;
}
