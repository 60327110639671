import qs from "qs";
import React, { useContext } from "react";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { DashboardPages } from "components/features/dashboard/pages";
import { PlaidWrapper } from "components/features/dashboard/components/plaid-wrapper";
import { useHistory } from "react-router";

export const PlaidOauthCallbackPage: React.FC = () => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  const history = useHistory();
  const { resumeFromSessionStorage, clear } = useContext(AddAccountsContext);

  const oauthCallbackStateID = query["oauth_state_id"];

  React.useEffect(() => {
    if (oauthCallbackStateID) {
      resumeFromSessionStorage();
    }
    // Only call once on page load; this page is supposed to be only accessed programtically
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (oauthCallbackStateID) {
    return (
      <PlaidWrapper
        oauthRedirectURI={window.location.href}
        onClose={() => {
          clear();
          history.replace(DashboardPages.Integrations);
        }}
      />
    );
  }

  // Else user entered the page by accident? redirect them to the base integration page instead
  history.replace(DashboardPages.Integrations);

  return <div>Looks like you've accessed this page by accident!</div>;
};
