import React, { useState } from "react";
import { Account, CreditCardDetails } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { AccountInput } from "core/queries/accounts";
import { addYears } from "date-fns";
import { formatNumberToString } from "./utils";
import { ModalDateInput } from "components/core/input/modal-date-input";
import { ModalInput } from "components/core/input/modal-input";
import "./credit-styles.css";

interface Props {
  account: Account;
  isConnected: boolean;

  onUpdate?: (input: Partial<AccountInput>) => void;
  displayOnly?: boolean;
}

interface CreditDetailState {
  limit?: string;
  apr?: string;
  details: Partial<CreditCardDetails>;
}

export const CreditCardDetailCard: React.FC<Props> = ({
  account,
  isConnected,
  onUpdate,
  displayOnly,
}) => {
  const [state, setState] = useState<CreditDetailState>({
    limit: formatNumberToString(account.balances.limit),
    apr: formatNumberToString(account.aprs?.purchasePercentage),
    details: account.details?.creditCardDetails
      ? {
          monthlyPayment: account.details?.creditCardDetails?.monthlyPayment,
          interestRate: account.details?.creditCardDetails.interestRate,
          dueDate: account.details?.creditCardDetails.dueDate,
        }
      : {},
  });

  const onBlurUpdate = () => {
    onUpdate?.({
      details: {
        creditCardDetails: state.details,
      },
    });
  };

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Last Statement Balance"
          value={account.lastStatementBalance}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Credit Limit"
          value={account.balances.limit}
        />

        {/* Only show if user can edit it or if the account */}
        {!isConnected && (
          <AccountInformationValueCard
            type={ValueCardType.Percentage}
            title="Purchase APR"
            value={account.aprs?.purchasePercentage}
          />
        )}

        {isConnected && (
          <AccountInformationValueCard
            type={ValueCardType.Percentage}
            title="Cash APR"
            value={account.aprs?.cashPercentage}
          />
        )}

        {isConnected && (
          <AccountInformationValueCard
            type={ValueCardType.Percentage}
            title="Transfer APR"
            value={account.aprs?.transferPercentage}
          />
        )}

        {isConnected && (
          <AccountInformationValueCard
            type={ValueCardType.Percentage}
            title="Special APR"
            value={account.aprs?.specialPercentage}
          />
        )}

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Monthly Payment"
          value={account.details?.creditCardDetails?.monthlyPayment}
        />

        <AccountInformationValueCard
          type={ValueCardType.Percentage}
          title="Interest Rate"
          value={account.details?.creditCardDetails?.interestRate}
        />

        <AccountInformationValueCard
          type={ValueCardType.Date}
          title="Due date"
          value={account.details?.creditCardDetails?.dueDate}
        />
      </>
    );
  }

  return (
    <>
      {!isConnected && (
        <ModalInput
          id="account-credit-limit"
          type="money"
          label={"Credit Limit"}
          value={state.limit}
          placeholder={"$3,000.00"}
          onChange={(v) => {
            setState({
              ...state,
              limit: v,
            });
          }}
          onBlur={() => {
            if (formatNumberToString(account.balances.limit) !== state.limit) {
              onUpdate?.({
                balances: {
                  limit: state.limit
                    ? Number.parseFloat(state.limit)
                    : undefined,
                },
              });
            }
          }}
        />
      )}

      {!isConnected && (
        <ModalInput
          id="account-purchase-apr"
          type="percentage"
          label={"Purchase APR"}
          value={state.apr}
          placeholder={"4.00%"}
          onChange={(v) => {
            setState({
              ...state,
              apr: v,
            });
          }}
          onBlur={() => {
            if (
              formatNumberToString(account.aprs.purchasePercentage) !==
              state.apr
            ) {
              onUpdate?.({
                aprs: {
                  purchasePercentage: state.apr
                    ? Number.parseFloat(state.apr)
                    : undefined,
                },
              });
            }
          }}
        />
      )}

      <ModalInput
        id="account-monthly-payment"
        type="money"
        label={"Monthly Payment"}
        placeholder={"$300.00"}
        onChange={(v) => {
          setState({
            ...state,
            details: {
              ...state.details,
              monthlyPayment: v,
            },
          });
        }}
        onBlur={onBlurUpdate}
        value={state.details.monthlyPayment}
      />

      <ModalInput
        id="account-purchase-apr"
        type="percentage"
        label={"Interest Rate"}
        value={state.details.interestRate}
        placeholder={"3.00%"}
        onChange={(v) => {
          setState({
            ...state,
            details: {
              ...state.details,
              interestRate: v,
            },
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalDateInput
        id="account-due-date"
        label="Due Date"
        placeholderText="01/20/2030"
        minDate={new Date()}
        maxDate={addYears(new Date(), 35)}
        selected={
          state.details.dueDate ? new Date(state.details.dueDate) : null
        }
        onChange={(date) => {
          if (!Array.isArray(date)) {
            const newDetails = {
              ...state.details,
              dueDate: date?.toUTCString() ?? undefined,
            };

            setState({
              ...state,
              details: newDetails,
            });

            onUpdate?.({
              details: {
                creditCardDetails: newDetails,
              },
            });
          }
        }}
      />
    </>
  );
};
