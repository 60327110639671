import illustration from "./exp-d-illustration.png";
import illustrationMobile from "./exp-d-illustration-mobile.png";
import logoChase from "../assets/images/logo-chase.png";
import logoCoinBase from "../assets/images/logo-coin-base.png";
import logoRobinhood from "../assets/images/logo-robinhood.png";
import logoSmallChase from "../assets/images/logo-sm-chase.png";
import logoSmallCoinBase from "../assets/images/logo-sm-coinbase.png";
import logoSmallRobinhood from "../assets/images/logo-sm-robinhood.png";
import logoSmallWellsFargo from "../assets/images/logo-sm-wells-fargo.png";
import logoWellsFargo from "../assets/images/logo-wells-fargo.png";
import { Colour } from "core/models";
import { LandingPageDefinition } from "components/features/landing";
import { RegisterButtonLeaf } from "components/features/landing/components/register-leaf";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";

export const LandingPageEffortlessManagementD: LandingPageDefinition = {
  Path: "/landing2/manage/effortless-money-management",
  Component: () => {
    const mobileLayout = useMobileLayout();

    return (
      <div
        className="flex-row mobile-flex-column"
        style={{
          minHeight: "100vh",
          backgroundColor: "#E5EFD9",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          className="flex-column"
          style={{
            width: mobileLayout ? undefined : "544px",
            flexShrink: mobileLayout ? undefined : 0,
            flexGrow: mobileLayout ? undefined : 0,
            alignSelf: "center",
            position: "relative",
            marginLeft: mobileLayout ? "1.5rem" : "3.5rem",
            marginRight: mobileLayout ? "1.5rem" : undefined,
          }}
        >
          {mobileLayout && <div style={{ paddingTop: "24px" }} />}
          <Text
            type={TextType.Div}
            style={TextStyle.SGM25}
            size={"1.5rem"}
            lineHeight={"2rem"}
            colour={Colour.Black}
          >
            Pylon
          </Text>
          <div style={{ paddingTop: "40px" }} />
          <Text
            type={TextType.Div}
            style={mobileLayout ? TextStyle.Sharp35Bold : TextStyle.Sharp56Bold}
          >
            Effortless money management
          </Text>
          <div style={{ paddingTop: mobileLayout ? "8px" : "24px" }} />
          <Text type={TextType.Div} style={TextStyle.Sharp17Regular}>
            Financial planning just got easier.
          </Text>
          <div
            style={{
              paddingTop: mobileLayout ? "64px" : "40px",
            }}
          />
          <RegisterButtonLeaf text="Get started →" optionalParam="skip=true" />
        </div>
        <div
          style={{
            flexShrink: 1,
            flexGrow: 1,
          }}
        />
        <div
          className="flex-column"
          style={{
            flexShrink: 0,
            flexGrow: 0,
            paddingLeft: mobileLayout ? "30.5px" : undefined,
            rowGap: mobileLayout ? "8px" : "16px",
            alignSelf: mobileLayout ? "start" : "stretch",
            paddingTop: mobileLayout ? "56px" : undefined,
            paddingBottom: mobileLayout ? "24px" : undefined,

            backgroundImage: mobileLayout
              ? `url(${illustrationMobile})`
              : `url(${illustration})`,
            backgroundAttachment: "absolute",
            backgroundPosition: mobileLayout ? "bottom right" : "right",
            backgroundRepeat: "no-repeat",
            justifyContent: "center",
            width: mobileLayout ? "95%" : "656px",
          }}
        >
          <ItemRow
            logo={mobileLayout ? logoSmallWellsFargo : logoWellsFargo}
            name="Wells Fargo"
            type="Checking"
            amount="$258,374"
            mobile={mobileLayout}
          />
          <ItemRow
            logo={mobileLayout ? logoSmallCoinBase : logoCoinBase}
            name="Coinbase"
            type="Crypto"
            amount="$572,723"
            mobile={mobileLayout}
          />
          <ItemRow
            logo={mobileLayout ? logoSmallRobinhood : logoRobinhood}
            name="Robinhood"
            type="Investment"
            amount="$327,216"
            mobile={mobileLayout}
          />
          <ItemRow
            logo={mobileLayout ? logoSmallChase : logoChase}
            name="Chase"
            type="Checking"
            amount="$41,687"
            mobile={mobileLayout}
          />

          <div
            style={{
              padding: mobileLayout ? "12px" : "24px",
              marginLeft: mobileLayout ? "56px" : "112px",
              marginTop: mobileLayout ? "24px" : "48px",
              background: "rgba(255, 255, 255, 0.8)",
              borderRadius: mobileLayout ? "8px" : "16px",
              width: mobileLayout ? "192px" : "384px",
              opacity: 0.8,
              zIndex: 1,
            }}
          >
            <Text
              type={TextType.Div}
              style={
                mobileLayout ? TextStyle.Sharp9Bold : TextStyle.Sharp17Bold
              }
            >
              Net Worth
            </Text>
            <div
              style={{
                paddingTop: "1rem",
              }}
            />
            <Text
              type={TextType.Div}
              style={
                mobileLayout
                  ? TextStyle.Sharp9Regular
                  : TextStyle.Sharp17Regular
              }
            >
              You and your spouse have a combined net worth of{" "}
              <span
                style={{
                  color: "var(--green-01)",
                }}
              >
                $2.6 million.
              </span>
            </Text>
          </div>
        </div>
        {mobileLayout && <div className="mt1" />}
        {!mobileLayout && (
          <div
            style={{
              marginRight: "112px",
            }}
          />
        )}
      </div>
    );
  },
};

export const ItemRow: React.FC<{
  logo: string;
  name: string;
  type: string;
  amount: string;
  mobile?: boolean;
}> = ({ logo, name, type, amount, mobile }) => {
  return (
    <div
      className="flex-row align-center"
      style={{
        background: "rgba(255, 255, 255, 0.6)",
        borderRadius: mobile ? "8px" : "16px",
        backdropFilter: "blur(20px)",
        padding: mobile ? "8px" : "16px",
        width: mobile ? "160px" : "320px",
        gap: mobile ? "8px" : "16px",
      }}
    >
      <div
        style={{
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <img src={logo} alt={`${name} Logo`} />
      </div>
      <div
        className="flex-column"
        style={{
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <Text
          style={mobile ? TextStyle.Sharp9Regular : TextStyle.Sharp17Regular}
          type={TextType.Div}
        >
          {name}
        </Text>

        <div
          style={{
            opacity: 0.4,
          }}
        >
          <Text
            style={mobile ? TextStyle.Sharp7Regular : TextStyle.Sharp14Regular}
            type={TextType.Div}
          >
            {type}
          </Text>
        </div>
      </div>
      <div
        style={{
          flex: 1,
          textAlign: "right",
          justifySelf: "flex-end",
        }}
      >
        <Text
          style={mobile ? TextStyle.Sharp9Regular : TextStyle.Sharp17Regular}
          type={TextType.Div}
        >
          {amount}
        </Text>
      </div>
    </div>
  );
};
