import { PylonToastBody } from "components/core/pylon-toast-body";

export const SharedSuccessToast = (assetType: string) => {
  let typeStr = assetType;
  if (typeStr === "credithistory") {
    typeStr = "credit history";
  }
  return (
    <PylonToastBody
      title={`Shared successfully!`}
      body={`Your ${typeStr} has been successfully shared! You can revoke access any time by going to the ${typeStr} page.`}
    />
  );
};
