import { Colour } from "core/models";
import { MovingCalculatorBody } from "components/features/external/moved/calculator-body";
import { PylonALink } from "components/core/alink";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import "./styles.css";

export const Moved: React.FC = () => {
  return (
    <div className="pylon__moved-calculator">
      <Header />
      <MovingCalculatorBody />
    </div>
  );
};

const Header: React.FC = () => {
  return (
    <div className="pylon__moved-calculator__title">
      <div
        style={{
          display: "flex",
        }}
      >
        <Text
          type={TextType.Div}
          style={TextStyle.L12}
          format={TextFormat.UpperCase}
          colour={Colour.Text03}
        >
          The moving calculator powered by
        </Text>
        <div style={{ paddingLeft: "0.25rem" }} />
        <PylonALink colour={Colour.Blue01} href={"https://www.hellopylon.com"}>
          <Text
            type={TextType.Div}
            style={TextStyle.L12}
            format={TextFormat.UpperCase}
            colour={Colour.Blue01}
          >
            PYLON
          </Text>
        </PylonALink>
      </div>

      <div>
        <Text
          type={TextType.Div}
          style={TextStyle.L14}
          colour={Colour.Text01}
          size="1.5rem"
          weight={600}
        >
          How much is the move going to cost you?
        </Text>
      </div>
    </div>
  );
};
