import React, { useState } from "react";
import { Account } from "core/models";
import { AccountInformationValueCard, ValueCardType } from "./detail-card";
import { ModalInput } from "components/core/input/modal-input";
import { sanitizeStringNumberToString } from "components/features/dashboard/components/add-account-tray/details-entry/utils";
import "./styles.css";

export interface Props {
  account: Account;
  isConnected: boolean;
  displayOnly?: boolean;
  onUpdate?: (account: Partial<Account>) => void;
}

export const ManageCDDetails: React.FC<Props> = ({
  account,
  isConnected,
  displayOnly,
  onUpdate,
}) => {
  const inputDetails = account.details?.cdDetails;
  const [details, setDetails] = useState({
    apy: inputDetails?.apy || undefined,
    term: inputDetails?.term || undefined,
    minimumDeposit: inputDetails?.minimumDeposit || undefined,
  });

  const onBlurUpdate = () => {
    onUpdate?.({
      details: {
        cdDetails: details,
      },
    });
  };

  if (displayOnly) {
    return (
      <>
        <AccountInformationValueCard
          type={ValueCardType.Percentage}
          title="Annual Percentage Yield"
          value={account.details?.cdDetails?.apy}
        />

        <AccountInformationValueCard
          type={ValueCardType.String}
          title="Term"
          value={account.details?.cdDetails?.term}
        />

        <AccountInformationValueCard
          type={ValueCardType.Money}
          title="Minimum Deposit"
          value={account.details?.cdDetails?.minimumDeposit}
        />
      </>
    );
  }

  if (isConnected) {
    return null;
  }

  return (
    <>
      <ModalInput
        id="account-purchase-apy"
        type="percentage"
        label={"Annual Percentage Yield"}
        value={details.apy}
        placeholder="1%"
        onChange={(v) => {
          setDetails({
            ...details,
            apy: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-purchase-term"
        type="string"
        label={"Term"}
        value={details.term}
        placeholder="12 months"
        onChange={(v) => {
          setDetails({
            ...details,
            term: v,
          });
        }}
        onBlur={onBlurUpdate}
      />

      <ModalInput
        id="account-purchase-min-deposit"
        type="money"
        label={"Minimum Deposit"}
        value={details.minimumDeposit}
        placeholder="$100"
        onChange={(v) => {
          setDetails({
            ...details,
            minimumDeposit: sanitizeStringNumberToString(v),
          });
        }}
        onBlur={onBlurUpdate}
      />
    </>
  );
};
