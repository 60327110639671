import Confetti from "react-confetti";
import React, { useState } from "react";

interface SummaryProps {
  currentSummaryCards: React.ReactNode[];
  comingSoonCards: React.ReactNode[];
}

export const OnboardingSummary: React.FC<SummaryProps> = ({
  currentSummaryCards,
  comingSoonCards,
}) => {
  const [doneAnimating, setDoneAnimating] = useState(false);

  return (
    <div className="onboarding-wrapper-inner">
      <div className="onboarding-top">
        <div>
          <div>
            <div>
              Thanks for sharing
              <span style={{ color: "var(--blue-01)" }}>!</span>
            </div>
          </div>
        </div>
      </div>
      <div className="onboarding-middle-summary">
        {!doneAnimating && (
          <div
            style={{
              margin: "0 auto",
              width: `${window.innerWidth}px`,
              position: "absolute",
              height: "98vh",
              top: "0",
              zIndex: 0,
            }}
          >
            <Confetti
              width={window.innerWidth}
              numberOfPieces={750}
              tweenDuration={5000}
              recycle={false}
              onConfettiComplete={() => setDoneAnimating(true)}
            />
          </div>
        )}
        <div
          className="thanks-section"
          style={{ display: "flex", flexDirection: "column", width: "60%" }}
        >
          <h5>
            Thank you! Your feedback will help us improve Pylon. Let's get
            started!
          </h5>
        </div>
      </div>
    </div>
  );
};
