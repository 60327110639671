import credit from "./assets/credit.png";
import institutionImage from "./assets/institutions.png";
import partner from "./assets/partner.png";
import taxes from "./assets/taxes.png";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { Emoji } from "components/core/emoji";
import { FinancialPlanProgressStep } from "core/queries/dashboard";
import { Icon, IconAsset } from "components/core/icons";
import { Pill, PillSize } from "components/core/pill";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import { useState } from "react";
import "./styles.css";
import {
  FinancialPlanStepModal,
  FinancialPlanStepModalButtons,
} from "components/features/dashboard/components/financial-plan-table/components/financial-plan-step-modal";

enum PlanStatusType {
  ToDo = "To Do",
  InProgress = "In Progress",
  Complete = "Complete",
}

interface FinancialPlanTableProps {
  steps: FinancialPlanProgressStep[];
  closeParentModal: () => void;
}

export enum StepProgressIDs {
  AddAccount = "AddAccount",
  AddCollaborator = "AddCollaborator",
  LinkCreditScore = "LinkCreditScore",
  UpdateIncomeAndTaxes = "UpdateIncomeAndTaxes",
  CreateEmergencyFund = "CreateEmergencyFund",
  SetUpLifeInsurancePlan = "SetUpLifeInsurancePlan",
  SetUpEstatePlan = "SetUpEstatePlan",
}

export const FinancialPlanTable: React.FC<FinancialPlanTableProps> = ({
  steps,
  closeParentModal,
}) => {
  const mobileLayout = useMobileLayout();
  const stepCompletionToPlanStatus = (step: FinancialPlanProgressStep) => {
    return step.isCompleted ? PlanStatusType.Complete : PlanStatusType.ToDo;
  };

  const statusByStepID: { [key: string]: PlanStatusType } = {};
  steps.forEach((step) => {
    statusByStepID[step.id] = stepCompletionToPlanStatus(step);
  });

  return (
    <Table title="Financial Onboarding" selectable>
      <div className="table-head">
        <div className="table-cell">Name</div>
        <div className="table-cell setup-time">Setup Time</div>
        <div className="table-cell status">Status</div>
      </div>
      <div className="table-body">
        <PlanRow
          name="Create your profile"
          duration="1 min"
          status={PlanStatusType.Complete}
        />
        <PlanRow
          name="Connect your accounts"
          duration="8 mins"
          step={StepProgressIDs.AddAccount}
          status={statusByStepID[StepProgressIDs.AddAccount]}
          secondaryText={[
            ["💰️", "Track your net worth"],
            ["📈", "Reduce recurring expenses"],
            ["🗓", "Download monthly financial statements"],
            ["⚖️", "Monitor all your investments"],
            ["💎", "Get real-time budget updates"],
          ]}
          closeParentModal={closeParentModal}
        />
        <PlanRow
          name={`Add your partner ${
            mobileLayout ? "" : "and connect to accounts"
          }`}
          duration="4 mins"
          step={StepProgressIDs.AddCollaborator}
          status={statusByStepID[StepProgressIDs.AddCollaborator]}
          secondaryText={[
            ["🌋", "Eliminate duplicate expenses"],
            ["🔗", "View your joint net worth"],
            ["📈", "Share your credit scores"],
            ["📄", "Save important documents together"],
            ["💵", "Manage a shared budget"],
          ]}
          closeParentModal={closeParentModal}
        />
        <PlanRow
          name="Check your credit score"
          duration="2 mins"
          step={StepProgressIDs.LinkCreditScore}
          status={statusByStepID[StepProgressIDs.LinkCreditScore]}
          secondaryText={[]}
          // ["💳", "Free credit monitoring"]
          closeParentModal={closeParentModal}
        />
        <PlanRow
          name="Update your income and taxes"
          duration="8 mins"
          step={StepProgressIDs.UpdateIncomeAndTaxes}
          status={statusByStepID[StepProgressIDs.UpdateIncomeAndTaxes]}
          secondaryText={[
            ["💸", "Estimate your refund"],
            ["🧾", "Track tax changes throughout the year"],
            ["💵", "Update your withholdings"],
            ["📉", "Reduce your tax bill"],
          ]}
          closeParentModal={closeParentModal}
        />
      </div>
    </Table>
  );
};

export interface PlanRowSecondaryText {
  icon: string;
  text: string;
}

export interface PlanRowProps {
  name: string;
  duration: string;
  step?: StepProgressIDs;
  status: PlanStatusType;
  secondaryText?: [string, string][];
  closeParentModal?: () => void;
}

export const PlanRow: React.FC<PlanRowProps> = ({
  name,
  duration,
  step,
  status,
  secondaryText,
  closeParentModal,
}) => {
  const showSecondaryText = status !== PlanStatusType.Complete;
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();

  const getViewForStep = () => {
    switch (step) {
      case StepProgressIDs.AddAccount:
        return (
          <>
            <img
              src={institutionImage}
              width={"100%"}
              alt="financial institution logos"
            />
            <div
              style={{
                margin: "2.5rem",
                marginTop: "1.5rem",
              }}
            >
              <div style={{ marginBottom: "0.5rem" }}>
                <Pill
                  size={PillSize.Small}
                  textColor={Colour.Green500}
                  backgroundColor={Colour.Green50}
                >
                  Estimated Setup Time: {duration}
                </Pill>
              </div>
              <TextD style={TextStyle.M31B}>Connect your accounts</TextD>
              <div
                style={{
                  margin: "0.5rem 0",
                }}
              >
                <TextD>
                  Aggregate all your financial accounts into one view to keep a
                  pulse on your money. By connecting, you'll unlock the
                  following:
                </TextD>
              </div>
              {secondaryText?.map((st, i) => {
                return (
                  <div className="flex-row" style={{ gap: "0.25 rem" }} key={i}>
                    <Emoji emoji={st[0]} />
                    <div>{st[1]}</div>
                  </div>
                );
              })}
              <FinancialPlanStepModalButtons
                step={StepProgressIDs.AddAccount}
                onClose={() => setShowModal(false)}
                onNext={() => {
                  closeParentModal?.();
                  setShowModal(false);
                  history.push(DashboardPages.Integrations);
                }}
              />
            </div>
          </>
        );
      case StepProgressIDs.AddCollaborator:
        return (
          <>
            <img src={partner} width={"100%"} alt="couple holding hands" />
            <div
              style={{
                margin: "2.5rem",
                marginTop: "1.5rem",
              }}
            >
              <div style={{ marginBottom: "0.5rem" }}>
                <Pill
                  size={PillSize.Small}
                  textColor={Colour.Green500}
                  backgroundColor={Colour.Green50}
                >
                  Estimated Setup Time: {duration}
                </Pill>
              </div>
              <TextD style={TextStyle.M31B}>
                Add your partner and connect accounts
              </TextD>
              <div
                style={{
                  margin: "0.5rem 0",
                }}
              >
                <TextD>
                  Collaborate on your finances with a partner. You each control
                  which account views are shared or remain private.
                </TextD>
              </div>
              {secondaryText?.map((st, i) => {
                return (
                  <div className="flex-row" style={{ gap: "0.25 rem" }} key={i}>
                    <Emoji emoji={st[0]} />
                    <div>{st[1]}</div>
                  </div>
                );
              })}
              <FinancialPlanStepModalButtons
                step={StepProgressIDs.AddCollaborator}
                onClose={() => setShowModal(false)}
                onNext={() => {
                  closeParentModal?.();
                  setShowModal(false);
                  history.push(`${DashboardPages.ManageContact}?addNew=1`);
                }}
              />
            </div>
          </>
        );

      case StepProgressIDs.LinkCreditScore:
        return (
          <>
            <img src={credit} width={"100%"} alt="credit cards" />
            <div
              style={{
                margin: "2.5rem",
                marginTop: "1.5rem",
              }}
            >
              <div style={{ marginBottom: "0.5rem" }}>
                <Pill
                  size={PillSize.Small}
                  textColor={Colour.Green500}
                  backgroundColor={Colour.Green50}
                >
                  Estimated Setup Time: {duration}
                </Pill>
              </div>
              <TextD style={TextStyle.M31B}>Check your credit score</TextD>
              <div
                style={{
                  margin: "0.5rem 0",
                }}
              >
                <TextD>
                  Monitoring your credit is free and doesn't hurt your score.
                  It's updated every 30 days for you to stay on top of your
                  credit!
                </TextD>
              </div>
              {secondaryText?.map((st, i) => {
                return (
                  <div className="flex-row" style={{ gap: "0.25 rem" }} key={i}>
                    <Emoji emoji={st[0]} />
                    <div>{st[1]}</div>
                  </div>
                );
              })}
              <FinancialPlanStepModalButtons
                step={StepProgressIDs.LinkCreditScore}
                onClose={() => setShowModal(false)}
                onNext={() => {
                  closeParentModal?.();
                  setShowModal(false);
                  history.push(DashboardPages.CreditHistory);
                }}
              />
            </div>
          </>
        );
      case StepProgressIDs.UpdateIncomeAndTaxes:
        return (
          <>
            <img src={taxes} width={"100%"} alt="tax forms" />
            <div
              style={{
                margin: "2.5rem",
                marginTop: "1.5rem",
              }}
            >
              <div style={{ marginBottom: "0.5rem" }}>
                <Pill
                  size={PillSize.Small}
                  textColor={Colour.Green500}
                  backgroundColor={Colour.Green50}
                >
                  Estimated Setup Time: {duration}
                </Pill>
              </div>
              <TextD style={TextStyle.M31B}>Update your income and taxes</TextD>
              <div
                style={{
                  margin: "0.5rem 0",
                }}
              >
                <TextD>
                  Pylon has built one of the most comprehensive tax estimators
                  on the internet. We do our best to stay completely up to date
                  with federal, state, and local tax codes to give you the most
                  accurate representation of your taxes. Use our calculator to:
                </TextD>
              </div>
              {secondaryText?.map((st, i) => {
                return (
                  <div className="flex-row" style={{ gap: "0.25 rem" }} key={i}>
                    <Emoji emoji={st[0]} />
                    <div>{st[1]}</div>
                  </div>
                );
              })}
              <FinancialPlanStepModalButtons
                step={StepProgressIDs.UpdateIncomeAndTaxes}
                onClose={() => setShowModal(false)}
                onNext={() => {
                  closeParentModal?.();
                  setShowModal(false);
                  history.push(DashboardPages.IncomeAndTaxes);
                }}
              />
            </div>
          </>
        );
    }
  };

  return (
    <>
      <FinancialPlanStepModal
        complete={status === PlanStatusType.Complete}
        showModal={showModal}
        onClose={() => setShowModal(false)}
      >
        {getViewForStep()}
      </FinancialPlanStepModal>
      <div
        data-selector={`financial-plan-table-row-${step}-header`}
        onClick={() => {
          if (step && status !== PlanStatusType.Complete) {
            setShowModal(true);
          }
        }}
        className={`table-row plan-row ${
          status === PlanStatusType.Complete ? "disabled" : ""
        }`}
      >
        <div className="table-cell flex-column">
          <div className="flex-row">
            <div style={{ marginRight: ".5rem", display: "inline-flex" }}>
              {status === PlanStatusType.Complete && (
                <Icon asset={IconAsset.CheckMark} width="24px" height="24px" />
              )}
              {status === PlanStatusType.ToDo && (
                <Icon
                  asset={IconAsset.ArrowPointingRight}
                  width="24px"
                  height="24px"
                />
              )}
            </div>
            <Text style={TextStyle.M15SM} type={TextType.Div}>
              {name}
            </Text>
          </div>
          {showSecondaryText && secondaryText && (
            <div
              style={{ marginTop: ".5rem" }}
              data-selector={`financial-plan-table-row-${step}-items-list`}
            >
              <div
                className="flex-column"
                style={{
                  marginLeft: "40px",
                  marginBottom: ".5rem",
                }}
              >
                {secondaryText.map((st, i) => {
                  return (
                    <div
                      data-selector={`financial-plan-table-row-${step}-items-list-${st[1]}`}
                      className="flex-row"
                      style={{ marginBottom: ".25rem" }}
                      key={i}
                    >
                      <Emoji emoji={st[0]} />
                      <div>{st[1]}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div className="table-cell setup-time">{duration}</div>
        <div className="table-cell status">
          <StatusLabel statusType={status} />
        </div>
      </div>
    </>
  );
};

interface TableProps {
  title?: string;
  selectable?: boolean;
}

const Table: React.FC<TableProps> = ({
  title,
  selectable = false,
  children,
}) => {
  return (
    <div>
      {title && (
        <div className="budget-table__section-header">
          <TextD style={TextStyle.M17SB} weight={700}>
            {title}
          </TextD>
        </div>
      )}
      <div className={`table ${selectable && "selectable"}`}>{children}</div>
    </div>
  );
};

interface StatusLabelProps {
  statusType: PlanStatusType;
}
const getBackgroundColorFromType = (statusType: PlanStatusType) => {
  switch (statusType) {
    case PlanStatusType.ToDo:
      return Colour.Red100;
    case PlanStatusType.Complete:
      return Colour.Green100;
    default:
      return Colour.Gray100;
  }
};
const getColorFromType = (statusType: PlanStatusType) => {
  switch (statusType) {
    case PlanStatusType.ToDo:
      return Colour.Red700;
    case PlanStatusType.Complete:
      return Colour.Green800;
    default:
      return Colour.Gray800;
  }
};

const StatusLabel: React.FC<StatusLabelProps> = ({ statusType }) => {
  const bgColor = getBackgroundColorFromType(statusType);
  const color = getColorFromType(statusType);
  return (
    <div
      style={{
        background: bgColor,
        display: "inline-flex",
        borderRadius: ".5rem",
        padding: "2px 8px",
        color: color,
      }}
    >
      {statusType}
    </div>
  );
};
