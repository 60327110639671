import * as React from "react";
import { useState } from "react";

export interface TrayData {
  id?: string; // If provided, tray with same id will not be pushed
  onClose?: () => void;
  children: React.ReactNode;
  header: React.ReactNode;
  className?: string;
}

export interface TrayContextValue {
  trayStack: TrayData[];
  pushTray: (tray: TrayData) => void;
  popTray: () => void;
  clearStack: () => void;
  /*Snapshots current tray stack that can be restored later */
  snapshotStack: () => TrayData[];
  /* Restores current stack to previously snapshotted stack. If snapshotStack is never invoked this resets stack */
  restoreSnapshotStack: (snapshot: TrayData[]) => void;
}

export const SideTrayContext = React.createContext<TrayContextValue>({
  trayStack: [],
  pushTray: () => null,
  popTray: () => null,
  clearStack: () => null,
  snapshotStack: () => {
    return [];
  },
  restoreSnapshotStack: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const SideTrayContextProvider: React.FC<Props> = (props: Props) => {
  const [trayStack, setTrayStack] = useState<TrayData[]>([]);

  const pushTray = (tray: TrayData) => {
    if (tray.id) {
      // Do nothing if the current
      if (
        trayStack.length > 0 &&
        trayStack[trayStack.length - 1].id === tray.id
      ) {
        return;
      }
    }
    trayStack.push(tray);
    setTrayStack([...trayStack]);
  };

  const popTray = () => {
    const tray = trayStack.pop();

    tray?.onClose?.();

    setTrayStack([...trayStack]);
  };

  const clearStack = () => {
    for (let i = trayStack.length - 1; i >= 0; i--) {
      trayStack[i].onClose?.();
    }

    setTrayStack([]);
  };

  const snapshotStack = () => {
    return [...trayStack];
  };

  const restoreSnapshotStack = (snapshot: TrayData[]) => {
    setTrayStack([...snapshot]);
  };

  return (
    <SideTrayContext.Provider
      value={{
        trayStack: trayStack,
        pushTray: pushTray,
        popTray: popTray,
        clearStack: clearStack,
        snapshotStack: snapshotStack,
        restoreSnapshotStack: restoreSnapshotStack,
      }}
    >
      {props.children}
    </SideTrayContext.Provider>
  );
};
