import _ from "lodash";
import { OptionalIntegratedField } from "core/models";

export const getProjectedPaymentsInfo = (
  balance: number,
  interestRate?: string,
  monthlyPayment?: string,
  dueDate?: string
): {
  valid: boolean;

  projectedInterest?: number;
  projectedPrincipal?: number;
} => {
  const projectedInterest =
    interestRate !== undefined
      ? (balance * Number.parseFloat(interestRate)) / 100 / 12
      : undefined;
  const projectedPrincipal =
    monthlyPayment !== undefined && projectedInterest !== undefined
      ? Number.parseFloat(monthlyPayment) - projectedInterest
      : undefined;

  const autoUpdateDisabled =
    dueDate === undefined ||
    monthlyPayment === undefined ||
    interestRate === undefined ||
    projectedPrincipal === undefined ||
    projectedPrincipal < 0;

  return {
    valid: !autoUpdateDisabled,

    projectedInterest: projectedInterest,
    projectedPrincipal: projectedPrincipal,
  };
};

export const formatNumberToString = (v?: number): string | undefined => {
  if (_.isNil(v) || Number.isNaN(v)) {
    return undefined;
  }
  return v.toFixed(2);
};

export const validNumberIntegrateField = (
  field?: OptionalIntegratedField<string>
) => {
  return field?.isIntegrated && Number.parseFloat(field?.value ?? "0") !== 0;
};

export const validStringIntegrateField = (
  field?: OptionalIntegratedField<string>
) => {
  return field?.isIntegrated && field?.value && field.value.trim() !== "";
};
