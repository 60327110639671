import { useEffect, useState } from "react";

export const useLocalStorage = (
  key: string,
  defaultValue: any,
  storeEmpty: boolean = true
) => {
  return useStorage(key, defaultValue, localStorage, storeEmpty);
};

export const useSessionStorage = (key: string, defaultValue: any) => {
  return useStorage(key, defaultValue, sessionStorage);
};

const useStorage = (
  key: string,
  defaultValue: any,
  storage: Storage,
  storeEmpty: boolean = true
) => {
  const stored = storage.getItem(key);
  const initial = stored ? JSON.parse(stored) : defaultValue;
  const [value, setValue] = useState(initial);
  useEffect(() => {
    if (!storeEmpty && !value) {
      return;
    }
    storage.setItem(key, JSON.stringify(value));
  }, [storage, key, value, storeEmpty]);

  return [value, setValue];
};
