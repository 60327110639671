import React, { useContext } from "react";
import { Colour } from "core/models";
import { ManageCategoriesTray } from "components/features/dashboard/components/transactions/manage-categories-tray";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import { Text, TextStyle, TextType } from "components/core/text";

export interface Props {
  showBack?: boolean;
}

export const ManageCategoryButton: React.FC<Props> = ({ showBack }) => {
  const { pushTray } = useContext(SideTrayContext);

  return (
    <div
      className="flex-row"
      onClick={() => {
        pushTray({
          header: <SimpleSideTrayHeader text="Manage Categories" />,

          children: <ManageCategoriesTray />,
        });
      }}
      style={{
        cursor: "pointer",
        marginRight: ".5rem",
      }}
    >
      <Text
        type={TextType.Div}
        style={TextStyle.Default}
        colour={Colour.Blue01}
      >
        Manage Categories
      </Text>
    </div>
  );
};
