import * as React from "react";
import { Colour } from "../../../../../core/models";
import { ConfirmationModal } from "components/core/modal";
import { DocumentData } from "components/features/dashboard/models/document";
import { Icon, IconAsset } from "components/core/icons";
import { SessionContext } from "../../../../contexts/session-context-provider";
import { ShareTray } from "../../components/share-tray";
import { Text, TextStyle, TextType } from "components/core/text";

interface Props {
  document?: DocumentData | null;
}

export const ShareDocumentQuickAction: React.FC<Props> = ({ document }) => {
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const { userID } = React.useContext(SessionContext.context);
  const isSharedWithMe = document?.owner?.id !== userID;

  if (!document || isSharedWithMe) {
    return null;
  }

  return (
    <>
      <ConfirmationModal
        children={
          <ShareTray asset={document} onClose={() => setShowShare(false)} />
        }
        showModal={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <div
        key="share"
        className="flex-row align-center"
        onClick={() => {
          setShowShare(true);
        }}
        style={{
          cursor: "pointer",
          gap: "0.5rem",
        }}
      >
        <Icon
          asset={IconAsset.Users}
          width="16px"
          height="16px"
          colour={Colour.Black}
        />
        <Text style={TextStyle.M15} type={TextType.Div}>
          Share
        </Text>
      </div>
    </>
  );
};
