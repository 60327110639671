import { gql } from "@apollo/client";
import { UserData } from "components/features/dashboard/models/sharing";

export interface ScenarioPlan {
  id: string;
  owner: UserData;
  scenarioName: string;
  scenarioType: string;
  value: string;
  createdAt: string;
  modifiedAt: string;

  isOwnerOrEditor: boolean;
  recipients?: UserData[];
  __typename?: "ScenarioPlan";
}

export interface FetchScenarioPlanResponse {
  scenarioPlansByType: {
    plans: ScenarioPlan[];
  };
}

export const SCENARIO_PLAN_FIELDS = gql`
  fragment ScenarioPlanFields on ScenarioPlan {
    id
    owner {
      id
      firstName
      lastName
      email
    }
    scenarioType
    scenarioName
    value
    isOwnerOrEditor
    createdAt
    modifiedAt
  }
`;

export const FETCH_SCENARIO_PLANS = gql`
  ${SCENARIO_PLAN_FIELDS}

  query FetchScenarioPlanByType($type: String!) {
    scenarioPlansByType(type: $type) {
      plans {
        ...ScenarioPlanFields
      }
    }
  }
`;

export type SaveScenarioPlanMutationInput = Pick<
  ScenarioPlan,
  "scenarioName" | "scenarioType" | "value"
> &
  Partial<Pick<ScenarioPlan, "id">>;

export const SAVE_SCENARIO_PLAN_MUTATION = gql`
  ${SCENARIO_PLAN_FIELDS}

  mutation saveNewScenarioPlan($input: SaveScenarioPlanInput!) {
    saveScenarioPlan(input: $input) {
      ...ScenarioPlanFields
    }
  }
`;

export interface DeleteScenarioPlanMutationInput {
  id: string;
}

export const DELETE_SCENARIO_PLAN_MUTATION = gql`
  mutation deleteScenarioPlan($id: ID!) {
    deleteScenarioPlan(id: $id)
  }
`;
