import React from "react";
import Toggle from "react-toggle";
import { Account } from "core/models";
import { AccountRow } from "components/features/dashboard/components/manage-collaborator/account-share-list-row";
import { Text, TextStyle, TextType } from "components/core/text";

interface Props {
  accounts: Account[];
  disabled: boolean;

  isAccShared: (accID: string) => boolean;
  onAccAdd: (accID: string) => void;
  onAccRemove: (accID: string) => void;

  unshareAll: () => void;
  shareAll: () => void;
}

export const ShareAccountList: React.FC<Props> = ({
  accounts,
  disabled,
  isAccShared,
  onAccAdd,
  onAccRemove,
  unshareAll,
  shareAll,
}) => {
  const isAllShared = accounts.every((acc) => isAccShared(acc.account_id));

  const accountRows =
    accounts.map((acc) => (
      <AccountRow
        key={acc.id}
        account={acc}
        isShared={isAccShared(acc.account_id)}
        disabled={disabled}
        onRemove={() => {
          if (!disabled) {
            onAccRemove(acc.account_id);
          }
        }}
        onAdd={() => {
          if (!disabled) {
            onAccAdd(acc.account_id);
          }
        }}
      />
    )) || [];

  return (
    <>
      {accounts.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <Text style={TextStyle.FieldLabel} type={TextType.Div}>
            {isAllShared
              ? "Unshare All Accounts Below"
              : "Share All Accounts Below"}
          </Text>
          <Toggle
            icons={false}
            checked={isAllShared}
            disabled={disabled}
            onChange={() => {
              if (disabled) {
                return;
              }

              if (isAllShared) {
                unshareAll();
              } else {
                shareAll();
              }
            }}
          />
        </div>
      )}

      <div
        style={{
          padding: "1rem 0",
        }}
      />
      {accountRows.length > 0 && accountRows}
    </>
  );
};
