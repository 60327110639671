import * as React from "react";
import Modal from "react-modal";
import { AddDocumentTray } from "../../components/add-document-tray";
import { CategoryIcon } from "../../components/category-icon";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { DocumentCategoryType } from "../../../../../core/models/documents";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentRowCardList } from "components/features/dashboard/components/document-row-card-list";
import { Icon, IconAsset } from "../../../../core/icons";
import { Input } from "components/core/input";
import { LeafButton, LeafButtonStyle } from "components/core/leaf-button";
import { Link } from "react-router-dom";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { PylonTooltip, TooltipIconSize } from "components/core/tooltip";
import { RowCardRecipients } from "components/features/dashboard/components/row-card-recipients";
import { ShareTray } from "components/features/dashboard/components/share-tray";
import { SideTrayContext } from "../../../../contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import "./document-cards.css";
import {
  CREATE_DOCUMENT_FOLDER,
  DELETE_FOLDER,
  DocumentCategoriesResponse,
  DocumentCategory,
  FETCH_DOCUMENT_CATEGORIES,
} from "core/queries/documents";
import {
  ConfirmationModal,
  ConfirmationModalOld,
} from "../../../../core/modal";

interface Props {}

export interface DocumentResponse {
  documents: DocumentData[];
}

export const DocumentCards: React.FC<Props> = (props: Props) => {
  const { pushTray, popTray } = React.useContext(SideTrayContext);

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [folderName, setFolderName] = React.useState<string>();
  const [folderToDeleteID, setFolderToDeleteID] = React.useState<string>();
  const [showFolderDeletionPrompt, setShowFolderDeletionPrompt] =
    React.useState<boolean>(false);
  const { data, refetch } = useQuery<DocumentCategoriesResponse>(
    FETCH_DOCUMENT_CATEGORIES,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [createFolder] = useMutation(CREATE_DOCUMENT_FOLDER, {
    onError: (err) => {
      console.error("failed to add account", err);
      toast(
        <PylonToastBody
          title={"Error Occurred"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteFolderMutation] = useMutation(DELETE_FOLDER, {
    onError: (err) => {
      console.error("failed to delete folder", err);
      toast(
        <PylonToastBody
          title={"Error Occurred"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
    refetchQueries: [{ query: FETCH_DOCUMENT_CATEGORIES }],
  });

  const customFolders = data?.documentCategories?.filter((dc) => {
    return dc.category.type === DocumentCategoryType.Folder;
  });

  const accountFolders = data?.documentCategories?.filter((dc) => {
    return (
      dc.category.type === DocumentCategoryType.Account && dc.documents.length
    );
  });

  const looseDocuments = data?.documentCategories?.filter((dc) => {
    return (
      dc.category.type !== DocumentCategoryType.Account &&
      dc.category.type !== DocumentCategoryType.Folder
    );
  });

  const deleteFolder = (folderID: string) => {
    setFolderToDeleteID(folderID);
    setShowFolderDeletionPrompt(true);
  };
  const addFolder = (value?: string) => {
    const folderNameToUse = value || folderName;
    if (!folderNameToUse) {
      alert("Please provide a name for this folder.");
      return;
    }

    setShowModal(false);
    createFolder({
      variables: {
        name: folderNameToUse,
      },
    });
  };

  const { setOnUpdateRefetchQueries } = React.useContext(DataPageContext);

  React.useEffect(() => {
    setOnUpdateRefetchQueries([{ query: FETCH_DOCUMENT_CATEGORIES }]);

    // Only set once on coomponent mount; this does not depend on anything else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper
      header={
        <PageTitle
          title="Documents"
          subtitle="Store all of your financial documents in one place for easy access and collaboration."
        />
      }
      actions={
        <div>
          <LeafButton
            buttonStyle={LeafButtonStyle.LeafSmallDense}
            onClick={() =>
              pushTray({
                header: <SimpleSideTrayHeader text="Add Document" />,
                children: <AddDocumentTray onUpload={() => refetch()} />,
                onClose: () => {
                  popTray();
                },
              })
            }
          >
            Add Document
          </LeafButton>
        </div>
      }
    >
      <div className="document-cards ">
        <div>
          <div>
            <div
              className="section-header"
              style={{
                marginTop: "0",
              }}
            >
              Your Folders
            </div>
            <div className={`folder-layout flex-row mobile-flex-column`}>
              {customFolders?.map((af) => {
                return <CustomFolder folder={af} onDelete={deleteFolder} />;
              })}
              <div
                className="folder add-folder"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <div></div>
                <div>
                  <Icon
                    asset={IconAsset.AddCircle}
                    width="48px"
                    height="48px"
                  />
                </div>
                <div>Add Folder</div>
              </div>
            </div>
          </div>
          {accountFolders && accountFolders.length > 0 && (
            <div>
              <div className="section-header">
                Account Folders{" "}
                <PylonTooltip
                  text={
                    <Text>
                      Pylon automatically creates <b>Account Folders</b> as you
                      add documents to specific accounts. If you delete an
                      account, all documents will be saved and moved to a Misc
                      folder. You cannot delete these account folders without
                      deleting your account.
                    </Text>
                  }
                  size={TooltipIconSize.Small}
                />
              </div>
              <div className="folder-layout flex-row mobile-flex-column">
                {accountFolders?.map((af) => {
                  return (
                    <Link
                      key={af.category.id}
                      to={`${DashboardPages.DocumentFolder}?folderID=${af.category.id}&folderType=${af.category.type}`}
                    >
                      <div className={`folder`}>
                        <div className="icon-row">
                          <div>
                            <CategoryIcon category={af.category} scale={1} />
                          </div>
                        </div>
                        <div>
                          <div className="folder-name">{af.category.name}</div>
                          <div className="folder-count">
                            {af.documents.length} Documents
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}

          {looseDocuments && looseDocuments?.length > 0 && (
            <div className="loose-documents">
              <div className="section-header" style={{ marginBottom: "1rem" }}>
                Uncategorized Documents
              </div>
              <div className={`folder-layout `}>
                <DocumentRowCardList documents={looseDocuments[0].documents} />
                {/* {looseDocuments[0].documents.map((d) => {
                return (
                  <DocumentRowCard
                    key={d.id}
                    document={d}
                    open={d.id === openDocumentID}
                    onOpen={() => {
                      const search = qs.stringify({
                        documentID: d.id,
                      });
                      history.push(`${DashboardPages.Documents}?${search}`);
                    }}
                    onClose={() => {
                      history.push(DashboardPages.Documents);
                    }}
                  />
                );
              })} */}
              </div>
            </div>
          )}
        </div>

        <Modal
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          onRequestClose={() => {}}
          isOpen={showModal}
          className="modal-base documents-modal"
          overlayClassName="overlay-base"
          style={{
            overlay: {
              backgroundColor: "rgba(3, 12, 23, 0.7)",
            },
            content: {
              width: "30rem",
              height: "auto",
            },
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "1rem",
              right: "1.5rem",
              width: "auto",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            <Icon
              asset={IconAsset.Close}
              width="32px"
              height="32px"
              colour={Colour.Text02}
            />
          </div>
          <div
            className={"document-modal-wrapper"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h6>Add Folder</h6>
            <Input
              placeholder="Untitled"
              defaultValue="Untitled folder"
              onFocus={(e) => {
                e.target.select();
              }}
              autoFocus
              onBlur={(e) => {
                setFolderName(e.currentTarget.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addFolder(e.currentTarget.value);
                }
              }}
            />
            <div className="mt1">
              <LeafButton
                onClick={() => {
                  addFolder();
                }}
              >
                Add Folder
              </LeafButton>
            </div>
          </div>
        </Modal>
        <ConfirmationModalOld
          width={"22rem"}
          showModal={showFolderDeletionPrompt}
          children={
            <div>
              Are you sure you want to delete this folder?
              <br /> <br />
            </div>
          }
          onAction={() => {
            deleteFolderMutation({
              variables: {
                id: folderToDeleteID,
              },
            });
            setFolderToDeleteID("");
            setShowFolderDeletionPrompt(false);
          }}
          actionButtonText="Delete"
          onCancel={() => {
            setShowFolderDeletionPrompt(false);
          }}
          cancelButtonText="Cancel"
        />
      </div>
    </PageWrapper>
  );
};

interface FolderProps {
  folder: DocumentCategory;
  onDelete: (id: string) => void;
}

export const CustomFolder: React.FC<FolderProps> = ({ folder, onDelete }) => {
  const [showShare, setShowShare] = React.useState<boolean>(false);
  const af = folder;
  return (
    <>
      <ConfirmationModal
        children={
          <ShareTray asset={af.category} onClose={() => setShowShare(false)} />
        }
        showModal={showShare}
        onClose={() => {
          setShowShare(false);
        }}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <Link
        key={af.category.id}
        to={`${DashboardPages.DocumentFolder}?folderID=${af.category.id}&folderType=${af.category.type}`}
      >
        <div className={`folder `}>
          <div className="icon-row">
            <div>
              <CategoryIcon category={af.category} scale={1} />
            </div>
          </div>
          <div className="hover-action">
            {!af.category.sharedWithMe && (
              <div>
                <div
                  className="on-hover"
                  onClick={(e) => {
                    e.preventDefault();

                    onDelete(af.category.id);
                  }}
                >
                  <Icon
                    asset={IconAsset.Trashcan}
                    width="24px"
                    height="24px"
                    colour={Colour.Blue01}
                  />
                </div>

                <div
                  className="on-hover"
                  style={{
                    paddingTop: "0.5rem",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowShare(true);
                  }}
                >
                  <Icon
                    asset={IconAsset.ShareAsset}
                    width="24px"
                    height="24px"
                    colour={Colour.Blue01}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="folder-name">{af.category.name}</div>
            <div className="folder-count">{af.documents.length} Documents</div>

            {af.category.recipients && (
              <div onClick={(e) => e.preventDefault()}>
                <div
                  style={{
                    padding: "0.25rem",
                  }}
                />
                <div
                  style={{
                    borderTop: "2px solid var(--gray-30)",
                  }}
                />
                <div
                  style={{
                    padding: "0.25rem",
                  }}
                />

                <RowCardRecipients
                  noDivider
                  fullTextClickable
                  recipients={af.category.recipients}
                  limitNameLength="7rem"
                />
              </div>
            )}

            {af.category?.folder?.suggestionID && (
              <div>
                <div
                  style={{
                    margin: "0.5rem  0",
                    borderTop: "2px solid var(--separator-01)",
                  }}
                />
                <Text
                  style={TextStyle.L12}
                  colour={Colour.Text03}
                  type={TextType.Div}
                  format={TextFormat.UpperCase}
                >
                  Suggested by Pylon
                </Text>
              </div>
            )}
          </div>
        </div>
      </Link>
    </>
  );
};
