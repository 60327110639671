import * as React from "react";
import jwt_decode from "jwt-decode";
import { createContext } from "core/utils/context";
import { logout } from "App";
import { SessionData } from "core/models";
declare let gtag: Function;

export const TOKEN_KEY = "pylon_token";
export const ACCESS_TOKEN_KEY = "pylon_access_token";
export const LAST2FA_TOKEN_KEY = "pylon_last2fa_token";

export interface SessionContextValue {
  sessionToken: string;
  email: string;
  userID: string;
  firstName: string;
  lastName: string;
  plaidAccessToken: string;
  emailVerified: boolean;
  createdAt: Date;
  initSessionContext: () => void;
  setSessionToken: (token: string) => void;
  setAccessToken: (accessToken: string) => void;
  setLast2FAToken: (token: string) => void;
  unsetSession: () => void;
}

export const SessionContext = createContext<SessionContextValue>({
  sessionToken: "",
  email: "",
  userID: "",
  firstName: "",
  lastName: "",
  plaidAccessToken: "",
  emailVerified: false,
  createdAt: new Date(),
  initSessionContext: () => null,
  setSessionToken: (_: string) => null,
  setAccessToken: (_: string) => null,
  setLast2FAToken: (_: string) => null,
  unsetSession: () => null,
});

export const withSessionContext = SessionContext.withContext;

interface Props {}

export class SessionContextProvider extends React.Component<
  Props,
  SessionContextValue
> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      ...loadSessionToken(),
      initSessionContext: this.initSessionContext,
      setSessionToken: this.setSessionToken,
      setAccessToken: this.setAccessToken,
      setLast2FAToken: this.setLast2FAToken,
      unsetSession: this.unsetSession,
    };
  }

  private unsetSession = () => {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.clear();
    this.setState({
      sessionToken: "",
      email: "",
      userID: "",
      firstName: "",
      lastName: "",
      plaidAccessToken: "",
    });
  };

  private setSessionToken = (token: string) => {
    if (!token) {
      console.error("SetSessionToken is called with empty value. Ignoring");
      return;
    }
    localStorage.setItem(TOKEN_KEY, token);
    const decoded: SessionData = jwt_decode(token);
    window.heap.identify(decoded.UserID);
    gtag("set", { user_id: decoded.UserID });
    this.setState({
      sessionToken: token,
      email: decoded.Email,
      userID: decoded.UserID,
      firstName: decoded.FirstName,
      lastName: decoded.LastName,
      emailVerified: decoded.IsEmailVerified,
      createdAt: decoded.CreatedAt,
    });
  };

  private setLast2FAToken = (token: string) => {
    localStorage.setItem(LAST2FA_TOKEN_KEY, token);
  };

  private setAccessToken = (accessToken: string) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    this.setState({
      plaidAccessToken: accessToken,
    });
  };

  private initSessionContext = () => {
    this.setState({
      ...loadSessionToken(),
    });
  };

  public render() {
    return (
      <SessionContext.InnerProvider value={this.state}>
        {this.props.children}
      </SessionContext.InnerProvider>
    );
  }
}

const loadSessionToken = (): Pick<
  SessionContextValue,
  | "sessionToken"
  | "email"
  | "userID"
  | "plaidAccessToken"
  | "firstName"
  | "lastName"
  | "emailVerified"
  | "createdAt"
> => {
  const sessionToken = localStorage.getItem(TOKEN_KEY) || "";
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || "";
  const parsedSessionToken: SessionData = !!sessionToken
    ? jwt_decode(sessionToken)
    : {
        Email: "",
        UserID: "",
        FirstName: "",
        LastName: "",
        IsEmailVerified: false,
        CreatedAt: new Date(),
        exp: 0,
      };

  // If the token already expired, log usr out immediately
  if (
    sessionToken !== "" &&
    parsedSessionToken.exp * 1000 < new Date().getTime()
  ) {
    logout();
  }

  return {
    sessionToken: sessionToken,
    firstName: parsedSessionToken?.FirstName,
    lastName: parsedSessionToken?.LastName,
    email: parsedSessionToken?.Email,
    userID: parsedSessionToken?.UserID,
    plaidAccessToken: accessToken,
    emailVerified: parsedSessionToken?.IsEmailVerified ?? false,
    createdAt: parsedSessionToken?.CreatedAt,
  };
};
