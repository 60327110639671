import _ from "lodash";
import IconTitle from "./title-icon.png";
import { Colour } from "core/models";
import { CurrencyInput } from "components/features/external/moved/currency-input";
import { LeafButton, LeafButtonColour } from "components/core/leaf-button";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs
  } from "react-tabs";
import { Text, TextStyle, TextType } from "components/core/text";
import { toMoneyStringNoDecimal } from "core/utils";
import { useState } from "react";
import "./styles.css";
import {
  PylonTooltip,
  SpacingSide,
  TooltipIconSize,
} from "components/core/tooltip";

interface PreMoveCosts {
  movingCompany?: string;
  travellingExpenses?: string;
  securityDeposit?: string;
  moveOutFee?: string;
  firstMonthRent?: string;
  newFurniture?: string;
  renovation?: string;
  otherMoveInFee?: string;
}

interface PostMoveOneTimeCost {
  newCar?: string;
  newBike?: string;
  tv?: string;
  yardTools?: string;
  decorations?: string;
  otherCost?: string;
}

interface PostMoveRecurringCost {
  storage?: string;
  utilities?: string;
  internet?: string;
  cableTV?: string;
  lawnCare?: string;
  insurances?: string;
  transitPass?: string;
  otherSubscriptions?: string;
}

interface Setting {
  title: string;
  isTaxDeductible?: boolean;
}

const PreMoveCostsLabels: Record<keyof PreMoveCosts, Setting> = {
  movingCompany: {
    title: "Moving Company",
    isTaxDeductible: true,
  },
  travellingExpenses: {
    title: "Travelling Expenses",
    isTaxDeductible: true,
  },
  securityDeposit: {
    title: "Security Deposit",
    isTaxDeductible: true,
  },
  firstMonthRent: {
    title: "First Month Rent",
    isTaxDeductible: true,
  },
  moveOutFee: {
    title: "Move out fees",
  },
  newFurniture: {
    title: "New Furniture",
  },
  otherMoveInFee: {
    title: "Other Move in fees",
  },
  renovation: {
    title: "Renovations",
  },
};

const PostMoveOneTimeCostLabels: Record<keyof PostMoveOneTimeCost, string> = {
  newCar: "New Car",
  newBike: "New Bike",
  tv: "New TV",
  yardTools: "Yard Tools",
  decorations: "Decorations",
  otherCost: "Other purchases",
};

const PostMoveRecurringCostLabels: Record<keyof PostMoveRecurringCost, string> =
  {
    storage: "Monthly Storage",
    utilities: "Monthly Utilities",
    internet: "Monthly Internet",
    cableTV: "Cable TV",
    lawnCare: "Lawn Care",
    insurances: "Insurances",
    transitPass: "Transit Pass",
    otherSubscriptions: "Other Subscriptions",
  };

const taxDeductionRate = 0.2;

export const MovingCalculatorBody: React.FC = () => {
  const [index, setIndex] = useState(0);
  const [preMove, setPreMove] = useState<PreMoveCosts>({});
  const [postMove, setPostMove] = useState<PostMoveOneTimeCost>({});
  const [postMoveRecurring, setPostMoveRecurring] =
    useState<PostMoveRecurringCost>({});

  const setPreMoveValue = (field: keyof PreMoveCosts) => {
    return (v: string | undefined) => {
      const newInput = {
        ...preMove,
      };
      newInput[field] = v;
      setPreMove(newInput);
    };
  };

  const setPostMoveOneTimeValue = (field: keyof PostMoveOneTimeCost) => {
    return (v: string | undefined) => {
      const newInput = {
        ...postMove,
      };
      newInput[field] = v;
      setPostMove(newInput);
    };
  };

  const setPostMoveRecurringValue = (field: keyof PostMoveRecurringCost) => {
    return (v: string | undefined) => {
      const newInput = {
        ...postMoveRecurring,
      };
      newInput[field] = v;
      setPostMoveRecurring(newInput);
    };
  };

  const totalPreMove =
    Object.values(preMove).length > 0
      ? sanitizeNumber(
          Object.values(preMove).reduce((x, y) => {
            const xVal = x ? Number.parseFloat(x) : 0;
            const yVal = y ? Number.parseFloat(y) : 0;
            return xVal + yVal;
          })
        )
      : 0;

  const totalPostMove =
    Object.values(postMove).length > 0
      ? sanitizeNumber(
          Object.values(postMove).reduce((x, y) => {
            const xVal = x ? Number.parseFloat(x) : 0;
            const yVal = y ? Number.parseFloat(y) : 0;
            return xVal + yVal;
          })
        )
      : 0;

  const taxDeductibleItems = Object.keys(preMove)
    .filter((k) => {
      const key = k as keyof PreMoveCosts;
      return (
        !!PreMoveCostsLabels[key]?.isTaxDeductible && asNumber(preMove[key]) > 0
      );
    })
    .map((k) => {
      const key = k as keyof PreMoveCosts;
      return {
        key: key,
        label: PreMoveCostsLabels[key],
        value: asNumber(preMove[key]) * taxDeductionRate,
      };
    });

  const totalOneTime = totalPreMove + totalPostMove;
  const deductibleTax =
    taxDeductibleItems.length > 0
      ? taxDeductibleItems
          .map((x) => x.value)
          .reduce((x, y) => {
            return x + y;
          })
      : 0;

  const totalOneTimeFinal = totalOneTime - deductibleTax;

  const recurringCostItems = Object.keys(postMoveRecurring)
    .filter((k) => {
      const key = k as keyof PostMoveRecurringCost;
      return (
        !!PostMoveRecurringCostLabels[key] &&
        asNumber(postMoveRecurring[key]) > 0
      );
    })
    .map((k) => {
      const key = k as keyof PostMoveRecurringCost;
      return {
        key: key,
        label: PostMoveRecurringCostLabels[key],
        value: postMoveRecurring[key],
      };
    });

  const recurringCost =
    Object.values(recurringCostItems).length > 0
      ? recurringCostItems
          .map((x) => asNumber(x.value))
          .reduce((x, y) => {
            return x + y;
          })
      : 0;

  return (
    <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
      <div
        style={{
          display: "flex",
        }}
      >
        <div>
          <div className="pylon__moved-calculator__left-side">
            <img height={30} width={240} src={IconTitle} alt="CollabTitle" />
            <div className="pylon__moved-calculator__result-card__separator" />

            <div className="pylon__moved-calculator__result-card">
              <ResultHeader title="Moving Cost" />
              <ResultTitle title="Pre Move" value={totalPreMove} />
              <ResultTitle title="Post Move" value={totalPostMove} />
              <div
                className="pylon__moved-calculator__result-card__separator"
                style={{
                  marginLeft: "0.5rem",
                }}
              />
              <ResultTitle
                title="Total One Time Cost"
                value={totalOneTime}
                bold
              />
              <div className="pylon__moved-calculator__result-card__padding" />

              <ResultHeader
                title="Tax Savings"
                tooltip="Some work related moving expenses are tax deductible."
              />
              {taxDeductibleItems.length > 0 && (
                <>
                  {taxDeductibleItems.map((item) => {
                    return (
                      <ResultTitle
                        key={item.key}
                        title={item.label.title}
                        value={item.value}
                      />
                    );
                  })}

                  <div
                    className="pylon__moved-calculator__result-card__separator"
                    style={{
                      marginLeft: "0.5rem",
                    }}
                  />
                </>
              )}

              <ResultTitle
                title="Total Deductible (Est.)"
                bold
                value={deductibleTax}
              />
              <div className="pylon__moved-calculator__result-card__padding" />
              <div className="pylon__moved-calculator__result-card__separator" />
              <ResultTitle
                title="Total (With Tax Savings)"
                value={totalOneTimeFinal}
                bold
                noIndentation
              />
            </div>

            <div className="pylon__moved-calculator__result-card__separator" />

            <div className="pylon__moved-calculator__result-card">
              <ResultHeader title="Recurring Costs" />

              {recurringCostItems.length > 0 && (
                <>
                  {recurringCostItems.map((item) => {
                    return (
                      <ResultTitle
                        key={item.key}
                        title={item.label}
                        value={asNumber(item.value)}
                      />
                    );
                  })}

                  <div className="pylon__moved-calculator__result-card__separator" />
                </>
              )}
              <ResultTitle
                title="Total Montly Costs"
                value={recurringCost}
                bold
                noIndentation
              />
            </div>

            <div className="pylon__moved-calculator__result-card__padding" />

            <LeafButton
              fullWidth
              onClick={() => {
                window.open("https://www.hellopylon.com/login", "_blank");
              }}
            >
              Save my results in Pylon
            </LeafButton>

            <LeafButton
              fullWidth
              buttonColour={LeafButtonColour.Transparent}
              onClick={() => {
                window.open("https://www.hellopylon.com/login", "_blank");
              }}
            >
              Save my tax receipts
            </LeafButton>
          </div>
        </div>
        <div className="pylon__moved-calculator__right-side">
          <Tabs
            className="pylon__moved-calculator-tabs"
            selectedIndex={index}
            onSelect={(i) => {
              setIndex(i);
            }}
          >
            <TabList className="pylon__moved-calculator-tab-list">
              <Tab
                selected={index === 0}
                className="pylon__moved-calculator-tab__tab-title"
                selectedClassName="pylon__moved-calculator-tab__tab-title-selected"
              >
                PRE MOVE
              </Tab>
              <Tab
                selected={index === 1}
                className="pylon__moved-calculator-tab__tab-title"
                selectedClassName="pylon__moved-calculator-tab__tab-title-selected"
              >
                POST MOVE
              </Tab>
            </TabList>

            <TabPanel className="pylon__moved-calculator-panel__content">
              <div className="pylon__moved-calculator__input-wrapper">
                {Object.keys(PreMoveCostsLabels).map((k) => {
                  const key = k as keyof PreMoveCosts;
                  return (
                    <div key={k} className="pylon__moved-calculator__input">
                      <CurrencyInput
                        id={key}
                        label={PreMoveCostsLabels[key]?.title ?? key}
                        value={preMove[key]}
                        onChange={setPreMoveValue(key)}
                      />
                      <div className="pylon__moved-calculator__result-card__padding" />
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel className="pylon__moved-calculator-panel__content">
              <div className="pylon__moved-calculator__input-wrapper">
                {Object.keys(PostMoveOneTimeCostLabels).map((k) => {
                  const key = k as keyof PostMoveOneTimeCost;
                  return (
                    <div key={k} className="pylon__moved-calculator__input">
                      <CurrencyInput
                        id={key}
                        label={PostMoveOneTimeCostLabels[key]}
                        value={postMove[key]}
                        onChange={setPostMoveOneTimeValue(key)}
                      />
                      <div className="pylon__moved-calculator__result-card__padding" />
                    </div>
                  );
                })}

                {Object.keys(PostMoveRecurringCostLabels).map((k) => {
                  const key = k as keyof PostMoveRecurringCost;
                  return (
                    <div key={k} className="pylon__moved-calculator__input">
                      <CurrencyInput
                        id={key}
                        label={PostMoveRecurringCostLabels[key]}
                        value={postMoveRecurring[key]}
                        onChange={setPostMoveRecurringValue(key)}
                      />
                      <div className="pylon__moved-calculator__result-card__padding" />
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </form>
  );
};

const ResultHeader: React.FC<{
  title: string;
  tooltip?: string;
}> = ({ title, tooltip }) => {
  return (
    <div className="pylon__moved-calculator__result-card__title">
      <div
        style={{
          display: "flex",
        }}
      >
        <Text
          style={TextStyle.L12}
          type={TextType.Div}
          size="0.85rem"
          colour={Colour.Text05}
        >
          {title}
        </Text>

        {!!tooltip && (
          <PylonTooltip
            text={tooltip}
            spacingSide={SpacingSide.Left}
            size={TooltipIconSize.Small}
          />
        )}
      </div>
    </div>
  );
};

const ResultTitle: React.FC<{
  title: string;
  value: number;
  noIndentation?: boolean;
  bold?: boolean;
  tooltip?: string;
}> = ({ title, value, noIndentation, bold, tooltip }) => {
  return (
    <div className="pylon__moved-calculator__result-card__title">
      <div
        style={{
          display: "flex",
          paddingLeft: noIndentation ? "unset" : "0.5rem",
        }}
      >
        <Text
          style={TextStyle.L12}
          type={TextType.Div}
          size="0.85rem"
          weight={bold ? undefined : 400}
          colour={Colour.Text05}
        >
          {title}
        </Text>
        {!!tooltip && (
          <PylonTooltip
            text={tooltip}
            spacingSide={SpacingSide.Left}
            size={TooltipIconSize.Small}
          />
        )}
      </div>

      <Text
        style={TextStyle.L12}
        type={TextType.Div}
        size="1rem"
        weight={500}
        colour={Colour.Blue01}
      >
        {toMoneyStringNoDecimal(value)}
      </Text>
    </div>
  );
};

const asNumber = (v: string | undefined) => {
  if (_.isNil(v)) {
    return 0;
  }

  const parsedV = Number.parseFloat(v);
  if (!parsedV) {
    return 0;
  }
  return parsedV;
};

const sanitizeNumber = (n?: number | string) => {
  if (_.isNaN(n) || _.isNil(n)) {
    return 0;
  }
  return asNumber(n as string);
};
