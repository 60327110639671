import { Account } from "core/models";
import { CategoryData } from "../../../../core/queries/documents";
import { UserData } from "components/features/dashboard/models/sharing";

export interface DocumentCategory {
  id: string;
  type: string;
  name: string;
}
export interface DocumentData {
  id: string;
  name: string;
  ownerID: string;
  mimeType: string;
  filename: string;
  createdAt: string;
  modifiedAt: string;
  categories?: CategoryData[];
  secondaryAssets?: Pick<
    Account,
    "account_id" | "type" | "name" | "institutionName" | "official_name"
  >[];
  downloadURL?: string;
  previewURL?: string;
  owner?: UserData;
  notes?: string;
  recipients?: UserData[];
  isOwner?: boolean;
  isEditor?: boolean;
  isOwnerOrEditor?: boolean;
  __typename?: "Document";
}

export const DocumentExtensionColorHex: { [key: string]: string } = {
  jpeg: "var(--orange-02)",
  png: "var(--purple-01)",
  pdf: "var(--blue-01)",
  gif: "var(--orange-01)",
  jpg: "var(--yellow-01)",
  bmp: "var(--blue-01)",
  txt: "var(--gray-70)",
  doc: "var(--gray-50)",
  xlsx: "var(--green-01)",
  csv: "var(--yellow-01)",
  xls: "var(--green-02)",
};
