import * as React from "react";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentIcon } from "components/features/dashboard/components/document-icon";
import { DocumentTags } from "components/features/dashboard/components/document-tags";
import { FETCH_DOCUMENT } from "core/queries/documents";
import { ManageDocumentOptions } from "components/features/dashboard/components/manage-document-options";
import { millisecondsReadable } from "core/utils/format";
import { useQuery } from "@apollo/client";
import { ViewDocumentButton } from "components/features/dashboard/components/view-document-button";
import "./styles.css";

interface PublicProps {
  document: DocumentData;
  skipDataLoading?: boolean;
}

interface GetDocumentResponse {
  document: DocumentData;
}

export const ViewDocumentTray: React.FC<PublicProps> = ({
  document: documentInput,
  skipDataLoading,
}) => {
  const { data } = useQuery<GetDocumentResponse>(FETCH_DOCUMENT, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: documentInput.id,
    },
    skip: skipDataLoading,
  });

  if (!documentInput) {
    return null;
  }
  const doc = data?.document ?? documentInput;

  return (
    <div className="manage-account-tray">
      <div className="manage-account-tray__content">
        <DocumentIcon document={doc} scale={1.33} />
        <div className="manage-account-tray__info-row">
          <div className="manage-account-tray__info-subrow">
            <div className="manage-account-tray__info-subrow-bold-text">
              {doc.name}
            </div>
            <div className="manage-account-tray__info-subrow-buttons">
              <div className="row-card__star"></div>
              <ManageDocumentOptions document={doc} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              paddingBottom: "1rem",
            }}
          >
            <DocumentTags
              assets={doc.categories ?? []}
              disabled
              sectionWrapperClassName="document-row__section-wrapper"
              tagsWrapperClassName="document-row__tag-wrapper"
            />
          </div>
          <div className="manage-account-tray__info-subrow">
            <div className="manage-account-tray__info-subrow-light-text">
              {`Last updated ${millisecondsReadable(
                new Date().getTime() - new Date(doc.modifiedAt).getTime()
              )} ago`}
            </div>
          </div>
        </div>

        <div>
          <ViewDocumentButton document={doc} />
        </div>
        <div className="manage-account-tray__properties"></div>
      </div>
    </div>
  );
};
