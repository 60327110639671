import * as React from "react";
import { Colour } from "core/models";
import { TaxOutcome } from "@pylon/taxes";
import { Text, TextStyle, TextType } from "components/core/text";
import { toMoneyString } from "core/utils";
import { useMobileLayout } from "core/hooks/responsive";
import "./tax-result-styles.css";

export interface TaxResult {
  outcome: TaxOutcome;
  inputState: string;
}

interface Props {
  currentStateTax: TaxResult;
  newStateTax: TaxResult;
}

export const TaxStateResults: React.FC<Props> = ({
  currentStateTax,
  newStateTax,
}) => {
  const mobileLayout = useMobileLayout();
  const currentTax = currentStateTax.outcome.stateOwed ?? 0;
  const newTax = newStateTax.outcome.stateOwed ?? 0;

  return (
    <div className="tax-state-results">
      <div
        className="flex-row between mobile-flex-column"
        style={{
          alignItems: "center",
          padding: "1rem 2rem",
          gap: "0.5rem",
        }}
      >
        <Text
          type={TextType.Div}
          style={TextStyle.TitlePoppins}
          colour={Colour.Navy}
          weight={600}
          size={mobileLayout ? "1.25rem" : undefined}
        >
          Current State Tax - {currentStateTax.inputState}
        </Text>
        <Text
          type={TextType.Div}
          style={TextStyle.TitlePoppins}
          colour={newTax < currentTax ? Colour.Red01 : Colour.Green01}
        >
          {toMoneyString(Math.abs(currentTax))}
        </Text>
      </div>

      <div
        className="flex-row between mobile-flex-column"
        style={{
          alignItems: "center",
          padding: "1rem 2rem",
          gap: "0.5rem",
        }}
      >
        <Text
          type={TextType.Div}
          style={TextStyle.TitlePoppins}
          colour={Colour.Navy}
          weight={600}
          size={mobileLayout ? "1.25rem" : undefined}
        >
          New State Tax - {newStateTax.inputState}
        </Text>
        <Text
          type={TextType.Div}
          style={TextStyle.TitlePoppins}
          colour={newTax <= currentTax ? Colour.Green01 : Colour.Red01}
        >
          {toMoneyString(Math.abs(newTax))}
        </Text>
      </div>
    </div>
  );
};
