import * as React from "react";
import { InvestmentHoldingsHighlightsSection } from "components/features/dashboard/components/investment-holdings-highlights";
import { InvestmentHoldingsSection } from "components/features/dashboard/components/investment-holdings";
import { InvestmentsSummarySection } from "components/features/dashboard/components/investments-summary-section";
import { InvestmentSummaryContextProvider } from "components/features/dashboard/components/investments-summary-section/context/investments-summary-context";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { PrivateHoldingsSection } from "components/features/dashboard/components/private-holdings";
import { RealEstateHoldingsSection } from "components/features/dashboard/components/real-estate-holdings";
import "./styles.css";
interface Props {}

export const InvestmentHoldings: React.FC<Props> = (props: Props) => {
  return (
    <InvestmentSummaryContextProvider>
      <PageWrapper
        header={
          <PageTitle
            title="Investments and Stocks"
            subtitle="See all of your investments and stock holdings in one spot."
          />
        }
        setWidth
      >
        <div className="investment-holdings">
          <div className="body">
            <InvestmentsSummarySection />
            <InvestmentHoldingsHighlightsSection showAllocationChart={true} />
            <InvestmentHoldingsSection />
            <PrivateHoldingsSection />
            <div className="mt2" />
            <RealEstateHoldingsSection />
          </div>
        </div>
      </PageWrapper>
    </InvestmentSummaryContextProvider>
  );
};
