import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import range from "lodash/range";
import React from "react";
import Select from "react-select";
import "./styles.css";

export type Props = ReactDatePickerProps & {
  yearOrder?: "asc" | "desc";
};

export const PylonDatePicker: React.FC<Props> = (props) => {
  let minYearRange = 1900;
  let maxYearRange = getYear(new Date()) + 1;

  if (props.minDate) {
    minYearRange = getYear(props.minDate);
  }

  if (props.maxDate) {
    maxYearRange = getYear(props.maxDate);
  }

  let years = range(minYearRange, maxYearRange, 1);
  if (props.yearOrder === "desc") {
    years = years.reverse();
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthOptions = months.map((option) => {
    return {
      label: option,
      value: months.indexOf(option),
    };
  });

  const yearOptions = years.map((option) => {
    return {
      label: option,
      value: option,
    };
  });

  return (
    <DatePicker
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Select
            styles={{
              valueContainer: (base) => {
                return {
                  ...base,
                  justifyContent: "center",
                };
              },
              input: (base) => {
                return {
                  ...base,
                  width: "3rem",
                };
              },
            }}
            value={{
              label: getYear(date),
              value: getYear(date),
            }}
            onChange={(v) => {
              if (v?.value) {
                changeYear(v.value);
              }
            }}
            options={yearOptions}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          ></Select>
          <div
            style={{
              paddingLeft: "1rem",
            }}
          />

          <Select
            styles={{
              valueContainer: (base) => {
                return {
                  ...base,
                  width: "6rem",
                  justifyContent: "center",
                };
              },
            }}
            value={{
              label: months[getMonth(date)],
              value: getMonth(date),
            }}
            onChange={(v) => {
              if (v?.value) {
                changeMonth(v.value);
              }
            }}
            options={monthOptions}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          ></Select>
        </div>
      )}
      {...props}
      className={`pylon-input ${props.className ?? ""}`}
    />
  );
};
