import * as React from "react";
import Select from "react-select";
import { accountShortName } from "core/models/account";
import { Colour } from "core/models";
import { ConfirmationModalOld } from "components/core/modal";
import { createSharedDataMutation } from "core/queries/shared-data";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { InviteNewUserButton } from "components/features/dashboard/components/invite-new-user-button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RecipientList } from "../manage-account-modal/recipient-list";
import { SharedSuccessToast } from "components/core/toasts/components/shared-success-toast";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { Text, TextFormat, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import "./styles.css";
import {
  ManageCollaboratorHeader,
  ManageCollaboratorTray,
} from "components/features/dashboard/components/manage-collaborator-tray";
import {
  AssetType,
  Collaboration,
  UserData,
} from "components/features/dashboard/models/sharing";
import {
  Asset,
  isAccount,
  isDocument,
  isDocumentCategory,
  isScenarioPlan,
} from "core/models/asset";
import {
  CollaborationQueryResponse,
  collaborationsQuery,
  recipientsForAssetQuery,
  recipientsForAssetQueryResponse,
} from "core/queries/collaborations";

export interface Props {
  asset: Asset;
  onAssetShared?: () => void;
}

export interface Option {
  value: string | undefined;
  label: string | React.ReactNode;
  isDisabled: boolean;
}

export interface ShareModalState {
  email: string;
  phone: string;
}

interface RecipientsResponse {
  recipientsForAsset: recipientsForAssetQueryResponse[];
}

export const ShareWidgetMini: React.FC<Props> = ({ asset, onAssetShared }) => {
  let assetID: string;
  let assetType: AssetType;
  let assetName: string;

  if (isAccount(asset)) {
    assetID = asset.account_id;
    assetType = AssetType.Account;
    assetName = accountShortName(asset);
  } else if (isDocument(asset)) {
    assetID = asset.id;
    assetType = AssetType.Document;
    assetName = asset.name;
  } else if (isDocumentCategory(asset)) {
    assetID = asset.id;
    assetType = AssetType.DocumentFolder;
    assetName = asset.name;
  } else if (isScenarioPlan(asset)) {
    assetID = asset.id;
    assetType = AssetType.ScenarioPlan;
    assetName = asset.scenarioName;
  } else {
    console.error("Unrecognized asset ", asset);
    throw new Error("Unrecognized asset ");
  }

  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const additionalRefetchQueries = onUpdateRefetchQueries ?? [];
  const { data: recipientsData, loading: recipientsLoading } =
    useQuery<RecipientsResponse>(recipientsForAssetQuery, {
      fetchPolicy: "cache-and-network",
      variables: {
        assetID: assetID,
        assetType: assetType,
      },
    });

  const { pushTray, popTray, snapshotStack, restoreSnapshotStack } =
    React.useContext(SideTrayContext);
  const [showModal, setShowModal] = React.useState(false);
  const [createSharedData] = useMutation(createSharedDataMutation, {
    refetchQueries: [
      {
        query: recipientsForAssetQuery,
        variables: {
          assetID: assetID,
          assetType: assetType,
        },
      },
      ...additionalRefetchQueries,
    ],
    onCompleted: () => {
      toast(SharedSuccessToast(assetType.toLowerCase()));
      onAssetShared?.();
      setselectedCollaboration(null);
    },
    onError: (err) => {
      console.error("Failed to create shared data", err);
    },
  });

  const {
    loading: createLoading,
    error,
    data,
  } = useQuery<CollaborationQueryResponse>(collaborationsQuery);
  const getCollabIDForRecipient = (recipientID: string) => {
    return (
      data?.collaborations.find(
        (c: Collaboration) => c.recipientID === recipientID
      )?.id ?? "missing-id"
    );
  };

  const [selectedCollaboration, setselectedCollaboration] = useState<
    Option | undefined | null
  >(undefined);

  if (createLoading || !recipientsData?.recipientsForAsset || error) {
    return null;
  }

  let recipients: UserData[] = recipientsData.recipientsForAsset
    .filter((r) => !r.isDeleted)
    .map((r) => r.user);

  const renderCollaboratorState = () => {
    const options: Option[] =
      data?.recipients?.map((r: UserData): Option => {
        const collabID = getCollabIDForRecipient(r.id);
        let labelText = r.email;

        if (r.firstName) {
          labelText = `${r.firstName} ${r.lastName}`;
        }

        const isDisabled = !!recipients.find((x) => x.id === r.id);
        let label = <>{labelText}</>;
        if (isDisabled) {
          label = (
            <OverlayTrigger
              placement="top-end"
              overlay={
                <Tooltip id={`shared-label-${r.id}`}>Already Shared</Tooltip>
              }
            >
              <div>{labelText}</div>
            </OverlayTrigger>
          );
        }
        return {
          value: collabID,
          label: label,
          isDisabled: isDisabled,
        };
      }) ?? [];

    options.push({
      label: "Invite a new collaborator",
      value: undefined,
      isDisabled: false,
    });
    return (
      <div className="share-widget__shared-state">
        {options?.length > 0 ? (
          <>
            <div
              className="share-widget__empty-state-share"
              style={{ marginBottom: "1rem" }}
            >
              <Text
                weight={700}
                size={"0.75rem"}
                colour={Colour.Navy}
                format={TextFormat.UpperCase}
              >
                share with others
              </Text>
            </div>
            <div
              className="share-widget__dropdown-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Select
                className="share-widget__user-dropdown"
                placeholder="Select Collaborator"
                disabled={recipientsLoading || createLoading}
                isSearchable={false}
                styles={{
                  input: () => ({ lineHeight: "2.875rem" }),
                }}
                value={selectedCollaboration}
                onChange={(e) => {
                  if (!e) {
                    setselectedCollaboration(undefined);
                    return;
                  }
                  if (!e.value) {
                    const snap = snapshotStack();
                    pushTray({
                      id: "manage-collaborator",
                      header: <ManageCollaboratorHeader />,
                      children: (
                        <ManageCollaboratorTray onClose={() => popTray()} />
                      ),
                      onClose: () => {
                        restoreSnapshotStack(snap);
                        setselectedCollaboration(undefined);
                      },
                    });
                    return;
                  }
                  setselectedCollaboration(e);
                  setShowModal(true);
                }}
                options={options}
              />
            </div>

            <div>
              <RecipientList asset={asset} compressedRows />
            </div>
          </>
        ) : (
          <InviteNewUserButton />
        )}
        <ConfirmationModalOld
          width={"22rem"}
          showModal={showModal}
          children={
            <div style={{ marginBottom: "2rem" }}>
              <Text type={TextType.Div} weight={400}>
                You're about to share <b>{assetName}</b> with{" "}
                <b>{selectedCollaboration?.label}</b>. You can always revoke
                this later.
              </Text>
            </div>
          }
          onAction={() => {
            if (!selectedCollaboration) {
              return;
            }
            createSharedData({
              variables: {
                collaborationID: selectedCollaboration.value,
                assetID: assetID,
                assetType: assetType,
              },
            });

            setShowModal(false);
            setselectedCollaboration(undefined);
          }}
          actionButtonText={"Share"}
          onCancel={() => {
            setShowModal(false);
            setselectedCollaboration(undefined);
          }}
          cancelButtonText="Cancel"
        />
      </div>
    );
  };

  return <>{renderCollaboratorState()}</>;
};
