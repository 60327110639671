import * as React from "react";
import { Account, Colour } from "core/models";
import { ConfirmationModal } from "components/core/modal";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { deleteAccountMutation } from "core/queries/accounts";
import { Icon, IconAsset } from "components/core/icons";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SessionContext } from "../../../../contexts/session-context-provider";
import {
  Text,
  TextD,
  TextStyle,
  TextType
  } from "components/core/text";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

export interface Props {
  account: Account;
  onRemove?: () => void;
}

export const DeleteAccountQuickActionButton: React.FC<Props> = ({
  account,
  onRemove,
}) => {
  const { userID } = React.useContext(SessionContext.context);
  const [showDelete, setShowDelete] = React.useState<boolean>(false);

  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);
  const [deleteAccount, { loading }] = useMutation(deleteAccountMutation, {
    refetchQueries: onUpdateRefetchQueries,
    onCompleted: () => {
      toast(
        <PylonToastBody
          title={"Account Removed"}
          body={`Account successfully removed.`}
        />
      );
      onRemove?.();
      setShowDelete(false);
    },
    onError: (err) => {
      console.error("failed to delete account", err);
      toast(
        <PylonToastBody
          title={"Error Occurred"}
          body={`${err.message}`}
          type={ToastType.Error}
        />
      );
    },
  });

  if (userID !== account.owner?.id) {
    return null;
  }

  return (
    <>
      <ConfirmationModal
        width="32rem"
        title="Are you sure you want to delete this account?"
        children={
          <>
            <TextD>This action is irreversible and cannot be undone.</TextD>
            <TextD>
              Documents associated with this account will still be accessible
              from the documents page.
            </TextD>
          </>
        }
        showModal={showDelete}
        onAction={() => {
          deleteAccount({
            variables: {
              id: account.account_id,
            },
          });
        }}
        onClose={() => {
          setShowDelete(false);
        }}
        onCancel={() => {
          setShowDelete(false);
        }}
        cancelButtonText="Cancel"
        actionButtonText="Delete"
        actionButtonLoading={loading}
        defaultStyling
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      />
      <div
        className="flex-row align-center"
        onClick={() => {
          setShowDelete(true);
        }}
        style={{
          cursor: "pointer",
          gap: "0.5rem",
        }}
      >
        <Icon
          asset={IconAsset.TrashcanAlt}
          width="16px"
          height="16px"
          colour={Colour.Black}
        />
        <Text style={TextStyle.M15} type={TextType.Div}>
          Delete
        </Text>
      </div>
    </>
  );
};
