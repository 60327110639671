import * as React from "react";
import { AssetType } from "components/features/dashboard/models/sharing";
import { ConfirmationModal } from "components/core/modal";
import { CreditHistory, CreditHistoryResponse } from "core/queries/credit";
import { SessionContext } from "../../../../contexts/session-context-provider";
import { ShareTray } from "../share-tray";
import { useQuery } from "@apollo/client";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  RecipientForAssetQueryResponse,
  recipientsForAssetQuery,
} from "core/queries/collaborations";

interface Props {
  creditHistoryResponse: CreditHistoryResponse;
}

export const ShareCreditHistoryButton: React.FC<Props> = ({
  creditHistoryResponse,
}) => {
  const { userID } = React.useContext(SessionContext.context);

  const [showShare, setShowShare] = React.useState<boolean>(false);
  const { data: recipientsData } = useQuery<RecipientForAssetQueryResponse>(
    recipientsForAssetQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        assetID: userID,
        assetType: AssetType.CreditHistory,
      },
    }
  );

  const recipientCount = React.useMemo(() => {
    return recipientsData?.recipientsForAsset.filter(
      (r) => r.isDeleted === false
    ).length;
  }, [recipientsData]);

  const ch = React.useMemo(() => {
    let c = new CreditHistory();
    c.ownerid = userID;
    return c;
  }, [userID]);

  return (
    (userID && creditHistoryResponse && (
      <div
        className="flex-row share-button"
        style={{
          padding: "0 0.25rem",
        }}
      >
        <ConfirmationModal
          children={
            <ShareTray asset={ch} onClose={() => setShowShare(false)} />
          }
          showModal={showShare}
          onClose={() => {
            setShowShare(false);
          }}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        />
        <LeafButton
          buttonColour={LeafButtonColour.Transparent}
          buttonStyle={LeafButtonStyle.LeafSmallDense}
          onClick={() => {
            setShowShare(true);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 500,
            }}
          >
            <div
              style={{
                marginRight: ".5rem",
                color: "var(--text-01)",
                fontSize: ".75rem",
                textTransform: "uppercase",
                height: "1.35rem",
              }}
            >
              Share Credit History
              <span style={{ color: "var(--blue-01)" }}>
                {(recipientCount || 0) > 0 && ` (${recipientCount})`}
              </span>
            </div>
          </div>
        </LeafButton>
      </div>
    )) ||
    null
  );
};
