import classnames from "classnames";
import React from "react";
import { AccountTag } from "components/features/dashboard/components/account-tag";
import { CategoryData, DELETE_DOCUMENT } from "../../../../../core/queries/documents";
import { Colour } from "core/models";
import { ConfirmationModalOld } from "components/core/modal";
import { DashboardPages } from "components/features/dashboard/pages";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { FolderPicker } from "components/features/dashboard/components/folder-picker";
import { PylonToastBody } from "components/core/pylon-toast-body";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { Text, TextFormat, TextType } from "components/core/text";
import { toast } from "react-toastify";
import { useClickOutDetector } from "core/hooks/click-out";
import { useMutation } from "@apollo/client";
import "./styles.css";

export interface Props {
  documentID?: string;
  assets: CategoryData[];

  disabled?: boolean;
  openTagsInNewTab?: boolean;
  sectionWrapperClassName?: string;
  tagsWrapperClassName?: string;
  unremovableTagID?: string;

  onAdd?: (acc: CategoryData[]) => void;
  onRemove?: (acc: CategoryData) => void;
}

export const DocumentTags: React.FC<Props> = ({
  onAdd,
  onRemove,
  disabled,
  assets,
  openTagsInNewTab,
  sectionWrapperClassName,
  tagsWrapperClassName,
  documentID,
  unremovableTagID,
}) => {
  const { popTray } = React.useContext(SideTrayContext);
  const { onUpdateRefetchQueries } = React.useContext(DataPageContext);

  const [documentToDeleteID, setDocumentToDeleteID] = React.useState<string>();
  const [showDocumentDeletionPrompt, setShowDocumentDeletionPrompt] =
    React.useState<boolean>(false);

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: () => {
      toast(
        <PylonToastBody
          title={"Document deleted"}
          body={"Document has been deleted"}
        />
      );
      popTray();
    },
    refetchQueries: onUpdateRefetchQueries,
  });

  const [edit, setEdit] = React.useState(false);
  const clickOutRef = useClickOutDetector<HTMLDivElement>(() => setEdit(false));

  const wrapperClassnames = classnames({
    "document-tags__section": true,
    [sectionWrapperClassName ?? ""]: true,
    "document-tags__space-buttons": !!documentID,
  });

  const pickerClassNames = classnames({
    "document-tags__account-pickers": true,
    "document-tags__account-pickers-inactive": !edit,
  });

  const tagsWrapperClassnames = classnames({
    "document-tags__account-tag-wrapper": true,
    [tagsWrapperClassName ?? ""]: true,
  });

  const tags = assets.map((x) => (
    <div key={x.id} className={tagsWrapperClassnames}>
      <AccountTag
        disabled={disabled || x.id === unremovableTagID}
        category={x}
        onClick={() => {
          const url = `${DashboardPages.AccountDetails}?accountID=${x.id}`;
          if (openTagsInNewTab) {
            window.open(url, "_blank");
          } else {
            document.location.href = url;
          }
        }}
        onRemove={() => onRemove?.(x)}
      />
    </div>
  ));

  const editComponent = (
    <div
      className={pickerClassNames}
      style={{
        display: edit && !disabled ? undefined : "none",
      }}
    >
      <FolderPicker
        forceMenuOpen
        disabled={disabled || true}
        excludeAccountIDs={assets.map((x) => x.id)}
        onSelect={(folders) => {
          setEdit(false);
          onAdd?.(folders);
        }}
      />
    </div>
  );

  return (
    <div>
      <div className={wrapperClassnames} ref={clickOutRef}>
        <div
          className="document-tags__add-account"
          onClick={(e) => {
            e.preventDefault();
            if (!edit) {
              setEdit(true);
            }
          }}
        >
          <div
            style={{
              cursor: "pointer",
              display: disabled ? "none" : undefined,
            }}
          >
            <Text
              type={TextType.Div}
              colour={Colour.Blue01}
              weight={700}
              size={"0.75rem"}
              format={TextFormat.UpperCase}
            >
              Add to folder
            </Text>
          </div>
          {editComponent}
        </div>
        {documentID && (
          <div className="document-tags__add-account" ref={clickOutRef}>
            <div
              onClick={(e) => {
                e.preventDefault();
                setDocumentToDeleteID(documentID);
                setShowDocumentDeletionPrompt(true);
              }}
              style={{
                cursor: "pointer",
                display: disabled ? "none" : undefined,
              }}
            >
              <Text
                type={TextType.Div}
                colour={Colour.Blue01}
                weight={700}
                size={"0.75rem"}
                format={TextFormat.UpperCase}
              >
                Delete Document
              </Text>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {tags}
        </div>
      </div>
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showDocumentDeletionPrompt}
        children={
          <div>
            Are you sure you want to delete this document?
            <br /> <br />
          </div>
        }
        onAction={() => {
          deleteDocument({
            variables: {
              id: documentToDeleteID,
            },
          });
          setDocumentToDeleteID("");
          setShowDocumentDeletionPrompt(false);
        }}
        actionButtonText="Delete"
        onCancel={() => {
          setShowDocumentDeletionPrompt(false);
        }}
        cancelButtonText="Cancel"
      />
    </div>
  );
};
