import * as React from "react";
import { Colour } from "core/models";
import { Text, TextStyle, TextType } from "components/core/text";
import "./styles.css";

export enum DropDownMenuItemType {
  AccountAdd = "drop-down-menu-item__account_add",
  Padded = "drop-down-menu-item__padded",
  FixedMinHeight = "drop-down-menu-item__fixed-min-height",
}

interface Props {
  text: JSX.Element | string | React.ReactElement;
  borderBottom?: boolean;
  type?: DropDownMenuItemType;
  onClick: () => void;
}

export const DropDownMenuItem: React.FC<Props> = ({
  text,
  onClick,
  type = DropDownMenuItemType.FixedMinHeight,
  borderBottom,
}) => {
  const renderAccountAddText = () => {
    return (
      <Text
        type={TextType.Div}
        colour={"#757575" as Colour}
        size={"0.688rem"}
        lineHeight="1rem"
      >
        {text}
      </Text>
    );
  };

  const renderRegularText = () => {
    return (
      <Text type={TextType.Div} colour={Colour.Black} style={TextStyle.M15}>
        {text}
      </Text>
    );
  };

  return (
    <div
      data-selector={`drop-down-menu-item ${type}`}
      className={`drop-down-menu-item ${type}`}
      onClick={onClick}
      style={{
        borderBottom: borderBottom ? "1px solid #EEEEEE" : "",
      }}
    >
      {type === DropDownMenuItemType.AccountAdd
        ? renderAccountAddText()
        : renderRegularText()}
    </div>
  );
};
