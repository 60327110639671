import * as React from "react";
import "./privacy.css";

export interface PublicProps {}

type Props = PublicProps;
export const Privacy: React.FC<Props> = (props) => {
  return (
    <div className="privacy dashboard-content-inner">
      <p className="c18">
        <span className="c24">PRIVACY POLICY</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          This Privacy Policy describes how Pylon, Inc. collects and uses your
          personal data that you provide in connection with products and
          services Pylon provides, including the Pylon website at
          www.hellopylon.com (the “Site”) and the Pylon App when released. In
          this Privacy Policy, the above are referred to collectively as the
          “Services”. This policy describes the choices available to you
          regarding our use of your personal data and how you can access and
          update this data. Services include software and services that we own
          and operate directly, as well as software and services that we market
          or provide from or on behalf of third parties.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c16">
          If you have any questions regarding this Privacy Policy, please
          contact{" "}
        </span>
        <span className="c15 c8">support@hellopylon.com</span>
        <span className="c16 c20">. </span>
        <span className="c0">
          By using any of the Services you agree to be bound by this policy. If
          you do not agree to the terms of this Privacy Policy, please do not
          provide us with any personal information and do not use the Services.{" "}
        </span>
      </p>
      <p className="c2">
        <span className="c0"> </span>
      </p>
      <p className="c13">
        <span className="c0">
          The Services are intended for a general audience. We do not knowingly
          collect personal information from children under the age of 13. We
          will delete any personal information collected that we later determine
          to be from a user younger than the age of 13. If you are a parent or
          guardian of a child under the age of 13 and you believe he or she has
          disclosed personal information to us, please contact Customer Service
          using the contact information below.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          The Services are provided to users from the United States. If you are
          from outside the United States and use the Services, please be aware
          that the data protection laws in the United States may differ from
          those of the country where you are located, and your personal
          information may be subject to access requests from governments,
          courts, or law enforcement in the United States according to the laws
          of the United States. In addition, such data may be stored on servers
          located outside your resident jurisdiction and in jurisdictions that
          may have less stringent privacy practices than your own. By using the
          Services or providing us with any information, you consent to the
          transfer to, and processing, usage, sharing, and storage of your
          information, including personal information, in the United States as
          set forth in this Privacy Policy.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          If you are a California resident, you have certain rights as provided
          in the California Consumer Privacy Act (“CCPA”). &nbsp;For the
          CCPA-specific provisions of this Privacy Policy, please see Section
          VII below.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0 start" start={1}>
        <li className="c11 li-bullet-0">
          <span className="c12">INFORMATION COLLECTED ON THE SITE</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Pylon may obtain various types of personal data about users of the
          Services and non-customer Site visitors. This data may include:
        </span>
      </p>
      <p className="c2 c17">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_2-0 start">
        <li className="c13 c14 li-bullet-0">
          <span className="c0">
            Contact information (name, address, phone number, and email
            address);
          </span>
        </li>
      </ul>
      <p className="c2 c17">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_2-0">
        <li className="c13 c14 li-bullet-0">
          <span className="c0">
            Information about products ordered or provided;
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_2-0">
        <li className="c4">
          <span className="c0">
            Information collected through Internet-based and e-commerce
            activities such as information obtained from log files, cookies,
            clear gifs (
          </span>
          <span className="c16 c19">i.e.</span>
          <span className="c0">, web bugs), images, and scripts;</span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_2-0">
        <li className="c4">
          <span className="c0">
            Customer service and demographic data such as gender, income level,
            and information about your prior experiences with Pylon’s products.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_2-0">
        <li className="c4">
          <span className="c0">
            Information collected from our third-party service providers; and
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_2-0">
        <li className="c4">
          <span className="c0">Other transaction-related data.</span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          We also may use certain tracking technologies described below to
          collect, store, or accumulate certain non-personally identifiable
          information concerning your use of this Site, including your browsing
          history, preferences, internet addresses, unique identifiers, device
          types, device location, and other similar information.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={2}>
        <li className="c11 li-bullet-0">
          <span className="c12">
            INFORMATION COLLECTED THROUGH SOFTWARE AND SERVICES
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          When providing the Services we may also collect the following
          information about your computer or other device, including:
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_3-0 start">
        <li className="c4">
          <span className="c0">
            Device information, such as the operating system version, processor
            type, disk space, memory space, and number of drivers;
          </span>
        </li>
      </ul>
      <p className="c2 c22">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_3-0">
        <li className="c4">
          <span className="c0">
            Information about software on your computer, including the version
            of certain software programs and when they were installed and/or
            upgraded; and
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_3-0">
        <li className="c4">
          <span className="c0">
            Information collected from logs, configuration files, registry keys,
            and other system or software-level information.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          The data collected under this Section II is generally anonymous and
          aggregated. However, if Pylon needs to have further information to
          diagnose certain kinds of problems or to monitor your computer for
          certain events, Pylon will ask you for permission to collect this
          additional information. You may authorize or revoke any authorization
          to identify you during software installation, or by using the control
          panel in the particular Product.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          As part of accessing the Services, you may be asked to provide log-in
          or similar information so that Pylon can aggregate data from multiple
          locations and repositories to display to you within the Services. We
          work with third parties to enable the transfer of that information to
          the holders of that data, and your provision of information to those
          third parties is in accordance with the privacy policies of those
          third parties. Links to those privacy policies are available for your
          review prior to the provision of any information, and we advise you to
          consult them directly. Pylon does not maintain, store, or otherwise
          use in any fashion the information you provide via such third parties,
          except as necessary to provide the Services. Pylon never has access to
          log-in or password information for third parties.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={3}>
        <li className="c11 li-bullet-0">
          <span className="c12">
            RETAINING, STORING, AND ACCESSING YOUR PERSONAL DATA
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          We will retain your information for as long as your account is active
          and as needed to provide the Services. We will retain and use your
          information as reasonably necessary to conduct our business, comply
          with our legal obligations, resolve disputes, and enforce our
          agreements.
        </span>
      </p>
      <p className="c2">
        <span className="c12"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={4}>
        <li className="c11 li-bullet-0">
          <span className="c12">INFORMATION USAGE</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          You hereby agree that Pylon may use the personal data you submit in
          order to:
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_5-0 start">
        <li className="c4">
          <span className="c0">
            Accomplish its business purposes, including to deliver or provide
            the Services, establish or maintain customer and business
            relationships, improve the Services, provide access to
            Internet-based and e-commerce activities, perform accounting
            functions, and conduct other activities as necessary or appropriate
            in connection with the Services.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_5-0">
        <li className="c4">
          <span className="c0">
            Market its products and services to you, and the products and
            services of its third-party partners, including to send you
            information about products, services, or promotions.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_5-0">
        <li className="c4">
          <span className="c0">
            Send you service updates, announcements, and alerts related to the
            Services, including notices of security incidents, downtime, or
            planned maintenance. You may not opt-out of these service
            communications. If you do not wish to receive them, you have the
            option of deactivating your account and ceasing your use of the
            Services.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          In addition, Pylon may sell, transfer, or disclose personal data to
          our service providers under written contracts as needed for such
          service providers to perform services on our behalf, including to
          fulfill our obligations to you. Service providers and business
          partners that are provided information under this section will not use
          personal data for any other purposes. If Pylon has knowledge that a
          Pylon business partner or service provider is processing your personal
          data in a way that is contrary to this Privacy Policy, Pylon will take
          reasonable steps to prevent or stop such processing. In such case, you
          agree that Pylon will not be held liable for the misuse of your data.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Pylon may sell, transfer, or disclose personal data, including your
          contact information, to its affiliates, licensees, partners, and other
          third parties as appropriate in accordance with the terms of written
          agreements for legitimate business or marketing purposes.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Pylon will, in its sole discretion, disclose your personal data to
          third parties in certain additional limited cases: &nbsp;(1) when we
          have reason to believe that disclosing this information is necessary
          to identify, contact, or bring legal action against someone who may be
          causing injury to or interference with the rights of Pylon or its
          customers and vendors; (2) when we believe in good faith that the law
          requires us to do so; (3) to a subsequent owner, co-owner, or operator
          of a Site or applicable database or in connection with a merger,
          consolidation, or restructuring, or the sale of substantially all of
          our interests and/or assets, or other corporate change, including
          during the course of any due diligence process; and (4) in situations
          involving threats to the physical safety of any person.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={5}>
        <li className="c11 li-bullet-0">
          <span className="c12">INFORMATION SECURITY</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Your data is of the utmost importance to us. We use Secure Sockets
          Layer (SSL) and other transmission technologies to encrypt your
          personal data so it cannot be read in transit. In addition, we use
          secure technology, privacy protection controls, and restrictions on
          employee access to safeguard your personal information in storage. As
          noted in Section II above, the provision of information to third
          parties as part of the Services is governed by those third parties’
          privacy policies and we do not guarantee the security of information
          you transmit to them.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Please note that although we employ industry-standard security
          measures to safeguard the security of your personal information, no
          transmissions made on or through the Internet are guaranteed to be
          secure. Therefore, we cannot ensure the absolute security of any
          information you transmit to us, and you use our Site and provide us
          with your information at your own risk.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={6}>
        <li className="c11 li-bullet-0">
          <span className="c12">TRACKING TECHNOLOGIES</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Because of the manner in which we offer our Services, we cannot
          provide them to customers who do not permit our use of cookies or
          whose browsers are set to reject cookies.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          We and our marketing partners, affiliates, or analytics or service
          providers use technologies such as cookies, beacons, tags, browser and
          device fingerprinting, network traffic monitoring, active scripting,
          recognition technologies, and scripts, to analyze trends, administer
          the Site, track users’ movements around the Site, and to gather
          demographic information about our user base as a whole. We may receive
          reports based on the use of these technologies by these companies on
          an individual and aggregated basis.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          We may not control those tracking technologies and are not responsible
          for them. However, you consent to potentially encountering third-party
          tracking technologies in connection with use of our Site and accept
          that our statements under this Privacy Policy do not apply to the
          tracking technologies or practices of such third parties.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          There may be other tracking technologies now and later devised and
          used by us in connection with the Site. Further, third parties may use
          tracking technologies in connection with our Site, which may include
          the collection and storing of information about your online activities
          over time and across third-party websites or online services.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          We may partner with one or more third parties to either display
          advertising on the Site or to manage our advertising on other sites.
          Our third-party partner(s) may use tracking technologies to gather
          information about your activities on the Sites and other websites to
          provide you targeted advertising based upon your browsing activities
          and interests. If you wish not to have this information used for the
          purpose or serving you targeted ads, you may be able to opt-out by
          visiting: &nbsp;http:/www.networkadvertising.org/managing/opt-out
          and/or http:/www.aboutads.info/choices.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Statements regarding our practices do not apply to the methods for
          collecting information used by these third parties or the use of the
          information that such third parties collect. We do however work with
          third parties to make efforts to have you provided with information on
          their practices and any available opportunity to exercise choice. We
          make no representations regarding the policies or practices of third
          party advertisers or advertising networks or exchanges or related
          third parties.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={7}>
        <li className="c11 li-bullet-0">
          <span className="c12">OTHER SERVICES</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          If you click on a link to a third-party site, you will leave the Site
          you are visiting and be redirected to the site you selected. Because
          we cannot control the activities of third parties, we do not accept
          responsibility for any use of your personal data by such third
          parties, and we do not guarantee that they will adhere to the same
          privacy policies as Pylon. We encourage you to review the privacy
          policies of any other service provider from whom you request services.
          If you visit a third-party site that is linked to a Site, you should
          read that third-party site’s privacy statement before providing any
          personal data.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c16">
          If you use a forum on any of the Sites, you should be aware that any
          personal data you submit there can be read, collected, or used by
          other users of these forums, and could be used to send you unsolicited
          messages. We are not responsible for the personal data you choose to
          submit in these forums. You may remove your post along with your
          personal data you submit within these forums by contacting us at{" "}
        </span>
        <span className="c15 c8">support@hellopylon.com</span>
        <span className="c0">.</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          Our Site(s) may include social media features, such as the “Share
          This” button that allows you to like us on Facebook or share our
          content on Twitter. These features may collect your IP address and
          which page you are visiting on our Sites, and they may set a cookie to
          enable the feature to function properly. Social media features are
          either hosted by a third party or hosted directly on our Sites. Your
          interactions with these features are governed by the privacy statement
          of the applicable company.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={8}>
        <li className="c11 li-bullet-0">
          <span className="c12">CALIFORNIA CONSUMER PRIVACY ACT</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_7-1 start" start={1}>
        <li className="c21 li-bullet-0">
          <span className="c12">
            Categories of Personal Information We Collect
          </span>
        </li>
      </ol>
      <p className="c5 c9 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Along with the information in the Privacy Policy as a whole, the CCPA
          requires us to describe the information we collect in certain
          “categories.” &nbsp;The categories we collect now or in the future may
          include:
        </span>
      </p>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_8-0 start">
        <li className="c4 c6">
          <span className="c0">
            Identifiers (such as real name, alias, postal address, unique
            personal identifier, online identifier, internet protocol address,
            email address, account name, or other similar identifiers);
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_8-0">
        <li className="c5 c14 li-bullet-0">
          <span className="c0">
            Commercial information (such as transaction data);
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_8-0">
        <li className="c4 c6">
          <span className="c0">
            Internet or other network or device activity (such as browsing
            history and information on interaction with a website, application,
            or advertisement);
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_8-0">
        <li className="c5 c14 li-bullet-0">
          <span className="c0">
            Geolocation data (at an IP-based or city/state/zip level);
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_8-0">
        <li className="c4 c6">
          <span className="c0">
            Inference data about you drawn from the data in this section (based
            on user profile information);
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_8-0">
        <li className="c5 c14 li-bullet-0">
          <span className="c0">
            Legally protected classNameifications (such as age and gender);
          </span>
        </li>
      </ul>
      <p className="c5 c7 c17">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_8-0">
        <li className="c4 c6">
          <span className="c0">
            Other information that identifies or can be reasonably associated
            with you (e.g., information you voluntarily provide as you use the
            Services, such as additional profile information or user-generated
            content).
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_7-1" start={2}>
        <li className="c21 li-bullet-0">
          <span className="c12">
            The Sources From Which the Categories of Personal Information Are
            Collected
          </span>
        </li>
      </ol>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          We collect personal data directly from information you provide to us;
          indirectly from our affiliates, service providers, and other vendors;
          and indirectly through your interactions with websites or
          advertisements.
        </span>
      </p>
      <p className="c5 c7 c9">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_7-1" start={3}>
        <li className="c21 li-bullet-0">
          <span className="c12">
            Business Purposes for Our Collection of Personal Information
          </span>
        </li>
      </ol>
      <p className="c5 c9 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c16">
          The following is a list of the business purposes for our collection of
          information
        </span>
        <span className="c0">.</span>
      </p>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0 start">
        <li className="c4 c6">
          <span className="c0">
            Provide the Services you have requested, including invoicing and
            accounting;
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c4 c6">
          <span className="c0">
            Respond to your requests for information and provide you with more
            effective and efficient customer service;
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c5 c14 li-bullet-0">
          <span className="c0">
            Provide you with updates and information about the Services;
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c4 c6">
          <span className="c0">
            Contact you by email, postal mail, or phone regarding our Services
            and third- party products, services, surveys, research studies,
            promotions, special events and other subjects that we think may be
            of interest to you;
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c5 c14 li-bullet-0">
          <span className="c0">
            Customize the advertising and content you see via the Services;
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c4 c6">
          <span className="c0">
            Help us better understand your interests and needs, and improve the
            Services, including through research and reports, and test and
            create new products, features, and services;
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c4 c6">
          <span className="c0">
            Secure our websites and applications, and resolve technical issues
            being reported;
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c4 c6">
          <span className="c0">
            Comply with any procedures, laws, and regulations which apply to us
            where it is necessary for our legitimate interests or the legitimate
            interests of others; and
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_9-0">
        <li className="c4 c6">
          <span className="c0">
            Establish, exercise, or defend our legal rights where it is
            necessary for our legitimate interests or the legitimate interests
            of others.
          </span>
        </li>
      </ul>
      <p className="c5 c9 c7">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_7-1" start={4}>
        <li className="c21 li-bullet-0">
          <span className="c12">
            Sharing of Categories of Personal Information
          </span>
        </li>
      </ol>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          We may share categories of personal information to accomplish the
          various business purposes above and as described throughout this
          Privacy Policy.
        </span>
      </p>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_7-1" start={5}>
        <li className="c21 li-bullet-0">
          <span className="c12">CCPA Rights Disclosure</span>
        </li>
      </ol>
      <p className="c7 c10">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          If you are a California resident, the CCPA allows you to make certain
          requests about your personal information. Specifically, the CCPA
          allows you to request us to:
        </span>
      </p>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_10-0 start">
        <li className="c4 c6">
          <span className="c0">
            Inform you about the categories of personal information we collect
            or disclose about you and the categories of sources of such
            information;
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_10-0">
        <li className="c4 c6">
          <span className="c0">
            Inform you about the business or commercial purpose for collecting
            personal information and the categories of third parties with whom
            we share/disclose personal information (which is also disclosed in
            this Privacy Policy);
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_10-0">
        <li className="c4 c6">
          <span className="c0">
            Provide you with the specific pieces of personal information we
            collect about you; and
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ul className="c3 lst-kix_list_10-0">
        <li className="c5 c14 li-bullet-0">
          <span className="c0">
            Delete personal information we have about you.
          </span>
        </li>
      </ul>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          Please note that certain information may be exempt from such requests
          under California law. For example, we need certain information in
          order to provide the Services to you. You may only make such a request
          twice within a twelve-month period. Requests are generally free;
          however, we may charge a reasonable fee or deny your request if it is
          manifestly unfounded, excessive, or repetitive.
        </span>
      </p>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c16 c20">
          To make a CCPA request,&nbsp;please contact us at{" "}
        </span>
        <span className="c15 c8">support@hellopylon.com</span>
        <span className="c0">
          . We will request information, which at a minimum will include your
          name and email address, to verify your identity. We may request
          additional information to verify your identity before responding to a
          request. Under the CCPA, you may have an authorized agent submit a
          request on your behalf, and we will collect certain authorization and
          verification information from the agent and you in such circumstances.
        </span>
      </p>
      <p className="c5 c9 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c0">
          The CCPA further provides you with the right to receive information
          about the financial incentives that we offer to you, if any, and the
          right not to be discriminated against for exercising your rights under
          applicable law.
        </span>
      </p>
      <p className="c5 c9 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c16 c20">
          If you would like further information regarding your legal rights
          under California law, please contact us at{" "}
        </span>
        <span className="c15 c8">support@hellopylon.com</span>
        <span className="c0">.</span>
      </p>
      <p className="c5 c7">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_7-1" start={6}>
        <li className="c21 li-bullet-0">
          <span className="c12">
            California Do Not Sell Notice and Information Request
          </span>
        </li>
      </ol>
      <p className="c5 c9 c7">
        <span className="c0"></span>
      </p>
      <p className="c5">
        <span className="c16 c20">
          If you are a California resident, the CCPA provides you with the right
          to opt out of the "sale" of your "personal information." We may allow
          some of our advertising partners to collect certain pieces of
          information from our site visitors, such as device identifiers,
          cookies, advertising IDs, IP addresses, and usage activity, so that we
          and our partners can deliver ads that are more relevant to you. This
          type of information sharing with our advertising partners may be
          considered a "sale" under the CCPA. If you want to opt out of these
          activities, please contact us at{" "}
        </span>
        <span className="c15 c8">support@hellopylon.com</span>
        <span className="c0">
          . Please note that some or all of the Services may not function if you
          choose to opt out of such activities.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c16">
          If you are a California resident, California Civil Code Section
          1798.83 allows you to request and obtain from us once a year, free of
          charge, information about the personal information (if any) we
          disclosed to third parties for the third parties’ direct marketing
          purposes in the preceding calendar year. If applicable, this
          information would include a list of the categories of personal
          information that was shared and the names and addresses of all third
          parties with which we shared information in the immediately preceding
          twelve calendar months. If you are a California resident and would
          like to make such a request, please submit your request in writing to
          our privacy inbox at{" "}
        </span>
        <span className="c15 c8">support@hellopylon.com</span>
        <span className="c16 c20">.</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <ol className="c3 lst-kix_list_1-0" start={9}>
        <li className="c11 li-bullet-0">
          <span className="c12">MISCELLANEOUS</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c16">
          You can access, update or correct your information, or otherwise ask
          us questions about this Privacy Policy by contacting us at{" "}
        </span>
        <span className="c15 c8">support@hellopylon.com</span>
        <span className="c16 c20">.</span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c13">
        <span className="c0">
          We may update this Privacy Policy from time to time to reflect changes
          to our information practices. When we make changes to this Privacy
          Policy, we will revise the “Posted” and “Effective” dates at the
          beginning of this Privacy Policy. If the changes to the Privacy Policy
          are material, we will notify you a notice on the Site or via email if
          we have your email address on file.
        </span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
      <p className="c2">
        <span className="c0"></span>
      </p>
    </div>
  );
};
