import * as React from "react";
import { Checkbox } from "components/core/checkbox";
import { Text, TextStyle } from "components/core/text";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";

interface Props {
  onNext: () => void;
}
export const ArrayLegalAck: React.FC<Props> = ({ onNext }) => {
  const [checked, setChecked] = React.useState(false);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Pylon partners with Array to pull your credit score.</Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "0 3rem" }}>
          <Text style={TextStyle.Hint} textAlign={"left"}>
            The credit score is a soft pull that does not affect your credit
            score.
          </Text>
          <Text style={TextStyle.Hint} textAlign={"left"}>
            You understand that by checking this box and clicking on the ‘I
            Accept’ button below, you agree to the terms and conditions,
            acknowledge receipt of our privacy policy and you are providing
            ‘written instructions’ under the FCRA to Pylon authorizing Pylon to
            obtain information from your personal credit profile from each
            credit reporting agency. You authorize Pylon to obtain such
            information solely to confirm your identity and display your credit
            data to you.
          </Text>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem 0",
        }}
      >
        <div style={{ margin: "0 1rem" }}>
          <Checkbox
            id="array-credit-accept"
            style={{
              width: "1rem",
              marginRight: "1rem",
            }}
            label={""}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </div>
        <LeafButton
          disabled={!checked}
          buttonColour={LeafButtonColour.Black}
          buttonStyle={LeafButtonStyle.LeafSmallDense}
          onClick={() => onNext()}
        >
          I Accept
        </LeafButton>
      </div>
    </div>
  );
};
