import * as React from "react";
import { ActivityLog } from "components/features/dashboard/components/activity-log";
import {
  ApolloError,
  gql,
  useMutation,
  useQuery
  } from "@apollo/client";
import { AssetNotes } from "components/features/dashboard/components/asset-notes";
import { AssetType } from "components/features/dashboard/models/sharing";
import { Colour } from "core/models";
import { DocumentData } from "components/features/dashboard/models/document";
import { DocumentTags } from "components/features/dashboard/components/document-tags";
import { DownloadDocumentButton } from "components/features/dashboard/components/document-download-button";
import { millisecondsReadable } from "core/utils/format";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { recipientsForAssetQuery } from "core/queries/collaborations";
import { SessionContext } from "components/contexts/session-context-provider";
import { ShareWidgetMini } from "components/features/dashboard/components/share-widget-mini";
import { Spinner } from "react-bootstrap";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { toast } from "react-toastify";
import { ViewDocumentButton } from "components/features/dashboard/components/view-document-button";
import { ViewDocumentTray } from "components/features/dashboard/components/view-document-tray";
import "./styles.css";
import {
  DetailsNav,
  DetailsTabs,
} from "components/features/dashboard/components/details-nav";
import {
  CategoryData,
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_CATEGORIES,
} from "core/queries/documents";

interface PublicProps {
  document: DocumentData;
}

interface GetDocumentResponse {
  document: DocumentData;
}

const ADD_DOCUMENT_TAG = gql`
  mutation ($documentID: ID!, $categoryData: DocumentCategoryInput!) {
    addDocumentTag(documentID: $documentID, categoryData: $categoryData) {
      document {
        id
      }
    }
  }
`;

const REMOVE_DOCUMENT_TAG = gql`
  mutation ($documentID: ID!, $categoryData: DocumentCategoryInput!) {
    removeDocumentTag(documentID: $documentID, categoryData: $categoryData) {
      document {
        id
        categories {
          id
          type
          name
        }
      }
    }
  }
`;

const UPDATE_DOCUMENT_METADATA = gql`
  mutation (
    $id: ID!
    $newName: String
    $newSecondaryAssetIDs: [String!]
    $notes: String
  ) {
    editDocument(
      id: $id
      newName: $newName
      newSecondaryAssetIDs: $newSecondaryAssetIDs
      notes: $notes
    ) {
      document {
        id
        name
        categories {
          id
          type
          name
        }
        notes
      }
    }
  }
`;

interface UpdateDocumentMetadata {
  id: string;
  newName?: string;
  newSecondaryAssetIDs?: string[];
}

export const ManageDocumentTray: React.FC<PublicProps> = ({
  document: documentInput,
}) => {
  const { userID } = React.useContext(SessionContext.context);
  const { data, loading: dataLoading } = useQuery<GetDocumentResponse>(
    FETCH_DOCUMENT,
    {
      variables: {
        id: documentInput.id,
      },
    }
  );

  const errHandler: (error: ApolloError) => void = (err) => {
    toast(
      <PylonToastBody
        title={"Unexpected Error Occurred"}
        body={`${err.message}`}
        type={ToastType.Error}
      />
    );
  };

  const [updateMetadata, { loading: editLoading }] =
    useMutation<UpdateDocumentMetadata>(UPDATE_DOCUMENT_METADATA, {
      onError: errHandler,
      refetchQueries: [
        {
          query: FETCH_DOCUMENT_CATEGORIES,
        },
      ],
    });

  const [addTagMutation, { loading: addTagLoading }] = useMutation(
    ADD_DOCUMENT_TAG,
    {
      onError: errHandler,
      refetchQueries: [
        {
          query: recipientsForAssetQuery,
          variables: {
            assetID: documentInput.id,
            assetType: AssetType.Document,
          },
        },
        { query: FETCH_DOCUMENT_CATEGORIES },
      ],
    }
  );

  const [removeTagMutation, { loading: removeTagLoading }] = useMutation(
    REMOVE_DOCUMENT_TAG,
    {
      onError: errHandler,
    }
  );

  const doc = data?.document ?? documentInput;

  let assets: CategoryData[] = [];
  doc.categories?.forEach((x) => assets.push(x));

  const name = React.useRef(doc.name);

  const isSharedWithMe = doc.owner?.id !== userID;
  const updateLoading = editLoading || addTagLoading || removeTagLoading;
  const loading = dataLoading || updateLoading;

  const { isOwnerOrEditor } = doc;
  const { isOwner } = doc;
  const { isEditor } = doc;

  if (!isOwnerOrEditor) {
    // View document loads the same document again and triggers react warning about memory leak
    // Force the compoennt to skip data loading if it's been redirected
    return <ViewDocumentTray document={doc} skipDataLoading={true} />;
  }

  return (
    <div>
      <div className="manage-account-tray__content">
        <div className="manage-account-tray__info-row">
          <div className="manage-account-tray__info-subrow">
            <div className="manage-account-tray__info-subrow-bold-text">
              <PageTitle
                title={name.current}
                noBlueDot={true}
                editable={true}
                onEdit={(t) => {
                  updateMetadata({
                    variables: {
                      id: doc.id,
                      newName: t,
                    },
                  });
                }}
              />
              <span className="text-highlight-blue">＿</span>
            </div>
          </div>
          <div className="manage-account-tray__info-subrow">
            <div
              style={{
                color: "var(--gray-70)",
                display: "flex",
                width: "100%",
                height: "1rem",
                justifyContent: "space-between",
              }}
            >
              {/* <Text type={TextType.Div} style={TextStyle.Default} weight={400}>
                {doc.filename}
              </Text> */}
              <Text
                type={TextType.Div}
                colour={Colour.Navy}
                weight={700}
                size={"0.75rem"}
                format={TextFormat.UpperCase}
              >
                {`Last updated ${millisecondsReadable(
                  new Date().getTime() - new Date(doc.modifiedAt).getTime()
                )} ago`}
              </Text>
              {updateLoading && (
                <div>
                  <Text type={TextType.Span} style={TextStyle.Hint}>
                    Saving... <Spinner animation="border" size="sm" />
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "1rem", display: "flex" }}>
          <ViewDocumentButton document={doc} />
          <DownloadDocumentButton document={doc} asIcon={true} />
        </div>

        <DocumentTags
          documentID={doc.id}
          assets={assets}
          sectionWrapperClassName={"manage-account-tray__tag-section"}
          tagsWrapperClassName={"manage-document-tray__tag-wrapper"}
          onAdd={(folders) => {
            folders.forEach((x: CategoryData) =>
              addTagMutation({
                variables: {
                  documentID: doc.id,
                  categoryData: {
                    id: x.id,
                    type: x.type,
                  },
                },
              })
            );
          }}
          onRemove={(acc) =>
            removeTagMutation({
              variables: {
                documentID: doc.id,
                categoryData: {
                  id: acc.id,
                  type: acc.type,
                },
              },
            })
          }
          disabled={loading}
        />
        <div className="manage-account-tray__properties"></div>
      </div>

      <>
        {!isSharedWithMe && <ShareWidgetMini asset={doc} />}
        <div
          style={{
            paddingTop: "1rem",
            borderBottom: "1px solid var(--gray-10)",
          }}
        />
        <div>
          <Text
            weight={700}
            size={"0.75rem"}
            colour={Colour.Navy}
            format={TextFormat.UpperCase}
          >
            Document Notes
          </Text>
          <AssetNotes
            assetID={doc.id}
            assetType={AssetType.Document}
            owner={isOwner || false}
            editor={isEditor || false}
          />
        </div>

        <div
          style={{
            paddingTop: "1rem",
            borderBottom: "1px solid var(--gray-10)",
          }}
        />
      </>
      <ActivityLog assetID={doc.id} assetType={AssetType.Document} />
      <DetailsNav
        selected={DetailsTabs.Overview}
        onSelect={(e) => console.debug(e)}
      />
    </div>
  );
};
