import React from "react";
import { Badge, Overlay, Tooltip } from "react-bootstrap";
import { CategoryData } from "../../../../../core/queries/documents";
import { DocumentCategoryType } from "../../../../../core/models/documents";
import { Icon, IconAsset } from "components/core/icons";
import "./styles.css";
import {
  AccountType,
  getColorForType,
} from "components/features/dashboard/components/add-account-tray/models";

export interface AccountTagProps {
  category: CategoryData;

  disabled?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}

export const AccountTag: React.FC<AccountTagProps> = ({
  disabled,
  category,
  onClick,
  onRemove,
}) => {
  const [showTagTooltip, setShowTagTooltip] = React.useState(false);
  const [showRemoveTooltip, setShowRemoveTooltip] = React.useState(false);
  const tagTarget = React.useRef(null);
  const removeTarget = React.useRef(null);

  return (
    <div
      className={"account-tag"}
      style={{
        background: getColorForType(category.type as AccountType).Light,
      }}
    >
      <Badge
        className={"account-tag__account"}
        ref={tagTarget}
        onMouseEnter={() => {
          setShowTagTooltip(true);
        }}
        onMouseLeave={() => setShowTagTooltip(false)}
        onClick={
          category.type === DocumentCategoryType.Account ? onClick : () => {}
        }
      >
        {category.name}
      </Badge>
      <Overlay target={tagTarget.current} show={showTagTooltip} placement="top">
        {(props) => (
          <Tooltip id="a" {...props}>
            {category.name}
          </Tooltip>
        )}
      </Overlay>
      {onRemove && !disabled && (
        <>
          <div
            ref={removeTarget}
            className={"account-tag__remove"}
            onMouseEnter={() => {
              setShowRemoveTooltip(true);
            }}
            onMouseLeave={() => setShowRemoveTooltip(false)}
            onClick={() => {
              if (!disabled) {
                onRemove();
              }
            }}
          >
            <Icon asset={IconAsset.SmallCross} height={"6px"} width={"6px"} />
          </div>
          <Overlay
            target={removeTarget.current}
            show={showRemoveTooltip}
            placement="top"
          >
            {(props) => (
              <Tooltip id="a" {...props}>
                Click to remove the association
              </Tooltip>
            )}
          </Overlay>
        </>
      )}
    </div>
  );
};
