import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { ConfirmationModalOld } from "components/core/modal";
import { expenseTransactionsFilter } from "components/features/dashboard/components/chart-expenses";
import { Icon, IconAsset } from "components/core/icons";
import { Spinner } from "react-bootstrap";
import { toMoneyString } from "core/utils";
import { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  BudgetGoal,
  DeleteBudgetGoalMutationInput,
  DELETE_BUDGET_GOAL_MUTATION,
  GET_BUDGET_GOAL_QUERY,
} from "core/queries/budget-goal";

export interface Props {
  goal: BudgetGoal;
}

export const BudgetGoalRow: React.FC<Props> = ({ goal }) => {
  const { transactionData } = useContext(AccountTransactionContext);
  const [showModal, setShowModal] = useState(false);

  const [deleteBudgetMutation, { loading: deleteLoading }] = useMutation<
    {},
    DeleteBudgetGoalMutationInput
  >(DELETE_BUDGET_GOAL_MUTATION, {
    refetchQueries: [
      {
        query: GET_BUDGET_GOAL_QUERY,
      },
    ],
  });

  const tx = transactionData?.accountTransactions?.transactions ?? [];
  const spending = tx
    .filter((tx) => !tx.pending)
    .filter(expenseTransactionsFilter)
    .filter((c) => {
      return c.category.toLowerCase().startsWith(goal.category.toLowerCase());
    })
    .map((tx) => {
      return tx.amount;
    })
    .reduce((a, b) => {
      return a + b;
    }, 0);

  const onConfirm = () => {
    deleteBudgetMutation({
      variables: {
        id: goal.id,
      },
    });
  };

  const parsedGoal = Number.parseFloat(goal.budget);
  const overSpend = parsedGoal > spending;

  return (
    <div className="table-row">
      <ConfirmationModalOld
        width={"22rem"}
        showModal={showModal}
        onAction={() => {
          onConfirm();
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
        actionButtonText="Delete"
      >
        Are you sure you want to delete goal for {goal.category}?
        <br />
        <br />
      </ConfirmationModalOld>

      <div className="table-cell category-column">{goal.category}</div>
      <div className="table-cell filler-columnvno-mobile" />
      <div className="table-cell budget-column">
        {toMoneyString(parsedGoal)}
      </div>
      <div
        className="table-cell spend-column mobile-last"
        style={{
          color: overSpend ? "var(--green-01)" : "var(--red-01)",
        }}
      >
        {toMoneyString(spending)}
      </div>
      <div className="table-cell delete-column no-mobile">
        <div
          onClick={() => {
            setShowModal(true);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          {deleteLoading ? (
            <Spinner size="sm" animation="border" />
          ) : (
            <Icon asset={IconAsset.Trashcan} width="24px" height="24px" />
          )}
        </div>
      </div>
    </div>
  );
};
