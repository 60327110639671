import * as React from "react";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { DashboardPages } from "components/features/dashboard/pages";
import { Dropdown } from "react-bootstrap";
import { GlobalFilteringContext } from "components/contexts/global-filtering-context";
import { Link } from "react-router-dom";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import { RecipientDropdownItem } from "../../components/row-card-recipients/recipients-dropdown-item";
import { SessionContext } from "components/contexts/session-context-provider";
import { useQuery } from "@apollo/client";
import { UserData } from "../../models/sharing";
import "./styles.css";
import {
  Collaboration,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";

export enum CollaborationDirection {
  Outoing = "Outoing",
  Incoming = "Incoming",
  My = "My", // Outgoing or not shared
  All = "All",
}

interface Props {
  onClick?: (collaborator?: UserData, type?: CollaborationDirection) => void;
  initialFilteringDirection?: CollaborationDirection;
  includeMyAccounts?: boolean;
}

export const CollaboratorFilter: React.FC<Props> = ({
  onClick,
  initialFilteringDirection,
  includeMyAccounts = false,
}) => {
  const userIDMap: { [key: string]: UserData } = {};
  const getFirstUserName = (newOwnerIDs: string[]) => {
    if (newOwnerIDs.indexOf(userID) >= 0) {
      return "Me";
    }
    return userIDMap[newOwnerIDs[0]]?.firstName || "";
  };

  const getSecondUserName = (newOwnerIDs: string[]) => {
    if (
      newOwnerIDs.filter((id) => {
        return id === userID || userIDMap != null;
      }).length > 2
    ) {
      return ` + ${newOwnerIDs.length - 1} others`;
    }
    let secondUser = null;
    if (newOwnerIDs.indexOf(userID) === 1) {
      secondUser = userIDMap[newOwnerIDs[0]];
    } else if (newOwnerIDs.length > 1) {
      secondUser = userIDMap[newOwnerIDs[1]];
    }
    if (secondUser) {
      return ` + ${secondUser.firstName || ""}`;
    }
    return ""; // shouldn't be a second user
  };

  const { userID } = React.useContext(SessionContext.context);
  const { ownerIDs, setOwnerIDs } = React.useContext(GlobalFilteringContext);
  const [filteringOnName, setFilteringOnName] = React.useState<string>("Me");
  const { data: collaboratorsData } = useQuery(FETCH_MY_COLLABORATORS, {
    fetchPolicy: "cache-and-network",
    onCompleted: () => {},
  });

  React.useEffect(() => {
    collaboratorsData?.collaborations?.forEach((c: Collaboration) => {
      userIDMap[c.recipient.id] = c.recipient;
    });
    setFilteringOnName(
      getFirstUserName(ownerIDs) + getSecondUserName(ownerIDs)
    );
  }, [
    collaboratorsData,
    setFilteringOnName,
    getFirstUserName,
    getSecondUserName,
    ownerIDs,
  ]);

  // const collaborators = collaboratorsData?.collaborations || [];
  // if (!collaborators.length || !ownerIDs) {
  //   return null;
  // }

  const onFilter = (userData?: UserData, type?: CollaborationDirection) => {
    if (!userData?.id) {
      document.dispatchEvent(new MouseEvent("click"));
      return;
    }
    let newOwnerIDs = [...ownerIDs];
    const index = newOwnerIDs.indexOf(userData.id);
    if (index >= 0) {
      newOwnerIDs.splice(index, 1);
    } else {
      newOwnerIDs.push(userData.id);
    }

    if (newOwnerIDs.length === 0) {
      newOwnerIDs.push(userID);
    }

    setOwnerIDs(newOwnerIDs);
    setFilteringOnName(
      getFirstUserName(newOwnerIDs) + getSecondUserName(newOwnerIDs)
    );
  };

  const renderFilter = () => {
    return (
      <div>
        <Dropdown>
          <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
            <PylonDropDownToggle text={filteringOnName} />
          </DropdownToggle>
          <DropdownMenu className="pylon-dropdown-menu">
            <RecipientDropdownItem
              key={userID}
              onClick={(u) => {
                onFilter(u);
              }}
              recipient={{
                id: userID,
                firstName: "Me",
                lastName: " ",
                isPhoneVerified: true,
                isEmailVerified: true,
                email: "",
              }}
              selected={ownerIDs.indexOf(userID) >= 0}
              bottomBorder={true}
            />
            {collaboratorsData?.collaborations?.map(
              (c: Collaboration, index: number) => (
                <RecipientDropdownItem
                  key={c.id}
                  onClick={(u) => {
                    onFilter(u);
                  }}
                  recipient={c.recipient}
                  selected={ownerIDs.indexOf(c.recipient.id) >= 0}
                  bottomBorder={true}
                />
              )
            )}
            {(!collaboratorsData ||
              collaboratorsData?.collaborations?.length === 0) && (
              <div
                data-selector={`invite-partner-drop-down`}
                className="drop-down-menu-item"
              >
                <Link to={`${DashboardPages.ManageContact}?addNew=1`}>
                  <div>Invite a partner</div>
                </Link>
              </div>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  };

  return <div>{renderFilter()}</div>;
};
