import "./styles.css";

import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import React, { useState } from "react";
import {
  Request2FAVerificationResponse,
  obtainPasswordResetToken,
} from "core/api";

import { AccountsHeader } from "../../../components/header";
import { LoginPages } from "components/features/login/pages";
import { SectionHeader } from "components/core/section-header";
import { TwoFactorAuthComponent } from "components/features/login/components/2fa";
import qs from "qs";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

export const PasswordResetLandingPage: React.FC = () => {
  const history = useHistory();

  const [request2FAData, setRequest2FAData] = useState<
    Request2FAVerificationResponse | undefined
  >(undefined);

  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const code = query["code"] as string;

  React.useEffect(() => {
    obtainPasswordResetToken(
      code,
      (data) => {
        // An error has occurred
        if (data.message) {
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={`We could not fetch the information. Please refresh and try again`}
              type={ToastType.Error}
            />
          );
          return;
        }

        setRequest2FAData(data);
      },
      (err) => {
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={`We could not fetch the information. Please refresh and try again`}
            type={ToastType.Error}
          />
        );
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only run once on component mount

  if (!request2FAData) {
    return (
      <div className="account-page">
        <AccountsHeader redirectToHome />
        <SectionHeader>Reset your password</SectionHeader>
        <div>Loading your information...</div>
      </div>
    );
  }

  return (
    <div>
      <TwoFactorAuthComponent
        res={request2FAData}
        onCodeVerified={(data) => {
          history.push(
            `${LoginPages.NewPasswordPage}?code=${encodeURIComponent(
              code
            )}&otp=${encodeURIComponent(data.identityToken)}`
          );
        }}
      />
    </div>
  );
};
