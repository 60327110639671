import classnames from "classnames";
import { Logo } from "components/core/logo";
import { PublicPageLinks } from "components/features/public";
import "./styles.css";

export interface Props {
  transparentBackground?: boolean;
  hideIcon?: boolean;
  noLoginLink?: boolean;
}
export const PublicHeader: React.FC<Props> = ({
  noLoginLink,
  hideIcon,
  transparentBackground,
}) => {
  const bgNames = classnames({
    "public-header": true,
    "transparent-background": !!transparentBackground,
  });
  return (
    <div className={bgNames}>
      <div
        style={{
          paddingLeft: "1rem",
        }}
      >
        {!hideIcon && <Logo redirectTo={"/landing/main"} />}
      </div>
      <div
        style={{
          display: "flex",
          paddingRight: "2rem",
        }}
      >
        <a href={"mailto:support@hellopylon.com"}>SUPPORT</a>
        <a href={PublicPageLinks.Privacy}>PRIVACY</a>
        <a href={PublicPageLinks.Term}>TERMS</a>
        <a href="/security">SECURITY</a>
        {!noLoginLink && <a href="/login">LOG IN</a>}
      </div>
    </div>
  );
};
