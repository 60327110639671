import qs from "qs";
import React, { useContext } from "react";
import { DashboardPages } from "components/features/dashboard/pages";
import { loggedInUserVerifyEmail } from "core/api";
import { LoginPages } from "components/features/login/pages";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { SessionContext } from "components/contexts/session-context-provider";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useSessionStorage } from "core/utils/browser-storage";

export const VerifyEmailComponent: React.FC = () => {
  const history = useHistory();
  const { sessionToken, emailVerified } = useContext(SessionContext.context);

  const [emailVerificationCode, setEmailVerificationCode] = useSessionStorage(
    "email-verification-code",
    ""
  );

  React.useEffect(() => {
    const query = qs.parse(document.location.search, {
      ignoreQueryPrefix: true,
    });
    setEmailVerificationCode(query["ev"]);
  });

  React.useEffect(() => {
    const query = qs.parse(document.location.search, {
      ignoreQueryPrefix: true,
    });
    const emailVerificationCode = query["ev"] as string;
    if (
      emailVerificationCode &&
      emailVerificationCode !== "" &&
      !emailVerified
    ) {
      loggedInUserVerifyEmail(
        emailVerificationCode,
        sessionToken,
        () => {
          history.push(`${DashboardPages.Summary}?emailverified=true`);
        },
        (err) => {
          toast(
            <PylonToastBody
              title={"Email Verification failed"}
              body={`An error occurred. Please try again later`}
              type={ToastType.Error}
            />
          );
          setEmailVerificationCode("");
          history.push(LoginPages.Login);
        }
      );
    } else {
      history.push(LoginPages.Login);
    }
  }, [emailVerificationCode]);

  return <></>;
};
