import * as React from "react";
import { createViewLogMutation } from "core/queries/shared-data";
import { DefaultPreviewIcon } from "components/core/file-viewer/default-preview-icon";
import { DisplayModal } from "components/core/modal/display";
import { DocumentData } from "components/features/dashboard/models/document";
import { DownloadDocumentButton } from "components/features/dashboard/components/document-download-button";
import { extension } from "mime-types";
import { FileViewer } from "components/core/file-viewer";
import { SessionContext } from "components/contexts/session-context-provider";
import { useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import "./styles.css";

export interface Props {
  document?: DocumentData;
  file?: File;
  scale?: number;
}

export const DocumentIcon: React.FC<Props> = ({
  document,
  file,
  scale = 1,
}) => {
  const { userID } = React.useContext(SessionContext.context);
  const [sentLog, setSentLog] = useState(false);
  const [createViewLog] = useMutation(createViewLogMutation);
  const [showModal, setShowModal] = useState(false);

  const preview = React.useMemo(() => {
    if (document) {
      return (
        <FileViewer
          displayClassName="document-icon__file-preview"
          fileURL={document.previewURL || ""}
          mimeType={document.mimeType}
          unsupportedComponent={
            <div>Unsupported format for preview. Please download instead</div>
          }
          preview
        />
      );
    }
    const ext = extension(file?.type || "");

    return <DefaultPreviewIcon format={ext || "pdf"} />;
  }, [document?.previewURL, document?.previewURL]);

  const modalViewer = useMemo(() => {
    if (document) {
      return (
        <FileViewer
          fileURL={document.previewURL || ""}
          mimeType={document.mimeType}
          unsupportedComponent={<DownloadDocumentButton document={document} />}
        />
      );
    }

    return null;
  }, []);

  return (
    <div
      className="document-card__icon"
      style={{ height: `${3 * scale}rem`, width: `${3 * scale}rem` }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!sentLog && document?.owner?.id !== userID) {
          setSentLog(true);
          if (document?.id) {
            createViewLog({
              variables: {
                assetID: document.id,
              },
            });
          }
        }
        setShowModal(true);
      }}
    >
      {preview}
      <DisplayModal
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {modalViewer}
      </DisplayModal>
      {document && document.owner?.id !== userID && (
        <div className="row-card__shared-indicator"></div>
      )}
    </div>
  );
};
