import * as React from "react";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { TaxContext } from "components/contexts/tax-context";
import { TaxResults } from "components/features/dashboard/pages/income-and-taxes/components/tax-results/tax-results";
import { TaxSectionAddIncomeSource } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/add-income-source";
import { TaxSectionBonusIncome } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/income-bonus";
import { TaxSectionCommissionIncome } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/income-commission";
import { TaxSectionEmployerWithholding } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/withholding-employer";
import { TaxSectionHeader } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/tax-page-section-header";
import { TaxSectionInvestmentIncome } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/investment-income";
import { TaxSectionItemizedDeductions } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/deductions-itemized";
import { TaxSectionOverview } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/overview";
import { TaxSectionRegularIncome } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/income-regular";
import { TaxSectionRetirementContributions } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/deductions-retirement";
import { TaxSectionSupplementalWithholding } from "components/features/dashboard/pages/income-and-taxes/components/tax-page-sections/withholding-supplemental";
import { toMoneyString } from "core/utils";
import "./index-version-2.css";

export interface ProfileInput {
  occupation?: string;
  income?: string;
  spouseIncome?: string;
  filingStatus?: string;
  dob?: number;

  addressLineOne?: string;
  addressLineTwo?: string;
  addressLineThree?: string;
  city?: string;
  stateProvinceRegion?: string;
  postalCode?: string;
  country?: string;

  [index: string]: any;
}

interface Props {}
const currencyReplaceRegex = /(\$|,)/g;
export const sanitizeCurrencyString = (s: string) => {
  return s?.replace(currencyReplaceRegex, "");
};

export const IncomeAndTaxes2: React.FC<Props> = () => {
  const [addAccountsCounter] = React.useState<number>(0);

  const { taxResults, grossIncome, totalWithholding, federalDeductions } =
    React.useContext(TaxContext);

  return (
    <PageWrapper
      header={
        <PageTitle
          title="Income and Taxes"
          subtitle="Estimate your taxes real-time to see if you're on pace for a refund or tax bill."
        />
      }
      setWidth
    >
      <div className="income-and-taxes-2">
        <div className="body">
          <TaxSectionHeader
            headerKey="overview"
            headerLeft="Tax Overview"
            headerRight={<>{toMoneyString(grossIncome() || 0)}</>}
            table={
              <div className="table selectable" style={{ width: "100%" }}>
                <div className="table-head">
                  <div className="table-cell">Name</div>
                  <div className="table-cell">Details</div>
                </div>
                <div className="table-body">
                  <TaxSectionOverview />
                </div>
              </div>
            }
          />

          <TaxSectionHeader
            headerKey="income"
            headerLeft="Income"
            headerRight={<>{toMoneyString(grossIncome() || 0)}</>}
            table={
              <div className="table selectable" style={{ width: "100%" }}>
                <div className="table-head">
                  <div className="table-cell">Name</div>
                  <div className="table-cell">Amount</div>
                </div>
                <div className="table-body">
                  <TaxSectionRegularIncome />
                  <TaxSectionBonusIncome />
                  <TaxSectionCommissionIncome />
                  <TaxSectionInvestmentIncome
                    fetchAccountsTrigger={addAccountsCounter}
                  />
                  <TaxSectionAddIncomeSource />
                </div>
              </div>
            }
          />

          <TaxSectionHeader
            headerKey="deductions"
            headerLeft="Deductions"
            headerRight={
              <>
                <div style={{ fontSize: "17px", fontWeight: 700 }}>
                  {toMoneyString(
                    taxResults.calculated.allowedDeductibleContributions +
                      federalDeductions()
                  )}
                </div>
              </>
            }
            table={
              <div className="table selectable" style={{ width: "100%" }}>
                <div className="table-head">
                  <div className="table-cell">Name</div>
                  <div className="table-cell">Amount</div>
                </div>
                <div className="table-body">
                  <TaxSectionRetirementContributions />
                  <TaxSectionItemizedDeductions />
                </div>
              </div>
            }
          />
          <TaxSectionHeader
            headerKey="withholdings"
            headerLeft="Withholdings"
            headerRight={
              <>
                <div style={{ fontSize: "17px" }}>
                  {toMoneyString(totalWithholding())}
                </div>
              </>
            }
            table={
              <div className="table selectable" style={{ width: "100%" }}>
                <div className="table-head">
                  <div className="table-cell name">Name</div>
                  <div className="table-cell state-amount no-mobile">
                    State Amount
                  </div>
                  <div className="table-cell federal-amount">
                    Federal Amount
                  </div>
                </div>
                <div className="table-body">
                  <TaxSectionEmployerWithholding />
                  <TaxSectionSupplementalWithholding />
                </div>
              </div>
            }
          />

          <div className="mt2">
            <TaxResults />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
