import * as React from "react";
import { dateFormatYYYY_MM } from "core/utils";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  FilingStatus,
  getAllStates,
  getLocalities,
  getStateRules,
  PaycheckWithholdingResult,
  StateTaxRules,
  TaxCalculationInput,
  TaxCalculator,
} from "@pylon/taxes";
import {
  AccountSubType,
  AccountType,
} from "components/features/dashboard/components/add-account-tray/models";
import {
  AccountCG,
  sumInvestmentLTCG,
  sumInvestmentSTCG,
} from "components/features/dashboard/pages/income-and-taxes/components/cap-gains-calculator";
import {
  accountsBySubtypeQuery,
  AccountsBySubtypeResponse,
} from "core/queries/accounts";
import {
  CollaborationRelationships,
  FetchMyCollaboratorsResponse,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";
import {
  GET_TAX_INCOME_EVENTS,
  GET_TAX_ITEMIZED_DEDUCTIONS,
  GET_TAX_LIABILITY,
  GET_TAX_WITHHOLDING,
  REMOVE_TAX_INCOME_EVENT,
  REMOVE_TAX_ITEMIZED_DEDUCTION,
  SET_TAX_INCOME_EVENT,
  SET_TAX_ITEMIZED_DEDUCTION,
  SET_TAX_LIABILITY,
  SET_TAX_WITHHOLDING,
  TaxIncomeEventInterface,
  TaxIncomeEventType,
  TaxItemizedDeductionInterface,
  TaxLiabilityInterface,
  TaxWithholdingInterface,
} from "core/queries/taxes";

export interface TaxContextValue {
  bonusIncomeEvents: TaxIncomeEventInterface[] | undefined;
  commissionIncomeEvents: TaxIncomeEventInterface[] | undefined;
  itemizedDeductions: TaxItemizedDeductionInterface[] | undefined;
  earnedIncome: string;
  setEarnedIncome: React.Dispatch<React.SetStateAction<string>>;
  earnedIncomeSpouse: string;
  setEarnedIncomeSpouse: React.Dispatch<React.SetStateAction<string>>;
  showAdvancedFederal: boolean;
  setShowAdvancedFederal: React.Dispatch<React.SetStateAction<boolean>>;
  showAdvancedCG: boolean;
  setShowAdvancedCG: React.Dispatch<React.SetStateAction<boolean>>;
  filingStatus: FilingStatus;
  setFilingStatus: React.Dispatch<React.SetStateAction<FilingStatus>>;
  paymentPeriod: string;
  setPaymentPeriod: React.Dispatch<React.SetStateAction<string>>;
  federalWithholding: string | undefined;
  setFederalWithholding: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  spousePaymentPeriod: string;
  setSpousePaymentPeriod: React.Dispatch<React.SetStateAction<string>>;
  spouseFederalWithholding: string;
  setSpouseFederalWithholding: React.Dispatch<React.SetStateAction<string>>;
  spouseStateWithholding: string;
  setSpouseStateWithholding: React.Dispatch<React.SetStateAction<string>>;
  stateWithholding: string;
  setStateWithholding: React.Dispatch<React.SetStateAction<string>>;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  ltcg?: string;
  setLTCG: React.Dispatch<React.SetStateAction<string | undefined>>;
  stcg?: string;
  setSTCG: React.Dispatch<React.SetStateAction<string | undefined>>;
  contributions401K?: string;
  setContributions401K: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  contributionsIRA?: string;
  setContributionsIRA: React.Dispatch<React.SetStateAction<string | undefined>>;
  spouseContributions401K?: string;
  setSpouseContributions401K: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  spouseContributionsIRA?: string;
  setSpouseContributionsIRA: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  investmentSTCG?: AccountCG;
  setInvestmentSTCG: React.Dispatch<
    React.SetStateAction<AccountCG | undefined>
  >;
  totalExemptions: string;
  setTotalExemptions: React.Dispatch<React.SetStateAction<string>>;
  totalItemizedDeductions: number;
  locality: string;
  setLocality: React.Dispatch<React.SetStateAction<string>>;
  taxResults: any;
  noSpouse: boolean;
  stateRules: StateTaxRules;
  localityValues: {
    label: string;
    value: string;
  }[];

  saveLiabilityFieldValues: (changes: any) => void;
  saveLiabilityFieldValue: (
    key: keyof Omit<TaxLiabilityInterface, "taxYear">,
    value: string
  ) => void;
  saveWithholdingFieldValue: (
    key: keyof Omit<TaxWithholdingInterface, "taxYear">,
    value: string
  ) => void;
  saveIncomeEventFieldValue: (
    key: keyof Omit<
      TaxIncomeEventInterface,
      "taxYear" | "incomeEventType" | "id"
    >,
    value: string,
    type: TaxIncomeEventType,
    id?: string
  ) => void;
  removeIncomeEvent: (incomeEvent: TaxIncomeEventInterface) => void;
  removeItemizedDeduction: (
    itemizedDeduction: TaxItemizedDeductionInterface
  ) => void;
  month: string;
  setMonth: React.Dispatch<React.SetStateAction<string>>;
  shortTermCapitalGainsEstimator: (cg: number) => number;
  longTermCapitalGainsEstimator: (cg: number) => number;
  taxDataLoading: boolean;

  regularIncome: () => number;
  shortTermInvestmentIncome: number;
  longTermInvestmentIncome: number;
  retirementContributions: () => number;
  adjustedGrossIncome: () => number;
  effectiveRate: () => number;

  totalStateWithholding: () => number;
  totalFederalWithholding: () => number;
  totalWithholding: () => number;
  federalDeductions: () => number;
  shouldUseFederalDeduction: () => boolean;
  totalStateTaxLiability: () => number;
  totalFederalTaxLiability: () => number;
  grossIncome: () => number;

  unpaidStateTax: () => number;
  unpaidFederalTax: () => number;

  totalBonusIncome: number;
  totalCommissionIncome: number;

  estimatedSingleWithholding: (
    income: number,
    paymentPeriods: number
  ) => PaycheckWithholdingResult | undefined;

  useEstimatedWithholdings: () => void;

  estimateSupplementalIncomeWithholding: (supplementalIncome: number) => number;
  saveSupplementalIncome: (
    supplementalIncome: number,
    isSpouse: boolean,
    incomeEventType: TaxIncomeEventType,
    id?: string,
    estimateWithholding?: boolean
  ) => void;
  saveItemizedDeduction: (
    amount: number,
    type: string,
    name: string,
    id?: string
  ) => void;
  accountsBySubtypeResponse: AccountsBySubtypeResponse | undefined;
  refetchAccountsBySubtypeResponse: () => void;
}

interface Props {
  children: React.ReactNode;
  accountID?: string;
}

const currentTaxYear = 2020;

export const martialStatuses = [
  FilingStatus.MarriedFilingJointly,
  FilingStatus.MarriedFilingSeparately,
  FilingStatus.HeadOfHousehold,
];

export const filingStatusValues = Object.keys(FilingStatus)
  .filter((s) => {
    return s !== FilingStatus.QualifyingWidower;
  })
  .map((s) => {
    return {
      value: s,
      label: s.split(/(?=[A-Z])/).join(" "),
    };
  });

export const stateValues = getAllStates()
  .map((stateAbbreviation) => {
    return {
      value: stateAbbreviation,
      label: stateAbbreviation,
    };
  })
  .sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

export const paymentPeriods = [
  { label: "Twice a Month", value: "24" },
  { label: "Every 2 weeks", value: "26" },
  { label: "Every week", value: "52" },
  { label: "Once a month", value: "12" },
  { label: "Quarterly", value: "4" },
  { label: "Once a year", value: "1" },
];

export const TaxContext = React.createContext<TaxContextValue>({
  bonusIncomeEvents: [],
  itemizedDeductions: [],
  commissionIncomeEvents: [],
  earnedIncome: "",
  setEarnedIncome: () => null,
  earnedIncomeSpouse: "",
  setEarnedIncomeSpouse: () => null,
  showAdvancedFederal: false,
  setShowAdvancedFederal: () => null,
  showAdvancedCG: false,
  setShowAdvancedCG: () => null,
  filingStatus: FilingStatus.Single,
  setFilingStatus: () => null,
  paymentPeriod: "",
  setPaymentPeriod: () => null,
  federalWithholding: "",
  setFederalWithholding: () => null,
  spousePaymentPeriod: "",
  setSpousePaymentPeriod: () => null,
  spouseFederalWithholding: "",
  setSpouseFederalWithholding: () => null,
  spouseStateWithholding: "",
  setSpouseStateWithholding: () => null,
  stateWithholding: "",
  setStateWithholding: () => null,
  state: "",
  setState: () => null,
  ltcg: "",
  setLTCG: () => null,
  stcg: "",
  setSTCG: () => null,
  contributions401K: "",
  setContributions401K: () => null,
  contributionsIRA: "",
  setContributionsIRA: () => null,
  spouseContributions401K: "",
  setSpouseContributions401K: () => null,
  spouseContributionsIRA: "",
  setSpouseContributionsIRA: () => null,
  investmentSTCG: undefined,
  setInvestmentSTCG: () => null,
  totalExemptions: "",
  totalItemizedDeductions: 0,
  setTotalExemptions: () => null,
  locality: "",
  setLocality: () => null,
  taxResults: undefined,
  noSpouse: false,
  stateRules: {
    stateTaxes: false,
    stateAbbreviation: "",
    localityTaxes: false,
    localityTaxesDependOnStatus: false,
    stateExemptions: false,
    stateDeductions: false,
    localitySurchage: false,
    useFederalTaxableIncome: false,
  },
  localityValues: [],

  saveLiabilityFieldValues: () => null,
  saveLiabilityFieldValue: () => null,
  saveWithholdingFieldValue: () => null,
  saveIncomeEventFieldValue: () => null,
  removeIncomeEvent: () => null,
  removeItemizedDeduction: () => null,

  month: "2021-09",
  setMonth: () => null,
  shortTermCapitalGainsEstimator: (cg: number) => 0,
  longTermCapitalGainsEstimator: (cg: number) => 0,
  taxDataLoading: false,

  regularIncome: () => 0,
  shortTermInvestmentIncome: 0,
  longTermInvestmentIncome: 0,
  retirementContributions: () => 0,
  adjustedGrossIncome: () => 0,
  effectiveRate: () => 0,

  totalStateWithholding: () => 0,
  totalFederalWithholding: () => 0,
  totalWithholding: () => 0,
  federalDeductions: () => 0,

  shouldUseFederalDeduction: () => true,

  totalStateTaxLiability: () => 0,
  totalFederalTaxLiability: () => 0,

  grossIncome: () => 0,

  unpaidStateTax: () => 0,
  unpaidFederalTax: () => 0,

  totalBonusIncome: 0,
  totalCommissionIncome: 0,

  estimatedSingleWithholding: (income: number, paymentPeriods: number) =>
    undefined,

  useEstimatedWithholdings: () => null,

  estimateSupplementalIncomeWithholding: (supplementalIncome: number) => 0,

  saveSupplementalIncome: (
    supplementalIncome: number,
    isSpouse: boolean,
    incomeEventType: TaxIncomeEventType,
    id?: string,
    estimateWithholding?: boolean
  ) => null,

  saveItemizedDeduction: (
    amount: number,
    type: string,
    name: string,
    id?: string
  ) => null,
  accountsBySubtypeResponse: undefined,
  refetchAccountsBySubtypeResponse: () => null,
});

export const TaxContextProvider: React.FC<Props> = (props: Props) => {
  const [month, setMonth] = useState(`${dateFormatYYYY_MM(new Date())}`);

  const [setTaxLiabilityFields] = useMutation<TaxLiabilityInterface>(
    SET_TAX_LIABILITY,
    {
      onError: (err) => {
        console.error("failed to update tax fields", err);
      },
    }
  );

  const [setTaxWithholdingFields] = useMutation<TaxWithholdingInterface>(
    SET_TAX_WITHHOLDING,
    {
      onError: (err) => {
        console.error("failed to update tax fields", err);
      },
    }
  );

  const [setAllTaxWithholdingFields] = useMutation<TaxWithholdingInterface>(
    SET_TAX_WITHHOLDING,
    {
      onError: (err) => {
        console.error("failed to update tax fields", err);
      },
      onCompleted: (_data: any) => {
        const data = _data.setTaxWithholding as TaxWithholdingInterface;
        setFederalWithholding(data.federalIncomeTax);
        setStateWithholding(data.stateIncomeTax || "0");
        setSpouseFederalWithholding(data.spouseFederalIncomeTax || "0");
        setSpouseStateWithholding(data.spouseStateIncomeTax || "0");
      },
    }
  );

  const { data: liabilityData, loading: taxDataLoading } = useQuery<{
    taxLiability?: TaxLiabilityInterface;
  }>(GET_TAX_LIABILITY, {
    fetchPolicy: "network-only",
    variables: {
      taxYear: currentTaxYear,
    },
    onError: (err) => {
      console.error("failed to get tax fields", err);
    },
  });

  const { data: withholdingData } = useQuery<{
    taxWithholding: TaxWithholdingInterface;
  }>(GET_TAX_WITHHOLDING, {
    fetchPolicy: "network-only",
    variables: {
      taxYear: currentTaxYear,
    },
    onError: (err) => {
      console.error("failed to get tax fields", err);
    },
  });

  const { data: supplementalIncomeEvents } = useQuery<{
    taxIncomeEvents?: TaxIncomeEventInterface[];
  }>(GET_TAX_INCOME_EVENTS, {
    fetchPolicy: "network-only",
    variables: {
      taxYear: currentTaxYear,
    },
    onError: (err) => {
      console.error("failed to get tax fields", err);
    },
  });

  const [setTaxIncomeEventFields] = useMutation<TaxIncomeEventInterface>(
    SET_TAX_INCOME_EVENT,
    {
      refetchQueries: [
        {
          query: GET_TAX_INCOME_EVENTS,
          variables: {
            taxYear: currentTaxYear,
          },
        },
      ],
      onError: (err) => {
        console.error("failed to update tax event fields", err);
      },
    }
  );

  const [setTaxItemizedDeductionFields] =
    useMutation<TaxItemizedDeductionInterface>(SET_TAX_ITEMIZED_DEDUCTION, {
      refetchQueries: [
        {
          query: GET_TAX_ITEMIZED_DEDUCTIONS,
          variables: {
            taxYear: currentTaxYear,
          },
        },
      ],
      onError: (err) => {
        console.error("failed to update tax itemized deductions fields", err);
      },
    });

  const { data: itemizedDeductionsData } = useQuery<{
    taxItemizedDeductions?: TaxItemizedDeductionInterface[];
  }>(GET_TAX_ITEMIZED_DEDUCTIONS, {
    fetchPolicy: "network-only",
    variables: {
      taxYear: currentTaxYear,
    },
    onError: (err) => {
      console.error("failed to get tax fields", err);
    },
  });

  const {
    data: accountsBySubtypeResponse,
    loading: accountsLoading,
    refetch: refetchAccountsBySubtypeResponse,
  } = useQuery<AccountsBySubtypeResponse>(accountsBySubtypeQuery, {
    fetchPolicy: "network-only",
    variables: {
      subtype:
        AccountSubType[AccountType.NonRetirement]["Brokerage/Trading Account"],
    },
  });

  const saveIncomeEventFieldValue = (
    key: keyof Omit<
      TaxIncomeEventInterface,
      "taxYear" | "incomeEventType" | "id"
    >,
    value: string,
    type: TaxIncomeEventType,
    id?: string
  ) => {
    const o: TaxIncomeEventInterface = {
      taxYear: currentTaxYear,
      incomeEventType: type,
      id: id,
    };

    o[key] = value;

    setTaxIncomeEventFields({
      variables: {
        input: o,
      },
    });
  };

  const [removeTaxIncomeEvent] = useMutation<TaxIncomeEventInterface>(
    REMOVE_TAX_INCOME_EVENT,
    {
      refetchQueries: [
        {
          query: GET_TAX_INCOME_EVENTS,
          variables: {
            taxYear: currentTaxYear,
          },
        },
      ],
      onError: (err) => {
        console.error("failed to update tax event fields", err);
      },
    }
  );

  const removeIncomeEvent = (incomeEvent: TaxIncomeEventInterface) => {
    removeTaxIncomeEvent({
      variables: {
        id: incomeEvent.id,
      },
    });
  };

  const [removeTaxItemizedDeduction] =
    useMutation<TaxItemizedDeductionInterface>(REMOVE_TAX_ITEMIZED_DEDUCTION, {
      refetchQueries: [
        {
          query: GET_TAX_ITEMIZED_DEDUCTIONS,
          variables: {
            taxYear: currentTaxYear,
          },
        },
      ],
      onError: (err) => {
        console.error("failed to update tax itemized deduction fields", err);
      },
    });

  const removeItemizedDeduction = (
    itemizedDeduction: TaxItemizedDeductionInterface
  ) => {
    removeTaxItemizedDeduction({
      variables: {
        id: itemizedDeduction.id,
      },
    });
  };

  const [earnedIncome, setEarnedIncome] = React.useState<string>("0.00");
  const [longTermInvestmentIncome, setLongTermInvestmentIncome] =
    React.useState<number>(0);
  const [shortTermInvestmentIncome, setShortTermInvestmentIncome] =
    React.useState<number>(0);
  const [earnedIncomeSpouse, setEarnedIncomeSpouse] =
    React.useState<string>("0");
  const [showAdvancedFederal, setShowAdvancedFederal] =
    React.useState<boolean>(false);
  const [showAdvancedCG, setShowAdvancedCG] = React.useState<boolean>(false);
  const [filingStatus, setFilingStatus] = React.useState<FilingStatus>(
    FilingStatus[
      liabilityData?.taxLiability?.filingStatus as keyof typeof FilingStatus
    ] || FilingStatus.Single
  );
  const [paymentPeriod, setPaymentPeriod] = React.useState<string>("24");
  const [federalWithholding, setFederalWithholding] = React.useState<
    string | undefined
  >();
  const [stateWithholding, setStateWithholding] = React.useState<string>("0");
  const [spousePaymentPeriod, setSpousePaymentPeriod] =
    React.useState<string>("24");
  const [spouseFederalWithholding, setSpouseFederalWithholding] =
    React.useState<string>("0");
  const [spouseStateWithholding, setSpouseStateWithholding] =
    React.useState<string>("0");
  const [state, setState] = React.useState<string>("MD");
  const [ltcg, setLTCG] = React.useState<string>();
  const [stcg, setSTCG] = React.useState<string>();
  const [investmentSTCG, setInvestmentSTCG] = React.useState<AccountCG>();
  const [contributions401K, setContributions401K] = React.useState<string>();
  const [contributionsIRA, setContributionsIRA] = React.useState<string>();
  const [spouseContributions401K, setSpouseContributions401K] =
    React.useState<string>();
  const [spouseContributionsIRA, setSpouseContributionsIRA] =
    React.useState<string>();
  const [totalExemptions, setTotalExemptions] = React.useState<string>(
    (filingStatus === FilingStatus.MarriedFilingJointly ? 2 : 1).toString()
  );
  const [totalItemizedDeductions, setTotalItemizedDeductions] =
    React.useState<number>(0);

  const [locality, setLocality] = React.useState<string>(
    getLocalities(state) ? getLocalities(state)[0] : ""
  );

  React.useEffect(() => {
    setShortTermInvestmentIncome(
      parseFloat(stcg || "") +
        sumInvestmentSTCG(
          investmentSTCG,
          accountsBySubtypeResponse,
          accountsLoading
        )
    );
    setLongTermInvestmentIncome(
      parseFloat(ltcg || "") +
        sumInvestmentLTCG(
          investmentSTCG,
          accountsBySubtypeResponse,
          accountsLoading
        )
    );
  }, [accountsBySubtypeResponse, accountsLoading, investmentSTCG, ltcg, stcg]);

  React.useEffect(() => {
    if (!itemizedDeductionsData?.taxItemizedDeductions) {
      return;
    }

    setTotalItemizedDeductions(
      itemizedDeductionsData.taxItemizedDeductions.reduce((total, current) => {
        return (total += parseFloat(current.amount || "0"));
      }, 0)
    );
  }, [itemizedDeductionsData]);

  // Save Liability Data to UI
  React.useEffect(() => {
    if (!liabilityData?.taxLiability) {
      return;
    }
    const tl = liabilityData.taxLiability;
    if (tl.filingStatus) {
      setFilingStatus(
        FilingStatus[tl?.filingStatus as keyof typeof FilingStatus]
      );
    }
    if (tl.earnedIncome) {
      setEarnedIncome(tl.earnedIncome);
    }
    setEarnedIncomeSpouse(tl.spouseEarnedIncome || "0");
    setContributions401K(tl.contributions401K || "0");
    setContributionsIRA(tl.contributionsIRA || "0");
    setSpouseContributions401K(tl.spouseContributions401K || "0");
    setSpouseContributionsIRA(tl.spouseContributionsIRA || "0");
    setSTCG(tl.capitalGainsShortTerm || "0");
    // setInvestmentSTCG(parseInvestmentSTCG(tl.investmentCapitalGainsShortTerm));
    setLTCG(tl.capitalGainsLongTerm || "0");
    setTotalExemptions(tl.totalExemptions || "0");
    setState(tl.state || "MD");
    setLocality(tl.county || "");
    const actualTotalExemptions = parseInt(tl.totalExemptions || "0");
    const expectedTotalExemptions =
      (tl.filingStatus || FilingStatus.Single) ===
      FilingStatus.MarriedFilingJointly
        ? 2
        : 1;
    if (
      parseInt(tl.contributions401K || "0") ||
      parseInt(tl.contributionsIRA || "0") ||
      parseInt(tl.spouseContributions401K || "0") ||
      parseInt(tl.spouseContributionsIRA || "0") ||
      parseInt(tl.capitalGainsShortTerm || "0") ||
      parseInt(tl.capitalGainsLongTerm || "0") ||
      (actualTotalExemptions &&
        actualTotalExemptions !== expectedTotalExemptions)
    ) {
      setShowAdvancedFederal(true);
    }
    // if (tl.investmentCapitalGainsShortTerm) {
    //   setShowAdvancedCG(true);
    // }
  }, [liabilityData?.taxLiability]);

  const totalBonusIncome = React.useMemo(() => {
    const includeSpouse = filingStatus === FilingStatus.MarriedFilingJointly;
    return (
      supplementalIncomeEvents?.taxIncomeEvents
        ?.filter((event) => {
          return event.incomeEventType === TaxIncomeEventType.Bonus;
        })
        ?.reduce((total, incomeEvent) => {
          return (total +=
            (parseFloat(incomeEvent?.amount || "") || 0) +
            (includeSpouse
              ? parseFloat(incomeEvent?.spouseAmount || "") || 0
              : 0));
        }, 0) || 0
    );
  }, [filingStatus, supplementalIncomeEvents?.taxIncomeEvents]);

  const totalCommissionIncome = React.useMemo(() => {
    const includeSpouse = filingStatus === FilingStatus.MarriedFilingJointly;
    return (
      supplementalIncomeEvents?.taxIncomeEvents
        ?.filter((event) => {
          return event.incomeEventType === TaxIncomeEventType.Commission;
        })
        ?.reduce((total, incomeEvent) => {
          return (total +=
            (parseFloat(incomeEvent?.amount || "") || 0) +
            (includeSpouse
              ? parseFloat(incomeEvent?.spouseAmount || "") || 0
              : 0));
        }, 0) || 0
    );
  }, [filingStatus, supplementalIncomeEvents?.taxIncomeEvents]);

  React.useEffect(() => {
    if (!withholdingData?.taxWithholding) {
      return;
    }
    const tw = withholdingData?.taxWithholding;
    setPaymentPeriod(tw.paymentPeriodsPerYear || "24");
    setFederalWithholding(tw.federalIncomeTax || "0");
    setStateWithholding(tw.stateIncomeTax || "0");

    setSpousePaymentPeriod(tw.spousePaymentPeriodsPerYear || "24");
    setSpouseFederalWithholding(tw.spouseFederalIncomeTax || "0");
    setSpouseStateWithholding(tw.spouseStateIncomeTax || "0");
  }, [withholdingData?.taxWithholding]);

  const taxResults = React.useMemo<any>(() => {
    const taxInputs: TaxCalculationInput = {
      stateAbbreviation: state,
      locality: locality,
      filingStatus: filingStatus,
      federalDeductions: totalItemizedDeductions,
      income:
        parseFloat(earnedIncome) +
        totalBonusIncome +
        totalCommissionIncome +
        (filingStatus === FilingStatus.MarriedFilingJointly
          ? parseFloat(earnedIncomeSpouse) || 0
          : 0),
      exemptions: parseFloat(totalExemptions),
      longTermCapitalGains:
        parseFloat(ltcg || "") + sumInvestmentLTCG(investmentSTCG),
      shortTermCapitalGains:
        parseFloat(stcg || "") + sumInvestmentSTCG(investmentSTCG),
      contributions401k:
        (parseFloat(contributions401K || "") || 0) +
        (parseFloat(spouseContributions401K || "") || 0),
      contributionsTraditionalIRA:
        (parseFloat(contributionsIRA || "") || 0) +
        (parseFloat(spouseContributionsIRA || "") || 0),

      paymentPeriods: parseInt(paymentPeriod),
      federalWithholding: parseFloat(federalWithholding || "0"),
      stateWithholding: parseFloat(stateWithholding),

      spousePaymentPeriods: parseInt(spousePaymentPeriod),
      spouseFederalWithholding: parseFloat(spouseFederalWithholding),
      spouseStateWithholding: parseFloat(spouseStateWithholding),
    };
    const calculator = new TaxCalculator(taxInputs);

    return {
      federal: calculator.getFederalTaxes(),
      state: calculator.getStateTaxes(),
      taxOutcome: calculator.getTaxOutcome(),
      calculated: calculator.getCalculated(),
      taxInput: taxInputs,
    };
  }, [
    state,
    locality,
    filingStatus,
    earnedIncome,
    totalBonusIncome,
    totalCommissionIncome,
    earnedIncomeSpouse,
    totalExemptions,
    ltcg,
    investmentSTCG,
    stcg,
    contributions401K,
    spouseContributions401K,
    contributionsIRA,
    spouseContributionsIRA,
    paymentPeriod,
    federalWithholding,
    stateWithholding,
    spousePaymentPeriod,
    spouseFederalWithholding,
    spouseStateWithholding,
    totalItemizedDeductions,
  ]);

  const stateRules = React.useMemo<StateTaxRules>(() => {
    return getStateRules(state);
  }, [state]);

  const localityValues = React.useMemo(() => {
    const o = stateRules?.localityTaxes
      ? getLocalities(state)?.map((l: string) => {
          return {
            label: l.replace(/_/g, " "),
            value: l,
          };
        }) || []
      : [];
    return o;
  }, [state, stateRules]);

  const { data: myCollabData } = useQuery<FetchMyCollaboratorsResponse>(
    FETCH_MY_COLLABORATORS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const noSpouse = !myCollabData?.collaborations?.find((c) => {
    return c.relationship === CollaborationRelationships.Spouse;
  });

  const includeSpouse = filingStatus === FilingStatus.MarriedFilingJointly;

  const taxInputs = taxDataLoading
    ? null
    : {
        stateAbbreviation: liabilityData?.taxLiability?.state || "FL",
        locality: liabilityData?.taxLiability?.county || "",
        filingStatus: filingStatus,
        income:
          parseFloat(liabilityData?.taxLiability?.earnedIncome || "100000") +
          (includeSpouse
            ? parseFloat(
                liabilityData?.taxLiability?.spouseEarnedIncome || "100000"
              ) || 0
            : 0),
        exemptions: parseFloat(
          liabilityData?.taxLiability?.totalExemptions || "1"
        ),
        longTermCapitalGains: parseFloat(
          liabilityData?.taxLiability?.capitalGainsLongTerm || ""
        ),
        shortTermCapitalGains: parseFloat(
          liabilityData?.taxLiability?.capitalGainsShortTerm || ""
        ),
        contributions401k:
          (parseFloat(liabilityData?.taxLiability?.contributions401K || "") ||
            0) +
          (includeSpouse
            ? parseFloat(
                liabilityData?.taxLiability?.spouseContributions401K || ""
              ) || 0
            : 0),
        contributionsTraditionalIRA:
          (parseFloat(liabilityData?.taxLiability?.contributionsIRA || "") ||
            0) +
          (includeSpouse
            ? parseFloat(
                liabilityData?.taxLiability?.spouseContributionsIRA || ""
              ) || 0
            : 0),
      };

  const saveLiabilityFieldValues = (changes: any) => {
    const o: TaxLiabilityInterface = {
      taxYear: currentTaxYear,
    };

    Object.assign(o, changes);

    setTaxLiabilityFields({
      variables: {
        input: o,
      },
    });
  };

  const saveLiabilityFieldValue = (
    key: keyof Omit<TaxLiabilityInterface, "taxYear">,
    value: string
  ) => {
    const o: TaxLiabilityInterface = {
      taxYear: currentTaxYear,
    };

    if (
      liabilityData?.taxLiability &&
      value === liabilityData.taxLiability[key]
    ) {
      return;
    }

    o[key] = value;

    setTaxLiabilityFields({
      variables: {
        input: o,
      },
    });
  };

  const saveWithholdingFieldValue = (
    key: keyof Omit<TaxWithholdingInterface, "taxYear">,
    value: string
  ) => {
    const o: TaxWithholdingInterface = {
      taxYear: currentTaxYear,
    };

    o[key] = value;

    setTaxWithholdingFields({
      variables: {
        input: o,
      },
    });
  };

  const saveWithholdingValues = (
    stateIncomeTax: number,
    federalIncomeTax: number,
    spouseStateIncomeTax: number,
    spouseFederalIncomeTax: number
  ) => {
    setAllTaxWithholdingFields({
      variables: {
        input: {
          taxYear: currentTaxYear,
          stateIncomeTax: stateIncomeTax.toFixed(2),
          federalIncomeTax: federalIncomeTax.toFixed(2),
          spouseStateIncomeTax: spouseStateIncomeTax.toFixed(2),
          spouseFederalIncomeTax: spouseFederalIncomeTax.toFixed(2),
        },
      },
    });
  };

  const getShortTermCapitalGainsEstimate = (
    taxInputs: TaxCalculationInput,
    additionalCapGains: number = 0
  ) => {
    const inputsCopy = Object.assign({}, taxInputs);
    inputsCopy.shortTermCapitalGains =
      (inputsCopy.shortTermCapitalGains || 0) + additionalCapGains;
    const calculator = new TaxCalculator(inputsCopy);
    return (
      calculator.getFederalTaxes().shortTermCapitalGains.total +
      calculator.getStateTaxes().shortTermCapitalGains.total
    );
  };
  const shortTermCapitalGainsEstimator = (cg: number) => {
    if (!taxInputs) {
      return 0;
    }
    const taxBefore = getShortTermCapitalGainsEstimate(taxInputs);
    const taxAfter = getShortTermCapitalGainsEstimate(taxInputs, cg);
    return taxAfter - taxBefore;
  };

  const getLongTermCapitalGainsEstimate = (
    taxInputs: TaxCalculationInput,
    additionalCapGains: number = 0
  ) => {
    const inputsCopy = Object.assign({}, taxInputs);
    inputsCopy.longTermCapitalGains =
      (inputsCopy.longTermCapitalGains || 0) + additionalCapGains;
    const calculator = new TaxCalculator(inputsCopy);
    return (
      calculator.getFederalTaxes().longTermCapitalGains.total +
      calculator.getStateTaxes().longTermCapitalGains.total
    );
  };
  const longTermCapitalGainsEstimator = (cg: number) => {
    if (!taxInputs) {
      return 0;
    }
    const taxBefore = getLongTermCapitalGainsEstimate(taxInputs);
    const taxAfter = getLongTermCapitalGainsEstimate(taxInputs, cg);
    return taxAfter - taxBefore;
  };

  const regularIncome = () => {
    const ei = parseInt(earnedIncome) || 0;
    const sei = parseInt(earnedIncomeSpouse) || 0;
    return ei + (filingStatus === FilingStatus.MarriedFilingJointly ? sei : 0);
  };

  const retirementContributions = () => {
    const rc =
      parseFloat(contributions401K || "0") +
      parseFloat(contributionsIRA || "0");
    const src =
      parseFloat(spouseContributions401K || "0") +
      parseFloat(spouseContributionsIRA || "0");
    return rc + (filingStatus === FilingStatus.MarriedFilingJointly ? src : 0);
  };

  const adjustedGrossIncome = () => {
    return taxResults.calculated.federgalAGI;
  };
  const effectiveRate = () => {
    return parseFloat(
      (taxResults.federal.income.effectiveRate * 100).toFixed(2)
    );
  };

  const totalStateWithholding = () => {
    const w = parseFloat(stateWithholding) * parseFloat(paymentPeriod);

    const sw =
      parseFloat(spouseStateWithholding) * parseFloat(spousePaymentPeriod);

    return w + (filingStatus === FilingStatus.MarriedFilingJointly ? sw : 0);
  };

  const totalFederalWithholding = () => {
    const w = parseFloat(federalWithholding || "0") * parseFloat(paymentPeriod);
    const sw =
      parseFloat(spouseFederalWithholding) * parseFloat(spousePaymentPeriod);

    return w + (filingStatus === FilingStatus.MarriedFilingJointly ? sw : 0);
  };

  const totalFederalSupplementalWithholding = () => {
    const wSupplemental =
      supplementalIncomeEvents?.taxIncomeEvents?.reduce(
        (total, incomeEvent) => {
          return total + parseFloat(incomeEvent.federalWithholding || "0");
        },
        0
      ) || 0;
    const swSupplemental =
      supplementalIncomeEvents?.taxIncomeEvents?.reduce(
        (total, incomeEvent) => {
          return (
            total + parseFloat(incomeEvent.spouseFederalWithholding || "0")
          );
        },
        0
      ) || 0;
    return (
      wSupplemental +
      (filingStatus === FilingStatus.MarriedFilingJointly ? swSupplemental : 0)
    );
  };

  const totalStateSupplementalWithholding = () => {
    const wSupplemental =
      supplementalIncomeEvents?.taxIncomeEvents?.reduce(
        (total, incomeEvent) => {
          return total + parseFloat(incomeEvent.stateWithholding || "0");
        },
        0
      ) || 0;
    const swSupplemental =
      supplementalIncomeEvents?.taxIncomeEvents?.reduce(
        (total, incomeEvent) => {
          return total + parseFloat(incomeEvent.spouseStateWithholding || "0");
        },
        0
      ) || 0;
    return (
      wSupplemental +
      (filingStatus === FilingStatus.MarriedFilingJointly ? swSupplemental : 0)
    );
  };

  const totalWithholding = () => {
    return (
      totalFederalWithholding() +
      totalStateWithholding() +
      totalStateSupplementalWithholding() +
      totalFederalSupplementalWithholding()
    );
  };

  const federalDeductions = () => {
    return taxResults.calculated.federalDeductions;
  };

  const shouldUseFederalDeduction = () => {
    return (
      taxResults.calculated.federalDeductions ===
      taxResults.calculated.standardDeductionAmount
    );
  };

  const totalStateTaxLiability = () => {
    return taxResults?.state?.income?.total || 0;
  };

  const totalFederalTaxLiability = () => {
    return (
      (taxResults?.federal?.income?.total || 0) +
      (taxResults?.federal?.netInvestmentIncomeTax?.total || 0) +
      (taxResults?.federal?.longTermCapitalGains?.total || 0)
    );
  };

  const grossIncome = () => {
    return (
      parseFloat(earnedIncome) +
      totalBonusIncome +
      totalCommissionIncome +
      (filingStatus === FilingStatus.MarriedFilingJointly
        ? parseFloat(earnedIncomeSpouse) || 0
        : 0) +
      parseFloat(ltcg || "") +
      sumInvestmentLTCG(investmentSTCG) +
      parseFloat(stcg || "") +
      sumInvestmentSTCG(investmentSTCG)
    );
  };

  const unpaidStateTax = () => {
    return totalStateTaxLiability() - totalStateWithholding();
  };

  const unpaidFederalTax = () => {
    return totalFederalTaxLiability() - totalFederalWithholding();
  };

  const estimatedSingleWithholding = (
    income: number,
    paymentPeriods: number
  ): PaycheckWithholdingResult => {
    // For withholding, treat the income as
    // a Single filer, even if filing as Married.
    const taxCalculator = new TaxCalculator({
      income: income,
      filingStatus: FilingStatus.Single,
      stateAbbreviation: state,
      locality: locality,
      paymentPeriods,
      exemptions: 1,
    });
    return taxCalculator.getWithholdingEstimate();
  };

  const useEstimatedWithholdings = () => {
    const estimated = estimatedSingleWithholding(
      parseFloat(earnedIncome) || 0,
      parseInt(paymentPeriod)
    );
    const estimatedSpouse = estimatedSingleWithholding(
      parseFloat(earnedIncomeSpouse) || 0,
      parseInt(spousePaymentPeriod)
    );
    saveWithholdingValues(
      estimated.stateIncome.total,
      estimated.federalIncome.total,
      estimatedSpouse.stateIncome.total,
      estimatedSpouse.federalIncome.total
    );
  };

  const estimateSupplementalIncomeWithholding = (
    supplementalIncome: number
  ) => {
    return supplementalIncome * 0.22;
  };

  const saveSupplementalIncome = (
    supplementalIncome: number,
    isSpouse: boolean,
    incomeEventType: TaxIncomeEventType,
    id?: string,
    estimateWithholding?: boolean
  ) => {
    if (!estimateWithholding) {
      setTaxIncomeEventFields({
        variables: {
          [isSpouse ? "spouseAmount" : "amount"]: supplementalIncome,
        },
      });
    }

    const calculator = new TaxCalculator({
      income: parseFloat(earnedIncome),
      filingStatus: FilingStatus.Single,
      stateAbbreviation: state,
      locality: locality,
      exemptions: 1,
    });

    const withholdingEstimates =
      calculator.calculateSupplementalWithholding(supplementalIncome);

    const federalWithholding = withholdingEstimates.federal;
    const stateWithholding = withholdingEstimates.state;

    setTaxIncomeEventFields({
      variables: {
        input: {
          [isSpouse ? "spouseAmount" : "amount"]:
            supplementalIncome?.toFixed(2),
          [isSpouse ? "spouseFederalWithholding" : "federalWithholding"]:
            federalWithholding?.toFixed(2),
          [isSpouse ? "spouseStateWithholding" : "stateWithholding"]:
            stateWithholding?.toFixed(2),
          id,
          incomeEventType,
          taxYear: currentTaxYear,
        },
      },
    });
  };

  const saveItemizedDeduction = (
    amount: number,
    type: string,
    name: string,
    id?: string
  ) => {
    setTaxItemizedDeductionFields({
      variables: {
        input: {
          id,
          amount: amount.toFixed(2),
          type,
          name,
          taxYear: currentTaxYear,
        },
      },
    });
  };

  return (
    <TaxContext.Provider
      value={{
        saveSupplementalIncome,
        estimateSupplementalIncomeWithholding,
        useEstimatedWithholdings,
        estimatedSingleWithholding,
        totalBonusIncome,
        bonusIncomeEvents: supplementalIncomeEvents?.taxIncomeEvents?.filter(
          (event) => {
            return event.incomeEventType === TaxIncomeEventType.Bonus;
          }
        ),
        totalCommissionIncome,
        commissionIncomeEvents:
          supplementalIncomeEvents?.taxIncomeEvents?.filter((event) => {
            return event.incomeEventType === TaxIncomeEventType.Commission;
          }),
        unpaidStateTax,
        saveItemizedDeduction,
        itemizedDeductions: itemizedDeductionsData?.taxItemizedDeductions,
        unpaidFederalTax,
        grossIncome,
        totalFederalTaxLiability,
        totalStateTaxLiability,

        federalDeductions,
        shouldUseFederalDeduction,
        totalStateWithholding,
        totalFederalWithholding,
        totalWithholding,

        adjustedGrossIncome,
        effectiveRate,
        regularIncome,
        shortTermInvestmentIncome,
        longTermInvestmentIncome,
        retirementContributions,

        earnedIncome,
        setEarnedIncome,
        earnedIncomeSpouse,
        setEarnedIncomeSpouse,
        showAdvancedFederal,
        setShowAdvancedFederal,
        showAdvancedCG,
        setShowAdvancedCG,
        filingStatus,
        setFilingStatus,
        paymentPeriod,
        setPaymentPeriod,
        federalWithholding,
        setFederalWithholding,
        spousePaymentPeriod,
        setSpousePaymentPeriod,
        spouseFederalWithholding,
        setSpouseFederalWithholding,
        spouseStateWithholding,
        setSpouseStateWithholding,
        stateWithholding,
        setStateWithholding,
        state,
        setState,
        ltcg,
        setLTCG,
        stcg,
        setSTCG,
        contributions401K,
        setContributions401K,
        contributionsIRA,
        setContributionsIRA,
        spouseContributions401K,
        setSpouseContributions401K,
        spouseContributionsIRA,
        setSpouseContributionsIRA,
        investmentSTCG,
        setInvestmentSTCG,
        totalExemptions,
        setTotalExemptions,
        totalItemizedDeductions,
        locality,
        setLocality,
        taxResults,
        noSpouse,
        stateRules,
        localityValues,

        saveLiabilityFieldValues,
        saveLiabilityFieldValue,
        saveWithholdingFieldValue,
        saveIncomeEventFieldValue,
        removeIncomeEvent,

        removeItemizedDeduction,
        month,
        setMonth,
        shortTermCapitalGainsEstimator,
        longTermCapitalGainsEstimator,
        taxDataLoading,

        accountsBySubtypeResponse,
        refetchAccountsBySubtypeResponse,
      }}
    >
      {props.children}
    </TaxContext.Provider>
  );
};

/*
<TaxContextProvider>
...
const capitalGainsEsimator = useContext(TaxContext);
const cgEstimate = capitalGainsEsimator(10000);
...
</TaxContextProvider>
*/
