import { Icon, IconAsset } from "components/core/icons";
import { Text, TextStyle, TextType } from "components/core/text";
import { Colour } from "core/models";

export enum ChangeInValueDirection {
  Up = 1,
  Down = 2,
}
interface Props {
  direction?: ChangeInValueDirection;
  example?: boolean;
}

export const ChangeInValueLabel: React.FC<Props> = ({
  children,
  direction,
  example,
}) => {
  const color =
    direction === ChangeInValueDirection.Up
      ? Colour.Green800
      : direction === ChangeInValueDirection.Down
      ? Colour.Red700
      : Colour.Gray600;
  const bgColor =
    direction === ChangeInValueDirection.Up
      ? Colour.Green100
      : direction === ChangeInValueDirection.Down
      ? Colour.Red100
      : Colour.Gray50;

  const render = () => {
    return (
      <div className="change-in-value-label">
        <div style={{ background: bgColor }}>
          <Text style={TextStyle.M15} colour={color} type={TextType.Div}>
            {direction && (
              <div
                style={{
                  marginRight: ".125rem",
                  display: "inline-flex",
                  alignSelf: "center",
                }}
              >
                <Icon
                  asset={
                    direction === ChangeInValueDirection.Up
                      ? IconAsset.ArrowUpToRight
                      : IconAsset.ArrowDownToRight
                  }
                  width="16px"
                  height="16px"
                  colour={color}
                />
              </div>
            )}
            {children}
          </Text>
        </div>
      </div>
    );
  };
  const renderExample = () => {
    return (
      <div className="change-in-value-label">
        <div style={{ background: Colour.Gray50 }}>
          <Text style={TextStyle.M15} colour={color} type={TextType.Div}>
            Example
          </Text>
        </div>
      </div>
    );
  };
  return example ? renderExample() : render();
};
