import * as React from "react";
import { Colour } from "core/models";
import { LeafButton, LeafButtonColour } from "components/core/leaf-button";
import { TaxContext } from "components/contexts/tax-context";
import { TaxIncomeEventType } from "core/queries/taxes";
import { TextD, TextStyle } from "components/core/text";

export const TaxSectionAddIncomeSource: React.FC = () => {
  const { saveIncomeEventFieldValue } = React.useContext(TaxContext);

  const [showIncomeSourceOptions, setShowIncomeSourceOptions] =
    React.useState<boolean>(false);

  return (
    <div
      className="flex-row add-income-source"
      style={{
        height: "60px",
        padding: ".5rem",
        borderBottom: "1px solid #eeeeee",
      }}
    >
      <button
        style={{
          fontSize: "15px",
          padding: 0,
          background: "white",
          color: "var(--blue-01)",
          border: "none",
        }}
        onClick={() => {
          setShowIncomeSourceOptions(!showIncomeSourceOptions);
        }}
      >
        + Add
      </button>
      {showIncomeSourceOptions && (
        <div className="ml1 flex-row align-center">
          <LeafButton
            buttonColour={LeafButtonColour.Transparent}
            onClick={() => {
              saveIncomeEventFieldValue(
                "amount",
                "0",
                TaxIncomeEventType.Commission
              );
              setShowIncomeSourceOptions(false);
            }}
          >
            <TextD colour={Colour.Blue01} style={TextStyle.M15SM}>
              Add Commission
            </TextD>
          </LeafButton>
          <LeafButton
            buttonColour={LeafButtonColour.Transparent}
            onClick={() => {
              saveIncomeEventFieldValue(
                "amount",
                "0",
                TaxIncomeEventType.Bonus
              );
              setShowIncomeSourceOptions(false);
            }}
          >
            <TextD colour={Colour.Blue01} style={TextStyle.M15SM}>
              Add Bonus
            </TextD>
          </LeafButton>
        </div>
      )}
    </div>
  );
};
