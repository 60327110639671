import * as React from "react";
import classnames from "classnames";
import { Icon, IconAsset } from "components/core/icons";
import { Link, useLocation } from "react-router-dom";
import { RouteProps } from "react-router";
import { Text, TextType } from "components/core/text";
import { useEffect, useState } from "react";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";

interface LeftNavSubsection {
  subsection: string;
  subsectionKey: string;
  linkTo: string;
}

export interface PublicProps {
  sectionKey: string;
  section: string;

  sectionIcon?: IconAsset;

  // singular item params
  selected?: boolean;
  linkTo?: string;

  // drop down params
  subsectionSelected?: string;
  subsections?: LeftNavSubsection[];
  defaultExpanded?: boolean;

  onClick?: () => void;
}

type Props = PublicProps & RouteProps;

const LeftNavItemComponent: React.FC<Props> = (props: Props) => {
  const mobileLayout = useMobileLayout();
  const parseLocation = (l: string) => {
    const loc = l.split("/");
    if (loc.length === 3) {
      return [loc[2].trim(), ""];
    } else if (loc.length === 4) {
      return [loc[2].trim(), loc[3].trim()];
    }
    return ["", ""];
  };

  let location = useLocation();
  const [selectedSection, selectedSubsection] = parseLocation(
    location!.pathname
  );

  useEffect(() => {
    parseLocation(selectedSection);
    setExpanded(
      (prevState: boolean) =>
        prevState ||
        selectedSection === props.sectionKey ||
        props.defaultExpanded!
    );
  }, [location, selectedSection, props.defaultExpanded, props.sectionKey]);

  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  const [expanded, setExpanded] = useState(false);

  const isSelectedSection = React.useMemo<boolean>(() => {
    const o =
      props.selected ||
      (props.linkTo &&
        (props.linkTo === location.pathname ||
          location.pathname?.includes(props?.linkTo || "")));
    return !!o;
  }, [props, location]);
  const getItemStyle = () => {
    return classnames({
      "left-nav-item": true,
      "left-nav-item-selected": isSelectedSection,
    });
  };

  const getItemContainerStyle = () => {
    return classnames({
      "left-nav-item-container": true,
      "left-nav-item-container-selected": isSelectedSection,
    });
  };

  const getSubsectionItemStyle = (subsection: string) => {
    return classnames({
      "left-nav-item__subsection-item": true,
      "left-nav-item__subsection-item-selected":
        subsection === selectedSubsection,
    });
  };

  const subsctions =
    props.subsections &&
    props.subsections.map((s) => (
      <Link key={s.subsectionKey} to={s.linkTo}>
        <div className={getSubsectionItemStyle(s.subsectionKey)}>
          <span>&#8226;</span>
          <div className="left-nav-item__subsection-item-text">
            {s.subsection}
          </div>
        </div>
      </Link>
    ));

  const renderSubsectionListItem = () => (
    <div className={getItemContainerStyle()}>
      <div
        onClick={() => {
          setExpanded(!expanded);
        }}
        className={getItemStyle()}
      >
        <div className="left-nav-item__section-header">
          <div>{props.section}</div>
        </div>
        {expanded && (
          <div className="left-nav-item__subsection-container">
            {subsctions}
          </div>
        )}
      </div>
    </div>
  );

  const iconSize = mobileLayout ? "24px" : "24px";
  const leftPadding = mobileLayout ? "1.5rem" : "1rem";
  const singularItemInnerContent = () => {
    return (
      <div className={getItemStyle()}>
        <div className="left-nav-item__section-header">
          {props.sectionIcon && (
            <>
              <Icon
                height={iconSize}
                width={iconSize}
                asset={props.sectionIcon}
                selected={isSelectedSection}
              />
              <div
                style={{
                  paddingLeft: leftPadding,
                }}
              />
            </>
          )}
          <Text type={TextType.Span}>{props.section}</Text>
        </div>
      </div>
    );
  };
  const renderSignularListItem = () => (
    <div className="left-nav-item-container">
      {props.linkTo ? (
        <Link to={props.linkTo!} onClick={() => handleOnClick()}>
          {singularItemInnerContent()}
        </Link>
      ) : (
        <div onClick={() => handleOnClick()}>{singularItemInnerContent()}</div>
      )}
    </div>
  );

  return subsctions ? renderSubsectionListItem() : renderSignularListItem();
};

export const LeftNavItem = LeftNavItemComponent;
