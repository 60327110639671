import * as React from "react";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { Spinner } from "react-bootstrap";
import { trackEvent } from "core/distribution/distribution";
import { useQuery } from "@apollo/client";
import {
  getInstitutionStatus,
  InstitutionStatusResponse,
} from "core/queries/accounts";

interface Props {
  linkError?: string;
  institutionID?: string;
  onClose: () => void;
}

export const PlaidLinkErrorHandlerModal: React.FC<Props> = ({
  institutionID,
  linkError,
  onClose,
}) => {
  const { data, loading } = useQuery<InstitutionStatusResponse>(
    getInstitutionStatus,
    {
      variables: {
        institutionID,
      },
    }
  );

  if (!loading) {
    trackEvent("plaid-event-render", {
      name: data?.plaidInstitutionStatus.name,
      error: linkError,
      status: data?.plaidInstitutionStatus.status,
    });
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "1rem",
          right: "1.5rem",
          width: "auto",
          cursor: "pointer",
        }}
        onClick={() => {
          onClose();
        }}
      >
        <Icon
          asset={IconAsset.Close}
          width="2rem"
          height="2rem"
          colour={Colour.Text02}
        />
      </div>
      <div>
        <div
          style={{
            height: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ marginBottom: 0 }}>
            Plaid Error
            <Icon
              asset={IconAsset.Dot}
              width=".25rem"
              height=".25rem"
              colour={Colour.Blue01}
            />
          </h6>
        </div>

        <div style={{ margin: "0 3rem" }}>
          <div>
            We've noticed you ran into an issue while trying to connect your
            account.
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "3rem 0",
              }}
            >
              <Spinner animation="border" size="sm" />
            </div>
          ) : (
            <>
              <div
                style={{
                  margin: "1rem 0",
                }}
              >
                Our partner Plaid is reporting that the connection to{" "}
                {<b>{data?.plaidInstitutionStatus?.name}</b>} is{" "}
                {<b>{data?.plaidInstitutionStatus?.status?.toLowerCase()}</b>}.{" "}
                {data?.plaidInstitutionStatus?.name} currently has a{" "}
                <b>{data?.plaidInstitutionStatus?.percentage}</b> connection
                success rate.
              </div>
              <div
                style={{
                  margin: "1rem 0",
                }}
              >
                We're sorry for the inconvenience. We'll monitor the status and
                send you an email when it is ready to connect.
              </div>
            </>
          )}
          <div
            style={{
              margin: "1rem 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LeafButton
              onClick={() => {
                onClose();
              }}
            >
              Finish
            </LeafButton>
          </div>
        </div>
      </div>
    </>
  );
};
