import * as React from "react";
import classNames from "classnames";
import { Colour } from "core/models";
import { DashboardPages } from "components/features/dashboard/pages";
import { DayFiltering } from "components/features/dashboard/components/investments-summary-section/models";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton } from "components/core/leaf-button";
import { LinkType, PylonLink } from "components/core/link";
import { NetworthHistory } from "core/queries/overview";
import { Text, TextType } from "components/core/text";
import { toMoneyStringNoDecimal } from "core/utils";
import {
  ChangeTypeFiltering,
  DataTypeFiltering,
  InvestmentSummaryContext,
  investmentTypeToValue,
} from "components/features/dashboard/components/investments-summary-section/context/investments-summary-context";
// import "./styles.css";

interface Props {}

export const InvestmentSummary: React.FC<Props> = () => {
  const { portfolioData, days, dataType, changeType } = React.useContext(
    InvestmentSummaryContext
  );

  const accHistory = portfolioData?.portfolioNetworthHistory?.filter((v) => {
    if (dataType === DataTypeFiltering.All) {
      return v.accountID === "Total Investments";
    } else {
      return v.accountID !== "Total Investments";
    }
  });

  const changeStyles = (change: number) =>
    classNames({
      "green-01": change > 0,
      "red-01": change < 0,
    });
  const renderEmptyState = () => (
    <div style={{ height: "20rem" }}>
      <div
        style={{
          margin: "4rem auto",
          width: "6rem",
          height: "6rem",
          borderRadius: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon
          asset={IconAsset.AddAccountEmpty}
          height={"64px"}
          width={"64px"}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{ textAlign: "center", marginBottom: "2rem", width: "50%" }}
        >
          <Text
            type={TextType.Div}
            weight={500}
            colour={Colour.Navy}
            size={"1rem"}
          >
            Add accounts to get started
          </Text>
        </div>
        <PylonLink linkType={LinkType.NoStyle} to={DashboardPages.Integrations}>
          <LeafButton>Add Accounts</LeafButton>
        </PylonLink>
      </div>
    </div>
  );

  const valueSorter = (
    a: { accountID?: string; history?: NetworthHistory[] },
    b: { accountID?: string; history?: NetworthHistory[] }
  ) => {
    const aNetworthChange =
      investmentTypeToValue(dataType, a?.history?.[0]) -
      investmentTypeToValue(dataType, a?.history?.[a?.history?.length - 1]);
    const bNetworthChange =
      investmentTypeToValue(dataType, b?.history?.[0]) -
      investmentTypeToValue(dataType, b?.history?.[b?.history?.length - 1]);
    return bNetworthChange - aNetworthChange;
  };

  const percentSorter = (
    a: { accountID?: string; history?: NetworthHistory[] },
    b: { accountID?: string; history?: NetworthHistory[] }
  ) => {
    const aNetworthChange =
      investmentTypeToValue(dataType, a?.history?.[0]) -
      investmentTypeToValue(dataType, a?.history?.[a?.history?.length - 1]);
    const aPercentChange =
      (aNetworthChange / investmentTypeToValue(dataType, a?.history?.[0])) *
      100;
    const bNetworthChange =
      investmentTypeToValue(dataType, b?.history?.[0]) -
      investmentTypeToValue(dataType, b?.history?.[b?.history?.length - 1]);
    const bPercentChange =
      (bNetworthChange / investmentTypeToValue(dataType, b?.history?.[0])) *
      100;
    return bPercentChange - aPercentChange;
  };
  const hasDelayWarning =
    days === DayFiltering.SevenDays || days === DayFiltering.Today;

  const renderSummary = () => (
    <div
      style={{ height: hasDelayWarning ? "20rem" : "32rem", overflowY: "auto" }}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <Text
          type={TextType.Div}
          weight={500}
          colour={Colour.Navy}
          size={"1.125rem"}
        >
          Change over last {days}
        </Text>
      </div>
      {accHistory &&
        accHistory
          .sort(
            changeType === ChangeTypeFiltering.Value
              ? valueSorter
              : percentSorter
          )
          .map((o: { accountID?: string; history?: NetworthHistory[] }) => {
            const networthChange =
              investmentTypeToValue(dataType, o?.history?.[0]) -
              investmentTypeToValue(
                dataType,
                o?.history?.[o?.history?.length - 1]
              );
            const percent =
              (networthChange /
                investmentTypeToValue(
                  dataType,
                  o?.history?.[o?.history?.length - 1]
                )) *
              100;
            const percentChange = `${
              (percent < 1 && percent > 0) || (percent > -1 && percent < 0)
                ? percent.toFixed(2)
                : Math.round(percent)
            }%`;
            const renderChange = () => {
              switch (changeType) {
                case ChangeTypeFiltering.Value:
                  return (
                    networthChange !== 0 && (
                      <>
                        <span
                          className={networthChange > 0 ? "green-01" : "red-01"}
                          style={{ fontWeight: "bold" }}
                        >
                          {networthChange > 0 && <>&uarr;</>}
                          {networthChange < 0 && <>&darr;</>}
                          {toMoneyStringNoDecimal(networthChange)}
                        </span>
                      </>
                    )
                  );
                case ChangeTypeFiltering.Percent:
                  return (
                    networthChange !== 0 && (
                      <>
                        <span
                          className={networthChange > 0 ? "green-01" : "red-01"}
                          style={{ fontWeight: "bold" }}
                        >
                          {networthChange > 0 && <>&uarr;</>}
                          {networthChange < 0 && <>&darr;</>}
                          {percentChange}
                        </span>
                      </>
                    )
                  );
              }
            };

            return (
              networthChange !== 0 && (
                <div className="subsection-line-item" key={o.accountID}>
                  <Text
                    type={TextType.Div}
                    weight={400}
                    colour={Colour.Gray70}
                    size={"0.825rem"}
                    ellipsis
                  >
                    {o.accountID}
                  </Text>
                  <span
                    className={changeStyles(networthChange)}
                    style={{ fontWeight: "bold" }}
                  >
                    {renderChange()}
                  </span>
                </div>
              )
            );
          })}
    </div>
  );

  return (
    <div className="networth-summary">
      {!accHistory?.length ? renderEmptyState() : renderSummary()}
      {hasDelayWarning && (
        <div
          style={{
            margin: "0.75rem 0.5rem",
            padding: "0.5rem",
            background: "white",
            borderRadius: "4px",
          }}
        >
          <Text
            type={TextType.Div}
            weight={400}
            colour={Colour.Gray70}
            size={"0.825rem"}
          >
            Balance updates may be delayed up to 24 hours depending on your
            brokerage provider.
          </Text>
        </div>
      )}
    </div>
  );
};
