import Icons from "./icons.svg";
import React from "react";
import { Colour } from "core/models";
import { Icon, IconAsset } from "components/core/icons";
import { SuggestableRelationship } from "components/features/dashboard/pages/contacts/models";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import "./styles.css";

export enum RelationshipIcon {
  Spouse = "spouse",
  Accountant = "accountant",
}

export interface Props {
  relationship: SuggestableRelationship;
  icon: RelationshipIcon;
  title: string;
  description: string;
  headerText?: string;

  onHideSuggestion: (relationship: SuggestableRelationship) => void;
  onSuggestionClick: (relationship: SuggestableRelationship) => void;
}

export const SuggestedContactCard: React.FC<Props> = ({
  relationship,
  icon,
  title,
  description,
  headerText,
  onHideSuggestion,
  onSuggestionClick,
}) => {
  return (
    <div className="suggested-contacts">
      <div
        className="suggested-contacts-cross-out"
        onClick={() => onHideSuggestion(relationship)}
      >
        <Icon asset={IconAsset.SmallCross} height="8.5" width="8.5" />
      </div>
      <div className="flex-row suggested-contacts__contact-wrapper">
        <div className="suggested-contacts__icon-background">
          <svg height="55.13" width="55.13">
            <use xlinkHref={`${Icons}#${icon}`} />
          </svg>
        </div>
        <div
          className="flex-column"
          style={{
            justifyContent: "center",
          }}
        >
          <Text type={TextType.Div} style={TextStyle.B18} weight="bold">
            Add {headerText ?? relationship}
          </Text>
          <div
            id={`suggested-contact-invite-${relationship}`}
            className="suggested-contact-invite"
            onClick={() => onSuggestionClick(relationship)}
          >
            <Text
              type={TextType.Div}
              style={TextStyle.O10}
              colour={Colour.Blue01}
              format={TextFormat.UpperCase}
            >
              Invite
            </Text>
          </div>
        </div>
      </div>
      <div
        className="flex-column"
        style={{
          overflow: "hidden",
        }}
      >
        <div
          style={{
            paddingTop: "2rem",
          }}
        />
        <Text type={TextType.Div} style={TextStyle.B14} weight={700}>
          {title}
        </Text>
        <Text type={TextType.Div} style={TextStyle.B14}>
          {description}
        </Text>
      </div>
    </div>
  );
};
