import { gql } from "@apollo/client";

export const REFRESH_SESSION_MUTATION = gql`
  mutation RefreshUserSession {
    refreshSession {
      authToken
    }
  }
`;

export interface RefreshSessionResponse {
  refreshSession: {
    authToken: string;
  };
}
