import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropDownMenuItem } from "components/core/drop-down-menu-item";
import { PylonDropDownToggle } from "components/core/drop-down-toggle";
import { UserData } from "components/features/dashboard/models/sharing";
import "./styles.css";

interface Props {
  users: UserData[];
  setSelectUserID: (userID: string) => void;
}
export const SharedCreditPicker: React.FC<Props> = ({
  users,
  setSelectUserID,
}) => {
  const [currentUserIdx, setCurrentUserIdx] = React.useState<number>(0);
  const dataTypeMenuItems = users.map((u, i) => (
    <DropDownMenuItem
      text={
        <>
          {u?.firstName || u?.email}
          {i === 0 ? " (me)" : ""}
        </>
      }
      onClick={() => {
        let uidx = users.findIndex((x) => x.id === u.id);
        setCurrentUserIdx(uidx);
        setSelectUserID(u.id);
        document.dispatchEvent(new MouseEvent("click"));
      }}
    />
  ));

  return (
    <>
      <Dropdown alignRight>
        <DropdownToggle as="div" bsPrefix="pylon-dropdown-toggle">
          <PylonDropDownToggle
            text={
              <>
                {users[currentUserIdx]?.firstName ||
                  users[currentUserIdx]?.email}
                {currentUserIdx === 0 ? " (Me)" : ""}
              </>
            }
          />
        </DropdownToggle>
        <DropdownMenu>
          <div
            style={{
              width: "15rem",
              position: "absolute",
              zIndex: 1000,
              cursor: "pointer",
              backgroundColor: "white",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.24)",
              borderRadius: "2px",
              top: "0",
              right: "0",
            }}
          >
            {dataTypeMenuItems}
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
