import * as React from "react";
import classnames from "classnames";
import { Account, Colour } from "core/models";
import { AccountIcon } from "../account-icon";
import { AccountTableContext } from "components/contexts/account-table-context";
import { Icon, IconAsset } from "components/core/icons";
import { IntegrationSource } from "components/features/dashboard/models/integration-source";
import { isAccountConnected } from "components/features/dashboard/components/add-account-tray/models";
import { ManageAccountOptions } from "components/features/dashboard/components/manage-account-menu";
import { millisecondsReadable, toMoneyString } from "core/utils/format";
import { PlaidUpdateLink } from "../plaid-update-link";
import { RowCardRecipients } from "components/features/dashboard/components/row-card-recipients";
import { SessionContext } from "components/contexts/session-context-provider";
import { Text, TextType } from "components/core/text";
import { ViewAccountMenu } from "components/features/dashboard/components/view-account-menu";
import "./styles.css";

export interface PublicProps {
  account: Account;
  title: string;
  balance?: string;
  name?: string;
  hideDetails?: boolean;
  draggable?: boolean;

  openAddMenu?: () => void;
}

export const RowCard: React.FC<PublicProps> = (props: PublicProps) => {
  const { userID } = React.useContext(SessionContext.context);
  const { onSelectAccount } = React.useContext(AccountTableContext);
  const isConnected = isAccountConnected(props.account.integration_source);
  const exampleAccount =
    props.account.integration_source === IntegrationSource.Example;
  const isSharedWithMe = props.account.owner?.id !== userID && !exampleAccount;
  const oneDayChange =
    (props.account.oneDayChange || 0) *
    (props.account.typeProperties?.signMultiplier || 1);

  const rowCardClasses = classnames({
    "row-card-wrapper": true,
    selectable: !exampleAccount,
    draggable: props.draggable && !exampleAccount,
    "example-row": exampleAccount,
  });
  return (
    <div
      data-selector={exampleAccount ? `example-account-row` : `account-row`}
      className={rowCardClasses}
    >
      {props.draggable && (
        <Icon
          asset={IconAsset.Reorder}
          height={"16px"}
          width={"10px"}
          svgClass="drag-handle"
        />
      )}
      <div
        className="row-card__linkable-area table-row"
        onClick={() => {
          onSelectAccount?.(props.account.account_id);
        }}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <>
          {" "}
          <div className="row-card__content table-cell flex-row">
            <div className="row-card__title" style={{ marginRight: ".5rem" }}>
              <AccountIcon account={props.account} />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="row-card__content-title-text"
                style={{ marginRight: "0.5rem" }}
              >
                {props.account.name}
              </div>
              {exampleAccount && (
                <div
                  className="row-card__content-subtext official-name ellipsis "
                  style={{ marginTop: "0.25rem" }}
                >
                  <Text
                    type={TextType.Div}
                    size={"9px"}
                    lineHeight="12px"
                    colour={Colour.Gray70}
                  >
                    EXAMPLE
                  </Text>
                </div>
              )}
            </div>
            <div className="row-card__content-subtext-wrapper">
              {props.account.official_name && (
                <div className="row-card__content-subtext official-name ellipsis ">
                  {props.account.official_name}
                  {props.account.mask && ` **${props.account.mask}`}
                </div>
              )}
              {!props.account.official_name && props.account.subtype && (
                <div className="row-card__content-subtext flex-no-grow-shrink ">
                  {props.account.subtype[0].toUpperCase() +
                    props.account.subtype.substring(1)}
                  {props.account.mask && ` ending in ${props.account.mask}`}
                </div>
              )}
              <>
                <div className="row-card__content-subtext-divider">{" · "}</div>
                {isSharedWithMe && (
                  <>
                    <div className="row-card__content-subtext-shared-by flex-no-grow-shrink">
                      <div className="row-card__content-subtext-shared-by-text">
                        Shared by
                      </div>
                      <div className="row-card__content-subtext-shared-by-name">
                        {props.account.owner?.firstName}{" "}
                        {props.account.owner?.lastName}
                      </div>
                    </div>
                    <div className="row-card__content-subtext-divider">
                      {" · "}
                    </div>
                  </>
                )}

                <div className="row-card__content-subtext last-updated flex-no-grow-shrink ">
                  {props.account.modified_at
                    ? `Last updated ${millisecondsReadable(
                        new Date().getTime() -
                          new Date(
                            parseInt(props.account.modified_at) * 1000
                          ).getTime()
                      )} ago`
                    : "Last updated 1 minute ago"}
                </div>
                {!isSharedWithMe &&
                  props.account.recipients &&
                  props.account.recipients.length > 0 &&
                  !props.hideDetails && (
                    <div className="flex-no-grow-shrink ">
                      <RowCardRecipients
                        recipients={props.account.recipients}
                      />
                    </div>
                  )}
              </>
            </div>

            {props.account.loginRequired && (
              <PlaidUpdateLink forItemID={props.account.integrationItemID} />
            )}
          </div>
          <div className="table-cell owner">
            {isSharedWithMe
              ? props.account.owner?.firstName || props.account.owner?.email
              : "Me"}
          </div>
          {!props.hideDetails &&
            (props.account.balances.available != null ||
              props.account.balances.current != null) && (
              <div className="row-card__balance table-cell value">
                <div className="card__balance" style={{ textAlign: "right" }}>
                  {toMoneyString(
                    props.account.balances.current !== undefined
                      ? props.account.balances.current
                      : props.account.balances.available,
                    props.account.typeProperties?.signMultiplier
                  )}
                  {oneDayChange != null && oneDayChange !== 0 && (
                    <div
                      style={{
                        color:
                          oneDayChange > 0
                            ? "var(--green-01)"
                            : "var(--red-01)",
                      }}
                      className="card__one-day-change"
                    >
                      {toMoneyString(oneDayChange)}
                      {oneDayChange > 0 && <>&uarr;</>}
                      {oneDayChange < 0 && <>&darr;</>}
                    </div>
                  )}
                </div>
              </div>
            )}
        </>
      </div>

      {!props.hideDetails && (
        <div
          className="row-card__more"
          onClick={(e) => {
            e.stopPropagation(); // Prevent the click from opening up account details
          }}
        >
          {isSharedWithMe ? (
            <ViewAccountMenu
              isConnected={isConnected}
              account={props.account}
            />
          ) : (
            <ManageAccountOptions
              account={props.account}
              isConnected={isConnected}
            />
          )}
        </div>
      )}
    </div>
  );
};
