import React, { useContext } from "react";
import { Input } from "components/core/input";
import { ShareAccounts } from "components/features/dashboard/components/add-new-trusted-contact/accounts";
import { TrustedSourceContext } from "components/contexts/trusted-source-context";
import "./styles.css";

interface ControlProps {
  valid: boolean;
  goNext: () => void;
}
export const AddTrustedContactName: React.FC<ControlProps> = ({
  valid,
  goNext,
}) => {
  const { state, updateState } = useContext(TrustedSourceContext);

  return (
    <div>
      <div className="add-account-tray__step-action">
        <h5>
          What is your collaborator's full name
          <span className="text-highlight-blue">?</span>
          <br />
          <span className="text-highlight-blue">＿</span>
        </h5>
        <div>
          <Input
            autoFocus
            type="firstName"
            id="firstName"
            name="firstName"
            placeholder="First Name"
            value={state.firstName}
            onEnter={() => {
              if (valid) {
                goNext();
              }
            }}
            onChange={(e) => {
              updateState({
                firstName: e.target.value,
              });
            }}
          />
        </div>
        <div className="mt1">
          <Input
            type="lastName"
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            value={state.lastName}
            onEnter={() => {
              if (valid) {
                goNext();
              }
            }}
            onChange={(e) => {
              updateState({
                lastName: e.target.value,
              });
            }}
            onBlurValidate={[
              {
                validationFunc: () => state.lastName !== "",
                errorMessage: "Please enter a valid last name",
              },
            ]}
          />
        </div>
      </div>

      <ShareAccounts />
    </div>
  );
};
