import * as React from "react";
import qs from "qs";
import { DocumentFolder } from "components/features/dashboard/components/document-folder";

export const DocumentFolderPage: React.FC = () => {
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  const id = query["folderID"] as string;
  const type = query["folderType"] as string;

  return <DocumentFolder folderID={id} folderType={type} />;
};
