import { IntegrationSource } from "components/features/dashboard/models/integration-source";

export const isAccountConnected = (integrationSource?: string) => {
  if (!integrationSource) {
    return false;
  }
  return (
    integrationSource.toUpperCase() === IntegrationSource.Plaid ||
    integrationSource.toUpperCase() === IntegrationSource.Savvy ||
    integrationSource.toUpperCase() === IntegrationSource.Coinbase ||
    integrationSource.toUpperCase() === IntegrationSource.CoinbasePro
  );
};

// creating a temp enum here to remove some types for add account empty state
export enum TempAccountTypes {
  Banking = "Banking",
  CreditCard = "Credit Card",
  Retirement = "Retirement",
  NonRetirement = "Investments & Stocks",
  HomeAndRentalProperty = "Property",
  Insurance = "Insurance",
  Loans = "Loans",
  FullyPaidAssets = "Fully Paid Assets",
  // PersonallyGuaranteedBusinessLoans = "Personally Guaranteed Business Loans",
  // DigitalWallet = "Digital Wallet"
  // Taxes = "Taxes",
  // Payroll = "Payroll",
}
export enum AccountType {
  Banking = "Banking",
  CreditCard = "Credit Card",
  Retirement = "Retirement",
  NonRetirement = "Investments & Stocks",
  HomeAndRentalProperty = "Property",
  Insurance = "Insurance",

  DigitalWallet = "Digital Wallet",
  Loans = "Loans",
  PersonallyGuaranteedBusinessLoans = "Personally Guaranteed Business Loans",
  Taxes = "Taxes",
  FullyPaidAssets = "Fully Paid Assets",
  Payroll = "Payroll",
}

export const AccountTypeValueToKeyLookup: Record<string, AccountType> = {};

for (const t of Object.keys(AccountType)) {
  const k = t as keyof typeof AccountType;
  AccountTypeValueToKeyLookup[k as string] = AccountType[k];
}

export enum AccountSubtypeEnum {
  Mortgage = "Mortgage",
  CheckingAccounts = "Checking Accounts",
  SavingsAccounts = "Savings Accounts",
  MoneyMarketAccounts = "Money Market Accounts",
  PrivateStock = "Private Stock",
  CD = "Certificates of Deposits (CDs)",
  HomeEquityLineOfCredit = "Home Equity Line of Credit",
  HomeEquityLoan = "Home Equity Loan",
  HomeConstruction = "Home Construction",
  CarInsurance = "Car Insurance",
  Cryptocurrency = "Cryptocurrency",
  HomeInsurance = "Home Insurance",
  HealthInsurance = "Health Insurance",
  LifeInsurance = "Life Insurance",
  ReverseMortgage = "Reverse Mortgage",
  RentalProperty = "Rental Property",
  PrivateCompany = "Private Company",
  BrokageTradingAccount = "Brokerage/Trading Account",
}

export const SubtypesWithCalculatedBalance: string[] = [
  AccountSubtypeEnum.PrivateStock,
  AccountSubtypeEnum.Cryptocurrency,
];

export const AccountSubType = {
  [AccountType.Banking]: {
    [AccountSubtypeEnum.CheckingAccounts]: "Checking Accounts",
    [AccountSubtypeEnum.SavingsAccounts]: "Savings Accounts",
    [AccountSubtypeEnum.MoneyMarketAccounts]: "Money Market Accounts",
    [AccountSubtypeEnum.CD]: "Certificates of Deposits (CDs)",
  },
  [AccountType.CreditCard]: {
    "Standard Credit Card": "Standard Credit Card",
    "Charge Cards": "Charge Cards",
    // "Balance Transfer Card": "Balance Transfer Card",
    // "Reward Credit Cards": "Reward Credit Cards",
    // "Secured Credit Cards": "Secured Credit Cards",
    // "Subprime Credit Cards": "Subprime Credit Cards",
    // "Prepaid Cards": "Prepaid Cards",
    // "Business Credit Card": "Business Credit Card",
  },
  [AccountType.DigitalWallet]: {
    Cryptocurrency: "Cryptocurrency", // This is treated as AccountType.NonRetirement['Cryptocurrency'] in backend, duplicated entry so it shows up under different menu
    "Transfer Account (Venmo, Paypal, etc)":
      "Transfer Account (Venmo, Paypal, etc)",
  },
  [AccountType.Retirement]: {
    "401k": "401k",
    "403b": "403b",
    "457b": "457b",
    IRA: "IRA",
    "Roth IRA": "Roth IRA",
    "Roth 401k": "Roth 401k",
    "Simple IRA": "Simple IRA",
    "SEP IRA": "SEP IRA",
    HSA: "HSA",
    Pension: "Pension",
    Annuity: "Annuity",
  },
  [AccountType.NonRetirement]: {
    "Brokerage/Trading Account": "Brokerage/Trading Account",
    "Private Stock": "Private Stock",
    "Private Company": "Private Company",
    "Fund Carry (Private Investing)": "Fund Carry (Private Investing)",
    "Education Account (529 savings plan)":
      "Education Account (529 savings plan)",
    Bonds: "Bonds",
    Cryptocurrency: "Cryptocurrency",
  },
  [AccountType.Insurance]: {
    "Life Insurance": "Life Insurance",
    "Home Insurance": "Home Insurance",
    "Car Insurance": "Car Insurance",
    "Health Insurance": "Health Insurance",
  },
  [AccountType.HomeAndRentalProperty]: {
    [AccountSubtypeEnum.Mortgage]: "Mortgage",
    [AccountSubtypeEnum.HomeEquityLineOfCredit]: "Home Equity Line of Credit",
    [AccountSubtypeEnum.HomeEquityLoan]: "Home Equity Loan",
    "Home Construction": "Home Construction",
    "Reverse Mortgage": "Reverse Mortgage",
    [AccountSubtypeEnum.RentalProperty]: "Rental Property",
  },
  [AccountType.Loans]: {
    "Car Loan": "Car Loan",
    "Other Vehicle Type Loan": "Other Vehicle Type Loan",
    "Student Loan": "Student Loan",
    "Government Loan": "Government Loan",
    "Personal Loan - Unsecured": "Personal Loan - Unsecured",
    "Personal Loan - Secured": "Personal Loan - Secured",
    "Fixed Rate Loan": "Fixed Rate Loan",
    "Adjustable Rate Loan": "Adjustable Rate Loan",
    "Friends/Family Loan": "Friends/Family Loan",
  },
  [AccountType.PersonallyGuaranteedBusinessLoans]: {
    "Term loans": "Term loans",
    "Lines of Credit": "Lines of Credit",
    "SBA Loans": "SBA Loans",
    "Equipment Financing": "Equipment Financing",
    "Invoice Financing": "Invoice Financing",
    "Commercial real estate loans": "Commercial real estate loans",
    Microloans: "Microloans",
    "Merchant Cash Advances": "Merchant Cash Advances",
  },
  [AccountType.Taxes]: {
    "Prepaid Taxes": "Prepaid Taxes",
  },
  [AccountType.FullyPaidAssets]: {
    Property: "Property",
    Vehicles: "Vehicles",
    Jewelry: "Jewelry",
    Paintings: "Paintings",
    Furniture: "Furniture",
    Electronics: "Electronics",
    Other: "Other",
  },
  [AccountType.Payroll]: {
    W2: "W2",
    "1099": "1099",
    Other: "Other,",
  },
};

interface AccountColors {
  Primary: string;
  Light: string;
}

export const AccountColorHex = {
  [AccountType.Banking]: { Primary: "#0C98D5", Light: "#e4f1f7" }, // blue
  [AccountType.CreditCard]: { Primary: "#12BD89", Light: "#ddebe6" }, // green
  [AccountType.DigitalWallet]: { Primary: "#8546E0", Light: "#eae4f2" }, // purple
  [AccountType.Retirement]: { Primary: "#FE850C", Light: "#f7e7d7" }, // oragne
  [AccountType.NonRetirement]: { Primary: "#FE850C", Light: "#f7e7d7" }, // yellow
  [AccountType.Insurance]: { Primary: "#DD3A9C", Light: "#f5daea" }, // pink
  [AccountType.HomeAndRentalProperty]: { Primary: "#F35151", Light: "#f5dada" }, // red
  [AccountType.Loans]: { Primary: "#3b3bed", Light: "#bcbceb" },
  [AccountType.PersonallyGuaranteedBusinessLoans]: {
    Primary: "#d3eddb",
    Light: "#2aeb65",
  },
  [AccountType.Taxes]: { Primary: "#d2e827", Light: "#e7ebc7" },
  [AccountType.FullyPaidAssets]: { Primary: "#f0c426", Light: "#ebe3c7" },
  [AccountType.Payroll]: { Primary: "#1fffff", Light: "#dcf5f5" },
};

export const getColorForType = (type: AccountType): AccountColors => {
  if (!(type in AccountColorHex)) {
    return { Primary: "#0C98D5", Light: "#e4f1f7" };
  }
  return AccountColorHex[type];
};

const UnconnectableAccountTypes: string[] = [
  AccountType.FullyPaidAssets,
  AccountType.Payroll,
  AccountType.Taxes,
  AccountType.Insurance,
];

const UnconnectableAccountSubTypes: string[] = [
  AccountSubType[AccountType.NonRetirement]["Private Stock"],
  AccountSubType[AccountType.NonRetirement]["Private Company"],
];

export const IsAccountTypeConnectable = (
  accType: string,
  accSubType?: string
): boolean => {
  if (accSubType && UnconnectableAccountSubTypes.includes(accSubType)) {
    return false;
  }

  if (UnconnectableAccountTypes.includes(accType)) {
    return false;
  }
  return true;
};
