import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { PersonalBalanceSheet } from "components/features/dashboard/components/personal-balance-sheet";

export const BalanceSheetPage: React.FC = () => {
  return (
    <PageWrapper>
      <div
        style={{
          maxWidth: "45rem",
        }}
      >
        <PersonalBalanceSheet />
      </div>
    </PageWrapper>
  );
};
