import PngLanding1 from "core/assets/landing-taxes-1.png";
import PngLanding2 from "core/assets/landing-taxes-2.png";
import { LandingPageDefinition } from "components/features/landing";
import { RegisterButton } from "components/features/landing/components/register";
import { StateTaxDifference } from "components/features/landing/components/diff-state-taxes";
import { Text, TextStyle, TextType } from "components/core/text";
import { useMobileLayout } from "core/hooks/responsive";
import "../all-landing.css";
import "./styles.css";

export const LandingPageTaxes1: LandingPageDefinition = {
  Path: "/landing/taxes/calculate-your-federal-and-state-tax",
  Component: () => {
    return (
      <div className="taxes-1">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <h1 className="title-line-2">
              Calculate your Federal and State Tax.
            </h1>
            <div>
              <RegisterButton optionalParam="skip=true" />
            </div>
          </div>
          <div className="landing-right">
            <img src={PngLanding1} width="100%" alt="Income Taxes" />
          </div>
        </div>
      </div>
    );
  },
};

export const LandingPageTaxes1a: LandingPageDefinition = {
  Path: "/landing/taxes/calculate-your-federal-and-state-tax-a",
  Component: () => {
    return (
      <div className="taxes-1">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <h1 className="title-line-2">
              Calculate your Federal and State Tax.
            </h1>
            <div>
              <RegisterButton optionalParam="to=true" />
            </div>
          </div>
          <div className="landing-right">
            <img src={PngLanding1} width="100%" alt="Income Taxes" />
          </div>
        </div>
      </div>
    );
  },
};

export const LandingPageTaxes2: LandingPageDefinition = {
  Path: "/landing/taxes/figure-out-your-taxes-fast-and-easy-with-pylon",
  Component: () => {
    const mobileLayout = useMobileLayout();
    return (
      <div className="taxes-2">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <div className="title-line-1">Taxes made simple</div>
            <h1 className="title-line-2">
              Figure out your taxes fast and easy with Pylon.
            </h1>
            {!mobileLayout && (
              <div>
                <RegisterButton optionalParam="skip=true" />
              </div>
            )}
          </div>
          <div className="landing-right">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
          {mobileLayout && (
            <div>
              <RegisterButton optionalParam="skip=true" />
            </div>
          )}
        </div>
      </div>
    );
  },
};

export const LandingPageTaxes2a: LandingPageDefinition = {
  Path: "/landing/taxes/figure-out-your-taxes-fast-and-easy-with-pylon-a",
  Component: () => {
    return (
      <div className="taxes-2">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <div className="title-line-1">Taxes made simple</div>
            <h1 className="title-line-2">
              Figure out your taxes fast and easy with Pylon.
            </h1>
            <div>
              <RegisterButton optionalParam="to=true" />
            </div>
          </div>
          <div className="landing-right">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
        </div>
      </div>
    );
  },
};

export const LandingPageTaxes3: LandingPageDefinition = {
  Path: "/landing/taxes/track-your-robinhood-taxes-real-time",
  Component: () => {
    return (
      <div className="taxes-3">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <h1 className="title-line-2">
              Track your Robinhood taxes real-time.
            </h1>
            <div>
              <RegisterButton optionalParam="skip=true" />
            </div>
          </div>
          <div className="landing-right">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
        </div>
      </div>
    );
  },
};

export const LandingPageTaxes3a: LandingPageDefinition = {
  Path: "/landing/taxes/track-your-robinhood-taxes-in-real-time",
  Component: () => {
    return (
      <div className="taxes-3 taxes-3a">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <h1 className="title-line-2">
              Track your capital gains and trading taxes with Pylon.
            </h1>
            <div>
              <RegisterButton optionalParam="skip=true" />
            </div>
          </div>
          <div className="landing-right">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
        </div>
      </div>
    );
  },
};

export const LandingPageTaxes3b: LandingPageDefinition = {
  Path: "/landing/taxes/track-your-robinhood-taxes-in-real-time-a",
  Component: () => {
    return (
      <div className="taxes-3 taxes-3a">
        <div className="flex-row between mobile-flex-column">
          <div className="landing-left">
            <h1 className="title-line-2">
              Track your capital gains and trading taxes with Pylon.
            </h1>
            <div>
              <RegisterButton optionalParam="to=true" />
            </div>
          </div>
          <div className="landing-right">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
        </div>
      </div>
    );
  },
};

export const LandingPageTaxes9: LandingPageDefinition = {
  Path: "/landing/taxes/find-out-state-tax-differences",
  Component: () => {
    const mobileLayout = useMobileLayout();

    return (
      <div
        style={{
          background: "white",
        }}
      >
        <div className="flex-row between mobile-flex-column">
          <div>
            <div className="title-summary mt3">Taxes made simple.</div>
            <h1
              className="title-line-2 mt1"
              style={{
                maxWidth: "40.5rem",
              }}
            >
              Thinking about moving to a different state
              <span
                style={{
                  color: "var(--blue-01)",
                }}
              >
                ?
              </span>
            </h1>
            <div className="mt2">
              <Text type={TextType.Div} style={TextStyle.S20}>
                Find out your tax implications below.
              </Text>
            </div>
          </div>
        </div>
        <div className="mt3">
          <StateTaxDifference />
        </div>

        <div className="mt5">
          <RegisterButton alwaysCenter optionalParam="skip=true" />
        </div>

        {!mobileLayout && (
          <div className="mt2">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
        )}
      </div>
    );
  },
};

export const LandingPageTaxes9a: LandingPageDefinition = {
  Path: "/landing/taxes/find-out-differences-in-state-tax",
  Component: () => {
    const mobileLayout = useMobileLayout();

    return (
      <div
        style={{
          background: "white",
        }}
      >
        <div className="flex-row between mobile-flex-column">
          <div>
            <div className="title-summary mt3">Taxes made simple.</div>
            <h1
              className="title-line-2 mt1"
              style={{
                maxWidth: "40.5rem",
              }}
            >
              Thinking about moving to a different state
              <span
                style={{
                  color: "var(--blue-01)",
                }}
              >
                ?
              </span>
            </h1>
            <div className="mt2">
              <Text type={TextType.Div} style={TextStyle.S20}>
                Find out your tax implications below.
              </Text>
            </div>
          </div>
        </div>
        <div className="mt3">
          <StateTaxDifference />
        </div>

        <div
          style={{
            justifySelf: "center",
            alignSelf: "center",
            padding: "2rem 0",
          }}
        >
          <RegisterButton alwaysCenter optionalParam="skip=true" />
        </div>

        {!mobileLayout && (
          <div className="mt2">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
        )}
      </div>
    );
  },
};

export const LandingPageTaxes9b: LandingPageDefinition = {
  Path: "/landing/taxes/find-out-differences-in-state-tax-a",
  Component: () => {
    const mobileLayout = useMobileLayout();

    return (
      <div
        style={{
          background: "white",
        }}
      >
        <div className="flex-row between mobile-flex-column">
          <div>
            <div className="title-summary mt3">Taxes made simple.</div>
            <h1
              className="title-line-2 mt1"
              style={{
                maxWidth: "40.5rem",
              }}
            >
              Thinking about moving to a different state
              <span
                style={{
                  color: "var(--blue-01)",
                }}
              >
                ?
              </span>
            </h1>
            <div className="mt2">
              <Text type={TextType.Div} style={TextStyle.S20}>
                Find out your tax implications below.
              </Text>
            </div>
          </div>
        </div>
        <div className="mt3">
          <StateTaxDifference />
        </div>

        <div
          style={{
            justifySelf: "center",
            alignSelf: "center",
            padding: "2rem 0",
          }}
        >
          <RegisterButton alwaysCenter optionalParam="to=true" />
        </div>

        {!mobileLayout && (
          <div className="mt2">
            <img src={PngLanding2} width="100%" alt="Income Taxes" />
          </div>
        )}
      </div>
    );
  },
};
