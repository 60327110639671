import * as React from "react";
import { PrivacyPage } from "components/features/public/privacy";
import { PublicHeader } from "components/features/public/header";
import { PublicMobileNavigation } from "components/features/login/components/mobile-navigation";
import { PylonRoute } from "pages";
import { Route, Switch } from "react-router";
import { TermsPage } from "components/features/public/terms";
import { useMobileLayout } from "core/hooks/responsive";
import { WithBackground } from "components/features/public/with-background";
import "./styles.css";

export const PublicPageLinks = {
  Term: "/public/terms",
  Privacy: "/public/privacy",
};

export const PublicPage: React.FC<{}> = () => {
  const mobileLayout = useMobileLayout();
  return (
    <div className="public-page-wrapper">
      {mobileLayout ? (
        <PublicMobileNavigation redirectToHome={true} />
      ) : (
        <PublicHeader />
      )}
      <WithBackground>
        <div className="public-page-content-wrapper">
          <Switch>{renderRoutes()}</Switch>
        </div>
      </WithBackground>
    </div>
  );
};

const renderRoutes = () => {
  const routes: PylonRoute[] = [
    {
      index: 1,
      route: PublicPageLinks.Privacy,
      component: PrivacyPage,
      exact: true,
    },
    {
      index: 0,
      route: PublicPageLinks.Term,
      component: TermsPage,
      exact: true,
    },
  ];

  return routes.map((route) => (
    <Route
      exact={route.exact}
      key={route.index}
      path={route.route}
      component={route.component}
    />
  ));
};
