import qs from "qs";
import React, { useCallback, useMemo, useState } from "react";
import { Colour } from "core/models";
import { EditableBeneficiaryCard } from "components/features/dashboard/pages/contacts/manage-beenficiary-card";
import { Icon, IconAsset } from "components/core/icons";
import { LeafButton, LeafButtonStyle } from "components/core/leaf-button";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { SideTrayContext } from "../../../../contexts/side-tray-context-provider";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import { usePreferenceSettingBooleans } from "core/hooks/user-preference-setting";
import { useQuery } from "@apollo/client";
import { UserPreferenceSettingEnum } from "core/queries/user-preference-settings";
import "./styles.css";
import {
  ManageCollaboratorHeader,
  ManageCollaboratorTray,
} from "components/features/dashboard/components/manage-collaborator-tray";
import {
  SuggestableRelationship,
  SUGGESTED_RELATIONSHIPS,
} from "components/features/dashboard/pages/contacts/models";
import {
  RelationshipIcon,
  SuggestedContactCard,
} from "components/features/dashboard/pages/contacts/suggestion-card";
import {
  CollaborationRelationships,
  FetchMyCollaboratorsResponse,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";

const RELATIONSHIP_SETTING_MAP: Readonly<
  Record<SuggestableRelationship, UserPreferenceSettingEnum>
> = {
  [CollaborationRelationships.Spouse]:
    UserPreferenceSettingEnum.HideCollabSuggestionSpouse,
  [CollaborationRelationships.Accountant]:
    UserPreferenceSettingEnum.HideCollabSuggestionAccountant,
};

export const ManageTrustedContact: React.FC = () => {
  const [selectedContact] = useState("");
  const { pushTray, popTray } = React.useContext(SideTrayContext);
  const { data, loading: loadingCollaborators } =
    useQuery<FetchMyCollaboratorsResponse>(FETCH_MY_COLLABORATORS, {
      fetchPolicy: "cache-and-network",
    });

  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });

  React.useEffect(() => {
    if (query["addNew"]) {
      showAddNewCollaborator();
    }
    // Use this as a componentDidMount and check if the add collaborator should be opened by default
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAddNewCollaborator = useCallback(
    (defaultRelationship?: CollaborationRelationships) =>
      pushTray({
        id: "manage-collaborator",
        header: <ManageCollaboratorHeader />,
        children: (
          <ManageCollaboratorTray
            onClose={() => popTray()}
            defaultRelationship={defaultRelationship}
          />
        ),
      }),
    [popTray, pushTray]
  );

  const onSuggestionClick = (relationship?: SuggestableRelationship) => {
    showAddNewCollaborator(relationship);
  };

  const existingRelationshipMap = useMemo(() => {
    const m: Record<string, boolean> = {};
    data?.collaborations?.forEach((c) => {
      if (c.relationship) {
        m[c.relationship] = true;
      }
    });

    return m;
  }, [data?.collaborations]);

  const [hideSuggestionPrefences, setHideSuggestionPreference] =
    usePreferenceSettingBooleans(
      false,
      Object.values(RELATIONSHIP_SETTING_MAP),
      true
    );

  const shouldShowSuggestion = useCallback(
    (
      relationship: SuggestableRelationship,
      ...additionalRelationships: CollaborationRelationships[]
    ) => {
      const setting = RELATIONSHIP_SETTING_MAP[relationship];
      if (!data && loadingCollaborators) {
        return false;
      }
      if (existingRelationshipMap[relationship]) {
        return false;
      }

      if (additionalRelationships) {
        const exist = additionalRelationships
          .map((x) => existingRelationshipMap[x])
          .reduce((x, y) => x || y, false);

        if (exist) {
          return false;
        }
      }

      return !hideSuggestionPrefences[setting];
    },
    [
      data,
      loadingCollaborators,
      hideSuggestionPrefences,
      existingRelationshipMap,
    ]
  );

  const hasSuggestions = useMemo(() => {
    return SUGGESTED_RELATIONSHIPS.map((x) => shouldShowSuggestion(x)).reduce(
      (x, y) => x || y,
      false
    );
  }, [shouldShowSuggestion]);

  const hideSuggestion = useCallback(
    (relationship: SuggestableRelationship) => {
      setHideSuggestionPreference(RELATIONSHIP_SETTING_MAP[relationship], true);
    },
    [setHideSuggestionPreference]
  );

  return (
    <PageWrapper
      header={
        <PageTitle
          title="Collaborators"
          subtitle="Share as much or as little information as you'd like with your collaborators."
        />
      }
      actions={
        <LeafButton
          buttonStyle={LeafButtonStyle.LeafSmallDense}
          onClick={() => showAddNewCollaborator()}
        >
          Add collaborator
        </LeafButton>
      }
    >
      <div>
        <div
          className="flex-row mt4 mobile-flex-column collaborator-ad"
          style={{
            gap: "1.5rem",
            justifyContent: "stretch",
          }}
        >
          <div
            className="flex-column"
            style={{
              flexBasis: "50%",
            }}
          >
            <div className="flex-row">
              <div
                style={{
                  paddingTop: "0.5rem",
                  paddingRight: "1.5rem",
                }}
              >
                <Icon asset={IconAsset.EyeSlash} width="36.77" height="27.01" />
              </div>
              <div className="collab-title">
                <Text
                  style={TextStyle.TitlePoppins}
                  type={TextType.Span}
                  size="1.25rem"
                  lineHeight="1.5rem"
                >
                  Collaborators&nbsp;
                  <span
                    style={{
                      color: "var(--blue-01)",
                    }}
                  >
                    do not
                  </span>
                  &nbsp; see any of your accounts by default.
                </Text>
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid var(--separator-01)",
                margin: "1rem 0",
              }}
            />
            <div className="collab-body">
              <Text
                style={TextStyle.B14}
                type={TextType.Span}
                colour={Colour.Text02}
              >
                Collaborators{" "}
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  do not have access{" "}
                </span>{" "}
                to your accounts when you invite them. You can specify what to
                share after sending an invite.
              </Text>
            </div>
          </div>

          <div
            className="flex-column"
            style={{
              flexBasis: "50%",
            }}
          >
            <div className="flex-row">
              <div
                style={{
                  paddingTop: "0.5rem",
                  paddingRight: "1.5rem",
                }}
              >
                <Icon asset={IconAsset.DisabledToggle} width="47" height="24" />
              </div>
              <div className="collab-title">
                <Text
                  style={TextStyle.TitlePoppins}
                  type={TextType.Span}
                  size="1.25rem"
                  lineHeight="1.5rem"
                >
                  Easily{" "}
                  <span
                    style={{
                      color: "var(--blue-01)",
                    }}
                  >
                    set permissions{" "}
                  </span>{" "}
                  when sharing .
                </Text>
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid var(--separator-01)",
                margin: "1rem 0",
              }}
            />
            <div className="collab-body">
              <Text
                style={TextStyle.B14}
                type={TextType.Span}
                colour={Colour.Text02}
              >
                In order for a collaborator to see any of your account details,
                you must select a collaborator and set explicit permissions for
                viewing.
              </Text>
            </div>
          </div>
        </div>

        <div
          className="flex-row mobile-flex-column mt3"
          style={{
            paddingTop: "1rem",
            flexWrap: "wrap",
          }}
        >
          {data?.collaborations &&
            data.collaborations.map((s) => {
              return (
                <EditableBeneficiaryCard
                  key={s.id}
                  collaborator={s}
                  isSelected={s.id === selectedContact}
                />
              );
            })}
          {data && data.collaborations.length === 0 && (
            <div className="nothing-here">
              <div className="nothing-here-icon">
                <Icon asset={IconAsset.Users} width="64px" height="64px" />
              </div>
              <div className="nothing-here-text">
                Click the Add New Collaborator button to get started
              </div>
              <div className="nothing-here-line"></div>
            </div>
          )}
        </div>

        {hasSuggestions && (
          <div className="flex-column">
            <div
              className="flex-row"
              style={{
                alignItems: "center",
              }}
            >
              <Text
                type={TextType.Div}
                style={TextStyle.L12}
                format={TextFormat.UpperCase}
              >
                Pylon Suggests
              </Text>
              <hr className="suggested-contacts__separator" />
            </div>
            <div className="flex-row suggested-contacts__suggestion-wrapper">
              {shouldShowSuggestion(
                CollaborationRelationships.Spouse,
                CollaborationRelationships.Partner
              ) && (
                <SuggestedContactCard
                  headerText={"Spouse/Partner"}
                  relationship={CollaborationRelationships.Spouse}
                  icon={RelationshipIcon.Spouse}
                  title="Invite your spouse or partner"
                  description="Share accounts or documents with your loved one to collaborate in real time."
                  onHideSuggestion={hideSuggestion}
                  onSuggestionClick={onSuggestionClick}
                />
              )}

              {shouldShowSuggestion(CollaborationRelationships.Accountant) && (
                <SuggestedContactCard
                  relationship={CollaborationRelationships.Accountant}
                  icon={RelationshipIcon.Accountant}
                  title="Invite your accountant"
                  description="Share important documents and accounts with your service provider."
                  onHideSuggestion={hideSuggestion}
                  onSuggestionClick={onSuggestionClick}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
