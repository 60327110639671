import * as React from "react";
import CSS from "csstype";
import Select from "react-select";
import { SELECT__MODAL_INPUT_STYLE } from "components/core/input/styles";

interface Option {
  value: string;
  label: string;
}

interface Props {
  userState?: string;

  openUp?: boolean;

  height?: CSS.Property.Height;
  onSave: (data: string) => void;
}

export const DEFAULT_STATE = "";

export const StateDropdown: React.FC<Props> = ({
  userState,
  openUp,
  height,
  onSave,
}) => {
  const values: Option[] = [
    { value: "", label: "Select State" },
    { value: "US-AL", label: "Alabama" },
    { value: "US-AK", label: "Alaska" },
    { value: "US-AS", label: "American Samoa" },
    { value: "US-AZ", label: "Arizona" },
    { value: "US-AR", label: "Arkansas" },
    { value: "US-CA", label: "California" },
    { value: "US-CO", label: "Colorado" },
    { value: "US-CT", label: "Connecticut" },
    { value: "US-DE", label: "Delaware" },
    { value: "US-DC", label: "District of Columbia" },
    { value: "US-FL", label: "Florida" },
    { value: "US-GA", label: "Georgia" },
    { value: "US-GU", label: "Guam" },
    { value: "US-HI", label: "Hawaii" },
    { value: "US-ID", label: "Idaho" },
    { value: "US-IL", label: "Illinois" },
    { value: "US-IN", label: "Indiana" },
    { value: "US-IA", label: "Iowa" },
    { value: "US-KS", label: "Kansas" },
    { value: "US-KY", label: "Kentucky" },
    { value: "US-LA", label: "Louisiana" },
    { value: "US-ME", label: "Maine" },
    { value: "US-MD", label: "Maryland" },
    { value: "US-MA", label: "Massachusetts" },
    { value: "US-MI", label: "Michigan" },
    { value: "US-MN", label: "Minnesota" },
    { value: "US-MS", label: "Mississippi" },
    { value: "US-MO", label: "Missouri" },
    { value: "US-MT", label: "Montana" },
    { value: "US-NE", label: "Nebraska" },
    { value: "US-NV", label: "Nevada" },
    { value: "US-NH", label: "New Hampshire" },
    { value: "US-NJ", label: "New Jersey" },
    { value: "US-NM", label: "New Mexico" },
    { value: "US-NY", label: "New York" },
    { value: "US-NC", label: "North Carolina" },
    { value: "US-ND", label: "North Dakota" },
    { value: "US-MP", label: "Northern Mariana " },
    { value: "US-OH", label: "Ohio" },
    { value: "US-OK", label: "Oklahoma" },
    { value: "US-OR", label: "Oregon" },
    { value: "US-PA", label: "Pennsylvania" },
    { value: "US-PR", label: "Puerto " },
    { value: "US-RI", label: "Rhode Island" },
    { value: "US-SC", label: "South Carolina" },
    { value: "US-SD", label: "South Dakota" },
    { value: "US-TN", label: "Tennessee" },
    { value: "US-TX", label: "Texas" },
    { value: "US-UM", label: "United States Minor Outlying" },
    { value: "US-UT", label: "Utah" },
    { value: "US-VT", label: "Vermont" },
    { value: "US-VI", label: "Virgin Islands, U.S" },
    { value: "US-VA", label: "Virginia" },
    { value: "US-WA", label: "Washington" },
    { value: "US-WV", label: "West Virginia" },
    { value: "US-WI", label: "Wisconsin" },
    { value: "US-WY", label: "Wyoming" },
  ];
  const userValue = userState && values.find((v) => v.value === userState);
  return (
    <>
      <Select
        value={userValue || values[0]}
        styles={{
          ...SELECT__MODAL_INPUT_STYLE,
          // Fixes the overlapping problem of the component
          menu: (provided) => {
            const newCss = {
              ...provided,
              zIndex: 9999,
              width: "100%",
            };

            if (openUp) {
              newCss["bottom"] = "3.5rem";
              newCss["top"] = "unset";
            }
            return newCss;
          },
        }}
        onChange={(e) => {
          if (!e) {
            return;
          }

          onSave(e.value);
        }}
        isSearchable={true}
        options={values}
      />
    </>
  );
};
