import { Checkbox } from "components/core/checkbox";
import { ConfirmationModal } from "components/core/modal";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { TextArea } from "components/core/textarea";
import { TextD, TextStyle } from "components/core/text";
import { toast } from "react-toastify";
import { useMobileLayout } from "core/hooks/responsive";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import "./styles.css";
import {
  LeafButton,
  LeafButtonColour,
  LeafButtonStyle,
} from "components/core/leaf-button";
import {
  AssetNoteVisibilityPermission,
  CreateAssetNoteMutationInput,
  CREATE_NEW_ASSET_NOTE_MUTATION,
} from "core/queries/asset-notes";

export interface Props {
  show: boolean;
  assetID: string;
  assetType: string;
  onClose: () => void;
  onCreate: () => void;
}

export const AddNoteModal: React.FC<Props> = ({
  show,
  assetID,
  assetType,
  onClose,
  onCreate,
}) => {
  const defaultState = {
    assetID: assetID,
    assetType: assetType,
    visibility: AssetNoteVisibilityPermission.VisibleToOwnerOnly,
  };
  const [state, setState] =
    useState<Partial<CreateAssetNoteMutationInput>>(defaultState);

  const mobileLayout = useMobileLayout();
  const [createNewNoteMutation, { loading: createLoading }] = useMutation<
    {},
    { input: CreateAssetNoteMutationInput }
  >(CREATE_NEW_ASSET_NOTE_MUTATION, {
    onCompleted: () => {
      toast(
        <PylonToastBody
          title={`Successfully added note`}
          body={`Your note has been added`}
        />
      );
      onCreate();
    },
    onError: (err) => {
      console.error("error adding note", err);
      toast(
        <PylonToastBody
          title={`Failed to add note`}
          body={`We couldn't save the note. Please try again`}
          type={ToastType.Error}
        />
      );
    },
  });

  const disabled = !state.note || !state.visibility || createLoading;

  const createNote = () => {
    if (disabled) {
      return;
    }

    createNewNoteMutation({
      variables: {
        // Disabled check should've covered to make sure all the input fields are there
        input: state as CreateAssetNoteMutationInput,
      },
    });
  };

  return (
    <ConfirmationModal
      showModal={show}
      onClose={onClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
    >
      <div
        className="flex-column "
        style={{
          padding: mobileLayout ? "2.5rem 1rem" : "2.5rem",
          width: mobileLayout ? "95vw" : "32rem",
          color: "black",
          gap: "1.5rem",
        }}
      >
        <TextD style={TextStyle.M31B}>Add note</TextD>

        <div
          className="flex-column gap-1"
          style={{
            paddingTop: "0.5rem",
          }}
        >
          <TextD style={TextStyle.M15}>
            <TextArea
              className="asset-note__note-input"
              id="asset-note"
              name="assetnote"
              autoFocus
              placeholder="Something noteworthy..."
              value={state.note}
              onChange={(e) =>
                setState({
                  ...state,
                  note: e.currentTarget.value,
                })
              }
            />
          </TextD>

          <div className="flex-row align-center">
            <Checkbox
              id="asset-note-visibility"
              checked={
                state.visibility ===
                AssetNoteVisibilityPermission.VisibleToOwnerOnly
              }
              label="Mark as private (only you can see this note)"
              onChange={() => {
                let newVisibility = AssetNoteVisibilityPermission.VisibleToAll;
                if (
                  state.visibility ===
                  AssetNoteVisibilityPermission.VisibleToAll
                ) {
                  newVisibility =
                    AssetNoteVisibilityPermission.VisibleToOwnerOnly;
                }
                setState({
                  ...state,
                  visibility: newVisibility,
                });
              }}
            />
          </div>
        </div>

        <div
          className="flex-row gap-1"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <LeafButton
            onClick={(e) => {
              onClose();
              setState({ ...defaultState });
            }}
            buttonStyle={LeafButtonStyle.LeafSmall}
            buttonColour={LeafButtonColour.Transparent}
          >
            Cancel
          </LeafButton>

          <LeafButton
            disabled={disabled}
            loading={createLoading}
            onClick={(e) => {
              createNote();
              setState({ ...defaultState });
            }}
            buttonStyle={LeafButtonStyle.LeafSmall}
            buttonColour={LeafButtonColour.Black}
          >
            Ok
          </LeafButton>
        </div>
      </div>
    </ConfirmationModal>
  );
};
