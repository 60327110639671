import * as React from "react";
import { AccountTransactionContext } from "components/contexts/account-transaction-context";
import { Colour } from "core/models";
import { format } from "date-fns";
import {
  Tab,
  TabList,
  TabPanel,
  Tabs
  } from "react-tabs";
import { Text, TextStyle } from "components/core/text";
import { TransactionsTable } from "components/features/dashboard/components/transactions";
import {
  AccountTransaction,
  RecurringExpenseType,
} from "core/queries/transactions";

export const TransactionsSummary: React.FC = () => {
  const { monthDate } = React.useContext(AccountTransactionContext);
  // Transactions
  const transactionTableLimit = 10;

  return (
    <div className="flex-column transactions">
      <Text style={TextStyle.M17SB} colour={Colour.Black}>
        {format(monthDate, "MMMM")} Transactions
      </Text>
      <Tabs>
        <TabList className="account-detail-tab-list">
          <Tab
            id="dashboard-transactions-recent"
            className="account-detail-tab-item"
            selectedClassName="account-detail-tab-item--selected"
          >
            Recent
          </Tab>
          <Tab
            id="dashboard-transactions-pending"
            className="account-detail-tab-item"
            selectedClassName="account-detail-tab-item--selected"
          >
            Pending
          </Tab>
          <Tab
            id="dashboard-transactions-recurring"
            itemID="adfadf"
            className="account-detail-tab-item"
            selectedClassName="account-detail-tab-item--selected"
          >
            Recurring
          </Tab>
        </TabList>
        <TabPanel>
          <TransactionsTable
            transactionFilter={(tx: AccountTransaction) => {
              return !tx?.pending;
            }}
            limit={transactionTableLimit}
            showTitle={false}
            detailsView={false}
          />
        </TabPanel>
        <TabPanel>
          <TransactionsTable
            transactionFilter={(tx: AccountTransaction) => {
              return tx?.pending;
            }}
            limit={transactionTableLimit}
            showTitle={false}
            detailsView={false}
          />
        </TabPanel>
        <TabPanel>
          <TransactionsTable
            transactionFilter={(tx: AccountTransaction) => {
              return (
                !tx?.pending &&
                tx.recurringExpenseType === RecurringExpenseType.Subscription
              );
            }}
            limit={transactionTableLimit}
            showTitle={false}
            detailsView={false}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};
