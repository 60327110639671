export enum TrackingIDs {
  AdWords = "AW-306475341",
  GoogleAnalytics = "G-97LWQ926JY",
}

export enum GoogleAdWordsConversionIDs {
  Legacy = `AW-306475341/ZJLGCLu4q_kCEM3ikZIB`,
  Waitlist = `AW-306475341/dN8WCJbvmvwCEM3ikZIB`,
  StartedRegistration = `AW-306475341/rQs1CPjxsvwCEM3ikZIB`,
  CompletedRegistration = `AW-306475341/JKKgCNDxivwCEM3ikZIB`,
  AddedAnAccount = `AW-306475341/Mlc2CPb2ivwCEM3ikZIB`,
}

export const trackEvent = (eventName: string, eventData: any) => {
  window.heap?.track(eventName, eventData);
};

export const addUserProperties = (userProperties: any) => {
  window.heap?.addUserProperties(userProperties);
};
