import _ from "lodash";
import { DEFAULT_CATEGORY_LIST } from "components/features/dashboard/components/transactions/utils";
import { LeafButton } from "components/core/leaf-button";
import { MutationWrapper } from "core/queries/mutation";
import { PylonCurrencyInput } from "components/core/currency-input";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleDropDown } from "components/core/simple-drop-down";
import { Text, TextStyle, TextType } from "components/core/text";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateOrUpdateBudgetGoalMutationInput,
  CreateOrUpdateBudgetGoalMutationResponse,
  CREATE_OR_UPDATE_BUDGET_GOAL_MUTATION,
  GET_BUDGET_GOAL_QUERY,
} from "core/queries/budget-goal";
import {
  CustomTransactionCategoryResponse,
  GetCustomTransactionCategoryQuery,
} from "core/queries/transaction-category";

export interface Props {}

export const AddBudgetGoalTray: React.FC<Props> = () => {
  const { popTray } = useContext(SideTrayContext);
  const { data: categoryData } = useQuery<CustomTransactionCategoryResponse>(
    GetCustomTransactionCategoryQuery,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [createGoalMutation, { loading }] = useMutation<
    CreateOrUpdateBudgetGoalMutationResponse,
    MutationWrapper<CreateOrUpdateBudgetGoalMutationInput>
  >(CREATE_OR_UPDATE_BUDGET_GOAL_MUTATION, {
    onCompleted: () => {
      popTray();
    },
    refetchQueries: [
      {
        query: GET_BUDGET_GOAL_QUERY,
      },
    ],
  });

  const [category, setCategory] = useState("");
  const [goal, setGoal] = useState<string | undefined>();

  const categories = (
    categoryData?.customTransactionCategories?.map((c) => c.category) ?? []
  ).concat(DEFAULT_CATEGORY_LIST);
  const disabled = _.isEmpty(category) || !goal || _.isNaN(parseFloat(goal));

  const createGoal = () => {
    if (!disabled) {
      createGoalMutation({
        variables: {
          input: {
            category: category,
            budget: goal ?? "0",
          },
        },
      });
    }
  };

  return (
    <div>
      <div
        className="flex-column"
        style={{
          gap: "0.5rem",
        }}
      >
        <Text style={TextStyle.FieldLabel} type={TextType.Div}>
          Select a Category
        </Text>
        <div
          style={{
            flex: "1 1",
          }}
        >
          <SimpleDropDown
            options={categories}
            selectedValue={category}
            onSelect={(v) => {
              if (v) {
                setCategory(v);
              }
            }}
          />
        </div>
      </div>

      <div className="flex-column mt1">
        <Text style={TextStyle.FieldLabel} type={TextType.Div}>
          Goal
        </Text>
        <div
          style={{
            flex: "1 1",
          }}
        >
          <PylonCurrencyInput
            id="add-budget-goal"
            placeholder="$1,000"
            prefix="$"
            onValueChange={(v) => {
              setGoal(v);
            }}
            allowNegativeValue={false}
            value={goal}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                createGoal();
              }
            }}
          />
        </div>
      </div>

      <div className="mt1" />

      <div className="flex-column">
        <LeafButton
          id="budget-goal-create"
          disabled={disabled}
          loading={loading}
          onClick={() => {
            createGoal();
          }}
        >
          Create
        </LeafButton>
      </div>
    </div>
  );
};
