import React from "react";
import { Link, LinkProps } from "react-router-dom";
import "./styles.css";

export enum LinkType {
  Default = "pylon-link__default",
  Highlight = "pylon-link__highlight",
  Hint = "pylon-link__hint",
  HintBlue = "pylon-link__hint-blue",
  InheritColour = "pylon-link__inherit-colour",
  NoStyle = "",
}
export interface PublicProps {
  linkType?: LinkType;
}

type Props = PublicProps & LinkProps;

export const PylonLink: React.FC<Props> = (props) => {
  const { linkType, ...linkProps } = props;

  return (
    <Link
      className={`${linkType ?? LinkType.Default} pylon-link`}
      {...linkProps}
    >
      {props.children}
    </Link>
  );
};
