import React, { useContext } from "react";
import { Colour } from "core/models";
import { displayColouredNumber } from "components/features/dashboard/components/planning/coloured-number";
import { ModifyIncomeAndExpenseTray } from "components/features/dashboard/components/planning/income-expense-section/modify-income-and-expense-tray";
import { PlanContext } from "components/contexts/plan-context-provider";
import { SideTrayContext } from "components/contexts/side-tray-context-provider";
import { SimpleSideTrayHeader } from "components/core/side-tray-header";
import {
  Text,
  TextFormat,
  TextStyle,
  TextType
  } from "components/core/text";
import "./styles.css";

export const IncomeExpenseSection: React.FC = () => {
  const { pushTray } = useContext(SideTrayContext);
  const { householdIncome, taxes, monthlyNetIncome, monthlyExpense } =
    useContext(PlanContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        id="plan-modify-income-and-expense"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          pushTray({
            header: <SimpleSideTrayHeader text="Update Details" />,
            children: <ModifyIncomeAndExpenseTray />,
          });
        }}
      >
        <Text
          type={TextType.Div}
          style={TextStyle.L14}
          format={TextFormat.UpperCase}
          colour={Colour.Blue01}
        >
          update details
        </Text>
      </div>
      <div className="plan-income__section-item">
        <Text
          type={TextType.Div}
          style={TextStyle.B14}
          weight={700}
          colour={Colour.Text01}
          format={TextFormat.UpperCase}
        >
          Annual Household Income
        </Text>
        {displayColouredNumber(householdIncome)}
      </div>

      <div className="plan-income__section-item">
        <Text
          type={TextType.Div}
          style={TextStyle.B14}
          weight={700}
          colour={Colour.Text01}
          format={TextFormat.UpperCase}
        >
          Taxes
        </Text>
        {displayColouredNumber(taxes)}
      </div>

      <div className="plan-income__section-item">
        <Text
          type={TextType.Div}
          style={TextStyle.B14}
          weight={700}
          colour={Colour.Text01}
          format={TextFormat.UpperCase}
        >
          Monthly Net Income
        </Text>
        {displayColouredNumber(monthlyNetIncome)}
      </div>

      <div className="plan-income__section-item">
        <Text
          type={TextType.Div}
          style={TextStyle.B14}
          weight={700}
          colour={Colour.Text01}
          format={TextFormat.UpperCase}
        >
          Monthly Net Expenses
        </Text>
        {displayColouredNumber(monthlyExpense, 0, true)}
      </div>

      <div className="plan-income__section-item">
        <Text
          type={TextType.Div}
          style={TextStyle.B14}
          weight={700}
          colour={Colour.Text01}
          format={TextFormat.UpperCase}
        >
          Monthly Cash Flow
        </Text>
        {displayColouredNumber(
          Number.parseFloat(monthlyNetIncome) -
            Number.parseFloat(monthlyExpense)
        )}
      </div>
    </div>
  );
};
