import React, { useState } from "react";
import { AccountsHeader } from "../../components/header";
import { Checkbox } from "components/core/checkbox";
import { Colour } from "core/models";
import { Input } from "components/core/input";
import { LeafButton } from "components/core/leaf-button";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { toast } from "react-toastify";
import { useTimer } from "react-timer-hook";
import "./styles.css";
import {
  Text,
  TextD,
  TextFormat,
  TextStyle,
  TextType,
} from "components/core/text";
import {
  Request2FAVerificationResponse,
  resendTwoFA,
  validateTwoFactorCode,
  Verify2FAResponse,
} from "core/api";

interface Props {
  res: Request2FAVerificationResponse;
  title?: string;
  noHeader?: boolean;
  onCodeVerified: (identityToken: Verify2FAResponse) => void;
}

export const TwoFactorAuthComponent: React.FC<Props> = ({
  res,
  noHeader,
  title,
  onCodeVerified,
}) => {
  const [code, setCode] = useState("");
  const [identityToken, setIdentityToken] = useState(res.identityToken);
  const [remember, setRemember] = React.useState(false);
  const handleSubmit = () => {
    validateTwoFactorCode(
      identityToken,
      code,
      remember,
      (data) => {
        if (!data || !data.identityToken) {
          toast(
            <PylonToastBody
              title={"Error Occurred"}
              body={`${data.message}`}
              type={ToastType.Error}
            />
          );
        } else {
          onCodeVerified(data);
        }
      },
      (err) => {
        console.error("Error validating 2fa", err);
        const message = err instanceof Error ? err.message : "Unknown error";
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={`${message}`}
            type={ToastType.Error}
          />
        );
      }
    );
  };
  const [isTwoFACooldown, setIsTwoFACooldown] = useState(false);
  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date().getTime(),
    onExpire: () => {
      setIsTwoFACooldown(false);
    },
    autoStart: false,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      {!noHeader && <AccountsHeader redirectToHome />}

      <div className="account-page">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h2>{title ?? "Account Verification"}</h2>
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <Text
            textAlign={"center"}
            style={TextStyle.Hint}
            colour={Colour.Text03}
            weight={400}
            size={"1.25rem"}
          >
            Verification code has been sent to {res.maskedPhoneNumber}, it will
            expire in 5 minutes.
          </Text>
        </div>
        {/* <Text>
          A text message with a verification code has been sent to{" "}
          {res.maskedPhoneNumber} please enter the 6 digit verification code
          below. It is valid for 5 minutes
        </Text> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className="login-field-wrapper">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                style={{
                  width: "100%",
                }}
                autoComplete="off"
              >
                <Input
                  style={{ height: "3rem" }}
                  autoFocus
                  pattern="[0-9]+"
                  id="code"
                  name="code"
                  placeholder="Enter 6-digit code"
                  inputWrapperClassName="twofa-input-with-resend"
                  onEnter={handleSubmit}
                  value={code}
                  onChange={(e) => setCode(e?.currentTarget?.value)}
                  required
                />
              </form>
              <div
                style={{
                  position: "absolute",
                  right: "1rem",
                  top: "1.25rem",
                  cursor: "pointer",
                }}
              >
                <div
                  onClick={() => {
                    if (isTwoFACooldown) {
                      return;
                    }
                    setIsTwoFACooldown(true);
                    restart(new Date().getTime() + 1000 * 30);

                    resendTwoFA(
                      res.identityToken,

                      (data) => {
                        if (!data || !data.identityToken) {
                          toast(
                            <PylonToastBody
                              title={"Error Occurred"}
                              body={`${data.message}`}
                              type={ToastType.Error}
                            />
                          );
                        } else {
                          toast(
                            <PylonToastBody
                              title={"SMS Requested"}
                              body={`A new text message has been sent`}
                              type={ToastType.Notification}
                            />
                          );
                          setIdentityToken(data.identityToken);
                        }
                      },
                      (err) => {
                        console.error("Error validating 2fa", err);
                        const message =
                          err instanceof Error ? err.message : "Unknown error";
                        toast(
                          <PylonToastBody
                            title={"Error Occurred"}
                            body={`${message}`}
                            type={ToastType.Error}
                          />
                        );
                      }
                    );
                  }}
                >
                  <TextD
                    size={"0.75rem"}
                    weight={600}
                    colour={Colour.Text03}
                    format={TextFormat.UpperCase}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      Resend Code {seconds > 0 && `(${seconds})`}
                    </span>
                  </TextD>
                </div>
              </div>
            </div>
          </div>
          <div className="mt1">
            <Checkbox
              id="2fa-remember-device"
              label="Remember this device for 30 days"
              checked={remember}
              onChange={() => setRemember(!remember)}
            />
          </div>
          <div className="login-button-wrapper">
            <LeafButton fullWidth onClick={handleSubmit}>
              Verify
            </LeafButton>
          </div>
        </div>
        <div
          style={{
            paddingBottom: "1rem",
          }}
        />
      </div>
      <div
        style={{
          marginTop: "auto",
          height: "3rem",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Text
          size={"0.75rem"}
          weight={600}
          format={TextFormat.UpperCase}
          style={TextStyle.Hint}
          type={TextType.Div}
          textAlign={"center"}
        >
          If you did not receive the SMS code, please contact us at
          support@hellopylon.com
        </Text>
      </div>
    </div>
  );
};
