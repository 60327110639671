import React from "react";
import { DashboardPages } from "components/features/dashboard/pages";
import { Logo } from "components/core/logo";
import { useMobileLayout } from "core/hooks/responsive";
import "./styles.css";

interface Props {
  redirectToHome: boolean;
}
export const AccountsHeader: React.FC<Props> = ({ redirectToHome }) => {
  const mobileLayout = useMobileLayout();
  return mobileLayout ? (
    <></>
  ) : (
    <div className="account-header-section">
      <Logo
        redirectTo={redirectToHome ? "/landing/main" : DashboardPages.Summary}
      />
    </div>
  );
};
