import * as React from "react";
import qs from "qs";
import update from "immutability-helper";
import { Account } from "../../../../../core/models";
import { AccountCategoryAdd } from "components/features/dashboard/components/account-category-add";
import { AccountCategorySection } from "components/features/dashboard/components/account-category-section";
import { AccountDetailView } from "components/features/dashboard/components/account-detail-view";
import { AccountTableContext } from "components/contexts/account-table-context";
import { AccountTransactionContextProvider } from "components/contexts/account-transaction-context";
import { AddAccountsContext } from "components/features/dashboard/components/add-account-tray/context/add-account-context";
import { CryptoConnectWrapper } from "components/features/dashboard/components/crypto-connect-wrapper";
import { DashboardPages } from "components/features/dashboard/pages";
import { DataPageContext } from "components/contexts/data-page-context-provider";
import { DeleteAccountQuickActionButton } from "components/features/dashboard/components/delete-account-quick-action";
import { DndProvider } from "react-dnd";
import { DraggableCategory } from "components/features/dashboard/components/row-card/draggable-wrapper/util";
import { EditAccountQuickAction } from "components/features/dashboard/components/edit-account-quick-action";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon, IconAsset } from "../../../../core/icons";
import { IntegrationSource } from "components/features/dashboard/models/integration-source";
import { NetWorthSummaryBrief } from "components/features/dashboard/components/networth-summary-brief";
import { PageTitle } from "components/features/dashboard/components/page-title";
import { PageWrapper } from "components/features/dashboard/pages/common/page-wrapper";
import { PlaidWrapper } from "components/features/dashboard/components/plaid-wrapper";
import { PylonToastBody, ToastType } from "components/core/pylon-toast-body";
import { RefreshAccountQuickAction } from "components/features/dashboard/components/refresh-account-quick-action";
import { RemoveAccountQuickAction } from "components/features/dashboard/components/remove-access-quick-action";
import { SessionContext } from "../../../../contexts/session-context-provider";
import { ShareAccountQuickAction } from "components/features/dashboard/components/share-account-quick-action";
import { TableRowModal } from "components/core/modal/table-row-modal";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useMobileLayout } from "core/hooks/responsive";
import { useMutation, useQuery } from "@apollo/client";
import { usePreferenceSettingStringEnum } from "core/hooks/user-preference-setting";
import { UserPreferenceSettingEnum } from "core/queries/user-preference-settings";
import "./styles.css";
import {
  AccountOwnerFilter,
  AccountOwnerTypeFiltering,
} from "components/features/dashboard/components/account-owner-filter";
import {
  AccountTypeFilter,
  AccountTypeFiltering,
} from "components/features/dashboard/components/account-type-filter";
import {
  DraggableWrapper,
  RowCardType,
} from "components/features/dashboard/components/row-card/draggable-wrapper";
import {
  accountCategoriesExamplesQuery,
  AccountCategoriesResponse,
  AccountCategory,
  updateAccountCategories,
} from "core/queries/accounts";
import {
  CollaborationRelationships,
  FetchMyCollaboratorsResponse,
  FETCH_MY_COLLABORATORS,
} from "core/queries/collaborations";

interface Props {}

export const Integrations: React.FC<Props> = (props: Props) => {
  const mobileLayout = useMobileLayout();
  const { userID } = React.useContext(SessionContext.context);

  const [accountType, setAccountType] = usePreferenceSettingStringEnum(
    AccountTypeFiltering.All,
    UserPreferenceSettingEnum.AccountsHistoryDataType
  );
  const [accountOwnerType, setAccountOwnerType] =
    usePreferenceSettingStringEnum(
      AccountOwnerTypeFiltering.All,
      UserPreferenceSettingEnum.AccountsHistoryOwnerType
    );

  const { setOnUpdateRefetchQueries } = React.useContext(DataPageContext);
  const { data } = useQuery<AccountCategoriesResponse>(
    accountCategoriesExamplesQuery,
    {
      fetchPolicy: "cache-and-network",
      onError: (err) => {
        console.error("failed to delete account", err);
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={`${err.message}`}
            type={ToastType.Error}
          />
        );
      },
    }
  );

  const { data: collabData } = useQuery<FetchMyCollaboratorsResponse>(
    FETCH_MY_COLLABORATORS,
    {
      fetchPolicy: "cache-first",
    }
  );

  const spouseAndPartnerIds: string[] = React.useMemo(() => {
    return (
      collabData?.collaborations
        .filter((c) => {
          return (
            (c.relationship === CollaborationRelationships.Spouse ||
              c.relationship === CollaborationRelationships.Partner) &&
            c.recipientID !== userID
          );
        })
        ?.map((c) => {
          return c.recipient.id;
        }) || []
    );
  }, [collabData, userID]);

  const [updateCategories] = useMutation<AccountCategoriesResponse>(
    updateAccountCategories,
    {
      refetchQueries: [{ query: accountCategoriesExamplesQuery }],
      onError: (err) => {
        console.error("failed to add account", err);
        toast(
          <PylonToastBody
            title={"Error Occurred"}
            body={`${err.message}`}
            type={ToastType.Error}
          />
        );
      },
    }
  );
  const history = useHistory();
  const query = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const isMobileLayout = useMobileLayout();

  React.useEffect(() => {
    setOnUpdateRefetchQueries([{ query: accountCategoriesExamplesQuery }]);

    // Only set once on coomponent mount; this does not depend on anything else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAccountID = query["accountID"] as string;

  const [draggableAccounts, setDraggableAccounts] = React.useState<
    DraggableCategory[]
  >([]);

  const existingCategories = draggableAccounts.map((d) => d.category.category);
  const showAccountFilters: boolean = React.useMemo(() => {
    return (
      draggableAccounts?.length > 0 ||
      accountType !== AccountTypeFiltering.All ||
      accountOwnerType !== AccountOwnerTypeFiltering.All
    );
  }, [draggableAccounts, accountType, accountOwnerType]);

  const filterAccounts = React.useCallback(
    (accounts: Account[]) => {
      let accountList: Account[] = [];
      if (accountType === AccountTypeFiltering.All) {
        accountList = accounts.filter((a) => {
          return true;
        });
      } else if (accountType === AccountTypeFiltering.Liabilities) {
        accountList = accounts.filter((a) => {
          return a.typeProperties?.isLiability;
        });
      } else if (accountType === AccountTypeFiltering.Assets) {
        accountList = accounts.filter((a) => {
          return a.typeProperties?.isAsset;
        });
      } else if (accountType === AccountTypeFiltering.LiquidAssets) {
        accountList = accounts.filter((a) => {
          return a.typeProperties?.isLiquid === true;
        });
      } else if (accountType === AccountTypeFiltering.IlliquidAssets) {
        accountList = accounts.filter((a) => {
          return a.typeProperties?.isLiquid === false;
        });
      }

      accountList = accountList.filter((acc) => {
        if (accountOwnerType === AccountOwnerTypeFiltering.My) {
          return acc.owner?.id === userID;
        } else if (
          accountOwnerType === AccountOwnerTypeFiltering.SpousePartner
        ) {
          return (
            spouseAndPartnerIds &&
            spouseAndPartnerIds.find((s) => s === acc.owner?.id)
          );
        } else if (
          accountOwnerType === AccountOwnerTypeFiltering.MineAndSpousePartner
        ) {
          return (
            acc.owner?.id === userID ||
            (spouseAndPartnerIds &&
              spouseAndPartnerIds.find((s) => s === acc.owner?.id))
          );
        }

        return true;
      });

      return accountList;
    },
    [accountOwnerType, accountType, spouseAndPartnerIds, userID]
  );

  // Filter data?.accountCategories based on filtering selections
  const accountCategories: AccountCategory[] = React.useMemo(() => {
    const ret: AccountCategory[] = [];

    function newAccountCategory(ac: AccountCategory, accounts: Account[]) {
      return {
        index: ac.index,
        hidden: false,
        category: ac.category,
        accounts: accounts,
        total: accounts?.reduce((total, acc) => {
          return (
            total +
            acc.balances.current * (acc.typeProperties?.signMultiplier || 1)
          );
        }, 0),
      };
    }

    data?.accountCategoriesExamples.forEach((ac) => {
      let accountList = filterAccounts(ac?.accounts ?? []);
      if (accountList.length) {
        ret.push(newAccountCategory(ac, accountList));
      }
    });

    return ret;
  }, [data?.accountCategoriesExamples, filterAccounts]);

  React.useEffect(() => {
    const set: DraggableCategory[] = [];
    if (accountCategories) {
      const categories = accountCategories || [];
      let i = 0;
      for (const category of categories) {
        set.push({ id: i, category });
        i++;
      }
    }
    setDraggableAccounts(Array.from(set));
  }, [accountCategories]);

  const moveCategory = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = draggableAccounts[dragIndex];
      const newCategories = update(draggableAccounts, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });

      setDraggableAccounts(newCategories);
    },
    [draggableAccounts]
  );

  const onMoveAccount = React.useCallback(
    (category: string, dragIndex: number, hoverIndex: number) => {
      const cat = draggableAccounts.find(
        (c) => c.category.category === category
      );
      if (!cat) {
        return;
      }

      const dragAccount = cat.category.accounts[dragIndex];
      const newAccounts = update(cat.category.accounts, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragAccount],
        ],
      });

      const newCategories = draggableAccounts.map((c) => {
        if (cat.category.category === c.category.category) {
          return {
            id: c.id,
            category: {
              ...c.category,
              accounts: newAccounts,
            },
          };
        }

        return c;
      });

      setDraggableAccounts(newCategories);
    },
    [draggableAccounts]
  );

  const { showPlaid, showCoinbase, setShowPlaid, setShowCoinbase } =
    React.useContext(AddAccountsContext);

  const onDragComplete = React.useCallback(
    () =>
      updateCategories({
        variables: {
          categories: draggableAccounts.map((v, i) => ({
            index: i,
            category: v.category.category,
            accountIDs: v.category.accounts.map((x) => x.account_id),
          })),
        },
      }),
    [draggableAccounts, updateCategories]
  );

  const allAccounts = React.useMemo(() => {
    if (!data?.accountCategoriesExamples) {
      return [];
    }

    const accounts = data?.accountCategoriesExamples.flatMap((cat) => {
      return cat.accounts ?? [];
    });

    return filterAccounts(
      accounts.filter((v) => !v.account_id.includes("EXAMPLE"))
    );
  }, [data?.accountCategoriesExamples, filterAccounts]);

  const [showModal, setShowModal] = React.useState(true);

  const [selectedAccountIndex, setSelectedAccountIndex] = React.useState(-1);
  const selectedAccount = React.useMemo(() => {
    if (selectedAccountIndex === -1) {
      return null;
    }
    return allAccounts[selectedAccountIndex];
  }, [allAccounts, selectedAccountIndex]);

  const onRemove = (category: string, isExample: boolean) => {
    const found = draggableAccounts.find(
      (v) => v.category.category === category
    );
    if (found) {
      found.category.hidden = true;
    }

    updateCategories({
      variables: {
        categories: draggableAccounts.map((v, i) => ({
          index: i,
          category: v.category.category,
          accountIDs: v.category.accounts.map((x) => x.account_id),
          hidden: v.category.hidden,
        })),
      },
    });
  };

  const onCategoryAdd = (category: string) => {
    updateCategories({
      variables: {
        categories: [
          ...draggableAccounts.map((v, i) => ({
            index: i,
            category: v.category.category,
            accountIDs: v.category.accounts.map((x) => x.account_id),
            hidden: v.category.hidden,
          })),
          {
            category,
            index: draggableAccounts.length + 1,
            hidden: false,
            accountIDs: [],
          },
        ],
      },
    });
  };

  const hasAccount = React.useMemo(() => {
    if (!data) {
      return false;
    }
    return !!data?.accountCategoriesExamples?.find((cat) => {
      return cat.accounts?.find(
        (acc) => acc.integration_source !== IntegrationSource.Example
      );
    });
  }, [data]);

  if (!data) {
    return null;
  }
  return (
    <AccountTableContext.Provider
      value={{
        onSelectAccount: (accID) => {
          if (accID.includes("EXAMPLE")) {
            return;
          }
          if (mobileLayout) {
            history.push(`${DashboardPages.AccountDetails}?accountID=${accID}`);
            return;
          }
          const idx = allAccounts.findIndex((acc) => acc.account_id === accID);
          setSelectedAccountIndex(idx);
          if (idx !== -1) {
            setShowModal(true);
          }
        },
      }}
    >
      <AccountTransactionContextProvider>
        <PageWrapper
          header={
            <PageTitle
              title="Accounts"
              subtitle="Keep track of all your financial accounts in one place for you and your collaborators."
            />
          }
          actions={
            showAccountFilters ? (
              <div
                style={{
                  alignSelf: "center",
                  marginRight: "1rem",
                  display: "flex",
                  gap: "0.5rem",
                }}
              >
                <AccountTypeFilter
                  accountType={accountType || AccountTypeFiltering.All}
                  setAccountType={setAccountType}
                />

                <AccountOwnerFilter
                  accountOwnerType={
                    accountOwnerType || AccountOwnerTypeFiltering.My
                  }
                  setAccountOwnerType={setAccountOwnerType}
                />
              </div>
            ) : undefined
          }
        >
          <div>
            {showPlaid && <PlaidWrapper onClose={() => setShowPlaid(false)} />}
            {showCoinbase && (
              <CryptoConnectWrapper
                showingModal={showCoinbase}
                onClose={() => setShowCoinbase(false)}
              />
            )}
            {selectedAccountIndex !== -1 && selectedAccount && (
              <TableRowModal
                width={isMobileLayout ? "100vw" : "55rem"}
                onClose={() => {
                  setSelectedAccountIndex(-1);
                  setShowModal(false);
                }}
                detailPageLink={`${DashboardPages.AccountDetails}?accountID=${selectedAccount.account_id}`}
                quickActions={[
                  <EditAccountQuickAction
                    key="edit-account"
                    account={selectedAccount}
                  />,
                  <ShareAccountQuickAction
                    key="share"
                    account={selectedAccount}
                  />,
                  <RefreshAccountQuickAction
                    key="refresh-account"
                    account={selectedAccount}
                  />,
                  <DeleteAccountQuickActionButton
                    key="delete"
                    account={selectedAccount}
                  />,

                  <RemoveAccountQuickAction
                    key="remove-account-access"
                    account={selectedAccount}
                  />,
                ]}
                showModal={showModal}
                hasPrevious={selectedAccountIndex > 0}
                hasNext={selectedAccountIndex < allAccounts.length - 1}
                onClickNext={() => {
                  setSelectedAccountIndex(selectedAccountIndex + 1);
                }}
                onClickPrevious={() => {
                  setSelectedAccountIndex(selectedAccountIndex - 1);
                }}
              >
                <AccountDetailView account={selectedAccount} />
              </TableRowModal>
            )}
          </div>
          {(!isMobileLayout || hasAccount) && (
            <NetWorthSummaryBrief accounts={allAccounts} />
          )}

          {data && (
            <DndProvider backend={HTML5Backend}>
              <div className="accounts__content-wrapper mt2">
                <div className="accounts__section">
                  {draggableAccounts.map((draggableAccount, i) => {
                    const { category, accounts, total } =
                      draggableAccount.category;
                    return (
                      <DraggableWrapper
                        type={RowCardType}
                        id={draggableAccount.id}
                        index={i}
                        moveCard={moveCategory}
                        onDrop={onDragComplete}
                        key={i}
                      >
                        <AccountCategorySection
                          key={category}
                          category={category}
                          accounts={accounts ?? []}
                          sum={total}
                          openAccountID={openAccountID}
                          onOpen={(accountID) => {
                            const search = qs.stringify({
                              accountID: accountID,
                            });
                            history.push(
                              `${DashboardPages.Integrations}?${search}`
                            );
                          }}
                          onClose={() => {
                            history.push(DashboardPages.Integrations);
                          }}
                          onAccountMove={onMoveAccount}
                          onDragComplete={onDragComplete}
                          onRemove={onRemove}
                          onAdd={onCategoryAdd}
                          existingCategories={existingCategories}
                          lastCategory={i === draggableAccounts.length - 1}
                        />
                        <AccountCategoryAdd
                          onAdd={onCategoryAdd}
                          existingCategories={existingCategories}
                        />
                      </DraggableWrapper>
                    );
                  })}
                </div>
              </div>
            </DndProvider>
          )}
          {!showAccountFilters && (
            <div className="nothing-here">
              <div className="nothing-here-icon">
                <Icon
                  asset={IconAsset.AddAccountEmpty}
                  width="64px"
                  height="64px"
                />
              </div>
              <div className="nothing-here-text">
                Click an account type above to get started
              </div>
              <div className="nothing-here-line"></div>
            </div>
          )}
        </PageWrapper>
      </AccountTransactionContextProvider>
    </AccountTableContext.Provider>
  );
};
